import { Editor } from '@tiptap/react'
import { useState } from 'react'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import * as S from './TableTipTapButtonStyles'

interface ICPProps {
	editor: Editor
}

type tipTapTableTP = {
	rows: number
	cols: number
	withHeaderRow: boolean
}

export function TableTipTapButtonCP(props: ICPProps): JSX.Element {
	const [selectedRow] = useState<number>(-1)
	const [selectedCol] = useState<number>(-1)
	const [hoverRow, setHoverRow] = useState<number>(-1)
	const [hoverCol, setHoverCol] = useState<number>(-1)

	function handleClicOnCell(row: number, col: number): void {
		const table: tipTapTableTP = {
			cols: col,
			rows: row,
			withHeaderRow: true,
		}
		props.editor.commands.insertTable(table)
	}

	function handleMouseEnterOnCell(row: number, col: number): void {
		setHoverRow(row)
		setHoverCol(col)
	}

	function handleMouseLeaveOnCell(): void {
		setHoverRow(-1)
		setHoverCol(-1)
	}

	const displayRow = hoverRow !== -1 ? hoverRow : selectedRow
	const displayCol = hoverCol !== -1 ? hoverCol : selectedCol

	return (
		<S.MatrizContainer>
			<TitleCP textSize="small" marginBottom={10}>
				Modelo da Tabela
			</TitleCP>
			<S.Matriz>
				{Array.from({ length: 6 }).map((_, rowIndex) => (
					<S.Row key={rowIndex}>
						{Array.from({ length: 6 }).map((_, colIndex) => (
							<S.Cell
								key={colIndex}
								selected={rowIndex < selectedRow && colIndex < selectedCol}
								preview={rowIndex < hoverRow && colIndex < hoverCol}
								onClick={() => handleClicOnCell(rowIndex + 1, colIndex + 1)}
								onMouseEnter={() => handleMouseEnterOnCell(rowIndex + 1, colIndex + 1)}
								onMouseLeave={handleMouseLeaveOnCell}
							/>
						))}
					</S.Row>
				))}
			</S.Matriz>
			<S.MatrizLabel>{`${Math.max(displayRow, 1)} x ${Math.max(displayCol, 1)}`}</S.MatrizLabel>
		</S.MatrizContainer>
	)
}
