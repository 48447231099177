import { Radio } from 'antd'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import styled, { css } from 'styled-components'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

export const RadioGroupLabel = styled.div`
	color: ${ThemeFrameworkCommon.browserDefaultColorDark}55;
`

export type RadioGroupWrapperPropsTP = {
	buttonWidth?: number
	buttonHeight?: number
	secondary?: boolean
	paddingTop?: number
	fontSize?: FontSizeTP
	type: 'button' | 'vertical-radio'
}

export const RadioGroupWrapper = styled.div<RadioGroupWrapperPropsTP>`
	display: flex;
`

export const RadioGroup = styled(Radio.Group)<RadioGroupWrapperPropsTP>`
	${(props) => css`
		&.ant-radio-group {
			${props.type === 'button' && 'display: flex;'}
			padding-top: ${props.paddingTop}px;
		}

		.ant-radio-button-wrapper {
			display: flex;
			width: ${!!props.buttonWidth ? `${props.buttonWidth}px` : 'unset'};
			height: ${!!props.buttonHeight ? `${props.buttonHeight}px` : 'unset'};
			align-items: center;
			justify-content: center;
			color: ${ThemeFrameworkCommon.black};

			&-disabled {
				opacity: 0.5 !important;
				color: ${ThemeFrameworkCommon.black} !important;
				background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
				border: 0 !important;

				&:hover {
					background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight} !important;
				}
			}

			&-checked {
				&.ant-radio-button-wrapper-disabled {
					background-color: ${ThemeProject.secondary};
					color: ${ThemeFrameworkCommon.white} !important;

					&:hover {
						background-color: ${ThemeProject.secondary} !important;
					}
				}
			}

			&:hover {
				color: ${ThemeProject.menuItemColor};
				background-color: ${ThemeProject.secondary};
				border-color: ${ThemeProject.secondary};
			}

			&.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
				background: ${ThemeProject.primary};
				border-color: ${ThemeProject.primary};
				color: ${ThemeProject.menuItemColor};
			}
		}
	`}
`

export const RadioGroupError = styled.div`
	transition: opacity 0.3s;
	font-size: 12px;
	font-style: italic;
	margin: 2px 0;
	color: ${ThemeProject.error};

	i {
		margin-left: 5px;
	}
`
