import { IsNotEmpty, IsRequired } from 'submodules/nerit-framework-utils/validators'

export class UserLoginRequestDTO {
	@IsRequired()
	@IsNotEmpty()
	email: string

	@IsRequired()
	@IsNotEmpty()
	password: string
}
