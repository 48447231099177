import { createSlice } from '@reduxjs/toolkit'
import { logoutDoctorGroupReducer } from 'app/redux/slices/doctor-group/reducers/logoutDoctorGroupReducer'
import { setCurrentDoctorGroupReducer } from 'app/redux/slices/doctor-group/reducers/setCurrentDoctorGroupReducer'
import { DoctorGroupNamesResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/responses/DoctorGroupNamesResponseDTO'

export interface IDoctorGroupState {
	code: DoctorGroupNamesResponseDTO['code'] | null
	name: DoctorGroupNamesResponseDTO['name'] | null
	loggedUserAccess: DoctorGroupNamesResponseDTO['loggedUserAccess'] | null
	config: DoctorGroupNamesResponseDTO['config'] | null
}

const initialState: IDoctorGroupState = {
	code: null,
	name: null,
	loggedUserAccess: null,
	config: null,
}

export const doctorGroupSlice = createSlice({
	name: 'doctorGroup',
	initialState,
	reducers: (reducerCreator) => ({
		setCurrentDoctorGroup: reducerCreator.reducer(setCurrentDoctorGroupReducer),
		logout: reducerCreator.reducer(logoutDoctorGroupReducer),
	}),
})

export const doctorGroupActions = doctorGroupSlice.actions
