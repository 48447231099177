import { Icon as LegacyIcon } from '@ant-design/compatible'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

type ScpPropsTP = {
	marginRight?: number
	marginLeft?: number
	marginTop?: number
	marginBottom?: number
}

export interface IIconCPProps extends ScpPropsTP {
	antIcon: string
	color?: string
	theme?: 'filled' | 'outlined' | 'twoTone'
}

/**
 * Componente WRAPPER de Icone
 */
export function IconCP(props: IIconCPProps): JSX.Element {
	return (
		<WrapperSCP
			marginTop={props.marginTop ?? 0}
			marginRight={props.marginRight ?? 0}
			marginBottom={props.marginBottom ?? 0}
			marginLeft={props.marginLeft ?? 0}
		>
			<LegacyIcon type={props.antIcon} style={{ color: props.color }} />
		</WrapperSCP>
	)
}

const WrapperSCP = styled.span<ScpPropsTP>`
	margin: ${(props) => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
	color: ${ThemeFrameworkCommon.black};
`
