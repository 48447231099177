import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { useAppSelector } from 'app/redux/hook'
import { CommonPermissionUtils } from 'modules/auth/permissions/CommonPermissionUtils'
import { ButtonSendToAwaitingCP } from 'modules/exams/components/exam-analysis/button-send-to-awaiting/ButtonSendToAwaitingCP'
import { ButtonSendToPendingAnalysisCP } from 'modules/exams/components/exam-analysis/button-send-to-pending-analysis/ButtonSendToPendingAnalysisCP'
import { ButtonSendToRectificationAnalysisCP } from 'modules/exams/components/exam-analysis/button-send-to-rectification-analysis/ButtonSendToRectificationAnalysisCP'
import { ButtonSendToReturnedCP } from 'modules/exams/components/exam-analysis/button-send-to-returned/ButtonSendToReturnedCP'
import { ButtonArchiveExamCP } from 'modules/exams/components/exams/button-archive-exam/ButtonArchiveExamCP'
import { ButtonDownloadExamCP } from 'modules/exams/components/exams/button-download-exam/ButtonDownloadExamCP'
import { ButtonPopoverExamBillingFlagsCP } from 'modules/exams/components/exams/button-popover-exam-billing-flags/ButtonPopoverExamBillingFlagsCP'
import { ButtonPopoverExamUrgencyCP } from 'modules/exams/components/exams/button-popover-exam-urgency/ButtonPopoverExamUrgencyCP'
import { ButtonUnarchiveExamCP } from 'modules/exams/components/exams/button-unarchive-exam/ButtonUnarchiveExamCP'
import { ButtonDiscardMedicalReportCP } from 'modules/exams/components/medical-report/button-discard-medical-report/ButtonDiscardMedicalReportCP'
import { ButtonDownloadMedicalReportCP } from 'modules/exams/components/medical-report/button-download-medical-report/ButtonDownloadMedicalReportCP'
import { ExamFileNamesUtils } from 'modules/exams/utils/ExamFileNamesUtils'
import styled from 'styled-components'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { ExamStatusUtils } from 'submodules/beerads-sdk/services/exams/exams/utils/ExamStatusUtils'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import ButtonRefuseRectification from 'modules/exams/components/exam-analysis/button-refuse-rectification/ButtonRefuseRectification'

interface ICPProps {
	exam: ExamResponseDTO
	onChangeExam?: () => void
	onChangeData: () => void
}

/**
 * Componente para exibir botões de ação do exame.
 */
export function ExamActionButtonsICP(props: ICPProps): JSX.Element {
	const screenSize = useScreenSize()
	const loggedUser = useAppSelector((state) => state.auth.user)
	const isBeeRadsAdmin = CommonPermissionUtils.isBeeRadsAdmin(loggedUser)

	return (
		<FlexCP justify={'space-between'} flexDirection={screenSize.sm || screenSize.xs ? 'column' : undefined}>
			<ButtonsSCP isLeft={true}>
				<ButtonDownloadExamCP
					size={'small'}
					exam={{
						id: props.exam.id,
						examDescription: props.exam.description,
						patientName: props.exam.patient.name,
						imageData: props.exam.imageData,
					}}
					downloadBy={'user'}
				/>
				{ExamStatusUtils.examStatusOfExamStatusGroupMap.get(ExamStatusGroupEnum.DONE)?.includes(props.exam.status) && (
					<ButtonDownloadMedicalReportCP
						fileName={ExamFileNamesUtils.getMedicalReportFileName([props.exam.code], props.exam)}
						margin={{ left: 10 }}
						size={'small'}
						digitalCertification={props.exam.digitalCertification}
						downloadedByConfig={{
							user: {
								examIds: [props.exam.id],
							},
						}}
						showPrinter={true}
					/>
				)}

				{props.exam.status === ExamStatusEnum.RECTIFICATION && props.exam.doctorGroup?.code && (
					<AccessControlCP
						permission={{
							byHasAnyPermissionInDoctorGroup: {
								doctorGroupCode: props.exam.doctorGroup.code,
								permissions: ['isDoctorGroupAdmin', 'isDoctorGroupUser'],
							},
						}}
					>
						<ButtonRefuseRectification examCode={props.exam.code} onSave={props.onChangeData} />
					</AccessControlCP>
				)}

				<ButtonDiscardMedicalReportCP exam={props.exam} onSave={props.onChangeData} />

				<ButtonSendToPendingAnalysisCP exam={props.exam} onSave={props.onChangeData} />
			</ButtonsSCP>

			<ButtonsSCP isLeft={false}>
				{props.exam.status === ExamStatusEnum.RETURNED && props.exam.doctorGroup?.code && (
					<AccessControlCP
						permission={{
							byHasAnyPermissionInDoctorGroup: {
								doctorGroupCode: props.exam.doctorGroup.code,
								permissions: ['isDoctorGroupAdmin', 'isDoctorGroupUser'],
							},
						}}
					>
						<ButtonSendToAwaitingCP exam={props.exam} onSave={props.onChangeData} />
					</AccessControlCP>
				)}
				<ButtonSendToReturnedCP exam={props.exam} onRectificationRequested={props.onChangeData} />

				<ButtonSendToRectificationAnalysisCP exam={props.exam} onRectificationRequested={props.onChangeData} />

				{isBeeRadsAdmin && (
					<ButtonPopoverExamBillingFlagsCP
						exam={{
							id: props.exam.id,
							extraData: props.exam.extraData,
						}}
						onChangeBillingFlags={props.onChangeExam}
					/>
				)}

				<ButtonPopoverExamUrgencyCP
					exam={{
						code: props.exam.code,
						urgencyType: props.exam.urgentType,
						status: props.exam.status,
						clinicCode: props.exam.clinic.code,
						doctorGroupCode: props.exam.doctorGroup?.code,
					}}
					appearance={{
						size: 'small',
						showBorder: true,
					}}
					onChangeUrgency={props.onChangeExam}
				/>

				{ExamStatusUtils.statusToBeArchived.includes(props.exam.status) && (
					<AccessControlCP
						permission={{
							byHasAnyPermissionInClinic: {
								clinicCode: props.exam.clinic.code,
								permissions: ['isDoctorGroupUser', 'isDoctorGroupAdmin', 'isAdmin', 'isUser'],
							},
						}}
					>
						<ButtonArchiveExamCP
							examCode={props.exam.code}
							onArchive={props.onChangeData}
							hideLabel={true}
							showIcon={true}
							size={'small'}
							margin={{ left: 10 }}
							tooltip={'Arquivar'}
						/>
					</AccessControlCP>
				)}
				{props.exam.status === ExamStatusEnum.ARCHIVED && (
					<AccessControlCP
						permission={{
							byHasAnyPermissionInClinic: {
								clinicCode: props.exam.clinic.code,
								permissions: ['isDoctorGroupUser', 'isDoctorGroupAdmin', 'isAdmin', 'isUser'],
							},
						}}
					>
						<ButtonUnarchiveExamCP
							examId={props.exam.id}
							examCode={props.exam.code}
							clinicCode={props.exam.clinic.code}
							onUnarchive={props.onChangeData}
							hideLabel={true}
							showIcon={true}
							size={'small'}
							margin={{ left: 10 }}
							tooltip={'Desarquivar'}
						/>
					</AccessControlCP>
				)}
			</ButtonsSCP>
		</FlexCP>
	)
}

const ButtonsSCP = styled.div<{ isLeft: boolean }>`
	margin-bottom: 10px;
	display: flex;

	button {
		margin-left: ${(props) => (props.isLeft ? '5px' : undefined)};
		margin-right: ${(props) => (!props.isLeft ? '5px' : undefined)};
	}
`
