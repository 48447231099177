import { useAppSelector } from 'app/redux/hook'
import { ClinicPermissionUtils } from 'modules/auth/permissions/ClinicPermissionUtils'
import { StethoscopeIconCP } from 'modules/person/components/doctor/icons/StethoscopeIconCP'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	value: UserTypeEnum
	onSelect: (type: UserTypeEnum) => void
}

/**
 * Seletor de visao de Medico ou Usuario
 */
export function SelectMultipleDoctorViewCP(props: ICPProps): JSX.Element {
	const screenSize = useScreenSize()
	const loggedUser = useAppSelector((state) => state.auth.user)
	const clinicPermissions = useAppSelector((state) => state.auth.clinicPermissions)

	// Se nao for medico e nem usuario em alguma clinica nao aparece
	// Ou seja, so aparece se o medico eh usuario e pode selecionar uma opcao
	if (!loggedUser?.isDoctor || !ClinicPermissionUtils.hasAnyPermissionsInAnyClinic(['isDoctorGroupAdmin'], clinicPermissions)) return <></>

	return (
		<RadioGroupCP<UserTypeEnum>
			selected={props.value}
			onChange={props.onSelect}
			type={'button'}
			paddingTop={0}
			options={[
				{
					value: UserTypeEnum.DOCTOR,
					content: (
						<TextCP
							icon={
								<StethoscopeIconCP
									size={14}
									color={props.value === UserTypeEnum.USER ? ThemeFrameworkCommon.black : ThemeFrameworkCommon.white}
								/>
							}
							text={screenSize.smd ? undefined : 'Ver como Médico'}
						/>
					),
				},
				{
					value: UserTypeEnum.USER,
					content: (
						<TextCP
							icon={'user'}
							text={screenSize.smd ? undefined : 'Ver como Usuário'}
							color={props.value === UserTypeEnum.DOCTOR ? ThemeFrameworkCommon.black : ThemeFrameworkCommon.white}
						/>
					),
				},
			]}
		/>
	)
}
