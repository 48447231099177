import { SelectClinicFromLoggedUserCP } from 'modules/clinic/components/select-clinic/SelectClinicFromLoggedUserCP'
import { SelectDoctorGroupPeopleCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-people/SelectDoctorGroupPeopleCP'
import { AuditFilterFormModel } from 'modules/quality-assurance/components/filters-audits/form-model/AuditFilterFormModel'
import { SelectAuditStatusCP } from 'modules/quality-assurance/components/select-audit-status/SelectAuditStatusCP'
import styled from 'styled-components'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { FlexOneICP } from 'submodules/nerit-framework-ui/common/components/flex/inner/FlexOneICP'
import { DateRangePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/DateRangePickerCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	isLoading: boolean
	onFilter: () => void
	doctorGroupCode: number
	filterFormStateManager: IFormStateManager<AuditFilterFormModel>
}

/**
 */
export function FiltersAuditsCP(props: ICPProps): JSX.Element {
	return (
		<ContentSCP>
			<FlexCP alignItems={'center'}>
				<FlexOneICP>
					<RowCP>
						<ColumnCP size={5}>
							<DateRangePickerCP
								showBorder={true}
								marginTop={16}
								fastFilter={'all'}
								formStateManager={props.filterFormStateManager}
								fieldName={'dateRange'}
							/>
						</ColumnCP>
						<ColumnCP size={5}>
							<SelectAuditStatusCP formStateManager={props.filterFormStateManager} fieldName={'status'} isMultiple={true} />
						</ColumnCP>
						<ColumnCP size={5}>
							<SelectDoctorGroupPeopleCP
								role={UserTypeEnum.DOCTOR}
								formStateManager={props.filterFormStateManager}
								formField={'doctorExamCode'}
								doctorGroupCode={props.doctorGroupCode}
								label={'Médico do Laudo'}
							/>
						</ColumnCP>
						<ColumnCP size={5}>
							<SelectDoctorGroupPeopleCP
								role={UserTypeEnum.DOCTOR}
								formStateManager={props.filterFormStateManager}
								formField={'doctorAuditorCode'}
								doctorGroupCode={props.doctorGroupCode}
								filters={{ isAuditor: true }}
								label={'Médico Auditor'}
							/>
						</ColumnCP>
						<ColumnCP size={4}>
							<SelectClinicFromLoggedUserCP
								value={{
									byFormStateManager: {
										formStateManager: props.filterFormStateManager,
										fieldName: 'clinicCodes',
									},
								}}
								multiple={{
									isMultiple: true,
									clearSearchTextOnSelect: false,
								}}
							/>
						</ColumnCP>
					</RowCP>
				</FlexOneICP>

				<ButtonCP marginLeft={10} marginTop={22} icon={'search'} type={'primary'} onClick={props.onFilter} loading={props.isLoading}>
					Filtrar
				</ButtonCP>
			</FlexCP>
		</ContentSCP>
	)
}

const ContentSCP = styled.div`
	background: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
	padding: 10px;
	margin-bottom: 40px;
`
