import { MedicalTimetableAssignFormModel } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/inner/MedicalTimetableAssignFormModel'
import { MedicalTimetableWeekDayRecurrencyTP } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/inner/MedicalTimetableWeekDayRecurrencyTP'
import { TimeRangeMedicalTimetableAssignCP } from 'modules/medical-timetable/components/time-range-medical-timetable-assign/TimeRangeMedicalTimetableAssignCP'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
	MedicalTimetablePeriodEnum,
	MedicalTimetablePeriodLabelEnum,
} from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetablePeriodEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { IconCP } from 'submodules/nerit-framework-ui/common/components/icon/IconCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { momentPtBrLocale } from 'submodules/nerit-framework-utils/utils/date/moment-pt-br-locale'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'

interface ICPProps {
	formStateManager: IFormStateManager<MedicalTimetableAssignFormModel>
	weekDay: WeekDaysEnum
	period: MedicalTimetablePeriodEnum
}

/**
 */
export function BoxMedicalTimetableDoctorWeekRecurrencyConfigItemICP(props: ICPProps): JSX.Element {
	const isRecurrent: boolean = props.formStateManager.getFieldValue('type') === 'recurrent'

	const [onEdit, setOnEdit] = useState<boolean>(false)

	const [recurrencyQuantity, setRecurrencyQuantity] = useState<number>(
		props.formStateManager.getFieldValue('recurrency')?.get(props.weekDay)?.recurrencyQuantity ?? 1,
	)
	const [beginHour, setBeginHour] = useState<string>('')
	const [endHour, setEndHour] = useState<string>('')

	/**
	 * valor é recebido como undefined várias vezes no decorrer da renderização,
	 * realiza a operação apenas quando é certo de que o valor já foi recebido
	 */
	useEffect(setBeginAndEndHour, [
		props.formStateManager.getFieldValue('recurrency')?.get(props.weekDay)?.beginHour,
		props.formStateManager.getFieldValue('recurrency')?.get(props.weekDay)?.endHour,
	])

	function setBeginAndEndHour(): void {
		/**
		 *  Caso não haja registro, seta o período como horário default
		 * */
		const [start, end] = MedicalTimetablePeriodLabelEnum[props.period].split('~')

		setBeginHour(props.formStateManager.getFieldValue('recurrency')?.get(props.weekDay)?.beginHour ?? start)
		setEndHour(props.formStateManager.getFieldValue('recurrency')?.get(props.weekDay)?.endHour ?? end)
	}

	function onChangeDaySelection(selected: boolean): void {
		let map: Map<WeekDaysEnum, MedicalTimetableWeekDayRecurrencyTP> = props.formStateManager.getFieldValue('recurrency')
		if (!map) map = new Map<WeekDaysEnum, MedicalTimetableWeekDayRecurrencyTP>()

		map.set(props.weekDay, {
			selected: !selected,
			beginHour: beginHour,
			endHour: endHour,
		})

		props.formStateManager.changeFieldValue('recurrency', map)

		const configOnDay = map?.get(props.weekDay)

		if (!configOnDay) {
			console.log('ERRO: Indice no mapa dos valores nao encontrado')
			return
		}
		/**
		 * Seta valor da recorrência para sempre começar como 1
		 */
		configOnDay.recurrencyQuantity = 1
		setRecurrencyQuantity(configOnDay.recurrencyQuantity)
	}

	/**
	 */
	function onChangeRecurrencyQuantity(value: number): void {
		const map: Map<WeekDaysEnum, MedicalTimetableWeekDayRecurrencyTP> = props.formStateManager.getFieldValue('recurrency')
		const configOnDay = map?.get(props.weekDay)

		if (!configOnDay) {
			console.log('ERRO: Indice no mapa dos valores nao encontrado')
			return
		}

		configOnDay.recurrencyQuantity = value
		setRecurrencyQuantity(value)
	}

	return (
		<WrapperSCP>
			<RowCP align="middle">
				<ColumnCP size={9}>
					<SwitchWrapperSCP>
						<SwitchCP
							label={momentPtBrLocale.weekdaysFull[props.weekDay]}
							value={props.formStateManager.getFieldValue('recurrency')?.get(props.weekDay)?.selected ?? false}
							onChange={() =>
								onChangeDaySelection(props.formStateManager.getFieldValue('recurrency')?.get(props.weekDay)?.selected ?? false)
							}
						/>
					</SwitchWrapperSCP>
				</ColumnCP>
				<ColumnCP size={6}>
					<TextCP text={`${beginHour}~${endHour}`} fontStyle="italic" size="small" />
				</ColumnCP>
				<ColumnCP size={7}>
					{isRecurrent && props.formStateManager.getFieldValue('recurrency')?.get(props.weekDay)?.selected && (
						<TextCP icon={'sync'} fontStyle="italic" text={`${recurrencyQuantity} semana(s)`} />
					)}
				</ColumnCP>
				<ColumnCP size={2}>
					<ButtonCP
						size="small"
						type={'default'}
						onClick={() => setOnEdit(!onEdit)}
						disabled={!props.formStateManager.getFieldValue('recurrency')?.get(props.weekDay)?.selected}
					>
						<IconCP antIcon="edit" />
					</ButtonCP>
				</ColumnCP>
			</RowCP>

			{props.formStateManager.getFieldValue('recurrency')?.get(props.weekDay)?.selected && onEdit && (
				<RowCP align={'middle'}>
					<ColumnCP size={10}>
						<TimeRangeMedicalTimetableAssignCP
							formStateManager={props.formStateManager}
							weekDay={props.weekDay}
							assignType={'recurrency'}
							beginHour={beginHour}
							endHour={endHour}
							setBeginHour={setBeginHour}
							setEndHour={setEndHour}
						/>
					</ColumnCP>
					<ColumnCP size={14}>
						{isRecurrent && (
							<RecurrenceWrapperSCP>
								<TextCP text={'Repetir a cada'} fontStyle="italic" />
								<RecurrenceInputWrapperSCP>
									<InputCP
										placeholder={'X'}
										type={'number'}
										required={true}
										value={props.formStateManager.getFieldValue('recurrency')?.get(props.weekDay)?.recurrencyQuantity}
										onChange={onChangeRecurrencyQuantity}
										marginTop={0}
										marginLeft={20}
										width={60}
										maxlength={2}
										min={0}
										max={99}
									/>
								</RecurrenceInputWrapperSCP>
								<TextCP text={'semana(s)'} marginLeft={20} />
							</RecurrenceWrapperSCP>
						)}
					</ColumnCP>
				</RowCP>
			)}
		</WrapperSCP>
	)
}

const RecurrenceInputWrapperSCP = styled.div`
	.ant-form-item {
		margin-left: 5px;
		margin-right: 5px;
		width: 76px !important;
	}
	> div {
		width: 70px;
	}
`

const RecurrenceWrapperSCP = styled.div`
	display: flex;
	align-items: center;
	margin-left: 10px;
	margin-top: 15px;
	font-style: italic;
	font-size: 10px;
`

const WrapperSCP = styled.div`
	display: flex;
	gap: 5px;
	margin-bottom: 10px;
	flex-direction: column;
	padding: 5px;
	border-radius: 5px;
	justify-content: center;
`
const SwitchWrapperSCP = styled.div`
	.ant-typography {
		width: 100px;
	}
`
