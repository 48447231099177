import { Badge } from 'antd'
import { BadgeProps } from 'antd/lib'

interface ICPProps extends BadgeProps {
	children: JSX.Element
	count?: React.ReactNode
	showZero?: boolean
	dot?: boolean
	title?: string
}

export function BadgeCP(props: ICPProps): JSX.Element {
	return <Badge {...props}>{props.children}</Badge>
}
