import { useAppSelector } from '@/app/redux/hook'
import { AvcSectionContentICP, IAvcSectionProps } from 'modules/exams/components/medical-report/editor-avc-medical-report/inner/AvcSectionContentICP'
import { TemplatesRequests } from 'submodules/beerads-sdk/services/templates/templates/TemplatesRequests'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { TemplateStatusEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateStatusEnum'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import { MentionFromApiCP } from 'submodules/nerit-framework-ui/common/components/form-fields/mention/MentionFromApiCP'

export function AvcQuestion5ICP(props: IAvcSectionProps): JSX.Element {
	const user = useAppSelector((store) => store.auth.user)

	/**
	 * Ao mudar o valor
	 */
	function onChange(option: string): void {
		if (!option) return

		props.formStateManager.changeFieldValue('q1HemoExtr', option.replace('@', ''))
	}

	return (
		<AvcSectionContentICP title={'OUTROS ACHADOS/DÚVIDAS:'}>
			<MentionFromApiCP<TemplateResponseDTO>
				placeholder={'Digite @ para autocompletar as frases de laudo'}
				requestConfigTP={() =>
					TemplatesRequests.search({
						type: TemplateTypeEnum.PHRASE,
						status: TemplateStatusEnum.ACTIVE,
						doctorCode: user?.code,
					})
				}
				renderMentionOption={(row) => ({
					label: row.name,
					value: row.content,
				})}
				prefix={'@'}
				value={props.formStateManager.getFieldValue('q1HemoExtr')}
				onChange={onChange}
				rows={3}
			/>
		</AvcSectionContentICP>
	)
}
