import { IsNotEmpty, IsString, IsUUID } from 'class-validator'

export class ExtractPathologyFromReportRequestDTO {
	@IsString()
	@IsNotEmpty()
	report: string

	@IsString()
	@IsNotEmpty()
	@IsUUID()
	examId: string
}
