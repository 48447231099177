import { userHasPermissions } from '@/submodules/beerads-sdk/common/roles-permissions/utils/userHasPermissions'
import { RequestHelper } from '@/submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'
import { AuthUtils } from '@/submodules/nerit-framework-utils/sdk-utils/request-manager/utils/AuthUtils'
import { useAppSelector } from 'app/redux/hook'
import { useMemo, useState } from 'react'
import { RolesPermissionsTP } from 'submodules/beerads-sdk/common/roles-permissions/enums'

interface IUsePermissionsResponse {
	userPermissions: RolesPermissionsTP[]
	hasAllPermissions: (permissions: RolesPermissionsTP[]) => boolean
	hasSomePermission: (permissions: RolesPermissionsTP[]) => boolean
	onChangeTemporaryDoctorGroup(doctorGroupCode: number): void
}

export function usePermissions(): IUsePermissionsResponse {
	const user = useAppSelector((state) => state.auth.user)
	const code = useAppSelector((state) => state.doctorGroup.code)

	/**
	 * TODO: Remove this when we figure permission out!
	 */
	const [temporaryDoctorGroup, setTemporaryDoctorGroup] = useState<number>()

	const userPermissions = useMemo(() => {
		const selectedCode = temporaryDoctorGroup ?? code

		if (!selectedCode) {
			return []
		}

		return user?.permissions.doctorGroups[selectedCode] ?? []
	}, [user, code, temporaryDoctorGroup])

	function onChangeTemporaryDoctorGroup(doctorGroupCode: number): void {
		setTemporaryDoctorGroup(doctorGroupCode)
		RequestHelper.addDefaultHeaderConfig(AuthUtils.getCodeBehalfTo(doctorGroupCode))
	}

	function hasAllPermissions(permissions: RolesPermissionsTP[]): boolean {
		return userHasPermissions({ userPermissions, requiredPermissions: permissions, options: { validateAllRoles: true } })
	}

	function hasSomePermission(permissions: RolesPermissionsTP[]): boolean {
		return userHasPermissions({ userPermissions, requiredPermissions: permissions })
	}

	return {
		userPermissions,
		hasAllPermissions,
		hasSomePermission,
		onChangeTemporaryDoctorGroup,
	}
}
