import { Typography } from 'antd'
import styled, { css } from 'styled-components'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

/**
 * @todo: Declarar isso num arquivo proprio
 */
export interface ITextStyleProps {
	underline?: boolean
	delete?: boolean
	strong?: boolean
	color?: string
	wrapText?: boolean
	size?: FontSizeTP
	light?: boolean
	center?: boolean
	width?: number
}

interface ITextCPProps extends ITextStyleProps {
	text: string
}

/**
 * Componente para exibir texto.
 *
 * @author Stella
 * @author hjcostabr
 * @author Victor
 */
export function TextCP(props: ITextCPProps): JSX.Element {
	return (
		<TextSCP
			wrapText={props.wrapText}
			underline={props.underline}
			delete={props.delete}
			strong={props.strong}
			color={props.color}
			size={props.size ?? 'normal'}
			light={props.light}
			center={props.center}
			width={props.width}
			className=""
		>
			{props.text}
		</TextSCP>
	)
}

const fontSizeCss = css<{ size: FontSizeTP }>`
	font-size: ${(props) => ThemeFrameworkCommon.fontSizes[props.size]};
`

const TextSCP = styled(Typography.Text)<{ size: FontSizeTP; color?: string; wrapText?: boolean; light?: boolean; center?: boolean; width?: number }>`
	&.ant-typography {
		color: ${(props) => (!!props.color ? `${props.color}` : 'unset')};
		${fontSizeCss}
		strong {
			font-weight: 700;
		}
	}
	overflow: hidden;
	text-overflow: ellipsis;
	overflow-wrap: normal;
	font-weight: ${(props) => (props.light ? '400' : '500')};
	white-space: ${(props) => (props.wrapText ? 'normal' : 'nowrap')};
	text-align: ${(props) => (!!props.center ? 'center' : 'unset')};
	width: ${(props) => props.width}px;
`
