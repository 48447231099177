import { ButtonEditUserDataCP } from 'modules/person/components/user/button-edit-user-data/ButtonEditUserDataCP'
import { PeopleRequests } from 'submodules/beerads-sdk/services/people/people/PeopleRequests'
import { PersonResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PersonResponseDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextAndTitleCP } from 'submodules/nerit-framework-ui/common/components/text/TextAndTitleCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { UploaderImageFromApiCP } from 'submodules/nerit-framework-ui/common/components/uploader/uploader-image-from-api/UploaderImageFromApiCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import * as S from './CardProfileUserDataStyles'

interface ICPProps {
	person: PersonResponseDTO
	onClickChangePassword: () => void
	onChangeData: () => void
}

/**
 */
export function CardProfileUserDataCP(props: ICPProps): JSX.Element | null {
	return (
		<CardCP>
			<S.Wrapper>
				<S.ImgWrapper>
					<UploaderImageFromApiCP
						type={'avatar'}
						currentData={{
							code: props.person.code,
							imgUrl: props.person.profilePicture,
						}}
						requestApiConfig={{
							requestApi: PeopleRequests.changeProfilePicture,
						}}
						onSuccess={() => window.location.reload()}
					/>
				</S.ImgWrapper>

				<S.Info>
					<FlexCP>
						<div className={'custom-label'}>
							<TitleCP underLine={true} marginBottom={30}>
								{props.person.name}
							</TitleCP>

							<TextAndTitleCP icon={'phone'} title={'Celular:'} text={MaskUtils.applyMask2(InputMaskTypeEnum.PHONE, props.person.phone)} />

							<TextAndTitleCP icon={'mail'} title={'Email:'} text={props.person.email} />

							<FlexCP>
								<ButtonEditUserDataCP
									person={props.person}
									onChangeData={props.onChangeData}
									appearance={{
										type: 'primary',
										showLabel: true,
									}}
								/>
								<ButtonCP onClick={props.onClickChangePassword} icon={'key'} marginLeft={5}>
									Alterar Senha
								</ButtonCP>
							</FlexCP>
						</div>
					</FlexCP>
				</S.Info>
			</S.Wrapper>
		</CardCP>
	)
}
