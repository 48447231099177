import { TableClinicPeopleCP } from 'modules/clinic/components/table-clinic-people/TableClinicPeopleCP'
import { ModalClinicDoctorGroupDoctorCP } from 'modules/doctor-groups/components/clinic-doctor-groups/modal-clinic-doctor-group-doctor/ModalClinicDoctorGroupDoctorCP'
import { useEffect, useState } from 'react'
import { ClinicDoctorGroupResponseDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/responses/ClinicDoctorGroupResponseDTO'
import {
	ClinicPersonRelationModeEnum,
	ClinicPersonRelationModeLabelEnum,
} from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicPersonRelationModeEnum'
import { PersonClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/person-clinic/dtos/responses/PersonClinicResponseDTO'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'

interface ICPProps {
	type: ClinicPersonRelationModeEnum
	clinicDoctorGroup: ClinicDoctorGroupResponseDTO
	onChangeData: () => void
}

/**
 * Aba com os medicos alocados na clinica pelo grupo
 */
export function ClinicDoctorGroupDoctorsConfigCP(props: ICPProps): JSX.Element {
	const [showAddDoctorModal, setShowAddDoctorModal] = useState<boolean>(false)
	const [selectedDoctor, setSelectedDoctor] = useState<PersonClinicResponseDTO>()

	const [linkedDoctorsCodes, setLinkedDoctorsCodes] = useState<number[]>()

	const [reloadDoctorsList, setReloadDoctorsList] = useState<number>()
	useEffect(() => setReloadDoctorsList(TableUtils.getReloadNumber()), [props.clinicDoctorGroup])

	/**
	 * Ao fechar modal de adicionar medico.
	 */
	function onAddDoctorModalClose(allocationSuccess: boolean): void {
		setShowAddDoctorModal(false)
		if (allocationSuccess) {
			setReloadDoctorsList(TableUtils.getReloadNumber())
			props.onChangeData()
		}
	}

	/**
	 * Ao clicar para alocar medico.
	 */
	function onEditDoctor(doctor: PersonClinicResponseDTO): void {
		setShowAddDoctorModal(true)
		setSelectedDoctor(doctor)
	}

	return (
		<>
			<FlexCP justify={'space-between'} margin={{ top: 40, bottom: 10 }}>
				<TitleCP underLine={true} textSize={'normal'}>
					{`Médicos ${ClinicPersonRelationModeLabelEnum[props.type]}`}
				</TitleCP>
				{!!linkedDoctorsCodes && (
					<ButtonCP
						icon={props.type === ClinicPersonRelationModeEnum.IN ? 'user-add' : 'user-delete'}
						size={'small'}
						type={'primary'}
						danger={props.type === ClinicPersonRelationModeEnum.NOT_IN}
						onClick={() => {
							setSelectedDoctor(undefined)
							setShowAddDoctorModal(true)
						}}
					>
						{props.type === ClinicPersonRelationModeEnum.IN ? 'Alocar médico' : 'Bloquear médico'}
					</ButtonCP>
				)}
			</FlexCP>

			<TableClinicPeopleCP
				clinicCode={props.clinicDoctorGroup.clinic.code}
				role={UserTypeEnum.DOCTOR}
				doctorGroupCode={props.clinicDoctorGroup.doctorGroup.code}
				shouldReload={reloadDoctorsList}
				onEditDoctor={onEditDoctor}
				onChangeData={props.onChangeData}
				relationMode={props.type}
				tableConfig={{
					showToogleAdminButton: false,
					wrappedOnCard: false,
					hideEmptyLogo: true,
				}}
				onLoad={(clinicPersons) => setLinkedDoctorsCodes(clinicPersons.map((clinicPerson) => clinicPerson.person.code))}
			/>

			<ModalClinicDoctorGroupDoctorCP
				clinicDoctorGroupCode={props.clinicDoctorGroup.code}
				doctorGroupCode={props.clinicDoctorGroup.doctorGroup.code}
				clinicModalities={props.clinicDoctorGroup.modalities}
				onClose={onAddDoctorModalClose}
				visible={showAddDoctorModal}
				clinicPersonToEdit={selectedDoctor}
				alreadyLinkedDoctorCodes={linkedDoctorsCodes}
				doctorTypeRelation={props.type}
			/>
		</>
	)
}
