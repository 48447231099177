import { Buffer } from 'buffer'
import * as md5 from 'md5'

/**
 * Encapsula metodos para criptografar dados.
 */
export class CryptoUtils {
	private constructor() {
		/** Construtor privado impede instanciacao. */
	}

	/**
	 * Criptografa string passada.
	 */
	public static encrypt(data: string, salt: string): string {
		return md5(data + salt)
	}

	/**
	 * Criptografa o dado passado sem salt
	 */
	public static encryptSaltless(data: string): string {
		return md5(data)
	}

	/**
	 * Valida se o dado entrado condiz com o hash recebido
	 */
	public static compareHash(hash: string, password: string, saltPlainText: string): boolean {
		return CryptoUtils.encrypt(password, saltPlainText) === hash
	}

	/**
	 * Cria um hash reversivel encriptado.
	 */
	public static reversibleEncrypt(text: string): string {
		return Buffer.from(text).toString('base64')
	}

	/**
	 * Descripta um hash reversivel
	 */
	public static decrypt(text: string): string {
		return Buffer.from(text, 'base64').toString('utf8')
	}

	public static toBase64(text: string): string {
		return Buffer.from(text).toString('base64')
	}

	/** Gera & retorna string com salt de senha padrao NAO criptografado. */
	public static getDefaultDecryptedSalt(fieldSalt?: string): string {
		return `${new Date().getTime()}nerit${fieldSalt}`
	}

	public static base64UrlEncode(input: string): string {
		return Buffer.from(input, 'utf-8').toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
	}

	public static base64UrlDecode(input: string): string {
		let unpaddedInput = input.replace(/-/g, '+').replace(/_/g, '/')
		const padding = input.length % 4
		if (padding !== 0) {
			unpaddedInput += '='.repeat(4 - padding)
		}
		return Buffer.from(unpaddedInput, 'base64').toString('utf-8')
	}
}
