import { useAppSelector } from 'app/redux/hook'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { ButtonAssignExamCP } from 'modules/exams/components/exams/button-assign-exam/ButtonAssignExamCP'
import { ButtonDownloadExamCP } from 'modules/exams/components/exams/button-download-exam/ButtonDownloadExamCP'
import { ButtonExamDetailsCP } from 'modules/exams/components/exams/button-exam-details/ButtonExamDetailsCP'
import { ButtonDoMedicalReportCP } from 'modules/exams/components/medical-report/button-do-medical-report/ButtonDoMedicalReportCP'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ExamListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'

interface ICPProps {
	exam: ExamListItemResponseDTO
	onChangeData: () => void
	viewAsDoctor: boolean
	searchExamDto?: ExamSearchRequestDTO
}

/**
 * Renderiza coluna de ações da tabela de exames.
 */
export function ColumnExamAwaitingActionsICP(props: ICPProps): JSX.Element {
	const loggedUser = useAppSelector((state) => state.auth.user)

	// Verifica se mostra mostrar dados do exame
	const canSeeExamData = DoctorGroupPermissionUtils.canSeeExamData(loggedUser, props.viewAsDoctor, props.exam.clinic.code, props.exam.doctorGroupCode)

	return (
		<ButtonGroupCP mode={'separeted'}>
			<ButtonDoMedicalReportCP exam={props.exam} searchExamDto={props.searchExamDto} />

			{canSeeExamData && (
				<BasicStyleWrapperCP>
					<ButtonDownloadExamCP
						size={'small'}
						iconOnly={true}
						exam={{
							id: props.exam.id,
							imageData: props.exam.imageData,
							examDescription: props.exam.description,
							patientName: props.exam.patient.name,
						}}
						downloadBy={'user'}
					/>
				</BasicStyleWrapperCP>
			)}

			{[ExamStatusEnum.AWAITING, ExamStatusEnum.SIGNED, ExamStatusEnum.RECTIFICATION].includes(props.exam.status) &&
				!!props.exam.doctorGroupCode && (
					<ButtonAssignExamCP
						selectedExamCodes={[props.exam.code]}
						doctorGroupCode={props.exam.doctorGroupCode}
						assignedDoctorCodes={props.exam.doctorsAssigned?.map((doctor) => doctor.code)}
						onSave={props.onChangeData}
						appearance={{
							size: 'small',
							buttonType: loggedUser?.isDoctor ? 'default' : 'primary',
						}}
					/>
				)}

			{canSeeExamData && <ButtonExamDetailsCP examId={props.exam.id} onSave={props.onChangeData} />}
		</ButtonGroupCP>
	)
}
