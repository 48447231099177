import { ThemeProject } from 'config/theme/project/ThemeProject'
import styled from 'styled-components'

export const Wrapper = styled.div<{ positionFixed?: boolean }>`
	background: ${ThemeProject.warning};
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	width: 100%;
	font-weight: bold;
	color: ${ThemeProject.primary};

	> div > button {
		padding: 0 4px;
	}
`
