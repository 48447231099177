import { Menu } from 'antd'
import { MenuProps } from 'antd/lib/menu'
import React from 'react'
import * as S from './MenuStyles'

type _AntPropsTP = {
	defaultOpenKeys?: MenuProps['defaultOpenKeys']
	selectable?: MenuProps['selectable']
	inlineCollapsed?: MenuProps['inlineCollapsed']
	onSelectionChange?: (openKeys: string[]) => void
	mode?: MenuProps['mode']
}

interface IMenuCPProps extends React.PropsWithChildren<S._ScpPropsTP & _AntPropsTP> {}

/**
 * COMPONENTE: Menu.
 */
export function MenuCP(props: IMenuCPProps): JSX.Element {
	return (
		<S.WrapperSCP width={props.width}>
			<Menu
				theme="light"
				onOpenChange={props.onSelectionChange}
				defaultOpenKeys={props.defaultOpenKeys}
				inlineCollapsed={props.mode === 'inline' ? !!props.inlineCollapsed : undefined}
				selectable={props.selectable}
				mode={props.mode ?? 'inline'}
			>
				{props.children}
			</Menu>
		</S.WrapperSCP>
	)
}
