import { FloatingMenu } from '@tiptap/react'
import styled from 'styled-components'

const theme = localStorage.getItem('theme')
const isDarkMode = theme === 'dark'

export const TipTapFloatingMenu = styled(FloatingMenu)`
	display: flex;
	flex-direction: column;
	gap: 5px;
	background-color: ${isDarkMode ? 'rgba(127, 123, 123, 0.85)' : 'rgba(255, 255, 255, 0.85)'};
	padding: 0.2rem;
	border-radius: 0.5rem;
	z-index: 1000;
	max-height: 200px;
	overflow: auto;
`

export const TipTapFloatingMenuButton = styled.button`
	background-color: unset;
	padding: 0.275rem 0.425rem;
	border-radius: 0.3rem;
	cursor: pointer;
	border: none;
	color: ${isDarkMode ? '#fff' : '#000'};

	&:hover {
		background-color: rgba(61, 37, 20, 0.2);
	}
`
