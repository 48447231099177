import styled from 'styled-components'

export const CertificatesFooter = styled.div`
	align-self: center;
	position: fixed;
	bottom: 24px;
	display: grid;
	grid-auto-flow: column;
	gap: 16px;
`

export const Image = styled.img`
	object-fit: contain;
	width: 80px;
	height: 80px;
`