import styled from 'styled-components'

export const DoctorOnMedicalTimetableCellWrapper = styled.div<{ isDoctorAbsence?: boolean }>`
	height: 115px;
	display: flex;
	background-color: ${(props) => (props.isDoctorAbsence ? 'rgba(212, 206, 94, 0.47)' : '')};

	.divider {
		height: auto;
		width: 4px;
		background: #d4ce5e;
		display: ${(props) => (props.isDoctorAbsence ? 'block' : 'none')};
	}
`
export const ButtonMedicalTimetableAssignWrapper = styled.div`
	padding: 8px;
`
