import { RoutingHelper } from 'config/RoutingHelper'
import { ButtonSendToPendingAnalysisCP } from 'modules/exams/components/exam-analysis/button-send-to-pending-analysis/ButtonSendToPendingAnalysisCP'
import { ButtonDiscardMedicalReportCP } from 'modules/exams/components/medical-report/button-discard-medical-report/ButtonDiscardMedicalReportCP'
import { ButtonSaveMedicalReportAsDraftCP } from 'modules/exams/components/medical-report/button-save-medical-report-as-draft/ButtonSaveMedicalReportAsDraftCP'
import { MedicalReportFormModel } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModel'
import { useState } from 'react'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
	exam: ExamResponseDTO
	formStateManager: IFormStateManager<MedicalReportFormModel>
	searchExamDto?: ExamSearchRequestDTO
}

/**
 */
export function ButtonBackMedicalReportHeaderCP(props: ICPProps): JSX.Element {
	const [isVisible, setIsVisible] = useState<boolean>(false)

	return (
		<PopOverCP
			title={'O que você deseja fazer?'}
			visible={isVisible}
			onClose={() => setIsVisible(false)}
			trigger={'click'}
			content={
				<>
					<TextCP text={'Ao voltar para lista, este exame irá continuar em PROGRESSO atribuído a você.'} />
					<FlexCP justify={'space-between'} margin={{ top: 20 }}>
						{props.exam.doctorGroup?.config?.hasAdministrativeAnalysis ? (
							<ButtonSendToPendingAnalysisCP
								exam={props.exam}
								size={'default'}
								onSave={() => RoutingHelper.goToExams(undefined, props.searchExamDto, true)}
								onClick={() => setIsVisible(false)}
							/>
						) : (
							<ButtonDiscardMedicalReportCP
								size={'default'}
								exam={props.exam}
								onSave={() => RoutingHelper.goToExams(undefined, props.searchExamDto, true)}
								onClick={() => setIsVisible(false)}
							/>
						)}

						<ButtonSaveMedicalReportAsDraftCP
							margin={{ left: 20 }}
							exam={props.exam}
							formStateManager={props.formStateManager}
							onSave={() => RoutingHelper.goToExams(undefined, props.searchExamDto, true)}
							text={'Salvar rascunho e voltar para lista'}
						/>
					</FlexCP>
				</>
			}
		>
			<ButtonCP onClick={() => setIsVisible(true)} ghost={true} type={'primary'} shape={'circle'} icon={'arrow-left'} marginRight={10} />
		</PopOverCP>
	)
}
