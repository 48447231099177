import DOMPurify from 'dompurify'
import { AvatarMultipleExamModalityCP } from 'modules/exams/components/exams/avatar-multiple-exam-modality/AvatarMultipleExamModalityCP'
import { WrapperMedicalReportEditorCP } from 'modules/exams/components/medical-report/editor-medical-report/wrappers/WrapperMedicalReportEditorCP'
import { WrapperMedicalReportEditorContentAreaICP } from 'modules/exams/components/medical-report/editor-medical-report/wrappers/WrapperMedicalReportEditorContentAreaICP'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { WrapperSCP } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/TipTapStyles'
import * as S from '../ModalCompareTemplateStyles'

interface ICPProps {
	content: string
	templateExamName: string
	templateExamDescription?: string
	templateExamModalities: ExamModalityEnum[]
	templateVersion: string
}

export function TemplateContentToCompareCP({
	content,
	templateExamModalities,
	templateExamName,
	templateVersion,
	templateExamDescription,
}: ICPProps): JSX.Element {
	const purifiedHTML = DOMPurify.sanitize(content)

	return (
		<FlexCP justify="center" flexDirection="column" gap={4}>
			<FlexCP flexDirection="column" margin={{ bottom: 8 }}>
				<FlexCP margin={{ right: 12, left: 12 }} gap={4} justify="space-between">
					<FlexCP>
						<AvatarMultipleExamModalityCP modalities={templateExamModalities} />
						<FlexCP flexDirection="column">
							<S.TextStyledSCP>
								<TextCP text={templateExamName} size="large" />
								<TextCP text={templateExamDescription} size="small" />
							</S.TextStyledSCP>
						</FlexCP>
					</FlexCP>
					<TagCP color={/Nov(a|o)/gm.test(templateVersion) ? 'lightGreen' : 'blue'} content={templateVersion} />
				</FlexCP>
			</FlexCP>
			<S.WrapperSCP>
				<WrapperMedicalReportEditorCP appearance={{ noBorder: true }}>
					<WrapperMedicalReportEditorContentAreaICP hideToolbar={true}>
						<WrapperSCP>
							<div className="tiptap" dangerouslySetInnerHTML={{ __html: purifiedHTML }} />
						</WrapperSCP>
					</WrapperMedicalReportEditorContentAreaICP>
				</WrapperMedicalReportEditorCP>
			</S.WrapperSCP>
		</FlexCP>
	)
}
