import { IMedicalTimetableRecord } from 'modules/medical-timetable/components/tables-medical-timetable/types/IMedicalTimetableRecord'
import { ReactNode } from 'react'
import { MedicalTimetableIdLabelEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableIdEnum'
import { MedicalTimetablePeriodLabelEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetablePeriodEnum'
import { ExamModalityLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ExamSpecialtyEnum, ExamSpecialtyLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamSpecialtyEnum'
import { ExamUrgencyTypeLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'

type RenderColumnTP = {
	children: ReactNode
	props: { rowSpan: number }
}

export class TablesMedicalTimetableUtils {
	/**
	 */
	static renderIdColumn(record: IMedicalTimetableRecord, index: number, allRecords: IMedicalTimetableRecord[]): RenderColumnTP {
		const totalSameId = allRecords.filter((st) => st.id === record.id).length

		if (index === 0 || record.id !== allRecords[index - 1].id) {
			return {
				children: MedicalTimetableIdLabelEnum[record.id],
				props: { rowSpan: totalSameId },
			}
		}

		return {
			children: MedicalTimetableIdLabelEnum[record.id],
			props: { rowSpan: 0 },
		}
	}

	/**
	 */
	static renderPeriodColumn(record: IMedicalTimetableRecord, index: number, allRecords: IMedicalTimetableRecord[]): RenderColumnTP {
		const totalSamePeriod = allRecords.filter((st) => st.id === record.id && st.period === record.period).length

		if (index === 0 || record.id !== allRecords[index - 1].id || record.period !== allRecords[index - 1].period) {
			return {
				children: MedicalTimetablePeriodLabelEnum[record.period],
				props: { rowSpan: totalSamePeriod },
			}
		}

		return {
			children: MedicalTimetablePeriodLabelEnum[record.period],
			props: { rowSpan: 0 },
		}
	}

	/**
	 */
	static renderModalityColumn(record: IMedicalTimetableRecord, index: number, allRecords: IMedicalTimetableRecord[]): RenderColumnTP {
		const totalSameModality = allRecords.filter((st) => st.id === record.id && st.period === record.period && st.modality === record.modality).length
		if (
			index === 0 ||
			record.id !== allRecords[index - 1].id ||
			record.modality !== allRecords[index - 1].modality ||
			record.period !== allRecords[index - 1].period
		) {
			return {
				children: ExamModalityLabelEnum[record.modality],
				props: { rowSpan: totalSameModality },
			}
		}

		return {
			children: ExamModalityLabelEnum[record.modality],
			props: { rowSpan: 0 },
		}
	}

	/**
	 */
	static renderUrgencyColumn(record: IMedicalTimetableRecord, index: number, allRecords: IMedicalTimetableRecord[]): RenderColumnTP {
		const correctLabel = !!ExamSpecialtyEnum[record.groupBy] ? ExamSpecialtyLabelEnum[record.groupBy] : ExamUrgencyTypeLabelEnum[record.groupBy]

		const totalSameGroupBy = allRecords.filter(
			(st) => st.id === record.id && st.period === record.period && st.modality === record.modality && st.groupBy === record.groupBy,
		).length

		if (
			index === 0 ||
			record.id !== allRecords[index - 1].id ||
			record.groupBy !== allRecords[index - 1].groupBy ||
			record.modality !== allRecords[index - 1].modality ||
			record.period !== allRecords[index - 1].period
		) {
			return {
				children: correctLabel,
				props: { rowSpan: totalSameGroupBy },
			}
		}

		return {
			children: correctLabel,
			props: { rowSpan: 0 },
		}
	}
}
