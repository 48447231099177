import { PhraseIconCP } from '_old/main/common/components/icons/PhraseIconCP'
import { TemplateIconCP } from '_old/main/common/components/icons/TemplateIconCP'
import { useAppSelector } from 'app/redux/hook'
import { FormTemplateCP } from 'modules/template/components/form-template/FormTemplateCP'
import { HeaderTemplateCP } from 'modules/template/screens/screen-templates/header/HeaderTemplateCP'
import { SiderTemplateCP } from 'modules/template/screens/screen-templates/sider/SiderTemplateCP'
import { useEffect, useState } from 'react'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { TemplateTypeEnum, TemplateTypeLabelEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { WelcomeContentCP } from 'submodules/nerit-framework-ui/common/components/welcome-content/WelcomeContentCP'

/**
 * Tela principal do módulo de templates.
 */
export function ScreenTemplates(): JSX.Element {
	const [viewModel, setViewModel] = useState<'welcome' | 'form'>('welcome')

	const [type, setType] = useState<TemplateTypeEnum>(TemplateTypeEnum.TEMPLATE)
	useEffect(() => setShouldLoadList(TableUtils.getReloadNumber()), [type])

	const [selectedTemplate, setSelectedTemplate] = useState<TemplateResponseDTO>()
	const [shouldLoadList, setShouldLoadList] = useState<number>(0)

	const loggedUser = useAppSelector((state) => state.auth.user)

	function onSave(): void {
		setViewModel('welcome')
		setSelectedTemplate(undefined)
		setShouldLoadList(TableUtils.getReloadNumber())
	}

	return (
		<LayoutCP
			titleBrowser={'Templates de Laudo'}
			header={
				<HeaderTemplateCP
					type={type}
					onChangeType={(selectedType) => {
						setType(selectedType)
						setViewModel('welcome')
					}}
					onAdd={() => {
						setViewModel('form')
						setSelectedTemplate(undefined)
					}}
				/>
			}
			sider={
				<SiderTemplateCP
					reload={shouldLoadList}
					type={type}
					currentTemplate={selectedTemplate}
					onTemplateSelectionChange={(template) => {
						setSelectedTemplate(template)
						setViewModel('form')
					}}
					onDelete={() => setViewModel('welcome')}
				/>
			}
			content={
				<ContentCP overflowVertical={true}>
					{viewModel === 'welcome' && (
						<BasicStyleWrapperCP margin={{ top: 200 }}>
							<WelcomeContentCP
								message={TemplateTypeLabelEnum[type]}
								icon={type === TemplateTypeEnum.PHRASE ? <PhraseIconCP size={80} /> : <TemplateIconCP size={80} />}
							/>
						</BasicStyleWrapperCP>
					)}

					{viewModel === 'form' && (
						<FormTemplateCP
							data={{
								template: selectedTemplate,
								initialValues: {
									owner: {
										doctorCode: loggedUser?.code,
									},
								},
							}}
							type={type}
							onSave={onSave}
							appearance={{
								wrapBasicDataOnCard: true,
							}}
						/>
					)}
				</ContentCP>
			}
		/>
	)
}
