import { IAuthState } from 'app/redux/slices/auth/AuthSlice'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { TagClinicCategoryCP } from 'modules/clinic/components/tag-clinic-category/TagClinicCategoryCP'
import { ButtonClinicDoctorGroupAnswerInviteCP } from 'modules/doctor-groups/components/clinic-doctor-groups/button-clinic-doctor-group-answer-invite/ButtonClinicDoctorGroupAnswerInviteCP'
import { TagClinicDoctorGroupStatusCP } from 'modules/doctor-groups/components/clinic-doctor-groups/tag-clinic-doctor-group-status/TagClinicDoctorGroupStatusCP'
import { TagsTeamsCP } from 'modules/doctor-groups/components/teams/tag-team/TagsTeamsCP'
import { TagsExamModalitiesCP } from 'modules/exams/components/exams/tags-exam-modalities/TagsExamModalitiesCP'
import { ClinicDoctorGroupListItemResponseDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/responses/ClinicDoctorGroupListItemResponseDTO'
import { ClinicDoctorGroupStatusEnum } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/enums/ClinicDoctorGroupStatusEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ITableColumn } from 'submodules/nerit-framework-ui/common/components/table/types/ITableColumn'
import { TextAndTitleCP } from 'submodules/nerit-framework-ui/common/components/text/TextAndTitleCP'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

export const TableClinicsDoctorGroupsUtils = {
	getColumns(
		loggedUser: IAuthState['user'],
		onEdit: (record: ClinicDoctorGroupListItemResponseDTO) => void,
		// openPriceList: (record: ClinicDoctorGroupListItemResponseDTO) => void,
		onChangeData: () => void,
		doctorGroupCode: number,
	): Array<ITableColumn<ClinicDoctorGroupListItemResponseDTO>> {
		return [
			{
				render: (text, item) => <TagClinicDoctorGroupStatusCP status={item.status} />,
			},
			{
				title: 'Nome',
				render: (text, item) => item.clinic.name,
			},
			{
				render: (text, item) => <TagClinicCategoryCP category={item.category} />,
			},
			{
				title: 'Modalidades',
				render: (text, item) => <TagsExamModalitiesCP modalities={item.modalities} />,
			},
			{
				title: 'Médicos',
				render: (text, item) => (
					<>
						{!ArrayUtils.isEmpty(item.allocationDetails?.teams) && (
							<TextAndTitleCP title={'Times: '} text={<TagsTeamsCP teams={item.allocationDetails?.teams} />} />
						)}
						{!ArrayUtils.isEmpty(item.allocationDetails?.inDoctors) && (
							<TextAndTitleCP
								title={'Médicos Permitidos: '}
								text={item.allocationDetails?.inDoctors?.map((doctor) => StringUtils.getFirstName(doctor.name))?.join(', ')}
							/>
						)}
						{!ArrayUtils.isEmpty(item.allocationDetails?.notInDoctors) && (
							<TextAndTitleCP
								title={'Médicos Bloqueados: '}
								text={item.allocationDetails?.notInDoctors?.map((doctor) => StringUtils.getFirstName(doctor.name))?.join(', ')}
							/>
						)}
					</>
				),
			},
			{
				hide: !DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(loggedUser, doctorGroupCode, ['isDoctorGroupAdmin']),
				render: (text, item) => (
					<>
						<FlexCP justify={'flex-end'}>
							{item.status === ClinicDoctorGroupStatusEnum.AWAITING_DOCTOR_GROUP && (
								<ButtonClinicDoctorGroupAnswerInviteCP clinicDoctorGroupCode={item.code} onSave={onChangeData} />
							)}
							<ButtonCP icon={'edit'} onClick={() => onEdit(item)} size={'small'} marginLeft={5} />
							{/* <ButtonCP
								icon={'dollar'}
								onClick={() => openPriceList(item)}
								size={'small'}
								marginLeft={5}
							/> */}
						</FlexCP>
					</>
				),
			},
		]
	},
}
