import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'

import { DateTimeFormatEnum } from '_old/main/common/enums/DateFormatEnum'
import { DateUtils } from '_old/main/common/utils/DateUtils'
import { ButtonEditDoctorDataCP } from 'modules/person/components/doctor/button-edit-doctor-data/ButtonEditDoctorDataCP'
import { PeopleRequests } from 'submodules/beerads-sdk/services/people/people/PeopleRequests'
import { PersonResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PersonResponseDTO'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { FlexOneICP } from 'submodules/nerit-framework-ui/common/components/flex/inner/FlexOneICP'
import { TextAndTitleCP } from 'submodules/nerit-framework-ui/common/components/text/TextAndTitleCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { UploaderImageFromApiCP } from 'submodules/nerit-framework-ui/common/components/uploader/uploader-image-from-api/UploaderImageFromApiCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'

interface ICPProps {
	person: PersonResponseDTO
	onClickChangePassword: () => void
	onChangeData: () => void
}

/**
 * Componente para exibir dados do paciente na aba de exames do drawer.
 */
export function CardProfileDoctorDataCP(props: ICPProps): JSX.Element {
	return (
		<CardCP>
			<div className="flex items-center">
				<div className="pb-7 pr-7">
					<UploaderImageFromApiCP
						currentData={{
							code: props.person.code,
							imgUrl: props.person.profilePicture,
						}}
						type={'avatar'}
						requestApiConfig={{
							requestApi: PeopleRequests.changeProfilePicture,
						}}
					/>
				</div>

				<div className="flex w-full flex-col justify-center leading-7">
					<FlexCP justify={'space-between'}>
						<div className={'custom-label'}>
							<TitleCP underLine={true} marginBottom={30}>
								{`${props.person.doctorData?.treatment ?? ''} ${props.person.name}`}
							</TitleCP>
						</div>

						<ButtonGroupCP mode={'separeted'}>
							<ButtonEditDoctorDataCP onChangeData={props.onChangeData} doctor={props.person} />
							<ButtonCP onClick={props.onClickChangePassword} icon={'key'}>
								Alterar Senha
							</ButtonCP>
						</ButtonGroupCP>
					</FlexCP>

					<FlexCP>
						<FlexOneICP>
							<TextAndTitleCP icon={'user'} title={'CPF:'} text={MaskUtils.applyMask2(InputMaskTypeEnum.CPF, props.person.cpf)} />
							<TextAndTitleCP icon={'mail'} title={'Email:'} text={props.person.email} />
							<TextAndTitleCP
								icon={'idcard'}
								title={`${props.person.doctorData?.ids?.[0].type ?? 'Sem tipo de registro'}:`}
								text={props.person.doctorData?.ids?.[0].id}
							/>
						</FlexOneICP>
						<FlexOneICP>
							<TextAndTitleCP icon={'phone'} title={'Celular:'} text={MaskUtils.applyMask2(InputMaskTypeEnum.PHONE, props.person.phone)} />
							<TextAndTitleCP
								icon={'calendar'}
								title={'Data de Nascimento:'}
								text={
									!!props.person.birthDate
										? DateUtils.transformDateStrFormat(
												props.person.birthDate,
												DateTimeFormatEnum.EUA_WITHOUT_TIME,
												DateTimeFormatEnum.BR_WITHOUT_TIME,
											)
										: undefined
								}
							/>
							<TextAndTitleCP icon={'pushpin'} title={'Especialidade:'} text={props.person.doctorData?.specialty} />
						</FlexOneICP>
					</FlexCP>
				</div>
			</div>
			<div className="flex flex-col gap-3">
				<TextCP
					text={'* Ao realizar o upload de minha foto, autorizo o uso de minha imagem na identificação do médico no sistema da VX.'}
					fontStyle="italic"
					size="small"
					color={'#C2C2C2'}
				/>
				<TextCP
					text={
						'Esta autorização é de forma gratuita e pelo tempo em que for necessária para atingir a sua finalidade, abrangendo o uso de imagem e qualquer outra informação disponibilizada durante a coleta do conteúdo.'
					}
					fontStyle="italic"
					size="small"
					color={'#C2C2C2'}
				/>
			</div>
		</CardCP>
	)
}
