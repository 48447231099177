import React from 'react'
import { MedicalTimetableAbsenceFormModel } from 'modules/medical-timetable/components/modals/modal-medical-timetable-absence/inner/MedicalTimetableAbsenceFormModel'
import { DateRangePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/DateRangePickerCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import styled from 'styled-components'
import { SelectMedicalTimetableAbsenceCP } from 'modules/medical-timetable/components/select-medical-timetable-reason-absence/SelectMedicalTimetableAbsenceCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'

interface ICProps {
	isUserAddingAbsenceOnMedicalTimetable?: boolean
	formStateManager: IFormStateManager<MedicalTimetableAbsenceFormModel>
}

export function FormMedicalTimetableAbsenceCP(props: ICProps): JSX.Element {
	return (
		<>
			<WrapperSCP>
				<RowCP align="middle" flex>
					<ColumnCP size={10}>
						<SelectMedicalTimetableAbsenceCP
							fieldName="reasonAbsence"
							formStateManager={props.formStateManager}
							isUserAddingAbsenceOnMedicalTimetable={props.isUserAddingAbsenceOnMedicalTimetable}
						/>
					</ColumnCP>
					<ColumnCP size={14}>
						{props.formStateManager.getFieldValue('type') === 'spot' ? (
							<InputCP
								type="date"
								label={'Selecione o dia'}
								formStateManager={props.formStateManager}
								fieldName={'dateRange'}
								required={true}
							/>
						) : (
							<DateRangePickerCP
								showBorder={true}
								marginTop={15}
								fastFilter={'all'}
								formStateManager={props.formStateManager}
								fieldName={'dateRange'}
							/>
						)}
					</ColumnCP>
				</RowCP>
				<TextAreaCP
					placeholder={'Escreva os detalhes do motivo da ausência (opcional)'}
					formStateManager={props.formStateManager}
					fieldName={'description'}
					required={false}
					minRows={4}
					hint={{ text: 'Campo opcional, use apenas para casos de ausência', type: 'tooltip' }}
				/>
			</WrapperSCP>
		</>
	)
}

const WrapperSCP = styled.div`
	margin-top: 10px;
`
