import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { ButtonToggleUserDoctorGroupStatusCP } from 'modules/doctor-groups/components/button-toggle-user-doctor-group-status/ButtonToggleUserDoctorGroupStatusCP'
import { ModalUserChangeImagesCP } from 'modules/person/components/common/modal-user-change-images/ModalUserChangeImagesCP'
import { ButtonEditDoctorDataCP } from 'modules/person/components/doctor/button-edit-doctor-data/ButtonEditDoctorDataCP'
import { useState } from 'react'
import { DoctorGroupPersonResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/responses/DoctorGroupPersonResponseDTO'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'

interface ICPProps {
	role: UserTypeEnum
	record: DoctorGroupPersonResponseDTO
	onEdit: () => void
	onChangeData: () => void
}

/**
 */
export function ColumnTableDoctorGroupActionsCP(props: ICPProps): JSX.Element {
	const [isUserImagesModalVisible, setIsUserImagesModalVisible] = useState<boolean>(false)

	return (
		<AccessControlCP
			permission={{ byHasAnyPermissionInDoctorGroup: { doctorGroupCode: props.record.doctorGroupCode, permissions: ['isDoctorGroupAdmin'] } }}
		>
			<ButtonGroupCP mode={'separeted'}>
				<ButtonCP size={'small'} icon={'picture'} onClick={() => setIsUserImagesModalVisible(true)} />

				{props.role === UserTypeEnum.DOCTOR && (
					<>
						<ButtonEditDoctorDataCP
							doctor={props.record.person}
							extraDoctorData={props.record.extraDoctorData}
							onChangeData={props.onChangeData}
							fieldConfig={{
								showHasDigitalCertificate: true,
							}}
							appearance={{
								type: 'default',
								size: 'small',
								icon: 'idcard',
								hideText: true,
							}}
						/>

						<ButtonCP icon={'edit'} size={'small'} type={'primary'} onClick={props.onEdit} />

						<ButtonToggleUserDoctorGroupStatusCP
							doctorGroupCode={props.record.code}
							status={props.record.status}
							onSave={props.onChangeData}
						/>
					</>
				)}
			</ButtonGroupCP>

			<ModalUserChangeImagesCP visible={isUserImagesModalVisible} onClose={() => setIsUserImagesModalVisible(false)} user={props.record.person} />
		</AccessControlCP>
	)
}
