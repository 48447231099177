import { AppDispatch } from '@/app/redux/store'
import { PublicRouter } from '@/app/routers/public/PublicRouter'
import { NotificationHelper } from '@/submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { HttpStatusEnum } from '@/submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpStatusEnum'
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

interface IHandleErrorProps {
	error: Error
	fallbackMessage?: unknown
	dispatch: ThunkDispatch<AppDispatch, undefined, UnknownAction>
	history: ReturnType<typeof useHistory>
}

export function handleGlobalErrors({ error, fallbackMessage, dispatch, history }: IHandleErrorProps): void {
	if (axios.isAxiosError(error)) {
		if (error.response?.status === HttpStatusEnum.UNAUTHORIZED) {
			NotificationHelper.info('Sessão Expirada!', 'Sua sessão expirou. Faça login novamente para prosseguir', 6)

			dispatch(() => ({ type: 'auth/logout' }))

			history.push(PublicRouter.USER_LOGIN)
			return
		}

		if (error.response?.data?.data?.errors) {
			NotificationHelper.error('Erro!', error.response.data.data.errors)
			return
		}
	}

	if (fallbackMessage && typeof fallbackMessage === 'string') {
		NotificationHelper.error('Erro', fallbackMessage)
		return
	}

	NotificationHelper.error('Erro', 'Ocorreu um erro inesperado')
}
