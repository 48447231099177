import { ThemeConfig } from 'antd'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

export const themeConfig: ThemeConfig = {
	token: {
		colorPrimary: ThemeProject.primary,
		colorInfo: ThemeProject.primary,
		colorSuccess: ThemeProject.success,
		colorWarning: ThemeProject.warning,
		colorErrorBg: `${ThemeProject.error}30`,
		colorError: ThemeProject.error,
		fontSize: 14,
		fontFamily: 'Montserrat, sans-serif',
		colorText: ThemeFrameworkCommon.browserDefaultBackgroundDark,
		controlOutline: '0',
		controlOutlineWidth: 0,
		borderRadius: 4,
		colorBgElevated: ThemeFrameworkCommon.browserDefaultBackgroundLight,
		colorBgContainer: ThemeFrameworkCommon.browserDefaultBackgroundLight,
		colorTextDescription: `${ThemeFrameworkCommon.browserDefaultBackgroundDark}90`,
		colorIcon: `${ThemeFrameworkCommon.browserDefaultBackgroundDark}90`,
	},
	hashed: false,
	components: {
		Table: {
			bodySortBg: 'none',
			rowHoverBg: 'none',
			borderColor: `${ThemeFrameworkCommon.browserDefaultColorDark}30`,
			headerSortHoverBg: `${ThemeFrameworkCommon.browserDefaultBackgroundLight}90`,
			headerSplitColor: 'none',
		},
		Button: {
			defaultColor: ThemeFrameworkCommon.black,
			defaultBg: ThemeFrameworkCommon.white,
			defaultHoverBg: `${ThemeFrameworkCommon.white}`,
		},
		Select: {
			optionActiveBg: `${ThemeFrameworkCommon.browserDefaultBackgroundDark}10`,
			multipleItemBg: `${ThemeFrameworkCommon.browserDefaultBackgroundDark}0a`,
			optionSelectedBg: `${ThemeFrameworkCommon.browserDefaultBackgroundDark}0a`,
			optionSelectedColor: ThemeFrameworkCommon.browserDefaultBackgroundDark,
		},
		Layout: {
			bodyBg: ThemeFrameworkCommon.browserDefaultBackgroundLight,
		},
		Menu: {
			colorSplit: 'none',
			itemColor: ThemeFrameworkCommon.browserDefaultBackgroundLight,
			itemSelectedColor: ThemeFrameworkCommon.white,
		},
		Alert: {},
		Radio: {
			buttonBg: ThemeFrameworkCommon.white,
		},
	},
}
