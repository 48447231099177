import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { WelcomeContentSelectDoctorGroupCP } from 'app/components/welcome-content-select-doctor-group/WelcomeContentSelectDoctorGroupCP'
import { useAppSelector } from 'app/redux/hook'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { ButtonMedicalTimetableAbscenceCP } from 'modules/medical-timetable/components/buttons/button-medical-timetable-absence/ButtonMedicalTimetableAbscenceCP'
import { SiderMedicalTimetableFiltersCP } from 'modules/medical-timetable/components/sider-medical-timetable-filters/SiderMedicalTimetableFiltersCP'
import { MedicalTimetableFilterFormModel } from 'modules/medical-timetable/components/sider-medical-timetable-filters/inner/MedicalTimetableFilterFormModel'
import { TableMedicalTimetableDoctorCP } from 'modules/medical-timetable/components/tables-medical-timetable/table-medical-timetable-doctor/TableMedicalTimetableDoctorCP'
import { TitleMedicalTimetableCP } from 'modules/medical-timetable/components/title-medical-timetable/TitleMedicalTimetableCP'
import { useEffect, useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'

/**
 */
export function ScreenMedicalTimetable(): JSX.Element {
	const [formModel, setFormModel] = useState<MedicalTimetableFilterFormModel>()
	const formStateManager = useFormStateManager<MedicalTimetableFilterFormModel>(formModel)

	const currentDoctorGroup = useAppSelector((state) => state.doctorGroup)

	const [reload, setReload] = useState<number>()

	useEffect(init, [])

	/**
	 */
	function init(): void {
		const initialData = {
			beginDate: DateUtils.getFirstAndLastDayOfWeek().beginDate,
			endDate: DateUtils.getFirstAndLastDayOfWeek().endDate,
		}
		setFormModel(new MedicalTimetableFilterFormModel(initialData))
	}

	if (!formStateManager.getFormValues()) return <LoadingOverlayCP show={true} />

	if (!currentDoctorGroup.code) return <WelcomeContentSelectDoctorGroupCP />

	return (
		<AccessControlCP
			permission={{
				byHasAnyPermissionInDoctorGroup: {
					doctorGroupCode: currentDoctorGroup.code,
					permissions: ['isDoctorGroupAdmin', 'isDoctorGroupUser'],
				},
			}}
		>
			<LayoutCP
				titleBrowser={'Escala Médica'}
				header={
					<HeaderCP title={'Escala Médica'}>
						<ButtonMedicalTimetableAbscenceCP onSave={() => setReload(TableUtils.getReloadNumber())} />
						<ButtonCP icon={'setting'} onClick={() => NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.MEDICAL_TIMETABLE_CONFIG)}>
							Configurar
						</ButtonCP>
					</HeaderCP>
				}
				sider={
					<SiderMedicalTimetableFiltersCP
						filterFormStateManager={formStateManager}
						onChangeFilters={() => setReload(TableUtils.getReloadNumber())}
					/>
				}
				content={
					<ContentCP overflowVertical={true}>
						<TitleMedicalTimetableCP
							filterFormStateManager={formStateManager}
							onChangeFilters={() => setReload(TableUtils.getReloadNumber())}
							appearance={{ hideTimeFrame: true }}
						/>
						<TableMedicalTimetableDoctorCP reload={reload} filterFormStateManager={formStateManager} />
					</ContentCP>
				}
			/>
		</AccessControlCP>
	)
}
