import { useAppSelector } from 'app/redux/hook'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import { SubtitleTableExamsCP } from 'modules/exams/components/exams/subtitle-table-exams/SubtitleTableExamsCP'
import { BulkActionsBarICP } from 'modules/exams/components/exams/table-exams/bulk-actions-bar/BulkActionsBarICP'
import { ExamFilterFormModel } from 'modules/exams/components/exams/table-exams/filters/ExamFilterFormModel'
import { ExamFiltersFormModelConverter } from 'modules/exams/components/exams/table-exams/filters/ExamFiltersFormModelConverter'
import { IExamCodeId } from 'modules/exams/components/exams/table-exams/inner/IExamCodeId'
import { TableExamsUtils } from 'modules/exams/components/exams/table-exams/inner/TableExamsUtils'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ExamListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { ExamUrgencyTypeEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { ExamsRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	reloadTable: number
	examStatusGroup?: ExamStatusGroupEnum
	onLoading?: (isLoading: boolean) => void
	filterStateManager?: IFormStateManager<ExamFilterFormModel>
	onChangeAnyData?: () => void
	appearance?: {
		title?: string
	}
}

/**
 */
export function TableExamsCP(props: ICPProps): JSX.Element {
	const loggedUser = useAppSelector((state) => state.auth.user)
	const [selectedExamCodes, setSelectedExamCodes] = useState<number[]>([])
	const [selectedExamIds, setSelectedExamIds] = useState<string[]>([])

	const [allExamCodeIds, setAllExamCodeIds] = useState<IExamCodeId[]>([])

	const [keepPage, setKeepPage] = useState<boolean>()
	const [reload, setReload] = useState<number>(props.reloadTable)
	useEffect(onReloadTableByFilters, [props.reloadTable])

	/**
	 */
	function onReloadTableByFilters(): void {
		setKeepPage(false)
		setReload(props.reloadTable)
		setSelectedExamCodes([])
		setSelectedExamIds([])
	}

	/**
	 */
	function onChangeAnyDataOnTable(): void {
		setKeepPage(true)
		setReload(TableUtils.getReloadNumber())

		if (!!props.onChangeAnyData) props.onChangeAnyData()
	}

	return (
		<WrapperSCP>
			<BulkActionsBarICP
				doctorGroupCode={props.filterStateManager?.getFieldValue('doctorGroupCode')}
				selectedExamCodes={selectedExamCodes}
				selectedExamIds={selectedExamIds}
				viewAsDoctor={props.filterStateManager?.getFieldValue('viewAsDoctor')}
				onSave={() => {
					setSelectedExamCodes([])
					setSelectedExamIds([])
					setReload(TableUtils.getReloadNumber())
				}}
			/>

			<TableFromApiCP<ExamListItemResponseDTO, ExamSearchRequestDTO>
				appearance={{
					showReloadButton: true,
					reloadButtonSize: 'default',
					wrappedOnCard: true,
					recordLabel: 'exames',
					showTotalOnHeader: true,
					title: props.appearance?.title ?? '',
				}}
				onLoad={(loadedExamCodeIds) => {
					setAllExamCodeIds(
						loadedExamCodeIds.map((allExams) => ({
							code: allExams.code,
							id: allExams.id,
						})),
					)
				}}
				shouldLoadData={reload}
				keepCurrentPage={keepPage}
				onLoading={props.onLoading}
				apiConfig={{
					hasPagination: true,
					pageSize: 50,
					filters: ExamFiltersFormModelConverter.formatDto(props.filterStateManager?.getFormValues()),
					requestConfigTP: (filters) => ExamsRequests.search(filters!),
				}}
				row={{
					setClass: (row) => row.urgentType ?? '',
					onSelect: {
						rowKey: 'code',
						selectedCodes: selectedExamCodes,
						onSelectRow: (codes) => {
							setSelectedExamCodes(codes)
							const selectedCodeIds = allExamCodeIds.filter((examCodeIds) => codes.includes(examCodeIds.code))
							setSelectedExamIds(selectedCodeIds.map((codeId) => codeId.id))
						},
					},
				}}
				columns={TableExamsUtils.getColumns(loggedUser, onChangeAnyDataOnTable, props.examStatusGroup, props.filterStateManager)}
				sorter={{
					initialSorter: props.filterStateManager?.getFieldValue('sorter'),
					onChangeSorter: (sorter) => props.filterStateManager?.changeFieldValue('sorter', sorter),
				}}
			/>

			{props.examStatusGroup !== ExamStatusGroupEnum.PENDING && <SubtitleTableExamsCP />}
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	position: relative;

	.ant-table-placeholder {
		background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
		color: ${ThemeFrameworkCommon.browserDefaultColorDark};
	}

	.ant-table-row.${ExamUrgencyTypeEnum.URGENT} {
		background-color: ${ThemeProjectCommon.examUrgentTypeColor[ExamUrgencyTypeEnum.URGENT]};
	}

	.ant-table-row.${ExamUrgencyTypeEnum.AVC} {
		background-color: ${ThemeProjectCommon.examUrgentTypeColor[ExamUrgencyTypeEnum.AVC]};
	}

	.ant-table-row.${ExamUrgencyTypeEnum.HOSPITALIZATION} {
		background-color: ${ThemeProjectCommon.examUrgentTypeColor[ExamUrgencyTypeEnum.HOSPITALIZATION]};
	}

	.ant-table-row.${ExamUrgencyTypeEnum.REGULAR} {
		background-color: ${ThemeProjectCommon.examUrgentTypeColor[ExamUrgencyTypeEnum.REGULAR]};
	}
`
