import { useTipTapContext } from '@/submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/context/useTipTapContext'
import { useCurrentEditor } from '@tiptap/react'
import { ReactElement, useEffect, useRef } from 'react'
import { RiMicFill, RiPauseFill } from 'react-icons/ri'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ToolbarTipTapButtonICP } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/toolbar-tip-tap/toolbar-tip-tap-button/ToolbarTipTapButtonICP'

type WindowTP = globalThis.Window &
	typeof globalThis & {
		SpeechRecognition: SpeechRecognition
		webkitSpeechRecognition: SpeechRecognition
	}

export function VoiceRecorderBrowserTipTapButtonICP(): ReactElement {
	const { editor } = useCurrentEditor()
	const { isCapturing, onChangeCapturing } = useTipTapContext()
	const recognitionRef = useRef<SpeechRecognition | null>(null)

	useEffect(() => {
		if (!editor) {
			return
		}

		const SpeechRecognition = (window as WindowTP).SpeechRecognition || (window as WindowTP).webkitSpeechRecognition

		if (!SpeechRecognition) {
			NotificationHelper.warning('Alerta', 'Seu navegador não suporta esta funcionalidade.')
			return
		}

		if (!recognitionRef.current) {
			const recognitionInstance = new SpeechRecognition()
			recognitionInstance.continuous = true
			recognitionInstance.interimResults = false
			recognitionInstance.lang = 'pt-BR'
			recognitionInstance.maxAlternatives = 1

			recognitionInstance.onstart = () => {
				onChangeCapturing(true)
			}

			recognitionInstance.onresult = (event) => {
				let finalTranscript = ''
				for (let i = event.resultIndex; i < event.results.length; i++) {
					if (event.results[i].isFinal) {
						finalTranscript += event.results[i][0].transcript
					}
				}

				editor?.commands.deleteSelection()

				finalTranscript = finalTranscript.replace(/v[iíÍ]rgula/gi, ',')
				finalTranscript = finalTranscript.replace(/ponto final/gi, '.')
				finalTranscript = finalTranscript.replace(/ponto/gi, '.')

				const newLineRegex = new RegExp('parágrafo', 'gi')
				const hasNewLine = !!finalTranscript.match(newLineRegex)

				if (hasNewLine) {
					finalTranscript = finalTranscript.replace(newLineRegex, '')
				}

				const isStartingWithSign = !!finalTranscript.match(/^[.,](.*)/)
				if (!isStartingWithSign) {
					finalTranscript = `${finalTranscript.charAt(0).toUpperCase()}${finalTranscript.substring(1, finalTranscript.length)}`
				}

				editor?.commands.insertContent(finalTranscript)
			}

			recognitionRef.current = recognitionInstance
		}
	}, [])

	function startListening(): void {
		if (recognitionRef.current) {
			recognitionRef.current.start()
		}
	}

	function stopListening(): void {
		if (recognitionRef.current) {
			recognitionRef.current.stop()
			onChangeCapturing(false)
		}
	}

	return (
		<ToolbarTipTapButtonICP
			Icon={isCapturing ? RiPauseFill : RiMicFill}
			onClick={isCapturing ? stopListening : startListening}
			className={isCapturing ? 'is-recording' : ''}
		/>
	)
}
