import { useAppDispatch } from '@/app/redux/hook'
import { handleGlobalErrors } from '@/providers/react-query-provider/utils'
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PropsWithChildren, useState } from 'react'
import { useHistory } from 'react-router-dom'

export function ReactQueryProvider({ children }: PropsWithChildren): JSX.Element {
	const dispatch = useAppDispatch()
	const history = useHistory()

	const [queryClient] = useState(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						refetchOnWindowFocus: false,
					},
				},
				queryCache: new QueryCache({
					onError: (error, query) => {
						handleGlobalErrors({ dispatch, history, error, fallbackMessage: query.meta?.fallbackErrorMessage })
					},
				}),
				mutationCache: new MutationCache({
					onError: (error) => handleGlobalErrors({ dispatch, error, history }),
				}),
			}),
	)

	return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
