import { NeritFrameworkProjectConfig } from '@/config/NeritFrameworkProjectConfig'
import { setOnBehalfOfHeader } from '@/services/http/http'
import { PrivateAccessVerifierCP } from 'app/components/private-access-verifier/PrivateAccessVerifierCP'
import { PrivatePatientPortalAccessVerifierCP } from 'app/components/private-patient-portal-access-verifier/PrivatePatientPortalAccessVerifierCP'
import { PrivatePatientPortalRouter } from 'app/routers/private/PrivatePatientPortalRouter'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import { AppUtils } from 'app/utils/AppUtils'
import { RoutingHelper } from 'config/RoutingHelper'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
	ProjectWhiteLabelDomainsEnum,
	ProjectWhiteLabelPatientPortalDomainsEnum,
	ProjectWhiteLabelsEnum,
} from 'submodules/beerads-sdk/common/ProjectWhiteLabelsEnum'
import { RouterSwitchCP } from 'submodules/nerit-framework-ui/common/router/components/router-switch/RouterSwitchCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { RequestHelper } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'
import { AuthUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/utils/AuthUtils'

/**
 * Router principal da aplicacao
 *
 * 1. Instancia o Routing HELPER, para ser usado na aplicacao
 * 2. Valida se a ROTA possui os dominios WL
 * 3. Renderiza as ROTAS
 *  3.1 Publica, caso seja rota publica
 *  3.2 Caso nao seja rota publica, entrar no AccessVerifier para validar se esta logao
 */
export function AppRouter(): JSX.Element {
	const routeHistory = useHistory()
	useEffect(() => NeritFrameworkRoutingHelper.init(routeHistory), [routeHistory])

	// Valida se o dominio(whitelabel) é valido
	const isValidRoute = RoutingHelper.isValidSchema(AppUtils.getDomain())
	if (!isValidRoute) {
		let redirectTo
		const hostName = `https://${window.location.hostname}`

		// Faz os tratamentos abaixo pq a URL redirecionada é sempre o root do projeto, temos que jogar para url completa com o whitelabel correto
		// Se for ambiente PRD, faz o de-para dos dominios
		if (hostName === ProjectWhiteLabelDomainsEnum.VX) {
			redirectTo = `${ProjectWhiteLabelsEnum.VX}/${PublicRouter.USER_LOGIN}`
		} else if (hostName === ProjectWhiteLabelPatientPortalDomainsEnum.VX) {
			redirectTo = `${ProjectWhiteLabelsEnum.VX}/${PublicRouter.PATIENT_PORTAL_LOGIN}`
		} else if (hostName === ProjectWhiteLabelPatientPortalDomainsEnum.BEERADS) {
			redirectTo = `${ProjectWhiteLabelsEnum.BEERADS}/${PublicRouter.PATIENT_PORTAL_LOGIN}`
		} else {
			// Muda na força, trocando o dominio para o padrao do BEERADS
			redirectTo = `${ProjectWhiteLabelsEnum.BEERADS}/${PublicRouter.USER_LOGIN}`
		}

		window.location.pathname = redirectTo
		return <></>
	}

	RequestHelper.addDefaultHeaderConfig(AuthUtils.getOnBehalfHeaderConfig(AppUtils.getDomain() ?? ProjectWhiteLabelsEnum.BEERADS))
	RequestHelper.addDefaultHeaderConfig(AuthUtils.getAppVersion(NeritFrameworkProjectConfig.APP_VERSION))
	RequestHelper.addDefaultHeaderConfig(AuthUtils.getTypeBehalfTo())
	setOnBehalfOfHeader()

	return (
		<RouterSwitchCP
			routes={[
				{ path: PublicRouter.ROOT, component: PublicRouter },
				{ path: PrivatePatientPortalRouter.ROOT, component: PrivatePatientPortalAccessVerifierCP },
				{ path: '', component: PrivateAccessVerifierCP },
			]}
		/>
	)
}
