import { DoctorGroupDigitalCertificateDataFormModel } from 'modules/doctor-groups/components/doctor-groups/form-doctor-group-digital-certificate-data/inner/DoctorGrouṕDigitalCertificateDataFormModel'
import { DigitalCertificatePartner } from 'submodules/beerads-sdk/services/auth/enums/DigitalCertificatePartner'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
	digitalCertificateStateManager: IFormStateManager<DoctorGroupDigitalCertificateDataFormModel>
}

export function FormDoctorGroupCertificateData(props: ICPProps): JSX.Element {
	return (
		<RowCP>
			<ColumnCP size={18}>
				<RowCP spaceBetweenCols={4}>
					<ColumnCP size={10}>
						<RowCP>
							<SelectCP
								label={'Partner'}
								fieldName={'partner'}
								formStateManager={props.digitalCertificateStateManager}
								required={true}
								options={Object.values(DigitalCertificatePartner).map((option) => ({
									value: option,
									label: option,
								}))}
							/>
						</RowCP>
						<RowCP>
							<InputCP
								type={'text'}
								label={'Client ID'}
								required={true}
								fieldName={'clientId'}
								formStateManager={props.digitalCertificateStateManager}
							/>
						</RowCP>
					</ColumnCP>
					<ColumnCP size={10}>
						<RowCP>
							<InputCP
								type={'text'}
								label={'Endpoint'}
								required={true}
								fieldName={'endpoint'}
								formStateManager={props.digitalCertificateStateManager}
							/>
						</RowCP>
						<RowCP>
							<InputCP
								type={'text'}
								label={'Client Secret'}
								required={true}
								fieldName={'clientSecret'}
								formStateManager={props.digitalCertificateStateManager}
							/>
						</RowCP>
					</ColumnCP>
				</RowCP>
			</ColumnCP>
		</RowCP>
	)
}
