import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import * as S from 'modules/exams/components/exams/tag-exam-status/style'
import { ExamStatusEnum, ExamStatusLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'

interface ICPProps {
	examStatus: ExamStatusEnum
}

/**
 * Renderiza componente para exibir status do exame.
 */
export function TagExamStatusCP(props: ICPProps): JSX.Element {
	return (
		<S.WrapperSCP>
			<TagCP color={ThemeProjectCommon.examStatusColor[props.examStatus]} content={ExamStatusLabelEnum[props.examStatus]} />
		</S.WrapperSCP>
	)
}
