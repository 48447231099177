import { useAppSelector } from '@/app/redux/hook'
import { ExamResponseDTO } from '@/submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { JSONContent } from '@tiptap/react'
import * as S from 'modules/exams/components/medical-report/editor-medical-report/style'
import { useEffect, useState } from 'react'
import { TemplatesRequests } from 'submodules/beerads-sdk/services/templates/templates/TemplatesRequests'
import { TemplateSearchRequestDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/request/TemplateSearchRequestDTO'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { TemplateStatusEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateStatusEnum'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import { LoadingCP } from 'submodules/nerit-framework-ui/common/components/loading/basic/LoadingCP'
import { TipTapCP } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/TipTapCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps {
	exam?: ExamResponseDTO
	initialContent?: string
	onContentChange: (json: JSONContent, html: string) => void
	appearance?: {
		showDebugPanel?: boolean
		noBorder?: boolean
		hideAddPhraseButton?: boolean
	}
	forceReloadContent?: number
}

export interface IAutocompleteOption {
	name: string
	content: string
}

/**
 * Editor de texto do tipo WYSIWYG.
 */
export function EditorMedicalReportCP(props: ICPProps): JSX.Element {
	const [showEditor, setShowEditor] = useState<boolean>(false)
	const user = useAppSelector((store) => store.auth.user)

	const [loadedPhrases, setLoadedPhrases] = useState<IAutocompleteOption[]>([])
	const searchPhrasesRequest = useRequest<ListResponseDTO<TemplateResponseDTO>>()

	useEffect(onSearchPhrasesRequestChange, [searchPhrasesRequest.isAwaiting])

	useEffect(() => {
		init()
	}, [props.forceReloadContent])

	function init(): void {
		// Seta vazio para resetar o editor
		setLoadedPhrases([])

		setShowEditor(true)

		const dto: TemplateSearchRequestDTO = {
			type: TemplateTypeEnum.PHRASE,
			modalities: !!props.exam?.modality ? [props.exam.modality] : undefined,
			status: TemplateStatusEnum.ACTIVE,
			doctorCode: user?.code,
		}
		searchPhrasesRequest.runRequest(TemplatesRequests.search(dto))
	}

	/**
	 * Retorno da requisicao
	 */
	function onSearchPhrasesRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(searchPhrasesRequest)) return

		const result = searchPhrasesRequest.responseData!.list
		const phrases: IAutocompleteOption[] = result.map((phrase) => ({
			name: phrase.name,
			content: phrase.content as string,
		}))
		setLoadedPhrases(phrases ?? [])
	}

	if (!loadedPhrases) {
		return <LoadingCP margin={{ top: 80, bottom: 40 }} />
	}

	function handleEditorUpdate(json: JSONContent, html: string): void {
		props.onContentChange(json, html.replace(/<p(\s+[^>]*)?>\s*<\/p>/gm, '<p$1><br></p>'))
	}

	if (!showEditor) {
		return <></>
	}

	return (
		<div className="editor-wrapper relative">
			<S.EditorWrapper noBorder={props.appearance?.noBorder}>
				<TipTapCP exam={props.exam} content={props.initialContent} onContentChange={handleEditorUpdate} autoComplete={loadedPhrases} />
			</S.EditorWrapper>
		</div>
	)
}
