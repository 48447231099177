import { FormDoctorGroupDoctorCP } from 'modules/doctor-groups/components/people/form-doctor-group-doctor/FormDoctorGroupDoctorCP'
import { DoctorGroupDoctorFormModel } from 'modules/doctor-groups/components/people/form-doctor-group-doctor/inner/DoctorGroupDoctorFormModel'
import { useState } from 'react'
import { DoctorGroupDoctorSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/requests/DoctorGroupDoctorSaveRequestDTO'
import { PersonByCpfResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PersonByCpfResponseDTO'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextAndTitleCP } from 'submodules/nerit-framework-ui/common/components/text/TextAndTitleCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'

interface IUserSelectedInfoICPProps {
	person: PersonByCpfResponseDTO
	type: UserTypeEnum
	doctorGroupCode?: number
	onClickAdd: (doctorData?: DoctorGroupDoctorSaveRequestDTO) => void
	loading: boolean
}

/**
 * Componente para mostrar informações básicas do usuário selecionado.
 */
export function FindPersonResultICP(props: IUserSelectedInfoICPProps): JSX.Element {
	const [formModel] = useState<DoctorGroupDoctorFormModel>(new DoctorGroupDoctorFormModel())
	const formStateManager = useFormStateManager<DoctorGroupDoctorFormModel>(formModel)

	/**
	 * Ao adicionar uma pessoa
	 */
	async function onAdd(): Promise<void> {
		if (props.type === UserTypeEnum.USER) return props.onClickAdd()

		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) return

		const formValues = formStateManager.getFormValues()!
		props.onClickAdd({
			specialty: formValues.specialty,
			doctorLevel: formValues.doctorLevel,
			teamCodes: formValues.teamCodes,
			isAuditor: formValues.isAuditor,
			doctorDutyProgram: formValues.doctorDutyProgram,
			doctorRole: formValues.doctorRole,
		})
	}

	return (
		<>
			<AlertCP
				margin={{ bottom: 20 }}
				type={'success'}
				message={
					<>
						<TextAndTitleCP title={'Nome:'} text={props.person.name} />
						<TextAndTitleCP title={'Email:'} text={props.person.email} />
					</>
				}
			/>

			{props.type === UserTypeEnum.DOCTOR && !!props.doctorGroupCode && (
				<BasicStyleWrapperCP margin={{ bottom: 20 }}>
					<TitleCP underLine={true} textSize={'normal'} marginBottom={30}>
						Defina o perfil de atuação do médico no grupo
					</TitleCP>

					<FormDoctorGroupDoctorCP doctorGroupCode={props.doctorGroupCode} formStateManager={formStateManager} />
				</BasicStyleWrapperCP>
			)}

			<FlexCP justify={'flex-end'} margin={{ top: 20 }}>
				<ButtonCP type={'primary'} onClick={onAdd} loading={props.loading}>
					Adicionar
				</ButtonCP>
			</FlexCP>
		</>
	)
}
