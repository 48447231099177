import { VoiceRecorderTipTapButtonICP } from '@/submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/toolbar-tip-tap/toolbar-tip-tap-button/toolbar-tip-tap-button-voice-recorder/VoiceRecorderTipTapButtonICP'
import { VoiceRecorderBrowserTipTapButtonICP } from '@/submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/toolbar-tip-tap/toolbar-tip-tap-button/voice-recorder-browser/VoiceRecorderBrowserTipTapButtonICP'
import { Editor } from '@tiptap/react'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { LEORadButtonICP } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/buttons/leoRad/LEORadButtonICP'
import {
	RiAlignCenter,
	RiAlignJustify,
	RiAlignLeft,
	RiAlignRight,
	RiArrowGoBackLine,
	RiArrowGoForwardLine,
	RiBold,
	RiBookOpenLine,
	RiFormatClear,
	RiH1,
	RiItalic,
	RiListOrdered,
	RiListUnordered,
	RiSeparator,
	RiTableLine,
	RiUnderline,
} from 'react-icons/ri'
import { useLocation } from 'react-router-dom'
import { AuthUserResponseDTO } from 'submodules/beerads-sdk/services/auth/dtos/responses/inner/AuthUserResponseDTO'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import { useTipTapContext } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/context/useTipTapContext'
import { ToolbarTipTapButtonICP } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/toolbar-tip-tap/toolbar-tip-tap-button/ToolbarTipTapButtonICP'
import { TableTipTapButtonCP } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/toolbar-tip-tap/toolbar-tip-tap-button/toolbar-tip-tap-button-table/TableTipTapButtonCP'
import { EditorUiUtils, KeyMaps } from 'submodules/nerit-framework-ui/common/utils/EditorUiUtils'

interface ICPProps {
	editor: Editor
	loggedUser: AuthUserResponseDTO
}

export const TipTapButtonUtils = {
	getTipTapButtons({ editor, loggedUser }: ICPProps) {
		const osKeyboardComand = EditorUiUtils.matchKeyMapToOS(KeyMaps.CMD_OR_CTRL)
		const { onSavePhrase, canSeeWind } = useTipTapContext()
		const route = useLocation()

		const isMedicalReportScreen = route.pathname.split('/')[3] === PrivateUserRouter.MEDICAL_REPORT.split('/')[1]

		function getCurrentSelection(): string {
			const state = editor.state
			const { from, to } = state.selection
			return state.doc.textBetween(from, to, ' ')
		}

		const toolBarButtons = [
			{
				button: [
					(): JSX.Element => (
						<TooltipCP showSpan text={`Negrito (${osKeyboardComand}+B)`}>
							<ToolbarTipTapButtonICP
								Icon={RiBold}
								onClick={() => editor.chain().focus().toggleBold().run()}
								className={editor.isActive('bold') ? 'is-active' : ''}
							/>
						</TooltipCP>
					),
				],
			},
			{
				button: [
					(): JSX.Element => (
						<TooltipCP showSpan text={`Itálico (${osKeyboardComand}+I)`}>
							<ToolbarTipTapButtonICP
								Icon={RiItalic}
								onClick={() => editor.chain().focus().toggleItalic().run()}
								className={editor.isActive('italic') ? 'is-active' : ''}
							/>
						</TooltipCP>
					),
				],
			},
			{
				button: [
					(): JSX.Element => (
						<TooltipCP showSpan text={`Sublinhar (${osKeyboardComand}+U)`}>
							<ToolbarTipTapButtonICP
								Icon={RiUnderline}
								onClick={() => editor.chain().focus().toggleUnderline().run()}
								className={editor.isActive('underline') ? 'is-active' : ''}
							/>
						</TooltipCP>
					),
				],
			},
			{ divider: true },
			{
				button: [
					(): JSX.Element => (
						<TooltipCP showSpan text={'Título'}>
							<ToolbarTipTapButtonICP
								Icon={RiH1}
								onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
								className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
							/>
						</TooltipCP>
					),
				],
			},
			{ divider: true },
			{
				button: [
					(): JSX.Element => (
						<TooltipCP showSpan text={`Alinhar à esquerda (${osKeyboardComand}+Shift+L)`}>
							<ToolbarTipTapButtonICP
								Icon={RiAlignLeft}
								onClick={() => editor.chain().focus().setTextAlign('left').run()}
								className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
							/>
						</TooltipCP>
					),
				],
			},
			{
				button: [
					(): JSX.Element => (
						<TooltipCP showSpan text={`Alinhar ao centro (${osKeyboardComand}+Shift+E)`}>
							<ToolbarTipTapButtonICP
								Icon={RiAlignCenter}
								onClick={() => editor.chain().focus().setTextAlign('center').run()}
								className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
							/>
						</TooltipCP>
					),
				],
			},
			{
				button: [
					(): JSX.Element => (
						<TooltipCP showSpan text={`Alinhar à direita (${osKeyboardComand}+Shift+R)`}>
							<ToolbarTipTapButtonICP
								Icon={RiAlignRight}
								onClick={() => editor.chain().focus().setTextAlign('right').run()}
								className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
							/>
						</TooltipCP>
					),
				],
			},
			{
				button: [
					(): JSX.Element => (
						<TooltipCP showSpan text={`Justificar (${osKeyboardComand}+Shift+J)`}>
							<ToolbarTipTapButtonICP
								Icon={RiAlignJustify}
								onClick={() => editor.chain().focus().setTextAlign('justify').run()}
								className={editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''}
							/>
						</TooltipCP>
					),
				],
			},
			{ divider: true },
			{
				button: [
					(): JSX.Element => (
						<TooltipCP showSpan text={'Lista com marcadores'}>
							<ToolbarTipTapButtonICP
								Icon={RiListUnordered}
								onClick={() => editor.chain().focus().toggleBulletList().run()}
								className={editor.isActive('bulletList') ? 'is-active' : ''}
							/>
						</TooltipCP>
					),
				],
			},
			{
				button: [
					(): JSX.Element => (
						<TooltipCP showSpan text={'Lista numerada'}>
							<ToolbarTipTapButtonICP
								Icon={RiListOrdered}
								onClick={() => editor.chain().focus().toggleOrderedList().run()}
								className={editor.isActive('orderedList') ? 'is-active' : ''}
							/>
						</TooltipCP>
					),
				],
			},
			{ divider: true },
			{
				button: [
					(): JSX.Element => (
						<TooltipCP showSpan text={'Tabela'}>
							<PopOverCP content={<TableTipTapButtonCP editor={editor} />} trigger="click">
								<ToolbarTipTapButtonICP Icon={RiTableLine} onClick={() => undefined} />
							</PopOverCP>
						</TooltipCP>
					),
				],
			},
			{
				button: [
					(): JSX.Element => (
						<>
							<TooltipCP showSpan text={'Capturar voz'}>
								{canSeeWind ? <VoiceRecorderTipTapButtonICP /> : <VoiceRecorderBrowserTipTapButtonICP />}
							</TooltipCP>
						</>
					),
				],
			},
			{
				hide: !loggedUser?.preferences?.leoRadToken,
				button: [
					(): JSX.Element => (
						<TooltipCP showSpan text={'LEO Rad'}>
							<LEORadButtonICP />
						</TooltipCP>
					),
				],
			},
			{ divider: true },
			{
				button: [
					(): JSX.Element => (
						<TooltipCP showSpan text={'Separador'}>
							<ToolbarTipTapButtonICP Icon={RiSeparator} onClick={() => editor.chain().focus().setHorizontalRule().run()} />
						</TooltipCP>
					),
				],
			},
			{
				button: [
					(): JSX.Element => (
						<TooltipCP showSpan text={'Limpar formatação'}>
							<ToolbarTipTapButtonICP Icon={RiFormatClear} onClick={() => editor.chain().focus().unsetAllMarks().clearNodes().run()} />
						</TooltipCP>
					),
				],
			},
			{ divider: true },
			{
				button: [
					(): JSX.Element => (
						<TooltipCP showSpan text={`Desfazer (${osKeyboardComand}+Z)`}>
							<ToolbarTipTapButtonICP Icon={RiArrowGoBackLine} onClick={() => editor.chain().focus().undo().run()} />
						</TooltipCP>
					),
				],
			},
			{
				button: [
					(): JSX.Element => (
						<TooltipCP showSpan text={`Refazer (${osKeyboardComand}+Y)`}>
							<ToolbarTipTapButtonICP Icon={RiArrowGoForwardLine} onClick={() => editor.chain().focus().redo().run()} />
						</TooltipCP>
					),
				],
			},
		]

		if (isMedicalReportScreen) {
			toolBarButtons.push({ divider: true })

			toolBarButtons.push({
				button: [
					(): JSX.Element => (
						<TooltipCP showSpan text="Salvar frase">
							<ToolbarTipTapButtonICP Icon={RiBookOpenLine} onClick={() => onSavePhrase(getCurrentSelection())} />
						</TooltipCP>
					),
				],
			})
		}

		return toolBarButtons
	},
}
