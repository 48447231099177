import { DataTable } from '@/components/data-table/DataTableCP'
import { DataTablePagination } from '@/components/data-table/DataTablePaginationCP'
import { TableTesteColumns } from '@/modules/template/components/table-templates/inner/TableTesteColumns'
import { useSearchExamsByFilters } from '@/services/http/modules/exams/hooks'
import { examKeys } from '@/services/http/modules/exams/keys'
import { ExamAssignedFilterEnum } from '@/submodules/beerads-sdk/services/exams/exams/enums/ExamAssignedFilterEnum'
import { ExamStatusEnum } from '@/submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { getCoreRowModel, getPaginationRowModel, RowSelectionState, useReactTable } from '@tanstack/react-table'
import { useState } from 'react'

export function TableTesteCP(): JSX.Element {
	const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

	const filters = {
		statuses: [ExamStatusEnum.AWAITING, ExamStatusEnum.IN_PROGRESS],
		viewAsDoctor: true,
		assigned: ExamAssignedFilterEnum.ALL,
		doctorGroupCode: 142,
		page: 1,
		itemsPerPage: 50,
	}

	const { data } = useSearchExamsByFilters(filters, {
		queryKey: examKeys.searchByFilters(filters),
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	})

	const table = useReactTable({
		data: data?.list ?? [],
		columns: TableTesteColumns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onRowSelectionChange: setRowSelection,
		state: {
			rowSelection,
		},
	})

	return (
		<div className="flex w-full flex-col gap-4 px-6 py-10">
			<DataTable columns={TableTesteColumns} tableConfig={table} />
			<DataTablePagination table={table} />
		</div>
	)
}
