import 'photoviewer/dist/photoviewer.min.css'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ScreenResultCP } from 'submodules/nerit-framework-ui/common/components/screen/screen-result/ScreenResultCP'
import { ButtonCloseWindow } from 'submodules/nerit-framework-ui/features/common/components/button-close-window/ButtonCloseWindow'

interface ICPProps {
	buttonCloseLabel?: string
}

/**
 */
export function ScreenResultWrongParametersCP(props: ICPProps): JSX.Element {
	return (
		<ScreenResultCP
			status={'403'}
			title={'Ops! Parâmetros inválidos'}
			bottomContent={
				<FlexCP justify={'center'}>
					<ButtonCloseWindow label={props.buttonCloseLabel} />
				</FlexCP>
			}
		/>
	)
}
