import { HeaderVxCP } from 'app/components/header-vx/HeaderVxCP'
import * as _ from 'lodash-es'
import { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { UploadMedicalReportVideoResponseDTO } from 'submodules/beerads-sdk/services/exams/attachments/dtos/response/UploadMedicalReportVideoResponseDTO'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { ScreenResultCP } from 'submodules/nerit-framework-ui/common/components/screen/screen-result/ScreenResultCP'
import { VideoCP } from 'submodules/nerit-framework-ui/common/components/video/VideoCP'
import { CryptoUtils } from 'submodules/nerit-framework-utils/utils/CryptoUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'

type ViewTP = { status: 'awaiting' | 'error' | 'ok'; message?: string }

/**
 */
export function ScreenMedicalReportVideo(props: RouteComponentProps): JSX.Element {
	const routeParams = _.get(props, 'match.params') as { token: string }
	const token: string = routeParams.token

	const [view, setView] = useState<ViewTP>({ status: 'awaiting' })
	const [videoUrl, setVideoUrl] = useState<string>()

	useEffect(init, [])

	/**
	 * Inicializa.
	 */
	function init(): void {
		if (!token) {
			setView({ status: 'error', message: 'Parâmetros Inválidos' })
			return
		}

		let decrypted: any = CryptoUtils.base64UrlDecode(token)
		if (!decrypted) {
			setView({ status: 'error', message: 'Parâmetros Inválidos' })
			return
		}

		try {
			decrypted = JSON.parse(decrypted) as UploadMedicalReportVideoResponseDTO
			const diff = DateUtils.getDiff(TimeBaseEnum.MINUTE, DateUtils.toDate(decrypted.expirationDate, DateFormatEnum.US_WITH_TIME_H_M))

			if (diff > 0) {
				setView({ status: 'error', message: 'O link expirou' })
				return
			}

			setView({ status: 'ok' })
			setVideoUrl(decrypted.url)
		} catch {
			setView({ status: 'error', message: 'Parâmetros Inválidos' })
			return
		}
	}

	if (view.status === 'error') return <ScreenResultCP status={'403'} message={view.message} />

	return (
		<LayoutCP
			mainLayout={true}
			titleBrowser={'Vídeo Laudo'}
			header={<HeaderVxCP title={'Vídeo Laudo'} />}
			content={
				<ContentCP overflowVertical={true}>
					<LoadingOverlayCP show={view.status === 'awaiting'} />

					{view.status === 'ok' && videoUrl && <VideoCP url={videoUrl} type="video/mp4" />}
				</ContentCP>
			}
		/>
	)
}
