import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { WelcomeContentSelectDoctorGroupCP } from 'app/components/welcome-content-select-doctor-group/WelcomeContentSelectDoctorGroupCP'
import { useAppSelector } from 'app/redux/hook'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import {
	RadioGroupMedicalTimetableConfigTP,
	RadioGroupMedicalTimetableConfigTypeCP,
} from 'modules/medical-timetable/components/radio-group-medical-timetable-config-type/RadioGroupMedicalTimetableConfigTypeCP'
import { MedicalTimetableFilterFormModel } from 'modules/medical-timetable/components/sider-medical-timetable-filters/inner/MedicalTimetableFilterFormModel'
import { SiderMedicalTimetableFiltersCP } from 'modules/medical-timetable/components/sider-medical-timetable-filters/SiderMedicalTimetableFiltersCP'
import { ScreenContentMedicalTimetableConfig } from 'modules/medical-timetable/screens/screen-medical-timetable-config/content/ScreenContentMedicalTimetableConfig'
import { useEffect, useState } from 'react'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'

/**
 */
export function ScreenMedicalTimetableConfig(): JSX.Element {
	const [formModel, setFormModel] = useState<MedicalTimetableFilterFormModel>()
	const formStateManager = useFormStateManager<MedicalTimetableFilterFormModel>(formModel)
	const currentDoctorGroup = useAppSelector((state) => state.doctorGroup)

	const [reload, setReload] = useState<number>(1)
	const [selectedConfigType, setSelectedConfigType] = useState<RadioGroupMedicalTimetableConfigTP>('week')

	useEffect(init, [])

	/**
	 */
	function init(): void {
		setFormModel(
			new MedicalTimetableFilterFormModel({
				beginDate: DateUtils.getFirstAndLastDayOfWeek().beginDate,
				endDate: DateUtils.getFirstAndLastDayOfWeek().endDate,
			}),
		)
	}

	if (!formStateManager.getFormValues()) return <LoadingOverlayCP show={true} />

	if (!currentDoctorGroup.code) return <WelcomeContentSelectDoctorGroupCP />

	return (
		<AccessControlCP
			permission={{
				byHasAnyPermissionInDoctorGroup: { doctorGroupCode: currentDoctorGroup.code, permissions: ['isDoctorGroupAdmin'] },
			}}
		>
			<LayoutCP
				titleBrowser={'Configurar Escala Médica'}
				header={
					<HeaderCP
						title={'Configurar Escala Médica'}
						arrowBack={true}
						onClickBack={() => NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.MEDICAL_TIMETABLE)}
					>
						<RadioGroupMedicalTimetableConfigTypeCP
							selected={selectedConfigType}
							onChange={(configType) => {
								setSelectedConfigType(configType)
								setReload(TableUtils.getReloadNumber())
							}}
						/>
					</HeaderCP>
				}
				sider={
					<SiderMedicalTimetableFiltersCP
						filterFormStateManager={formStateManager}
						onChangeFilters={() => setReload(TableUtils.getReloadNumber())}
						appearance={{
							hideCalendar: selectedConfigType === 'default',
						}}
					/>
				}
				content={
					<ScreenContentMedicalTimetableConfig
						selectedConfigType={selectedConfigType}
						filterFormStateManager={formStateManager}
						reload={reload}
					/>
				}
			/>
		</AccessControlCP>
	)
}
