import { FlexProps } from 'antd/lib'
import * as React from 'react'
import * as S from './FlexStyles'

interface IScpProps extends S.IFlexSCPProps {
	alignItems?: 'center' | 'flex-end' | 'space-between' | 'flex-start' | 'baseline'
	justify?: 'center' | 'flex-end' | 'space-between' | 'flex-start'
	flexDirection?: 'column'
	flexWrap?: 'wrap'
	gap?: FlexProps['gap']
	wrap?: boolean
	children: React.ReactNode
	className?: string
	onClick?: () => void
}

/**
 * Para fazer um displex flex
 */
export function FlexCP(props: IScpProps): JSX.Element {
	return (
		<S.CustomFlex
			className={props.className}
			vertical={props.flexDirection === 'column'}
			wrap={props.wrap}
			justify={props.justify}
			align={props.alignItems}
			gap={props.gap}
			margin={props.margin}
			onClick={props.onClick}
			role={props.onClick ? 'button' : undefined}
		>
			{props.children}
		</S.CustomFlex>
	)
}
