import { Splitter } from 'antd'
import { PanelProps } from 'antd/es/splitter/interface'
import { SplitterProps } from 'antd/lib/splitter'
import React from 'react'

interface ICPProps extends SplitterProps {
	children: React.ReactNode
}

interface ISplitterPanelProps extends PanelProps {
	children: JSX.Element
}

export function SplitterCP({ children, ...props }: ICPProps): JSX.Element {
	return (
		<Splitter {...props}>
			{/* Verifica e renderiza apenas os componentes SplitterPanel */}
			{React.Children.map(children, (child) => {
				if (React.isValidElement(child) && child.type === SplitterCP.Panel) {
					return child
				}
				return null
			})}
		</Splitter>
	)
}

SplitterCP.Panel = function SplitterPanel(props: ISplitterPanelProps): JSX.Element {
	return <Splitter.Panel {...props}>{props.children}</Splitter.Panel>
}
