import { useAppSelector } from 'app/redux/hook'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import { ColumnCountSlaICP } from 'modules/exams/components/exams/table-total-exams-assigned-by-doctor/inner/ColumnCountSlaICP'
import { useEffect, useState } from 'react'
import { WidgetsRequests } from 'submodules/beerads-sdk/services/dashboard/widgets/WidgetsRequests'
import { CountExamsAssignedBySlaWidgetsResponseDTO } from 'submodules/beerads-sdk/services/dashboard/widgets/dtos/responses/CountExamsAssignedBySlaWidgetsResponseDTO'
import { ExamSpecialtyEnum, ExamSpecialtyLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamSpecialtyEnum'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'
import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'

interface ICPProps {
	onRowClick: (doctor: NameAndCodeResponseDTO) => void
	specialty?: ExamSpecialtyEnum
	clinicCode?: number
	reload?: number
}

/**
 * Mostra a Home especifica para cara tipo de usuario.
 */
export function TableTotalExamsAssignedByDoctorCP(props: ICPProps): JSX.Element {
	const [load, setLoad] = useState<number>()
	const currentDoctorGroup = useAppSelector((state) => state.doctorGroup)

	useEffect(() => setLoad(TableUtils.getReloadNumber()), [props.clinicCode, props.reload])

	return (
		<TableFromApiCP<CountExamsAssignedBySlaWidgetsResponseDTO>
			appearance={{
				wrappedOnCard: true,
				title: props.specialty ? ExamSpecialtyLabelEnum[props.specialty] : undefined,
			}}
			shouldLoadData={load}
			apiConfig={{
				requestConfigTP: () =>
					WidgetsRequests.countExamsAssignedBySla({
						doctorGroupCode: currentDoctorGroup.code ?? undefined,
						specialty: props.specialty,
						clinicCode: props.clinicCode,
					}),
			}}
			row={{
				onClick: (row) => props.onRowClick({ code: row.doctor.code, name: row.doctor.name }),
			}}
			columns={[
				{
					title: 'Médico',
					render: (text, record) => record.doctor.name,
				},
				{
					title: 'Especialidade',
					hide: !!props.specialty,
					render: (text, record) => !!record.doctor.specialty && <TagCP content={ExamSpecialtyLabelEnum[record.doctor.specialty]} />,
				},
				{
					title: '< 2h',
					align: 'center',
					render: (text, record) => <ColumnCountSlaICP total={record.sla2h} color={ThemeProjectCommon.examSlaColor.sla2h} />,
				},
				{
					title: '< 6h',
					align: 'center',
					render: (text, record) => <ColumnCountSlaICP total={record.sla6h} color={ThemeProjectCommon.examSlaColor.sla6h} />,
				},
				{
					title: '< 24h',
					align: 'center',
					render: (text, record) => <ColumnCountSlaICP total={record.sla24h} color={ThemeProjectCommon.examSlaColor.sla24h} />,
				},
				{
					title: 'Total',
					align: 'center',
					render: (text, record) => <b>{record.totalAssigned}</b>,
				},
			]}
		/>
	)
}
