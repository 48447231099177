import { KeyboardEvent, MouseEvent } from 'react'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { TabItemTP } from 'submodules/nerit-framework-ui/common/components/tabs/inner/TabItemTP'
import * as S from './TabsStyles'

interface ICPProps extends BasicStyleWrapperCPProps {
	tabs: TabItemTP[]
	type?: 'line' | 'card' | 'editable-card'
	tabPosition?: 'top' | 'right' | 'bottom' | 'left'
	extraContent?: JSX.Element
	defaultActiveKey?: string
	onChangeTab?: (key: string) => void
	editableConfig?: {
		onAddTab: () => void
		onRemoveTab: (tabKey: string) => void
	}
}

/**
 * Componente de abas customizado para o projeto.
 */
export function TabsCP(props: ICPProps): JSX.Element {
	/**
	 * Ao clicar para adicionar ou remover uma aba
	 */
	function onEdit(tab: MouseEvent | KeyboardEvent | string, action: 'add' | 'remove'): void {
		if (action === 'add' && !!props.editableConfig?.onAddTab) {
			return props.editableConfig.onAddTab()
		}

		if (action === 'remove' && !!props.editableConfig?.onRemoveTab && typeof tab === 'string') {
			return props.editableConfig.onRemoveTab(tab)
		}
	}

	return (
		<BasicStyleWrapperCP margin={props.margin} padding={props.padding}>
			<div>
				<S.CustomTabs
					type={props.type}
					defaultActiveKey={props.defaultActiveKey}
					tabPosition={props.tabPosition}
					animated
					tabBarExtraContent={props.extraContent}
					onEdit={onEdit}
					onChange={props.onChangeTab}
					items={props.tabs
						.filter((tab) => !tab.hide)
						.map((tab) => ({
							key: tab.key,
							label: tab.title,
							disabled: tab.disabled,
							children: tab.content,
						}))}
				/>
			</div>
		</BasicStyleWrapperCP>
	)
}
