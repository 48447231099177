import { useAppSelector } from '@/app/redux/hook'
import { RoutingHelper } from '@/config/RoutingHelper'
import { ThemeProjectCommon } from '@/config/theme/project/white-labels/ThemeProjectCommon'
import { ThemeProjectVX } from '@/config/theme/project/white-labels/ThemeProjectVX'
import { DoctorGroupPermissionUtils } from '@/modules/auth/permissions/DoctorGroupPermissionUtils'
import { DoctorPermissionUtils } from '@/modules/auth/permissions/DoctorPermissionUtils'
import { TagClinicCategoryCP } from '@/modules/clinic/components/tag-clinic-category/TagClinicCategoryCP'
import { AvatarExamModalityCP } from '@/modules/exams/components/exams/avatar-exam-modality/AvatarExamModalityCP'
import { ButtonPopoverExamUrgencyCP } from '@/modules/exams/components/exams/button-popover-exam-urgency/ButtonPopoverExamUrgencyCP'
import { IconCriticalExamCP } from '@/modules/exams/components/exams/icons/IconCriticalExamCP'
import { PopoverExamDateLogCP } from '@/modules/exams/components/exams/popover-exam-date-log/PopoverExamDateLogCP'
import { ColumnExamActionsICP } from '@/modules/exams/components/exams/table-exams/columns/actions/ColumnExamActionsICP'
import { TagExamStatusCP } from '@/modules/exams/components/exams/tag-exam-status/TagExamStatusCP'
import { ExamListItemResponseDTO } from '@/submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import { ExamStatusUtils } from '@/submodules/beerads-sdk/services/exams/exams/utils/ExamStatusUtils'
import { AvatarMultiUserCP } from '@/submodules/nerit-framework-ui/common/components/avatar-multi-user/AvatarMultUserCP'
import { BlinkCP } from '@/submodules/nerit-framework-ui/common/components/basic-wrappers/BlinkCP'
import { RoundedWrapperCP } from '@/submodules/nerit-framework-ui/common/components/basic-wrappers/RoundedWrapperCP'
import { IconICP } from '@/submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { TooltipCP } from '@/submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import { ThemeFrameworkCommon } from '@/submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { DateUtils } from '@/submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from '@/submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { ColumnDef } from '@tanstack/react-table'
import { Checkbox } from 'antd'

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.

export const TableTesteColumns: Array<ColumnDef<ExamListItemResponseDTO>> = [
	{
		id: 'select-col',
		header: ({ table }) => (
			<Checkbox
				checked={table.getIsAllRowsSelected()}
				indeterminate={table.getIsSomeRowsSelected()}
				onChange={table.getToggleAllRowsSelectedHandler()}
			/>
		),
		cell: ({ row }) => <Checkbox checked={row.getIsSelected()} disabled={!row.getCanSelect()} onChange={row.getToggleSelectedHandler()} />,
	},
	{
		id: 'urgency-type',
		cell: ({ row }) => {
			const rowValue = row.original
			return (
				<div>
					{rowValue.isCritical && (
						<BlinkCP>
							<RoundedWrapperCP color={ThemeProjectVX.warning} text={<IconCriticalExamCP hideIcon={!rowValue.isCritical} />} size={26} />
						</BlinkCP>
					)}
					<ButtonPopoverExamUrgencyCP
						exam={{
							code: rowValue.code,
							urgencyType: rowValue.urgentType,
							status: rowValue.status,
							clinicCode: rowValue.clinic.code,
							doctorGroupCode: rowValue.doctorGroupCode,
						}}
						appearance={{ size: 'small' }}
					/>
				</div>
			)
		},
	},
	{
		id: 'examDate',
		accessorKey: 'examDate',
		header: () => <div className="capitalize">Data Exame</div>,
		cell: ({ row }) => (
			<PopoverExamDateLogCP exam={row.original}>
				<p className="text-xs font-medium"> {DateUtils.formatDate(row.getValue('examDate'), DateFormatEnum.BR_WITHOUT_TIME)} </p>
			</PopoverExamDateLogCP>
		),
	},
	{
		id: 'patient',
		accessorKey: 'patient',
		header: () => <div className="capitalize">Paciente</div>,
		cell: ({ row }) => <p className="text-xs font-medium">{row.original.patient.name}</p>,
	},
	{
		id: 'description',
		accessorKey: 'description',
		header: () => <div className="capitalize">Exame</div>,
		cell: ({ row }) => {
			const record = row.original
			const loggedUser = useAppSelector((selector) => selector.auth.user)
			return (
				<AvatarExamModalityCP
					onClick={
						DoctorPermissionUtils.canDoMedicalReport(
							loggedUser,
							record.status,
							record.doctorGroupCode,
							record.doctor?.code,
							record.revisionDoctor?.code,
							record.doctorsAssigned?.map((doctor) => doctor.code),
						)
							? () =>
									RoutingHelper.goToMedicalReport(record.id, false, undefined, {
										viewAsDoctor: true,
									})
							: undefined
					}
					modality={record.modality}
					description={record.description}
					size={28}
					fontSize={'small'}
				/>
			)
		},
	},
	{
		id: 'imageData.totalImages',
		accessorKey: 'imageData.totalImages',
		header: () => <div className="capitalize">Imgs</div>,
		cell: ({ row }) => <div className="font-medium"> {row.original.imageData.totalImages} </div>,
	},
	{
		id: 'examDescription',
		accessorKey: 'examDescription',
		header: () => <div className="capitalize">Unidade Hospitalar</div>,
		cell: ({ row }) => {
			const record = row.original
			const loggedUser = useAppSelector((selector) => selector.auth.user)

			return (
				<>
					<div className="flex items-center">
						{record.digitalCertification?.isDigitalCertificateRequired && (
							<TooltipCP showSpan={true} text={'Essa clínica exige assinatura com certificado digital no laudo'}>
								<IconICP
									iconName={ThemeProjectCommon.icons.digitalCertificate}
									color={`${ThemeFrameworkCommon.browserDefaultColorDark}`}
									size={16}
									style={{ marginRight: 5 }}
								/>
							</TooltipCP>
						)}
						<p className="text-xs font-medium">{record.clinic.name}</p>
					</div>
					{!!record.doctorGroupCode &&
						DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(loggedUser, record.doctorGroupCode, [
							'isDoctorGroupAdmin',
							'isDoctorGroupUser',
							'isDoctorAdmin',
						]) && <TagClinicCategoryCP category={record.clinic.category} small={true} />}
				</>
			)
		},
	},
	{
		id: 'requestingDoctor',
		accessorKey: 'requestingDoctor',
		header: () => <div className="capitalize">Solicitante</div>,
		cell: ({ row }) => <p className="text-xs font-medium">{row.original.extraData?.requestingDoctor}</p>,
	},
	{
		id: 'status',
		accessorKey: 'status',
		header: () => <div className="capitalize">Status</div>,
		cell: ({ row }) => <TagExamStatusCP examStatus={row.getValue('status')} />,
	},
	{
		id: 'assignedDoctors',
		cell: ({ row }) =>
			row.original.doctorsAssigned ? <AvatarMultiUserCP users={row.original.doctorsAssigned} titleTooltip="Médicos atribuídos" /> : <div />,
	},
	{
		id: 'actions',
		cell: ({ row }) => {
			const record = row.original
			return (
				<ColumnExamActionsICP
					onSave={() => undefined}
					examStatusGroup={ExamStatusUtils.examStatusGroupOfExamStatusMap.get(record.status)!}
					exam={record}
					viewAsDoctor={true}
				/>
			)
		},
	},
]
