import { SelectDoctorGroupExamDescriptionsCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-exam-descriptions/SelectDoctorGroupExamDescriptionsCP'
import { SelectDoctorGroupFromLoggedUserCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-groups/SelectDoctorGroupFromLoggedUserCP'
import { SelectExamModalityCP } from 'modules/exams/components/exams/select-exam-modality/SelectExamModalityCP'
import { TemplateFormModel } from 'modules/template/components/form-template/inner/TemplateFormModel'
import { useState } from 'react'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { SelectFullOptionTP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/inner/types/SelectOptionTP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import * as S from './FormTemplateBasicDataStyles'

interface ICPProps {
	type: TemplateTypeEnum
	doctorGroupCode?: number
	formStateManager: IFormStateManager<TemplateFormModel>
	isTemplateScreen: boolean
}

/**
 */
export function FormTemplateBasicDataICP(props: ICPProps): JSX.Element {
	const [examDescription, setExamDescription] = useState<{ code?: number | string; description?: string } | undefined>(undefined)

	function mountExamDescription(value: SelectFullOptionTP): string {
		const label = value.label as string

		const selectedModalityBrLabel = label.split(' ')[0] as ExamModalityEnum

		return label.slice(selectedModalityBrLabel.length)
	}

	function onChangeExamDescription(selected?: SelectFullOptionTP): void {
		props.formStateManager.changeFieldValue('examDescription', selected ? mountExamDescription(selected) : undefined)

		if (selected) {
			setExamDescription({ code: selected.value, description: selected.label as string })
		} else {
			setExamDescription((prevState) => ({ ...prevState, code: undefined }))
		}
	}

	return (
		<S.FormWrapperSCP>
			<RowCP>
				<ColumnCP size={6}>
					<InputCP label={'Nome'} fieldName={'name'} formStateManager={props.formStateManager} required={true} />
				</ColumnCP>
				<ColumnCP size={5}>
					<SelectExamModalityCP
						label={props.type === TemplateTypeEnum.TEMPLATE ? 'Modalidade' : 'Modalidades'}
						formStateManager={props.formStateManager}
						fieldName={'modalities'}
						required={true}
						isMultiple={props.type === TemplateTypeEnum.PHRASE}
						excludedModalities={[ExamModalityEnum.CR_OIT]}
					/>
				</ColumnCP>
				{props.type === TemplateTypeEnum.TEMPLATE && !!props.formStateManager.getFieldValue('modalities') && (
					<ColumnCP size={6}>
						{!!props.doctorGroupCode ? (
							<SelectDoctorGroupExamDescriptionsCP
								selectedDescription={{ code: examDescription?.code, description: examDescription?.description } as any}
								onChange={onChangeExamDescription}
								doctorGroupCode={props.doctorGroupCode}
								selectedModality={props.formStateManager.getFieldValue('modalities')}
								allowFalsyValue
							/>
						) : (
							<InputCP label={'Nome do Exame'} formStateManager={props.formStateManager} fieldName={'examDescription'} />
						)}
						<HelpCP
							text={
								'Se este campo estiver preenchido, no momento de laudar o exame que tenha exatamente esta descrição, já será carregado o template automaticamente'
							}
							type={'tooltip'}
						/>
					</ColumnCP>
				)}
				<ColumnCP size={7}>
					{props.type === TemplateTypeEnum.TEMPLATE && !props.isTemplateScreen && (
						<SelectDoctorGroupFromLoggedUserCP
							value={props.doctorGroupCode}
							formStateManager={props.formStateManager}
							fieldName="doctorGroupCode"
							disable={!!props.doctorGroupCode}
							required
						/>
					)}
				</ColumnCP>
			</RowCP>
		</S.FormWrapperSCP>
	)
}
