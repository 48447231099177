import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { TemplateHistorySearchRequestDTO } from 'submodules/beerads-sdk/services/templates/history-templates/dtos/request/TemplateHistorySearchRequestDTO'
import { TemplateHistoryResponseDTO } from 'submodules/beerads-sdk/services/templates/history-templates/dtos/response/TemplateHistoryResponseDTO'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'

export class TemplatesHistoryRequests {
	public static CONTROLLER_ROOT = 'templateshistory'
	public static SEARCH_EP = 'search'
	public static GET_EP = 'get/:id'
	public static APPROVE_TEMPLATE_EP = 'approve-template/:id'
	public static REPROVE_TEMPLATE_EP = 'reprove-template/:id'

	static searchTemplateHistory = (dto: TemplateHistorySearchRequestDTO): RequestConfigTP<ListResponseDTO<TemplateHistoryResponseDTO>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${TemplatesHistoryRequests.CONTROLLER_ROOT}/${TemplatesHistoryRequests.SEARCH_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static getTemplateHistoryByCode = (id: string): RequestConfigTP<TemplateResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${TemplatesHistoryRequests.CONTROLLER_ROOT}/${TemplatesHistoryRequests.GET_EP}`.replace(':id', id),
		method: HttpMethodEnum.GET,
	})

	static approveTemplateHistory = (id: string): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${TemplatesHistoryRequests.CONTROLLER_ROOT}/${TemplatesHistoryRequests.APPROVE_TEMPLATE_EP}`.replace(':id', id),
		method: HttpMethodEnum.PUT,
	})

	static reproveTemplateHistory = (id: string): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${TemplatesHistoryRequests.CONTROLLER_ROOT}/${TemplatesHistoryRequests.REPROVE_TEMPLATE_EP}`.replace(':id', id),
		method: HttpMethodEnum.PUT,
	})
}
