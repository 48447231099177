import { useAppSelector } from 'app/redux/hook'
import { ModalSendExamToPendingAnalysisCP } from 'modules/exams/components/exams/modal-send-exam-to-pending-analysis/ModalSendExamToPendingAnalysisCP'
import { useState } from 'react'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'

interface ICPProps {
	exam: ExamResponseDTO
	onSave: () => void
	size?: ButtonSizeTP
	onClick?: () => void
}

/**
 * Botao que devolve um exame e descarta o laudo.
 */
export function ButtonSendToPendingAnalysisCP(props: ICPProps): JSX.Element {
	const [isSendToPendingAnalysisModalVisible, setIsSendToPendingAnalysisModalVisible] = useState<boolean>(false)
	const loggedUser = useAppSelector((state) => state.auth.user)

	if (![ExamStatusEnum.IN_PROGRESS, ExamStatusEnum.RECTIFICATION].includes(props.exam.status)) return <></>

	if (props.exam.doctor?.code !== loggedUser?.code) return <></>

	return (
		<>
			<ButtonCP
				size={props.size ?? 'small'}
				onClick={() => {
					setIsSendToPendingAnalysisModalVisible(true)
					if (!!props.onClick) props.onClick()
				}}
				confirmMsg={'Você tem certeza que deseja Devolver o Exame? O laudo será descartado.'}
				icon={'file-search'}
			>
				Devolver exame para análise
			</ButtonCP>

			<ModalSendExamToPendingAnalysisCP
				exam={props.exam}
				visible={isSendToPendingAnalysisModalVisible}
				onCancel={() => setIsSendToPendingAnalysisModalVisible(false)}
				onSave={() => {
					setIsSendToPendingAnalysisModalVisible(false)
					props.onSave()
				}}
			/>
		</>
	)
}
