import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { ModalSendExamToRectificationAnalysisCP } from 'modules/exams/components/exam-analysis/modal-send-exam-to-rectification-analysis/ModalSendExamToRectificationAnalysisCP'
import { useState } from 'react'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'

interface ICPProps extends BasicStyleWrapperCPProps {
	exam: ExamResponseDTO
	onRectificationRequested: () => void
}

/**
 */
export function ButtonSendToRectificationAnalysisCP(props: ICPProps): JSX.Element {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

	if (![ExamStatusEnum.DONE, ExamStatusEnum.DONE_RECTIFICATION, ExamStatusEnum.PRINTED].includes(props.exam.status)) return <></>

	return (
		<AccessControlCP
			permission={{
				byHasAnyPermissionInClinic: { clinicCode: props.exam.clinic.code, permissions: ['isUser', 'isAdmin', 'isDoctorGroupAdmin'] },
			}}
		>
			<ButtonCP
				size={'small'}
				onClick={() => setIsModalVisible(true)}
				icon={'monitor'}
				marginRight={props.margin?.right}
				marginLeft={props.margin?.left}
			>
				Solicitar Retificação
			</ButtonCP>

			<ModalSendExamToRectificationAnalysisCP
				exam={props.exam}
				onCancel={() => setIsModalVisible(false)}
				onSave={() => {
					setIsModalVisible(false)
					props.onRectificationRequested()
				}}
				visible={isModalVisible}
			/>
		</AccessControlCP>
	)
}
