import { useAppSelector } from 'app/redux/hook'
import { ClinicPermissionUtils } from 'modules/auth/permissions/ClinicPermissionUtils'
import { ModalExamDataCP } from 'modules/exams/components/exams/modal-exam-data/ModalExamDataCP'
import { useState } from 'react'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'

interface ICPProps extends BasicStyleWrapperCPProps {
	exam: ExamResponseDTO
	onEditData: () => void
}

/**
 */
export function ButtonEditExamDataCP(props: ICPProps): JSX.Element | null {
	const [isExamModalVisible, setIsExamModalVisible] = useState<boolean>(false)
	const loggedUser = useAppSelector((state) => state.auth.user)

	if (
		!ClinicPermissionUtils.hasAnyPermissionsInClinic(
			props.exam.clinic.code,
			['isDoctorGroupUser', 'isDoctorGroupAdmin', 'isUser', 'isAdmin'],
			loggedUser,
		)
	)
		return <></>

	return (
		<>
			<ButtonCP
				size={'small'}
				onClick={() => setIsExamModalVisible(true)}
				icon={'edit'}
				tooltip={'Editar dados do paciente/exame'}
				tooltipPlacement={'topRight'}
			/>

			<ModalExamDataCP
				exam={props.exam}
				onCancel={() => setIsExamModalVisible(false)}
				onSave={() => {
					setIsExamModalVisible(false)
					props.onEditData()
				}}
				visible={isExamModalVisible}
			/>
		</>
	)
}
