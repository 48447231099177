import styled from 'styled-components'

export type _ScpPropsTP = {
	width?: number
}

export const WrapperSCP = styled.div<_ScpPropsTP>`
	.ant-menu {
		width: ${(props) => (props.width ? `${props.width.toString()}px` : 'unset')};
		background: none;
		border-right: 0;
	}
`
