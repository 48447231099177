import { PublicRouter } from 'app/routers/public/PublicRouter'
import { TableDoctorsCP } from 'modules/admin-beerads/components/table-doctors/TableDoctorsCP'
import { LayoutSubmenuContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/content/LayoutSubmenuContentCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { HeaderButtonICP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/inner/HeaderButtonICP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'

interface ICPProps {
	title: string
}

/**
 * Tela de listagem de medicos.
 */
export function ScreenContentAdminDoctorsCP(props: ICPProps): JSX.Element {
	return (
		<>
			<HeaderCP title={props.title}>
				<HeaderButtonICP
					onClick={() => NeritFrameworkRoutingHelper.historyPush(PublicRouter.DOCTOR_REGISTER)}
					icon={'plus'}
					label={`Novo ${props.title}`}
				/>
			</HeaderCP>

			<LayoutSubmenuContentCP>
				<TableDoctorsCP />
			</LayoutSubmenuContentCP>
		</>
	)
}
