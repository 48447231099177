import { ModalMedicalTimetableAssignCP } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/ModalMedicalTimetableAssignCP'
import React, { useState } from 'react'
import { MedicalTimetableGroupByTP } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableGroupByTP'
import { MedicalTimetableIdEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableIdEnum'
import { MedicalTimetablePeriodEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetablePeriodEnum'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'

interface ICPProps {
	dayOfWeek: WeekDaysEnum
	doctorGroupCode: number
	period: MedicalTimetablePeriodEnum
	modality: ExamModalityEnum
	groupBy: MedicalTimetableGroupByTP
	id: MedicalTimetableIdEnum
	date: Date
	onSave: () => void
	doctorCode: number
	isRecurrent: boolean
}

export function ButtonMedicalTimetableEditSpotCP(props: ICPProps): JSX.Element {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

	if (props.isRecurrent) return <></>

	return (
		<>
			<ButtonCP icon={'clock-circle'} size={'small'} onClick={() => setIsModalVisible(true)} />
			{isModalVisible && (
				<ModalMedicalTimetableAssignCP
					dayOfWeek={props.dayOfWeek}
					doctorGroupCode={props.doctorGroupCode}
					period={props.period}
					modality={props.modality}
					groupBy={props.groupBy}
					id={props.id}
					date={props.date}
					onCancel={() => setIsModalVisible(false)}
					onSave={() => {
						setIsModalVisible(false)
						props.onSave()
					}}
					visible={isModalVisible}
					editData={{
						doctorCode: props.doctorCode,
						type: 'spot',
					}}
				/>
			)}
		</>
	)
}
