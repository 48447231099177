export enum MedicalTimetableReasonAbsenceEnum {
	NO_SHOW = 'NO_SHOW',
	VACATION = 'VACATION',
	MISTAKE = 'MISTAKE',
}

export enum MedicalTimetableReasonAbsenceLabelEnum {
	NO_SHOW = 'Falta',
	VACATION = 'Férias',
	MISTAKE = 'Falta',
}
