import { EventInput } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { DateRangeSearchRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/DateRangeSearchRequestDTO'

export interface IFullCalendarEvent extends EventInput {
	id: string
	title: string
	start: string | Date
	end: string | Date
	backgroundColor?: string
	textColor?: string
	borderColor?: string
	extendedProps?: any
}

interface IFullCalendarCPProps {
	onDateClick?: (dateInfo: any) => void
	onEventClick?: (eventInfo: any) => void
	dateRangeState?: DateRangeSearchRequestDTO
	onDateRangeChange: (dateRange: DateRangeSearchRequestDTO) => void
	events: IFullCalendarEvent[]
	date?: Date
	loading?: boolean
}

/**
 * Componente FullCalendar para exibicao do calendario.
 */
export function FullCalendarCP(props: IFullCalendarCPProps): JSX.Element {
	const calendarRef = useRef<FullCalendar>(null)

	useEffect(() => {
		if (!!calendarRef.current && props.date) {
			const calendarApi = calendarRef.current.getApi()
			calendarApi.gotoDate(props.date)
		}
	}, [props.date])

	const calendarApi = calendarRef.current?.getApi()

	useEffect(() => {
		if (!calendarApi) return

		calendarApi.removeAllEvents()

		for (const event of props.events) {
			calendarApi.addEvent(event)
		}
	}, [props.events])

	return (
		<>
			<CalendarWrapperSCP>
				<LoadingOverlayCP show={!!props.loading} />

				<FullCalendar
					ref={calendarRef}
					plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
					initialView="timeGridWeek"
					headerToolbar={{
						left: 'prev,next today',
						center: 'title',
						right: 'timeGridWeek,timeGridDay,listWeek',
					}}
					contentHeight="auto"
					locale={{
						code: 'pt-br',
						buttonText: {
							prev: 'Anterior',
							next: 'Próximo',
							today: 'Hoje',
							month: 'Mês',
							week: 'Semana',
							day: 'Dia',
							list: 'Lista',
						},
						allDayText: 'dia inteiro',
						eventTimeFormat: {
							hour: 'numeric',
							minute: '2-digit',
							separator: ' - ',
							meridiem: false,
						},
						slotLabelFormat: {
							hour: 'numeric',
							minute: '2-digit',
							omitZeroMinute: false,
							meridiem: 'short',
						},
					}}
					nowIndicator
					slotLabelInterval="01:00"
					slotDuration="01:00"
					allDaySlot={false}
					dateClick={props.onDateClick}
					eventClick={props.onEventClick}
					dayCellDidMount={(arg) => {
						if (!!props.onDateRangeChange) {
							props.onDateRangeChange({ beginDate: arg.view.activeStart, endDate: arg.view.activeEnd })
						}
					}}
				/>
			</CalendarWrapperSCP>
		</>
	)
}

const CalendarWrapperSCP = styled.div`
	height: fit-content;

	.fc {
		margin: 20px;
	}
	.fc .fc-daygrid-event-harness {
		border-radius: 5px;
	}
`
