import { useAppSelector } from 'app/redux/hook'
import { MonacoJsonEditorModalCP } from 'modules/clinic/screens/screen-clinics-admin/inner/monaco-json-editor-modal/MonacoJsonEditorModalCP'
import { useEffect, useState } from 'react'
import { SUPER_USER_EMAILS } from 'submodules/beerads-sdk/common/SuperUserEmails'
import { ClinicsRequests } from 'submodules/beerads-sdk/services/clinics/clinics/ClinicsRequests'
import { ClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicResponseDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { DescriptionCP } from 'submodules/nerit-framework-ui/common/components/description/DescriptionCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps {
	clinicCode: number
}

/**
 * Dados e configuracoes da instalacao
 */
export function ScreenContentClinicsAdminTechnical(props: ICPProps): JSX.Element {
	const [loadedClinic, setLoadedClinic] = useState<ClinicResponseDTO>()
	const [isModalOpen, setIsModalOpen] = useState(false)
	const loggedUser = useAppSelector((state) => state.auth.user)

	const canSeeAdvancedOptions = loggedUser?.isBeeradsAdmin && SUPER_USER_EMAILS.has(loggedUser.email)

	const getClinicRequest = useRequest<ClinicResponseDTO>()

	useEffect(onGetClinicRequestChange, [getClinicRequest.isAwaiting])

	useEffect(init, [props.clinicCode])

	/**
	 * Inicializa a tela.
	 */
	function init(): void {
		getClinicRequest.runRequest(ClinicsRequests.get(props.clinicCode))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetClinicRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getClinicRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		setLoadedClinic(getClinicRequest.responseData)
	}

	return (
		<FlexCP flexDirection={'column'}>
			<CardCP
				title={`Instalação ${loadedClinic?.clinicTechConfigTP?.beeradsBox?.isWeb ? 'web' : 'local'}`}
				innerSpacing={'none'}
				isLoading={getClinicRequest.isAwaiting}
			>
				<DescriptionCP
					bordered={true}
					numberOfColumns={1}
					items={[
						{
							label: 'Slug',
							text: loadedClinic?.slug,
						},
						{
							label: 'AE Title',
							text: loadedClinic?.clinicTechConfigTP?.beeradsBox?.aeTitle,
						},
						{
							label: 'IP',
							text: loadedClinic?.clinicTechConfigTP?.beeradsBox?.ip,
						},
						{
							label: 'Porta',
							text: loadedClinic?.clinicTechConfigTP?.beeradsBox?.port,
						},
						{
							label: 'Parceiro integração RIS',
							text: loadedClinic?.clinicTechConfigTP?.risIntegrationData?.partner,
						},
						{
							label: 'Observações',
							text: loadedClinic?.clinicTechConfigTP?.notes,
						},
					]}
				/>
			</CardCP>
			{canSeeAdvancedOptions && (
				<FlexCP justify={'flex-end'} margin={{ right: 20 }}>
					<ButtonCP icon={'tool'} onClick={() => setIsModalOpen(true)}>
						Configurações avançadas
					</ButtonCP>
				</FlexCP>
			)}

			{isModalOpen && (
				<MonacoJsonEditorModalCP
					clinicCode={props.clinicCode}
					clinicTechConfigTP={loadedClinic?.clinicTechConfigTP}
					onCancel={() => {
						setIsModalOpen(false)
					}}
					onSave={() => {
						setIsModalOpen(false)
						getClinicRequest.runRequest(ClinicsRequests.get(props.clinicCode))
					}}
				/>
			)}
		</FlexCP>
	)
}
