import { examKeys } from '@/services/http/modules/exams/keys'
import { searchExamsByFilters } from '@/services/http/modules/exams/requests'
import { ExamSearchRequestDTO } from '@/submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ExamListItemResponseDTO } from '@/submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import { ListResponseDTO } from '@/submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

export function useSearchExamsByFilters(
	filters?: ExamSearchRequestDTO,
	options?: UseQueryOptions<ListResponseDTO<ExamListItemResponseDTO>>,
): UseQueryResult<ListResponseDTO<ExamListItemResponseDTO>> {
	return useQuery({
		...options,
		queryKey: examKeys.searchByFilters(filters),
		queryFn: async () => searchExamsByFilters(filters),
		meta: {
			/**
			 * if present, this attribute will be shown in a snack bar
			  @see src/providers/react-query/ReactQueryProvider.tsx:33
			 */
			fallbackErrorMessage: 'Erro ao buscar exames',
		},
	})
}
