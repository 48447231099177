import { FormTemplateCP } from 'modules/template/components/form-template/FormTemplateCP'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { TemplateTypeEnum, TemplateTypeLabelEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import { IBasicModalCPProps, ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'

interface ICPProps extends IBasicModalCPProps {
	type: TemplateTypeEnum
	data?: {
		initialValues?: {
			content?: string
			modalities?: ExamModalityEnum[]
			owner: {
				// Um dos 3 deve ser preenchido
				clinicCode?: number
				doctorGroupCode?: number
				doctorCode?: number
			}
		}
		template?: TemplateResponseDTO
	}
}

export function ModalTemplateCP(props: ICPProps): JSX.Element {
	return (
		<ModalCP
			visible={props.visible}
			onCancel={props.onCancel}
			title={TemplateTypeLabelEnum[props.type]}
			width={props.type === TemplateTypeEnum.TEMPLATE ? 776 : undefined}
			top={33}
			noFooter
		>
			<FormTemplateCP type={props.type} onSave={props.onSave} data={props.data} />
		</ModalCP>
	)
}
