import { useAppSelector } from 'app/redux/hook'
import { ClinicPermissionUtils, ClinicRelationPermissionTP } from 'modules/auth/permissions/ClinicPermissionUtils'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

interface ICPProps {
	permissions?: ClinicRelationPermissionTP[]
	width?: number
	multiple?: {
		isMultiple: boolean
		clearSearchTextOnSelect?: boolean
		maxTagCount?: number
	}
	value: {
		byFormStateManager?: {
			fieldName: string
			formStateManager: IFormStateManager<any>
		}
		byOnChange?: {
			value: any
			onChange: (code: any) => void
			showSelectAll?: boolean // Não funciona com o formstatemanager ainda, por isso esta aqui no byOnChange
		}
	}
	allowClear?: boolean
}

/**
 */
export function SelectClinicFromLoggedUserCP(props: ICPProps): JSX.Element {
	const loggedUser = useAppSelector((state) => state.auth.user)

	return (
		<SelectCP
			label={'Unidade hospitalar'}
			options={
				loggedUser?.clinics
					?.filter((clinic) =>
						!!props.permissions
							? ClinicPermissionUtils.hasAnyPermissionsInClinic(clinic.code, props.permissions, loggedUser)
							: loggedUser?.clinics,
					)
					?.map((clinic) => ({
						value: clinic.code,
						label: clinic.name,
					})) ?? []
			}
			maxTagCount={props.multiple?.maxTagCount}
			isMultiple={props.multiple?.isMultiple}
			multiple={{
				showSelectAll: props.value.byOnChange?.showSelectAll,
				clearSearchTextOnSelect: props.multiple?.clearSearchTextOnSelect,
			}}
			allowClear={props.allowClear}
			width={props.width}
			onChange={props.value.byOnChange?.onChange}
			value={props.value.byOnChange?.value}
			formStateManager={props.value.byFormStateManager?.formStateManager}
			fieldName={props.value.byFormStateManager?.fieldName}
			filterOption={(filtered, eachElement) => {
				if (!eachElement?.props.children || !filtered) return true

				return StringUtils.removeAccents(eachElement.props.children.toString())
					.toLowerCase()
					.includes(StringUtils.removeAccents(filtered).toLowerCase())
			}}
		/>
	)
}
