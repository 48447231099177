import { VxWindReportAssistantCP } from '@/app/components/vx-wind/vx-wind-report-assistant/VxWindReportAssistantCP'
import { usePermissions } from '@/app/hooks/usePermissions'
import { FeatureTogglePermissionEnum } from '@/submodules/beerads-sdk/common/roles-permissions/enums/FeatureTogglePermissionEnum'
import { ExamResponseDTO } from '@/submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { TipTapAutoCompleteExtensionCP } from '@/submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/tip-tap-extensions/TipTapAutoCompleteExtensionCP'
import { TipTapSpeechToTextCP } from '@/submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/tip-tap-extensions/TipTapSpeechToTextCP'
import { ToolbarTipTapICP } from '@/submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/toolbar-tip-tap/inner/ToolbarTipTapICP'
import { ToolbarTipTapSavePhrase } from '@/submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/toolbar-tip-tap/toolbar-tip-tap-save-phrase/ToolbarTipTapSavePhrase'
import { Content, Editor, EditorProvider, JSONContent } from '@tiptap/react'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { TipTapContextProvider } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/context/TipTapProvider'
import { useEditorDefaultExtensions } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/tip-tap-extensions/hooks/useTipTapDefaultExtensions'
import * as S from './TipTapStyles'

export interface IAutocompleteOption {
	name: string
	content: string
}

interface ICPProps {
	exam?: ExamResponseDTO
	doctorGroupCode?: number
	content?: Content | string
	onContentChange: (json: JSONContent, html: string) => void
	autoComplete: IAutocompleteOption[]
}

export function TipTapCP(props: ICPProps): JSX.Element {
	const { hasAllPermissions, onChangeTemporaryDoctorGroup } = usePermissions()
	const extensions = useEditorDefaultExtensions()
	const route = useLocation()

	const isMedicalReportScreen = route.pathname.split('/')[3] === PrivateUserRouter.MEDICAL_REPORT.split('/')[1]

	const editorRef = useRef<Editor | null>(null)

	//Lida com o conteúdo do Editor recebido via props
	useEffect(() => {
		if (editorRef.current && props.content) {
			editorRef.current.commands.setContent(props.content)
		}
	}, [props.content])

	useEffect(() => {
		if (props.exam?.doctorGroup?.code) {
			onChangeTemporaryDoctorGroup(props.exam.doctorGroup.code)
		}
	}, [props.exam?.doctorGroup?.code])

	function onContentChange(jsonContentValue: JSONContent, htmlContentValue: string): void {
		props.onContentChange(jsonContentValue, htmlContentValue)
	}

	const canSeeWind = hasAllPermissions([FeatureTogglePermissionEnum.WIND.SHOW])

	return (
		<S.WrapperSCP>
			<TipTapContextProvider canSeeWind={canSeeWind} examModality={props.exam?.modality}>
				<EditorProvider
					slotBefore={<ToolbarTipTapICP />}
					extensions={extensions}
					onUpdate={(value) => onContentChange(value.editor.getJSON(), value.editor.getHTML())}
					onCreate={({ editor }) => {
						editorRef.current = editor
					}}
					content={props.content}
				>
					{isMedicalReportScreen && <TipTapAutoCompleteExtensionCP autoComplete={props.autoComplete} />}
					{isMedicalReportScreen && canSeeWind && <TipTapSpeechToTextCP />}
					{isMedicalReportScreen && canSeeWind && <VxWindReportAssistantCP forceShow={true} onContentChange={props.onContentChange} />}
					{isMedicalReportScreen && <ToolbarTipTapSavePhrase />}
				</EditorProvider>
			</TipTapContextProvider>
		</S.WrapperSCP>
	)
}
