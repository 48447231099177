import { flexRender } from '@tanstack/react-table'

import { IDataTableProps } from '@/components/data-table/utils/IDataTableProps'
import { TableBody, TableCell, Table as TableCP, TableHead, TableHeader, TableRow } from '@/components/ui/table'

export function DataTable<TData, TValue>({ columns, tableConfig }: IDataTableProps<TData, TValue>): JSX.Element {
	return (
		<div className="rounded-md border">
			<TableCP>
				<TableHeader>
					{tableConfig.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<TableHead key={header.id}>
										{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
									</TableHead>
								)
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{tableConfig.getRowModel().rows?.length ? (
						tableConfig.getRowModel().rows.map((row) => (
							<TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
								{row.getVisibleCells().map((cell) => (
									<TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
								))}
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell colSpan={columns.length} className="h-24 text-center">
								No results.
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</TableCP>
		</div>
	)
}
