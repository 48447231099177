import { IIconProps } from 'submodules/nerit-framework-ui/common/components/icon/inner/IIconProps'

export const StethoscopeIconCP = (props: IIconProps): JSX.Element => {
	return (
		<svg
			style={{
				width: props.size ?? '22',
				height: props.size ?? '22',
				fill: props.color ?? 'inherit',
			}}
			width={props.size ?? '22'}
			height={props.size ?? '22'}
			fill={props.color ?? 'inherit'}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 201.324 201.324"
		>
			<circle cx="95.596" cy="10.083" r="10.083" />
			<circle cx="149.018" cy="10.083" r="10.083" />
			{/* tslint:disable-next-line: max-line-length*/}
			<path d="M179.06 19.254c-5.123-8.873-14.298-14.17-24.544-14.17v10c6.631 0 12.568 3.428 15.884 9.17 3.316 5.743 3.316 12.599.001 18.342l-32.122 55.636c-3.315 5.742-9.253 9.17-15.884 9.171-6.631 0-12.569-3.428-15.885-9.171L74.389 42.595c-3.315-5.742-3.315-12.599 0-18.341s9.254-9.171 15.885-9.171v-10c-10.246 0-19.422 5.297-24.545 14.171s-5.123 19.468 0 28.341l32.121 55.636c4.272 7.399 11.366 12.299 19.545 13.727v26.832c0 26.211-15.473 47.535-34.492 47.535s-34.491-21.324-34.491-47.535v-31.948C59.802 109.52 68.4 99.424 68.4 87.356c0-13.779-11.21-24.989-24.989-24.989s-24.989 11.21-24.989 24.989c0 12.067 8.598 22.163 19.989 24.486v31.948c0 31.725 19.959 57.535 44.492 57.535 24.532 0 44.491-25.81 44.491-57.535v-26.832c8.178-1.428 15.273-6.328 19.544-13.727l32.122-55.636c5.124-8.873 5.124-19.468 0-28.341z" />
		</svg>
	)
}
