import { useAppSelector } from 'app/redux/hook'
import { RoutingHelper } from 'config/RoutingHelper'
import { ExamPendingAnalysisFooterICP } from 'modules/exams/components/exam-analysis/drawer-exam-pending-analysis/inner/ExamPendingAnalysisFooterICP'
import { PendingAnalysisExamFormModel } from 'modules/exams/components/exam-analysis/drawer-exam-pending-analysis/model/PendingAnalysisExamFormModel'
import { TimelineExamHistoryCP } from 'modules/exams/components/exam-history/timeline-exam-history/TimelineExamHistoryCP'
import { ButtonDownloadExamCP } from 'modules/exams/components/exams/button-download-exam/ButtonDownloadExamCP'
import { ButtonExamDetailsCP } from 'modules/exams/components/exams/button-exam-details/ButtonExamDetailsCP'
import { TitleExamCP } from 'modules/exams/components/exams/title-exam/TitleExamCP'
import { useEffect, useState } from 'react'
import { ExamsAnalysisRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsAnalysisRequests'
import { ExamPendingAnalysisResolveRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamPendingAnalysisResolveRequestDTO'
import { ExamPendingAnalysisResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamPendingAnalysisResponseDTO'
import { ExamStatusEnum, ExamStatusLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { DrawerCP } from 'submodules/nerit-framework-ui/common/components/drawer/DrawerCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

type PendingAnalysisSaveActionTP = ExamStatusEnum.AWAITING | ExamStatusEnum.RETURNED | ExamStatusEnum.IN_PROGRESS

interface ICPProps {
	examId: string
	examCode: number
	visible: boolean
	onCancel: () => void
	onSave: () => void
}

/**
 * Drawer para fazer analise administrativa de um exame.
 */
export function DrawerExamPendingAnalysisCP(props: ICPProps): JSX.Element {
	const [formModel, setFormModel] = useState<PendingAnalysisExamFormModel>(new PendingAnalysisExamFormModel())
	const formStateManager = useFormStateManager<PendingAnalysisExamFormModel>(formModel)
	const loggedUser = useAppSelector((state) => state.auth.user)

	const [loadedExam, setLoadedExam] = useState<ExamPendingAnalysisResponseDTO>()
	const getExamRequest = useRequest<ExamPendingAnalysisResponseDTO>()
	useEffect(onGetExamRequestChange, [getExamRequest.isAwaiting])

	const [saveAction, setSaveAction] = useState<PendingAnalysisSaveActionTP>()

	const resolveExamRequest = useRequest<void>('none')
	useEffect(onResolveExamRequestChange, [resolveExamRequest.isAwaiting])

	useEffect(init, [props.visible])

	/**
	 * Inicializa a tela
	 */
	function init(): void {
		setFormModel(new PendingAnalysisExamFormModel({}))
		setLoadedExam(undefined)
		if (!props.visible) return

		getExamRequest.runRequest(ExamsAnalysisRequests.getExamPendingAnalysis(props.examCode))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetExamRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getExamRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = getExamRequest.responseData!
		setFormModel(
			new PendingAnalysisExamFormModel({
				doctorCode: result.medicalReportDoctorCode,
			}),
		)
		setLoadedExam(result)
	}

	/**
	 * Salva o exame.
	 */
	async function save(sendTo: PendingAnalysisSaveActionTP): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager)))
			return NotificationHelper.error('Ops', 'Preencha a resolução para prosseguir')

		setSaveAction(sendTo)

		const formValues = formStateManager.getFormValues()!

		let doctorCode
		if (sendTo === ExamStatusEnum.AWAITING) doctorCode = formValues.doctorCode
		else if (sendTo === ExamStatusEnum.IN_PROGRESS) doctorCode = loggedUser?.code

		const dto: ExamPendingAnalysisResolveRequestDTO = {
			sendTo: sendTo === ExamStatusEnum.IN_PROGRESS ? ExamStatusEnum.AWAITING : sendTo,
			comments: formValues.comments,
			doctorCode,
		}
		resolveExamRequest.runRequest(ExamsAnalysisRequests.resolvePendingAnalysisExam(props.examCode, dto))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onResolveExamRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				resolveExamRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		if (saveAction === ExamStatusEnum.IN_PROGRESS) {
			RoutingHelper.goToMedicalReport(loadedExam!.id, false)
			return
		}

		props.onSave()
	}

	if (!props.visible) return <></>

	if (!loadedExam) return <LoadingOverlayCP show={true} />

	return (
		<DrawerCP
			visible={props.visible}
			loading={getExamRequest.isAwaiting || resolveExamRequest.isAwaiting}
			width={600}
			title={
				<TitleExamCP
					modality={loadedExam.modality}
					patientName={loadedExam.patientName}
					examTitle={loadedExam.description}
					examStatus={loadedExam.status}
				/>
			}
			footerSpaced={true}
			footer={
				<ExamPendingAnalysisFooterICP
					formStateManager={formStateManager}
					examCode={loadedExam.code}
					doctorGroupCode={loadedExam.doctorGroupCode}
					onSave={save}
				/>
			}
			onClose={props.onCancel}
		>
			<FlexCP margin={{ bottom: 2 }} justify={'space-between'}>
				<ButtonDownloadExamCP
					size={'small'}
					exam={{
						id: loadedExam.id,
						examDescription: loadedExam.description,
						patientName: loadedExam.patientName,
						imageData: loadedExam.imageData,
					}}
					downloadBy={'user'}
				/>
				<ButtonExamDetailsCP showText={true} examId={props.examId} onSave={init} />
			</FlexCP>

			<TitleCP underLine={true} textSize={'normal'} marginTop={50}>
				{`Resolução da ${ExamStatusLabelEnum[loadedExam.status]}`}
			</TitleCP>
			<HelpCP
				text={`${StringUtils.getFirstName(
					loggedUser?.name ?? '',
				)}, escreva uma resposta para a solicitação e devolva para a unidade hospitalar ou para o médico`}
				type={'text'}
			/>
			<TextAreaCP
				placeholder={'Escreva os detalhes da resolução do pedido de análise'}
				minRows={4}
				formStateManager={formStateManager}
				fieldName={'comments'}
				required={true}
			/>
			<TitleCP underLine={true} textSize={'normal'} marginTop={48}>
				{'Histórico'}
			</TitleCP>
			<BasicStyleWrapperCP padding={{ top: 10, bottom: 10, left: 10, right: 10 }} height={'45vh'} overflow={'auto'}>
				<TimelineExamHistoryCP examCode={props.examCode} />
			</BasicStyleWrapperCP>
		</DrawerCP>
	)
}
