import styled from 'styled-components'

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
	flex-direction: column;
`

export const Modal = styled.div<{ isVisible?: boolean }>`
	display: flex;
	flex-direction: column;
	gap: 15px;
	opacity: ${(props) => (props.isVisible ? 1 : 0)};
	transition: opacity 1000ms ease-in-out;
`

export const ModalContent = styled.div`
	background: white;
	width: 500px;
	padding: 24px;
	border-radius: 8px;
	position: relative;
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
	align-self: center;

	display: flex;
	flex-direction: column;
`

export const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	background: none;
	border: none;
	font-size: 1.5rem;
	cursor: pointer;
	align-self: end;
`

export const TextWrapper = styled.div`
	max-height: 460px;
	overflow-y: auto;
	padding: 8px;
`

export const FooterWrapperSCP = styled.div<{ isVisible: boolean }>`
	> div {
		svg {
			fill: #fff;
		}
	}
`
