import { TableTemplatesCP } from 'modules/template/components/table-templates/TableTemplatesCP'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { TabItemTP } from 'submodules/nerit-framework-ui/common/components/tabs/inner/TabItemTP'

type TemplateFiltersTP = {
	clinicCode?: number
	doctorGroupCode?: number
}

export const TabTemplateUtils = {
	getTabTemplates(type: TemplateTypeEnum, filters: TemplateFiltersTP, loadList: number, onChangeAnyData: (number: number) => void): TabItemTP {
		return {
			key: 'templates',
			title: 'Templates',
			content: (
				<TableTemplatesCP
					onSave={() => onChangeAnyData(TableUtils.getReloadNumber())}
					loadList={loadList}
					filters={{
						type: type,
						clinicCode: filters.clinicCode,
						doctorGroupCode: filters.doctorGroupCode,
					}}
					appearance={{
						showDeleteButton: true,
						showEditButton: true,
						wrappedOnCard: true,
					}}
				/>
			),
		}
	},
}
