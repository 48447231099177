export enum TemplateHistoryStatusEnum {
	ACTIVE = 'ACTIVE',
	AWAITING_APPROVAL = 'AWAITING_APPROVAL',
	REPROVED = 'REPROVED',
	DELETED = 'DELETED',
}

export enum TemplateHistoryStatusLabelEnum {
	ACTIVE = 'Ativo',
	AWAITING_APPROVAL = 'Aguardando aprovação',
	REPROVED = 'Reprovado',
	DELETED = 'Deletado',
}
