import { ThemeProject } from 'config/theme/project/ThemeProject'
import styled from 'styled-components'
import { LoadingCP } from 'submodules/nerit-framework-ui/common/components/loading/basic/LoadingCP'
import { MenuItemCP } from 'submodules/nerit-framework-ui/common/components/menu/menu-item/MenuItemCP'
import { MenuCP } from 'submodules/nerit-framework-ui/common/components/menu/menu/MenuCP'
import { SubMenuCP } from 'submodules/nerit-framework-ui/common/components/menu/sub-menu/SubMenuCP'
import { ScreenSubMenuTP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/submenu/inner/ScreenSubMenuItemTP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { SiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/sider/SiderCP'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface IScreenSubmenuCPProps<ViewTP> {
	onSelectMenuOption: (option: ViewTP) => void
	currentMenuOption: ViewTP
	title: string
	subTitle?: string
	submenuConfigs: Array<ScreenSubMenuTP<ViewTP>>
	defaultOpenKeys?: string[]
	marginVertical?: number
	loading?: boolean
}

/**
 * Generico de Submenus das telas que possuem submenu.
 */
export function ScreenSubmenuCP<ViewTP>(props: IScreenSubmenuCPProps<ViewTP>): JSX.Element {
	const screenSize = useScreenSize()

	function render(submenuCP: JSX.Element): JSX.Element {
		return screenSize.smd ? (
			submenuCP
		) : (
			<SiderWrapperSCP>
				<SiderCP width={275} bgColor={ThemeProject.primary200}>
					{submenuCP}
				</SiderCP>
			</SiderWrapperSCP>
		)
	}

	return render(
		<WrapperSCP>
			<MenuCP defaultOpenKeys={props.defaultOpenKeys}>
				{!screenSize.smd && (
					<>
						<HeaderCP title={props.title} noBackground={true} color={ThemeFrameworkCommon.browserDefaultColorDark} />
						{!!props.subTitle && <SubtitleSCP>{props.subTitle}</SubtitleSCP>}
					</>
				)}

				{props.loading ? (
					<LoadingCP />
				) : (
					props.submenuConfigs
						.filter((submenu) => submenu.enabled)
						?.map((submenu) => (
							<SubMenuCP key={submenu.key} title={submenu.title}>
								{submenu.items
									.filter((submenuItem) => submenuItem.enabled === undefined || submenuItem.enabled)
									?.map((menuItem) => (
										<MenuItemCP
											key={menuItem.view as any}
											marginVertical={props.marginVertical ?? 0}
											align={'left'}
											onClick={() => props.onSelectMenuOption(menuItem.view)}
											isActive={menuItem.view === props.currentMenuOption}
											isSecondaryMenu={true}
										>
											{typeof menuItem.name === 'string' ? (
												menuItem.name
											) : (
												<SubmenuElementWrapperSCP>{menuItem.name}</SubmenuElementWrapperSCP>
											)}
										</MenuItemCP>
									))}
							</SubMenuCP>
						))
				)}
			</MenuCP>
		</WrapperSCP>,
	)
}

const SiderWrapperSCP = styled.div`
	border-left: solid 1px #6b7280;

	.ant-menu-item {
		color: ${ThemeFrameworkCommon.browserDefaultColorDark};
		margin-bottom: 4px !important;

		&:hover {
			color: ${ThemeProject.primary};
		}
	}
`

const WrapperSCP = styled.div`
	overflow-y: auto;
	overflow-x: hidden;
`

const SubmenuElementWrapperSCP = styled.div`
	width: 100%;
`

const SubtitleSCP = styled.div`
	color: ${ThemeProject.gray300};
	font-style: italic;
	padding: 0 0 20px 24px;
`
