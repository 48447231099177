import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'

interface IHeaderButtonICPProps {
	onClick: () => void
	icon: string
	label?: string
	tooltip?: string
	strong?: boolean
}

/**
 * Botao principal dos headers das paginas
 */
export function HeaderButtonICP(props: IHeaderButtonICPProps): JSX.Element {
	const screenSize = useScreenSize()

	return (
		<ButtonCP onClick={props.onClick} icon={props.icon} marginLeft={10} tooltip={props.tooltip}>
			{!screenSize.smd && props.label && <TextCP text={props.label} strong={props.strong} />}
		</ButtonCP>
	)
}
