import { ModalTemplateCP } from 'modules/template/components/modal-template/ModalTemplateCP'
import { useState } from 'react'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'

interface ICPProps {
	template: TemplateResponseDTO
	doctorGroupCode?: number
	clinicCode?: number
	disabled?: boolean
	onSave: () => void
}

export function ButtonEditMedicalReportTemplateCP(props: ICPProps): JSX.Element {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

	function onSave(): void {
		setIsModalVisible(false)
		props.onSave()
	}

	return (
		<>
			<ButtonCP icon={'edit'} onClick={() => setIsModalVisible(true)} size={'small'} disabled={props.disabled}>
				Editar
			</ButtonCP>
			{isModalVisible && (
				<ModalTemplateCP
					type={TemplateTypeEnum.TEMPLATE}
					onCancel={() => setIsModalVisible(false)}
					onSave={onSave}
					visible
					data={{
						template: props.template,
						initialValues: {
							owner: {
								doctorGroupCode: props.doctorGroupCode,
								clinicCode: props.clinicCode,
							},
						},
					}}
				/>
			)}
		</>
	)
}
