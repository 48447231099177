import { BAR_GRAPH_CONFIG_OPTIONS } from '_old/main/common/components/graphs/bar-graph/inner/BarGraphConfigOptions'
import ApexCharts from 'apexcharts'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

export type GraphSeriesTP = {
	data: number[]
	name: string
}

interface IBarGraphCPProps {
	xAxis: string[]
	ySeries: GraphSeriesTP[]
}

/**
 * COMPONENTE
 * Grafico de barras.
 *
 * @author Lucas Rosa
 * @author hjcostabr
 */
export function BarGraphCP(props: IBarGraphCPProps): JSX.Element {
	const graphRef = useRef<any>(null)
	const graphElement = useRef<any>(null)

	useEffect(onMount, [])
	useEffect(updateGraphData, [props.ySeries, props.xAxis])

	function onMount(): void {
		graphElement.current = new ApexCharts(graphRef.current, BAR_GRAPH_CONFIG_OPTIONS)
		graphElement.current.render()
	}

	function updateGraphData(): void {
		if (!props.ySeries || !graphElement.current) return

		graphElement.current.updateSeries(props.ySeries)
		graphElement.current.updateOptions({
			xaxis: { categories: props.xAxis, labels: { style: { colors: props.xAxis.map(() => ThemeFrameworkCommon.browserDefaultBackgroundDark) } } },
			yaxis: { labels: { style: { colors: ThemeFrameworkCommon.browserDefaultBackgroundDark } } },
		})
	}

	return (
		<GraphWrapperSCP>
			<div ref={graphRef} />
		</GraphWrapperSCP>
	)
}

const GraphWrapperSCP = styled.div`
	height: 100%;
	width: 100%;

	.apexcharts-menu-item {
		color: ${ThemeFrameworkCommon.black};
		display: flex;
		align-items: center;
		alignt-text: start;

		.apexcharts-xaxis-label {
			color: 'white';
		}
	}
`
