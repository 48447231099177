import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { DoctorsMedicalTimetableEventsSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/DoctorsMedicalTimetableEventsSaveRequestDTO'
import { DoctorsMedicalTimetableEventsSearchRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/DoctorsMedicalTimetableEventsSearchRequestDTO'
import { DoctorsMedicalTimetableEventsResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/DoctorsMedicalTimetableEventsResponseDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'

export class DoctorsMedicalTimetableEventsRequests {
	public static CONTROLLER_ROOT = 'doctors-medical-timetable-events'
	public static CREATE_EP = 'create'
	public static UPDATE_EP = 'update/:code'
	public static DELETE_EP = 'delete/:code'
	public static SEARCH_EP = 'search'

	static search = (dto: DoctorsMedicalTimetableEventsSearchRequestDTO): RequestConfigTP<ListResponseDTO<DoctorsMedicalTimetableEventsResponseDTO>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorsMedicalTimetableEventsRequests.CONTROLLER_ROOT}/${DoctorsMedicalTimetableEventsRequests.SEARCH_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static update = (code: number, dto: DoctorsMedicalTimetableEventsSaveRequestDTO): RequestConfigTP<Promise<void>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorsMedicalTimetableEventsRequests.CONTROLLER_ROOT}/${DoctorsMedicalTimetableEventsRequests.UPDATE_EP.replace(
			':code',
			code.toString(),
		)}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static delete = (code: number): RequestConfigTP<Promise<void>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorsMedicalTimetableEventsRequests.CONTROLLER_ROOT}/${DoctorsMedicalTimetableEventsRequests.DELETE_EP.replace(
			':code',
			code.toString(),
		)}`,
		method: HttpMethodEnum.DELETE,
	})

	static create = (dto: DoctorsMedicalTimetableEventsSaveRequestDTO): RequestConfigTP<Promise<void>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorsMedicalTimetableEventsRequests.CONTROLLER_ROOT}/${DoctorsMedicalTimetableEventsRequests.CREATE_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})
}
