import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { tv } from 'tailwind-variants'

export interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	variant?: 'outlined' | 'default'
	label?: string
	suffixIcon?: React.ReactElement
	prefixIcon?: React.ReactElement
}

const inputVariants = tv({
	base: 'flex h-10 w-full px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
	variants: {
		intent: {
			default: 'peer bg-transparent placeholder-transparent pl-0',
			outlined: 'border border-input bg-white text-gray-800 border-gray-400 hover:bg-gray-100',
		},
	},
	defaultVariants: {
		intent: 'default',
	},
})

const InputCP = React.forwardRef<HTMLInputElement, IInputProps>(
	({ className, type, variant, label, suffixIcon, prefixIcon, required, id, ...props }, ref) => {
		if ((!variant || variant === 'default') && !!label) {
			return (
				<div className="relative mt-5 flex items-center border-b border-gray-400 peer-focus:border-gray-500">
					<input type={type} className={inputVariants({ intent: variant, className })} ref={ref} {...props} />
					<label
						htmlFor={id}
						className="pointer-events-none absolute -top-3.5 left-0 cursor-text font-normal text-foreground transition-all peer-placeholder-shown:top-2.5"
					>
						{label}&nbsp;{required && '*'}
					</label>
					{suffixIcon &&
						React.cloneElement(suffixIcon, {
							className: twMerge(suffixIcon.props.className),
						})}
				</div>
			)
		}

		return (
			<div className="relative flex items-center border-b border-gray-400 peer-focus:border-gray-500">
				{prefixIcon &&
					React.cloneElement(prefixIcon, {
						className: twMerge(prefixIcon.props.className),
					})}
				<input type={type} className={inputVariants({ intent: variant, className })} ref={ref} {...props} />
				{suffixIcon &&
					React.cloneElement(suffixIcon, {
						className: twMerge(suffixIcon.props.className),
					})}
			</div>
		)
	},
)
InputCP.displayName = 'InputCP'

export { InputCP }
