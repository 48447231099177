import { IThemeProject } from 'config/theme/project/IThemeProject'

const isDark = localStorage.getItem('theme') === 'dark'

export const ThemeProjectVX: IThemeProject = {
	logoLoginUrl: isDark ? '/logos/white-labels/vx/logo-negativa.png' : '/logos/white-labels/vx/logo-login.png',
	logoMenuUrl: '/logos/white-labels/vx/menu-icon.png',
	logoHeaderUrl: '/logos/white-labels/vx/header-icon.png',
	logoLoadingUrl: '/logos/white-labels/vx/logo.svg',

	success: '#52c41a',
	error: '#c41a1a',
	warning: '#f6c810',

	menuItemColor: '#ffffff',
	menuItemAltColor: isDark ? '#374151' : '#afcdd9',
	backgroundMain: isDark ? '#252525' : '#f1f1f1',
	backgroundHeader: isDark ? '#051a28' : '#ffffff',
	backgroundSidebar: isDark ? '#051a28' : '#0A3250',
	colorText: isDark ? '#ffffff' : '#010102',
	switchActiveBackground: isDark ? '#00C680' : '#0A3250',

	primary: isDark ? '#20505f' : '#0A3250',
	primary100: isDark ? '#99bfc9' : '#cce6ef',
	primary200: isDark ? '#6eabbd' : '#a1cfe4',
	primary300: isDark ? '#3c7a99' : '#6fa0c1',
	primary400: isDark ? '#20505f' : '#43708d',
	primary500: isDark ? '#0b1827' : '#16314e',
	primary600: isDark ? '#08141f' : '#102542',
	primary700: isDark ? '#050e18' : '#0b1d37',
	primary800: isDark ? '#030a10' : '#07132c',
	primary900: isDark ? '#02060d' : '#030e24',

	secondary: isDark ? '#007552' : '#00C680',
	secondary100: isDark ? '#90c7a4' : '#cdf5d3',
	secondary200: isDark ? '#64b98d' : '#a7f1b7',
	secondary300: isDark ? '#429977' : '#85e4a0',
	secondary400: isDark ? '#357f66' : '#6cd391',
	secondary500: isDark ? '#2a6c59' : '#57bf85',
	secondary600: isDark ? '#235b4d' : '#49a180',
	secondary700: isDark ? '#1b4941' : '#3b8678',
	secondary800: isDark ? '#133a34' : '#30706c',
	secondary900: isDark ? '#0c2c28' : '#255a5e',

	gray: isDark ? '#191919' : '#f1f1f1',
	gray100: isDark ? '#f1f1f1' : '#e2e2e2',
	gray200: isDark ? '#c9c9c9' : '#c9c9c9',
	gray300: isDark ? '#adadad' : '#adadad',
	gray400: isDark ? '#969696' : '#969696',
	gray500: isDark ? '#7f7f7f' : '#7f7f7f',
	gray600: isDark ? '#666666' : '#666666',
	gray700: isDark ? '#4c4c4c' : '#4c4c4c',
	gray800: isDark ? '#333333' : '#333333',
	gray900: isDark ? '#e2e2e2' : '#191919',

	pink: isDark ? '#ff6b81' : '#e04066',
	pink100: isDark ? '#ffcedd' : '#f2d4ce',
	pink200: isDark ? '#ffb3c1' : '#eca7a4',
	pink300: isDark ? '#ff99a6' : '#e78386',
	pink400: isDark ? '#ff8391' : '#e3677d',
	pink500: isDark ? '#ff6b81' : '#e04066',
	pink600: isDark ? '#df5971' : '#c03364',
	pink700: isDark ? '#bf485c' : '#9f275f',
	pink800: isDark ? '#9f3748' : '#7e1d57',
	pink900: isDark ? '#8b2e3e' : '#6d1651',

	orange: isDark ? '#ff8c64' : '#e5864c',
	orange100: isDark ? '#ffe6cc' : '#f6ead5',
	orange200: isDark ? '#ffccb2' : '#f2d6b0',
	orange300: isDark ? '#ffb999' : '#eebe8b',
	orange400: isDark ? '#ffaa82' : '#e9a775',
	orange500: isDark ? '#ff8c64' : '#e5864c',
	orange600: isDark ? '#dd754e' : '#c36639',
	orange700: isDark ? '#bb5f3c' : '#a04a2a',
	orange800: isDark ? '#99482b' : '#7f311d',
	orange900: isDark ? '#80391f' : '#6d2114',

	vxwindgradient: isDark ? 'linear-gradient(118deg, #315583, #587C9F, #7491B1)' : 'linear-gradient(118deg, #315583, #587C9F, #7491B1)',
}
