import { IAuthState } from 'app/redux/slices/auth/AuthSlice'
import { PersonClinicAccessTP } from 'submodules/beerads-sdk/services/clinics/person-clinic/types/PersonClinicAccessTP'

export type ClinicRelationPermissionTP = 'isUser' | 'isAdmin' | 'isDoctorGroupAdmin' | 'isDoctorGroupUser' | 'isDoctor'

/**
 */
export class ClinicPermissionUtils {
	/**
	 */
	static hasAnyPermissionsInClinic(clinicCode: number, permissions: ClinicRelationPermissionTP[], loggedUser: IAuthState['user']): boolean {
		const foundClinic = loggedUser?.clinics?.find((clinic) => clinic.code === clinicCode)

		if (!foundClinic) return false

		// Valida se possui alguma permissao na clinica especifica
		return ClinicPermissionUtils.checkAnyPermission(permissions, foundClinic.loggedUserAccess)
	}

	/**
	 */
	static hasAnyPermissionsInAnyClinic(permissions: ClinicRelationPermissionTP[], clinicPermissions: IAuthState['clinicPermissions']): boolean {
		// Valida se possui alguma permissao em alguma clinica
		return clinicPermissions ? ClinicPermissionUtils.checkAnyPermission(permissions, clinicPermissions) : false
	}

	/**
	 * Checa se possui pelo menos uma das permissoes desejadas
	 */
	private static checkAnyPermission(permissions: ClinicRelationPermissionTP[], clinicPermissions: PersonClinicAccessTP): boolean {
		if (!clinicPermissions) return false

		// A logida sera de OU em todas permissoes, ou seja, tendo ao menos uma ja da acesso
		// Buscaremos pela primeira ocorrecia verdadeira, ou seja, caso a permissao seja para acesso de usuario e seja usuario, ja eh retornado

		if (permissions.includes('isUser') && clinicPermissions.isUser) return true

		if (permissions.includes('isAdmin') && clinicPermissions.isAdmin) return true

		if (permissions.includes('isDoctorGroupAdmin') && clinicPermissions.isDoctorGroupAdmin) return true

		if (permissions.includes('isDoctorGroupUser') && clinicPermissions.isDoctorGroupUser) return true

		if (permissions.includes('isDoctor') && clinicPermissions.isDoctor) return true

		return false
	}
}
