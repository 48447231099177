import { Button } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

type ButtonItemTP = {
	icon: JSX.Element
	onClick?: () => void
	text: string
	disabled?: boolean
	hide?: boolean
	lastFromGroup?: boolean
}

interface ICPProps {
	itens: ButtonItemTP[]
}

/**
 * Grupo de botões aninhados na vertical
 */
export function ButtonFloatingMedicalReportICP(props: ICPProps): JSX.Element {
	return (
		<ButtonGroupSCP>
			<ButtonGroup>
				{props.itens
					.filter((item) => !item.hide)
					.map((item) => (
						<Button
							key={item.text}
							disabled={item.disabled}
							onClick={item.onClick}
							style={item.lastFromGroup ? { marginBottom: 20 } : undefined}
						>
							<div className="flex items-center">
								<div className="w-6">{item.icon}</div>
								<div className={'btn-text'}>{item.text}</div>
							</div>
						</Button>
					))}
			</ButtonGroup>
		</ButtonGroupSCP>
	)
}

const ButtonGroupSCP = styled.div`
	.ant-btn {
		height: 50px;
		width: 50px;
		fill: ${ThemeFrameworkCommon.white};
		color: ${ThemeFrameworkCommon.white};
		border-color: ${ThemeProject.primary};
		background-color: ${ThemeProject.primary};
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.ant-btn-group {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.ant-btn-group > .ant-btn {
		border-radius: 0 !important;
	}

	.ant-btn-group > .ant-btn:not(:first-child) {
		margin-left: 0px;
		margin-top: -1px;
	}

	.ant-btn-group > .ant-btn:hover:not(:disabled) {
		width: 270px;
		border-color: ${ThemeProject.primary};
		background-color: ${ThemeProject.primary};
		color: ${ThemeFrameworkCommon.white};
		transition: width 0.3s;

		.btn-text {
			display: block;
			margin-left: 5px;
		}
	}

	.btn-text {
		display: none;
	}

	.ant-btn[disabled],
	.ant-btn[disabled]:hover {
		background-color: ${ThemeProject.primary};
		border: solid 1px;
		opacity: 0.6;
	}

	button {
		position: relative;
	}
`
