import { JSONContent, generateJSON, useCurrentEditor } from '@tiptap/react'
import { VxWindIconCP } from 'app/components/vx-wind/vx-wind-icon/VxWindIconCP'
import { VxWindPortalCP } from 'app/components/vx-wind/vx-wind-portal/VxWindPortalCP'
import { ISpeakWithWindCPProps } from 'app/components/vx-wind/vx-wind-portal/inner/speak-with-wind/SpeakWithWindCP'
import { AppConfig } from 'config/AppConfig'
import { useSpeechToText } from 'modules/exams/components/medical-report/editor-medical-report/hooks/useSpeechToText'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { CreateReportPhysicianResponseDTO, VxWindRequests } from 'submodules/beerads-sdk/services/vx-wind'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import { useEditorDefaultExtensions } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/tip-tap-extensions/hooks/useTipTapDefaultExtensions'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { FrontEnvironmentEnum } from 'submodules/nerit-framework-utils/utils/enums/EnvironmentEnum'

interface ICPProps {
	forceShow?: boolean
	onContentChange: (json: JSONContent, html: string) => void
}
export const CALLED_VX_WIND_SERVICE_LOCAL_STORAGE_ITEM = 'CALLED_VX_WIND_SERVICE_LOCAL_STORAGE_ITEM'

export function VxWindReportAssistantCP(props: ICPProps): JSX.Element {
	const stream = useRef<MediaStream | null>()
	const websocket = useRef<WebSocket | null>()
	const [isVisible, setIsVisible] = useState(false)
	const [isFadingOut, setIsFadingOut] = useState(false)
	const [content, setContent] = useState('')
	const [isCapturing, setIsCapturing] = useState(false)
	const extensions = useEditorDefaultExtensions()
	const { editor } = useCurrentEditor()
	const request = useRequest<CreateReportPhysicianResponseDTO>()
	const params = useParams<{ examId: string }>()

	function onChangeCapturing(value: boolean): void {
		setIsCapturing(value)
	}

	function onChangeTextData(value: string): void {
		setContent(value)
	}

	const { onTrigger, volume } = useSpeechToText({
		stream,
		websocket,
		isCapturing,
		onChangeCapturing,
		onChangeTextData,
	})

	function handleVxWindClick(): void {
		onTrigger()

		if (!isVisible) {
			setIsFadingOut(true)
			setIsVisible(true)
			setContent('')
			//Transição
			setTimeout(() => {
				setIsFadingOut(false)
			}, 100)
		} else {
			setIsVisible(false)
		}
	}

	useEffect(() => {
		if (!isCapturing && content) {
			/**
			 * Verifica se há algum conteúdo no laudo, se houver, envia o conteúdo em html
			 * É necessária a verificação do conteúdo em texto, pois caso não tenha, ele irá enviar um <p> vazio
			 * e isso buga o retorno do laudo.
			 * */
			let previousContent: string | undefined = undefined

			if (editor) {
				previousContent = editor.getText() ? editor.getHTML() : undefined
			}

			request.runRequest(
				VxWindRequests.createReportPhysician({
					examId: params.examId,
					transcription: content,
					previousContent: previousContent,
				}),
			)
			//cria um item no localStorage para apresentar o modal de feedback da wind posteriormente
			localStorage.setItem(CALLED_VX_WIND_SERVICE_LOCAL_STORAGE_ITEM, 'true')
		}
	}, [isCapturing])

	useEffect(() => {
		if (request.isSuccess && request.responseData) {
			const { report } = request.responseData

			const json = generateJSON(report, extensions)
			props.onContentChange(json, report)

			if (editor) {
				editor.commands.setContent(report)

				//Transforma o texto em html e verifica se o primeiro filho é um h2, se sim, centraliza
				const doc = new DOMParser().parseFromString(report, 'text/html')
				const firstLine = doc.body.firstChild

				if (firstLine?.nodeName.startsWith('H')) {
					//Seleciona a primeira linha do editor e aplica align center
					editor.chain().focus().setTextSelection({ from: 1, to: 1 }).setTextAlign('center').setBold().run()

					//Após aplicar o align, coloca o cursor na última linha do documento
					const endPos = editor.state.doc.content.size
					editor.chain().focus().setTextSelection(endPos).run()
				}
			}

			setIsVisible(false)
		}
	}, [request.responseData])

	if (AppConfig.getInstance().environment === FrontEnvironmentEnum.DEVELOPMENT && !props.forceShow) {
		return <></>
	}

	return (
		<>
			<TooltipCP
				text={request.isAwaiting ? 'Estamos processando seu laudo...' : 'Diga "encerrar relatório" para finalizar o laudo'}
				open={isVisible || request.isAwaiting}
			>
				<WrapperSCP onClick={!request.isAwaiting ? handleVxWindClick : undefined} isVxWindActive={isVisible} isFadingOut={isFadingOut}>
					<AnimatedWrapperSCP isLoading={request.isAwaiting} volume={volume}>
						<VxWindIconCP volume={volume} isActive={isCapturing} />
					</AnimatedWrapperSCP>
				</WrapperSCP>
			</TooltipCP>

			{isVisible && (
				<VxWindPortalCP
					props={
						{
							isActive: isCapturing,
							isVisible,
							setVisibility: setIsVisible,
						} as ISpeakWithWindCPProps
					}
					type="speak-with-wind"
				/>
			)}
		</>
	)
}

const WrapperSCP = styled.div<{ isVxWindActive?: boolean; isFadingOut?: boolean }>`
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: ${(props) => (props.isVxWindActive ? 'fixed' : 'absolute')};
	z-index: 11;
	bottom: ${(props) => (props.isVxWindActive ? '30%' : '-11px')};
	right: 47%;
	box-shadow:
		0 1px 6px 0 rgba(0, 0, 0, 0.06),
		0 2px 32px 0 rgba(0, 0, 0, 0.16);
	opacity: ${(props) => (props.isFadingOut ? 0 : 1)};
	transition: opacity 100ms ease-in;
	width: ${(props) => (props.isVxWindActive ? '130px' : '48px')};
	height: ${(props) => (props.isVxWindActive ? '130px' : '48px')};
	margin-top: 8px;

	& > div > svg {
		width: ${(props) => (props.isVxWindActive ? '128px' : '48px')};
		height: ${(props) => (props.isVxWindActive ? '128px' : '48px')};
	}
	&:hover {
		transition: transform 250ms cubic-bezier(0.33, 0, 0, 1);
		transform: scale(1.1);
	}

	&:active {
		transform: scale(0.99);
	}
`

const AnimatedWrapperSCP = styled.div<{ isLoading: boolean; volume: number }>`
	${({ isLoading }) =>
		isLoading
			? `
	position: relative;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	@property --gradient-angle {
		syntax: '<angle>';
		initial-value: 0deg;
		inherits: false;
	}

	@keyframes rotation {
		0% {
			--gradient-angle: 0deg;
		}
		100% {
			--gradient-angle: 360deg;
		}
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		inset: -0.5rem;
		z-index: -1;
		background: linear-gradient(var(--gradient-angle), #0a3250, #2effc9);
		border-radius: inherit;
		animation: rotation 5s linear infinite
	}

	&::after {
		filter: blur(8px);
	}
	`
			: ''}

	& > div > svg {
		box-shadow: 0 0 ${({ volume }) => volume * 30}px ${({ volume }) => volume * 10}px rgba(255, 255, 255, 0.7);
		transition: box-shadow 0.3s ease-in-out;
	}
`
