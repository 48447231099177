import { http } from '@/services/http/http'
import { ExamSearchRequestDTO } from '@/submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ExamListItemResponseDTO } from '@/submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import { ExamsRequests } from '@/submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import { ListResponseDTO } from '@/submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'

export async function searchExamsByFilters(filters?: ExamSearchRequestDTO): Promise<ListResponseDTO<ExamListItemResponseDTO>> {
	const response = await http.request<{ data: ListResponseDTO<ExamListItemResponseDTO> }>(ExamsRequests.search(filters ?? ({} as ExamSearchRequestDTO)))

	return response.data.data
}
