import { ThemeProject } from 'config/theme/project/ThemeProject'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { TableInnerICP } from 'submodules/nerit-framework-ui/common/components/table/inner/TableInnerICP'
import { TableTopBarICP } from 'submodules/nerit-framework-ui/common/components/table/inner/TableTopBarICP'
import { ITableColumn } from 'submodules/nerit-framework-ui/common/components/table/types/ITableColumn'
import { TableSortTP } from 'submodules/nerit-framework-ui/common/components/table/types/TableSortTP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ColorUtils } from 'submodules/nerit-framework-ui/common/utils/ColorUtils'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { ISearchOrderingRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/interfaces/ISearchOrderingRequestDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestHelper } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'
import { IApiReturn } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/IApiReturn'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { OrderingEnum } from 'submodules/nerit-framework-utils/utils/enums/OrderingEnum'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import { OrUndefinedTP } from 'submodules/nerit-framework-utils/utils/types/OrUndefinedTP'

export interface TableCPProps<RowTP, FilterDto> {
	columns?: Array<ITableColumn<RowTP>>
	shouldLoadData?: number
	keepCurrentPage?: boolean
	onLoad?: (resultData: RowTP[]) => void
	footer?: (rows: RowTP[]) => JSX.Element
	onLoading?: (isLoading: boolean) => void
	apiConfig: {
		filters?: FilterDto
		requestConfigTP: (filtersDto?: FilterDto) => RequestConfigTP
		hasPagination?: boolean
		pageSize?: number
	}
	appearance?: {
		bordered?: boolean
		showHeader?: boolean
		emptyText?: string
		hideEmptyLogo?: boolean
		wrappedOnCard?: boolean
		showReloadButton?: boolean
		reloadButtonSize?: ButtonSizeTP
		title?: string
		topBarLeftContent?: React.ReactNode
		showTotalOnHeader?: boolean
		recordLabel?: string
		hasPagination?: boolean
		hideOnEmpty?: boolean
	}
	row?: {
		onClick?: (row: RowTP) => void
		onSelect?: {
			rowKey: keyof RowTP
			selectedCodes: number[]
			onSelectRow: (codes: number[]) => void
		}
		setClass?: (record: RowTP) => string
	}
	filter?: {
		position: 'center' | 'left'
		onFilterLoadedList?: (list: RowTP[], searchTxt: string) => RowTP[]
		loadedListFilterColumnName?: string
	}
	expand?: {
		expandedRowRender?: (record: RowTP, index: number, indent: number, expanded: boolean) => React.ReactNode
		onExpand?: (expanded: boolean, record: RowTP) => void
	}
	sorter?: {
		initialSorter?: ISearchOrderingRequestDTO
		onChangeSorter?: (sorter?: ISearchOrderingRequestDTO) => void
	}
}

/**
 * Tabela padrao buscada na api.
 */
export function TableFromApiCP<RowTP, FilterDto = any>(props: TableCPProps<RowTP, FilterDto>): JSX.Element {
	const [requestDto, setRequestDto] = useState<OrUndefinedTP<FilterDto>>()
	const [searchStr, setSearchStr] = useState<string>()

	const [isLoading, setIsLoading] = useState<boolean>(false)
	useEffect(() => props.onLoading?.(isLoading), [isLoading])

	const [total, setTotal] = useState<number>()
	const [list, setList] = useState<RowTP[]>()
	const [isReady, setIsReady] = useState<boolean>(false)

	useEffect(init, [props.shouldLoadData])

	/**
	 * Carrega relatorio.
	 */
	function init(): void {
		setList(undefined)
		setTotal(undefined)
		if (!props.shouldLoadData) return

		const dto: any = { ...props.apiConfig.filters }

		// Se tiver paginacao coloca a paginacao no DTO
		if (props.apiConfig.hasPagination) {
			let page = 1
			if (props.keepCurrentPage && !!(requestDto as any)?.page) page = (requestDto as any)?.page

			dto.page = page
			dto.itemsPerPage = props.apiConfig.pageSize ?? TableUtils.getDefaultPagination().pageSize
		}

		// Se tiver uma ordenacao inicial
		if (!!props.sorter?.initialSorter) {
			dto.orderingCriteria = props.sorter.initialSorter.orderingCriteria
			dto.orderingAscending = props.sorter.initialSorter.orderingAscending
		}

		setRequestDto(dto)

		doRequest(dto)
		setIsReady(true)
	}

	/**
	 * Faz o request dessa forma por questao de performance
	 */
	function doRequest(dto: any): void {
		setIsLoading(true)
		RequestHelper.runRequest<IApiReturn<ListResponseDTO<RowTP>>>(props.apiConfig.requestConfigTP(dto))
			.then((response) => {
				const result = response.data.data
				setList(result?.list)
				setTotal(result?.total)

				if (!!props.onLoad) props.onLoad(result?.list ?? [])
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	/**
	 * Ao mudar a paginacao
	 */
	function onChangePagination(currentPage: number, sorter?: TableSortTP): void {
		const dto: any = requestDto
		if (!dto) return NotificationHelper.error('Erro na paginação')

		dto.page = currentPage

		if (!!sorter) {
			dto.orderingCriteria = sorter.column
			dto.orderingAscending = sorter.order === OrderingEnum.ASC
		} else {
			dto.orderingCriteria = props.sorter?.initialSorter?.orderingCriteria
			dto.orderingAscending = props.sorter?.initialSorter?.orderingAscending
		}

		if (!!props.sorter?.onChangeSorter) {
			props.sorter.onChangeSorter({
				orderingAscending: dto.orderingAscending,
				orderingCriteria: dto.orderingCriteria,
			})
		}

		doRequest(dto)
	}

	/**
	 */
	function filterList(): RowTP[] | undefined {
		if (StringUtils.isEmpty(searchStr)) return list

		if (!!props.filter?.onFilterLoadedList) return props.filter.onFilterLoadedList(list ?? [], searchStr!)

		if (!!props.filter?.loadedListFilterColumnName)
			return list?.filter((record) => TableUtils.containsTxt(record[props.filter!.loadedListFilterColumnName!], searchStr!))

		return list
	}

	if (!isReady) return <></>

	if (props.appearance?.hideOnEmpty && list?.length === 0) return <></>

	return (
		<TableSCP isClickable={!!props.row?.onClick}>
			<TableTopBarICP
				wrappedOnCard={props.appearance?.wrappedOnCard}
				showReloadButton={props.appearance?.showReloadButton}
				loading={isLoading}
				onClick={init}
				size={props.appearance?.reloadButtonSize}
				leftContent={
					props.appearance?.topBarLeftContent ??
					(props.filter?.position === 'left' && (
						<SearchLeftWrapperSCP>
							<InputCP
								marginRight={props.appearance?.wrappedOnCard ? 10 : undefined}
								marginLeft={props.appearance?.wrappedOnCard ? 10 : undefined}
								icon={<IconICP iconName={'search'} />}
								placeholder={'Comece a digitar o nome para procurar'}
								value={searchStr}
								onChange={setSearchStr}
								width={400}
							/>
						</SearchLeftWrapperSCP>
					))
				}
				totalOnHeader={{
					total: total,
					show: props.appearance?.showTotalOnHeader ?? false,
					recordLabel: props.appearance?.recordLabel,
				}}
			/>

			{props.filter?.position === 'center' && (
				<SearchWrapperSCP>
					<InputCP
						marginRight={props.appearance?.wrappedOnCard ? 10 : undefined}
						marginLeft={props.appearance?.wrappedOnCard ? 10 : undefined}
						icon={<IconICP iconName={'search'} />}
						placeholder={'Comece a digitar o nome para procurar'}
						value={searchStr}
						onChange={setSearchStr}
					/>
				</SearchWrapperSCP>
			)}

			{props.appearance?.wrappedOnCard ? (
				<CardCP innerSpacing={'none'} bordered={false} overflow={'auto'} title={props.appearance.title}>
					<TableInnerICP
						data={filterList()}
						loading={isLoading}
						onChangePaginationOrSorter={onChangePagination}
						totalRecords={total}
						sorter={props.sorter}
						pagination={{
							hasPagination: props.appearance?.hasPagination ?? props.apiConfig.hasPagination ?? false,
							pageSize: props.apiConfig.pageSize,
							current: (requestDto as any)?.page,
						}}
						footer={props.footer}
						appearance={{
							emptyText: props.appearance?.emptyText,
							bordered: props.appearance?.bordered,
							showHeader: props.appearance?.showHeader,
							hideEmptyLogo: props.appearance?.hideEmptyLogo,
						}}
						row={props.row}
						columns={props.columns}
						expand={props.expand}
					/>
				</CardCP>
			) : (
				<TableInnerICP
					data={filterList()}
					loading={isLoading}
					onChangePaginationOrSorter={onChangePagination}
					totalRecords={total}
					sorter={props.sorter}
					pagination={{
						hasPagination: props.appearance?.hasPagination ?? props.apiConfig.hasPagination ?? false,
						pageSize: props.apiConfig.pageSize,
						current: (requestDto as any)?.page,
					}}
					footer={props.footer}
					appearance={props.appearance}
					row={props.row}
					columns={props.columns}
					expand={props.expand}
				/>
			)}
		</TableSCP>
	)
}

const TableSCP = styled.div<{ isClickable: boolean } & BasicStyleWrapperCPProps>`

  margin-left: ${(props) => props.margin?.left ?? 0}px;
  margin-right: ${(props) => props.margin?.right ?? 0}px;
  margin-bottom: ${(props) => props.margin?.bottom ?? 0}px;
  margin-top: ${(props) => props.margin?.top ?? 0}px;
  
  width: 100%;

  .row-nerit-error {
    background: ${ColorUtils.lightenColor(ThemeProject.error, 210)}; 

    :hover {
      background: ${ColorUtils.lightenColor(ThemeProject.error, 200)}; 
    }

	td {
		color: ${ThemeFrameworkCommon.black} !important;
	}
  }

  .row-disabled {
	opacity: 0.6;
    :hover {
      
    }

	td {
		color: ${ThemeFrameworkCommon.browserDefaultColorLight}; !important;
	}
  }

  ant-table-row {
    background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight}; 
  }

  .ant-table-small {
    border: none !important;
  }

  .ant-table-body {
    margin: 0 !important;
  }

  .ant-table-wrapper thead {
    tr th {
	  color: ${ThemeFrameworkCommon.browserDefaultColorDark};
      font-weight: bold;
      font-size: 0.8rem;
      padding: 12px 8px !important;
    }
  }

  .ant-table-wrapper tbody {
    tr:hover td {
      background: rgba(218, 225, 239, 0.3) !important;  // Hex: #DAE1EFF4
      cursor: ${(props) => (props.isClickable ? 'pointer' : undefined)};
    }

    tr td {
		color: ${ThemeFrameworkCommon.browserDefaultColorDark};
      	font-size: 11px;
    }

	.ant-table-row-selected td {
		color: blue; // @TODO KLEYTON
	}
  }

  .ant-table-wrapper .ant-pagination {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .n-selected-row {
    background-color: ${ThemeProject.gray};
  }
  .ant-table-placeholder {
	background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
	color: ${ThemeProject.colorText};
  }
  .ant-pagination {
	color: ${ThemeProject.colorText};
  }
  .ant-pagination-item a {
	color: ${ThemeProject.colorText};

	&:hover {
		color: ${ThemeProject.colorText};
	}
  }
  .ant-pagination-item-active {
	background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
  }
  .ant-pagination-item-ellipsis {
	color: ${ThemeProject.colorText} !important;
  }
`

const SearchWrapperSCP = styled.div`
	margin: 10px;
`

const SearchLeftWrapperSCP = styled.div`
	.ant-row {
		margin: 0;
	}
	input::placeholder {
		color: ${ThemeFrameworkCommon.browserDefaultBackgroundDark};
	}
`
