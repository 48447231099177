import { IsOptional } from 'class-validator'
import type { Moment } from 'moment'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ExamUrgencyTypeEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { GenderEnum } from 'submodules/nerit-framework-utils/utils/enums/GenderEnum'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { IsPhoneBR } from 'submodules/nerit-framework-utils/validators/decorators/IsPhoneBR'

type DescriptionTypeTP = {
	value?: number
	label: string
}

export class CreateExamFormModel extends FormModel {
	@IsRequired()
	requestingDoctor: string

	@IsRequired()
	modality: ExamModalityEnum

	@IsOptional()
	reason?: string

	@IsRequired()
	examDate: Moment

	@IsRequired()
	urgencyType: ExamUrgencyTypeEnum

	@IsRequired()
	examTime: string

	@IsRequired()
	description: DescriptionTypeTP

	@IsOptional()
	patientId: string

	@IsRequired()
	patientName: string

	@IsRequired()
	patientGender: GenderEnum

	@IsOptional()
	@IsPhoneBR()
	patientPhone: string

	@IsOptional()
	patientBirthDate?: string

	@IsOptional()
	patientFunction?: string

	@IsOptional()
	anamnesis?: string

	constructor(initial?: ObjectPropsTP<CreateExamFormModel>) {
		super(initial)
		Object.assign(this, initial)
	}
}
