import { IsOptional } from 'class-validator'
import { ExamContrastEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamContrastEnum'
import type { IPendingExamDescription } from 'submodules/beerads-sdk/services/exams/exams/types/IPendingExamDescription'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { GenderEnum } from 'submodules/nerit-framework-utils/utils/enums/GenderEnum'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'

export class ExamDataFormModel extends FormModel {
	@IsOptional()
	patientId: string

	@IsRequired()
	patientName: string

	@IsRequired()
	patientBirthday: string

	@IsRequired()
	patientGender: GenderEnum

	@IsOptional()
	contrasts: ExamContrastEnum[]

	@IsOptional()
	serviceOrder: string

	@IsOptional()
	requestingDoctor: string

	@IsOptional()
	extraInfo: string

	@IsRequired()
	anamnesis: string

	@IsRequired()
	currentExam: IPendingExamDescription

	constructor(initialData?: ObjectPropsTP<ExamDataFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
