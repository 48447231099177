import { useAppSelector } from 'app/redux/hook'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { TableTemplateUtils } from 'modules/template/components/table-templates/inner/TableTemplateUtils'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { TemplatesRequests } from 'submodules/beerads-sdk/services/templates/templates/TemplatesRequests'
import { TemplateSearchRequestDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/request/TemplateSearchRequestDTO'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'

interface ICPProps {
	onSave?: () => void
	onDelete?: () => void
	filters: {
		type: TemplateTypeEnum
		modalities?: ExamModalityEnum[]
		clinicCode?: number
		doctorGroupCode?: number
	}
	appearance?: {
		showEditButton?: boolean
		showDeleteButton?: boolean
		wrappedOnCard?: boolean
		canClickOnRow?: boolean
	}
	loadList: number
	onSelectTemplate?: (template: TemplateResponseDTO) => void
}

/**
 * Lista de templates.
 */
export function TableTemplatesCP(props: ICPProps): JSX.Element {
	const user = useAppSelector((selector) => selector.auth.user)
	const [selectedCode, setSelectedCode] = useState<number>()
	const { pathname } = useLocation()

	const [shouldLoadData, setShouldLoadData] = useState<number>(props.loadList)
	useEffect(() => setShouldLoadData(props.loadList), [props.loadList])

	function getRequestDTO(): TemplateSearchRequestDTO {
		const isClinicsTemplateScreen = pathname.includes(PrivateUserRouter.CLINIC)
		const isDoctorGroupTemplateScreen = pathname.includes(PrivateUserRouter.DOCTOR_GROUP_TEMPLATES)
		const isDoctorTemplateScreen = pathname.includes(PrivateUserRouter.TEMPLATES)

		let dtoFilters
		switch (true) {
			case isClinicsTemplateScreen:
				dtoFilters = {
					doctorGroupCode: props.filters.doctorGroupCode,
					clinicCode: props.filters.clinicCode,
				}
				break
			case isDoctorGroupTemplateScreen:
				dtoFilters = {
					doctorGroupCode: props.filters.doctorGroupCode,
				}
				break
			case isDoctorTemplateScreen:
				dtoFilters = {
					doctorCode: user?.isDoctor && user.code,
				}
				break
		}

		return {
			type: props.filters.type,
			modalities: props.filters.modalities,
			doctorGroupCode: !isClinicsTemplateScreen ? props.filters.doctorGroupCode : undefined,
			clinicCode: isClinicsTemplateScreen ? props.filters.clinicCode : undefined,
			...dtoFilters,
		}
	}

	return (
		<TableFromApiCP<TemplateResponseDTO>
			appearance={{
				wrappedOnCard: props.appearance?.wrappedOnCard,
			}}
			shouldLoadData={shouldLoadData}
			row={{
				onClick: !props.appearance?.canClickOnRow
					? undefined
					: (template) => {
							props.onSelectTemplate && props.onSelectTemplate(template)
							setSelectedCode(template.code)
						},
				setClass: (record) => (record.code === selectedCode ? 'n-selected-row' : ''),
			}}
			apiConfig={{
				requestConfigTP: () => TemplatesRequests.search(getRequestDTO()),
			}}
			filter={{ loadedListFilterColumnName: 'name', position: 'center' }}
			columns={TableTemplateUtils.getColumns(
				props.filters.type,
				() => setShouldLoadData(TableUtils.getReloadNumber()),
				props.filters.doctorGroupCode,
				props.appearance?.showDeleteButton,
				props.appearance?.showEditButton,
				props.filters.clinicCode,
				props.onSave,
				props.onDelete,
			)}
		/>
	)
}
