import { useAppSelector } from 'app/redux/hook'
import { IDoctorGroupState } from 'app/redux/slices/doctor-group/DoctorGroupSlice'
import { useEffect, useState } from 'react'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { AuthUserResponseDTO } from 'submodules/beerads-sdk/services/auth/dtos/responses/inner/AuthUserResponseDTO'
import { MedicalTimetableDoctorEventsSearchRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/MedicalTimetableDoctorEventsSearchRequestDTO'
import { MedicalTimetableDoctorEventResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/MedicalTimetableDoctorEventResponseDTO'
import { MedicalTimetableRequests } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/MedicalTimetableRequests'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { IFullCalendarEvent } from 'submodules/nerit-framework-ui/common/components/full-calendar/FullCalendarCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { ITimelineItem, TimelineCP } from 'submodules/nerit-framework-ui/common/components/timeline/TimelineCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { WeekDaysLabelEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'

export function DoctorProductivityTimeTableCP(): JSX.Element {
	const loggedUser = useAppSelector((state) => state.auth.user) as AuthUserResponseDTO
	const currentDoctorGroup = useAppSelector((state) => state.doctorGroup) as IDoctorGroupState

	const [calendarEvents, setCalendarEvents] = useState<IFullCalendarEvent[]>([])

	const getDoctorsOnMedicalTimetableRequest = useRequest<ListResponseDTO<MedicalTimetableDoctorEventResponseDTO>>()

	useEffect(onGetDoctorsOnMedicalTimetableRequest, [getDoctorsOnMedicalTimetableRequest.isAwaiting])
	useEffect(init, [])

	function init(): void {
		if (!currentDoctorGroup.code) {
			return
		}

		const filters: MedicalTimetableDoctorEventsSearchRequestDTO = {
			doctorGroupCode: currentDoctorGroup.code,
			doctorCode: loggedUser.code,
			dateRange: {
				beginDate: DateUtils.getDate(),
				endDate: DateUtils.add(DateUtils.getDate(), 7, TimeBaseEnum.DAY),
			},
		}

		getDoctorsOnMedicalTimetableRequest.runRequest(MedicalTimetableRequests.searchDoctorEvents(filters))
	}

	function onGetDoctorsOnMedicalTimetableRequest(): void {
		if (!RequestUtils.isValidRequestReturn(getDoctorsOnMedicalTimetableRequest, 'Erro ao buscar dados da escala')) {
			return
		}

		const result = getDoctorsOnMedicalTimetableRequest.responseData?.list ?? []

		setCalendarEvents(
			result.map((resultItem) => ({
				id: String(resultItem.doctor.code),
				title: resultItem.doctor.name,
				start: resultItem.beginDate,
				end: resultItem.endDate,
			})),
		)
	}

	function makeEvent(event: IFullCalendarEvent): ITimelineItem {
		const dayOfWeek = DateUtils.getFullDayOfWeek(DateUtils.toDate(event.start, DateFormatEnum.US_WITH_TIME_H_M))
		const formattedDayOfWeek = WeekDaysLabelEnum[dayOfWeek]
		const date = DateUtils.formatDate(event.start, DateFormatEnum.BR_DAY_MONTH)
		const startTime = DateUtils.formatDate(event.start, DateFormatEnum.HOUR_PATTERN)
		const endTime = DateUtils.formatDate(event.end, DateFormatEnum.HOUR_PATTERN)

		return {
			content: `${startTime} às ${endTime}`,
			icon: <AiOutlineClockCircle size={24} />,
			title: `${formattedDayOfWeek} (${date})`,
		}
	}

	return (
		<FlexCP flexDirection="column" gap={32}>
			<TextCP text="Peguei também a sua escala de trabalho dos próximos dias" center />

			<FlexCP justify="center">
				<TimelineCP items={calendarEvents.map(makeEvent)} loading={getDoctorsOnMedicalTimetableRequest.isAwaiting} />
			</FlexCP>
		</FlexCP>
	)
}
