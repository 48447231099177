import { useContext } from 'react'
import { ITipTapContext, TipTapContext } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/context/TipTapContext'

export function useTipTapContext(): ITipTapContext {
	const context = useContext(TipTapContext)

	if (!context) {
		throw new Error('TipTap context does not exist.')
	}

	return context
}
