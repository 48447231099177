import { SelectRectificationReason } from 'modules/exams/components/exam-analysis/select-rectification-reason/SelectRectificationReason'
import { TitleExamCP } from 'modules/exams/components/exams/title-exam/TitleExamCP'
import { useEffect, useState } from 'react'
import { ExamsAnalysisRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsAnalysisRequests'
import { SendExamToRectificationAnalysisRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/SendExamToRectificationAnalysisRequestDTO'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { RectificationReasonsEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/RectificationReasonsEnum'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { IBasicModalCPProps, ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

interface ICPProps extends IBasicModalCPProps {
	exam: ExamResponseDTO
}

/**
 */
export function ModalSendExamToRectificationAnalysisCP(props: ICPProps): JSX.Element {
	const [reason, setReason] = useState<string>()
	const [rectificationReason, setRectificationReason] = useState<RectificationReasonsEnum>()

	const sendExamToRectificationAnalysisRequest = useRequest<void>('none')
	useEffect(onSendExamToRectificationAnalysisRequestChange, [sendExamToRectificationAnalysisRequest.isAwaiting])

	/**
	 * Salva o exame.
	 */
	async function sendExamToPendingAnalysis(): Promise<void> {
		if (StringUtils.isEmpty(reason) || StringUtils.isEmpty(rectificationReason))
			return NotificationHelper.error('Ops', 'Preencha o motivo para prosseguir')

		const dto: SendExamToRectificationAnalysisRequestDTO = {
			examCode: props.exam.code,
			reason: reason!,
			rectificationReason: rectificationReason!,
		}
		sendExamToRectificationAnalysisRequest.runRequest(ExamsAnalysisRequests.sendExamToRectificationAnalysis(dto))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onSendExamToRectificationAnalysisRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				sendExamToRectificationAnalysisRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSave()
	}

	return (
		<ModalCP
			title={'Solicitar Retificação'}
			visible={props.visible}
			onOk={sendExamToPendingAnalysis}
			actionLoading={sendExamToRectificationAnalysisRequest.isAwaiting}
			onCancel={props.onCancel}
		>
			<FlexCP justify={'center'}>
				<TitleExamCP
					modality={props.exam.modality}
					patientName={props.exam.patient.name}
					examTitle={props.exam.description}
					examStatus={props.exam.status}
				/>
			</FlexCP>

			<SelectRectificationReason onChange={(selected) => setRectificationReason(selected)} required={true} />

			<TitleCP underLine={true} textSize={'normal'} marginTop={30}>
				Descreva o motivo da devolução do exame
			</TitleCP>
			<TextAreaCP placeholder={'Descreva o motivo do pedido de retificação'} value={reason} onChange={setReason} minRows={3} required={true} />
		</ModalCP>
	)
}
