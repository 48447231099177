import { useMemo, useRef, useState } from 'react'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ITipTapContext, TipTapContext } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/context/TipTapContext'

interface ITiptapContextProviderProps {
	examModality?: ExamModalityEnum
	canSeeWind: boolean
	children: React.ReactNode
}

export function TipTapContextProvider(props: ITiptapContextProviderProps): JSX.Element {
	const [isCapturing, setIsCapturing] = useState(false)
	const [savedPhrase, setSavedPhrase] = useState('')
	const stream = useRef<MediaStream | null>()
	const websocket = useRef<WebSocket | null>()

	function onChangeCapturing(value: boolean): void {
		setIsCapturing(value)
	}

	function onSavePhrase(value: string): void {
		setSavedPhrase(value)
	}

	const values = useMemo<ITipTapContext>(
		() => ({
			examModality: props.examModality,
			canSeeWind: props.canSeeWind,
			websocket,
			stream,
			isCapturing,
			onChangeCapturing,
			savedPhrase,
			onSavePhrase,
		}),
		[isCapturing, props.examModality, props.canSeeWind, onChangeCapturing],
	)

	return <TipTapContext.Provider value={values}>{props.children}</TipTapContext.Provider>
}
