import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import { ThemeProjectVX } from 'config/theme/project/white-labels/ThemeProjectVX'
import { IconByUrgencyTypeCP } from 'modules/exams/components/exams/icons/IconByUrgencyTypeCP'
import { IconCriticalExamCP } from 'modules/exams/components/exams/icons/IconCriticalExamCP'
import { ExamUrgencyTypeEnum, ExamUrgencyTypeLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { BlinkCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BlinkCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { SubtitleTableWrapperCP } from 'submodules/nerit-framework-ui/common/components/table/subtitle/SubtitleTableWrapperCP'
import { SubtitleTableItemICP } from 'submodules/nerit-framework-ui/common/components/table/subtitle/inner/SubtitleTableItemICP'

/**
 * Legenda da agenda com o significado das cores
 */
export function SubtitleTableExamsCP(): JSX.Element {
	return (
		<SubtitleTableWrapperCP>
			<FlexCP wrap>
				{Object.values(ExamUrgencyTypeEnum).map((urgencyType) => (
					<SubtitleTableItemICP
						key={urgencyType}
						icon={<IconByUrgencyTypeCP urgencyType={urgencyType} size={13} />}
						text={ExamUrgencyTypeLabelEnum[urgencyType]}
						color={ThemeProjectCommon.examUrgentTypeColor[urgencyType]}
					/>
				))}

				<SubtitleTableItemICP icon={<IconCriticalExamCP size={13} />} text={'Achado crítico'} />

				<SubtitleTableItemICP icon={<IconICP size={13} iconName={'clock-circle'} theme={'twoTone'} />} text={'SLA em dia'} color={'transparent'} />
				<SubtitleTableItemICP
					icon={<IconICP size={13} iconName={'fire'} theme={'twoTone'} color={ThemeProjectVX.error} />}
					text={'SLA comprometido'}
					color={'transparent'}
				/>
				<SubtitleTableItemICP
					icon={
						<BlinkCP>
							<IconICP size={13} iconName={'clock-circle'} theme={'twoTone'} color={ThemeProjectVX.error} />
						</BlinkCP>
					}
					text={'SLA comprometido ou faltando 30min'}
				/>
				<SubtitleTableItemICP text={'SLA < 2h'} color={ThemeProjectCommon.examSlaColor.sla2h} />
				<SubtitleTableItemICP text={'SLA < 6h'} color={ThemeProjectCommon.examSlaColor.sla6h} />
				<SubtitleTableItemICP text={'SLA < 24h'} color={ThemeProjectCommon.examSlaColor.sla24h} />
			</FlexCP>
		</SubtitleTableWrapperCP>
	)
}
