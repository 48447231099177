import React, { CSSProperties, ReactElement, Suspense } from 'react'
import styled from 'styled-components'
import * as Icons from './IconLibrary'

export type IconNamesTP = keyof typeof Icons

interface ICPProps {
	iconName: IconNamesTP
	className?: string
	variant?: 'primary' | 'secondary'
	disabled?: boolean
	style?: CSSProperties
}

const Icon = ({ iconName, ...properties }: ICPProps): ReactElement => {
	const icon = Icons[iconName]

	return (
		<Suspense fallback={<div />}>
			{icon &&
				React.createElement(icon, {
					...properties,
					id: iconName,
					name: iconName,
				})}
		</Suspense>
	)
}

const IconSCP = styled(Icon)``

export function IconCP({ ...properties }: ICPProps): ReactElement {
	return <IconSCP {...properties} />
}
