import { Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { IconCP } from 'submodules/nerit-framework-ui/common/components/icon/IconCP'
import { LoadingIconCP } from 'submodules/nerit-framework-ui/common/components/icon/icons/LoadingIconCP'
import { FontStyleTP } from 'submodules/nerit-framework-ui/common/components/text/inner/FontStyleTP'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

type SharedPropsTP = {
	size?: FontSizeTP
	center?: boolean
	color?: string
	wrap?: boolean
	marginRight?: number
	marginLeft?: number
	marginTop?: number
	marginBottom?: number
	fontStyle?: FontStyleTP
	onClick?: () => void
}

type AntPropsTP = {
	underline?: boolean
	elipsis?: boolean
	delete?: boolean
	strong?: boolean
	copyable?: boolean
}

type CustomPropsTP = {
	text?: string | number
	onCopy?: () => void
	style?: React.CSSProperties
	icon?: string | JSX.Element // Icone do Ant ou Elemento
	loading?: boolean
	shouldRender?: boolean
}

interface ITextCPProps extends SharedPropsTP, CustomPropsTP, AntPropsTP {}

/**
 * Normaliza exbicao de texto (wrapper do componente de tipografia do antd).
 */
export function TextCP(props: ITextCPProps): JSX.Element {
	if (props.shouldRender === false) return <></>

	return (
		<WrapperSCP
			wrap={props.wrap ?? true}
			color={props.color}
			size={props.size}
			center={!!props.center}
			style={props.style}
			marginTop={props.marginTop ?? 0}
			marginRight={props.marginRight ?? 0}
			marginBottom={props.marginBottom ?? 0}
			marginLeft={props.marginLeft ?? 0}
			onClick={props.onClick}
			fontStyle={props.fontStyle}
		>
			{props.loading && (
				<>
					<LoadingIconCP size={10} color={props.color} />
					<span style={{ marginRight: '5px' }} />
				</>
			)}
			{props.icon && !props.loading && (
				<>
					{typeof props.icon === 'string' ? <IconCP antIcon={props.icon} color={props.color} /> : props.icon}
					<span style={{ marginRight: '5px' }} />
				</>
			)}
			<Typography.Text
				underline={props.underline}
				ellipsis={!!props.elipsis}
				delete={props.delete}
				strong={props.strong}
				copyable={!!props.onCopy ? { onCopy: props.onCopy } : !!props.copyable}
			>
				{props.text}
			</Typography.Text>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div<SharedPropsTP>`
	white-space: ${(props) => (!!props.wrap ? 'normal' : 'nowrap')};
	display: flex;
	margin: ${(props) => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
	align-items: center;
	cursor: ${(props) => (!!props.onClick ? 'pointer' : undefined)};

	.ant-typography {
		flex: 1;
		color: ${(props) => (!!props.color ? props.color : 'unset')};
		font-size: ${(props) => ThemeFrameworkCommon.fontSizes[props.size ?? 'normal']};
		text-align: ${(props) => (!!props.center ? 'center' : 'inherit')};
		font-style: ${(props) => props.fontStyle};

		strong {
			font-weight: 700;
		}
	}
`
