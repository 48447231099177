import { type Action, configureStore, type ThunkAction } from '@reduxjs/toolkit'
import { persistedReducer, type RootStateTP } from 'app/redux/persistor'
import { PERSIST, REHYDRATE } from 'redux-persist'

export const store = configureStore({
	reducer: persistedReducer,
	devTools: import.meta.env.DEV,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [PERSIST, REHYDRATE],
			},
			thunk: true,
		}),
})

// Infer the type of `store`
export type AppStore = typeof store

// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore['dispatch']

export type AppThunk<ThunkReturnType = void> = ThunkAction<ThunkReturnType, RootStateTP, unknown, Action>
