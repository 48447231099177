import { useAppSelector } from 'app/redux/hook'
import { DrawerDoctorGroupDataCP } from 'modules/doctor-groups/components/doctor-groups/drawer-doctor-group-data/DrawerDoctorGroupDataCP'
import { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'

/**
 */
export function ButtonAddDoctorGroupCP(): JSX.Element {
	const [isDoctorGroupModalVisible, setIsDoctorGroupModalVisible] = useState<boolean>(false)
	const loggedUser = useAppSelector((state) => state.auth.user)

	if (!loggedUser?.isBeeradsAdmin) return <></>

	return (
		<>
			<ButtonCP onClick={() => setIsDoctorGroupModalVisible(true)} marginRight={10} icon={'plus'}>
				Novo Grupo de Médicos
			</ButtonCP>

			<DrawerDoctorGroupDataCP
				visible={isDoctorGroupModalVisible}
				onCancel={() => setIsDoctorGroupModalVisible(false)}
				onSave={() => setIsDoctorGroupModalVisible(false)}
			/>
		</>
	)
}
