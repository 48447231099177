import styled from 'styled-components'

export const Editor = styled.div`
	display: flex;
	margin: 0 20px;
	justify-content: center;

	.editor-wrapper {
		width: calc(100% - 40px);
		margin-inline: 20px;
	}
`

export const TextAareaWrapperSCP = styled.div`
	padding: 16px;
`
