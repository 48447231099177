import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { HTMLAttributes, ReactNode, useEffect } from 'react'
import { FieldValues, FormProvider, SubmitHandler, useForm, UseFormProps, UseFormReturn } from 'react-hook-form'

type OmitHtmlFormAttributes = Omit<HTMLAttributes<HTMLFormElement>, 'onSubmit' | 'defaultValue'>

interface ISmartFormProps<TInput extends FieldValues = FieldValues> extends OmitHtmlFormAttributes {
	children: ReactNode
	formModel: new () => TInput
	isNestedForm?: boolean
	onSubmit?: SubmitHandler<TInput>
	options?: Omit<UseFormProps<TInput>, 'defaultValues' | 'resolver'>
	defaultValues?: UseFormProps<TInput>['defaultValues']
	resetFormAfterSubmit?: Parameters<UseFormReturn<TInput>['reset']>[0]
}

/**
 * Componente de formulário integrado com o React Hook Form
 */
export function SmartFormCP<TInput extends FieldValues = FieldValues>({
	children,
	isNestedForm = false,
	onSubmit,
	formModel,
	options,
	defaultValues,
	resetFormAfterSubmit,
	...formProps
}: ISmartFormProps<TInput>): JSX.Element {
	const form = useForm<TInput>({
		...options,
		defaultValues,
		resolver: classValidatorResolver(formModel),
	})

	const { isSubmitSuccessful } = form.formState

	useEffect(() => {
		if (resetFormAfterSubmit) {
			form.reset(resetFormAfterSubmit)
		}
	}, [resetFormAfterSubmit, isSubmitSuccessful])

	return (
		<FormProvider {...form}>
			<form
				{...formProps}
				noValidate
				onSubmit={
					onSubmit
						? (ev) => {
								if (isNestedForm) {
									ev.stopPropagation()
								}
								form.handleSubmit(onSubmit)(ev)
						  }
						: undefined
				}
			>
				{children}
			</form>
		</FormProvider>
	)
}
