import { PendingExamFormModel } from 'modules/exams/components/exams/drawer-exam-pending/inner/form-models/PendingExamFormModel'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { SelectGenderCP } from 'submodules/nerit-framework-ui/features/people/components/select-gender/SelectGenderCP'

interface ICPProps {
	formStateManager: IFormStateManager<PendingExamFormModel>
}

/**
 * Drawer para triar um exam.
 */
export function FormExamPendingPatientDataCP(props: ICPProps): JSX.Element {
	return (
		<>
			<TitleCP textSize={'normal'} underLine={true}>
				Dados do Paciente
			</TitleCP>
			<RowCP>
				<ColumnCP size={4}>
					<InputCP label={'ID'} formStateManager={props.formStateManager} fieldName={'patientId'} />
				</ColumnCP>
				<ColumnCP size={8}>
					<InputCP label={'Nome'} formStateManager={props.formStateManager} fieldName={'patientName'} required={true} />
				</ColumnCP>
				<ColumnCP size={6}>
					<InputCP
						label={'Data de Nascimento'}
						mask={InputMaskTypeEnum.DATE}
						formStateManager={props.formStateManager}
						fieldName={'patientBirthday'}
						required={true}
					/>
				</ColumnCP>
				<ColumnCP size={6}>
					<SelectGenderCP required={true} formStateManager={props.formStateManager} fieldName={'patientGender'} />
				</ColumnCP>
			</RowCP>
		</>
	)
}
