import { TeamFormModel } from 'modules/doctor-groups/components/teams/drawer-team/inner/TeamFormModel'
import { ModalTeamRuleCP } from 'modules/doctor-groups/components/teams/modal-team-rule/ModalTeamRuleCP'
import { TeamRulesDescriptionCP } from 'modules/doctor-groups/components/teams/team-rule-description/TeamRulesDescriptionCP'
import { useState } from 'react'
import { TeamRuleTP } from 'submodules/beerads-sdk/services/doctor-groups/teams/types/TeamRuleTP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
	formStateManager: IFormStateManager<TeamFormModel>
}

/**
 * Drawer de time de um grupo de medicos.
 */
export function TeamRulesICP(props: ICPProps): JSX.Element {
	const [isRuleModalVisible, setIsRuleModalVisible] = useState<boolean>(false)
	const [selectedRuleIndex, setSelectedRuleIndex] = useState<number>()
	const [selectedRule, setSelectedRule] = useState<TeamRuleTP>()

	/**
	 * Ao salvar uma regra
	 */
	function onSaveRule(rule: TeamRuleTP): void {
		const rules = props.formStateManager.getFieldValue('rules') ?? []
		if (selectedRuleIndex !== undefined) rules.splice(selectedRuleIndex, 1)

		rules.push(rule)
		props.formStateManager.changeFieldValue('rules', [...rules])
		setIsRuleModalVisible(false)
	}

	return (
		<>
			<TeamRulesDescriptionCP
				rules={props.formStateManager.getFieldValue('rules')}
				onEdit={(rule, index) => {
					setSelectedRule(rule)
					setSelectedRuleIndex(index)
					setIsRuleModalVisible(true)
				}}
				onDelete={(index) => {
					const rules = props.formStateManager.getFieldValue('rules')
					rules.splice(index, 1)
					props.formStateManager.changeFieldValue('rules', [...rules])
				}}
			/>

			<FlexCP justify={'center'} margin={{ top: 30 }}>
				<ButtonCP
					icon={'plus'}
					size={'small'}
					onClick={() => {
						setSelectedRule(undefined)
						setSelectedRuleIndex(undefined)
						setIsRuleModalVisible(true)
					}}
				>
					Adcionar Regra
				</ButtonCP>
			</FlexCP>

			<ModalTeamRuleCP visible={isRuleModalVisible} onSave={onSaveRule} onCancel={() => setIsRuleModalVisible(false)} rule={selectedRule} />
		</>
	)
}
