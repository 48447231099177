import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

type QueryParams<T = Record<string, unknown>> = Partial<T>

type QueryParamValue<T extends Record<string, unknown>> = T[keyof T]

type UseQueryParamsHook<T extends Record<string, unknown>> = [QueryParams<T>, (value: QueryParams<Partial<T>>) => void]

interface IUseQueryParamsOptions<T> {
	parsers?: Partial<Record<keyof T, (value: string) => any>>
	defaultValues?: Partial<T>
}

/**
 * Hook that allows you to read and update query parameters of the current URL, with optional parsing functions.
 *
 * @param options - Object containing optional parsers for specific query parameters.
 * @returns An array with two values:
 * 1. An object with all the current query parameters.
 * 2. A function that updates the query parameters. It receives an object with the query parameters to be updated.
 * If the object is undefined, all query parameters are removed.
 *
 * @example
 * const [queryParams, setQueryParam] = useQueryParams<{ foo: string; bar: number }>({
 *   parsers: { bar: (value) => parseInt(value, 10) },
 *   defaultValues: { foo: 'default' },
 * })
 *
 * setQueryParam({ foo: 'xyz' })
 */
export function useQueryParams<T extends Record<string, unknown>>(
	options: IUseQueryParamsOptions<T> = {
		defaultValues: {},
		parsers: {},
	},
): UseQueryParamsHook<T> {
	const { parsers, defaultValues } = options
	const location = useLocation()
	const history = useHistory()

	const getQueryParams = useCallback((): QueryParams<T> => {
		const searchParams = new URLSearchParams(location.search)
		const params = { ...defaultValues } as QueryParams<T>

		searchParams.forEach((value, key) => {
			const parsedValue = parsers?.[key as keyof T] ? parsers[key as keyof T]?.(decodeURI(value)) : decodeURI(value)

			params[key as keyof T] = parsedValue as QueryParamValue<T>
		})

		return params
	}, [location.search])

	const setQueryParam = useCallback(
		(queryParam: QueryParams<T> | undefined) => {
			const searchParams = new URLSearchParams(location.search)

			if (!queryParam) {
				history.replace({ search: '' })
				return
			}

			for (const [key, value] of Object.entries(queryParam)) {
				// Remove null and undefined values from the query params
				if (value === null || value === undefined) {
					searchParams.delete(key)
					continue
				}

				// Use default behavior if no stringifier is available
				searchParams.set(key, typeof value === 'object' ? JSON.stringify(value) : value)
			}

			history.replace({ search: searchParams.toString() })
		},
		[location.search, history],
	)

	const queryParams = useMemo(() => getQueryParams(), [getQueryParams])

	return [queryParams, setQueryParam]
}
