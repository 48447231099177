import { Cascader } from 'antd'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormItemICP } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/FormItemICP'
import { LoadingIconCP } from 'submodules/nerit-framework-ui/common/components/icon/icons/LoadingIconCP'

export type CascaderValueTP = [] | [string] | [string, string] | [string, string, string]

export type CascaderOptionTP = {
	value: string
	label: string | JSX.Element
	disabled?: boolean
	children?: CascaderOptionTP[]
}

interface ICascaderCPProps {
	options: CascaderOptionTP[]
	onChange: (value: CascaderValueTP, selectedOptions?: CascaderOptionTP[]) => void
	value?: CascaderValueTP
	label: string
	loading?: boolean
	appearance?: {
		prefixIcon?: JSX.Element
		size?: 'default' | 'large' | 'small'
		allowClear?: boolean
		disabled?: boolean
	}
}

/**
 */
export function SelectCascaderCP(props: ICascaderCPProps): JSX.Element {
	const [selectionValue, setSelectionValue] = useState<CascaderValueTP>([])
	useEffect(() => setSelectionValue(props.value ?? []), [props.value])

	/**
	 */
	function onChange(value: string[]): void {
		setSelectionValue(value as CascaderValueTP)
		props.onChange(value as CascaderValueTP)
	}

	return (
		<WrapperSCP>
			{!!props.loading && (
				<IconWrapper>
					<LoadingIconCP size={14} />
				</IconWrapper>
			)}
			{!!props.appearance?.prefixIcon && !props.loading && <IconWrapper>{props.appearance?.prefixIcon}</IconWrapper>}

			<FormItemICP hideBorder={true} label={''} onChange={onChange}>
				<Cascader
					options={props.options}
					placeholder={props.label}
					value={selectionValue}
					size={props.appearance?.size}
					onChange={onChange}
					allowClear={props.appearance?.allowClear}
					disabled={props.loading ?? props.appearance?.disabled}
				/>
			</FormItemICP>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	.ant-cascader-picker {
		width: 600px;

		.ant-cascader-picker-label,
		input {
			padding-left: 30px;
		}
	}

	.ant-form-item-label {
		margin-bottom: 12px !important;
	}
`
const IconWrapper = styled.div`
	z-index: 9;
	margin-right: -25px;
	margin-top: 25px;
`
