import { useAppSelector } from 'app/redux/hook'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import { ThemeProjectVX } from 'config/theme/project/white-labels/ThemeProjectVX'
import { MedicalReportFormModel } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModel'
import { MedicalReportFormModelConverter } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModelConverter'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { ExamModalityLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { ListCP } from 'submodules/nerit-framework-ui/common/components/list/ListCP'
import { ListItemICP } from 'submodules/nerit-framework-ui/common/components/list/inner/ListItemICP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { GenderLabelEnum } from 'submodules/nerit-framework-utils/utils/enums/GenderEnum'

interface ICPProps {
	exam: ExamResponseDTO
	formStateManager: IFormStateManager<MedicalReportFormModel>
}

/**
 */
export function ListMedicalReportCompliance(props: ICPProps): JSX.Element {
	// Procura por palavras nao permitidas para o sexo do paciente
	const genderBlockedWords = MedicalReportFormModelConverter.findGenderBlockedWords(props.exam, props.formStateManager)

	const authData = useAppSelector((selector) => selector.auth)

	return (
		<ListCP<ExamResponseDTO>
			dataSource={[props.exam]}
			renderItem={(exam) => (
				<>
					{props.exam.digitalCertification?.isDigitalCertificateRequired && (
						<ListItemICP
							avatar={
								!authData.isLoggedWithDigitalCertificate ? (
									<IconICP iconName={'warning'} size={28} theme={'twoTone'} color={ThemeProjectVX.error} />
								) : (
									<IconICP
										iconName={ThemeProjectCommon.icons.digitalCertificate}
										size={28}
										theme={'twoTone'}
										color={ThemeProject.success}
									/>
								)
							}
							title={'Assinatura Digital'}
							content={
								!authData.isLoggedWithDigitalCertificate
									? 'Essa clínica exige assinatura digital e você não está autenticado com seu BirdID, o laudo não poderá ser impresso'
									: 'Esse laudo será assinado digitalmente'
							}
						/>
					)}
					<ListItemICP
						avatar={<IconICP iconName={'check-circle'} size={28} theme={'twoTone'} color={ThemeProject.success} />}
						title={'Paciente'}
						content={exam.patient.name}
					/>
					<ListItemICP
						avatar={<IconICP iconName={'check-circle'} size={28} theme={'twoTone'} color={ThemeProject.success} />}
						title={'Exame'}
						content={`${ExamModalityLabelEnum[exam.modality]} ${exam.description}`}
					/>
					{!ArrayUtils.isEmpty(genderBlockedWords) && (
						<ListItemICP
							avatar={<IconICP iconName={'close-circle'} size={28} theme={'twoTone'} color={ThemeProjectVX.error} />}
							title={'Palavra de sexo oposto encontrada'}
							content={`A(s) palavra(s): "${genderBlockedWords.join(', ')}" não condizem com o sexo do paciente "${
								GenderLabelEnum[props.exam.patient.gender]
							}"`}
						/>
					)}
				</>
			)}
		/>
	)
}
