import { TableTesteCP } from '@/modules/template/components/table-templates/TableTesteCP'
import { ScreenContentDevIntercom } from 'app/screens/screen-dev/content/ScreenContentDevIntercom'
import { ScreenContentDevMedicalReportEditor } from 'app/screens/screen-dev/content/ScreenContentDevMedicalReportEditor'
import { ScreenContentDevTestLoggedUser } from 'app/screens/screen-dev/content/ScreenContentDevTestLoggedUser'
import { ScreenContentDevTestPublicLinks } from 'app/screens/screen-dev/content/ScreenContentDevTestPublicLinks'
import { DrawerClinicCP } from 'modules/clinic/components/drawer-clinic-data/DrawerClinicCP'
import { ModalClinicLogoCP } from 'modules/clinic/components/modal-clinic-logo/ModalClinicLogoCP'
import { ModalNewClinicCP } from 'modules/clinic/components/modal-new-clinic/ModalNewClinicCP'
import { DrawerClinicDoctorGroupCP } from 'modules/doctor-groups/components/clinic-doctor-groups/drawer-clinic-doctor-group/DrawerClinicDoctorGroupCP'
import { ModalAddPersonToDoctorGroupCP } from 'modules/doctor-groups/components/doctor-groups/modal-add-person-doctor-group/ModalAddPersonToDoctorGroupCP'
import { ModalExamDescriptionCP } from 'modules/doctor-groups/components/exam-descriptions/modal-exam-description/ModalExamDescriptionCP'
import { DrawerTeamCP } from 'modules/doctor-groups/components/teams/drawer-team/DrawerTeamCP'
import { DrawerExamPendingAnalysisCP } from 'modules/exams/components/exam-analysis/drawer-exam-pending-analysis/DrawerExamPendingAnalysisCP'
import { DrawerExamPendingRectificationAnalysisCP } from 'modules/exams/components/exam-analysis/drawer-exam-pending-rectification-analysis/DrawerExamPendingRectificationAnalysisCP'
import { DrawerExamReturnedCP } from 'modules/exams/components/exam-analysis/drawer-exam-returned/DrawerExamReturnedCP'
import { DrawerCreateExamCP } from 'modules/exams/components/exams/drawer-create-exam/DrawerCreateExamCP'
import { DrawerExamDetailsCP } from 'modules/exams/components/exams/drawer-exam-details/DrawerExamDetailsCP'
import { DrawerExamPendingCP } from 'modules/exams/components/exams/drawer-exam-pending/DrawerExamPendingCP'
import { ModalExamAssignCP } from 'modules/exams/components/exams/modal-exam-assign/ModalExamAssignCP'
import { ModalExamsSuggestionsCP } from 'modules/exams/components/exams/modal-exams-suggestions/ModalExamsSuggestionsCP'
import { ModalFinalizeMedicalReportCP } from 'modules/exams/components/medical-report/modal-finalize-medical-report/ModalFinalizeMedicalReportCP'
import { MedicalReportFormModel } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModel'
import { ModalMedicalTimetableAssignCP } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/ModalMedicalTimetableAssignCP'
import { useState } from 'react'
import 'react-photo-view/dist/react-photo-view.css'
import { useLocation } from 'react-router-dom'
import { AuthRequests } from 'submodules/beerads-sdk/services/auth/AuthRequests'
import { MedicalTimetableIdEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableIdEnum'
import { MedicalTimetablePeriodEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetablePeriodEnum'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ExamUrgencyTypeEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { ModalSpeedTest } from 'submodules/nerit-framework-ui/features/common/components/modal-speed-test/ModalSpeedTest'
import { ButtonSetPasswordCP } from 'submodules/nerit-framework-ui/features/user/components/set-password-modal/ButtonSetPasswordCP'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'
import { GenderEnum } from 'submodules/nerit-framework-utils/utils/enums/GenderEnum'

/**
 * Pagina para testar componentes de forma rapida
 *
 * Chamar na ROTA: /dev-test
 */
export function ScreenDevTest(): JSX.Element {
	const [formModel] = useState<MedicalReportFormModel>(
		new MedicalReportFormModel({
			medicalReports: [
				{
					reportCode: 100,
					html: '',
				} as any,
			],
		}),
	)
	const formStateManager = useFormStateManager<any>(formModel)

	const routeLocation = useLocation()
	const params = new URLSearchParams(routeLocation.search)

	function doNothing(): void {
		NotificationHelper.info('Do Nothing')
	}

	if (!!params.get('publicLinks')) return <ScreenContentDevTestPublicLinks />

	if (!!params.get('editor')) return <ScreenContentDevMedicalReportEditor />

	if (!!params.get('loggedUser')) return <ScreenContentDevTestLoggedUser />

	if (!!params.get('intercom')) return <ScreenContentDevIntercom />

	return (
		<div className="overflow-auto">
			<TableTesteCP />
			{/* <ModalAuthDigitalCertificateCP visible={false} onCancel={doNothing} onSuccess={doNothing} /> */}

			<DrawerCreateExamCP visible={false} onClose={doNothing} />
			<DrawerExamPendingCP visible={false} examCode={718469} examId={'1'} clinicCode={315} onCancel={doNothing} onSave={doNothing} />
			<DrawerExamPendingAnalysisCP visible={false} examCode={752936} examId={'1'} onCancel={doNothing} onSave={doNothing} />
			<DrawerExamPendingRectificationAnalysisCP visible={false} examCode={753030} examId={'1'} onCancel={doNothing} onSave={doNothing} />
			<DrawerExamReturnedCP visible={false} examCode={700990} examId={'1'} onCancel={doNothing} onSave={doNothing} />
			<DrawerExamDetailsCP visible={false} examId={'1'} onClose={doNothing} />

			<ModalExamAssignCP
				isVisible={false}
				onCancel={doNothing}
				onSave={doNothing}
				examCodes={[732654]}
				assignedDoctorCodes={[222887]}
				doctorGroupCode={142}
			/>
			<ModalFinalizeMedicalReportCP
				visible={false}
				exam={{ patient: { gender: GenderEnum.FEMALE } } as any}
				formStateManager={formStateManager}
				onCancel={doNothing}
			/>
			<ModalExamsSuggestionsCP
				visible={false}
				exam={
					{
						patient: {
							name: 'LARISSA',
							gender: GenderEnum.FEMALE,
							birthday: '2022-04-02',
						},
						clinic: { code: 100 },
						doctorGroup: { code: 142 },
					} as any
				}
			/>

			<ModalMedicalTimetableAssignCP
				dayOfWeek={WeekDaysEnum.FRIDAY}
				visible={false}
				editData={{ doctorCode: 224137, type: 'recurrent' }}
				doctorGroupCode={142}
				period={MedicalTimetablePeriodEnum.MORNING}
				modality={ExamModalityEnum.MR}
				groupBy={ExamUrgencyTypeEnum.URGENT}
				id={MedicalTimetableIdEnum.URGENT}
				date={new Date()}
				onCancel={doNothing}
				onSave={doNothing}
			/>

			<ModalNewClinicCP visible={false} onCancel={doNothing} onSave={doNothing} doctorGroupCode={116} />
			<DrawerClinicCP visible={false} onCancel={doNothing} onSave={doNothing} code={235} />
			<ModalClinicLogoCP visible={false} onCancel={doNothing} clinicCode={235} onSave={doNothing} />

			<ModalExamDescriptionCP visible={false} doctorGroupCode={140} onCancel={doNothing} onSave={doNothing} />
			<DrawerTeamCP visible={false} onCancel={doNothing} onSave={doNothing} doctorGroupCode={142} />

			<DrawerClinicDoctorGroupCP visible={false} clinicCode={250} doctorGroupCode={142} onClose={doNothing} />
			<ModalAddPersonToDoctorGroupCP visible={false} onCancel={doNothing} onSave={doNothing} doctorGroupCode={140} role={UserTypeEnum.DOCTOR} />

			<ButtonSetPasswordCP requestConfig={() => AuthRequests.setPassword(100)} hasPermission={false} />

			<ModalSpeedTest visible={false} onClose={doNothing} />
		</div>
	)
}
