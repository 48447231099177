import { createSlice } from '@reduxjs/toolkit'
import { logoutReducer } from 'app/redux/slices/auth/reducers/logoutReducer'
import { setLoggedPatientReducer } from 'app/redux/slices/auth/reducers/setLoggedPatient'
import { setLoggedUserReducer } from 'app/redux/slices/auth/reducers/setLoggedUser'
import { AuthResponseDTO } from 'submodules/beerads-sdk/services/auth/dtos/responses/AuthResponseDTO'
import { PersonClinicAccessTP } from 'submodules/beerads-sdk/services/clinics/person-clinic/types/PersonClinicAccessTP'
import { UserDoctorGroupAccessTP } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/types/UserDoctorGroupAccessTP'
import { PatientPortalLoginResponseDTO } from 'submodules/beerads-sdk/services/patient-portal/dtos/response/PatientPortalLoginResponseDTO'

export interface IAuthState extends Omit<AuthResponseDTO, 'user'> {
	clinicPermissions: PersonClinicAccessTP | null
	doctorGroupPermissions: UserDoctorGroupAccessTP | null
	user: AuthResponseDTO['user'] | null
	patientPortal: PatientPortalLoginResponseDTO | null
}

const initialState: IAuthState = {
	token: '',
	user: null,
	version: '',
	isLoggedWithDigitalCertificate: false,
	clinicPermissions: null,
	doctorGroupPermissions: null,
	patientPortal: null,
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: (reducerCreator) => ({
		setLoggedUser: reducerCreator.reducer(setLoggedUserReducer),
		setLoggedPatient: reducerCreator.reducer(setLoggedPatientReducer),
		logout: reducerCreator.reducer(logoutReducer),
	}),
})

export const authActions = authSlice.actions
