import { useAppSelector } from 'app/redux/hook'
import { DashboardGraphCardCP } from 'modules/reports/components/chart-exams/inner/DashboardGraphCardCP'
import { CommonReportsFilterFormModel } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModel'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DashboardRequests } from 'submodules/beerads-sdk/services/dashboard/dashboard/DashboardRequests'
import { ExamsByPeriodRequestDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/request/ExamsByPeriodRequestDTO'
import { ExamsByPeriodResponseDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/response/ExamsByPeriodResponseDTO'
import { ReportTypeEnum } from 'submodules/beerads-sdk/services/dashboard/dashboard/types/ReportTypeEnum'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'

interface ICPProps {
	filterStateManager: IFormStateManager<CommonReportsFilterFormModel>
	reload: number
	reportType: ReportTypeEnum
	cardMargin?: string
}

/**
 */
export function ChartExamsCP(props: ICPProps): JSX.Element {
	const [timeBase, setTimeBase] = useState<TimeBaseEnum>(TimeBaseEnum.DAY)
	const [modality, setModality] = useState<ExamModalityEnum>()

	const [loadedData, setLoadedData] = useState<ExamsByPeriodResponseDTO>()
	const graphDataRequest = useRequest<ExamsByPeriodResponseDTO>()
	const loggedUser = useAppSelector((state) => state.auth.user)
	const [dataType, setDataType] = useState(props.filterStateManager.getFieldValue('shouldConsiderMedicalReportDate') ? 'Laudos' : 'Exames')

	useEffect(onGraphDataRequestChange, [graphDataRequest.isAwaiting])

	useEffect(requestGraphData, [modality, timeBase, props.reload])

	/**
	 */
	function requestGraphData(): void {
		if (!props.reload) return

		const formValues = props.filterStateManager.getFormValues()

		const filters: ExamsByPeriodRequestDTO = {
			reportType: props.reportType,
			shouldConsiderMedicalReportDate: formValues?.shouldConsiderMedicalReportDate ?? false,
			dateRange: formValues?.dateRange,
			timeBase: timeBase,
			clinicCodes: formValues?.clinicCodes,
			modalities: modality ? [modality] : undefined,
			considerArchived: formValues?.considerArchived,
			doctorCode: props.reportType === ReportTypeEnum.DOCTOR_REPORT ? loggedUser?.code : formValues?.doctorCode,
			doctorGroupCode: formValues?.doctorGroupCode,
		}

		graphDataRequest.runRequest(DashboardRequests.examsByPeriod(filters))
	}

	/**
	 */
	function onGraphDataRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(graphDataRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		setLoadedData(graphDataRequest.responseData)
		setDataType(props.filterStateManager.getFieldValue('shouldConsiderMedicalReportDate') ? 'Laudos' : 'Exames')
	}

	return (
		<WrapperSCP>
			<DashboardGraphCardCP
				title={dataType + ' por período'}
				graphBarsLabel={dataType}
				byModalityLists={loadedData?.byModality ?? []}
				byPeriodLists={loadedData?.byPeriod ?? []}
				currentTimeBase={timeBase}
				dateRange={props.filterStateManager.getFieldValue('dateRange')}
				onTimeBaseChange={setTimeBase}
				onModalityChange={setModality}
				currentModality={modality}
				loading={graphDataRequest.isAwaiting}
				cardMargin={props.cardMargin}
			/>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	background-color: transparent;

	.ant-card {
		background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
		color: ${ThemeFrameworkCommon.browserDefaultBackgroundDark};

		.ant-card-head {
			color: ${ThemeFrameworkCommon.browserDefaultBackgroundDark};
		}

		.ant-card-body {
			color: ${ThemeFrameworkCommon.browserDefaultBackgroundDark};
		}
	}
`
