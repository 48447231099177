import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { AutocompletePickerUtils } from 'submodules/nerit-framework-ui/common/components/form-fields/autocomplete-picker/inner/AutocompletePickerUtils'
import { IAutocompleteCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/autocomplete-picker/inner/IAutocompleteCommonProps'
import { SelectFullOptionTP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { IconCP } from 'submodules/nerit-framework-ui/common/components/icon/IconCP'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { TransientValueTrimmer } from 'submodules/nerit-framework-ui/common/transient-value-trimmer/TransientValueTrimmer'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'

const PAGINATION_ITEMS_DEFAULT = 20
const PAGINATION_PAGE_DEFAULT = 1

const NOT_FOUND_TXT_START_TYPING = 'Comece a digitar'
const NOT_FOUND_TXT_LOADING = 'Carregando...'
const NOT_FOUND_TXT_EMPTY = 'Nenhum item encontrado'

interface IAutocompletePickerCPProps<ListItemTP extends NameAndCodeResponseDTO, FModelTP extends FormModel = any>
	extends IAutocompleteCommonProps<ListItemTP, FModelTP> {
	label?: string
	requestConfigGetter: (searchDto: any) => RequestConfigTP
	hidePagination?: boolean
	customFilters?: any
	loading?: boolean
	mustClearSearch?: boolean
	onSearchCleared?: () => void
	onAdding?: (name: string) => void
	filterOption?: boolean
	onLoadOptions?: (loadedOptionsObjects: any[]) => void
	maxTagCount?: number
}

/**
 * Seletor com autocomplete generico.
 * NOTE: Caso este componente seja controlado via 'form state manager' seu valor nao eh determinado diretamente pela prop 'value'.
 */
export function AutocompletePickerCP<ListItemTP extends NameAndCodeResponseDTO, FModelTP extends FormModel = any>(
	props: IAutocompletePickerCPProps<ListItemTP, FModelTP>,
): JSX.Element {
	const [searchString, setSearchString] = useState<string>('')
	const [options, setOptions] = useState<SelectFullOptionTP[]>([])
	const [notFoundTxt, setNotFoundTxt] = useState<string>(NOT_FOUND_TXT_START_TYPING)
	const [selectedOptions, setSelectedOptions] = useState<SelectFullOptionTP[]>([])
	const [onChangeSearchStrTrimmer] = useState<TransientValueTrimmer<string>>(
		new TransientValueTrimmer(AutocompletePickerUtils.getSearchStrTrimmerConfig(setSearchString)),
	)

	const searchRequest = useRequest<ListResponseDTO<ListItemTP>>()

	useEffect(onSearchTxtChange, [searchString, props.showOptionsOnLoad])
	useEffect(onSearchRequestChange, [searchRequest.isAwaiting])
	useEffect(onInitialOptionsReceived, [props.initialOptions])
	useEffect(onMustClearSearchChange, [props.mustClearSearch])

	function onSearchRequestChange(): void {
		if (!searchRequest.wasTried || searchRequest.isCancelled) return

		if (searchRequest.isAwaiting) return setNotFoundTxt(NOT_FOUND_TXT_LOADING)

		_setOptions(AutocompletePickerUtils.getSelectOptions(searchRequest.responseData?.list ?? []), true)

		if (props.onLoadOptions) props.onLoadOptions(searchRequest.responseData?.list ?? [])

		if (RequestUtils.isRequestError(searchRequest)) {
			RequestUtils.handleError({
				request: searchRequest,
				errorMsg: 'Falha ao buscar lista para seletor via autocomplete',
				failureLogMsg: `FALHA - ${AutocompletePickerCP.name}.${onSearchRequestChange.name}`,
			})
		}
	}

	function onSearchTxtChange(): void {
		// FilterOption forca filtrar somente o que ja foi carregado
		if (props.filterOption && searchRequest.wasTried) return

		if (!searchString && !props.showOptionsOnLoad) {
			_setOptions([], false)
			searchRequest.cancelRequest()

			if (!!props.onSearchCleared) props.onSearchCleared()

			return
		}

		const searchDto = {
			...(props.customFilters || {}),
		}

		// Significa que vai filtrar na tela
		if (!props.filterOption && !!searchString) searchDto.searchString = searchString

		if (props.hidePagination) {
			searchDto.itemsPerPage = props.searchItems ?? PAGINATION_ITEMS_DEFAULT
			searchDto.page = props.searchPage ?? PAGINATION_PAGE_DEFAULT
		}

		searchRequest.runRequest(props.requestConfigGetter(searchDto))
	}

	function onInitialOptionsReceived(): void {
		const receivedOptions = AutocompletePickerUtils.getSelectOptions(props.initialOptions ?? [])
		if (!receivedOptions.length || searchRequest.isAwaiting) return

		// Atualiza opcoes disponiveis
		const nextOptions = AutocompletePickerUtils.getUniqueOptionsList([...options, ...receivedOptions])

		_setOptions(nextOptions, false)

		if (!props.isMultiple) return

		// Atualiza dados das opcoes ja selecionadas
		const currentValue = getCurrentMultipleValue()

		const nextSelectedOptions = AutocompletePickerUtils.getUniqueOptionsList([
			...selectedOptions,
			...receivedOptions.filter((listItem) => currentValue.includes(listItem.value as number)),
		])

		setSelectedOptions(nextSelectedOptions)
	}

	function onMustClearSearchChange(): void {
		if (!!props.mustClearSearch) setSearchString('')
	}

	function _setOptions(_options: SelectFullOptionTP[], hasSearchBeenRan: boolean): void {
		if (!_options.length) setNotFoundTxt(hasSearchBeenRan ? NOT_FOUND_TXT_EMPTY : NOT_FOUND_TXT_START_TYPING)
		setOptions(_options)
	}

	function getCurrentMultipleValue(): number[] {
		const hasFStateManager = !!props.formStateManager && !!props.fieldName
		const currentValue = hasFStateManager ? props.formStateManager?.getFieldValue(props.fieldName!) : props.value
		return currentValue ?? []
	}

	function handleChange(value?: any): void {
		if (!!props.isMultiple && !!props.returnFullOption) setSelectedOptions(value)

		if (!!props.onChange) props.onChange(value)
	}

	const showAddingBtn = !!props.onAdding && notFoundTxt === NOT_FOUND_TXT_EMPTY && !!searchString && !props.loading

	return (
		<WrapperSCP>
			<SelectCP<FModelTP>
				options={options}
				selectedOptions={selectedOptions}
				loading={searchRequest.isAwaiting || !!props.loading}
				onSearch={(_searchString) => onChangeSearchStrTrimmer.onChange(_searchString)}
				onChange={handleChange}
				onFormSubmit={props.onFormSubmit}
				isMultiple={!!props.isMultiple}
				returnFullOption={props.returnFullOption}
				fieldName={props.fieldName}
				formStateManager={props.formStateManager}
				label={props.label}
				required={props.required}
				width={props.width}
				allowClear={props.allowClear}
				value={props.value}
				fontSize={props.fontSize}
				disabled={props.disabled ?? props.loading}
				notFoundContent={props.filterOption ? NOT_FOUND_TXT_EMPTY : notFoundTxt}
				filterOption={props.filterOption ?? false}
				placeholder={props.placeholder}
				marginTop={props.marginTop ?? !!props.label ? 15 : 5}
				marginRight={props.marginRight ?? 0}
				marginBottom={props.marginBottom ?? 5}
				marginLeft={props.marginLeft ?? 0}
				hint={props.hint}
				maxTagCount={props.maxTagCount}
			/>

			{showAddingBtn && (
				<ButtonWrapperSCP show={showAddingBtn}>
					<ButtonCP onClick={() => props.onAdding!(searchString)} ghost borderColor={'transparent'} tooltip={'Adicionar'}>
						<IconCP antIcon={'plus-circle'} />
					</ButtonCP>
				</ButtonWrapperSCP>
			)}
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	position: relative;
`

const ButtonWrapperSCP = styled.div<{ show: boolean }>`
	position: absolute;
	right: 10px;
	top: 0;
	bottom: 5px;
	display: flex;
	align-items: center;
	padding-bottom: 5px;
	transition: opacity 0.5s;
	opacity: ${(props) => (props.show ? 1 : 0)};
`
