import { CheckBoxGroupFormCP } from '_old/main/common/components/form-fields/checkbox-group/CheckBoxGroupFormCP'
import { TextCP } from '_old/main/common/components/text/TextCP'
import { IOitSectionProps, OitSectionContentICP } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/OitSectionContentICP'
import styled from 'styled-components'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'

export function OitSection3DICP(props: IOitSectionProps): JSX.Element {
	return (
		<FlexCP>
			<OitSectionContentICP title={'3D- Espessamento pleural difuso:'}>
				<FlexCP>
					<RadioGroupCP
						type={'button'}
						options={[
							{ content: 'Sim', value: true },
							{ content: 'Não', value: false },
						]}
						formStateManager={props.formStateManager}
						fieldName={'answer3D'}
					/>
				</FlexCP>
				<FlexCP gap={40}>
					<FlexCP flexDirection="column" alignItems="center">
						<GridRowSCP>
							<Title1SCP>Calcificação</Title1SCP>
							<LabelSCP>Parede em perfil:</LabelSCP>
							<CheckBoxGroupFormCP
								options={['0', 'D', 'E']}
								formStateManager={props.formStateManager as any}
								fieldName={'answer3DProfileFirst'}
							/>
							<CheckBoxGroupFormCP
								options={['0', 'D', 'E']}
								formStateManager={props.formStateManager as any}
								fieldName={'answer3DProfileSecond'}
							/>
						</GridRowSCP>
						<GridRowSCP>
							<LabelSCP>Frontal:</LabelSCP>
							<CheckBoxGroupFormCP
								options={['0', 'D', 'E']}
								formStateManager={props.formStateManager as any}
								fieldName={'answer3DFrontFirst'}
							/>
							<CheckBoxGroupFormCP
								options={['0', 'D', 'E']}
								formStateManager={props.formStateManager as any}
								fieldName={'answer3DFrontSecond'}
							/>
						</GridRowSCP>
					</FlexCP>
					<FlexCP flexDirection="column">
						<div>Extensão Parede</div>
						<div>(Combinado em perfil e frontal)</div>
						<FlexSCP>
							<RadioGroupCP
								type={'button'}
								options={[
									{ content: '0', value: '0' },
									{ content: 'D', value: 'D' },
								]}
								formStateManager={props.formStateManager}
								fieldName={'answer3DExtension0D'}
							/>
							<RadioGroupCP
								type={'button'}
								options={[
									{ content: '0', value: '0' },
									{ content: 'E', value: 'E' },
								]}
								formStateManager={props.formStateManager}
								fieldName={'answer3DExtension0E'}
							/>
						</FlexSCP>
						<FlexSCP>
							<RadioGroupCP
								type={'button'}
								options={[
									{ content: '1', value: '1' },
									{ content: '2', value: '2' },
									{ content: '3', value: '3' },
								]}
								formStateManager={props.formStateManager}
								fieldName={'answer3DExtensionD123'}
							/>
							<RadioGroupCP
								type={'button'}
								options={[
									{ content: '1', value: '1' },
									{ content: '2', value: '2' },
									{ content: '3', value: '3' },
								]}
								formStateManager={props.formStateManager}
								fieldName={'answer3DExtensionE123'}
							/>
						</FlexSCP>
						<LabelSCP>
							<TextCP text={'Até 1/4 da parede lareral = 1'} />
							<TextCP text={'1/4 a 1/2 da parede lateral = 2'} />
							<TextCP text={'> 1/2 da parede lateral = 3'} />
						</LabelSCP>
					</FlexCP>
					<FlexCP flexDirection="column">
						<div>Largura (Opcional):</div>
						<div>(Mínimo de 3mm para marcação)</div>
						<FlexSCP>
							<RadioGroupCP
								type={'button'}
								options={[{ content: 'D', value: 'D' }]}
								formStateManager={props.formStateManager}
								fieldName={'answer3DWidth0D'}
							/>
							<RadioGroupCP
								type={'button'}
								options={[{ content: 'E', value: 'E' }]}
								formStateManager={props.formStateManager}
								fieldName={'answer3DWidth0E'}
							/>
						</FlexSCP>
						<FlexSCP>
							<RadioGroupCP
								type={'button'}
								options={[
									{ content: 'a', value: 'a' },
									{ content: 'b', value: 'b' },
									{ content: 'c', value: 'c' },
								]}
								formStateManager={props.formStateManager}
								fieldName={'answer3DWidthDabc'}
							/>
							<RadioGroupCP
								type={'button'}
								options={[
									{ content: 'a', value: 'a' },
									{ content: 'b', value: 'b' },
									{ content: 'c', value: 'c' },
								]}
								formStateManager={props.formStateManager}
								fieldName={'answer3DWidthEabc'}
							/>
						</FlexSCP>
						<LabelSCP>
							<TextCP text={'3 a 5 mm = a'} />
							<TextCP text={'5 a 10 mm = b'} />
							<TextCP text={'> 10 mm = c'} />
						</LabelSCP>
					</FlexCP>
				</FlexCP>
			</OitSectionContentICP>
		</FlexCP>
	)
}

const GridRowSCP = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: baseline;
`
const LabelSCP = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
`

const FlexSCP = styled.div`
	display: flex;
	justify-content: center;
`

const Title1SCP = styled.div`
	grid-column: 2 / span 3;
	text-align: center;
`
