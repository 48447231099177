import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

export const MatrizContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
	background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
	color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
`
export const Matriz = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2px;
`

export const Row = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	gap: 2px;
`

export const Cell = styled.div<{ selected: boolean; preview: boolean }>`
	width: 20px;
	height: 20px;
	background-color: ${(props) => (props.selected ? '#90caf9' : props.preview ? '#90caf9' : 'transparent')};
	border: 1px solid #ddd;
	box-sizing: border-box;
	cursor: pointer;

	&:hover {
		background-color: ${(props) => (props.selected ? '#90caf9' : '#90caf9')};
	}
`
export const MatrizLabel = styled.div`
	margin-top: 10px;
	text-align: center;
	font-size: 12px;
	color: ${ThemeFrameworkCommon.browserDefaultBackgroundDark};
`
