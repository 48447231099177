import { LinkExamDescriptionSuggestionAiCP } from 'modules/exams/components/exams/link-exam-description-ai-suggestion/LinkExamDescriptionSuggestionAiCP'
import React, { useEffect, useState } from 'react'
import { DoctorGroupExamDescriptionsRequests } from 'submodules/beerads-sdk/services/doctor-groups/exams-description/DoctorGroupExamDescriptionsRequests'
import { DoctorGroupExamDescriptionSearchRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/exams-description/dtos/request/DoctorGroupExamDescriptionSearchRequestDTO'
import { DoctorGroupExamDescriptionResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/exams-description/dtos/response/DoctorGroupExamDescriptionResponseDTO'
import { ExamModalityEnum, ExamModalityLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { SelectFullOptionTP, SelectOptionTP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/inner/types/SelectOptionTP'
import { HelpTP } from 'submodules/nerit-framework-ui/common/components/help/inner/HelpTP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps<FModelTP extends FormModel = any> {
	exam?: {
		doctorGroupCode: number
		modality: ExamModalityEnum
		description: string
		clinicCode: number
	}
	doctorGroupCode: number
	selectedModality: ExamModalityEnum
	selectedDescription?: DoctorGroupExamDescriptionResponseDTO
	onChange: (option?: SelectFullOptionTP) => void
	showLearnedDescription?: boolean
	disabled?: boolean
	required?: boolean
	fieldName?: keyof FModelTP
	formStateManager?: IFormStateManager<FModelTP>
	allowFalsyValue?: boolean
}

/**
 * Select com os medicos ou usuarios de um grupo.
 */
export function SelectDoctorGroupExamDescriptionsCP(props: ICPProps): React.ReactElement {
	const [showLearnedDescription, setShowLearnedDescription] = useState(!!props.showLearnedDescription)
	const [options, setOptions] = useState<SelectOptionTP[]>([])
	const getClinicExamsDescriptionsRequest = useRequest<ListResponseDTO<DoctorGroupExamDescriptionResponseDTO>>()
	useEffect(onGetClinicExamsDescriptionsRequestChange, [getClinicExamsDescriptionsRequest.isAwaiting])

	useEffect(init, [props.doctorGroupCode, props.selectedModality])

	/**
	 * Carrega todos.
	 */
	function init(): void {
		if (!props.doctorGroupCode) return

		const dto: DoctorGroupExamDescriptionSearchRequestDTO = {
			modality: props.selectedModality,
			doctorGroupCode: props.doctorGroupCode,
		}
		getClinicExamsDescriptionsRequest.runRequest(DoctorGroupExamDescriptionsRequests.search(dto))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetClinicExamsDescriptionsRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getClinicExamsDescriptionsRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = getClinicExamsDescriptionsRequest.responseData!.list

		const _options: SelectFullOptionTP[] = result.map((examDescription) => ({
			value: examDescription.code,
			label: `${ExamModalityLabelEnum[examDescription.modality]} ${examDescription.description}`,
		}))

		// Procura pra ver se o que veio tem correspondencia, dessa forma nao precisa sugerir nem mostrar o original do equipamento
		const found = result.find((examDescription) => examDescription.description === props.selectedDescription?.description)
		if (!!found) {
			if (!props.selectedDescription) {
				props.selectedDescription = {
					code: found.code,
					description: found.description,
					modality: found.modality,
					doctorGroupCode: found.doctorGroupCode,
					specialty: found.specialty,
					totalSegmentsClinicBilling: found.totalSegmentsClinicBilling,
					totalSegmentsDoctorRevenue: found.totalSegmentsDoctorRevenue,
					examGroupClinicBillingCode: found.examGroupClinicBillingCode,
					examGroupDoctorRevenueCode: found.examGroupDoctorRevenueCode,
				}
			} else props.selectedDescription.code = found.code

			setShowLearnedDescription(false)
		} else {
			// Caso nao tenha correspondencia, cria a opcao de original do equipamento
			if (props.selectedDescription?.code === -1) {
				_options.push({
					label: `${props.selectedDescription.description} (original do equipamento)`,
					value: -1,
				})
			} else if (props.selectedDescription?.description) {
				const modalityInExamDescription = props.selectedDescription?.description.split(' ')[0] as ExamModalityEnum

				if (modalityInExamDescription !== props.selectedModality) {
					if (_options.length > 0) {
						props.onChange({
							value: _options[0].value as number,
							label: _options[0].label,
						})
					} else {
						props.onChange(undefined)
					}
				}
			}
		}

		setOptions(_options)
	}

	function renderHint(): HelpTP | undefined {
		if (!showLearnedDescription || !props.exam) {
			return undefined
		}

		return {
			type: 'text',
			text: (
				<LinkExamDescriptionSuggestionAiCP
					exam={props.exam}
					doctorGroupCode={props.doctorGroupCode}
					onClick={(suggestion) =>
						props.onChange({
							value: suggestion.learnedDoctorGroupExamDescriptionCode,
							label: suggestion.description,
						})
					}
				/>
			),
		}
	}

	return (
		<SelectCP
			formStateManager={props.formStateManager}
			fieldName={props.fieldName}
			label={'Selecione o exame'}
			value={props.selectedDescription?.code}
			onChange={props.onChange}
			required={props.required}
			options={options}
			filterOption={true}
			returnFullOption={true}
			disabled={getClinicExamsDescriptionsRequest.isAwaiting ?? props.disabled}
			loading={getClinicExamsDescriptionsRequest.isAwaiting}
			hint={renderHint()}
			allowFalsyValue={props.allowFalsyValue}
		/>
	)
}
