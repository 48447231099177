import styled from 'styled-components'

export const ButtonsContainerSCP = styled.div`
	display: flex;
	justify-content: space-evenly;
	width: 100%;
	min-width: 500px;

	.ant-btn-primary {
		margin: 0 5px 0px 0 !important;
		padding: 0 9px !important;
		font-size: 12px !important;
	}
`
