import { ThemeProjectVX } from 'config/theme/project/white-labels/ThemeProjectVX'
import { MedicalTimetableAbsenceFormModel } from 'modules/medical-timetable/components/modals/modal-medical-timetable-absence/inner/MedicalTimetableAbsenceFormModel'
import { MedicalTimetableReasonAbsenceEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableReasonAbsenceEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
	formStateManager: IFormStateManager<MedicalTimetableAbsenceFormModel>
	onLoading?: boolean
	onCreateAbsence: () => void
	onSave: () => void
}

export function ButtonMedicalTimetableUnassignSpotCP(props: ICPProps): JSX.Element {
	return (
		<>
			<TextCP text={'Remova o médico da escala ou adicione uma ausência neste dia.'} />
			<TextCP
				text={
					'Remover o médico da escala não gera registro no sistema e não será possível gerar dados futuramente. Ele será removido da visualização.'
				}
				size="small"
				color={ThemeProjectVX.gray700}
				fontStyle="italic"
			/>
			<TextAreaCP
				placeholder={'Escreva os detalhes do motivo da ausência'}
				formStateManager={props.formStateManager}
				fieldName={'description'}
				minRows={4}
			/>
			<div className="mt-9 flex justify-between">
				<ButtonCP
					size={'small'}
					onClick={() => {
						props.formStateManager.changeFieldValue('reasonAbsence', MedicalTimetableReasonAbsenceEnum.MISTAKE)
						props.onSave()
					}}
					loading={props.onLoading}
					confirmMsg={'Você tem certeza que deseja tirar o médico da escala? O médico será removido da escala.'}
				>
					Remover da escala
				</ButtonCP>
				<ButtonCP
					size={'small'}
					onClick={() => {
						props.formStateManager.changeFieldValue('reasonAbsence', MedicalTimetableReasonAbsenceEnum.NO_SHOW)
						props.onCreateAbsence()
					}}
					confirmMsg={'Você tem certeza que deseja adicionar uma ausência neste dia?'}
					loading={props.onLoading}
					type="primary"
				>
					Adicionar ausência neste dia
				</ButtonCP>
			</div>
		</>
	)
}
