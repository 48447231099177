import { ModulePermissionTP, RolePermissionsDetailsTP } from 'submodules/beerads-sdk/common/roles-permissions/types'
import { ValueOf } from 'submodules/beerads-sdk/common/types'

export const FeatureTogglePermissionEnum = {
	WIND: {
		SHOW: 'FEATURE_TOGGLE:WIND:SHOW',
	},
	VIEWER: {
		SHOW: 'FEATURE_TOGGLE:VIEWER:SHOW',
	},
} as const

export type FeatureTogglePermissionTP = ValueOf<{
	[K in keyof typeof FeatureTogglePermissionEnum]: ValueOf<(typeof FeatureTogglePermissionEnum)[K]>
}>

/**
 * Custom type to enforce that feature toggle should always be backoffice
 */
type FeatureTogglePermissionsValueTP = RolePermissionsDetailsTP & {
	isBackoffice: true
}

type FeatureToggleModulePermissionTP = ModulePermissionTP<FeatureTogglePermissionTP, FeatureTogglePermissionsValueTP> & {
	isBackoffice: true
}

export const FEATURE_TOGGLE_MODULE_PERMISSION: FeatureToggleModulePermissionTP = {
	name: 'Funcionalidades',
	isBackoffice: true,
	permissions: {
		[FeatureTogglePermissionEnum.WIND.SHOW]: {
			description: 'Permissão para habilitar a Wind',
			name: 'Habilitar a Wind',
			isBackoffice: true,
		},
		[FeatureTogglePermissionEnum.VIEWER.SHOW]: {
			name: 'Habilitar a Viewer',
			description: 'Permissão para habilitar a Viewer',
			isBackoffice: true,
		},
	},
}
