import { AvatarMultipleExamModalityCP } from 'modules/exams/components/exams/avatar-multiple-exam-modality/AvatarMultipleExamModalityCP'
import { ButtonApproveMedicalReportTemplate } from 'modules/template/components/button-approve-medical-report-template/ButtonApproveMedicalReportTemplate'
import { ButtonModalCompareMedicalReportTemplateCP } from 'modules/template/components/button-modal-compare-medical-report-template/ButtonModalCompareMedicalReportTemplateCP'
import { ButtonReproveMedicalReportTemplate } from 'modules/template/components/button-reprove-medical-report-template.tsx/ButtonReproveMedicalReportTemplateCP'
import { TagsTemplateStatusCP } from 'modules/template/components/tags-template-status/TagsTemplateStatusCP'
import { TemplateHistoryResponseDTO } from 'submodules/beerads-sdk/services/templates/history-templates/dtos/response/TemplateHistoryResponseDTO'
import { TemplateHistoryStatusEnum } from 'submodules/beerads-sdk/services/templates/history-templates/enums/TemplateHistoryStatusEnum'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ITableColumn } from 'submodules/nerit-framework-ui/common/components/table/types/ITableColumn'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'

export const TableTemplatesMedicalReportHistoryUtils = {
	getColumns(
		onChangeDataOnTable: () => void,
		isLoading: (isLoading: boolean) => void,
		currentTemplateList?: TemplateResponseDTO[],
	): Array<ITableColumn<TemplateHistoryResponseDTO>> {
		function getCurrentTemplate(historyTemplateCode: number): TemplateResponseDTO | undefined {
			return currentTemplateList?.find((item) => item.code === historyTemplateCode)
		}

		return [
			{
				title: 'Template',
				render: (_, record) => (
					<FlexCP gap={8}>
						<TextCP text={record.examNameNew} />
					</FlexCP>
				),
			},
			{
				title: 'Modalidade',
				render: (_, record) => {
					return <AvatarMultipleExamModalityCP modalities={record.examModalitiesNew} id={record.id} />
				},
			},
			{
				title: 'Data de modificação',
				render: (_, record) => <TextCP text={DateUtils.formatDate(record.updatedAt, DateFormatEnum.BR_WITH_TIME_H_M)} />,
			},
			{
				title: 'Status',
				render: (_, record) => <TagsTemplateStatusCP status={record.status} />,
			},
			{
				render: (_, record, index) => {
					return (
						<>
							<FlexCP gap={4}>
								{record.status === TemplateHistoryStatusEnum.AWAITING_APPROVAL && (
									<>
										<ButtonApproveMedicalReportTemplate
											templateId={record.id}
											size="small"
											onSave={onChangeDataOnTable}
											setIsLoading={(loading) => isLoading(loading)}
										/>
										<ButtonReproveMedicalReportTemplate
											index={index}
											templateId={record.id}
											size="small"
											onSave={onChangeDataOnTable}
											setIsLoading={(loading) => isLoading(loading)}
										/>
									</>
								)}

								<ButtonModalCompareMedicalReportTemplateCP
									templateHistory={record}
									currentTemplate={getCurrentTemplate(record.templateCode)!}
									onChangeDataOnTable={onChangeDataOnTable}
								/>
							</FlexCP>
						</>
					)
				},
			},
		]
	},
}
