import { useEffect } from 'react'
import { ExamsRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps extends BasicStyleWrapperCPProps {
	examCode: number
	onArchive: () => void
	size?: ButtonSizeTP
	showIcon?: boolean
	hideLabel?: boolean
	tooltip?: string
}

/**
 */
export function ButtonArchiveExamCP(props: ICPProps): JSX.Element {
	const archiveExamRequest = useRequest<void>('none')
	useEffect(onArchiveExamRequestChange, [archiveExamRequest.isAwaiting])

	/**
	 * Retorno da requisicao.
	 */
	function onArchiveExamRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				archiveExamRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onArchive()
	}

	return (
		<ButtonCP
			onClick={() => archiveExamRequest.runRequest(ExamsRequests.archiveExam(props.examCode))}
			loading={archiveExamRequest.isAwaiting}
			type={'primary'}
			danger
			size={props.size}
			icon={props.showIcon ? 'delete' : undefined}
			marginTop={props.margin?.top}
			marginBottom={props.margin?.bottom}
			marginLeft={props.margin?.left}
			marginRight={props.margin?.right}
			confirmMsg={'Você tem certeza que deseja arquivar esse exame?'}
			tooltip={props.tooltip}
		>
			{props.hideLabel ? undefined : 'Arquivar'}
		</ButtonCP>
	)
}
