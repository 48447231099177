import { ThemeProject } from 'config/theme/project/ThemeProject'
import styled, { css } from 'styled-components'
import { ThemeTP } from 'submodules/nerit-framework-ui/features/common/components/button-switch-theme/inner/ThemeTP'

// This is a pattern suggestion for styled components, components. We separate the style logic in a single file.

const characterSCPColorModifiers = {
	inactive: css`
		color: ${ThemeProject.gray300};
	`,
	selected: css`
		outline: 2px solid ${ThemeProject.secondary};
	`,
}

export const ValidationCodeInputThemeModifiers = {
	light: css`
		color: #272729;
		background-color: #ffffff;
		box-shadow: 0 2px 0 #e4e2f5;
	`,
	dark: css`
		color: #fff;
		background-color: #222;
		box-shadow: 0 2px 0 #444;
	`,
}

type CharacterTP = { inactive: boolean; selected: boolean; appTheme: ThemeTP }

export const ValidationCodeCharacter = styled.div<CharacterTP>`
	${({ inactive, selected, appTheme }) => css`
		height: 100%;
		flex-grow: 1;
		flex-basis: 0;
		text-align: center;
		line-height: 50px;

		cursor: default;
		user-select: none;
		box-sizing: border-box;

		border: none;
		font-size: 20px;
		font-family: 'Montserrat' !important;
		border-radius: 8px;
		outline: none;

		${ValidationCodeInputThemeModifiers[appTheme]}

		${inactive && characterSCPColorModifiers.inactive};
		${selected && characterSCPColorModifiers.selected};
	`}
`

export const ValidationCodeHiddenInput = styled.input`
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	box-sizing: border-box;
	position: absolute;
	color: transparent;
	background: transparent;
	caret-color: transparent;
	outline: none;
	border: 0 none transparent;
	font-family: 'Montserrat';

	&::-ms-reveal,
	&::-ms-clear {
		display: none;
	}

	&::selection {
		background: transparent;
	}
`

export const ValidationCodeContainer = styled.div`
	position: relative;
	display: flex;
	gap: 8px;
	height: 50px;
	width: 300px;
`
