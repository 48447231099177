import { DrawerFooterICP } from '@/submodules/nerit-framework-ui/common/components/drawer/inner/DrawerFooterICP'
import { Drawer } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IntercomUtils } from 'submodules/nerit-framework-ui/common/components/intercom/inner/IntercomUtils'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

type _AntPropsTP = {
	title?: string | JSX.Element
	visible?: boolean
	footer?: JSX.Element
	onClose?: () => void
	loading?: boolean
	width?: number | string
	height?: number | string
	closable?: boolean
	maskClosable?: boolean
	placement?: 'left' | 'right' | 'bottom' | 'top'
	showMask?: boolean
	zIndex?: number
}

type _ScpPropsTP = {
	rightPadding?: number
	titleUnderline?: boolean
	footerSpaced?: boolean
}

interface IDrawerCPProps extends React.PropsWithChildren<_ScpPropsTP & _AntPropsTP> {}

/**
 * COMPONENTE: Drawer generico.
 */
export function DrawerCP(props: IDrawerCPProps): JSX.Element {
	const [showDrawer, setShowDrawer] = useState<boolean>()
	const screenSize = useScreenSize()

	useEffect(handleOpen, [props.visible])

	function handleOpen(): void {
		if (!!props.visible) IntercomUtils.setIntercomVisibility(false)
		setShowDrawer(!!props.visible)
	}

	function handleClose(): void {
		IntercomUtils.setIntercomVisibility(true)
		if (!!props.onClose) props.onClose()
		else setShowDrawer(false)
	}

	if (!props.visible) return <></>

	return (
		<WrapperSCP rightPadding={props.rightPadding}>
			<Drawer
				open={showDrawer}
				placement={props.placement ?? 'right'}
				closable={props.closable ?? true}
				destroyOnClose={true}
				onClose={handleClose}
				maskClosable={props.maskClosable ?? props.closable ?? true}
				mask={props.showMask}
				zIndex={props.zIndex ?? 10}
				width={screenSize.smd ? '100%' : (props.width ?? 450)}
				title={props.title}
				height={props.height}
				footer={!!props.footer && <DrawerFooterICP footerSpaced={props.footerSpaced}>{props.footer}</DrawerFooterICP>}
				styles={{
					body: {
						backgroundColor: ThemeFrameworkCommon.browserDefaultBackgroundLight,
						color: ThemeFrameworkCommon.browserDefaultColorDark,
						overflowX: 'hidden',
						padding: 0,
					},
					header: {
						backgroundColor: ThemeFrameworkCommon.browserDefaultBackgroundLight,
						color: ThemeFrameworkCommon.browserDefaultColorDark,
						marginTop: 0,
					},
				}}
			>
				<ContentWrapperSCP>
					<LoadingOverlayCP show={props.loading ?? false} />
					{props.children}
				</ContentWrapperSCP>
			</Drawer>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div<_ScpPropsTP>`
	.ant-drawer-title {
		font-size: 20px;
	}

	.ant-drawer-content {
		height: 100vh;
	}
	.ant-drawer-wrapper-body {
		overflow: hidden !important;
		display: flex;
		flex-direction: column;
	}

	.ant-drawer-header {
		position: relative;
		color: ${ThemeFrameworkCommon.browserDefaultColorDark}A6 !important;
		background: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
		border-bottom: none;
		border-radius: 4px 4px 0 0;
	}
	.ant-drawer .ant-drawer-body {
		margin-right: ${(props) => (props.rightPadding ? props.rightPadding : 0)}px;
		overflow: hidden !important;
		height: 100%;
		position: relative;
	}
	.anticon {
		color: ${ThemeFrameworkCommon.browserDefaultColorDark};
	}
`

const ContentWrapperSCP = styled.div`
	padding: 24px;
	height: 100%;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
`
