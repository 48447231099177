import { ExamDescriptionFormModel } from 'modules/doctor-groups/components/exam-descriptions/modal-exam-description/inner/ExamDescriptionFormModel'
import { SelectExamGroupCP } from 'modules/doctor-groups/components/exam-groups/select-exam-groups/SelectExamGroupsCP'
import { SelectExamModalityCP } from 'modules/exams/components/exams/select-exam-modality/SelectExamModalityCP'
import { SelectExamSpecialtyCP } from 'modules/exams/components/exams/select-exam-specialty/SelectExamSpecialtyCP'
import { useEffect, useState } from 'react'
import { DoctorGroupExamDescriptionsRequests } from 'submodules/beerads-sdk/services/doctor-groups/exams-description/DoctorGroupExamDescriptionsRequests'
import { ExamDescriptionSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/exams-description/dtos/request/ExamDescriptionSaveRequestDTO'
import { DoctorGroupExamDescriptionResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/exams-description/dtos/response/DoctorGroupExamDescriptionResponseDTO'
import { ExamGroupTypeEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamTypeEnum'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { IBasicModalCPProps, ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps extends IBasicModalCPProps {
	doctorGroupCode: number
	examDescription?: DoctorGroupExamDescriptionResponseDTO
}

/**
 */
export function ModalExamDescriptionCP(props: ICPProps): JSX.Element {
	const [formValidator, setFormValidator] = useState<ExamDescriptionFormModel>(new ExamDescriptionFormModel())
	const formStateManager = useFormStateManager<ExamDescriptionFormModel>(formValidator)

	const saveExamDescriptionRequest = useRequest<void>('none')
	useEffect(onSaveExamDescriptionRequestChange, [saveExamDescriptionRequest.isAwaiting])

	useEffect(init, [props.visible])

	/**
	 * Inicializa a tela
	 */
	function init(): void {
		setFormValidator(new ExamDescriptionFormModel({}))
		if (!props.visible || !props.examDescription) return

		setFormValidator(
			new ExamDescriptionFormModel({
				specialty: props.examDescription.specialty,
				description: props.examDescription.description,
				modality: props.examDescription.modality,
			}),
		)
	}

	/**
	 */
	async function save(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) return

		const formValues = formStateManager.getFormValues()!
		const dto: ExamDescriptionSaveRequestDTO = {
			doctorGroupCode: props.doctorGroupCode,
			description: formValues.description,
			modality: formValues.modality,
			specialty: formValues.specialty,
			totalSegments: formValues.totalSegments,
			examGroupClinicBillingCode: formValues.examGroupClinicBillingCode,
			examGroupDoctorRevenueCode: formValues.examGroupDoctorRevenueCode,
		}

		if (!!props.examDescription) saveExamDescriptionRequest.runRequest(DoctorGroupExamDescriptionsRequests.update(props.examDescription.code, dto))
		else saveExamDescriptionRequest.runRequest(DoctorGroupExamDescriptionsRequests.create(dto))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onSaveExamDescriptionRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				saveExamDescriptionRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSave()
	}

	return (
		<ModalCP visible={props.visible} onCancel={props.onCancel} title={'Exame'} onOk={save} actionLoading={saveExamDescriptionRequest.isAwaiting}>
			<RowCP>
				<ColumnCP size={6}>
					<SelectExamModalityCP label={'Mod.'} required={true} formStateManager={formStateManager} fieldName={'modality'} disabled />
				</ColumnCP>
				<ColumnCP size={18}>
					<InputCP label={'Nome do exame'} required={true} formStateManager={formStateManager} fieldName={'description'} disabled />
				</ColumnCP>
			</RowCP>

			<SelectExamSpecialtyCP formStateManager={formStateManager} fieldName={'specialty'} label={'Especialidade'} disabled />

			<InputCP
				label={'Total de segmentos para faturamento'}
				required={true}
				formStateManager={formStateManager}
				fieldName={'totalSegments'}
				step={0.1}
				min={1}
				type={'number'}
			/>

			<SelectExamGroupCP
				label={'Grupos de exames para faturamento'}
				required={true}
				formStateManager={formStateManager}
				fieldName={'examGroupClinicBillingCode'}
				type={ExamGroupTypeEnum.CLINIC_BILLING}
				doctorGroupCode={props.doctorGroupCode}
			/>

			<SelectExamGroupCP
				label={'Grupos de exames para repasse'}
				required={true}
				formStateManager={formStateManager}
				fieldName={'examGroupDoctorRevenueCode'}
				type={ExamGroupTypeEnum.DOCTOR_REVENUE}
				doctorGroupCode={props.doctorGroupCode}
			/>
		</ModalCP>
	)
}
