import { TableTemplatesCP } from 'modules/template/components/table-templates/TableTemplatesCP'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import { SiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/sider/SiderCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ISiderTemplateProps {
	onTemplateSelectionChange: (template: TemplateResponseDTO) => void
	onDelete: () => void
	type: TemplateTypeEnum
	currentTemplate?: TemplateResponseDTO
	reload: number
}

/**
 * Sidebar da tela de templates/frases de laudo.
 */
export function SiderTemplateCP(props: ISiderTemplateProps): JSX.Element {
	return (
		<SiderCP width={'28%'} bgColor={`${ThemeFrameworkCommon.browserDefaultBackgroundLight}`} overflowY={'auto'}>
			<div>
				<TableTemplatesCP
					loadList={props.reload}
					filters={{
						type: props.type,
					}}
					onSelectTemplate={props.onTemplateSelectionChange}
					onDelete={props.onDelete}
					appearance={{
						showDeleteButton: true,
						canClickOnRow: true,
					}}
				/>
			</div>
		</SiderCP>
	)
}
