import { BarGraphCP, GraphSeriesTP } from '_old/main/common/components/graphs/bar-graph/BarGraphCP'
import { DateTimeFormatEnum } from '_old/main/common/enums/DateFormatEnum'
import { ITotalByModality } from 'modules/reports/_old/interfaces/ITotalByModality'
import { ITotalByPeriod } from 'modules/reports/_old/interfaces/ITotalByPeriod'
import { DashboardGraphUtils } from 'modules/reports/components/chart-exams/inner/DashboardGraphUtils'
import { GraphSideOptionsICP } from 'modules/reports/components/chart-exams/inner/GraphSideOptionsICP'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { DateRangeSearchRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/DateRangeSearchRequestDTO'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'

interface IDashboardGraphCardCPProps {
	title: string
	graphBarsLabel: string
	dateRange: DateRangeSearchRequestDTO
	byModalityLists: ITotalByModality[]
	byPeriodLists: ITotalByPeriod[]
	currentTimeBase: TimeBaseEnum
	currentModality?: ExamModalityEnum
	onTimeBaseChange: (timeBase: TimeBaseEnum) => void
	onModalityChange: (modality?: ExamModalityEnum) => void
	loading?: boolean
	cardMargin?: string
}

/**
 * Card com grafico de barras de uma determinada quantidade por periodo de tempo para uso nos dashboards.
 */
export function DashboardGraphCardCP(props: IDashboardGraphCardCPProps): JSX.Element {
	const [graphSeries, setGraphSeries] = useState<GraphSeriesTP[]>([])
	const [graphXAxis, setGraphXAxis] = useState<string[]>([])

	useEffect(parseGraphData, [props.currentTimeBase, props.byPeriodLists])

	/**
	 */
	function parseGraphData(): void {
		const xAxisDates = DashboardGraphUtils.getXAxisDates(props.dateRange, props.currentTimeBase)
		const dateFormat = props.currentTimeBase === TimeBaseEnum.DAY ? DateTimeFormatEnum.BR_WITHOUT_TIME : DateTimeFormatEnum.BR_MONTH_YEAR

		setGraphXAxis(xAxisDates.map((date) => date.format(dateFormat)))

		setGraphSeries([
			{
				name: props.graphBarsLabel,
				data: DashboardGraphUtils.getYAxisExamCounts(xAxisDates, props.byPeriodLists),
			},
		])
	}

	return (
		<CardCP title={props.title} isLoading={props.loading} cardMargin={props.cardMargin}>
			<BodySCP>
				<GraphSideOptionsICP
					currentTimeBase={props.currentTimeBase}
					currentModality={props.currentModality}
					byModalityLists={props.byModalityLists}
					onModalityChange={props.onModalityChange}
					onTimeBaseChange={props.onTimeBaseChange}
				/>

				<BarGraphCP ySeries={graphSeries} xAxis={graphXAxis} />
			</BodySCP>
		</CardCP>
	)
}

const BodySCP = styled.div`
	color: ${ThemeFrameworkCommon.black};
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
`
