import { useAppSelector } from 'app/redux/hook'
import { AppUtils } from 'app/utils/AppUtils'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { ProjectWhiteLabelsEnum } from 'submodules/beerads-sdk/common/ProjectWhiteLabelsEnum'
import { ModalBoxCustomerSurveyCP } from 'submodules/space4leads-components-ui/survey/components/box-customer-survey/ModalBoxCustomerSurveyCP'

/**
 */
export function ModalVxPromoteCP(): JSX.Element {
	const loggedUser = useAppSelector((state) => state.auth.user)

	// Se o usuario nao estiver acessando pela URL da VX nao mostra
	if (AppUtils.getDomain() !== ProjectWhiteLabelsEnum.VX) return <></>

	// Se for colaborador da VX nao mostra
	if (DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(loggedUser, 142, ['isDoctorGroupAdmin', 'isDoctorGroupUser'])) return <></>

	// Se ele não for relacionado a VX (médico do grupo ou usuário/médico de uma clinica que pertence ao grupo)
	if (!loggedUser?.isVXCustomer) return <></>

	let tags: string[] | undefined
	let surveyToken: string
	let projectCode: number

	// Configuracoes especificas para MEDICO ou USUARIO
	if (loggedUser.isDoctor) {
		// Configuracoes de Medico
		tags = loggedUser.doctorGroups?.map((doctorGroup) => doctorGroup.name)
		projectCode = 157
		surveyToken = 'MjEx'
	} else {
		// Configuracoes de Usuario
		tags = loggedUser.clinics?.map((clinic) => clinic.name)
		projectCode = 158
		surveyToken = 'MjEw'
	}

	return (
		<ModalBoxCustomerSurveyCP
			person={{ name: loggedUser.name, email: loggedUser.email }}
			tags={[loggedUser.isDoctor ? 'Médico' : 'Usuário', ...(tags ?? [])]}
			timeConfig={{
				daysBetweenAnswers: 90,
				daysToFirstAnswer: 5,
				daysToReopenOnCancel: 3,
			}}
			boxType={'bottom'}
			surveyToken={surveyToken}
			projectCode={projectCode}
		/>
	)
}
