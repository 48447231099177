import { useAppSelector } from 'app/redux/hook'
import { SelectFullOptionTP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'

interface ICPProps {
	formStateManager?: IFormStateManager<any>
	fieldName?: string
	required?: boolean
	allowClear?: boolean
	value?: number
	onChange?: (value: number) => void
	width?: number
	disable?: boolean
	hideWhenEmpty?: boolean
}

/**
 */
export function SelectDoctorGroupFromLoggedUserCP(props: ICPProps): JSX.Element {
	const options: SelectFullOptionTP[] = []
	const loggedUser = useAppSelector((state) => state.auth.user)

	if (!!loggedUser?.doctorGroups) {
		loggedUser.doctorGroups.forEach((doctorGroup) => {
			options.push({
				value: doctorGroup.code,
				label: doctorGroup.name,
			})
		})
	}

	if (props.hideWhenEmpty && ArrayUtils.isEmpty(options)) return <></>

	return (
		<SelectCP
			allowClear={props.allowClear ?? true}
			label={'Grupo de Médico'}
			options={options}
			formStateManager={props.formStateManager}
			fieldName={props.fieldName}
			value={props.value}
			onChange={props.onChange}
			width={props.width}
			disabled={props.disable}
			required={props.required}
		/>
	)
}
