import { useAppSelector } from 'app/redux/hook'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { ClinicPermissionUtils, ClinicRelationPermissionTP } from 'modules/auth/permissions/ClinicPermissionUtils'
import { CommonPermissionUtils } from 'modules/auth/permissions/CommonPermissionUtils'
import { DoctorGroupPermissionUtils, DoctorGroupRelationPermissionTP } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import React from 'react'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { RedirectCP } from 'submodules/nerit-framework-ui/common/router/components/redirect/RedirectCP'

interface ICPProps {
	children: React.ReactNode
	permission: {
		isBeeRadsAdmin?: boolean
		byUserType?: UserTypeEnum
		byHasAnyClinicRelationPermission?: ClinicRelationPermissionTP[]
		byHasAnyPermissionInClinic?: {
			clinicCode: number
			permissions: ClinicRelationPermissionTP[]
		}
		byHasAnyDoctorGroupRelationPermission?: DoctorGroupRelationPermissionTP[]
		byHasAnyPermissionInDoctorGroup?: {
			doctorGroupCode: number
			permissions: DoctorGroupRelationPermissionTP[]
		}
	}
	redirect?: {
		shouldRedirect: boolean
		redirectTo?: string
	}
}

/**
 * Controle de Acesso.
 */
export function AccessControlCP(props: ICPProps): JSX.Element {
	const { auth: loggedUser } = useAppSelector((state) => state)

	let isPermitted = true

	if (props.permission.isBeeRadsAdmin && !CommonPermissionUtils.isBeeRadsAdmin(loggedUser.user)) isPermitted = false

	if (!!props.permission.byUserType) {
		isPermitted = CommonPermissionUtils.canAccessByType(props.permission.byUserType, loggedUser.user)
	}

	if (!!props.permission.byHasAnyClinicRelationPermission)
		isPermitted = ClinicPermissionUtils.hasAnyPermissionsInAnyClinic(props.permission.byHasAnyClinicRelationPermission, loggedUser.clinicPermissions)

	if (!!props.permission.byHasAnyPermissionInClinic)
		isPermitted = ClinicPermissionUtils.hasAnyPermissionsInClinic(
			props.permission.byHasAnyPermissionInClinic.clinicCode,
			props.permission.byHasAnyPermissionInClinic.permissions,
			loggedUser.user,
		)

	if (!!props.permission.byHasAnyDoctorGroupRelationPermission)
		isPermitted = DoctorGroupPermissionUtils.hasAnyPermissionsInAnyDoctorGroup(
			props.permission.byHasAnyDoctorGroupRelationPermission,
			loggedUser.doctorGroupPermissions,
		)

	if (!!props.permission.byHasAnyPermissionInDoctorGroup)
		isPermitted = DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(
			loggedUser.user,
			props.permission.byHasAnyPermissionInDoctorGroup.doctorGroupCode,
			props.permission.byHasAnyPermissionInDoctorGroup.permissions,
		)

	if (props.permission.isBeeRadsAdmin && !!props.permission.byHasAnyPermissionInClinic)
		isPermitted =
			CommonPermissionUtils.isBeeRadsAdmin(loggedUser.user) ||
			ClinicPermissionUtils.hasAnyPermissionsInClinic(
				props.permission.byHasAnyPermissionInClinic.clinicCode,
				props.permission.byHasAnyPermissionInClinic.permissions,
				loggedUser.user,
			)

	if (!isPermitted) {
		if (props.redirect?.shouldRedirect) {
			NotificationHelper.warning('Ops!', 'Você não tem permissão para acessar essa página')
			return <RedirectCP path={props.redirect.redirectTo ?? PrivateUserRouter.HOME} />
		}

		return <></>
	}

	return <>{props.children}</>
}
