import { DoctorProfileFormModel } from 'modules/person/components/doctor/modal-doctor-data/inner/DoctorProfileFormModel'
import { FormDoctorProfileDataICP } from 'modules/person/components/doctor/modal-doctor-data/inner/FormDoctorProfileDataICP'
import { TableDoctorIdsCP } from 'modules/person/components/doctor/table-doctor-ids/TableDoctorIdsCP'
import { useEffect, useState } from 'react'
import { DoctorGroupPersonResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/responses/DoctorGroupPersonResponseDTO'
import { ProfileDoctorUpdateRequestDTO } from 'submodules/beerads-sdk/services/people/people/dtos/request/ProfileDoctorUpdateRequestDTO'
import { PersonResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PersonResponseDTO'
import { PeopleRequests } from 'submodules/beerads-sdk/services/people/people/PeopleRequests'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { FormAddressCP } from 'submodules/nerit-framework-ui/features/address/components/form-address/FormAddressCP'
import { AddressFormModel } from 'submodules/nerit-framework-ui/features/address/components/form-address/inner/AddressFormModel'
import { AddressFormUtils } from 'submodules/nerit-framework-ui/features/address/components/form-address/inner/AddressFormUtils'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'

interface ICPProps {
	visible: boolean
	onClose: (dataChanged?: boolean) => void
	person: PersonResponseDTO
	extraDoctorData?: DoctorGroupPersonResponseDTO['extraDoctorData']
	fieldConfig?: {
		showHasDigitalCertificate?: boolean
	}
}

/**
 * Modal com o log de uma operacao em massa.
 */
export function ModalDoctorProfileDataCP(props: ICPProps): JSX.Element {
	const [addressFormValidator, setAddressFormValidator] = useState<AddressFormModel>(new AddressFormModel())
	const addressFormStateManager = useFormStateManager<AddressFormModel>(addressFormValidator)

	const [formValidator, setFormValidator] = useState<DoctorProfileFormModel>(new DoctorProfileFormModel())
	const formStateManager = useFormStateManager<DoctorProfileFormModel>(formValidator)

	const savePersonRequest = useRequest<void>('none')
	useEffect(onSavePersonRequestChange, [savePersonRequest.isAwaiting])

	useEffect(init, [props.visible])

	/**
	 * Inicializa.
	 */
	function init(): void {
		if (!props.visible) return

		setFormValidator(
			new DoctorProfileFormModel({
				name: props.person.name,
				cpf: props.person.cpf,
				email: props.person.email,
				phoneOne: props.person.phone,
				specialty: props.person.doctorData?.specialty,
				birthDate: (!!props.person.birthDate ? DateUtils.formatDate(props.person.birthDate, DateFormatEnum.BR_WITHOUT_TIME) : undefined) as any,
				ids: props.person.doctorData?.ids ?? [],
				treatment: props.person.doctorData?.treatment,
				hasDigitalCertificate: props.person.doctorData?.hasDigitalCertificate,
				leoRadToken: props.extraDoctorData?.leoRadToken ?? props.person.preferences?.leoRadToken,
			}),
		)

		setAddressFormValidator(new AddressFormModel(AddressFormUtils.formatFormModel(props.person.address)))
	}

	/**
	 * Salva pessoa
	 */
	async function save(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) return

		const formValues = formStateManager.getFormValues()!

		const dto: ProfileDoctorUpdateRequestDTO = {
			name: formValues.name,
			cpf: formValues.cpf,
			email: formValues.email,
			phone: formValues.phoneOne,
			birthDate: !!formValues.birthDate ? DateUtils.toDate(formValues.birthDate, DateFormatEnum.BR_WITHOUT_TIME) : undefined,
			specialty: formValues.specialty,
			treatment: formValues.treatment,
			address: AddressFormUtils.formatDto(addressFormStateManager.getFormValues()),
			ids: formValues.ids,
			hasDigitalCertificate: formValues.hasDigitalCertificate,
			leoRadToken: formValues.leoRadToken,
		}
		savePersonRequest.runRequest(PeopleRequests.updateDoctorProfile(props.person.code, dto))
	}

	/**
	 * Apos reotorno da api de salvar
	 */
	function onSavePersonRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				savePersonRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
			)
		)
			return

		formStateManager.reset(undefined)
		props.onClose(true)
	}

	return (
		<ModalCP
			title={'Editar Perfil'}
			visible={props.visible}
			onCancel={props.onClose}
			onOk={save}
			actionLoading={savePersonRequest.isAwaiting}
			top={10}
			width={700}
		>
			<FormDoctorProfileDataICP formStateManager={formStateManager} />

			{props.fieldConfig?.showHasDigitalCertificate && (
				<SwitchCP formStateManager={formStateManager} fieldName={'hasDigitalCertificate'} label={'Possui certificado digital ativo?'} />
			)}

			<TableDoctorIdsCP doctorCode={props.person.code} formStateManager={formStateManager} />
			<FormAddressCP formStateManager={addressFormStateManager} />
		</ModalCP>
	)
}
