import { useAppSelector } from 'app/redux/hook'
import { ScreenDevTest } from 'app/screens/screen-dev/ScreenDevTest'
import { AppUtils } from 'app/utils/AppUtils'
import { ScreenAdminBeeRads } from 'modules/admin-beerads/screens/screen-admin-beerads/ScreenAdminBeeRads'
import { ScreenClinicsAdmin } from 'modules/clinic/screens/screen-clinics-admin/ScreenClinicsAdmin'
import { ScreenDoctorGroup } from 'modules/doctor-groups/screens/screen-doctor-group/ScreenDoctorGroup'
import { ScreenExams } from 'modules/exams/screens/screen-exams/ScreenExams'
import { ScreenMedicalReport } from 'modules/exams/screens/screen-medical-report/ScreenMedicalReport'
import { ScreenMedicalTimetableConfig } from 'modules/medical-timetable/screens/screen-medical-timetable-config/ScreenMedicalTimetableConfig'
import { ScreenMedicalTimetableDoctor } from 'modules/medical-timetable/screens/screen-medical-timetable-doctor/ScreenMedicalTimetableDoctor'
import { ScreenMedicalTimetable } from 'modules/medical-timetable/screens/screen-medical-timetable/ScreenMedicalTimetable'
import { ScreenProfile } from 'modules/person/screens/screen-profile/ScreenProfile'
import { ScreenAudit } from 'modules/quality-assurance/screens/screen-audit/ScreenAudit'
import { ScreenDoctorAudits } from 'modules/quality-assurance/screens/screen-doctor-audits/ScreenDoctorAudits'
import { ScreenQualityAssuranceAudits } from 'modules/quality-assurance/screens/screen-quality-assurance-audits/ScreenQualityAssuranceAudits'
import { ScreenQualityAssuranceExams } from 'modules/quality-assurance/screens/screen-quality-assurance-exams/ScreenQualityAssuranceExams'
import { ScreenHome } from 'modules/reports/screens/screen-home/ScreenHome'
import { ScreenReports } from 'modules/reports/screens/screen-reports/ScreenReports'
import { ScreenTemplates } from 'modules/template/screens/screen-templates/ScreenTemplates'
import { useEffect } from 'react'
import { RouterSwitchCP } from 'submodules/nerit-framework-ui/common/router/components/router-switch/RouterSwitchCP'
import { ScreenError404 } from 'submodules/nerit-framework-ui/common/screens/ScreenError404'

PrivateUserRouter.ROOT = 'app'

PrivateUserRouter.HOME = `${PrivateUserRouter.ROOT}/home`

PrivateUserRouter.EXAMS = `${PrivateUserRouter.ROOT}/exams/:examStatusGroup?`
PrivateUserRouter.MEDICAL_REPORT = `${PrivateUserRouter.ROOT}/medical-report/:examId`

PrivateUserRouter.REPORTS = `${PrivateUserRouter.ROOT}/reports`
PrivateUserRouter.REPORT_CLINIC_MAIN = `${PrivateUserRouter.REPORTS}/clinic-main`
PrivateUserRouter.REPORT_CLINIC_EXAMS_DONE = `${PrivateUserRouter.REPORTS}/clinc-exams-done`
PrivateUserRouter.REPORT_CLINIC_GROWTH_ANALYSIS = `${PrivateUserRouter.REPORTS}/clinc-growth-analysis`
PrivateUserRouter.REPORT_CLINIC_SLA = `${PrivateUserRouter.REPORTS}/clinic-sla`
PrivateUserRouter.REPORT_DOCTOR_GROUP_MAIN = `${PrivateUserRouter.REPORTS}/doctor-group-main`
PrivateUserRouter.REPORT_DOCTOR_GROUP_EXAMS_DONE = `${PrivateUserRouter.REPORTS}/doctor-group-exams-done`
PrivateUserRouter.REPORT_DOCTOR_GROUP_GROWTH_ANALYSIS = `${PrivateUserRouter.REPORTS}/doctor-group-growth-analysis`
PrivateUserRouter.REPORT_DOCTOR_GROUP_SLA = `${PrivateUserRouter.REPORTS}/doctor-group-sla`
PrivateUserRouter.REPORT_DOCTOR_GROUP_RECTIFICATION = `${PrivateUserRouter.REPORTS}/doctor-group-rectification`
PrivateUserRouter.REPORT_DOCTOR_GROUP_NPS = `${PrivateUserRouter.REPORTS}/doctor-group-nps`
PrivateUserRouter.REPORT_DOCTOR_MAIN = `${PrivateUserRouter.REPORTS}/doctor-main`
PrivateUserRouter.REPORT_DOCTOR_EXAMS_DONE = `${PrivateUserRouter.REPORTS}/doctor-exams-done`

PrivateUserRouter.DOCTOR_GROUP = `${PrivateUserRouter.ROOT}/doctor-groups`
PrivateUserRouter.DOCTOR_GROUP_DATA = `${PrivateUserRouter.DOCTOR_GROUP}/main-data`
PrivateUserRouter.DOCTOR_GROUP_CLINICS = `${PrivateUserRouter.DOCTOR_GROUP}/clinics`
PrivateUserRouter.DOCTOR_GROUP_PEOPLE_USERS = `${PrivateUserRouter.DOCTOR_GROUP}/users`
PrivateUserRouter.DOCTOR_GROUP_TEAMS = `${PrivateUserRouter.DOCTOR_GROUP}/teams`
PrivateUserRouter.DOCTOR_GROUP_PEOPLE_DOCTORS = `${PrivateUserRouter.DOCTOR_GROUP}/doctors`
PrivateUserRouter.DOCTOR_GROUP_EXAMS = `${PrivateUserRouter.DOCTOR_GROUP}/exam-descriptions`
PrivateUserRouter.DOCTOR_GROUP_TEMPLATES = `${PrivateUserRouter.DOCTOR_GROUP}/templates`
// PrivateUserRouter.DOCTOR_GROUP_EXAM_PRICE = `${PrivateUserRouter.DOCTOR_GROUP}/pricing-clinic`
// PrivateUserRouter.DOCTOR_GROUP_REVENUE_PRICE = `${PrivateUserRouter.DOCTOR_GROUP}/doctor-revenue`
// PrivateUserRouter.DOCTOR_GROUP_CLINIC_BILLING = `${PrivateUserRouter.DOCTOR_GROUP}/exam-group-billing`
// PrivateUserRouter.DOCTOR_GROUP_DOCTOR_REVENUE = `${PrivateUserRouter.DOCTOR_GROUP}/exam-group-revenue`

PrivateUserRouter.MEDICAL_TIMETABLE = `${PrivateUserRouter.ROOT}/medical-timetables`
PrivateUserRouter.MEDICAL_TIMETABLE_DOCTOR = `${PrivateUserRouter.MEDICAL_TIMETABLE}/doctor`
PrivateUserRouter.MEDICAL_TIMETABLE_CONFIG = `${PrivateUserRouter.MEDICAL_TIMETABLE}/config`

PrivateUserRouter.QUALITY_ASSURANCE_ROOT = `${PrivateUserRouter.ROOT}/quality-assurance`
PrivateUserRouter.QUALITY_ASSURANCE_AUDITS = `${PrivateUserRouter.QUALITY_ASSURANCE_ROOT}/audits`
PrivateUserRouter.QUALITY_ASSURANCE_EXAMS = `${PrivateUserRouter.QUALITY_ASSURANCE_ROOT}/exams`
PrivateUserRouter.DOCTOR_AUDITS = `${PrivateUserRouter.QUALITY_ASSURANCE_ROOT}/doctor-audits`
PrivateUserRouter.AUDIT = `${PrivateUserRouter.QUALITY_ASSURANCE_ROOT}/audit/:auditCode`

PrivateUserRouter.TEMPLATES = `${PrivateUserRouter.ROOT}/templates`

PrivateUserRouter.CLINIC = `${PrivateUserRouter.ROOT}/clinics`
PrivateUserRouter.CLINIC_DATA = `${PrivateUserRouter.CLINIC}/clinica-data`
PrivateUserRouter.CLINIC_REPORT_CONFIG = `${PrivateUserRouter.CLINIC}/report-config`
PrivateUserRouter.CLINIC_REPORT_PREVIEW = `${PrivateUserRouter.CLINIC}/report-preview`
PrivateUserRouter.CLINIC_TEMPLATES = `${PrivateUserRouter.CLINIC}/templates`
PrivateUserRouter.CLINIC_TECHNICAL_CONFIG = `${PrivateUserRouter.CLINIC}/technical`
PrivateUserRouter.CLINIC_DOCTOR_GROUPS = `${PrivateUserRouter.CLINIC}/doctor-groups`
PrivateUserRouter.CLINIC_USERS = `${PrivateUserRouter.CLINIC}/users`
PrivateUserRouter.CLINIC_BILLING = `${PrivateUserRouter.CLINIC}/billing`

PrivateUserRouter.ADMIN_BEERADS = `${PrivateUserRouter.ROOT}/admin-beerads`
PrivateUserRouter.ADMIN_BEERADS_DOCTORS = `${PrivateUserRouter.ADMIN_BEERADS}/doctors`
PrivateUserRouter.ADMIN_BEERADS_CLINICS = `${PrivateUserRouter.ADMIN_BEERADS}/clinics`

PrivateUserRouter.PROFILE = `${PrivateUserRouter.ROOT}/profile`

PrivateUserRouter.DEV_TEST = `${PrivateUserRouter.ROOT}/dev-test`

/**
 * Define rotas que so podem ser acessadas apos realizar login na VISAO DE USUARIO.
 * (medico / funcionario de clinica).
 */
export function PrivateUserRouter(): JSX.Element {
	const loggedUserData = useAppSelector((state) => state.auth)

	useEffect(refreshUserToken, [])

	/**
	 */
	function refreshUserToken(): void {
		if (!loggedUserData.token) {
			return
		}

		AppUtils.refreshToken(loggedUserData.token)
	}

	return (
		<RouterSwitchCP
			routes={[
				{ path: PrivateUserRouter.HOME, component: ScreenHome, exact: true },

				{ path: PrivateUserRouter.EXAMS, component: ScreenExams, exact: true },
				{ path: PrivateUserRouter.MEDICAL_REPORT, component: ScreenMedicalReport },

				{ path: PrivateUserRouter.DOCTOR_GROUP, component: ScreenDoctorGroup },
				{ path: PrivateUserRouter.MEDICAL_TIMETABLE, component: ScreenMedicalTimetable, exact: true },
				{ path: PrivateUserRouter.MEDICAL_TIMETABLE_CONFIG, component: ScreenMedicalTimetableConfig },
				{ path: PrivateUserRouter.MEDICAL_TIMETABLE_DOCTOR, component: ScreenMedicalTimetableDoctor },

				{ path: PrivateUserRouter.QUALITY_ASSURANCE_AUDITS, component: ScreenQualityAssuranceAudits },
				{ path: PrivateUserRouter.QUALITY_ASSURANCE_EXAMS, component: ScreenQualityAssuranceExams },
				{ path: PrivateUserRouter.DOCTOR_AUDITS, component: ScreenDoctorAudits },
				{ path: PrivateUserRouter.AUDIT, component: ScreenAudit },

				{ path: PrivateUserRouter.REPORTS, component: ScreenReports },
				{ path: PrivateUserRouter.TEMPLATES, component: ScreenTemplates },
				{ path: PrivateUserRouter.CLINIC, component: ScreenClinicsAdmin },
				{ path: PrivateUserRouter.ADMIN_BEERADS, component: ScreenAdminBeeRads },
				{ path: PrivateUserRouter.PROFILE, component: ScreenProfile },

				{ path: PrivateUserRouter.DEV_TEST, component: ScreenDevTest },

				{ component: () => <ScreenError404 redirectTo={PrivateUserRouter.HOME} /> },
			]}
		/>
	)
}
