import { FrontEnvironmentEnum } from 'submodules/nerit-framework-utils/utils/enums/EnvironmentEnum'

export class AppConfig {
	readonly environment = import.meta.env.MODE as FrontEnvironmentEnum
	readonly reduxEncryptKey = import.meta.env.VITE_REDUX_ENCRYPT_KEY
	readonly defaultTimeZone = import.meta.env.VITE_DEFAULT_TIME_ZONE
	readonly uiBaseUrl = import.meta.env.VITE_UI_BASE_URL
	readonly apiBaseUrl = import.meta.env.VITE_API_BASE_URL
	readonly api2BaseUrl = import.meta.env.VITE_API2_BASE_URL
	readonly beeradsViewerBaseUrl = import.meta.env.VITE_BEERADS_VIEWER_BASE_URL
	readonly space4LeadsApiBaseUrl = import.meta.env.VITE_SPACE4LEADS_API_BASE_URL
	readonly leoRadUrl = import.meta.env.VITE_LEORAD_URL

	private static instance: AppConfig

	/** Gera & retorna instancia unica da classe. */
	static getInstance(): AppConfig {
		if (!this.instance) {
			this.instance = new AppConfig()
		}

		return this.instance
	}
}
