import { useAppSelector } from 'app/redux/hook'
import { CollapseTemplateMedicalReportHistoryCP } from 'modules/template/components/collapse-template-medical-report-history/CollapseTemplateMedicalReportHistoryCP'
import { useEffect, useState } from 'react'
import { TemplatesRequests } from 'submodules/beerads-sdk/services/templates/templates/TemplatesRequests'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import { BadgeCP } from 'submodules/nerit-framework-ui/common/components/badge/BadgeCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { TabItemTP } from 'submodules/nerit-framework-ui/common/components/tabs/inner/TabItemTP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { useQueryParams } from 'submodules/nerit-framework-ui/common/hooks/useQueryParam'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

const VX_DOCTOR_GROUP_CODE = 142
export const TabAnalysisTemplateUtils = {
	getTab(loadTemplateList: number, refreshTabTemplates: (number: number) => void): TabItemTP {
		const currentDoctorGroupCode = useAppSelector((selector) => selector.doctorGroup.code)
		const loggedUser = useAppSelector((selector) => selector.auth.user)


		const [query] = useQueryParams<{ clinicCode: number }>({
			parsers: {
				clinicCode: (code) => Number(code),
			},
		})

		const [listTemplateHistory, setListTemplateHistory] = useState<ListResponseDTO<TemplateResponseDTO>>()
		const getTemplateHistory = useRequest<ListResponseDTO<TemplateResponseDTO>>('api-return')

		const [loadList, setLoadList] = useState<number>(0)

		useEffect(init, [loadList, loadTemplateList])
		useEffect(onGetTemplateHistoryChange, [getTemplateHistory.isAwaiting])

		function init(): void {
			getTemplateHistory.runRequest(
				TemplatesRequests.search({ awaitingApproval: true, type: TemplateTypeEnum.TEMPLATE, clinicCode: query.clinicCode }),
			)
		}

		function onGetTemplateHistoryChange(): void {
			if (!RequestUtils.isValidRequestReturn(getTemplateHistory)) {
				NotificationHelper.DEFAULT_ERROR_GET_MESSAGE
				return
			}

			setListTemplateHistory(getTemplateHistory.responseData)
		}

		function onChangeDataOnTable(): void {
			setLoadList(TableUtils.getReloadNumber())
			refreshTabTemplates(TableUtils.getReloadNumber())
		}

		return {
			key: 'analysis',
			title: (
				<BadgeCP count={listTemplateHistory?.total} overflowCount={10} size="small">
					<TextCP text={'Em análise'} />
				</BadgeCP>
			),
			content: <CollapseTemplateMedicalReportHistoryCP templateHistories={listTemplateHistory?.list} currentTemplateList={listTemplateHistory?.list} onChangeData={onChangeDataOnTable} />,
			disabled: !currentDoctorGroupCode || currentDoctorGroupCode !== VX_DOCTOR_GROUP_CODE,
			hide: !loggedUser?.isDoctor
		}
	},
}
