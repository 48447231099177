import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { WelcomeContentSelectDoctorGroupCP } from 'app/components/welcome-content-select-doctor-group/WelcomeContentSelectDoctorGroupCP'
import { useAppSelector } from 'app/redux/hook'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { AppUtils } from 'app/utils/AppUtils'
import {
	DOCTOR_GROUP_SUBMENU_EXAMS_KEY,
	DOCTOR_GROUP_SUBMENU_FINANCIAL_KEY,
	DOCTOR_GROUP_SUBMENU_GENERAL_KEY,
	DOCTOR_GROUP_SUBMENU_TEAMS_KEY,
	DoctorGroupUtils,
	DoctorGroupViewTP,
} from 'modules/doctor-groups/utils/DoctorGroupUtils'
import { LayoutSubmenuCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/layout/LayoutSubmenuCP'

/**
 */
export function ScreenDoctorGroup(): JSX.Element {
	const { auth, doctorGroup } = useAppSelector((state) => state)

	/**
	 */

	if (!doctorGroup.code) return <WelcomeContentSelectDoctorGroupCP />

	return (
		<AccessControlCP
			permission={{
				byHasAnyPermissionInDoctorGroup: {
					doctorGroupCode: doctorGroup.code,
					permissions: ['isDoctorGroupAdmin', 'isDoctorGroupUser'],
				},
			}}
			redirect={{ redirectTo: PrivateUserRouter.HOME, shouldRedirect: true }}
		>
			<LayoutSubmenuCP<DoctorGroupViewTP>
				titleBrowser={'Grupo de Médico'}
				loggedDomain={AppUtils.getDomain()}
				menuTitle={doctorGroup?.name ?? 'Selecione um Grupo de Médicos'}
				defaultSubmenu={'doctorGroupData'}
				defaultOpenKeys={[
					DOCTOR_GROUP_SUBMENU_GENERAL_KEY,
					DOCTOR_GROUP_SUBMENU_TEAMS_KEY,
					DOCTOR_GROUP_SUBMENU_EXAMS_KEY,
					DOCTOR_GROUP_SUBMENU_FINANCIAL_KEY,
				]}
				submenuConfigs={DoctorGroupUtils.getSubMenuConfigs(auth, doctorGroup.code)}
			/>
		</AccessControlCP>
	)
}
