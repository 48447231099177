import { ExamDescriptionGroupRequests } from 'submodules/beerads-sdk/services/doctor-groups/exam-group/ExamDescriptionGroupRequests'
import { ExamGroupTypeEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamTypeEnum'
import { AutocompletePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps<FModelTP extends FormModel = any> {
	label?: string
	fieldName?: keyof FModelTP
	formStateManager?: IFormStateManager<FModelTP>
	isMultiple?: boolean
	required?: boolean
	type: ExamGroupTypeEnum
	doctorGroupCode: number
}

export function SelectExamGroupCP<FModelTP extends FormModel = any>(props: ICPProps): JSX.Element {
	return (
		<AutocompletePickerCP
			label={props.label}
			showOptionsOnLoad={true}
			formStateManager={props.formStateManager}
			fieldName={props.fieldName}
			required={props.required}
			requestConfigGetter={() =>
				ExamDescriptionGroupRequests.search({
					doctorGroupCode: props.doctorGroupCode,
					type: props.type,
				})
			}
			filterOption={true}
		/>
	)
}
