import { Popconfirm } from 'antd'

interface IPopConfirmCPProps {
	title?: string
	children: JSX.Element
	onConfirm?: () => void
	onCancel?: () => void
	onVisibilityChange?: VoidFunction
	okText?: string
	cancelText?: string
	disabled?: boolean
	icon?: JSX.Element
	placement?:
		| 'left'
		| 'right'
		| 'top'
		| 'bottom'
		| 'bottomRight'
		| 'topLeft'
		| 'topRight'
		| 'bottomLeft'
		| 'leftTop'
		| 'leftBottom'
		| 'rightTop'
		| 'rightBottom'
		| undefined
}

/**
 * Exibe 01 pequeno modal de confirmacao que se posiciona automaticamente proximo ao 'fiho' envolto por este componente.
 */
export function PopConfirmCP({ onVisibilityChange, ...props }: IPopConfirmCPProps): JSX.Element {
	return (
		<Popconfirm
			title={props.title}
			onConfirm={props.onConfirm}
			onCancel={props.onCancel}
			okText={props.okText ?? 'Sim'}
			cancelText={props.cancelText ?? 'Cancelar'}
			icon={props.icon}
			placement={props.placement}
			disabled={props.disabled}
			okButtonProps={{ size: 'middle' }}
			cancelButtonProps={{ size: 'middle' }}
		>
			{props.children}
		</Popconfirm>
	)
}
