import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ExamUrgencyTypeEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'

export const INVALID_EXAM_DESCRIPTION_CODE = -1

export interface IPendingExamDescription {
	code?: number
	modality: ExamModalityEnum
	description: string
	accessionNumber?: string
	uniqueExamIdPartnerSystem?: string
	urgencyType?: ExamUrgencyTypeEnum
}
