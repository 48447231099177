import { useAppSelector } from 'app/redux/hook'
import { ThemeProjectVX } from 'config/theme/project/white-labels/ThemeProjectVX'
import styled from 'styled-components'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

/**
 */
export function IconProfileMenuItemICP(): JSX.Element {
	const authData = useAppSelector((state) => state.auth)

	let color: string = ThemeProjectVX.success
	let icon = 'check'
	let comment: string | undefined

	// Se for medico, valida se ele deveria estar logado com certificado ou nao
	if (authData.user?.isDoctor && authData.user?.hasDigitalCertificate) {
		if (authData?.isLoggedWithDigitalCertificate) {
			icon = 'lock'
		} else {
			color = ThemeProjectVX.warning
			icon = 'warning'
			comment = 'Você não está autenticado com certificado digital, portanto não conseguirá ver exames em que é obrigatório a assinatura digital'
		}
	}

	return (
		<WrapperSCP color={color}>
			<TooltipCP text={comment} showSpan={true}>
				<IconICP iconName={icon} size={10} color={`${ThemeFrameworkCommon.browserDefaultColorDark}`} />
			</TooltipCP>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div<{ color: string }>`
	background: ${(props) => props.color};
	position: absolute;
	width: 20px;
	height: 20px;
	border-radius: 15px;
	bottom: -5px;
	right: 10px;
	border: 2px solid #dedede;
	display: flex;
	justify-content: center;
	align-items: center;

	> span {
		margin-bottom: 1px !important;
	}
`
