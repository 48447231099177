import * as SelectPrimitive from '@radix-ui/react-select'
import * as React from 'react'
import { LuCheck, LuChevronDown, LuChevronUp } from 'react-icons/lu'
import { tv } from 'tailwind-variants'

const Select = SelectPrimitive.Root

const SelectGroup = SelectPrimitive.Group

const SelectValue = SelectPrimitive.Value
const SelectTriggerVariant = tv({
	base: 'placeholder:text-muted-foreground flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1',
})
const SelectTrigger = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Trigger>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>>(
	({ className, children, ...props }, ref) => (
		<SelectPrimitive.Trigger ref={ref} className={SelectTriggerVariant({ className })} {...props}>
			{children}
			<SelectPrimitive.Icon asChild>
				<LuChevronDown className="h-4 w-4 opacity-50" />
			</SelectPrimitive.Icon>
		</SelectPrimitive.Trigger>
	),
)
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName
const SelectScrollUpVariant = tv({
	base: 'flex cursor-default items-center justify-center py-1',
})
const SelectScrollUpButton = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
	React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
	<SelectPrimitive.ScrollUpButton ref={ref} className={SelectScrollUpVariant({ className })} {...props}>
		<LuChevronUp className="h-4 w-4" />
	</SelectPrimitive.ScrollUpButton>
))
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName

const SelectScrollDownVariant = tv({
	base: 'flex cursor-default items-center justify-center py-1',
})
const SelectScrollDownButton = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
	React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
	<SelectPrimitive.ScrollDownButton ref={ref} className={SelectScrollDownVariant({ className })} {...props}>
		<LuChevronDown className="h-4 w-4" />
	</SelectPrimitive.ScrollDownButton>
))
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName

const SelectContentVariant = tv({
	base: 'text-popover-foreground relative z-50 max-h-96 min-w-[8rem] overflow-hidden rounded-md border bg-popover shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
	variants: {
		position: {
			popper: 'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
		},
	},
})
const SelectViewportVariant = tv({
	base: 'p-1',
	variants: {
		position: {
			popper: 'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]',
		},
	},
})
const SelectContent = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Content>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>>(
	({ className, children, position = 'popper', ...props }, ref) => (
		<SelectPrimitive.Portal>
			<SelectPrimitive.Content ref={ref} className={SelectContentVariant({ position: 'popper', className })} position={position} {...props}>
				<SelectScrollUpButton />
				<SelectPrimitive.Viewport className={SelectViewportVariant({ position: 'popper' })}>{children}</SelectPrimitive.Viewport>
				<SelectScrollDownButton />
			</SelectPrimitive.Content>
		</SelectPrimitive.Portal>
	),
)
SelectContent.displayName = SelectPrimitive.Content.displayName

const SelectLabelVariant = tv({
	base: 'py-1.5 pl-8 pr-2 text-sm font-semibold',
})
const SelectLabel = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Label>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>>(
	({ className, ...props }, ref) => <SelectPrimitive.Label ref={ref} className={SelectLabelVariant({ className })} {...props} />,
)
SelectLabel.displayName = SelectPrimitive.Label.displayName

const SelectItemVariant = tv({
	base: 'focus:text-accent-foreground relative flex w-full justify-between cursor-default select-none items-center rounded-sm py-1.5 px-2 text-sm outline-none focus:bg-accent data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
})
const SelectItem = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Item>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>>(
	({ className, children, ...props }, ref) => (
		<SelectPrimitive.Item ref={ref} className={SelectItemVariant({ className })} {...props}>
			<SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
			<span className="h-3.5 w-3.5 items-center justify-center">
				<SelectPrimitive.ItemIndicator>
					<LuCheck className="h-4 w-4" />
				</SelectPrimitive.ItemIndicator>
			</span>
		</SelectPrimitive.Item>
	),
)
SelectItem.displayName = SelectPrimitive.Item.displayName

const SelectSeparatorVariant = tv({
	base: '-mx-1 my-1 h-px bg-muted',
})
const SelectSeparator = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.Separator>,
	React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => <SelectPrimitive.Separator ref={ref} className={SelectSeparatorVariant({ className })} {...props} />)
SelectSeparator.displayName = SelectPrimitive.Separator.displayName

export {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectScrollDownButton,
	SelectScrollUpButton,
	SelectSeparator,
	SelectTrigger,
	SelectValue,
}
