import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { WidgetOnlyValuesResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/features/dashboard/dtos/response/WidgetOnlyValuesResponseDTO'
import { CountExamsAssignedBySlaWidgetsRequestDTO } from 'submodules/beerads-sdk/services/dashboard/widgets/dtos/requests/CountExamsAssignedBySlaWidgetsRequestDTO'
import { CountExamsAssignedBySlaWidgetsResponseDTO } from 'submodules/beerads-sdk/services/dashboard/widgets/dtos/responses/CountExamsAssignedBySlaWidgetsResponseDTO'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { MedicalReportsSlaAnalysisRequestDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/request/MedicalReportsSlaAnalysisRequestDTO'
import { ExamUrgencyTypeEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { RectificationReportRequestDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/request/RectificationReportRequestDTO'
import { AuditSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/audits/dtos/request/AuditSearchRequestDTO'

export class WidgetsRequests {
	public static CONTROLLER_ROOT = 'widgets'
	public static COUNT_EXAMS_EP = 'count-exams'
	public static COUNT_EXAMS_ASSIGNED_BY_SLA_EP = 'count-exams-assigned'
	public static MEDICAL_REPORTS_SLA_SUMMARY_EP = 'medical-reports-sla-summary'
	public static MEDICAL_REPORTS_SLA_BY_URGENCY_EP = 'medical-reports-sla-by-urgency/:urgencyType'
	public static MEDICAL_REPORTS_RECTIFICATION_SUMMARY_EP = 'medical-reports-rectification-summary'
	public static MEDICAL_REPORTS_RECTIFICATION_CLINICAL_IMPACT_EP = 'medical-reports-rectification-clinical-impact'
	public static COUNT_AUDITS_EP = 'count-audits'
	public static AVERAGE_AUDITS_SCORE_EP = 'average-audits-score'
	public static AUDIT_PERCENTAGE_EP = 'audit-percentage'

	static countExams = (dto: ExamSearchRequestDTO): RequestConfigTP<WidgetOnlyValuesResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${WidgetsRequests.CONTROLLER_ROOT}/${WidgetsRequests.COUNT_EXAMS_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static countAudits = (dto: AuditSearchRequestDTO): RequestConfigTP<WidgetOnlyValuesResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${WidgetsRequests.CONTROLLER_ROOT}/${WidgetsRequests.COUNT_AUDITS_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static averageAuditsScore = (dto?: AuditSearchRequestDTO): RequestConfigTP<WidgetOnlyValuesResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${WidgetsRequests.CONTROLLER_ROOT}/${WidgetsRequests.AVERAGE_AUDITS_SCORE_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static countExamsAssignedBySla = (dto: CountExamsAssignedBySlaWidgetsRequestDTO): RequestConfigTP<CountExamsAssignedBySlaWidgetsResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${WidgetsRequests.CONTROLLER_ROOT}/${WidgetsRequests.COUNT_EXAMS_ASSIGNED_BY_SLA_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static medicalReportSlaSummary = (dto: MedicalReportsSlaAnalysisRequestDTO): RequestConfigTP<WidgetOnlyValuesResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${WidgetsRequests.CONTROLLER_ROOT}/${WidgetsRequests.MEDICAL_REPORTS_SLA_SUMMARY_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static medicalReportRectificationSummary = (dto: RectificationReportRequestDTO): RequestConfigTP<WidgetOnlyValuesResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${WidgetsRequests.CONTROLLER_ROOT}/${WidgetsRequests.MEDICAL_REPORTS_RECTIFICATION_SUMMARY_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static medicalReportRectificationClinicalImpact = (dto: RectificationReportRequestDTO): RequestConfigTP<WidgetOnlyValuesResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${WidgetsRequests.CONTROLLER_ROOT}/${WidgetsRequests.MEDICAL_REPORTS_RECTIFICATION_CLINICAL_IMPACT_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static medicalReportSlaByUrgency = (
		urgency: ExamUrgencyTypeEnum,
		dto: MedicalReportsSlaAnalysisRequestDTO,
	): RequestConfigTP<WidgetOnlyValuesResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${WidgetsRequests.CONTROLLER_ROOT}/${WidgetsRequests.MEDICAL_REPORTS_SLA_BY_URGENCY_EP.replace(':urgencyType', urgency)}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static auditPercentage = (dto?: AuditSearchRequestDTO): RequestConfigTP<WidgetOnlyValuesResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${WidgetsRequests.CONTROLLER_ROOT}/${WidgetsRequests.AUDIT_PERCENTAGE_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})
}
