import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { AttachmentIconCP } from '_old/main/common/components/icons/AttachmentIconCP'
import { CheckIconCP } from '_old/main/common/components/icons/CheckIconCP'
import { ExamIconCP } from '_old/main/common/components/icons/ExamIconCP'
import { NotesIconCP } from '_old/main/common/components/icons/NotesIconCP'
import { ExamUtils } from 'modules/exams/_old/ExamUtils'
import { ButtonIntegrateMedicalReportCP } from 'modules/exams/components/exam-history/button-integrate-medical-report/ButtonIntegrateMedicalReportCP'
import { StethoscopeIconCP } from 'modules/person/components/doctor/icons/StethoscopeIconCP'
import { useEffect, useState } from 'react'
import { ExamHistoryRequests } from 'submodules/beerads-sdk/services/exams/history/ExamHistoryRequests'
import { ExamHistorySearchRequestDTO } from 'submodules/beerads-sdk/services/exams/history/dtos/request/ExamHistorySearchRequestDTO'
import { ExamHistoryResponseDTO } from 'submodules/beerads-sdk/services/exams/history/dtos/response/ExamHistoryResponseDTO'
import { ExamHistoryEnum, ExamHistoryLabelEnum } from 'submodules/beerads-sdk/services/exams/history/types/ExamHistoryEnum'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { FontAwsomeIconCP } from 'submodules/nerit-framework-ui/common/components/icon/FontAwsomeIconCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ITimelineItem, TimelineCP } from 'submodules/nerit-framework-ui/common/components/timeline/TimelineCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface IHistoryDrawerContentCPProps {
	examCode: number
	event?: ExamHistoryEnum
}

/**
 * Componente para exibir o log de um exame.
 */
export function TimelineExamHistoryCP(props: IHistoryDrawerContentCPProps): JSX.Element {
	const [timelineItems, setTimelineItems] = useState<ITimelineItem[]>()

	const searchHistoryRequest = useRequest<ListResponseDTO<ExamHistoryResponseDTO>>()
	useEffect(onSearchHistoryRequestChange, [searchHistoryRequest.isAwaiting])

	useEffect(init, [])

	/**
	 * Inicializa
	 */
	function init(): void {
		const dto: ExamHistorySearchRequestDTO = {
			examCode: props.examCode,
			event: props.event,
		}
		searchHistoryRequest.runRequest(ExamHistoryRequests.search(dto))
	}

	/**
	 * Retorno da busca.
	 */
	function onSearchHistoryRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(searchHistoryRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const items: ITimelineItem[] = []
		searchHistoryRequest.responseData!.list.map((item) => {
			const log: ITimelineItem = {
				title: ExamHistoryLabelEnum[item.event],
				content: ExamUtils.getExamHistoryMessage(item),
			}
			switch (item.event) {
				case ExamHistoryEnum.ADDED_ATTACHMENT:
				case ExamHistoryEnum.REMOVED_ATTACHMENT:
					log.icon = <AttachmentIconCP />
					break

				case ExamHistoryEnum.ADDED_NOTE:
				case ExamHistoryEnum.REMOVED_NOTE:
					log.icon = <NotesIconCP />
					break

				case ExamHistoryEnum.RETURNED_EXAM:
				case ExamHistoryEnum.EDITED_EXAM:
					log.icon = <StethoscopeIconCP />
					break

				case ExamHistoryEnum.CREATED_EXAM:
					log.icon = <ExamIconCP />
					break

				case ExamHistoryEnum.FINISHED_REPORT:
					log.icon = <CheckIconCP />
					break

				case ExamHistoryEnum.REPORT_SENT_BY_WHATSAPP:
					log.icon = <FontAwsomeIconCP type={faWhatsapp} />
					break

				case ExamHistoryEnum.VIEWED_REPORT:
					log.icon = <IconICP iconName={'printer'} />
					break

				case ExamHistoryEnum.SEND_REPORTS_TO_INTEGRATION:
				case ExamHistoryEnum.SEND_REPORTS_TO_INTEGRATION_ERROR:
				case ExamHistoryEnum.SEND_REPORTS_TO_INTEGRATION_QUIT:
				case ExamHistoryEnum.SEND_REPORTS_TO_INTEGRATION_SUCCESSFULL:
					log.content = (
						<>
							{log.content}
							<FlexCP justify={'center'} margin={{ top: 5 }}>
								<ButtonIntegrateMedicalReportCP examCode={item.examCode} onIntegrate={init} />
							</FlexCP>
						</>
					)
					log.icon = <FontAwsomeIconCP type={faPaperPlane} />
					break

				default:
					break
			}

			items.push(log)
		})
		setTimelineItems(items)
	}

	return <TimelineCP mode={'alternate'} loading={searchHistoryRequest.isAwaiting} items={timelineItems} />
}
