import { useAppSelector } from 'app/redux/hook'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { ExamDataFormModel } from 'modules/exams/components/exams/modal-exam-data/inner/form-model/ExamDataFormModel'
import { ExamDataFormModelConverter } from 'modules/exams/components/exams/modal-exam-data/inner/form-model/ExamDataFormModelConverter'
import { FormExamDataICP } from 'modules/exams/components/exams/modal-exam-data/inner/FormExamDataICP'
import { FormExamPatientDataICP } from 'modules/exams/components/exams/modal-exam-data/inner/FormExamPatientDataICP'
import { useEffect, useState } from 'react'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ExamsRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import { IBasicModalCPProps, ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps extends IBasicModalCPProps {
	exam: ExamResponseDTO
}

/**
 */
export function ModalExamDataCP(props: ICPProps): JSX.Element {
	const loggedUser = useAppSelector((state) => state.auth.user)
	const [formValidator, setFormValidator] = useState<ExamDataFormModel>()
	const formStateManager = useFormStateManager<ExamDataFormModel>(formValidator)

	const updateExamDataRequest = useRequest<void>('none')
	useEffect(onUpdateExamRequestChange, [updateExamDataRequest.isAwaiting])

	useEffect(init, [props.visible])

	/**
	 */
	function init(): void {
		formStateManager.reset(undefined)
		if (!props.visible) return

		setFormValidator(ExamDataFormModelConverter.getFormModel(props.exam))
	}

	/**
	 */
	async function updateExam(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) return

		const formValues = formStateManager.getFormValues()!
		const dto = ExamDataFormModelConverter.getDto(props.exam.code, formValues)
		updateExamDataRequest.runRequest(ExamsRequests.updateExamData(dto))
	}

	/**
	 */
	function onUpdateExamRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				updateExamDataRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSave()
	}

	if (!formStateManager.getFormValues()) return <></>

	// Alguns dados nao podem ser editados quando laudo for dado, para usuarios da clinica
	let shouldDisableEditSomeData = true

	// Se for usuario do grupo sempre pode editar
	// Ou se estiver em um dos status abaixo sempre pode editar tb
	if (
		!!props.exam.doctorGroup &&
		DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(loggedUser, props.exam.doctorGroup.code, [
			'isDoctorGroupAdmin',
			'isDoctorGroupUser',
			'isDoctorAdmin',
		])
	)
		shouldDisableEditSomeData = false
	else if ([ExamStatusEnum.PENDING, ExamStatusEnum.RETURNED, ExamStatusEnum.AWAITING, ExamStatusEnum.ARCHIVED].includes(props.exam.status))
		shouldDisableEditSomeData = false

	return (
		<ModalCP
			title={'Editar dados do exame'}
			visible={props.visible}
			onOk={updateExam}
			actionLoading={updateExamDataRequest.isAwaiting}
			onCancel={props.onCancel}
			top={10}
			width={600}
		>
			<FormExamPatientDataICP formStateManager={formStateManager} shouldDisableEditSomeData={shouldDisableEditSomeData} />
			<FormExamDataICP exam={props.exam} formStateManager={formStateManager} shouldDisableEditSomeData={shouldDisableEditSomeData} />
		</ModalCP>
	)
}
