import { DatePicker } from 'antd'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

export interface IScpProps {
	showBorder?: boolean
	marginRight?: number
	marginLeft?: number
	marginTop?: number
	marginBottom?: number
}

export const WrapperSCP = styled.div<IScpProps>`
	margin: ${(props) => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
`

export const DatePickerContainerSCP = styled.div<{ showBorder?: boolean }>`
	height: ${(props) => (props.showBorder ? undefined : '37px')};
	padding-bottom: 2px;
	border-bottom: ${(props) => (props.showBorder ? 'solid 1px #BBBBBB' : '')};
`

export const DateRangePickerSCP = styled(DatePicker.RangePicker)`
	&.ant-picker {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		border: none;
		box-shadow: none;
		background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};

		.ant-picker-icon {
			width: 28px;
			height: 28px;
			margin-top: -9px;
			left: 0;

			&:after {
				font-size: 20px;
				color: ${ThemeProject.warning};
			}
		}

		.ant-picker-input {
			background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
			color: ${ThemeFrameworkCommon.browserDefaultColorDark};

			.ant-calendar-range-picker-separator {
				margin: 5px;
			}

			&:focus {
				box-shadow: none;
			}

			input::placeholder {
				color: ${ThemeProject.gray300};
			}
		}

		input {
			border: none;
			width: 40%;
			font-size: 0.8rem;
			color: ${ThemeFrameworkCommon.browserDefaultColorDark};
		}

		.ant-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
			border: none;
			box-shadow: none;
		}

		svg {
			fill: ${ThemeFrameworkCommon.browserDefaultColorDark}80;
		}
	}
`
