import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { TableMedicalReportCounterICP } from 'app/components/report-counter-indicator/inner/TableMedicalReportCounterICP'
import { useAppSelector } from 'app/redux/hook'
import { RoutingHelper } from 'config/RoutingHelper'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import * as _ from 'lodash-es'
import { CommonPermissionUtils } from 'modules/auth/permissions/CommonPermissionUtils'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { AuthUserResponseDTO } from 'submodules/beerads-sdk/services/auth/dtos/responses/inner/AuthUserResponseDTO'
import { DashboardRequests } from 'submodules/beerads-sdk/services/dashboard/dashboard/DashboardRequests'
import { CountMedicalReportsDoneByClinicRequestDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/request/CountMedicalReportsDoneByClinicRequestDTO'
import { CountMedicalReportsDoneByClinicResponseDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/response/CountMedicalReportsDoneByClinicResponseDTO'
import { ExamAssignedFilterEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamAssignedFilterEnum'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { RoundedWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/RoundedWrapperCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'

export const MEDICAL_REPORT_COUNTER_PARAM = 'medical-report-counter'

/**
 * Indicador de numero diário de laudos
 */
export function MedicalReportCounterCP(): JSX.Element {
	const loggedUser = useAppSelector((selector) => selector.auth.user) as AuthUserResponseDTO
	const currentDoctorGroup = useAppSelector((selector) => selector.doctorGroup)

	const [totalMedicalReportsByClinic, setTotalMedicalReportsByClinic] = useState<CountMedicalReportsDoneByClinicResponseDTO[]>([])
	const countMedicalReportsDoneRequest = useRequest<ListResponseDTO<CountMedicalReportsDoneByClinicResponseDTO>>()
	useEffect(onCountMedicalReportsDoneRequestChange, [countMedicalReportsDoneRequest.isAwaiting])

	const routeLocation = useLocation()
	const params = new URLSearchParams(routeLocation.search)
	useEffect(init, [params.get(MEDICAL_REPORT_COUNTER_PARAM)])

	/**
	 */
	function init(): void {
		if (!CommonPermissionUtils.canAccessByType(UserTypeEnum.DOCTOR, loggedUser)) return

		const dto: CountMedicalReportsDoneByClinicRequestDTO = {
			dateRange: { beginDate: new Date(), endDate: new Date() },
			doctorCode: loggedUser.code,
			doctorGroupCode: currentDoctorGroup.code ?? undefined,
		}
		countMedicalReportsDoneRequest.runRequest(DashboardRequests.countMedicalReportsDoneByClinic(dto))
	}

	/**
	 */
	function onCountMedicalReportsDoneRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(countMedicalReportsDoneRequest)) return

		setTotalMedicalReportsByClinic(countMedicalReportsDoneRequest.responseData!.list)
	}

	return (
		<AccessControlCP permission={{ byUserType: UserTypeEnum.DOCTOR }}>
			<PopOverCP
				title={'Produtividade do dia'}
				content={
					<>
						<TableMedicalReportCounterICP data={totalMedicalReportsByClinic} loading={countMedicalReportsDoneRequest.isAwaiting} />
						<FlexCP justify={'space-between'} margin={{ top: 20 }}>
							<ButtonCP size={'small'} onClick={init} loading={countMedicalReportsDoneRequest.isAwaiting} icon={'reload'}>
								Atualizar
							</ButtonCP>
							<ButtonCP
								size={'small'}
								onClick={() =>
									RoutingHelper.goToExams(undefined, {
										assigned: ExamAssignedFilterEnum.YES,
										viewAsDoctor: true,
										shouldConsiderMedicalReportDate: true,
										dateRange: { beginDate: DateUtils.getDate(), endDate: DateUtils.getDate() },
									})
								}
								icon={'audit'}
								type={'primary'}
							>
								Ver laudos
							</ButtonCP>
						</FlexCP>
					</>
				}
				trigger={'click'}
				placement={'right'}
			>
				<WrapperContentSCP>
					<RoundedWrapperCP
						color={`${ThemeProject.secondary}`}
						size={32}
						hideMarginRight={true}
						loading={countMedicalReportsDoneRequest.isAwaiting}
						text={<TextCP text={_.sumBy(totalMedicalReportsByClinic, 'totalMedicalReportDone')} size={'small'} strong={true} />}
					/>
					<TextCP wrap={true} strong={true} color={`${ThemeFrameworkCommon.white}`} size={'extraSmall'} text={'Laudos'} marginTop={10} />
				</WrapperContentSCP>
			</PopOverCP>
		</AccessControlCP>
	)
}

const WrapperContentSCP = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-bottom: 15px;
`
