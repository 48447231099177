/**
 * Enum de ambientes do sistema
 */
export enum EnvironmentEnum {
	DEV = 'DEV',
	TEST = 'TEST',
	PRD = 'PRD',
	HOM = 'HOM',
}

export enum FrontEnvironmentEnum {
	DEVELOPMENT = 'development',
	PRODUCTION = 'production',
	HOMOLOGATION = 'homologation',
	BETA = 'beta',
}
