import { StringUtils } from '@/submodules/nerit-framework-utils/utils/StringUtils'
import { useCurrentEditor } from '@tiptap/react'
import { VxWindMicIconCP } from 'app/components/vx-wind/vx-wind-icon/VxWindMicIconCP'
import { useSpeechToText } from 'modules/exams/components/medical-report/editor-medical-report/hooks/useSpeechToText'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTipTapContext } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/context/useTipTapContext'
import { MathUtils } from 'submodules/nerit-framework-utils/utils/MathUtils'

const MIN_EDITOR_OFFSET = 226 // 226px
const CURSOR_OFFSET = 8 // 8px

export function TipTapSpeechToTextCP(): ReactElement {
	const { editor } = useCurrentEditor()
	const [textFromSpeech, setTextFromSpeech] = useState('')
	const previousText = useRef('')
	const selectionFrom = useRef<number>()
	const [micButtonPosition, setMicButtonPosition] = useState(0)
	const { stream, websocket, isCapturing, onChangeCapturing } = useTipTapContext()

	function onChangeTextFromSpeech(value: string): void {
		setTextFromSpeech(value)
	}

	useEffect(
		function onStartCapturing() {
			if (!editor) return

			if (stream.current) {
				editor.commands.deleteSelection()

				const { from } = editor.state.selection
				selectionFrom.current = from
			}
		},
		[stream.current],
	)

	useEffect(
		function writeTextFromSpeech() {
			if (!editor) return
			const maxLength = editor.$doc.size - 1

			if (textFromSpeech !== previousText.current && selectionFrom.current) {
				const from = selectionFrom.current
				const newText = StringUtils.parseEditorText(textFromSpeech)
				const previousTextInEditor = StringUtils.parseEditorText(previousText.current)

				// A tag <br> deve ser substituída por . para que os 4 carácteres da tag
				// não conflite com a quebra de linha de texto no editor do TipTap. Logo esse
				// replaceAll garante que a seleção de texto para atualização esteja correta.
				const to = from + previousTextInEditor.replaceAll('<br>', '.').length

				editor
					.chain()
					.deleteRange({ from, to: MathUtils.clampValue(to, 0, maxLength) })
					.insertContentAt(from, newText)
					.run()

				previousText.current = textFromSpeech
			}
		},
		[textFromSpeech],
	)

	useEffect(
		function cleanUpCapturing() {
			if (!isCapturing && textFromSpeech) {
				previousText.current = ''
				setTextFromSpeech('')
			}
		},
		[isCapturing],
	)

	useEffect(
		function updateMicCursorPosition() {
			if (!editor) return

			let bottom = 0

			const { from } = editor.state.selection
			const view = editor.view

			const coords = view.coordsAtPos(from)
			const editorY = view.dom.getBoundingClientRect().bottom

			bottom = MathUtils.clampValue(coords.bottom, MIN_EDITOR_OFFSET, editorY - CURSOR_OFFSET)

			setMicButtonPosition(editorY - bottom + CURSOR_OFFSET)
		},
		[editor?.state.selection.from],
	)

	const { onTrigger } = useSpeechToText({
		stream,
		websocket,
		isCapturing,
		onChangeCapturing,
		onChangeTextData: onChangeTextFromSpeech,
	})

	return (
		<button
			type="button"
			onClick={onTrigger}
			className="absolute -left-11 z-[1] cursor-pointer rounded-lg border-none bg-white px-2 py-1 shadow-md before:absolute before:-right-1 before:bottom-1 before:z-[2] before:size-4 before:-rotate-45 before:rounded-b-sm before:bg-white before:content-[''] dark:bg-[#1E2122] before:dark:bg-[#1E2122]"
			style={{
				bottom: `${micButtonPosition}px`,
			}}
		>
			<VxWindMicIconCP isCapturing={isCapturing} />
		</button>
	)
}
