import React, { ReactNode, useEffect, useState } from 'react'
import { TooltipPlacementTP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import * as S from './PopOverStyles'

interface IPopOverCPProps {
	children: React.ReactNode
	content?: JSX.Element | JSX.Element[] | false
	title?: ReactNode
	visible?: boolean
	trigger?: 'hover' | 'click' | 'focus'
	placement?: TooltipPlacementTP
	onClose?: () => void
	destroyOnHide?: boolean
	className?: string
}

/**
 * Exibe modal posicionado rente ao conteudo envolto pelo componente.
 */
export function PopOverCP(props: IPopOverCPProps): JSX.Element {
	const [isVisible, setIsVisible] = useState<boolean>(false)

	useEffect(onVisiblePropChange, [props.visible])

	function onVisiblePropChange(): void {
		if (props.visible !== undefined) setIsVisible(props.visible)
	}

	function onVisibilityStateChange(_visible: boolean): void {
		setIsVisible(_visible)
		if (!_visible && !!props.onClose) props.onClose()
	}

	if (props.trigger === 'hover') {
		return (
			<S.CustomPopOver content={props.content} title={props.title} placement={props.placement ?? 'top'}>
				<div>{props.children}</div>
			</S.CustomPopOver>
		)
	}

	return (
		<S.CustomPopOver
			content={props.content}
			title={typeof props.title === 'string' ? <S.PopOverTitle>{props.title}</S.PopOverTitle> : props.title}
			open={isVisible}
			trigger={props.trigger}
			placement={props.placement ?? 'top'}
			onOpenChange={onVisibilityStateChange}
			destroyTooltipOnHide={props.destroyOnHide}
			className={props.className}
		>
			{props.children}
		</S.CustomPopOver>
	)
}
