import { ThemeProject } from 'config/theme/project/ThemeProject'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { LogoCP } from 'submodules/nerit-framework-ui/common/components/logo/LogoCP'

interface ICPProps {}

/**
 * Rodape do PDF
 */
export function PatientPortalDataPdfFooterICP(props: ICPProps): JSX.Element {
	return (
		<FlexCP margin={{ top: 30 }} alignItems={'center'} justify={'center'}>
			<i>Powered by</i>
			<LogoCP height={30} marginLeft={10} marginTop={0} marginBottom={0} imgUrl={ThemeProject.logoLoginUrl} />
		</FlexCP>
	)
}
