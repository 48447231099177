import { Flex, Rate } from 'antd'
import { ThemeProjectVX } from 'config/theme/project/white-labels/ThemeProjectVX'
import React, { useState } from 'react'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'

interface ICPProps {
	defaultValue?: number
	showTooltips?: boolean
	rateOptions?: () => Record<number, React.ReactNode>
	value?: (value: number) => void
}

const description = ['Muito ruim', 'Ruim', 'Bom', 'Muito bom', 'Ótimo']

export function RateCP({ defaultValue, showTooltips, rateOptions, value }: ICPProps): JSX.Element {
	const [internalValue, setInternalValue] = useState<number>()

	const handleChange = (newValue: number): void => {
		const adjustedValue = newValue === 0 ? 1 : newValue // Garantir que o valor não seja 0
		setInternalValue(adjustedValue)

		if (value) {
			value(adjustedValue)
		}
	}

	return (
		<Flex gap="middle" vertical>
			<TextCP text={'Avaliar:'} style={{ fontWeight: 500 }} />
			<FlexCP justify={'center'} gap={'16px'}>
				<Rate
					className="[&.ant-rate_.ant-rate-star-zero_.ant-rate-star-first]:text-gray-600 [&.ant-rate_.ant-rate-star-zero_.ant-rate-star-second]:text-gray-600"
					defaultValue={defaultValue ?? 0}
					character={rateOptions ? ({ index = 0 }) => rateOptions[index + 1] : undefined}
					tooltips={showTooltips ? description : undefined}
					onChange={(value) => handleChange(value)}
					value={internalValue}
				/>
				{internalValue !== undefined && <TextCP text={`${internalValue}/5 estrelas`} style={{ fontWeight: 500 }} color={ThemeProjectVX.gray500} />}
			</FlexCP>
		</Flex>
	)
}
