import { AvatarMultipleExamModalityCP } from 'modules/exams/components/exams/avatar-multiple-exam-modality/AvatarMultipleExamModalityCP'
import { TagsExamModalitiesCP } from 'modules/exams/components/exams/tags-exam-modalities/TagsExamModalitiesCP'
import { ButtonEditMedicalReportTemplateCP } from 'modules/template/components/button-edit-medical-report-template/ButtonEditMedicalReportTemplateCP'
import { TagsTemplateStatusCP } from 'modules/template/components/tags-template-status/TagsTemplateStatusCP'
import { TemplateHistoryStatusEnum } from 'submodules/beerads-sdk/services/templates/history-templates/enums/TemplateHistoryStatusEnum'
import { TemplatesRequests } from 'submodules/beerads-sdk/services/templates/templates/TemplatesRequests'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { ButtonDeleteRecordCP } from 'submodules/nerit-framework-ui/common/components/table/columns/ButtonDeleteRecordCP'
import { ITableColumn } from 'submodules/nerit-framework-ui/common/components/table/types/ITableColumn'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'

export const TableTemplateUtils = {
	getColumns(
		filterType: TemplateTypeEnum,
		onChangeAnyDataOnTable: () => void,
		doctorGroupCode?: number,
		showDeleteButton?: boolean,
		showEditButton?: boolean,
		clinicCode?: number,
		onSave?: () => void,
		onDelete?: () => void,
	): Array<ITableColumn<TemplateResponseDTO>> {
		return [
			{
				title: 'Nome / Modalidade(s)',
				hide: filterType === TemplateTypeEnum.TEMPLATE,
				render: (_, record) => (
					<>
						<TextCP size={'small'} text={record.name} />
						<TagsExamModalitiesCP modalities={record.modalities} />
					</>
				),
			},
			{
				hide: filterType === TemplateTypeEnum.PHRASE,
				render: (_, record) => {
					if (!record.modalities) {
						console.log(record.modalities)
					}
					return <AvatarMultipleExamModalityCP modalities={record.modalities} />
				},
			},
			{
				title: 'Nome',
				hide: filterType === TemplateTypeEnum.PHRASE,
				render: (_, record) => <TextCP size={'small'} text={record.name} />,
			},
			{
				title: 'Exame',
				hide: filterType === TemplateTypeEnum.PHRASE,
				render: (_, record) => <TextCP size={'small'} text={record.examDescription ?? '-'} />,
			},
			{
				title: 'Status',
				hide: filterType === TemplateTypeEnum.PHRASE,
				render: (_, record) => (
					<TagsTemplateStatusCP status={record.contentHtml ? TemplateHistoryStatusEnum.ACTIVE : TemplateHistoryStatusEnum.AWAITING_APPROVAL} />
				),
			},
			{
				render: (_, record) => (
					<ButtonGroupCP mode={'separeted'}>
						{showEditButton && (
							<TooltipCP
								trigger="hover"
								text="Esse template não pode ser editado pois está aguardando aprovação"
								showSpan={!record.contentHtml}
								children={
									<ButtonEditMedicalReportTemplateCP
										template={record}
										onSave={onSave ? onSave : () => {}}
										clinicCode={clinicCode}
										doctorGroupCode={doctorGroupCode}
										disabled={!record.contentHtml}
									/>
								}
							/>
						)}
						{!!showDeleteButton && (
							<TooltipCP
								trigger="hover"
								text="Esse template não pode ser deletado pois está aguardando aprovação"
								showSpan={!record.contentHtml}
								children={
									<ButtonDeleteRecordCP
										requestConfigTP={() => TemplatesRequests.delete(record.code)}
										onDelete={() => {
											if (!!onDelete) {
												onDelete()
											}
											onChangeAnyDataOnTable()
										}}
										disabled={!record.contentHtml}
									/>
								}
							/>
						)}
					</ButtonGroupCP>
				),
			},
		]
	},
}
