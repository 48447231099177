import classNames from 'classnames'
import { ElementType } from 'react'

export type ToolbarTipTapButtonTP = {
	onClick: () => void
	Icon: ElementType
	disabled?: boolean
	className?: string
}

export function ToolbarTipTapButtonICP({ onClick, Icon, disabled = false, className: className = '' }: ToolbarTipTapButtonTP): JSX.Element {
	return (
		<div className={classNames('icon', className, { disabled })} onClick={onClick}>
			<Icon />
		</div>
	)
}
