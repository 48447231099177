import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { WidgetsRequests } from 'submodules/beerads-sdk/services/dashboard/widgets/WidgetsRequests'
import { AuditSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/audits/dtos/request/AuditSearchRequestDTO'
import { AuditStatusEnum } from 'submodules/beerads-sdk/services/exams/audits/enums/AuditStatusEnum'
import { CardWidgetOnlyValuesShouldLoadCP } from 'submodules/nerit-framework-ui/common/components/dashboard/card-widget-only-values-should-load/CardWidgetOnlyValuesShouldLoadCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'

interface ICPProps {
	doctorGroupCode?: number
	shouldLoad: number
	filters: AuditSearchRequestDTO
}

/**
 */
export function WidgetCountAuditsOpenCP(props: ICPProps): JSX.Element {
	return (
		<CardWidgetOnlyValuesShouldLoadCP
			onClick={!!props.filters.doctorAuditorCode ? () => NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.DOCTOR_AUDITS) : undefined}
			main={{
				title: 'Auditorias em aberto neste momento',
				help: !!props.filters.doctorAuditorCode
					? 'Número total de auditorias aguardando o seu parecer'
					: 'Número total de auditorias já designadas para os auditores, ou seja, auditorias que estão em aberto',
				isZeroTarget: true,
			}}
			requestConfigGetter={WidgetsRequests.countAudits({
				...props.filters,
				statuses: [AuditStatusEnum.AWAITING, AuditStatusEnum.IN_PROGRESS],
				dateRange: undefined,
			})}
			shouldLoad={props.shouldLoad}
		/>
	)
}
