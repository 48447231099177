import styled from 'styled-components'
import { AvatarCP } from 'submodules/nerit-framework-ui/common/components/avatar/AvatarCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextAndTitleCP } from 'submodules/nerit-framework-ui/common/components/text/TextAndTitleCP'
import { ProfileResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ProfileResponseDTO'

interface IAvatarMultiUserCPProps {
	users: ProfileResponseDTO[]
	titleTooltip: string
}

/**
 * COMPONENTE avatares dos usuários.
 */
export function AvatarMultiUserCP(props: IAvatarMultiUserCPProps): JSX.Element {
	return (
		<FlexCP>
			{props.users.map((user) => (
				<AvatarSCP key={`user-${user.code}`}>
					<AvatarCP
						size={'small'}
						name={user.name}
						src={user.imageUrl}
						textTooltip={<TextAndTitleCP title={props.titleTooltip} text={user.name} margin={{ bottom: 0 }} />}
					/>
				</AvatarSCP>
			))}
		</FlexCP>
	)
}

const AvatarSCP = styled.div`
	transition: left 0.3s;
	position: relative;

	&:not(:first-child) {
		left: -10px;
	}

	&:not(:first-child):not(:nth-child(2)) {
		margin-left: -10px;
	}

	&:hover ~ div {
		left: 0px;
		margin-left: 0px;
	}
`
