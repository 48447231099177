import Editor, { OnMount } from '@monaco-editor/react'
import React, { ChangeEvent } from 'react'

interface ICPProps {
	height?: string
	value?: string
	onChange: (content: string, event: ChangeEvent) => void
	onEditorMount?: OnMount
	mode?: 'html' | 'javascript' // Obs: Existem outros tipos na documentação do monaco editor, caso seja necessário implementar tem que fazer o import também;
}

export function EditorCodeCP(props: ICPProps): React.ReactElement {
	function handleChange(newValue: string | undefined, event: unknown): void {
		if (!newValue) {
			return
		}

		props.onChange(newValue, event as ChangeEvent)
	}

	return (
		<Editor
			language={props.mode}
			onChange={handleChange}
			onMount={props.onEditorMount}
			height={props.height}
			width={'100%'}
			value={props.value ?? ''}
			options={{
				theme: 'tomorrow',
				tabSize: 4,
				minimap: {
					enabled: false,
				},
			}}
		/>
	)
}
