export enum DoctorRoleInDoctorGroupEnum {
	MANAGER = 'MANAGER',
	COORDINATOR = 'COORDINATOR',
	ASSISTANT = 'ASSISTANT',
}

export enum DoctorRoleInDoctorGroupLabelEnum {
	MANAGER = 'Gerente',
	COORDINATOR = 'Coordenador',
	ASSISTANT = 'Assistente',
}
