import React from 'react'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { ExamUrgencyTypeEnum, ExamUrgencyTypeLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'

interface ICPProps<FModelTP extends FormModel = any> {
	label?: string
	fieldName?: keyof FModelTP
	formStateManager?: IFormStateManager<FModelTP>
	value?: ExamUrgencyTypeEnum[]
	onChange?: (selected: ExamUrgencyTypeEnum[]) => void
	required?: boolean
	isMultiple?: boolean
}

/**
 * Select de urgencias
 */
export function SelectExamUrgencyTypeCP<FModelTP extends FormModel = any>(props: ICPProps): JSX.Element {
	return (
		<SelectCP
			label={props.label ?? 'Escolha as Urgências que serão trabalhadas'}
			fieldName={props.fieldName}
			isMultiple={props.isMultiple ?? true}
			formStateManager={props.formStateManager}
			required={props.required}
			value={props.value}
			onChange={props.onChange}
			options={Object.values(ExamUrgencyTypeEnum).map((urgency) => ({
				value: urgency,
				label: ExamUrgencyTypeLabelEnum[urgency],
			}))}
		/>
	)
}
