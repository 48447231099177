import React from 'react'
import { MedicalTimetableAbsenceFormModel } from 'modules/medical-timetable/components/modals/modal-medical-timetable-absence/inner/MedicalTimetableAbsenceFormModel'
import { TabContentAddAbsenceICP } from 'modules/medical-timetable/components/tabs-medical-timetable-absence/tabs/TabContentAddAbsenceICP'
import { TabContentRemoveScheduleICP } from 'modules/medical-timetable/components/tabs-medical-timetable-absence/tabs/TabContentRemoveScheduleICP'
import { TimelineDoctorMedicalTimetableAbsenceCP } from 'modules/medical-timetable/components/timeline-doctor-medical-timetable-absence/TimelineDoctorMedicalTimetableAbsenceCP'
import { TabsCP } from 'submodules/nerit-framework-ui/common/components/tabs/TabsCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { isUserAddingAbsenceOnMedicalTimetableTP } from 'modules/medical-timetable/components/modals/modal-medical-timetable-absence/ModalMedicalTimetableAbsenceCP'

interface ICPProps {
	formStateManager: IFormStateManager<MedicalTimetableAbsenceFormModel>
	doctorGroupCode: number
	onLoading?: boolean
	isUserAddingAbsenceOnMedicalTimetable?: isUserAddingAbsenceOnMedicalTimetableTP
	onClose: () => void
	onSave: () => void
	onCreateAbsence: () => void
}

export function TabsMedicalTimetableAbsenceCP(props: ICPProps): JSX.Element {
	//Verifica se o usuário está adicionando a ausência direto na escala, se sim = true
	const isUserAddingAbsenceOnMedicalTimetable =
		!!props.isUserAddingAbsenceOnMedicalTimetable && Object.keys(props.isUserAddingAbsenceOnMedicalTimetable).length > 0

	return (
		<TabsCP
			defaultActiveKey={isUserAddingAbsenceOnMedicalTimetable ? 'removeSchedule' : 'addAbsence'}
			tabs={[
				{
					key: 'removeSchedule',
					title: 'Remover da escala',
					content: (
						<TabContentRemoveScheduleICP
							formStateManager={props.formStateManager}
							doctorGroupCode={props.doctorGroupCode}
							onClose={props.onClose}
							onSave={props.onSave}
							onCreateAbsence={props.onCreateAbsence}
							isUserAddingAbsenceOnMedicalTimetable={props.isUserAddingAbsenceOnMedicalTimetable!}
						/>
					),
					hide: !isUserAddingAbsenceOnMedicalTimetable,
				},
				{
					key: 'addAbsence',
					title: 'Adicionar ausência',
					content: (
						<TabContentAddAbsenceICP
							formStateManager={props.formStateManager}
							onLoading={props.onLoading}
							isUserAddingAbsenceOnMedicalTimetable={isUserAddingAbsenceOnMedicalTimetable}
							onClose={props.onClose}
							onCreateAbsence={props.onCreateAbsence}
						/>
					),
					hide: isUserAddingAbsenceOnMedicalTimetable,
				},
				{
					key: 'seeAbsence',
					title: 'Ver ausências',
					content: (
						<TimelineDoctorMedicalTimetableAbsenceCP
							doctorCode={props.formStateManager.getFieldValue('doctorCode')}
							doctorGroupCode={props.doctorGroupCode}
							onSave={props.onSave}
						/>
					),
					hide: isUserAddingAbsenceOnMedicalTimetable,
				},
			]}
		/>
	)
}
