import { ModalCompareTemplateCP } from 'modules/template/components/modal-compare-template/ModalCompareTemplateCP'
import { useState } from 'react'
import { TemplateHistoryResponseDTO } from 'submodules/beerads-sdk/services/templates/history-templates/dtos/response/TemplateHistoryResponseDTO'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'

interface ICPProps {
	templateHistory: TemplateHistoryResponseDTO
	currentTemplate: TemplateResponseDTO
	onChangeDataOnTable: () => void
}

export function ButtonModalCompareMedicalReportTemplateCP(props: ICPProps): JSX.Element {
	const [isModalVisible, setIsModalVisible] = useState(false)

	return (
		<>
			<ButtonCP size={'small'} icon={'eye'} onClick={() => setIsModalVisible(true)} />
			{isModalVisible && (
				<ModalCompareTemplateCP
					onClose={() => setIsModalVisible(false)}
					templateHistory={props.templateHistory}
					currentTemplate={props.currentTemplate}
					onSave={props.onChangeDataOnTable}
				/>
			)}
		</>
	)
}
