import styled from 'styled-components'

export const Wrapper = styled.div`
	padding: 8px;
	width: -webkit-fill-available;
`
export const ContentWrapper = styled.div`
	font-weight: 500;
`
export const HoursWrapper = styled.p`
	display: flex;
	align-items: center;
	font-weight: 600;
	svg {
		width: 15px;
		height: 15px;
	}
`
export const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 15px;
	gap: 2px;
`
export const CommentButtonWrapper = styled.div`
	button {
		cursor: help;
	}
`

export const AbsenceButtonWrapper = styled.div`
	svg {
		fill: #a31621;
	}
`

export const ValueWapper = styled.i`
	color: #205a2f;
	font-weight: 500;
`
