import { AvatarMultiUserCP } from '@/submodules/nerit-framework-ui/common/components/avatar-multi-user/AvatarMultUserCP'
import { ExamListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'

interface ICPProps {
	record: ExamListItemResponseDTO
}

export function ColumnDoctorAvatarICP(props: ICPProps): JSX.Element {
	return (
		<FlexCP>
			{!!props.record.doctor && (
				<AvatarMultiUserCP
					users={!!props.record.revisionDoctor ? [props.record.doctor, props.record.revisionDoctor] : [props.record.doctor]}
					titleTooltip={'Médico(s) do Laudo'}
				/>
			)}
			{!ArrayUtils.isEmpty(props.record.doctorsAssigned) &&
				[ExamStatusEnum.AWAITING, ExamStatusEnum.SIGNED, ExamStatusEnum.SIGNED_RECTIFICATION, ExamStatusEnum.RECTIFICATION].includes(
					props.record.status,
				) && <AvatarMultiUserCP users={props.record.doctorsAssigned!} titleTooltip={'Médico(s) Atribuído(s)'} />}
		</FlexCP>
	)
}
