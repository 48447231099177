import { RequestHelper } from '@/submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'
import { AuthUtils } from '@/submodules/nerit-framework-utils/sdk-utils/request-manager/utils/AuthUtils'
import { useAppDispatch, useAppSelector } from 'app/redux/hook'
import { doctorGroupActions } from 'app/redux/slices/doctor-group/DoctorGroupSlice'
import { SelectDoctorGroupFromLoggedUserCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-groups/SelectDoctorGroupFromLoggedUserCP'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DoctorGroupNamesResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/responses/DoctorGroupNamesResponseDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'

interface ICPProps {
	disabled?: boolean
	onChange: () => void
}

export function SelectCurrentDoctorGroupCP(props: ICPProps): JSX.Element {
	const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false)
	const currentDoctorGroup = useAppSelector((selector) => selector.doctorGroup)
	const loggedUser = useAppSelector((selector) => selector.auth.user)
	const dispatch = useAppDispatch()
	const isEnabledClearDoctorGroup = loggedUser?.doctorGroups?.length !== 1

	useEffect(init, [])

	useEffect(
		function syncCodeBehalfToReqHeader() {
			if (currentDoctorGroup.code) {
				RequestHelper.addDefaultHeaderConfig(AuthUtils.getCodeBehalfTo(currentDoctorGroup.code))
			}
		},
		[currentDoctorGroup.code],
	)

	function init(): void {
		if (loggedUser?.doctorGroups?.length === 1) dispatch(doctorGroupActions.setCurrentDoctorGroup(loggedUser.doctorGroups[0]))
	}

	function onSelectDoctorGroup(doctorGroupCode: number): void {
		let foundDoctorGroup: DoctorGroupNamesResponseDTO | undefined

		if (!!doctorGroupCode) {
			foundDoctorGroup = loggedUser?.doctorGroups?.find((doctorGroup) => doctorGroup.code === doctorGroupCode)
		}

		if (!!foundDoctorGroup) {
			dispatch(doctorGroupActions.setCurrentDoctorGroup(foundDoctorGroup))
		} else {
			dispatch(doctorGroupActions.setCurrentDoctorGroup(null))
		}

		setIsPopoverVisible(false)
		props.onChange()
	}

	if (ArrayUtils.isEmpty(loggedUser?.doctorGroups)) return <></>

	return (
		<ContentWrapperSCP>
			<PopOverCP
				trigger={'click'}
				placement={'right'}
				visible={isPopoverVisible}
				onClose={() => setIsPopoverVisible(false)}
				title={'Alterar grupo de médico'}
				content={
					<>
						<ContainerWrapperSCP>
							<SelectDoctorGroupFromLoggedUserCP
								allowClear={isEnabledClearDoctorGroup}
								value={currentDoctorGroup?.code ?? undefined}
								onChange={onSelectDoctorGroup}
								width={300}
							/>
						</ContainerWrapperSCP>
					</>
				}
			>
				<ButtonCP
					tooltip={
						!!currentDoctorGroup.name
							? `Você está logado no grupo: ${currentDoctorGroup.name}`
							: 'Nenhum grupo selecionado. Você irá visualizar exames de todos os grupos'
					}
					tooltipPlacement={'topLeft'}
					color={ThemeFrameworkCommon.white}
					onClick={() => setIsPopoverVisible(true)}
					type={'link'}
				>
					{!currentDoctorGroup?.name
						? '...'
						: currentDoctorGroup.name.length <= 3
							? currentDoctorGroup.name
							: currentDoctorGroup.name.slice(0, 3)}
				</ButtonCP>
			</PopOverCP>
		</ContentWrapperSCP>
	)
}

const ContentWrapperSCP = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 10px;

	button {
		font-weight: bold;
	}
`

const ContainerWrapperSCP = styled.div`
	padding-top: 0.75rem;
	.ant-form-explain {
		display: none;
	}
`
