import styled from 'styled-components'
import { ConditionalRenderCP } from 'submodules/nerit-framework-ui/common/components/conditional-render/ConditionalRenderCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { HelpIconCP } from 'submodules/nerit-framework-ui/common/components/icon/icons/HelpIconCP'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'

interface IScpProps {
	marginRight?: number
	marginLeft?: number
	marginTop?: number
	marginBottom?: number
}

interface ITooltipHelpCPProps extends IScpProps {
	text: string | JSX.Element
	type: 'text' | 'tooltip'
	showOnlyIcon?: boolean
	align?: 'center' | 'flex-end' | 'space-between' | 'flex-start'
	label?: string
}

/**
 * COMPONENTE de tooltip de ajuda
 */
export function HelpCP(props: ITooltipHelpCPProps): JSX.Element {
	return (
		<WrapperSCP
			marginTop={props.marginTop ?? 5}
			marginRight={props.marginRight ?? 0}
			marginBottom={props.marginBottom ?? 5}
			marginLeft={props.marginLeft ?? 0}
		>
			{props.type === 'tooltip' ? (
				<FlexCP alignItems={'center'} justify={props.align ?? 'flex-end'}>
					<TooltipCP showSpan={true} text={props.text}>
						<FlexCP alignItems={'center'}>
							<ConditionalRenderCP shouldRender={!props.showOnlyIcon}>{props.label ?? 'Entenda'}</ConditionalRenderCP>&nbsp;
							<HelpIconCP size={11} />
						</FlexCP>
					</TooltipCP>
				</FlexCP>
			) : (
				props.text
			)}
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div<IScpProps>`
	transition: opacity 0.3s;
	font-size: 12px;
	font-style: italic;
	margin: ${(props) => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};

	i {
		margin-left: 5px;
	}
`
