import { useAppSelector } from 'app/redux/hook'
import { ChartExamsCP } from 'modules/reports/components/chart-exams/ChartExamsCP'
import { CommonReportsFilterFormModel } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModel'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { ReportTypeEnum } from 'submodules/beerads-sdk/services/dashboard/dashboard/types/ReportTypeEnum'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'

export function DoctorProductivityChartCP(): JSX.Element {
	const [formValidator, setFormValidator] = useState<CommonReportsFilterFormModel>()
	const formStateManager = useFormStateManager<CommonReportsFilterFormModel>(formValidator)

	const currentDoctorGroup = useAppSelector((state) => state.doctorGroup)

	const [shouldLoad, setShouldLoad] = useState<number>(0)

	useEffect(init, [])

	/**
	 */
	function init(): void {
		setFormValidator(
			new CommonReportsFilterFormModel({
				dateRange: {
					beginDate: moment().startOf('M').toDate(),
					endDate: new Date(),
				},
				shouldConsiderMedicalReportDate: true,
				doctorGroupCode: currentDoctorGroup.code ?? undefined,
			}),
		)
		setShouldLoad(TableUtils.getReloadNumber())
	}

	return !formStateManager.getFormValues() ? (
		<LoadingOverlayCP show />
	) : (
		<ChartExamsCP reportType={ReportTypeEnum.DOCTOR_REPORT} filterStateManager={formStateManager} reload={shouldLoad} cardMargin="20px 0 20px 0" />
	)
}
