import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import {
	CreateReportPhysicianRequestDTO,
	CreateReportPhysicianResponseDTO,
	ExtractPathologyFromReportRequestDTO,
	ExtractPathologyFromReportResponseDTO,
} from 'submodules/beerads-sdk/services/vx-wind/dtos'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'

export class VxWindRequests {
	static CONTROLLER_ROOT = 'vx-wind'
	static REPORT_PHYSICIAN_EP = 'report-physician'
	static EXTRACT_PATHOLOGY_FROM_REPORT_PHYSICIAN_EP = 'pathology-extractor'

	static createReportPhysician = (dto: CreateReportPhysicianRequestDTO): RequestConfigTP<CreateReportPhysicianResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${VxWindRequests.CONTROLLER_ROOT}/${VxWindRequests.REPORT_PHYSICIAN_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static extractPathologyFromReport = (dto: ExtractPathologyFromReportRequestDTO): RequestConfigTP<ExtractPathologyFromReportResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${VxWindRequests.CONTROLLER_ROOT}/${VxWindRequests.EXTRACT_PATHOLOGY_FROM_REPORT_PHYSICIAN_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})
}
