import { MaskUtils } from '@/submodules/nerit-framework-ui/common/utils/MaskUtils'
import styled from 'styled-components'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'

interface ICProps {
	title: string
	value: number
	help?: string
	valueMask?: InputMaskTypeEnum.MONEY | string
}

export function CardWidgetFooterOnlyValueICP(props: ICProps): JSX.Element {
	function formatValue(value: number): string {
		if (props?.valueMask === InputMaskTypeEnum.MONEY) {
			return MaskUtils.applyMoneyMask(value)
		}

		if (props.valueMask) {
			const parsed = Number.isNaN(Number(value)) ? 0 : Number(value)

			return `${parsed.toFixed(2)}${props.valueMask}`
		}

		return `${value}`
	}

	return (
		<WrapperSCP>
			<FlexCP alignItems={'center'} justify={'space-between'}>
				<FlexCP>
					<TextCP text={props.title} />
					{!!props.help && <HelpCP showOnlyIcon={true} marginTop={0} marginBottom={0} marginLeft={10} text={props.help} type={'tooltip'} />}
				</FlexCP>
				<TextCP text={formatValue(props.value)} size={'large'} />
			</FlexCP>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	border-top: solid 1px #f1f1f1;
	padding: 10px;
`
