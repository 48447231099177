import { useEffect, useState } from 'react'
import { ExamAttachmentsRequests } from 'submodules/beerads-sdk/services/exams/attachments/ExamAttachmentsRequests'
import { NoteSaveRequestDTO } from 'submodules/beerads-sdk/services/exams/attachments/dtos/request/NoteSaveRequestDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

interface INewNoteICPProps {
	examCode: number
	onAddNote: () => void
}
/**
 * Componente de inserção de uma nova nota.
 */
export function FormExamNoteCP(props: INewNoteICPProps): JSX.Element {
	const [noteText, setNoteText] = useState<string>()

	const saveNoteRequest = useRequest<void>('none')
	useEffect(onSaveNoteRequestChange, [saveNoteRequest.isAwaiting])

	/**
	 * Salva a nota
	 */
	function saveNote(): void {
		if (StringUtils.isEmpty(noteText)) return NotificationHelper.error('Ops', 'O campo nota é obrigatório')

		const dto: NoteSaveRequestDTO = {
			examCode: props.examCode,
			noteText: noteText!,
		}
		saveNoteRequest.runRequest(ExamAttachmentsRequests.createNote(dto))
	}

	/**
	 * Retorno ao salvar
	 */
	function onSaveNoteRequestChange() {
		if (
			!RequestUtils.isValidRequestReturn(
				saveNoteRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onAddNote()
		setNoteText(undefined)
	}

	return (
		<>
			<TextAreaCP required={true} label={'Escreva aqui seus comentários'} maxRows={3} value={noteText} onChange={setNoteText} />

			<FlexCP justify={'flex-end'} margin={{ top: 20 }}>
				<ButtonCP type={'primary'} loading={saveNoteRequest.isAwaiting} onClick={saveNote}>
					Salvar Nota
				</ButtonCP>
			</FlexCP>
		</>
	)
}
