/**
 * UTILITARIOS: Para mansear objetos genericos.
 */
export const ObjectUtils = {
	/** Retorna copia de 01 objeto removendo 01 lista de propriedaades do mesmo. */
	getObjectsWithoutSomeProps<ChildPropsTP>(obj: any, propsToRemove: Array<keyof ChildPropsTP>): {} {
		const clearedObj = { ...obj }

		for (const propToRemove of propsToRemove) delete clearedObj[propToRemove]

		return clearedObj
	},

	trimAllAttributesFromObject(obj: any): void {
		Object.keys(obj).forEach((k) => (obj[k] = typeof obj[k] === 'string' ? (obj[k] as string).trim() : obj[k]))
	},

	sortObject<T = unknown>(obj: T): T {
		if (obj === null || typeof obj !== 'object') {
			return obj
		}

		if (Array.isArray(obj)) {
			return obj.map(this.sortObject) as unknown as T
		}

		const sortedKeys = Object.keys(obj).sort()
		const sortedObj = {} as T

		for (const key of sortedKeys) {
			sortedObj[key] = this.sortObject(obj[key])
		}

		return sortedObj
	},
}
