import styled from 'styled-components'
import { ButtonCP, IButtonCPProps } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import {
	PasswordDataTP,
	useFormSetPasswordContext,
} from 'submodules/nerit-framework-ui/common/components/form-set-password/inner/context/useFormSetPasswordContext'

export type ActionsPropsTP = {
	title: string
} & IButtonCPProps

interface ICPProps {
	cancelProps: ActionsPropsTP
	confirmProps: Omit<ActionsPropsTP, 'onClick'> & {
		onClick: (values: PasswordDataTP) => void
	}
}

export function FormSetPasswordActionsCP(props: ICPProps): JSX.Element {
	const { passwordData, passwordErrors } = useFormSetPasswordContext()

	const shouldDisableConfirmButton =
		props.confirmProps.disabled ?? (!passwordData.password || passwordData.password !== passwordData.confirmPassword || passwordErrors.length > 0)

	return (
		<>
			<ButtonWrapperSCP>
				<ButtonCP {...props.cancelProps}>{props.cancelProps.title}</ButtonCP>

				<ButtonCP
					type={props.confirmProps.type ?? 'primary'}
					disabled={shouldDisableConfirmButton}
					{...props.confirmProps}
					onClick={() => {
						if (props.confirmProps.onClick) {
							props.confirmProps.onClick({
								password: passwordData.password,
								confirmPassword: passwordData.confirmPassword,
								currentPassword: passwordData.currentPassword,
							})
						}
					}}
				>
					{props.confirmProps.title}
				</ButtonCP>
			</ButtonWrapperSCP>
		</>
	)
}

const ButtonWrapperSCP = styled.div`
	width: 100%;
	margin-top: 20px;
	padding: 10px 0;
	display: flex;
	justify-content: space-between;
`
