import { ButtonCreateMedicalReportTemplateCP } from 'modules/template/components/button-create-medical-report-template/ButtonCreateMedicalReportTemplateCP'
import { TabAnalysisTemplateUtils } from 'modules/template/components/tab-template/tab-analysis-template/TabAnalysisTemplateUtisl'
import { TabTemplateUtils } from 'modules/template/components/tab-template/tab-templates/TabTemplateUtils'
import { useState } from 'react'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { TabsCP } from 'submodules/nerit-framework-ui/common/components/tabs/TabsCP'

interface ICPProps {
	type: TemplateTypeEnum
	filters: {
		clinicCode?: number
		doctorGroupCode?: number
	}
}

/**
 * Lista de templates.
 */
export function TableTemplatesWithDataCP(props: ICPProps): JSX.Element {
	const [loadList, setLoadList] = useState<number>(1)

	return (
		<>
			<FlexCP justify={'flex-end'} margin={{ right: 20 }}>
				<ButtonCreateMedicalReportTemplateCP
					clinicCode={props.filters.clinicCode}
					doctorGroupCode={props.filters.doctorGroupCode}
					onSave={() => setLoadList(TableUtils.getReloadNumber())}
				/>
			</FlexCP>

			<TabsCP
				defaultActiveKey="templates"
				tabs={[
					TabTemplateUtils.getTabTemplates(props.type, props.filters, loadList, setLoadList),
					TabAnalysisTemplateUtils.getTab(loadList, setLoadList),
				]}
			/>
		</>
	)
}
