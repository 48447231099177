import { CardProfileChangeSignatureCP } from 'modules/person/components/doctor/card-profile-change-signature/CardProfileChangeSignatureCP'
import { CardProfileDoctorDataCP } from 'modules/person/components/doctor/card-profile-doctor-data/CardProfileDoctorDataCP'
import { PersonResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PersonResponseDTO'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { FlexOneICP } from 'submodules/nerit-framework-ui/common/components/flex/inner/FlexOneICP'

interface ICPProps {
	person: PersonResponseDTO
	onClickChangePassword: () => void
	onChangeData: () => void
}

/**
 * Perfil do Medico.
 */
export function ScreenContentProfileDoctor(props: ICPProps): JSX.Element {
	return (
		<>
			<CardProfileDoctorDataCP person={props.person} onClickChangePassword={props.onClickChangePassword} onChangeData={props.onChangeData} />

			{!!props.person.doctorData && (
				<FlexCP justify={'space-between'}>
					<FlexOneICP>
						<CardProfileChangeSignatureCP personCode={props.person.code} signatureUrl={props.person.doctorData?.signature} />
					</FlexOneICP>
				</FlexCP>
			)}
		</>
	)
}
