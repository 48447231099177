export enum MedicalTimetableIdEnum {
	URGENT = 'URGENT',
	GENERALIST = 'GENERALIST',
	ASSIGNMENTS = 'ASSIGNMENTS',
	SPECIALIST = 'SPECIALIST',
	ASSISTANT = 'ASSISTANT',
}

export enum MedicalTimetableIdLabelEnum {
	URGENT = 'Plantão',
	GENERALIST = 'Generalista',
	ASSIGNMENTS = 'Atribuições',
	SPECIALIST = 'Especialista',
	ASSISTANT = 'Assistente',
}
