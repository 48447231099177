import { useEffect, useState } from 'react'
import { FormSetPasswordCP } from 'submodules/nerit-framework-ui/common/components/form-set-password/FormSetPasswordCP'
import { PasswordDataTP } from 'submodules/nerit-framework-ui/common/components/form-set-password/inner/context/useFormSetPasswordContext'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { UserChangePasswordRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/features/user/dtos/request/UserChangePasswordRequestDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'

interface ICPProps {
	visible: boolean
	onClose: () => void
	userCode: number
	requestConfig: (dto: UserChangePasswordRequestDTO) => RequestConfigTP
}

/**
 * Modal de alteracao de senha.
 */
export function ModalChangePasswordCP(props: ICPProps): JSX.Element {
	const [passwordData, setPasswordData] = useState<PasswordDataTP>({
		confirmPassword: '',
		currentPassword: '',
		password: '',
	})

	const changePasswordRequest = useRequest('none')

	useEffect(onChangePasswordRequestChange, [changePasswordRequest.isAwaiting])

	function handlePasswordDataChange(value: PasswordDataTP): void {
		setPasswordData(value)
	}

	/**
	 * Faz requisicao na API para alteracao de senha.
	 */
	async function changePassword(): Promise<void> {
		changePasswordRequest.runRequest(
			props.requestConfig({
				oldPassword: passwordData.currentPassword,
				confirmPassword: passwordData.confirmPassword,
				password: passwordData.password,
			}),
		)
	}

	/**
	 * Faz as validações de request, manipula o novo tokem que a API retorna após a troca de senha e reseta o modal
	 */
	function onChangePasswordRequestChange(): void {
		const isValidRequest = RequestUtils.isValidRequestReturn(
			changePasswordRequest,
			'Não foi possível alterar a sua senha',
			'Senha alterada com sucesso!',
			true,
		)

		if (!isValidRequest) return

		props.onClose()
	}

	return (
		<ModalCP
			title={'Alterar Senha'}
			visible={props.visible}
			onOk={changePassword}
			onCancel={props.onClose}
			actionLoading={changePasswordRequest.isAwaiting}
			width={450}
		>
			<FormSetPasswordCP.Wrapper onPasswordDataChange={handlePasswordDataChange}>
				<FormSetPasswordCP.Inputs currentPassword />
				<FormSetPasswordCP.Validation />
			</FormSetPasswordCP.Wrapper>
		</ModalCP>
	)
}
