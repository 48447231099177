import { PatientPortalDataPdfFooterICP } from 'modules/patient-portal/components/patient-portal-data-pdf/inner/PatientPortalDataPdfFooterICP'
import { PatientPortalDataPdfHeaderICP } from 'modules/patient-portal/components/patient-portal-data-pdf/inner/PatientPortalDataPdfHeaderICP'
import React, { forwardRef, useEffect, useState } from 'react'
import { ClinicsRequests } from 'submodules/beerads-sdk/services/clinics/clinics/ClinicsRequests'
import { ClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicResponseDTO'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import * as S from './PatientPortalDataPdfStyles'

interface ICPProps {
	exam: ExamResponseDTO
	onPrinterReady: () => void
}

export const PatientPortalDataPdfCP = forwardRef<HTMLDivElement, ICPProps>((props, ref): JSX.Element => {
	const [clinic, setClinic] = useState<ClinicResponseDTO>()
	const getClinicRequest = useRequest<ClinicResponseDTO>()

	useEffect(onGetClinicRequestChange, [getClinicRequest.isAwaiting])

	useEffect(init, [props.exam])

	function init(): void {
		getClinicRequest.runRequest(ClinicsRequests.get(props.exam.clinic.code))
	}

	function onGetClinicRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getClinicRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) {
			return
		}

		setClinic(getClinicRequest.responseData)

		props.onPrinterReady()
	}

	if (!clinic) {
		return <></>
	}

	return (
		<S.WrapperSCP ref={ref}>
			<S.BorderWrapperSCP>
				<PatientPortalDataPdfHeaderICP exam={props.exam} clinic={clinic} />

				<PatientPortalDataPdfFooterICP />
			</S.BorderWrapperSCP>
		</S.WrapperSCP>
	)
})
