import { SelectDoctorGroupPeopleCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-people/SelectDoctorGroupPeopleCP'
import { MedicalReportLocalStorageUtil } from 'modules/exams/components/medical-report/status-save-report-local-storage/inner/MedicalReportLocalStorageUtil'
import { MedicalReportFormModel } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModel'
import { MedicalReportFormModelConverter } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModelConverter'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { MedicalReportRequests } from 'submodules/beerads-sdk/services/exams/medical-reports/MedicalReportRequests'
import { MedicalReportFinalizeActionTP } from 'submodules/beerads-sdk/services/exams/medical-reports/types/MedicalReportFinalizeActionTP'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ButtonMultiActionCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonMultiActionCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps {
	exam: ExamResponseDTO
	formStateManager: IFormStateManager<MedicalReportFormModel>
	onSave: () => void
}

/**
 */
export function ButtonsFinishMedicalReportCP(props: ICPProps): JSX.Element {
	const [isDoctorPopoverVisible, setIsDoctorPopoverVisible] = useState<boolean>(false)

	const [saveAction, setSaveAction] = useState<MedicalReportFinalizeActionTP>()

	const saveReportRequest = useRequest<'void'>('none')
	useEffect(onSaveRequestChange, [saveReportRequest.isAwaiting])

	/**
	 * Salva o laudo
	 */
	function save(selectedaAction: MedicalReportFinalizeActionTP): void {
		setSaveAction(selectedaAction)

		// Dupla assinatura
		if (selectedaAction === ExamStatusEnum.SIGNED) {
			const dto = MedicalReportFormModelConverter.formatSignDto(props.exam, props.formStateManager)
			if (!!dto) saveReportRequest.runRequest(MedicalReportRequests.sign(dto))

			return
		}

		// Finaliza o laudo
		const dto = MedicalReportFormModelConverter.formatFinalizeDto(props.exam, props.formStateManager)
		if (!!dto) saveReportRequest.runRequest(MedicalReportRequests.finalize(dto))
	}

	/**
	 * Retorno api.
	 */
	function onSaveRequestChange(): void {
		let successMsg: string | undefined
		if (saveAction === ExamStatusEnum.SIGNED) successMsg = 'Laudo assinado com sucesso'
		else if (saveAction === ExamStatusEnum.DONE) successMsg = 'Laudo assinado e entregue com sucesso'

		// Avalia retorno da ultima requisicao
		if (!RequestUtils.isValidRequestReturn(saveReportRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, successMsg, true)) return

		// Remove do LS o que foi salvo
		MedicalReportLocalStorageUtil.discardMedicalReportLocalStorage(props.exam.code)

		props.onSave()
	}

	return (
		<>
			<PopOverCP
				visible={isDoctorPopoverVisible}
				onClose={() => setIsDoctorPopoverVisible(false)}
				trigger={'click'}
				title={'Atribuição para médico'}
				content={
					<PopoverContentSCP>
						{!!props.exam.doctorGroup ? (
							<>
								<SelectDoctorGroupPeopleCP
									doctorGroupCode={props.exam.doctorGroup?.code}
									formStateManager={props.formStateManager}
									formField={'revisionDoctorCode'}
									role={UserTypeEnum.DOCTOR}
								/>
								<HelpCP text={'Selecione o médico que fará a revisão'} type={'text'} />

								<FlexCP justify={'space-between'} margin={{ top: 20 }}>
									<ButtonCP onClick={() => setIsDoctorPopoverVisible(false)}>Cancelar</ButtonCP>
									<ButtonCP
										type={'primary'}
										onClick={() => {
											setIsDoctorPopoverVisible(false)
											save(ExamStatusEnum.SIGNED)
										}}
									>
										Salvar
									</ButtonCP>
								</FlexCP>
							</>
						) : (
							<>Nenhum grupo de médicos definido</>
						)}
					</PopoverContentSCP>
				}
			>
				<ButtonMultiActionCP
					type={'primary'}
					loading={saveReportRequest.isAwaiting}
					options={[
						{
							label: 'Assinar e Entregar Laudo',
							onClick: () => save(ExamStatusEnum.DONE),
							icon: <IconICP iconName={'audit'} />,
						},
						{
							label: 'Assinar laudo e requisitar dupla assinatura',
							onClick: () => setIsDoctorPopoverVisible(true),
							hide:
								[ExamStatusEnum.SIGNED, ExamStatusEnum.RECTIFICATION, ExamStatusEnum.SIGNED_RECTIFICATION].includes(props.exam.status) ||
								!props.exam.doctorGroup,
						},
					]}
				/>
			</PopOverCP>
		</>
	)
}

const PopoverContentSCP = styled.div`
	width: 300px;
`
