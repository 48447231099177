import { FoundClinicResultICP } from 'modules/clinic/components/find-clinic-box/inner/FoundClinicResultICP'
import { InputSearchClinicByCnpjCP } from 'modules/clinic/components/input-search-clinic-by-cnpj/InputSearchClinicByCnpjCP'
import { useState } from 'react'
import { ClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicResponseDTO'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'

interface ICPProps {
	doctorGroupCode?: number
	onClickToCreateClinic: (cnpj: string) => void
	onInviteToDoctorGroup: (clinic: ClinicResponseDTO) => void
	onCancel: () => void
}

/**
 * Link do grupo de medico a uma clinica
 */
export function FindClinicBoxCP(props: ICPProps): JSX.Element {
	const [showInfo, setShowInfo] = useState(false)
	const [cnpjEntered, setCnpjEntered] = useState<string>()
	const [foundClinic, setFoundClinic] = useState<ClinicResponseDTO>()

	return (
		<>
			{!showInfo && (
				<InputSearchClinicByCnpjCP
					onResult={(cnpj, clinic) => {
						setCnpjEntered(cnpj)
						setFoundClinic(clinic)
						setShowInfo(true)
					}}
				/>
			)}

			{showInfo && !!foundClinic && (
				<FoundClinicResultICP
					doctorGroupCode={props.doctorGroupCode}
					clinic={foundClinic}
					onCancel={props.onCancel}
					onInviteToDoctorGroup={() => props.onInviteToDoctorGroup(foundClinic)}
				/>
			)}

			{showInfo && !foundClinic && !!cnpjEntered && (
				<>
					<AlertCP
						message={'Ops! Unidade Hospitalar não encontrada no sistema. Clique no botão abaixo para criar uma nova'}
						type={'warning'}
						margin={{ bottom: 10 }}
					/>

					<FlexCP justify={'center'} margin={{ top: 20 }}>
						<ButtonCP type={'primary'} onClick={() => props.onClickToCreateClinic(cnpjEntered)}>
							Criar Unidade Hospitalar
						</ButtonCP>
					</FlexCP>
				</>
			)}
		</>
	)
}
