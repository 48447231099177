import { Popover } from 'antd'
import { ComponentPropsWithoutRef, ReactNode } from 'react'

interface IProps extends ComponentPropsWithoutRef<typeof Popover> {
	children: ReactNode
	className?: string
}

export function PopOverWrapperICP(props: IProps): JSX.Element {
	return (
		<Popover {...props} rootClassName={props.className}>
			{props.children}
		</Popover>
	)
}
