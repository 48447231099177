import { useAppSelector } from 'app/redux/hook'
import { RoutingHelper } from 'config/RoutingHelper'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import { WidgetsRequests } from 'submodules/beerads-sdk/services/dashboard/widgets/WidgetsRequests'
import { CountExamsWidgetEnum } from 'submodules/beerads-sdk/services/dashboard/widgets/enums/CountExamsWidgetEnum'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ExamAssignedFilterEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamAssignedFilterEnum'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { ExamStatusUtils } from 'submodules/beerads-sdk/services/exams/exams/utils/ExamStatusUtils'
import { RoundedWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/RoundedWrapperCP'
import { CardWidgetOnlyValuesCP } from 'submodules/nerit-framework-ui/common/components/dashboard/card-widget-only-values/CardWidgetOnlyValuesCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'

interface ICPProps {
	clinicCode?: number
	viewAsDoctor: boolean
	assignedType: ExamAssignedFilterEnum
	widget:
		| CountExamsWidgetEnum.ASSIGNED_BY_SLA_2H
		| CountExamsWidgetEnum.ASSIGNED_BY_SLA_6H
		| CountExamsWidgetEnum.ASSIGNED_BY_SLA_24H
		| CountExamsWidgetEnum.BY_SLA_DELAYED
}

/**
 */
export function WidgetCountExamsBySlaCP(props: ICPProps): JSX.Element {
	const currentDoctorGroup = useAppSelector((state) => state.doctorGroup)

	let color: string
	let time: number

	switch (props.widget) {
		case CountExamsWidgetEnum.BY_SLA_DELAYED:
			color = ThemeProjectCommon.examSlaColor.sla0h
			time = 0
			break

		case CountExamsWidgetEnum.ASSIGNED_BY_SLA_2H:
			color = ThemeProjectCommon.examSlaColor.sla2h
			time = 2
			break

		case CountExamsWidgetEnum.ASSIGNED_BY_SLA_6H:
			color = ThemeProjectCommon.examSlaColor.sla6h
			time = 6
			break

		case CountExamsWidgetEnum.ASSIGNED_BY_SLA_24H:
			color = ThemeProjectCommon.examSlaColor.sla24h
			time = 24
			break
	}

	const shouldCache: boolean = true

	const filtersDto: ExamSearchRequestDTO = {
		viewAsDoctor: props.viewAsDoctor,
		clinicCodes: !!props.clinicCode ? [props.clinicCode] : undefined,
		statuses: ExamStatusUtils.examStatusOfExamStatusGroupMap.get(ExamStatusGroupEnum.AWAITING),
		assigned: props.assignedType,
		sla: time,
		doctorGroupCode: currentDoctorGroup.code ?? undefined,
		shouldCache: shouldCache
	}

	return (
		<CardWidgetOnlyValuesCP
			onClick={() => RoutingHelper.goToExams(ExamStatusGroupEnum.AWAITING, filtersDto)}
			requestConfigGetter={WidgetsRequests.countExams(filtersDto)}
			main={{
				title: `${props.assignedType === ExamAssignedFilterEnum.YES ? 'Atribuído com ' : ''}SLA ${time > 0 ? `< ${time}h` : 'atrasado'}`,
				help: `${props.assignedType === ExamAssignedFilterEnum.YES ? 'Exames atribuídos , com ' : ''}SLA restante em menos de ${time}h`,
				isZeroTarget: true,
				icon: <RoundedWrapperCP text={<IconICP iconName={'exclamation'} />} color={color} size={40} />,
				isCachedData: shouldCache,
			}}
		/>
	)
}
