import { ThemeFrameworkCommon } from '@/submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import styled from 'styled-components'

export const FilterExamsFormSCP = styled.form`
	background: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
	padding: 10px;
`

export const ButtonSmallScreeSCP = styled.div`
	margin-top: 20px;

	> div {
		width: 100%;

		button {
			width: 100%;
			display: flex;
			justify-content: center;
		}
	}
`

export const SwitchWrapperSCP = styled.div`
	margin-top: 10px;
	margin-left: 5px;
	.ant-typography {
		line-height: 20px;
	}
`

export const RadioGroupWrapperSCP = styled.div`
	margin-top: 5px;
	.ant-radio-button-wrapper {
		line-height: 25px;
	}
`
