import { faDev } from '@fortawesome/free-brands-svg-icons'
import { faFileMedical, faHospitalUser, faHourglassHalf, faPeopleCarry, faTools } from '@fortawesome/free-solid-svg-icons'
import { ExamIconCP } from '_old/main/common/components/icons/ExamIconCP'
import { ReportMaskIconCP } from '_old/main/common/components/icons/ReportMaskIconCP'
import { SettingIconCP } from '_old/main/common/components/icons/SettingIconCP'
import { IAuthState } from 'app/redux/slices/auth/AuthSlice'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { NeritFrameworkProjectConfig } from 'config/NeritFrameworkProjectConfig'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import { ClinicPermissionUtils } from 'modules/auth/permissions/ClinicPermissionUtils'
import { CommonPermissionUtils } from 'modules/auth/permissions/CommonPermissionUtils'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import styled from 'styled-components'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { FontAwsomeIconCP } from 'submodules/nerit-framework-ui/common/components/icon/FontAwsomeIconCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { MenuItemTP } from 'submodules/nerit-framework-ui/common/components/menu/menu/inner/MenuItemTP'

/**
 * UTILITARIOS do menu principal do sistema
 */
export const UserMenuUtils = {
	getMenuOptionsTop(authData: IAuthState): MenuItemTP[] {
		return [
			{
				route: PrivateUserRouter.HOME,
				title: 'Página Inicial',
				icon: <IconICP iconName={'home'} color={ThemeProject.menuItemColor} />,
				enabled: true,
			},
			{
				route: PrivateUserRouter.EXAMS.replace(':examStatusGroup', ExamStatusGroupEnum.AWAITING),
				title: 'Exames',
				icon: <ExamIconCP color={ThemeProject.menuItemColor} />,
				menuItemsChildren: [
					{
						title: 'Pendentes',
						route: PrivateUserRouter.EXAMS.replace(':examStatusGroup', ExamStatusGroupEnum.PENDING),
						icon: (
							<SubmenuIconICP>
								<FontAwsomeIconCP type={faHospitalUser} />
							</SubmenuIconICP>
						),
						renderType: 'text',
						enabled: ClinicPermissionUtils.hasAnyPermissionsInAnyClinic(
							['isUser', 'isAdmin', 'isDoctorGroupUser', 'isDoctorGroupAdmin'],
							authData.clinicPermissions,
						),
					},
					{
						title: 'Aguardando',
						route: PrivateUserRouter.EXAMS.replace(':examStatusGroup', ExamStatusGroupEnum.AWAITING),
						icon: (
							<SubmenuIconICP>
								<FontAwsomeIconCP type={faHourglassHalf} />
							</SubmenuIconICP>
						),
						renderType: 'text',
					},
					{
						title: 'Em Análise',
						route: PrivateUserRouter.EXAMS.replace(':examStatusGroup', ExamStatusGroupEnum.PENDING_ANALYSIS),
						icon: (
							<SubmenuIconICP>
								<FontAwsomeIconCP type={faPeopleCarry} />
							</SubmenuIconICP>
						),
						renderType: 'text',
						enabled: ClinicPermissionUtils.hasAnyPermissionsInAnyClinic(
							['isDoctorGroupUser', 'isDoctorGroupAdmin'],
							authData.clinicPermissions,
						),
					},
					{
						title: 'Concluídos',
						route: PrivateUserRouter.EXAMS.replace(':examStatusGroup', ExamStatusGroupEnum.DONE),
						icon: (
							<SubmenuIconICP>
								<FontAwsomeIconCP type={faFileMedical} />
							</SubmenuIconICP>
						),
						renderType: 'text',
					},
					{
						title: 'Arquivados',
						route: PrivateUserRouter.EXAMS.replace(':examStatusGroup', ExamStatusGroupEnum.ARCHIVED),
						icon: (
							<SubmenuIconICP>
								<FontAwsomeIconCP type={faFileMedical} />
							</SubmenuIconICP>
						),
						renderType: 'text',
						enabled: ClinicPermissionUtils.hasAnyPermissionsInAnyClinic(
							['isUser', 'isAdmin', 'isDoctorGroupUser', 'isDoctorGroupAdmin'],
							authData.clinicPermissions,
						),
					},
					{
						title: 'Todos',
						route: PrivateUserRouter.EXAMS.replace(':examStatusGroup?', ''),
						icon: (
							<SubmenuIconICP>
								<FontAwsomeIconCP type={faFileMedical} />
							</SubmenuIconICP>
						),
						renderType: 'text',
					},
				],
			},
			{
				route: PrivateUserRouter.REPORTS,
				title: 'Relatórios',
				icon: <IconICP iconName={'bar-chart'} color={ThemeProject.menuItemColor} />,
			},
			{
				route: PrivateUserRouter.TEMPLATES,
				title: 'Templates',
				icon: <ReportMaskIconCP color={ThemeProject.menuItemColor} />,
				enabled: !!authData.user?.isDoctor,
			},
			{
				route: PrivateUserRouter.DOCTOR_GROUP,
				title: 'Grupos de médicos',
				icon: <IconICP iconName={ThemeProjectCommon.icons.doctorGroup} size={22} color={ThemeProject.menuItemColor} />,
				enabled: DoctorGroupPermissionUtils.hasAnyPermissionsInAnyDoctorGroup(
					['isDoctorGroupAdmin', 'isDoctorGroupUser'],
					authData.doctorGroupPermissions,
				),
			},
			{
				route: PrivateUserRouter.QUALITY_ASSURANCE_AUDITS,
				title: 'Central de Qualidade',
				icon: <IconICP iconName={'security-scan'} color={ThemeProject.menuItemColor} />,
				enabled: DoctorGroupPermissionUtils.hasAnyPermissionsInAnyDoctorGroup(
					['isDoctorGroupAdmin', 'isAuditor'],
					authData.doctorGroupPermissions,
				),
				menuItemsChildren: [
					{
						title: 'Auditorias',
						route: PrivateUserRouter.QUALITY_ASSURANCE_AUDITS,
						icon: (
							<SubmenuIconICP>
								<IconICP iconName={'dashboard'} size={16} />
							</SubmenuIconICP>
						),
						renderType: 'text',
						enabled: DoctorGroupPermissionUtils.hasAnyPermissionsInAnyDoctorGroup(['isDoctorGroupAdmin'], authData.doctorGroupPermissions),
					},
					{
						title: 'Seleção de Exames',
						route: PrivateUserRouter.QUALITY_ASSURANCE_EXAMS,
						icon: (
							<SubmenuIconICP>
								<IconICP iconName={'check-circle'} size={16} />
							</SubmenuIconICP>
						),
						renderType: 'text',
						enabled: DoctorGroupPermissionUtils.hasAnyPermissionsInAnyDoctorGroup(['isDoctorGroupAdmin'], authData.doctorGroupPermissions),
					},
					{
						title: 'Minhas Auditorias',
						route: PrivateUserRouter.DOCTOR_AUDITS,
						icon: (
							<SubmenuIconICP>
								<IconICP iconName={'audit'} size={16} />
							</SubmenuIconICP>
						),
						renderType: 'text',
						enabled:
							DoctorGroupPermissionUtils.hasAnyPermissionsInAnyDoctorGroup(['isAuditor'], authData.doctorGroupPermissions) &&
							CommonPermissionUtils.canAccessByType(UserTypeEnum.DOCTOR, authData.user),
					},
				],
			},
			{
				route: DoctorGroupPermissionUtils.hasAnyPermissionsInAnyDoctorGroup(
					['isDoctorGroupAdmin', 'isDoctorGroupUser'],
					authData.doctorGroupPermissions,
				)
					? PrivateUserRouter.MEDICAL_TIMETABLE
					: PrivateUserRouter.MEDICAL_TIMETABLE_DOCTOR,
				title: 'Escala Médica',
				icon: <IconICP iconName={'calendar'} size={22} color={ThemeProject.menuItemColor} />,
				enabled:
					DoctorGroupPermissionUtils.hasAnyPermissionsInAnyDoctorGroup(
						['isDoctorGroupAdmin', 'isDoctorGroupUser'],
						authData.doctorGroupPermissions,
					) || CommonPermissionUtils.canAccessByType(UserTypeEnum.DOCTOR, authData.user),
				menuItemsChildren: DoctorGroupPermissionUtils.hasAnyPermissionsInAnyDoctorGroup(
					['isDoctorGroupAdmin', 'isDoctorGroupUser'],
					authData.doctorGroupPermissions,
				)
					? [
							{
								title: 'Escala do grupo',
								route: PrivateUserRouter.MEDICAL_TIMETABLE,
								renderType: 'text',
							},
							{
								title: 'Escala do médico',
								route: PrivateUserRouter.MEDICAL_TIMETABLE_DOCTOR,
								renderType: 'text',
							},
					  ]
					: undefined,
			},
		]
	},

	getMenuOptionsBottom(authData: IAuthState): MenuItemTP[] {
		return [
			{
				route: PrivateUserRouter.CLINIC,
				title: 'Clinicas',
				icon: <SettingIconCP color={ThemeProject.menuItemColor} />,
				enabled: ClinicPermissionUtils.hasAnyPermissionsInAnyClinic(['isAdmin', 'isDoctorGroupAdmin'], authData.clinicPermissions),
			},
			{
				route: PrivateUserRouter.ADMIN_BEERADS,
				title: 'Admin BeeRads',
				icon: <FontAwsomeIconCP type={faTools} color={ThemeProject.menuItemColor} style={{ fontSize: '20px' }} />,
				enabled: CommonPermissionUtils.isBeeRadsAdmin(authData.user),
			},
			{
				route: PrivateUserRouter.DEV_TEST,
				title: 'Dev',
				icon: <FontAwsomeIconCP type={faDev} color={ThemeProject.menuItemColor} style={{ fontSize: '20px' }} />,
				enabled: NeritFrameworkProjectConfig.isDev(),
				menuItemsChildren: [
					{
						title: 'Links Públicos',
						route: `${PrivateUserRouter.DEV_TEST}?publicLinks=true`,
						renderType: 'text',
					},
					{
						title: 'Editor de Laudo',
						route: `${PrivateUserRouter.DEV_TEST}?editor=true`,
						renderType: 'text',
					},
					{
						title: 'Usuário Logado',
						route: `${PrivateUserRouter.DEV_TEST}?loggedUser=true`,
						renderType: 'text',
					},
					{
						title: 'Intercom',
						route: `${PrivateUserRouter.DEV_TEST}?intercom=true`,
						renderType: 'text',
					},
				],
			},
		]
	},
}

const SubmenuIconICP = styled.div`
	width: 20px;
	display: flex;
	justify-content: center;
	svg {
		color: ${ThemeProject.menuItemAltColor} !important;
	}
`
