import { AlertTrialClinicExamsCP } from 'modules/clinic/components/alert-trial-clinic-exams/AlertTrialClinicExamsCP'
import { FiltersExamsCP } from 'modules/exams/components/exams/filters-exams/FiltersExamsCP'
import { SubtitleExamGroupStatusCP } from 'modules/exams/components/exams/subtitle-exam-group-status/SubtitleExamGroupStatusCP'
import { ExamFilterFormModel } from 'modules/exams/components/exams/table-exams/filters/ExamFilterFormModel'
import { TableExamsCP } from 'modules/exams/components/exams/table-exams/TableExamsCP'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
	examStatusGroup?: ExamStatusGroupEnum
	isScreenInitialized: boolean
	filterStateManager: IFormStateManager<ExamFilterFormModel>
	examsTable: {
		onRequestToReloadTable: () => void
		reloadTable: number
		onLoading: (isLoading: boolean) => void
		isLoading: boolean
	}
	originalExamFilters?: ExamSearchRequestDTO
}

/**
 */
export function ScreenContentExams(props: ICPProps): JSX.Element {
	return (
		<ContentCP overflowVertical={true}>
			<FiltersExamsCP
				examStatusGroup={props.examStatusGroup}
				filterFormStateManager={props.filterStateManager}
				isLoading={props.examsTable.isLoading}
				onFilter={props.examsTable.onRequestToReloadTable}
				isScreenInitialized={props.isScreenInitialized}
				originalExamFilters={props.originalExamFilters}
			/>

			{props.examStatusGroup === ExamStatusGroupEnum.PENDING && <AlertTrialClinicExamsCP borderType={'square'} />}

			{props.isScreenInitialized && (
				<TableExamsCP
					examStatusGroup={props.examStatusGroup}
					reloadTable={props.examsTable.reloadTable}
					onLoading={props.examsTable.onLoading}
					filterStateManager={props.filterStateManager}
				/>
			)}

			{!!props.examStatusGroup && <SubtitleExamGroupStatusCP examStatusGroup={props.examStatusGroup} />}
		</ContentCP>
	)
}
