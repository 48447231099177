import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { MenuItemProfileCP } from 'app/components/menu-item-profile/MenuItemProfileCP'
import { MedicalReportCounterCP } from 'app/components/report-counter-indicator/MedicalReportCounterCP'
import { SelectCurrentDoctorGroupCP } from 'app/components/select-current-doctor-group/SelectCurrentDoctorGroupCP'
import { UserMenuUtils } from 'app/components/user-main-menu/inner/UserMenuUtils'
import { useAppSelector } from 'app/redux/hook'
import styled from 'styled-components'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { LogoMenuCP } from 'submodules/nerit-framework-ui/common/components/menu/logo-menu/LogoMenuCP'
import { MenuUserMainCP } from 'submodules/nerit-framework-ui/common/components/menu/menu-user-main/MenuUserMainCP'
import { ButtonSwitchThemeCP } from 'submodules/nerit-framework-ui/features/common/components/button-switch-theme/ButtonSwitchTheme'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	onSelectMenuItem: () => void
	onChangeCurrentDoctorGroup: () => void
}

/**
 */
export function UserMainMenuCP(props: ICPProps): JSX.Element {
	const authData = useAppSelector((state) => state.auth)

	return (
		<MenuUserMainCP
			onSelectMenuItem={props.onSelectMenuItem}
			menuTop={{
				topContent: (
					<>
						<LogoMenuCP />
						<MenuDividerSCP />
					</>
				),
				options: UserMenuUtils.getMenuOptionsTop(authData),
			}}
			menuBottom={{
				options: UserMenuUtils.getMenuOptionsBottom(authData),
				bottomContent: (
					<>
						<AccessControlCP permission={{ byUserType: UserTypeEnum.DOCTOR }}>
							<MenuDividerSCP />
							<MedicalReportCounterCP />
						</AccessControlCP>

						<MenuDividerSCP />
						<SelectCurrentDoctorGroupCP onChange={props.onChangeCurrentDoctorGroup} />
						<ButtonSwitchThemeCP />
						<MenuItemProfileCP />
					</>
				),
			}}
		/>
	)
}

const MenuDividerSCP = styled.div`
	width: 67px;
	height: 2px;
	background: ${ThemeFrameworkCommon.browserDefaultBackgroundLight}80;
	margin-bottom: 15px;
`
