import React from 'react'
import * as S from './VxWindModalStyles'

interface ModalProps {
	children: React.ReactNode
	isVisible?: boolean
}

interface ModalContentProps {
	children: React.ReactNode
}

interface CloseButtonProps {
	onClick?: () => void
}

// Modal principal
function VxWindModal({ children, isVisible }: ModalProps): JSX.Element {
	return <S.Modal isVisible={isVisible}>{children}</S.Modal>
}

// Subcomponente Overlay
function Overlay({ children, isVisible }: ModalProps): JSX.Element {
	return <S.Overlay isVisible={isVisible}>{children}</S.Overlay>
}

// Subcomponente ModalContent
function Content({ children }: ModalContentProps): JSX.Element {
	return <S.ModalContent onClick={(e) => e.stopPropagation()}>{children}</S.ModalContent>
}

// Subcomponente CloseButton
function CloseButton({ onClick }: CloseButtonProps): JSX.Element {
	return <S.CloseButton onClick={onClick}></S.CloseButton>
}

// Expondo os subcomponentes
VxWindModal.Overlay = Overlay
VxWindModal.Modal = VxWindModal
VxWindModal.Content = Content
VxWindModal.CloseButton = CloseButton

export { VxWindModal }
