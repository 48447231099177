import { Tabs } from 'antd'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

export const CustomTabs = styled(Tabs)`
	.ant-tabs-tab {
		padding: 12px 16px;
	}

	.ant-tabs-nav::before {
		border-bottom: 1px solid ${ThemeFrameworkCommon.browserDefaultColorDark}10;
	}

	.ant-tabs-ink-bar {
		background: ${ThemeProject.secondary};
		height: 3px !important;
	}

	.ant-tabs-tab-btn {
		color: ${ThemeFrameworkCommon.browserDefaultColorDark} !important;
		font-size: 0.875rem;
		font-weight: 500;
	}
`
