import { AppConfig } from 'config/AppConfig'
import { MutableRefObject, useCallback, useState } from 'react'
import { FrontEnvironmentEnum } from 'submodules/nerit-framework-utils/utils/enums/EnvironmentEnum'
import webSocketProcessorUrlDev from './AWWebSocket.ts?url'
import webSocketProcessorUrlProd from './AWWebSocket.ts?worker&url'

const MAX_BYTE_FREQUENCY_DATA = 256 // Número máximo emitido pela função getByteFrequencyData
const FFT_SIZE = 128 // Tamanho da FFT, quanto menor, maior a sensibilidade - somente valores potências de 2

enum WebSocketStatesEnum {
	CONNECTING = 0,
	OPEN = 1,
	CLOSING = 2,
	CLOSED = 3,
}

interface IUseSpeechToTextProps {
	useFullTranscript?: boolean
	stream: MutableRefObject<MediaStream | null | undefined>
	websocket: MutableRefObject<WebSocket | null | undefined>
	isCapturing: boolean
	onChangeCapturing: (value: boolean) => void
	onChangeTextData: (value: string) => void
}

interface IUseSpeechToTextResponse {
	onTrigger: VoidFunction
	volume: number
}

export function useSpeechToText({
	useFullTranscript,
	stream,
	websocket,
	isCapturing,
	onChangeCapturing,
	onChangeTextData,
}: IUseSpeechToTextProps): IUseSpeechToTextResponse {
	const [volume, setVolume] = useState<number>(0)

	async function captureMicrophone(): Promise<void> {
		if (!stream.current) return

		const context = new AudioContext({
			sampleRate: 44_100,
		})
		await context.audioWorklet.addModule(import.meta.env.PROD ? webSocketProcessorUrlProd : webSocketProcessorUrlDev)
		const source = context.createMediaStreamSource(stream.current)

		const processor = new AudioWorkletNode(context, 'websocket-processor')

		const analyser = context.createAnalyser()
		analyser.fftSize = FFT_SIZE
		source.connect(analyser)

		source.connect(processor)
		processor.connect(context.destination)

		const dataArray = new Uint8Array(analyser.frequencyBinCount)

		processor.port.onmessage = ({ data }) => {
			if (websocket.current?.readyState === WebSocketStatesEnum.OPEN) {
				websocket.current?.send(data)
				captureVolume(analyser, dataArray)
			}
		}

		source.connect(processor)
		processor.connect(context.destination)
		onChangeCapturing(true)
	}

	function captureVolume(analyser: AnalyserNode, dataArray: Uint8Array): void {
		analyser.getByteFrequencyData(dataArray)

		let values = 0
		for (const data of dataArray) {
			values += +data
		}

		const average = values / dataArray.length
		const volume = average / MAX_BYTE_FREQUENCY_DATA
		const formattedVolume = Math.round(volume * 10) / 10

		setVolume(formattedVolume)
	}

	async function startCapturing(): Promise<void> {
		try {
			const { apiBaseUrl, environment } = AppConfig.getInstance()
			const baseUrl = apiBaseUrl.split('/')[2]
			const searchParams = new URLSearchParams({
				...((useFullTranscript ?? true) && { useFullTranscript: 'true' }),
			})

			stream.current = await navigator.mediaDevices.getUserMedia({
				audio: true,
			})

			if ([FrontEnvironmentEnum.HOMOLOGATION, FrontEnvironmentEnum.PRODUCTION, FrontEnvironmentEnum.BETA].includes(environment)) {
				websocket.current = new WebSocket(`wss://${baseUrl}/vx-wind/speech?${searchParams.toString()}`)
			} else {
				websocket.current = new WebSocket(`ws://localhost:3002/vx-wind/speech?${searchParams.toString()}`)
			}

			websocket.current.addEventListener('open', captureMicrophone)

			websocket.current.addEventListener('message', (event) => {
				const typedData = String(event.data).toLowerCase()
				onChangeTextData(typedData)
			})

			websocket.current.addEventListener('close', () => {
				stopCapturing()
			})
		} catch (error) {
			stopCapturing()
		}
	}

	function stopCapturing(): void {
		onChangeCapturing(false)
		if (!stream.current) {
			return
		}

		stream.current.getTracks().forEach((track) => {
			if (track.readyState === 'live') {
				track.stop()
			}
		})
		stream.current = null

		if (websocket.current?.readyState !== WebSocketStatesEnum.CLOSED) {
			websocket.current?.close()
		}
		websocket.current = null
	}

	const onTrigger = useCallback(async () => {
		if (isCapturing) {
			stopCapturing()
		} else {
			await startCapturing()
		}
	}, [isCapturing])

	return {
		onTrigger,
		volume,
	}
}
