import { MedicalTimetableAbsenceFormModel } from 'modules/medical-timetable/components/modals/modal-medical-timetable-absence/inner/MedicalTimetableAbsenceFormModel'
import React from 'react'
import {
	MedicalTimetableReasonAbsenceEnum,
	MedicalTimetableReasonAbsenceLabelEnum,
} from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableReasonAbsenceEnum'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
	formStateManager: IFormStateManager<MedicalTimetableAbsenceFormModel>
	fieldName: string
	isUserAddingAbsenceOnMedicalTimetable?: boolean
}

export function SelectMedicalTimetableAbsenceCP(props: ICPProps): JSX.Element {
	const options: Array<{ value: MedicalTimetableReasonAbsenceEnum; label: string }> = []

	if (!props.isUserAddingAbsenceOnMedicalTimetable) {
		// Se o usuário NÃO está adicionando ausência na escala, mostra todas as opções exceto NO_SHOW e MISTAKE
		for (const option of Object.values(MedicalTimetableReasonAbsenceEnum)) {
			if (option === MedicalTimetableReasonAbsenceEnum.MISTAKE || option === MedicalTimetableReasonAbsenceEnum.NO_SHOW) continue
			options.push({
				value: option,
				label: MedicalTimetableReasonAbsenceLabelEnum[option],
			})
		}
	}

	return <SelectCP label={'Motivo'} formStateManager={props.formStateManager} fieldName={'reasonAbsence'} options={options} />
}
