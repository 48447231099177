import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'
import { useTheme } from 'providers/theme-provider/ThemeProvider'
import { FontAwsomeIconCP } from 'submodules/nerit-framework-ui/common/components/icon/FontAwsomeIconCP'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'

export function ButtonSwitchThemeCP(): JSX.Element {
	const { theme, setTheme } = useTheme()

	function handleColors(): void {
		setTheme(theme === 'dark' ? 'light' : 'dark')
		window.location.reload()
	}

	return (
		<TooltipCP text={theme === 'dark' ? 'Alterar para o tema claro' : 'Alterar para o tema escuro'} placement={'right'}>
			<button className='border-none bg-transparent flex content-center text-sm mb-4 mx-auto text-white' onClick={handleColors}>
				<FontAwsomeIconCP type={theme === 'dark' ? faSun : faMoon} />
			</button>
		</TooltipCP>
	)
}
