import styled, { css, keyframes } from 'styled-components'

export const Overlay = styled.div<{ isVisible?: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	display: ${(props) => (props.isVisible ? 'flex' : 'none')};
	justify-content: center;
	align-items: center;
	z-index: 1000;
	flex-direction: column;
`

export const Modal = styled.div<{ isVisible?: boolean }>`
	${({ isVisible }) => css`
		display: ${isVisible ? 'flex' : 'none'};
		align-items: center;
		flex-direction: column;
		gap: 16px;
		opacity: ${isVisible ? 1 : 0};
		transition: opacity 1000ms ease-in-out;
		animation: ${isVisible ? fadeIn : 'none'} 1s ease-in-out forwards;
	`}
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateZ(-20px);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
`
export const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	background: none;
	border: none;
	font-size: 1.5rem;
	cursor: pointer;
`

export const ModalContent = styled.div`
	background: white;
	width: 500px;
	padding: 24px;
	border-radius: 8px;
	position: relative;
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
	align-self: center;
	display: flex;
	flex-direction: column;
`
