import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateZ(-20px);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
`
export const Wrapper = styled.div`
	margin-bottom: 24px;
	display: flex;
	gap: 10px;
	svg {
		width: 48px;
		height: 48px;
	}
`

export const AnimatedLetter = styled.span<{ delay: number }>`
	opacity: 0;
	display: inline-block;
	animation: ${fadeIn} 0.5s forwards;
	animation-delay: ${(props) => props.delay}s;
	color: #fff;
	font-weight: 500;
	font-size: 1.5rem;
`

export const AnimatedTextWrapper = styled.div`
	display: inline-block;
	font-size: 2em;
	font-weight: bold;
	text-shadow: 2px 1px 1px black;
`
