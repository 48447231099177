import { SelectLabelDoctorGroupPeopleICP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-people/inner/SelectLabelDoctorGroupPeopleICP'
import { useEffect, useState } from 'react'
import { DoctorGroupsRequests } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/DoctorGroupsRequests'
import { DoctorGroupPeopleNamesRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/requests/DoctorGroupPeopleNamesRequestDTO'
import { DoctorGroupPeopleNamesResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/responses/DoctorGroupPeopleNamesResponseDTO'
import { UserDoctorGroupStatusEnum } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/enums/UserDoctorGroupStatusEnum'
import { UserTypeEnum, UserTypeLabelEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { SelectFullOptionTP, SelectOptionTP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/inner/types/SelectOptionTP'
import { HelpTP } from 'submodules/nerit-framework-ui/common/components/help/inner/HelpTP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

interface ICPProps extends BasicStyleWrapperCPProps {
	doctorGroupCode: number
	formStateManager?: IFormStateManager<any>
	formField?: string
	role: UserTypeEnum
	label?: string
	value?: number[] | number
	alreadyLinkedPersonCodes?: number[]
	namesConfig?: {
		showSpecialty?: boolean
		showTeam?: boolean
	}
	disabled?: boolean
	required?: boolean
	onChange?: (code: number | number[]) => void
	isMultiple?: boolean
	width?: number
	hint?: HelpTP
	allowClear?: boolean
	filters?: {
		isAuditor?: boolean
		teamCode?: number
		status?: UserDoctorGroupStatusEnum[]
	}
}

/**
 * Select com os medicos ou usuarios de um grupo.
 */
export function SelectDoctorGroupPeopleCP(props: ICPProps): JSX.Element {
	const [options, setOptions] = useState<SelectOptionTP[]>([])
	const doctorGroupDoctorPeopleRequest = useRequest<ListResponseDTO<DoctorGroupPeopleNamesResponseDTO>>()
	useEffect(onGetDoctorGroupPeopleRequestChange, [doctorGroupDoctorPeopleRequest.isAwaiting])

	useEffect(init, [props.doctorGroupCode, props.filters?.teamCode])

	/**
	 * Carrega todos os medicos que existem no grupo.
	 */
	function init(): void {
		setOptions([])
		if (!props.doctorGroupCode) return

		const dto: DoctorGroupPeopleNamesRequestDTO = {
			doctorGroupCode: props.doctorGroupCode,
			role: props.role,
			isAuditor: props.filters?.isAuditor,
			teamCode: props.filters?.teamCode,
		}
		doctorGroupDoctorPeopleRequest.runRequest(DoctorGroupsRequests.namesPeople(dto))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetDoctorGroupPeopleRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(doctorGroupDoctorPeopleRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = doctorGroupDoctorPeopleRequest.responseData!.list

		// Monta as opcoes retirando os medicos que ja estao vinculados
		const _options: SelectFullOptionTP[] = []
		result.forEach((user) => {
			if (!props.alreadyLinkedPersonCodes?.includes(user.code)) {
				_options.push({
					value: user.code,
					label: <SelectLabelDoctorGroupPeopleICP user={user} namesConfig={props.namesConfig} doctorGroupCode={props.doctorGroupCode} />,
				})
			}
		})
		setOptions(_options)
	}

	return (
		<TooltipCP
			text={!props.doctorGroupCode ? 'Selecione primeiro um Grupo de Médicos para poder ver os médicos do grupo' : undefined}
			showSpan={!props.doctorGroupCode}
		>
			<SelectCP
				value={props.value}
				width={props.width}
				label={props.label ?? UserTypeLabelEnum[props.role]}
				formStateManager={props.formStateManager}
				fieldName={props.formField}
				required={props.required}
				options={options}
				disabled={doctorGroupDoctorPeopleRequest.isAwaiting || !props.doctorGroupCode || props.disabled}
				loading={doctorGroupDoctorPeopleRequest.isAwaiting}
				allowClear={props.allowClear ?? true}
				onChange={props.onChange}
				isMultiple={props.isMultiple}
				marginTop={props.margin?.top}
				marginBottom={props.margin?.bottom}
				marginLeft={props.margin?.left}
				hint={props.hint}
				marginRight={props.margin?.right}
				filterOption={(filtered, eachElement) => {
					if (!eachElement.props.children || !filtered) return true

					const eachUserName = (eachElement.props.children as any)?.props?.user?.name
					if (!eachUserName) return true

					return StringUtils.removeAccents(eachUserName).toLowerCase().includes(StringUtils.removeAccents(filtered).toLowerCase())
				}}
			/>
		</TooltipCP>
	)
}
