import React from 'react'
import { Icon as LegacyIcon } from '@ant-design/compatible'

import { IIconProps } from 'submodules/nerit-framework-ui/common/components/icon/inner/IIconProps'

/**
 * ICONE: 'Checar'
 *
 * @author hjcostabr
 */
export const CheckIconCP = (props: IIconProps): JSX.Element => {
	return (
		<LegacyIcon
			type={'check'}
			style={{
				color: props.color ?? 'inherit',
				fontSize: props.size,
				...props.style,
			}}
		/>
	)
}
