import { IsNumber, IsOptional, IsString } from 'class-validator'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { MedicalReportContentTP } from 'submodules/beerads-sdk/services/exams/medical-reports/types/MedicalReportContentTP'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'

export class TemplateFormModel extends FormModel {
	@IsRequired()
	name: string

	@IsRequired()
	modalities: ExamModalityEnum[] | ExamModalityEnum

	@IsOptional()
	examDescription: string

	@IsRequired()
	content: MedicalReportContentTP | string

	@IsOptional()
	@IsNumber()
	doctorGroupCode?: number

	@IsOptional()
	@IsString()
	contentHtml?: string

	constructor(initialData?: ObjectPropsTP<TemplateFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
