import styled from 'styled-components'
import { TextAndTitleCP } from 'submodules/nerit-framework-ui/common/components/text/TextAndTitleCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'

const isDark = localStorage.getItem('theme') === 'dark'

export const CustomTextAndTitle = styled(TextAndTitleCP)`
	align-items: center;

	a {
		color: ${isDark ? ThemeProject.gray100 : ThemeProject.gray600};

		&:hover {
			color: ${isDark ? ThemeProject.primary300 : ThemeProject.secondary};
		}
	}
`
