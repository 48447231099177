import { Empty } from 'antd'

interface IEmptyCPProps {
	description?: string
}

/**
 * Componente de resultados vazios do sistema
 */
export function EmptyCP(props: IEmptyCPProps): JSX.Element {
	return <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description={<span>{props.description ? props.description : 'Nenhum dado encontrado'}</span>} />
}
