import { useAppSelector } from 'app/redux/hook'
import { ModalMedicalTimetableAbsenceCP } from 'modules/medical-timetable/components/modals/modal-medical-timetable-absence/ModalMedicalTimetableAbsenceCP'
import { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'

interface ICPProps {
	onSave: () => void
}

export function ButtonMedicalTimetableAbscenceCP(props: ICPProps): JSX.Element {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
	const { code: doctorGroupCode } = useAppSelector((selector) => selector.doctorGroup)

	return (
		<>
			<ButtonCP icon={'solution'} marginRight={4} onClick={() => setIsModalVisible(true)}>
				Ausências na escala
			</ButtonCP>

			{isModalVisible && doctorGroupCode && (
				<ModalMedicalTimetableAbsenceCP
					isModalVisible={isModalVisible}
					doctorGroupCode={doctorGroupCode}
					onClose={() => setIsModalVisible(false)}
					onSave={() => {
						setIsModalVisible(false)
						props.onSave()
					}}
				/>
			)}
		</>
	)
}
