import { IsOptional, IsString, IsUUID } from 'class-validator'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'

export class CreateReportPhysicianRequestDTO {
	@IsString()
	@IsRequired()
	transcription: string

	@IsString()
	@IsRequired()
	@IsUUID()
	examId: string

	@IsOptional()
	@IsString()
	title?: string

	@IsOptional()
	@IsString()
	method?: string

	@IsOptional()
	@IsString()
	anamnesis?: string

	@IsString()
	@IsOptional()
	previousContent?: string
}
