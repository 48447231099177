import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { FeedbackRequestDTO } from 'submodules/beerads-sdk/services/feedbacks/dtos/request/FeedbackRequestDTO'
import { FeedbackResponseDTO } from 'submodules/beerads-sdk/services/feedbacks/dtos/response/FeedbackReponseDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'

export class FeedbacksRequests {
	public static ROOT = 'feedbacks'
	public static SEARCH_EP = 'search'
	public static CREATE_FEEDBACK_EP = 'create-feedback'
	public static GET_FEEDBACK_EP = 'get-feedback/:code'

	public static search = <T>(): RequestConfigTP<ListResponseDTO<FeedbackResponseDTO<T>>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${FeedbacksRequests.ROOT}/${FeedbacksRequests.SEARCH_EP}`,
		method: HttpMethodEnum.GET,
	})

	public static getFeedback = <T>(code: number): RequestConfigTP<FeedbackResponseDTO<T>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${FeedbacksRequests.ROOT}/${FeedbacksRequests.GET_FEEDBACK_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.GET,
	})

	public static createFeedback = <T>(dto: FeedbackRequestDTO<T>): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${FeedbacksRequests.ROOT}/${FeedbacksRequests.CREATE_FEEDBACK_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})
}
