import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { RoutingHelper } from 'config/RoutingHelper'
import * as _ from 'lodash-es'
import { ModalConfirmStartExamCP } from 'modules/exams/components/exams/modals/modal-confirm-start-exam/ModalConfirmStartExamCP'
import { ButtonsFloatingMedicalReportCP } from 'modules/exams/components/medical-report/buttons-floating-medical-report/ButtonsFloatingMedicalReportCP'
import { MedicalReportLocalStorageUtil } from 'modules/exams/components/medical-report/status-save-report-local-storage/inner/MedicalReportLocalStorageUtil'
import { EXAM_SCREEN_FILTERS_PARAM } from 'modules/exams/screens/screen-exams/ScreenExams'
import { ScreenContentMedicalReportCP } from 'modules/exams/screens/screen-medical-report/content/ScreenContentMedicalReportCP'
import { HeaderMedicalReportScreenCP } from 'modules/exams/screens/screen-medical-report/header/HeaderMedicalReportScreenCP'
import { MedicalReportFormModel } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModel'
import { SiderLeftMedicalReportCP } from 'modules/exams/screens/screen-medical-report/siders/SiderLeftMedicalReportCP'
import { SiderRightMedicalReport } from 'modules/exams/screens/screen-medical-report/siders/SiderRightMedicalReport'
import { useEffect, useState } from 'react'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ExamModalityLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { ExamsRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import { ExamStatusUtils } from 'submodules/beerads-sdk/services/exams/exams/utils/ExamStatusUtils'
import { StartMedicalReportResponseDTO } from 'submodules/beerads-sdk/services/exams/medical-reports/dtos/response/StartMedicalReportResponseDTO'
import { MedicalReportRequests } from 'submodules/beerads-sdk/services/exams/medical-reports/MedicalReportRequests'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

/**
 */
export function ScreenMedicalReport(props: RouteComponentProps): JSX.Element {
	const routeLocation = useLocation()
	const routeParams = _.get(props, 'match.params') as { examId: string }
	const examId: string = routeParams.examId

	const searchExamQueryFiltersStr = new URLSearchParams(routeLocation.search).get(EXAM_SCREEN_FILTERS_PARAM)
	let searchExamQueryFilters: ExamSearchRequestDTO | undefined
	if (!!searchExamQueryFiltersStr) searchExamQueryFilters = JSON.parse(searchExamQueryFiltersStr)

	const [formModel, setFormModel] = useState<MedicalReportFormModel>(new MedicalReportFormModel({ medicalReports: [{} as any] }))
	const formStateManager = useFormStateManager(formModel)

	const [startExamConfirmModalVisible, setStartExamConfirmModalVisible] = useState<boolean>(false)
	const [isEmbeddedViewerVisible, setIsEmbeddedViewerVisible] = useState<boolean>(false)

	const checkExamStatusRequest = useRequest<ExamStatusEnum>()
	useEffect(onCheckExamStatusRequestChange, [checkExamStatusRequest.isAwaiting])

	const [medicalReportData, setMedicalReportData] = useState<StartMedicalReportResponseDTO>()
	const startMedicalReportRequest = useRequest<StartMedicalReportResponseDTO>()
	useEffect(onStartMedicalReportRequestChange, [startMedicalReportRequest.isAwaiting])

	useEffect(init, [examId])

	/**
	 * Inicializa
	 */
	function init(): void {
		setMedicalReportData(undefined)

		if (!examId) {
			RoutingHelper.goToExams(undefined, searchExamQueryFilters)
			return
		}
		checkExamStatusRequest.runRequest(ExamsRequests.getExamStatus(examId))
	}

	/**
	 */
	function onCheckExamStatusRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(checkExamStatusRequest, 'Erro ao buscar status do exame')) return

		const examStatus: ExamStatusEnum = checkExamStatusRequest.responseData!

		// Para status em progresso, libera para laudar
		if (ExamStatusUtils.examStatusOfExamStatusGroupMap.get(ExamStatusGroupEnum.AWAITING)?.includes(examStatus)) {
			startMedicalReport()
			return
		}

		// Para status concluido, mostra aviso
		if ([ExamStatusEnum.DONE, ExamStatusEnum.DONE_RECTIFICATION].includes(examStatus)) {
			setStartExamConfirmModalVisible(true)
			return
		}

		// Caso contrario já redireciona para listagem
		NotificationHelper.error('Ops', 'Esse exame não está em status passível de alteração')
		RoutingHelper.goToExams(undefined, searchExamQueryFilters)
	}

	/**
	 */
	function startMedicalReport(): void {
		startMedicalReportRequest.runRequest(MedicalReportRequests.start(examId))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onStartMedicalReportRequestChange(): void {
		if (startMedicalReportRequest.isAwaiting || !startMedicalReportRequest.wasTried) return

		if (!RequestUtils.isValidRequestReturn(startMedicalReportRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) {
			RoutingHelper.goToExams(undefined, searchExamQueryFilters)
			return
		}

		const result = startMedicalReportRequest.responseData!
		setMedicalReportData(result)
		MedicalReportLocalStorageUtil.initMedicalReport(result.exam.code)

		setFormModel(
			new MedicalReportFormModel({
				isCritical: result.exam.isCritical,
				medicalReports: ArrayUtils.isEmpty(result.medicalReports) ? [{} as any] : result.medicalReports,
			}),
		)
	}

	if (checkExamStatusRequest.isAwaiting) return <LoadingOverlayCP show={true} />

	if (startExamConfirmModalVisible) {
		return (
			<ModalConfirmStartExamCP
				visible={startExamConfirmModalVisible}
				onGoBack={() => RoutingHelper.goToExams(undefined, searchExamQueryFilters)}
				onEditMedicalReport={() => {
					setStartExamConfirmModalVisible(false)
					startMedicalReport()
				}}
			/>
		)
	}

	if (startMedicalReportRequest.isAwaiting || !startMedicalReportRequest.wasTried) return <LoadingOverlayCP show={true} />

	if (!medicalReportData) return <AlertCP message={'Não encontramos o exame'} type={'error'} />

	return (
		<AccessControlCP
			permission={{ byHasAnyClinicRelationPermission: ['isDoctor'] }}
			redirect={{ shouldRedirect: true, redirectTo: PrivateUserRouter.EXAMS.replace(':examStatusGroup', ExamStatusGroupEnum.AWAITING) }}
		>
			<LayoutCP
				overflowVertical={true}
				titleBrowser={`${StringUtils.getFirstName(medicalReportData.exam.patient.name)} [${
					ExamModalityLabelEnum[medicalReportData.exam.modality]
				}] ${medicalReportData.exam.description}`}
				header={
					<HeaderMedicalReportScreenCP
						exam={medicalReportData.exam}
						formStateManager={formStateManager}
						searchExamDto={searchExamQueryFilters}
					/>
				}
				sider={
					!isEmbeddedViewerVisible && (
						<SiderLeftMedicalReportCP exam={medicalReportData.exam} pendingAnalysisHistories={medicalReportData.pendingAnalysisHistories} />
					)
				}
				content={
					<ScreenContentMedicalReportCP
						exam={medicalReportData.exam}
						formStateManager={formStateManager}
						searchExamDto={searchExamQueryFilters}
					/>
				}
				rightSider={
					<>
						<SiderRightMedicalReport
							exam={medicalReportData.exam}
							formStateManager={formStateManager}
							embeddedViewer={{
								isViewerEmbedded: isEmbeddedViewerVisible,
								onEmbedViewer: setIsEmbeddedViewerVisible,
							}}
							searchExamDto={searchExamQueryFilters}
						/>

						<ButtonsFloatingMedicalReportCP
							exam={medicalReportData.exam}
							formStateManager={formStateManager}
							onEmbedViewer={() => setIsEmbeddedViewerVisible(true)}
							searchExamDto={searchExamQueryFilters}
						/>
					</>
				}
			/>
		</AccessControlCP>
	)
}
