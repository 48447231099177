import { ReactQueryProvider } from '@/providers/react-query-provider/ReactQueryProvider'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ConfigProvider as AntDProvider } from 'antd'
import ptBR from 'antd/locale/pt_BR'
import { BeeRadsTitleBrowserCP } from 'app/components/beerads-title-browser/BeeRadsTitleBrowserCP'
import { LoadingVxCP } from 'app/components/loading-vx/LoadingVxCP'
import { store } from 'app/redux/store'
import { AppRouter } from 'app/routers/AppRouter'
import { themeConfig } from 'config/theme/ant/theme-config'
import { ThemeProvider } from 'providers/theme-provider/ThemeProvider'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { EmptyCP } from 'submodules/nerit-framework-ui/common/components/empty/EmptyCP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import './style.css'

/**
 * Componente inicial do sistema.
 */

export function App(): JSX.Element {
	return (
		<ReduxProvider store={store}>
			<PersistGate loading={<LoadingOverlayCP show={true} customLogo={<LoadingVxCP />} />} persistor={persistStore(store)}>
				<ThemeProvider defaultTheme="light" storageKey="theme">
					<AntDProvider locale={ptBR} theme={themeConfig} renderEmpty={() => <EmptyCP />}>
						<BrowserRouter>
							<ReactQueryProvider>
								<BeeRadsTitleBrowserCP />
								<AppRouter />
								<ReactQueryDevtools initialIsOpen={false} buttonPosition="top-right" />
							</ReactQueryProvider>
						</BrowserRouter>
					</AntDProvider>
				</ThemeProvider>
			</PersistGate>
		</ReduxProvider>
	)
}
