interface ICPProps {
	url: string
	type?: 'video/webm' | 'video/mp4'
}

/**
 */
export function VideoCP(props: ICPProps): JSX.Element {
	return (
		<video controls={true} autoPlay={true} loop={false} width={'100%'}>
			<source src={props.url} type={props.type} />
		</video>
	)
}
