import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import * as S from './SpeakWithWindStyles'

export interface ISpeakWithWindCPProps {
	content?: string
	isActive: boolean
	setVisibility: (isVisible: boolean) => void
	isVisible?: boolean
}

export function SpeakWithWindCP(props: ISpeakWithWindCPProps): JSX.Element {
	const [modalVisibility, setModalVisibility] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			setModalVisibility(true)
		}, 100)

		// Função de limpeza que será executada quando o componente for desmontado
		return () => clearTimeout(timer)
	}, [])

	return createPortal(
		<S.Overlay>
			<S.Modal isVisible={modalVisibility && !!props.content}>
				<S.ModalContent onClick={(e) => e.stopPropagation()}>
					<S.CloseButton onClick={() => props.setVisibility(false)}>&times;</S.CloseButton>
					<S.TextWrapper>
						{!props.content && <TextCP text="Fale no microfone para interagir com a VX-Wind" color="#d6d6d6" />}
						<TextCP text={props.content} wrap />
					</S.TextWrapper>
				</S.ModalContent>

				<S.FooterWrapperSCP isVisible={!!props.content}>
					<FlexCP gap={10}>
						<ButtonCP
							icon="copy"
							type="link"
							onClick={() => {
								navigator.clipboard.writeText(props.content ? props.content : '')
								NotificationHelper.success('Copiado com sucesso!')
							}}
						/>
					</FlexCP>
				</S.FooterWrapperSCP>
			</S.Modal>
		</S.Overlay>,
		document.body,
	)
}
