import { IntercomCP } from 'app/components/intercom/IntercomCP'
import { LoadingVxCP } from 'app/components/loading-vx/LoadingVxCP'
import { ModalVxPromoteCP } from 'app/components/modal-vx-promote/ModalVxPromoteCP'
import { UserMainMenuCP } from 'app/components/user-main-menu/UserMainMenuCP'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { useEffect, useState } from 'react'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { LayoutUserMainCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-user-main/LayoutUserMainCP'
import { TopBarHomEnvironmentCP } from 'submodules/nerit-framework-ui/common/components/system/top-bar-hom-environment/TopBarHomEnvironmentCP'
import { TopBarPasswordExpirationCP } from 'submodules/nerit-framework-ui/common/components/system/top-bar-password-expiration/TopBarPasswordExpirationCP'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'

/**
 */
export function ScreenPrivateUser(): JSX.Element {
	const [shouldReload, setShouldReload] = useState<boolean>(false)

	useEffect(onReload, [shouldReload])

	/**
	 */
	function onReload(): void {
		if (!shouldReload) return

		// Faz uma pausa para recarregar a tela pois alterou a variavel para true <-> false
		SystemUtils.sleep(500).then(() => {
			setShouldReload(false)
		})
	}

	if (shouldReload) {
		return <LoadingOverlayCP show={true} customLogo={<LoadingVxCP />} />
	}

	return (
		<LayoutUserMainCP
			menu={<UserMainMenuCP onSelectMenuItem={() => {}} onChangeCurrentDoctorGroup={() => setShouldReload(true)} />}
			privateRouter={
				<>
					<TopBarHomEnvironmentCP />
					<TopBarPasswordExpirationCP />
					<PrivateUserRouter />
				</>
			}
			extraContent={
				<>
					<IntercomCP forceShow={true} />
					<ModalVxPromoteCP />
				</>
			}
		/>
	)
}
