import { PublicRouter } from 'app/routers/public/PublicRouter'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import md5 from 'md5'
import { FormDoctorRegisterCP } from 'modules/person/components/doctor/form-doctor-register/FormDoctorRegisterCP'
import { DoctorRegisterFormModel } from 'modules/person/components/doctor/form-doctor-register/inner/DoctorRegisterFormModel'
import { useEffect, useState } from 'react'
import { PersonDoctorSaveRequestDTO } from 'submodules/beerads-sdk/services/people/people/dtos/request/PersonDoctorSaveRequestDTO'
import { PeopleRequests } from 'submodules/beerads-sdk/services/people/people/PeopleRequests'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { LayoutImageSiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-image-sider/LayoutImageSiderCP'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'

/**
 * Cadastro de Medico
 */
export function ScreenDoctorRegister(): JSX.Element {
	const [formValidator] = useState<DoctorRegisterFormModel>(new DoctorRegisterFormModel())
	const formStateManager = useFormStateManager<DoctorRegisterFormModel>(formValidator)

	const saveDoctorRequest = useRequest<void>('none')
	useEffect(onSaveDoctorRequestChange, [saveDoctorRequest.isAwaiting])

	/**
	 * Salva pessoa
	 */
	async function save(): Promise<void> {
		formStateManager.debugErrors()
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) return

		const formValues = formStateManager.getFormValues()!
		if (formValues.password !== formValues.confirmPassword) {
			NotificationHelper.error('Ops', 'As senhas não estão iguais')
			return
		}

		const dto: PersonDoctorSaveRequestDTO = {
			name: formValues.name,
			crm: formValues.crm,
			cpf: formValues.cpf,
			email: formValues.email,
			phoneOne: MaskUtils.removeMask(formValues.phoneOne)!,
			specialty: formValues.specialty,
			birthDate: DateUtils.toDate(formValues.birthDate, DateFormatEnum.BR_WITHOUT_TIME),
			treatment: formValues.treatment,
			doctorData: {
				ids: [
					{
						id: formValues.crm,
						type: formValues.idType,
					},
				],
			},
			password: md5(formValues.password),
		}

		saveDoctorRequest.runRequest(PeopleRequests.createDoctor(dto))
	}

	/**
	 * Apos reotorno da api de salvar
	 */
	function onSaveDoctorRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(saveDoctorRequest, 'Ocorreu algum erro ao salvar', undefined, true)) return

		formStateManager.reset(undefined)
		NeritFrameworkRoutingHelper.historyPush(PublicRouter.USER_LOGIN)
	}

	return (
		<LayoutImageSiderCP
			sider={{
				width: '50%',
				imageUrl: '/images/brCover3.jpg',
			}}
			content={{
				logoUrl: ThemeProject.logoLoginUrl,
				logoWidth: 300,
			}}
		>
			<>
				<FormDoctorRegisterCP formStateManager={formStateManager} />

				<FlexCP justify={'space-between'} margin={{ top: 30 }}>
					<ButtonCP onClick={() => NeritFrameworkRoutingHelper.historyPush(PublicRouter.USER_LOGIN)}>Voltar para login</ButtonCP>
					<ButtonCP onClick={save} loading={saveDoctorRequest.isAwaiting} type={'primary'}>
						Cadastrar
					</ButtonCP>
				</FlexCP>
			</>
		</LayoutImageSiderCP>
	)
}
