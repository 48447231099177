import * as React from 'react'
import { tv } from 'tailwind-variants'

const TableVariant = tv({
	base: 'w-full caption-bottom text-sm',
})

const Table = React.forwardRef<HTMLTableElement, React.HTMLAttributes<HTMLTableElement>>(({ className, ...props }, ref) => (
	<div className="relative w-full overflow-auto">
		<table ref={ref} className={TableVariant({ className })} {...props} />
	</div>
))
Table.displayName = 'Table'

const TableHeaderVariant = tv({
	base: '[&_tr]:border-b',
})
const TableHeader = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(({ className, ...props }, ref) => (
	<thead ref={ref} className={TableHeaderVariant({ className })} {...props} />
))
TableHeader.displayName = 'TableHeader'

const TableBodyVariant = tv({
	base: '[&_tr:last-child]:border-0',
})
const TableBody = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(({ className, ...props }, ref) => (
	<tbody ref={ref} className={TableBodyVariant({ className })} {...props} />
))
TableBody.displayName = 'TableBody'

const TableFooterVariant = tv({
	base: 'border-t bg-muted/50 font-medium [&>tr]:last:border-b-0',
})
const TableFooter = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(({ className, ...props }, ref) => (
	<tfoot ref={ref} className={TableFooterVariant({ className })} {...props} />
))
TableFooter.displayName = 'TableFooter'

const TableRowVariant = tv({
	base: 'border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted',
})
const TableRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(({ className, ...props }, ref) => (
	<tr ref={ref} className={TableRowVariant({ className })} {...props} />
))
TableRow.displayName = 'TableRow'

const TableHeadVariant = tv({
	base: 'text-muted-foreground h-12 px-4 text-left align-middle font-medium [&:has([role=checkbox])]:pr-0',
})
const TableHead = React.forwardRef<HTMLTableCellElement, React.ThHTMLAttributes<HTMLTableCellElement>>(({ className, ...props }, ref) => (
	<th ref={ref} className={TableHeadVariant({ className })} {...props} />
))
TableHead.displayName = 'TableHead'

const TableCellVariant = tv({
	base: 'p-4 align-middle [&:has([role=checkbox])]:pr-0',
})
const TableCell = React.forwardRef<HTMLTableCellElement, React.TdHTMLAttributes<HTMLTableCellElement>>(({ className, ...props }, ref) => (
	<td ref={ref} className={TableCellVariant({ className })} {...props} />
))
TableCell.displayName = 'TableCell'

const TableCaptionVariant = tv({
	base: 'text-muted-foreground mt-4 text-sm',
})
const TableCaption = React.forwardRef<HTMLTableCaptionElement, React.HTMLAttributes<HTMLTableCaptionElement>>(({ className, ...props }, ref) => (
	<caption ref={ref} className={TableCaptionVariant({ className })} {...props} />
))
TableCaption.displayName = 'TableCaption'

export { Table, TableBody, TableCaption, TableCell, TableFooter, TableHead, TableHeader, TableRow }
