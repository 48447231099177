import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

const theme = localStorage.getItem('theme')

export const EditorWrapper = styled.div<{ noBorder?: boolean }>`
	border: ${(props) => (!props.noBorder ? '1px solid ' + ThemeFrameworkCommon.browserDefaultBackgroundDark + '33' : '0 none')};
	box-shadow: ${(props) => (!props.noBorder ? '0px 2px 5px' + ThemeFrameworkCommon.browserDefaultBackgroundDark + '0D' : 'none')};
	color: ${ThemeFrameworkCommon.browserDefaultColorDark};
	border-radius: 0px 0px 5px 5px;
	background-color: ${theme === 'light' ? '#e5e5e5' : ''};
	position: relative;
	padding: 10px;

	&.editor-fullscreen {
		position: fixed;
		width: 100%;
		left: 0;
		top: 0;
		height: 100%;
		box-shadow: 0 2px 12px ${ThemeFrameworkCommon.browserDefaultBackgroundDark}4D;
		right: 0;
		margin: 0 auto;
		z-index: 9;
	}
`
