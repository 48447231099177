import { ThemeProjectVX } from 'config/theme/project/white-labels/ThemeProjectVX'
import { useEffect, useState } from 'react'
import { FeedbacksRequests } from 'submodules/beerads-sdk/services/feedbacks/FeedbacksRequests'
import { FeedbackRequestDTO } from 'submodules/beerads-sdk/services/feedbacks/dtos/request/FeedbackRequestDTO'
import { FeedbackEventEnum } from 'submodules/beerads-sdk/services/feedbacks/enum/FeedbackEventEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FeedbackFormModel } from 'submodules/nerit-framework-ui/common/components/feedback/inner/FeedbackFormModel'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { RateCP } from 'submodules/nerit-framework-ui/common/components/rate/RateCP'
import { ResultCP } from 'submodules/nerit-framework-ui/common/components/result/ResultCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import * as S from './FeedbackStyles'

interface ICPProps<T> {
	title: string
	subTitle: string
	feedbackData: T
	event: FeedbackEventEnum
}

export function FeedBackCP<T>({ title, subTitle, feedbackData, event }: ICPProps<T>): JSX.Element {
	const [isSuccess, setIsSuccess] = useState(false)
	const [formModel, setFormModel] = useState(new FeedbackFormModel<T>({}))
	const [score, setScore] = useState<number>()
	const formStateManager = useFormStateManager<FeedbackFormModel<T>>(formModel)
	const createFeedbackRequest = useRequest<FeedbackRequestDTO<T>>('none')

	useEffect(init, [])
	useEffect(onCreateFeedbackRequestChange, [createFeedbackRequest.isAwaiting])

	function init(): void {
		setFormModel(
			new FeedbackFormModel<T>({
				metadata: feedbackData,
				event: event,
			}),
		)
	}

	async function handleCreateFeedback() {
		formStateManager.changeFieldValue('score', score)

		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) {
			return
		}

		const formValues = formStateManager.getFormValues()

		if (formValues) {
			createFeedbackRequest.runRequest(FeedbacksRequests.createFeedback<T>(formValues))
		}
	}

	function onCreateFeedbackRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(createFeedbackRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE)) {
			setIsSuccess(false)
			return
		}

		setIsSuccess(true)
	}

	if (!isSuccess) {
		return (
			<S.WrapperRating fadeOut={isSuccess}>
				<FlexCP justify={'center'} flexDirection="column" gap={'16px'}>
					<S.TextWrapperSCP>
						<TextCP text={title} style={{ fontWeight: 600 }} size="extraLarge" />
						<TextCP text={subTitle} style={{ fontWeight: 500, color: ThemeProjectVX.gray500 }} />
					</S.TextWrapperSCP>

					<RateCP showTooltips={true} value={(value) => setScore(value)} />

					<TextAreaCP placeholder={'Adicionar comentários'} formStateManager={formStateManager} fieldName="comments" />

					<FlexCP justify="flex-end">
						<ButtonCP type="primary" onClick={() => handleCreateFeedback()}>
							Enviar feedback
						</ButtonCP>
					</FlexCP>
				</FlexCP>
			</S.WrapperRating>
		)
	}

	return (
		<S.WrapperSuccessRating fadeIn={isSuccess}>
			<ResultCP status="success" title="Obrigado!" />
		</S.WrapperSuccessRating>
	)
}
