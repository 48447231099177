import dayjs from 'dayjs'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import {
	FastFilterMonthEnum,
	FastFilterMonthLabelEnum,
} from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/fast-filter-month/inner/FastFilterMonthEnum'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'

interface IProps {
	onFilterSelection: (dates: IDateRangeFilter) => void
}

/**
 */
export const FastFilterMonthICP = (props: IProps): JSX.Element => {
	function onFilterClick(filterType: FastFilterMonthEnum): void {
		const today = dayjs()

		let startDate: dayjs.Dayjs
		let endDate: dayjs.Dayjs

		switch (filterType) {
			case FastFilterMonthEnum.LAST_3_MONTHS:
				startDate = today.add(-2, 'm')
				endDate = today.clone()
				break

			case FastFilterMonthEnum.LAST_6_MONTHS:
				startDate = today.add(-5, 'month')
				endDate = today.clone()
				break

			case FastFilterMonthEnum.LAST_12_MONTHS:
				startDate = today.add(-11, 'month')
				endDate = today.clone()
				break

			case FastFilterMonthEnum.THIS_YEAR:
				startDate = today.clone().set('month', 0)
				endDate = today.clone().set('month', 12)
				break
		}

		const dateFilter: IDateRangeFilter = {
			beginDate: startDate.startOf('month').toDate(),
			endDate: endDate.endOf('month').toDate(),
		}

		props.onFilterSelection(dateFilter)
	}

	return (
		<div style={{ paddingBottom: 5, paddingTop: 10 }}>
			<ButtonGroupCP mode={'separeted'}>
				{Object.values(FastFilterMonthEnum).map((fastFilter, index) => (
					<ButtonCP key={FastFilterMonthEnum[index]} onClick={() => onFilterClick(fastFilter)} size={'small'}>
						{FastFilterMonthLabelEnum[fastFilter]}
					</ButtonCP>
				))}
			</ButtonGroupCP>
		</div>
	)
}
