import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { useEffect, useState } from 'react'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { DateRangePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/DateRangePickerCP'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { LayoutSubmenuContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/content/LayoutSubmenuContentCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { CardsProjectsNpsCP } from 'submodules/space4leads-components-ui/report/components/cards-projetcs-nps/CardsProjectsNpsCP'

/**
 */
export function ScreenContentNpsReportCP(): JSX.Element {
	const [reload, setReload] = useState<number>(1)

	const [dateFilters, setDateFilters] = useState<IDateRangeFilter>()
	useEffect(() => setReload(TableUtils.getReloadNumber()), [dateFilters])

	return (
		<AccessControlCP
			permission={{ byHasAnyPermissionInDoctorGroup: { doctorGroupCode: 142, permissions: ['isDoctorGroupUser', 'isDoctorGroupAdmin'] } }}
			redirect={{ shouldRedirect: true, redirectTo: PrivateUserRouter.HOME }}
		>
			<HeaderCP title={'Pesquisa de Satisfação (NPS)'} />
			<FlexCP justify={'center'}>
				<CardCP innerSpacing={'small'}>
					<DateRangePickerCP value={dateFilters} onChange={setDateFilters} fastFilter={'all'} allowClear={false} />
				</CardCP>
			</FlexCP>

			<LayoutSubmenuContentCP>
				<CardsProjectsNpsCP accountCode={134} dateRange={dateFilters} reload={reload} />
			</LayoutSubmenuContentCP>
		</AccessControlCP>
	)
}
