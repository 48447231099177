import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import * as _ from 'lodash-es'
import { ButtonsFloatingMedicalReportCP } from 'modules/exams/components/medical-report/buttons-floating-medical-report/ButtonsFloatingMedicalReportCP'
import { ScreenContentAuditCP } from 'modules/quality-assurance/screens/screen-audit/content/ScreenContentAuditCP'
import { HeaderAuditScreenCP } from 'modules/quality-assurance/screens/screen-audit/header/HeaderAuditScreenCP'
import { AuditFormModel } from 'modules/quality-assurance/screens/screen-audit/inner/AuditFormModel'
import { SiderLeftAuditCP } from 'modules/quality-assurance/screens/screen-audit/siders/SiderLeftAuditCP'
import { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AuditsRequests } from 'submodules/beerads-sdk/services/exams/audits/AuditsRequests'
import { StartAuditResponseDTO } from 'submodules/beerads-sdk/services/exams/audits/dtos/response/StartAuditResponseDTO'
import { ExamModalityLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

/**
 */
export function ScreenAudit(props: RouteComponentProps): JSX.Element {
	const [formModel, setFormModel] = useState<AuditFormModel>(new AuditFormModel())
	const formStateManager = useFormStateManager(formModel)

	const [audit, setAudit] = useState<StartAuditResponseDTO>()
	const startAuditRequest = useRequest<StartAuditResponseDTO>()
	useEffect(onStartAuditRequestChange, [startAuditRequest.isAwaiting])

	useEffect(init, [])

	/**
	 * Inicializa
	 */
	function init(): void {
		const routeParams = _.get(props, 'match.params') as { auditCode: string }
		const auditCode = Number(routeParams.auditCode)
		if (!auditCode) return NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.DOCTOR_AUDITS)

		startAuditRequest.runRequest(AuditsRequests.start(auditCode))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onStartAuditRequestChange(): void {
		if (startAuditRequest.isAwaiting || !startAuditRequest.wasTried) return

		if (!RequestUtils.isValidRequestReturn(startAuditRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) {
			NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.DOCTOR_AUDITS)
			return
		}

		const result = startAuditRequest.responseData!
		setAudit(result)

		setFormModel(
			new AuditFormModel({
				comments: result.audit.comments,
				score: result.audit.score,
			}),
		)
	}

	if (startAuditRequest.isAwaiting) return <LoadingOverlayCP show={true} />

	if (!audit) return <AlertCP message={'Não encontramos a auditoria'} type={'error'} />

	return (
		<AccessControlCP
			permission={{ byHasAnyDoctorGroupRelationPermission: ['isAuditor'] }}
			redirect={{ shouldRedirect: true, redirectTo: PrivateUserRouter.EXAMS.replace(':examStatusGroup', ExamStatusGroupEnum.AWAITING) }}
		>
			<LayoutCP
				titleBrowser={`Auditoria - ${StringUtils.getFirstName(audit.exam.patient.name)} [${ExamModalityLabelEnum[audit.exam.modality]}] - ${
					audit.exam.description
				}]`}
				overflowVertical={true}
				header={<HeaderAuditScreenCP auditData={audit} formStateManager={formStateManager} />}
				sider={<SiderLeftAuditCP exam={audit.exam} />}
				content={<ScreenContentAuditCP exam={audit.exam} formStateManager={formStateManager} />}
				rightSider={
					<ButtonsFloatingMedicalReportCP
						exam={audit.exam}
						buttonsConfig={{
							hideTemplates: true,
						}}
					/>
				}
			/>
		</AccessControlCP>
	)
}
