import { ButtonMedicalTimetableUnassignSpotCP } from '@/modules/medical-timetable/components/buttons/button-medical-timetable-unassign/inner/ButtonMedicalTimetableUnassignSpotCP'
import { ButtonMedicalTimetableUnassignRecurrencyCP } from 'modules/medical-timetable/components/buttons/button-medical-timetable-unassign/inner/ButtonMedicalTimetableUnassignRecurrencyCP'
import { isUserAddingAbsenceOnMedicalTimetableTP } from 'modules/medical-timetable/components/modals/modal-medical-timetable-absence/ModalMedicalTimetableAbsenceCP'
import { MedicalTimetableAbsenceFormModel } from 'modules/medical-timetable/components/modals/modal-medical-timetable-absence/inner/MedicalTimetableAbsenceFormModel'
import { useEffect } from 'react'
import { MedicalTimetableRequests } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/MedicalTimetableRequests'
import { DoctorOnMedicalTimetableDeleteRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/DoctorOnMedicalTimetableDeleteRequestDTO'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps {
	doctorGroupCode: number
	isRecurrent?: boolean
	onLoading?: boolean
	isUserAddingAbsenceOnMedicalTimetable: isUserAddingAbsenceOnMedicalTimetableTP
	formStateManager: IFormStateManager<MedicalTimetableAbsenceFormModel>
	onClose: () => void
	onSave: () => void
	onCreateAbsence: () => void
}

/**
 */
export function ButtonMedicalTimetableUnassignCP(props: ICPProps): JSX.Element {
	const deleteDoctorOnMedicalTimetableRequest = useRequest<void>('none')

	useEffect(onDeleteMedicalTimetable, [deleteDoctorOnMedicalTimetableRequest.isAwaiting])

	function handleUnassignDoctor(shouldDeleteRecurrency?: boolean): void {
		const formValues = props.formStateManager.getFormValues()!

		const dto: DoctorOnMedicalTimetableDeleteRequestDTO = {
			dayOfWeek: props.isUserAddingAbsenceOnMedicalTimetable.dayOfWeek,
			doctorGroupCode: props.doctorGroupCode,
			modality: props.isUserAddingAbsenceOnMedicalTimetable.record.modality,
			groupBy: props.isUserAddingAbsenceOnMedicalTimetable.record.groupBy,
			userDoctorCode: formValues.doctorCode,
			period: props.isUserAddingAbsenceOnMedicalTimetable.record.period,
			id: props.isUserAddingAbsenceOnMedicalTimetable.record.id,
			date: props.isUserAddingAbsenceOnMedicalTimetable.date,
			shouldDeleteFollowingDays: shouldDeleteRecurrency,
		}
		deleteDoctorOnMedicalTimetableRequest.runRequest(MedicalTimetableRequests.deleteDoctorsOnMedicalTimetable(dto))
	}

	function onDeleteMedicalTimetable(): void {
		if (!RequestUtils.isValidRequestReturn(deleteDoctorOnMedicalTimetableRequest)) return

		props.onSave()
	}

	return (
		<div className="flex flex-col">
			{props.isRecurrent ? (
				<ButtonMedicalTimetableUnassignRecurrencyCP
					formStateManager={props.formStateManager}
					onLoading={props.onLoading}
					date={props.isUserAddingAbsenceOnMedicalTimetable.date}
					onSave={handleUnassignDoctor}
					onCreateAbsence={props.onCreateAbsence}
				/>
			) : (
				<ButtonMedicalTimetableUnassignSpotCP
					formStateManager={props.formStateManager}
					onLoading={props.onLoading}
					onSave={handleUnassignDoctor}
					onCreateAbsence={props.onCreateAbsence}
				/>
			)}
		</div>
	)
}
