import { RangePickerProps } from 'antd/es/date-picker'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { ConditionalRenderCP } from 'submodules/nerit-framework-ui/common/components/conditional-render/ConditionalRenderCP'
import * as S from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/DateRangePickerStyles'
import { FastFilterMonthICP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/fast-filter-month/FastFilterMonthICP'
import { FastDateRangeFilterTP, FastFilterICP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/FastFilterICP'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'

type RangePickerTP = RangePickerProps['value']

interface ICPProps extends S.IScpProps {
	onChange?: (dates: IDateRangeFilter, advancedFilterType?: FastDateRangeFilterTP) => void
	value?: IDateRangeFilter
	fastFilter?: FastDateRangeFilterTP
	allowClear?: boolean
	disabledDate?: ((current: moment.Moment | undefined) => boolean) | undefined
	label?: string
	fieldName?: any //keyof FModelTP
	formStateManager?: IFormStateManager<any> // FModelTP
	mode?: 'time' | 'date' | 'month' | 'year' | 'decade'
}

/**
 * Date picker customizado para range de datas (data inicial + data final).
 */
export const DateRangePickerCP = (props: ICPProps): JSX.Element => {
	const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false)
	const [dateRangePicker, setDateRangePicker] = useState<RangePickerTP>()

	useEffect(() => formatRangePicker(props.value), [props.value])
	useEffect(() => formatRangePicker(props.formStateManager?.getFieldValue(props.fieldName)), [props.formStateManager?.getFieldValue(props.fieldName)])

	/**
	 * Metodo necessario para abstrair o tipo RangeDatePicker, tudo que chega eh convertido para RangeDatePicker
	 */
	function formatRangePicker(dates?: IDateRangeFilter): void {
		if (!dates) return

		setDateRangePicker([dates.beginDate ? dayjs(dates.beginDate) : undefined, dates.endDate ? dayjs(dates.endDate) : undefined] as any)
	}

	/**
	 * Ao selecionar uma data no datePicker.
	 */
	function onDateRangeSelection(dates: RangePickerTP): void {
		const dateFilter: IDateRangeFilter = {
			beginDate: dates?.[0]?.toDate(),
			endDate: dates?.[1]?.toDate(),
		}

		setDateRangePicker(dates)
		setIsPickerOpen(false)

		if (!!props.onChange) props.onChange(dateFilter, undefined)

		if (!!props.formStateManager && !!props.fieldName) props.formStateManager.changeFieldValue(props.fieldName, dateFilter)
	}

	return (
		<S.WrapperSCP
			marginTop={props.marginTop ?? 0}
			marginRight={props.marginRight ?? 0}
			marginBottom={props.marginBottom ?? 0}
			marginLeft={props.marginLeft ?? 0}
		>
			<ConditionalRenderCP shouldRender={!!props.label}>
				<TextCP text={props.label} marginBottom={5} />
			</ConditionalRenderCP>

			<S.DatePickerContainerSCP showBorder={props.showBorder}>
				<S.DateRangePickerSCP
					onOpenChange={(status: boolean) => setIsPickerOpen(status)}
					value={dateRangePicker}
					onChange={onDateRangeSelection}
					open={isPickerOpen}
					format={props.mode === 'month' ? DateFormatEnum.BR_MONTH_YEAR : DateFormatEnum.BR_WITHOUT_TIME}
					placeholder={['Data inicial', 'Data final']}
					allowClear={props.allowClear}
					disabledDate={props.disabledDate as any}
					mode={props.mode === 'month' ? ['month', 'month'] : ['date', 'date']}
					popupClassName="datePickerPopup"
					renderExtraFooter={
						props.mode === 'month'
							? () => (
									<FastFilterMonthICP
										onFilterSelection={(selectedDateRange) =>
											onDateRangeSelection([dayjs(selectedDateRange.beginDate), dayjs(selectedDateRange.endDate)])
										}
									/>
							  )
							: !props.fastFilter
							? undefined
							: () => (
									<FastFilterICP
										onFilterSelection={(selectedDateRange) =>
											onDateRangeSelection([dayjs(selectedDateRange.beginDate), dayjs(selectedDateRange.endDate)])
										}
										currentFilter={props.fastFilter}
									/>
							  )
					}
				/>
			</S.DatePickerContainerSCP>
		</S.WrapperSCP>
	)
}
