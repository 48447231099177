import { TableClinicPeopleCP } from 'modules/clinic/components/table-clinic-people/TableClinicPeopleCP'
import { ModalAddUserToClinicCP } from 'modules/person/components/user/modal-add-user/ModalAddUserToClinicCP'
import { useEffect, useState } from 'react'
import { ClinicPersonRelationModeEnum } from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicPersonRelationModeEnum'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'

interface ICPProps {
	clinicCode: number
}

/**
 * Tela para gerenciamento da clínica
 */
export function ClinicsAdminUserScreenContent(props: ICPProps): JSX.Element {
	const [isNewUserModalVisible, setIsNewUserModalVisible] = useState<boolean>(false)

	const [reloadTable, setReloadTable] = useState<number>(TableUtils.getReloadNumber())
	useEffect(() => setReloadTable(props.clinicCode), [props.clinicCode])

	return (
		<>
			<FlexCP justify={'flex-end'} margin={{ bottom: 10, right: 20 }}>
				<ButtonCP onClick={() => setIsNewUserModalVisible(true)}>Novo Usuário</ButtonCP>
			</FlexCP>

			<TableClinicPeopleCP
				clinicCode={props.clinicCode}
				relationMode={ClinicPersonRelationModeEnum.IN}
				shouldReload={reloadTable}
				role={UserTypeEnum.USER}
				tableConfig={{
					showToogleAdminButton: true,
					wrappedOnCard: true,
				}}
			/>

			<ModalAddUserToClinicCP
				visible={isNewUserModalVisible}
				clinicCode={props.clinicCode}
				onCancel={() => setIsNewUserModalVisible(false)}
				onSave={() => {
					setIsNewUserModalVisible(false)
					setReloadTable(TableUtils.getReloadNumber())
				}}
			/>
		</>
	)
}
