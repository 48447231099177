import { ExamPendingResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamPendingResponseDTO'
import { INVALID_EXAM_DESCRIPTION_CODE, IPendingExamDescription } from 'submodules/beerads-sdk/services/exams/exams/types/IPendingExamDescription'
import { PendingExamUtils } from 'modules/exams/components/exams/drawer-exam-pending/inner/utils/PendingExamUtils'
import { PendingExamFormModel } from 'modules/exams/components/exams/drawer-exam-pending/inner/form-models/PendingExamFormModel'
import { ExamPendingSaveRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamPendingSaveRequestDTO'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { DoctorGroupNamesFromClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/DoctorGroupNamesFromClinicResponseDTO'

export class PendingExamFormModelConverter {
	/**
	 * Valida o FORM
	 */
	static async validateFormModel(formStateManager: IFormStateManager<PendingExamFormModel>): Promise<boolean> {
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) return false

		const formValues = formStateManager.getFormValues()!

		const doctorGroup: DoctorGroupNamesFromClinicResponseDTO = formStateManager.getFieldValue('doctorGroup')
		if (!doctorGroup) {
			NotificationHelper.error('Ops', 'Grupo de médicos não encontrado')
			return false
		}

		// Valida se algum dos exames esta com o codigo padrao
		return PendingExamFormModelConverter.validateExamDescriptions(formValues.exams, doctorGroup)
	}

	/**
	 * Retorna o DTO de save
	 */
	static getDto(formModel: PendingExamFormModel): ExamPendingSaveRequestDTO {
		const exams: IPendingExamDescription[] = formModel.exams.map((examDescription) => ({
			code: examDescription.code !== INVALID_EXAM_DESCRIPTION_CODE ? examDescription.code : undefined,
			modality: examDescription.modality,
			description: examDescription.description,
			accessionNumber: examDescription.accessionNumber,
		}))

		return {
			patient: {
				id: formModel.patientId,
				birthday: DateUtils.toDate(formModel.patientBirthday, DateFormatEnum.BR_WITHOUT_TIME),
				gender: formModel.patientGender,
				name: formModel.patientName,
			},
			urgencyType: formModel.urgencyType,
			contrasts: formModel.contrasts,

			requestingDoctor: formModel.requestingDoctor,
			requestingDoctorCrm: formModel.requestingDoctorCrm,
			extraInfo: formModel.extraInfo,
			anamnesis: formModel.anamnesis,
			doctorGroupCode: formModel.doctorGroupCode,
			exams: exams,
			internalClinic: formModel.internalClinic,
		}
	}

	/**
	 * Retorna o MODEL a partir do ResponseDTO
	 */
	static getFormModel(dto: ExamPendingResponseDTO): PendingExamFormModel {
		const exams: IPendingExamDescription[] = [
			{
				code: dto.examDescription?.code ?? INVALID_EXAM_DESCRIPTION_CODE,
				description: dto.examDescription?.name ?? dto.description,
				modality: PendingExamUtils.getModalityFromDescription(dto.examDescription?.name) ?? dto.modality,
				accessionNumber: dto.extraData?.accessionNumber ?? dto.extraData?.serviceOrder,
			},
		]

		return new PendingExamFormModel({
			exams,
			patientId: dto.patient.id,
			patientName: dto.patientName,
			patientBirthday: DateUtils.formatDate(dto.patient.birthDate, DateFormatEnum.BR_WITHOUT_TIME),
			patientGender: dto.patient.gender,
			urgencyType: dto.urgentType,
			contrasts: dto.contrasts,
			requestingDoctor: dto.extraData.requestingDoctor,
			requestingDoctorCrm: dto.extraData.requestingDoctorCrm,
			extraInfo: dto.extraData.extraInfo,
			anamnesis: dto.extraData.anamnesis,
			internalClinic: dto.extraData.internalClinic,
		})
	}

	/**
	 */
	static validateExamDescriptions(exams: IPendingExamDescription[], doctorGroup?: DoctorGroupNamesFromClinicResponseDTO): boolean {
		// Valida se algum dos exames esta com o codigo padrao
		let success = true
		exams.forEach((exam: IPendingExamDescription) => {
			if (!exam.modality || !exam.description) {
				NotificationHelper.error('Ops', 'Selecione um exame válido')
				success = false
				return
			}

			// Se grupo obriga seleciona exames de uma lista, valida
			if (doctorGroup?.config?.shouldValidateExamDescription === true && (!exam.code || exam.code === INVALID_EXAM_DESCRIPTION_CODE)) {
				NotificationHelper.error('Ops', 'Selecione um exame válido')
				success = false
				return
			}
		})

		return success
	}
}
