import { EXAMS_RANGE_DATE_TRANSLATION } from '@/modules/exams/components/exams/filters-exams/constants'
import { ExamDateRangeFilterEnum } from '@/submodules/beerads-sdk/services/exams/exams/enums/ExamDateRangeFilterEnum'
import { SelectCP } from '@/submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { ArrayUtils } from '@/submodules/nerit-framework-utils/utils/ArrayUtils'
import { useAppSelector } from 'app/redux/hook'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { SelectClinicFromLoggedUserCP } from 'modules/clinic/components/select-clinic/SelectClinicFromLoggedUserCP'
import { SelectClinicCategoryCP } from 'modules/doctor-groups/components/doctor-groups/select-clinic-category/SelectClinicCategoryCP'
import { SelectDoctorGroupPeopleCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-people/SelectDoctorGroupPeopleCP'
import { SelectDoctorGroupFromLoggedUserCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-groups/SelectDoctorGroupFromLoggedUserCP'
import { SelectExamModalityCP } from 'modules/exams/components/exams/select-exam-modality/SelectExamModalityCP'
import { SelectExamSlaCP } from 'modules/exams/components/exams/select-exam-sla/SelectExamSlaCP'
import { SelectExamSpecialtyCP } from 'modules/exams/components/exams/select-exam-specialty/SelectExamSpecialtyCP'
import { SelectExamStatusCP } from 'modules/exams/components/exams/select-exam-status/SelectExamStatusCP'
import { SelectExamUrgencyTypeCP } from 'modules/exams/components/exams/select-exam-urgency-type/SelectExamUrgencyTypeCP'
import { SwitchExamCriticalCP } from 'modules/exams/components/exams/switch-exam-critical/SwitchExamCriticalCP'
import { ExamFilterFormModel } from 'modules/exams/components/exams/table-exams/filters/ExamFilterFormModel'
import { FormEvent, useEffect, useState } from 'react'
import { UserDoctorGroupStatusEnum } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/enums/UserDoctorGroupStatusEnum'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ExamAssignedFilterEnum, ExamAssignedFilterLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamAssignedFilterEnum'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { FlexOneICP } from 'submodules/nerit-framework-ui/common/components/flex/inner/FlexOneICP'
import { DateRangePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/DateRangePickerCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { SkeletonCP } from 'submodules/nerit-framework-ui/common/components/skeleton/SkeletonCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import * as S from './FiltersExamsInnerStyles'

interface ICPProps {
	examStatusGroup?: ExamStatusGroupEnum
	isLoading: boolean
	onFilter: () => void
	isScreenInitialized: boolean
	filterFormStateManager: IFormStateManager<ExamFilterFormModel>
	fieldsConfig?: {
		disableStatus?: boolean
		disableDoctorGroup?: boolean
	}
	originalExamFilters?: ExamSearchRequestDTO
}

/**
 * Componente de filtros da tela de exames.
 */
export function FiltersExamsInnerICP(props: ICPProps): JSX.Element {
	const screenSize = useScreenSize()
	const loggedUser = useAppSelector((state) => state.auth.user)
	const hasDoctorGroup = !ArrayUtils.isEmpty(loggedUser?.doctorGroups)

	// Sera separado dos demais por questoes de performance
	const [patientName, setPatientName] = useState<string>()
	const [searchString, setSearchString] = useState<string>()
	const [reloadSearchField, setReloadSearchField] = useState<number>()

	useEffect(init, [props.isScreenInitialized])

	function init(): void {
		setSearchString(props.originalExamFilters?.searchString)
		setPatientName(props.originalExamFilters?.patientName)
		setReloadSearchField(TableUtils.getReloadNumber())
	}

	function onFilter(): void {
		props.filterFormStateManager.changeFieldValue('searchString', searchString)
		props.filterFormStateManager.changeFieldValue('patientName', patientName)

		props.onFilter()
	}

	function handleSubmit(e: FormEvent<HTMLFormElement>): void {
		e.preventDefault()
		onFilter()
	}

	if (!props.isScreenInitialized) {
		return (
			<S.FilterExamsFormSCP>
				<SkeletonCP />
			</S.FilterExamsFormSCP>
		)
	}

	return (
		<S.FilterExamsFormSCP id="exams-form" onSubmit={handleSubmit}>
			<RowCP>
				<ColumnCP md={5} sm={12} xs={24}>
					<InputCP label={'Nome do Paciente'} onChange={setPatientName} value={patientName} reloadField={reloadSearchField} allowClear={true} />
				</ColumnCP>
				<ColumnCP md={4} sm={12} xs={24}>
					<InputCP
						label={'Exame / Nº Pedido / ID Paciente'}
						onChange={setSearchString}
						value={searchString}
						reloadField={reloadSearchField}
						allowClear={true}
					/>
				</ColumnCP>
				<ColumnCP md={5} sm={12} xs={24}>
					<DateRangePickerCP
						showBorder={true}
						marginTop={15}
						fastFilter={'all'}
						formStateManager={props.filterFormStateManager}
						fieldName={'dateRange'}
					/>
				</ColumnCP>
				<ColumnCP md={3} sm={12} xs={24}>
					<SelectCP
						label="Data da:"
						options={Object.values(ExamDateRangeFilterEnum).map((status) => ({
							value: status,
							label: EXAMS_RANGE_DATE_TRANSLATION[status],
						}))}
						value={props.filterFormStateManager.getFieldValue('dateRangeFilter')}
						onChange={(value) => props.filterFormStateManager.changeFieldValue('dateRangeFilter', value)}
					/>
				</ColumnCP>
				<ColumnCP md={4} sm={12} xs={24}>
					<SelectClinicFromLoggedUserCP
						permissions={
							props.filterFormStateManager.getFieldValue('viewAsDoctor') === true
								? ['isDoctor']
								: ['isAdmin', 'isDoctorGroupAdmin', 'isUser']
						}
						value={{
							byOnChange: {
								value: props.filterFormStateManager.getFieldValue('clinicCodes'),
								onChange: (values) => props.filterFormStateManager.changeFieldValue('clinicCodes', values),
								showSelectAll: true,
							},
						}}
						multiple={{
							isMultiple: true,
							maxTagCount: 1,
							clearSearchTextOnSelect: false,
						}}
					/>
				</ColumnCP>
				<ColumnCP md={3} sm={12} xs={24}>
					<SelectExamModalityCP
						label={'Modalidades'}
						isMultiple={true}
						formStateManager={props.filterFormStateManager}
						fieldName={'modalities'}
					/>
				</ColumnCP>
			</RowCP>

			<FlexCP alignItems={'center'}>
				<FlexOneICP>
					<RowCP>
						<ColumnCP md={3} sm={12} xs={24}>
							<SelectExamSpecialtyCP label={'Especialidade'} formStateManager={props.filterFormStateManager} fieldName={'specialty'} />
						</ColumnCP>
						<ColumnCP md={3} sm={12} xs={12}>
							<SelectExamUrgencyTypeCP label={'Prioridade'} formStateManager={props.filterFormStateManager} fieldName={'urgencies'} />
						</ColumnCP>
						<ColumnCP md={hasDoctorGroup ? 10 : 16} sm={24} xs={24}>
							<SelectExamStatusCP
								formStateManager={props.filterFormStateManager}
								fieldName={'status'}
								viewAsDoctor={props.filterFormStateManager.getFieldValue('viewAsDoctor')}
								disabled={props.fieldsConfig?.disableStatus}
							/>
						</ColumnCP>
						<ColumnCP md={2} sm={12} xs={24}>
							<SelectExamSlaCP formStateManager={props.filterFormStateManager} fieldName={'sla'} />
						</ColumnCP>
						{hasDoctorGroup && (
							<ColumnCP md={6} sm={12} xs={24}>
								<FlexCP>
									<SelectDoctorGroupFromLoggedUserCP
										formStateManager={props.filterFormStateManager}
										fieldName={'doctorGroupCode'}
										disable={props.fieldsConfig?.disableDoctorGroup}
									/>
									{!!props.filterFormStateManager.getFieldValue('doctorGroupCode') &&
										DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(
											loggedUser,
											props.filterFormStateManager.getFieldValue('doctorGroupCode'),
											['isDoctorGroupAdmin'],
										) && (
											<>
												<BasicStyleWrapperCP margin={{ left: 10, right: 10 }}>
													<SelectClinicCategoryCP
														doctorGroupCode={props.filterFormStateManager.getFieldValue('doctorGroupCode')}
														formStateManager={props.filterFormStateManager}
														categoryFieldName={'clinicCategoryCode'}
														width={130}
													/>
												</BasicStyleWrapperCP>
											</>
										)}
								</FlexCP>
							</ColumnCP>
						)}
					</RowCP>
				</FlexOneICP>

				<FlexCP alignItems={'center'}>
					{hasDoctorGroup && (
						<S.RadioGroupWrapperSCP>
							<RadioGroupCP<ExamAssignedFilterEnum>
								fontSize={'small'}
								label={'Atribuído'}
								type={'button'}
								options={[
									{ value: ExamAssignedFilterEnum.ALL, content: ExamAssignedFilterLabelEnum[ExamAssignedFilterEnum.ALL] },
									{ value: ExamAssignedFilterEnum.YES, content: ExamAssignedFilterLabelEnum[ExamAssignedFilterEnum.YES] },
									{
										value: ExamAssignedFilterEnum.NO,
										content: ExamAssignedFilterLabelEnum[ExamAssignedFilterEnum.NO],
										hide: props.filterFormStateManager.getFieldValue('viewAsDoctor') === true,
									},
								]}
								formStateManager={props.filterFormStateManager}
								fieldName={'assigned'}
								margin={{ left: 5, right: 5 }}
								paddingTop={0}
								buttonWidth={50}
							/>
						</S.RadioGroupWrapperSCP>
					)}

					{props.filterFormStateManager.getFieldValue('viewAsDoctor') === false &&
						props.filterFormStateManager.getFieldValue('assigned') === ExamAssignedFilterEnum.YES && (
							<SelectDoctorGroupPeopleCP
								width={200}
								role={UserTypeEnum.DOCTOR}
								formStateManager={props.filterFormStateManager}
								formField={'doctorCodes'}
								isMultiple={true}
								doctorGroupCode={props.filterFormStateManager.getFieldValue('doctorGroupCode')}
								filters={{
									status: [UserDoctorGroupStatusEnum.ACTIVE],
								}}
							/>
						)}

					<S.SwitchWrapperSCP>
						<SwitchExamCriticalCP
							label={'Crítico'}
							labelAbove={true}
							formStateManager={props.filterFormStateManager}
							fieldName={'isCritical'}
						/>
					</S.SwitchWrapperSCP>
				</FlexCP>

				{!screenSize.smd && (
					<ButtonCP form="exams-form" isSubmit marginLeft={10} marginTop={22} icon={'search'} type={'primary'} loading={props.isLoading}>
						Filtrar
					</ButtonCP>
				)}
			</FlexCP>

			{screenSize.smd && (
				<S.ButtonSmallScreeSCP>
					<ButtonCP form="exams-form" isSubmit icon={'search'} type={'primary'} loading={props.isLoading} size={'large'}>
						Filtrar
					</ButtonCP>
				</S.ButtonSmallScreeSCP>
			)}
		</S.FilterExamsFormSCP>
	)
}
