import { Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import * as _ from 'lodash-es'
import { useEffect, useState } from 'react'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ConditionalRenderCP } from 'submodules/nerit-framework-ui/common/components/conditional-render/ConditionalRenderCP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { RadioOptionTP } from './inner/RadioOptionTP'
import * as S from './RadioGroupStyles'

type _AntPropsTP = {
	disabled?: boolean
}

type _CustomPropsTP<OptionTP, FModelTP extends FormModel> = {
	selected?: OptionTP
	onChange?: (value: OptionTP) => void
	fieldName?: keyof FModelTP
	formStateManager?: IFormStateManager<FModelTP>
	options: Array<RadioOptionTP<OptionTP>>
	label?: string
}

interface IRadioGroupCPProps<OptionTP = string, FModelTP extends FormModel = any>
	extends _AntPropsTP,
		S.RadioGroupWrapperPropsTP,
		_CustomPropsTP<OptionTP, FModelTP>,
		BasicStyleWrapperCPProps {
	loading?: boolean
}

/**
 * Grupo de radio-butons (combo de selecao unica).
 */
export function RadioGroupCP<OptionTP = string, FModelTP extends FormModel = any>(props: IRadioGroupCPProps<OptionTP>): JSX.Element | null {
	const hasStateManager = !!props.formStateManager && !!props.fieldName
	const validationsCount = props.formStateManager?.validationsCount ?? 0

	const [validationErrMsg, setValidationErrMsg] = useState<string>()
	const [errorMessage, setErrorMessage] = useState<string>()

	useEffect(parseValidation, [validationsCount])
	useEffect(handleErrMsgUpdate, [validationErrMsg])

	function handleChange(event: RadioChangeEvent): void {
		const nextValue = event.target.value

		if (!!props.onChange) props.onChange(nextValue)
		else if (hasStateManager) props.formStateManager!.changeFieldValue(props.fieldName!, nextValue)
	}

	function handleErrMsgUpdate(): void {
		setErrorMessage(validationErrMsg)
	}

	function parseValidation(): void {
		if (!hasStateManager || props.formStateManager!.isValid) return setValidationErrMsg(undefined)

		const fieldErrors = props.formStateManager!.getFieldError(props.fieldName as keyof FModelTP)
		const constraints = _.get(fieldErrors, 'constraints')

		if (!!constraints) {
			const errMessages: any = Object.values(constraints) || []
			if (!!errMessages.length) return setValidationErrMsg(errMessages[0])
		}

		setValidationErrMsg(undefined)
	}

	return (
		<BasicStyleWrapperCP margin={props.margin}>
			<>
				{!!props.label && <S.RadioGroupLabel>{props.label}</S.RadioGroupLabel>}

				<S.RadioGroupWrapper secondary={props.secondary} fontSize={props.fontSize} type={props.type}>
					<LoadingOverlayCP show={!!props.loading} />

					<S.RadioGroup
						type={props.type}
						paddingTop={props.paddingTop}
						buttonWidth={props.buttonWidth}
						buttonHeight={props.buttonHeight}
						value={hasStateManager ? props.formStateManager?.getFieldValue(props.fieldName!) : props.selected}
						disabled={props.disabled}
						onChange={handleChange}
					>
						{props.options
							.filter((opt) => !opt.hide)
							.map((opt, index) => (
								<TooltipCP
									text={opt.tooltip}
									key={`opt-radio-group-${opt.value ?? index}`}
									placement={props.type === 'vertical-radio' ? 'right' : undefined}
								>
									{props.type === 'button' && (
										<Radio.Button value={opt.value} disabled={opt.disabled}>
											{opt.content}
										</Radio.Button>
									)}
									{props.type === 'vertical-radio' && (
										<Radio value={opt.value} disabled={opt.disabled}>
											{opt.content}
										</Radio>
									)}
								</TooltipCP>
							))}
					</S.RadioGroup>

					<ConditionalRenderCP shouldRender={!!errorMessage}>
						<S.RadioGroupError>{errorMessage}</S.RadioGroupError>
					</ConditionalRenderCP>
				</S.RadioGroupWrapper>
			</>
		</BasicStyleWrapperCP>
	)
}
