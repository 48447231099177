import { ScreenForgotPassword } from 'modules/auth/screens/screen-recover-password/ScreenForgotPassword'
import { ScreenUserLogin } from 'modules/auth/screens/screen-user-login/ScreenUserLogin'
import { ScreenExamUploadFiles } from 'modules/exams/screens/screen-exam-upload-files/ScreenExamUploadFiles'
import { ScreenMedicalReportVideo } from 'modules/patient-portal/screens/screen-medical-report-video/ScreenMedicalReportVideo'
import { ScreenPatientPortalLogin } from 'modules/patient-portal/screens/screen-patient-portal-login/ScreenPatientPortalLogin'
import { ScreenDoctorRegister } from 'modules/person/screens/screen-doctor-register/ScreenDoctorRegister'
import { PATIENT_PORTAL_PATH } from 'submodules/beerads-sdk/services/patient-portal/utils/PatientPortalAccessUtils'
import { RouterSwitchCP } from 'submodules/nerit-framework-ui/common/router/components/router-switch/RouterSwitchCP'
import { ScreenError404 } from 'submodules/nerit-framework-ui/common/screens/ScreenError404'

/**
 * ROTAS
 */
PublicRouter.ROOT = 'public'

PublicRouter.USER_LOGIN = `${PublicRouter.ROOT}/login`
PublicRouter.FORGOT_PASSWORD = `${PublicRouter.ROOT}/forgot-password`

PublicRouter.PATIENT_PORTAL_LOGIN = PATIENT_PORTAL_PATH

PublicRouter.DOCTOR_REGISTER = `${PublicRouter.ROOT}/doctor-register`
PublicRouter.DOCTOR_REGISTER_WITH_QUERY = `${PublicRouter.DOCTOR_REGISTER}/:dgCode`

PublicRouter.UPLOAD_EXAM_FILES = `${PublicRouter.ROOT}/upload-exam-files`

PublicRouter.MEDICAL_REPORT_VIDEO = `${PublicRouter.ROOT}/medical-report-video/:token`

/**
 * Define rotas que podem ser acessadas sem realizar login.
 */
export function PublicRouter(): JSX.Element {
	return (
		<RouterSwitchCP
			routes={[
				{ path: PublicRouter.USER_LOGIN, component: ScreenUserLogin, exact: true },
				{ path: PublicRouter.FORGOT_PASSWORD, component: ScreenForgotPassword, exact: true },

				{ path: PublicRouter.PATIENT_PORTAL_LOGIN, component: ScreenPatientPortalLogin, exact: true },

				{ path: PublicRouter.DOCTOR_REGISTER, component: ScreenDoctorRegister, exact: true },
				{ path: PublicRouter.UPLOAD_EXAM_FILES, component: ScreenExamUploadFiles },
				{ path: PublicRouter.MEDICAL_REPORT_VIDEO, component: ScreenMedicalReportVideo },

				{ component: () => <ScreenError404 redirectTo={PublicRouter.USER_LOGIN} /> },
			]}
		/>
	)
}
