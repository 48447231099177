/**
 * UTILS
 * Reune metodos uteis para lidar com operacoes matematicas.
 */
export const MathUtils = {
	/** Retorna um numero inteiro aleatorio compreendido entre um determinado intervalo. */
	getRandomNumber(max: number, min?: number): number {
		min = min ?? 0
		const randomValue = Math.round(Math.random() * (max - min))
		return min + randomValue
	},

	/** Calcula & retorna razao entre 02 numeros na forma de porcentagem. */
	getPercentage(numerator?: number, denominator?: number, shouldRound = false): number {
		if (!numerator || !denominator) return 0

		const percentage = (numerator / denominator) * 100
		return shouldRound ? Math.round(percentage) : percentage
	},

	/** Determina se 01 numero inteiro qualquer eh PAR. */
	isEven(numberValue: number): boolean {
		return numberValue === 0 || numberValue % 2 === 0
	},

	/** Determina se 01 numero inteiro qualquer eh IMPAR. */
	isOdd(numberValue: number): boolean {
		return numberValue % 2 !== 0
	},

	/**
	 * Deixa um numero com duas casa decimais para ser tratado como valor financeiro. Em caso de despesa, retorna ele negativo.
	 * @param isExpense Indica que o valor que sera retornado eh referente a uma despesa, por isso retornamos ele negativo.
	 */
	getAsMoney(number: number, isExpense = false): number {
		if (number) {
			const value = Number((Math.round(number * 100) / 100).toFixed(2))

			if (isExpense) return -Math.abs(value)
			return value
		}
		return 0
	},

	/**
	 * Mantém um valor entre um valor mínimo e máximo, caso o valor enviado seja
	 * menor que o mínimo, irá retornar o mínimo e caso o valor seja maior que o máximo,
	 * irá retornar o máximo.
	 * @param value Valor a ficar entre o intervalo mínimo e máximo
	 * @param min Valor mínimo
	 * @param max Valor máximo
	 */
	clampValue(value: number, min: number, max: number): number {
		return Math.min(Math.max(value, min), max)
	}
} as const
