import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

export const Wrapper = styled.div`
	width: 22cm;
	border-radius: 5px;
	background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};

	.ant-tabs-nav-wrap {
		margin-left: -1px;
	}
`

export const TabsWrapper = styled.div`
	background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};

	.ant-tabs-tab {
		border-color: ${ThemeFrameworkCommon.browserDefaultColorDark}10 !important;
	}

	.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar {
		visibility: visible !important;
		background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
	}

	button {
		border-color: ${ThemeFrameworkCommon.browserDefaultColorDark}10 !important;
	}
`

export const TabsTitle = styled.span`
	margin: 0 40px;
`
