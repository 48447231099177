import { registerDecorator, ValidationArguments, ValidationOptions, Validator, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'
import * as _ from 'lodash-es'
import { ValidationTypes } from '../ValidationTypes'

/**
 * VALIDATOR
 * Valida Cpf
 *
 * @author guilherme.diniz
 */
@ValidatorConstraint({ name: ValidationTypes.IS_EMAIL })
class IsEmailConstraint implements ValidatorConstraintInterface {
	validate(value: string, args: ValidationArguments): boolean {
		const validator = new Validator()
		return !_.isEmpty(value) && validator.isEmail(value)
	}

	defaultMessage(args: ValidationArguments): string {
		return 'E-mail inválido (email@xyz.com(.br))'
	}
}

/**
 * DECORATOR
 * @param {ValidationOptions} validationOptions
 * @return {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function IsEmail(validationOptions?: ValidationOptions) {
	return (object: {}, propertyName: string) => {
		registerDecorator({
			target: object.constructor,
			propertyName,
			options: validationOptions,
			constraints: [],
			validator: IsEmailConstraint,
		})
	}
}
