import { SelectDoctorGroupExamDescriptionsCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-exam-descriptions/SelectDoctorGroupExamDescriptionsCP'
import { DuplicateExamFormModel } from 'modules/exams/components/exams/modal-duplicate-exam/inner/DuplicateExamFormModel'
import { SelectExamModalityCP } from 'modules/exams/components/exams/select-exam-modality/SelectExamModalityCP'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { ExamModalityEnum, ExamModalityLabelToEnEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { IPendingExamDescription } from 'submodules/beerads-sdk/services/exams/exams/types/IPendingExamDescription'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { SelectFullOptionTP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/inner/types/SelectOptionTP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { SelectExamUrgencyTypeCP } from 'modules/exams/components/exams/select-exam-urgency-type/SelectExamUrgencyTypeCP'

interface ICPProps {
	exam: ExamResponseDTO
	formStateManager: IFormStateManager<DuplicateExamFormModel>
}

/**
 */
export function DuplicateExamFormDataICP(props: ICPProps): JSX.Element {
	/**
	 * Ao selecionar um exam description.
	 */
	function onSelectExamDescription(val: SelectFullOptionTP, index: number): void {
		// Monta o exame selecionado para atualizar a lista de exames
		const selectedExam = mountExamDescription(val, props.formStateManager.getFieldValue('exams')?.[index]?.extraData?.accessionNumber)

		// Atualiza o objeto selecionado
		const currentExams = props.formStateManager.getFieldValue('exams')
		currentExams[index] = selectedExam
		props.formStateManager.changeFieldValue('exams', [...currentExams])
	}

	/**
	 */
	function mountExamDescription(val: SelectFullOptionTP, accessionNumber: string): IPendingExamDescription {
		// Modalidade selecionada esta antes do primeiro espaço do nome
		const selectedModalityBrLabel = (val.label as string).split(' ')[0] as ExamModalityEnum

		// Monta o exame selecionado para atualizar a lista de exames
		return {
			code: +val.value,
			modality: ExamModalityLabelToEnEnum[selectedModalityBrLabel],
			description: `${val.label}`,
			accessionNumber: accessionNumber,
		}
	}

	function getExcludedModalities(modality: ExamModalityEnum): ExamModalityEnum[] {
		const modalityInteroperability = [ExamModalityEnum.CR, ExamModalityEnum.CR_OIT]
		if (modalityInteroperability.includes(modality)) {
			return Object.values(ExamModalityEnum).filter((mod) => !modalityInteroperability.includes(mod))
		}
		return []
	}

	function onChangeSelect(value: unknown, index: number, selectKey: keyof IPendingExamDescription): void {
		const currentExams = props.formStateManager.getFieldValue('exams') as IPendingExamDescription
		currentExams[index][selectKey] = value
		props.formStateManager.changeFieldValue('exams', currentExams)
	}

	return (
		<>
			<>
				<TitleCP underLine={true} textSize={'normal'}>
					Exame Atual
				</TitleCP>
				<HelpCP type={'text'} text={'Caso deseje alterar o nome do exame atual, será criado novas entradas e editado o exame atual.'} />
				<RowCP>
					<ColumnCP size={4}>
						<SelectExamModalityCP label={'Mod.'} value={props.exam.modality} required={true} disabled={true} />
					</ColumnCP>
					<ColumnCP size={13}>
						{props.exam.doctorGroup?.config?.shouldValidateExamDescription === true ? (
							<SelectDoctorGroupExamDescriptionsCP
								required={true}
								selectedDescription={props.formStateManager.getFieldValue('currentExam')}
								onChange={(selected) =>
									props.formStateManager.changeFieldValue(
										'currentExam',
										mountExamDescription(selected, props.formStateManager.getFieldValue('currentExam')?.accessionNumber),
									)
								}
								doctorGroupCode={props.exam.doctorGroup.code}
								selectedModality={props.formStateManager.getFieldValue('exams')?.[0]?.modality}
								exam={{
									doctorGroupCode: props.exam.doctorGroup.code,
									description: props.exam.description,
									modality: props.exam.modality,
									clinicCode: props.exam.clinic.code,
								}}
							/>
						) : (
							<InputCP
								label={'Nome do Exame'}
								required={true}
								formStateManager={props.formStateManager}
								value={props.formStateManager.getFieldValue('currentExam').description}
								onChange={(val) => {
									const currentExam = props.formStateManager.getFieldValue('exams') as IPendingExamDescription
									currentExam.description = val
									props.formStateManager.changeFieldValue('exams', currentExam)
								}}
							/>
						)}
					</ColumnCP>
					<ColumnCP size={7}>
						<InputCP
							label={'Nº do Pedido'}
							formStateManager={props.formStateManager}
							value={props.formStateManager.getFieldValue('currentExam').accessionNumber}
							onChange={(val) => {
								const currentExam = props.formStateManager.getFieldValue('exams') as IPendingExamDescription
								currentExam.accessionNumber = val
								props.formStateManager.changeFieldValue('exams', currentExam)
							}}
						/>
					</ColumnCP>
				</RowCP>
			</>

			<>
				<FlexCP justify={'space-between'} alignItems={'center'} margin={{ top: 20 }}>
					<TitleCP underLine={true} textSize={'normal'} marginTop={20}>
						Novas Entradas
					</TitleCP>
					<ButtonCP
						icon={'plus'}
						size={'small'}
						onClick={() => {
							const newExamsData = [...props.formStateManager.getFieldValue('exams')]
							// Nova linha para ser preenchida
							newExamsData.push({ modality: props.formStateManager.getFieldValue('exams')?.[0]?.modality })
							props.formStateManager.changeFieldValue('exams', newExamsData)
						}}
					/>
				</FlexCP>
				{props.formStateManager.getFieldValue('exams')?.map((exam, index) => (
					<RowCP key={`row-${index}`}>
						<ColumnCP size={4}>
							<SelectExamModalityCP
								label={'Mod.'}
								value={props.exam.modality}
								onChange={(value) => onChangeSelect(value, index, 'modality')}
								excludedModalities={getExcludedModalities(props.exam.modality)}
								required={true}
								disabled={!getExcludedModalities(props.exam.modality).length}
							/>
						</ColumnCP>
						<ColumnCP size={10}>
							{props.exam.doctorGroup?.config?.shouldValidateExamDescription === true ? (
								<SelectDoctorGroupExamDescriptionsCP
									required={true}
									selectedDescription={props.formStateManager.getFieldValue('exams')?.[index]}
									onChange={(selected) => onSelectExamDescription(selected, index)}
									doctorGroupCode={props.exam.doctorGroup.code}
									selectedModality={props.formStateManager.getFieldValue('exams')?.[index]?.modality}
									exam={{
										doctorGroupCode: props.exam.doctorGroup.code,
										description: props.exam.description,
										modality: props.exam.modality,
										clinicCode: props.exam.clinic.code,
									}}
								/>
							) : (
								<InputCP
									label={'Nome do Exame'}
									required={true}
									formStateManager={props.formStateManager}
									value={props.formStateManager.getFieldValue('exams')?.[index].description}
									onChange={(value) => onChangeSelect(value, index, 'description')}
								/>
							)}
						</ColumnCP>
						<ColumnCP size={4}>
							<InputCP
								label={'Nº do Pedido'}
								required={true}
								formStateManager={props.formStateManager}
								value={props.formStateManager.getFieldValue('exams')?.[index].accessionNumber}
								onChange={(value) => onChangeSelect(value, index, 'accessionNumber')}
							/>
						</ColumnCP>
						<ColumnCP size={4}>
							<SelectExamUrgencyTypeCP
								label={'Urgência'}
								isMultiple={false}
								formStateManager={props.formStateManager}
								onChange={(value) => onChangeSelect(value, index, 'urgencyType')}
								required={true}
							/>
						</ColumnCP>
						<>
							{index > 0 && (
								<ColumnCP size={2}>
									<FlexCP justify={'flex-end'}>
										<ButtonCP
											marginTop={30}
											icon={'delete'}
											type={'primary'}
											danger
											size={'small'}
											onClick={() => {
												const newExamsData = [...props.formStateManager.getFieldValue('exams')]
												newExamsData.splice(index, 1)
												props.formStateManager.changeFieldValue('exams', newExamsData)
											}}
										/>
									</FlexCP>
								</ColumnCP>
							)}
						</>
					</RowCP>
				))}
			</>
		</>
	)
}
