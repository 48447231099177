import { OPEN_DIGITAL_CERTIFICATE_AUTH_PARAM } from 'app/components/menu-item-profile/MenuItemProfileCP'
import { VxWindModal } from 'app/components/vx-wind/inner/VxWindModal'
import * as S from 'app/components/vx-wind/vx-wind-modal-auth-digital-certificate/VxWindModalAuthDigitalCertificateStyles'
import { VxWindListExamsCP } from 'app/components/vx-wind/vx-wind-modal-auth-digital-certificate/vx-wind-list-exams/VxWindListExamsCP'
import { VxWindTalksCP } from 'app/components/vx-wind/vx-wind-talks/VxWindTalks'
import { useAppSelector } from 'app/redux/hook'
import { AppUtils } from 'app/utils/AppUtils'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useLocation } from 'react-router-dom'
import { AuthRequests } from 'submodules/beerads-sdk/services/auth/AuthRequests'
import { ExamsRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ExamListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import { ExamAssignedFilterEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamAssignedFilterEnum'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { ExamStatusUtils } from 'submodules/beerads-sdk/services/exams/exams/utils/ExamStatusUtils'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ResultCP } from 'submodules/nerit-framework-ui/common/components/result/ResultCP'
import { ValidationCodeInputCP } from 'submodules/nerit-framework-ui/common/components/validation-code-input/ValidationCodeInputCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { TransformUtils } from 'submodules/nerit-framework-utils/utils/TransformUtils'

interface ICPProps {
	onSuccess: () => void
	onCancel: () => void
	clinicCode?: number
	onClose: () => void
}

export function VxWindModalAuthDigitalCertificateCP(props: ICPProps): JSX.Element {
	const [otp, setOtp] = useState<string>('')
	const [modalVisibility, setModalVisibility] = useState(false)
	const digitalCertificateAuthRequest = useRequest<boolean>()
	const { auth, doctorGroup } = useAppSelector((state) => state)

	const [phrase, setPhrase] = useState<string>(
		`Olá, ${auth.user!.name.split(' ')[0]}. Para começarmos, acesse o aplicativo do BirdID e informe o código de autenticação`,
	)

	const examsAssignedRequests = useRequest<ListResponseDTO<ExamListItemResponseDTO>>()
	const [exams, setExams] = useState<ExamListItemResponseDTO[]>([])

	const isFirstRender = useRef<boolean>(true)
	const hasSetPhrase = useRef<boolean>(false)

	const routeLocation = useLocation()
	const params = new URLSearchParams(routeLocation.search)

	useEffect(init, [])

	/**
	 * Inicializa.
	 */
	function init(): void {
		const shouldOpenDigitalCerticate = TransformUtils.toBoolean({ value: params.get(OPEN_DIGITAL_CERTIFICATE_AUTH_PARAM) } as any)

		if (!shouldOpenDigitalCerticate) {
			return
		}

		if (!auth.user?.isDoctor || !auth.user?.hasDigitalCertificate || auth.isLoggedWithDigitalCertificate) {
			return
		}

		setModalVisibility(true)
	}

	useEffect(() => {
		if (!examsAssignedRequests.isAwaiting) {
			setExams(examsAssignedRequests.responseData?.list || [])
		}
	}, [examsAssignedRequests.isAwaiting])

	useEffect(() => {
		if (otp.length === 6 && !digitalCertificateAuthRequest.isAwaiting) {
			if (!hasSetPhrase.current) {
				setPhrase(`Conectando com a certificadora, aguarde alguns segundos`)

				hasSetPhrase.current = true
				authenticate()

				getExamsAwaiting()

				return
			}
			authenticate()
		}
	}, [otp])

	useEffect(() => {
		/**
		 * É necessário verificar se é a primeira vez, pois o useEffect é disparado assim que o componente é montado
		 * isso acontece porque o isAwaiting que já nasce como false e dispara a função
		 */

		if (isFirstRender.current) {
			isFirstRender.current = false
			return
		}

		if (!RequestUtils.isValidRequestReturn(digitalCertificateAuthRequest) && !digitalCertificateAuthRequest.isAwaiting) {
			setPhrase(` Ops, não conseguimos autenticá-lo... vamos tentar novamente`)
			return
		}
		if (digitalCertificateAuthRequest.isSuccess) {
			setPhrase('Tenha um ótimo dia de trabalho!')
			AppUtils.refreshLoggedUserData()
		}
	}, [digitalCertificateAuthRequest.isAwaiting])

	function authenticate(): void {
		if (!otp || otp.length < 6) {
			setPhrase('Preencha o código de 6 dígitos gerado no BirdID')
			return
		}
		digitalCertificateAuthRequest.runRequest(AuthRequests.createDigitalCertificateToken({ otp }))
	}

	function getExamsAwaiting(): void {
		const filtersDto: ExamSearchRequestDTO = {
			viewAsDoctor: true,
			assigned: ExamAssignedFilterEnum.YES,
			clinicCodes: props.clinicCode ? [props.clinicCode] : undefined,
			statuses: ExamStatusUtils.examStatusOfExamStatusGroupMap.get(ExamStatusGroupEnum.AWAITING),
			doctorGroupCode: doctorGroup.code || undefined,
		}

		examsAssignedRequests.runRequest(ExamsRequests.search(filtersDto))
	}

	function handleClickAuthenticate(): void {
		setPhrase('Conectando...')
		digitalCertificateAuthRequest.isSuccess ? props.onClose() : authenticate()
	}

	if (!auth.user?.isDoctor) return <></>

	return createPortal(
		<VxWindModal.Overlay isVisible={modalVisibility}>
			<FlexCP flexDirection="column" justify="space-between" gap={20}>
				<VxWindModal.Modal isVisible={modalVisibility}>
					<VxWindTalksCP phrase={phrase} />
					{digitalCertificateAuthRequest.isSuccess ? (
						<ResultCP status="success" subTitle="Autenticado com sucesso" />
					) : (
						<S.CodeInputWrapperSCP>
							<ValidationCodeInputCP onChange={setOtp} />
						</S.CodeInputWrapperSCP>
					)}
				</VxWindModal.Modal>

				{!!exams.length && (
					<VxWindModal.Modal isVisible={modalVisibility}>
						<VxWindTalksCP
							phrase={
								digitalCertificateAuthRequest.isSuccess
									? 'Separei os seus exames atribuídos'
									: 'Enquanto finalizamos a autenticação, já separei os seus exames atribuídos'
							}
						/>
						<VxWindModal.Content>
							<VxWindListExamsCP exams={exams} onReportClick={() => setModalVisibility(false)} />
						</VxWindModal.Content>
					</VxWindModal.Modal>
				)}

				<FlexCP justify="center" gap={5}>
					<ButtonCP onClick={props.onClose}>Cancelar</ButtonCP>
					<ButtonCP onClick={handleClickAuthenticate} type="primary" loading={digitalCertificateAuthRequest.isAwaiting}>
						Prosseguir
					</ButtonCP>
				</FlexCP>
			</FlexCP>
		</VxWindModal.Overlay>,
		document.body,
	)
}
