import { useAppSelector } from 'app/redux/hook'
import { CommonReportsFilterFormModel } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModel'
import { CommonReportsFilterFormModelConverter } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModelConverter'
import { DashboardRequests } from 'submodules/beerads-sdk/services/dashboard/dashboard/DashboardRequests'
import { MedicalReportsDoneRequestDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/request/MedicalReportsDoneRequestDTO'
import { MedicalReportsSlaAnalysisListItemResponseDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/response/MedicalReportsSlaAnalysisListItemResponseDTO'
import { ReportTypeEnum } from 'submodules/beerads-sdk/services/dashboard/dashboard/types/ReportTypeEnum'
import { ExamModalityLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ExamUrgencyTypeLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { ButtonExportTableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/inner/ButtonExportTableFromApiCP'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'

interface ICPProps {
	reportType: ReportTypeEnum
	reloadTable: number
	onLoading?: (isLoading: boolean) => void
	filterStateManager: IFormStateManager<CommonReportsFilterFormModel>
}

/**
 */
export function TableExamSlaAnalysisCP(props: ICPProps): JSX.Element {
	const loggedUser = useAppSelector((state) => state.auth.user)
	const doctorGroup = useAppSelector((state) => state.doctorGroup)

	return (
		<TableFromApiCP<MedicalReportsSlaAnalysisListItemResponseDTO, MedicalReportsDoneRequestDTO>
			shouldLoadData={props.reloadTable}
			onLoading={props.onLoading}
			apiConfig={{
				hasPagination: true,
				filters: CommonReportsFilterFormModelConverter.formatDto(
					loggedUser,
					doctorGroup,
					props.reportType,
					props.filterStateManager.getFormValues(),
				),
				requestConfigTP: (filters) => DashboardRequests.medicalReportsSlaAnalysis(filters!),
			}}
			appearance={{
				wrappedOnCard: true,
				showTotalOnHeader: true,
				showReloadButton: true,
				topBarLeftContent: (
					<ButtonExportTableFromApiCP
						requestConfigTP={() =>
							DashboardRequests.medicalReportsSlaAnalysisExport(
								CommonReportsFilterFormModelConverter.formatDto(
									loggedUser,
									doctorGroup,
									props.reportType,
									props.filterStateManager.getFormValues(),
								),
							)
						}
						fileName={`analise_sla`}
					/>
				),
			}}
			row={{
				setClass: (record) => (!!record.sla && record.sla?.finalSLA < 0 ? 'row-nerit-error' : ''),
			}}
			columns={[
				{
					title: 'Urgência',
					render: (text, record) => ExamUrgencyTypeLabelEnum[record.urgentType],
					align: 'center',
				},
				{
					title: 'Data do exame',
					align: 'center',
					render: (text, record) => DateUtils.formatDate(record.examDate, DateFormatEnum.BR_WITH_TIME_H_M),
				},
				{
					title: 'Data de Categorização',
					align: 'center',
					render: (text, record) => DateUtils.formatDate(record.pendingDate, DateFormatEnum.BR_WITH_TIME_H_M),
				},
				{
					title: 'Data do Laudo',
					align: 'center',
					render: (text, record) => DateUtils.formatDate(record.medicalReportDate, DateFormatEnum.BR_WITH_TIME_H_M),
				},
				{
					title: 'SLA Acordado (h)',
					render: (text, record) => record.sla?.hoursOriginalByUrgency ?? '-',
				},
				{
					title: 'SLA Realizado (h)',
					render: (text, record) => record.sla?.finalSLA ?? '-',
				},
				{
					title: 'Atrasado',
					render: (text, record) => (!record.sla ? '-' : record.sla.finalSLA < 0 ? 'Sim' : 'Não'),
				},
				{
					title: 'Paciente',
					render: (text, record) => record.patientName,
				},
				{
					title: 'Modalidade',
					align: 'center',
					render: (text, record) => ExamModalityLabelEnum[record.modality],
				},
				{
					title: 'Exame',
					render: (text, record) => record.examDescription,
				},
				{
					title: 'Unidade Hospitalar',
					render: (text, record) => record.clinic.name,
				},
				{
					title: 'Médico',
					render: (text, record) => record.doctor.name,
				},
				{
					title: 'Nº de laudos',
					align: 'right',
					render: (text, record) => record.totalMedicalReports,
				},
			]}
		/>
	)
}
