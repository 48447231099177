import { AppUtils } from '@/app/utils/AppUtils'
import { ProjectWhiteLabelsEnum } from '@/submodules/beerads-sdk/common/ProjectWhiteLabelsEnum'
import axios from 'axios'

export const http = axios.create({
	baseURL: import.meta.env.VITE_API_BASE_URL,
})

export function setAuthHeader(token?: string): void {
	http.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : token
}

export function setOnBehalfOfHeader(): void {
	http.defaults.headers.common['on-behalf-of'] = AppUtils.getDomain() ?? ProjectWhiteLabelsEnum.BEERADS
}
