import { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import styled from 'styled-components'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import loadingDocImg from './loading_doc.gif'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface ICPProps {
	file: any
	paginationType: 'scroll' | 'paginator'
	onLoadDocument?: () => void
	width?: string
}

/**
 * COMPONENTE: Visualizador de PDF
 */
export function PdfViewerContentICP(props: ICPProps): JSX.Element | null {
	const [totalPages, setTotalPages] = useState<number>(0)
	const [showPage, setShowPage] = useState<number>(1)
	const [showPageIndicator, setShowPageIndicator] = useState<boolean>(false)

	/**
	 */
	function renderPages(): JSX.Element | JSX.Element[] {
		// Se tiver paginacao, retonarna apenas uma pagina
		if (props.paginationType === 'paginator') return <Page pageNumber={showPage} />

		// Senao retorna todas as paginas e tera scroll
		const jsxPages: JSX.Element[] = []

		for (let i = 0; i < totalPages; i++) {
			jsxPages.push(
				<PageWrapperSCP>
					<Page pageNumber={i + 1} renderTextLayer={false} renderAnnotationLayer={false} />
				</PageWrapperSCP>,
			)
		}

		return jsxPages
	}

	/**
	 */
	function onLoadDocument(numPages: number): void {
		setTotalPages(numPages)
		setShowPageIndicator(props.paginationType === 'paginator')

		if (props.onLoadDocument) props.onLoadDocument()
	}

	return (
		<ViewerWrapperSCP className={'nerit-viewer-wrapper'}>
			<DocumentSCP width={props.width} isFixedSize={props.paginationType === 'paginator'}>
				{props.file ? (
					<Document
						file={props.file}
						loading={
							<LoadingWrapperSCP>
								<img src={loadingDocImg} width={200} />
								<LoadingTextSCP>... carregando documento ...</LoadingTextSCP>
							</LoadingWrapperSCP>
						}
						onLoadSuccess={({ numPages }) => onLoadDocument(numPages)}
					>
						{renderPages()}
					</Document>
				) : (
					<LoadingWrapperSCP>
						<img src={loadingDocImg} width={200} />
						<LoadingTextSCP>... documento não informado ainda ...</LoadingTextSCP>
					</LoadingWrapperSCP>
				)}
			</DocumentSCP>
			{showPageIndicator && (
				<NavWrapperSCP>
					{showPage > 1 && (
						<ArrowSCP onClick={() => setShowPage(showPage - 1)}>
							<IconICP iconName={'caret-left'} />
						</ArrowSCP>
					)}

					{`Página ${showPage} de ${totalPages}`}

					{showPage < totalPages && (
						<ArrowSCP onClick={() => setShowPage(showPage + 1)}>
							<IconICP iconName={'caret-right'} />
						</ArrowSCP>
					)}
				</NavWrapperSCP>
			)}
		</ViewerWrapperSCP>
	)
}

const DocumentSCP = styled.div<{ isFixedSize: boolean; width?: string }>`
	width: ${(props) => props.width ?? (props.isFixedSize ? '' : '90%')};
	max-width: ${(props) => (props.isFixedSize ? '' : '1000px')};

	canvas {
		height: ${(props) => (props.isFixedSize ? '80vh !important' : 'auto !important')};
		width: ${(props) => (props.isFixedSize ? 'auto !important' : '100% !important')};
	}
`

const LoadingWrapperSCP = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

const ViewerWrapperSCP = styled.div`
	background: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
`

const NavWrapperSCP = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
`

const ArrowSCP = styled.div`
	margin: 4px;
	padding: 4px;
	cursor: pointer;
`

const PageWrapperSCP = styled.div`
	margin-bottom: 10px;
`

const LoadingTextSCP = styled.div`
	animation: blink 3s linear infinite;
	margin-top: 10px;

	@keyframes blink {
		0% {
			opacity: 0.2;
		}
		20% {
			opacity: 0.6;
		}
		40% {
			opacity: 1;
		}
		60% {
			opacity: 1;
		}
		80% {
			opacity: 0.6;
		}
		100% {
			opacity: 0.2;
		}
	}
`
