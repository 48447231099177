import { setAuthHeader } from '@/services/http/http'
import { IconProfileMenuItemICP } from 'app/components/menu-item-profile/inner/IconProfileMenuItemICP'
import { useAppDispatch, useAppSelector } from 'app/redux/hook'
import { authActions } from 'app/redux/slices/auth/AuthSlice'
import { doctorGroupActions } from 'app/redux/slices/doctor-group/DoctorGroupSlice'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import { ModalAuthDigitalCertificateCP } from 'modules/person/components/doctor/modal-auth-digital-certificate/ModalAuthDigitalCertificateCP'
import { useState } from 'react'
import { ProfileMenuItemCP } from 'submodules/nerit-framework-ui/common/components/menu/menu-item-profile/ProfileMenuItemCP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { ModalSpeedTest } from 'submodules/nerit-framework-ui/features/common/components/modal-speed-test/ModalSpeedTest'

export const OPEN_DIGITAL_CERTIFICATE_AUTH_PARAM = 'open-digital-certificate-auth'

/**
 */
export function MenuItemProfileCP(): JSX.Element {
	const [isDigitalCertificateModalVisible, setIsDigitalCertificateModalVisible] = useState<boolean>(false)
	const [isSpeedTestModalVisible, setIsSpeedTestModalVisible] = useState<boolean>(false)
	const [isRedirectModal, setIsRedirectModel] = useState<boolean>(false)

	const authData = useAppSelector((state) => state.auth)
	const dispatch = useAppDispatch()

	return (
		<>
			<ProfileMenuItemCP
				profileImage={authData.user?.photoUrl}
				optionTitle={authData.user?.name ?? ''}
				profileImageIcon={<IconProfileMenuItemICP />}
				profileOptions={[
					{
						text: 'Autenticar com Certificado Digital',
						action: () => setIsDigitalCertificateModalVisible(true),
						hidden: !authData.user?.isDoctor || !authData.user?.hasDigitalCertificate,
					},
					{
						text: 'Sugerir melhorias no sistema',
						action: () => setIsRedirectModel(true),
					},
					{
						text: 'Teste de Velocidade',
						action: () => setIsSpeedTestModalVisible(true),
					},
					{
						text: 'Meu Perfil',
						action: () => NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.PROFILE),
					},
					{
						text: 'Sair',
						action: () => {
							dispatch(authActions.logout())
							dispatch(doctorGroupActions.logout())
							setAuthHeader()

							NeritFrameworkRoutingHelper.historyPush(PublicRouter.USER_LOGIN)
						},
					},
				]}
			/>

			<ModalSpeedTest visible={isSpeedTestModalVisible} onClose={() => setIsSpeedTestModalVisible(false)} />

			{isDigitalCertificateModalVisible && (
				<ModalAuthDigitalCertificateCP
					visible={isDigitalCertificateModalVisible}
					onCancel={() => setIsDigitalCertificateModalVisible(false)}
					onSuccess={() => setIsDigitalCertificateModalVisible(false)}
				/>
			)}

			<ModalCP
				title={'Você será redirecionado a uma nova guia.'}
				visible={isRedirectModal}
				okText={'Continuar'}
				onClose={() => setIsRedirectModel(false)}
				onOk={() =>
					NeritFrameworkRoutingHelper.openInNewTab(
						'https://forms.office.com/pages/responsepage.aspx?id=Nb28VnWiLEiNTlWJecFNA64b3EjwcGdLvkqmR-MJ2vZURDJKV0NWV1JIUTAxNDZXUjNDNk5DSFNJOS4u',
						false,
					)
				}
			>
				<TextCP strong={true} text={'Deseja continuar?'} />
			</ModalCP>
		</>
	)
}
