import { UploadUrlTP } from 'modules/exams/components/exams/drawer-exam-pending/inner/FormExamPendingAnamnesisDataCP'
import { CreateExamFormModel } from 'modules/exams/components/exams/wizard-create-exam/form-model/CreateExamFormModel'
import { CreateExamExamDataICP } from 'modules/exams/components/exams/wizard-create-exam/steps/step-exam-data/inner/CreateExamExamDataICP'
import { CreateExamPatientDataICP } from 'modules/exams/components/exams/wizard-create-exam/steps/step-exam-data/inner/CreateExamPatientDataICP'
import { useEffect } from 'react'
import { DoctorGroupNamesFromClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/DoctorGroupNamesFromClinicResponseDTO'
import { ClinicTypeEnum } from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicTypeEnum'
import { ExamsRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import { CreateExamRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/CreateExamRequestDTO'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'

interface ICPProps {
	clinicCode: number
	clinicType: ClinicTypeEnum
	doctorGroup: DoctorGroupNamesFromClinicResponseDTO
	formStateManager: IFormStateManager<CreateExamFormModel>
	onCreate: (examCode: number, examId: string, uploadUrlResponse: UploadUrlTP) => void
}

/**
 */
export function StepExamDataCreateExamICP(props: ICPProps): JSX.Element {
	const createExamRequest = useRequest<ExamResponseDTO>()
	useEffect(onCreateExamRequestChange, [createExamRequest.isAwaiting])

	/**
	 * Cria Exame.
	 */
	async function createExam(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(props.formStateManager))) return

		const formValues = props.formStateManager.getFormValues()!

		// O campo reason é obrigatório para VET e OCUP
		if ([ClinicTypeEnum.VET, ClinicTypeEnum.OCUP].includes(props.clinicType) && !formValues.reason) {
			NotificationHelper.error('Ops', 'O campo motivo é obrigatório')
			return
		}
		const dto: CreateExamRequestDTO = {
			urgencyType: formValues.urgencyType,
			clinicCode: props.clinicCode,
			doctorGroupCode: props.doctorGroup.code,
			patientId: formValues.patientId,
			patientName: formValues.patientName,
			patientBirthday: !!formValues.patientBirthDate ? DateUtils.toDate(formValues.patientBirthDate, DateFormatEnum.BR_WITHOUT_TIME) : undefined,
			patientPhone: formValues.patientPhone,
			description: {
				code: formValues.description.value,
				description: formValues.description.label,
				modality: formValues.modality,
			},
			reason: formValues.reason,
			examDate: formValues.examDate.toDate(),
			desiredFunction: formValues.patientFunction,
			anamnesis: formValues.anamnesis,
			requestingDoctor: formValues.requestingDoctor,
			patientSex: formValues.patientGender,
		}

		createExamRequest.runRequest(ExamsRequests.createExam(dto))
	}

	/**
	 * Ao criar o exame.
	 */
	function onCreateExamRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				createExamRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
			)
		)
			return

		const result = createExamRequest.responseData!
		props.onCreate(result.code, result.id, result.temporaryToken)
	}

	return (
		<>
			<CreateExamPatientDataICP formStateManager={props.formStateManager} clinicType={props.clinicType} />
			<CreateExamExamDataICP
				clinicCode={props.clinicCode}
				doctorGroup={props.doctorGroup}
				formStateManager={props.formStateManager}
				clinicType={props.clinicType}
			/>

			<FlexCP margin={{ top: 30 }} justify={'flex-end'}>
				<ButtonCP type={'primary'} onClick={createExam} loading={createExamRequest.isAwaiting}>
					Salvar
				</ButtonCP>
			</FlexCP>
		</>
	)
}
