import { useAppSelector } from 'app/redux/hook'
import { CommonReportsFilterFormModel } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModel'
import { CommonReportsFilterFormModelConverter } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModelConverter'
import { ButtonExportTableExamsDoneICP } from 'modules/reports/components/table-exams-done/inner/ButtonExportTableExamsDoneICP'
import styled from 'styled-components'
import { DashboardRequests } from 'submodules/beerads-sdk/services/dashboard/dashboard/DashboardRequests'
import { MedicalReportsDoneRequestDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/request/MedicalReportsDoneRequestDTO'
import { MedicalReportsDoneListItemResponseDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/response/MedicalReportsDoneListItemResponseDTO'
import { ReportTypeEnum } from 'submodules/beerads-sdk/services/dashboard/dashboard/types/ReportTypeEnum'
import { ExamContrastLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamContrastEnum'
import { ExamModalityLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ExamUrgencyTypeLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { GenderLabelEnum } from 'submodules/nerit-framework-utils/utils/enums/GenderEnum'

interface ICPProps {
	reportType: ReportTypeEnum
	reloadTable: number
	onLoading?: (isLoading: boolean) => void
	filterStateManager: IFormStateManager<CommonReportsFilterFormModel>
}

/**
 */
export function TableExamsDoneCP(props: ICPProps): JSX.Element {
	const loggedUser = useAppSelector((state) => state.auth.user)
	const doctorGroup = useAppSelector((state) => state.doctorGroup)

	return (
		<WrapperSCP>
			<BulkActionBtnsContainerSCP>
				<ButtonExportTableExamsDoneICP reportType={props.reportType} filterStateManager={props.filterStateManager} />
			</BulkActionBtnsContainerSCP>

			<TableFromApiCP<MedicalReportsDoneListItemResponseDTO, MedicalReportsDoneRequestDTO>
				shouldLoadData={props.reloadTable}
				onLoading={props.onLoading}
				apiConfig={{
					hasPagination: true,
					filters: CommonReportsFilterFormModelConverter.formatDto(
						loggedUser,
						doctorGroup,
						props.reportType,
						props.filterStateManager.getFormValues(),
					),
					requestConfigTP: (filters) => DashboardRequests.medicalReportsDone(filters!),
				}}
				appearance={{
					wrappedOnCard: true,
					showReloadButton: true,
					reloadButtonSize: 'default',
				}}
				columns={[
					{
						title: 'Urgência',
						render: (text, record) => ExamUrgencyTypeLabelEnum[record.urgentType],
						align: 'center',
					},
					{
						title: 'Data do Exame',
						align: 'center',
						render: (text, record) => DateUtils.formatDate(record.examDate, DateFormatEnum.BR_WITHOUT_TIME),
					},
					{
						title: 'Data de Envio',
						align: 'center',
						render: (text, record) => DateUtils.formatDate(record.creationDate, DateFormatEnum.BR_WITH_TIME_H_M),
					},
					{
						title: 'Data de Categorização',
						align: 'center',
						render: (text, record) => DateUtils.formatDate(record.pedingEndDate, DateFormatEnum.BR_WITH_TIME_H_M),
					},
					{
						title: 'Data do Laudo',
						align: 'center',
						render: (text, record) => DateUtils.formatDate(record.medicalReportDate, DateFormatEnum.BR_WITH_TIME_H_M),
					},
					{
						title: 'Paciente',
						render: (text, record) => record.patientName,
					},
					{
						title: 'Sexo',
						render: (text, record) => GenderLabelEnum[record.gender],
					},
					{
						title: 'Modalidade',
						align: 'center',
						render: (text, record) => ExamModalityLabelEnum[record.modality],
					},
					{
						title: 'Exame',
						render: (text, record) => record.examDescription,
					},
					{
						title: 'Contraste',
						render: (text, record) =>
							ArrayUtils.isEmpty(record.contrasts) ? '' : record.contrasts.map((contrast) => ExamContrastLabelEnum[contrast]).join(', '),
					},
					{
						title: 'Médico Solicitante',
						render: (text, record) => record.requestingDoctor,
					},
					{
						title: 'Unidade Hospitalar',
						render: (text, record) => record.clinic.name,
					},
					{
						title: 'Informações Extras',
						render: (text, record) => record.extraInfo,
					},
					{
						title: 'Clínica Interna',
						render: (text, record) => record.internalClinic,
					},
					{
						title: 'Médico do Laudo',
						render: (text, record) => record.doctor.name,
					},
					{
						title: 'Nº de Laudos',
						align: 'right',
						render: (text, record) => record.totalMedicalReports,
					},
					{
						title: 'Achado Crítico?',
						align: 'center',
						render: (text, record) => (record.isCritical ? 'Sim' : 'Não'),
					},
				]}
			/>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	position: relative;
`
const BulkActionBtnsContainerSCP = styled.div`
	display: flex;
	position: absolute;
	left: 20px;
`
