import { setAuthHeader, setOnBehalfOfHeader } from '@/services/http/http'
import { authActions, IAuthState } from 'app/redux/slices/auth/AuthSlice'
import { store } from 'app/redux/store'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import { ProjectWhiteLabelsEnum } from 'submodules/beerads-sdk/common/ProjectWhiteLabelsEnum'
import { AuthRequests } from 'submodules/beerads-sdk/services/auth/AuthRequests'
import { AuthResponseDTO } from 'submodules/beerads-sdk/services/auth/dtos/responses/AuthResponseDTO'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { RequestHelper } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'
import { IApiReturn } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/IApiReturn'
import { AuthUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/utils/AuthUtils'
import { OrUndefinedTP } from 'submodules/nerit-framework-utils/utils/types/OrUndefinedTP'

export class AppUtils {
	private static handlingUnauthorizedError = false

	static getDomain(): OrUndefinedTP<ProjectWhiteLabelsEnum> {
		const domain = window.location.pathname.split('/')[1]
		if (!domain) return

		return domain as ProjectWhiteLabelsEnum
	}

	static getDomainName(fullName?: boolean): OrUndefinedTP<string> {
		if (AppUtils.getDomain() === ProjectWhiteLabelsEnum.VX) {
			const name = 'VX'
			return fullName ? `${name} - Medical Innovation` : name
		}

		return 'VX PACS'
	}

	/**
	 * Asynchronously refreshes the logged user data by making a request to the server to refresh the login.
	 * If the response contains data, it dispatches an action to update the logged user in the Redux store.
	 * If the response does not contain data, it calls the `onUnauthorizedRequestResponse` function.
	 */
	static async refreshLoggedUserData(): Promise<void> {
		const response = await RequestHelper.runRequest<IApiReturn<AuthResponseDTO>>(AuthRequests.refreshLogin())

		const result = response?.data?.data

		if (!result) {
			AppUtils.onUnauthorizedRequestResponse(null)
			return
		}

		store.dispatch(authActions.setLoggedUser(result))
	}

	static onUnauthorizedRequestResponse(authData: IAuthState | null): void {
		if (this.handlingUnauthorizedError) return

		if (!authData?.token) {
			this.handlingUnauthorizedError = true
			const notificationDuration = 6
			NotificationHelper.info('Sessão Expirada!', 'Sua sessão expirou. Faça login novamente para prosseguir', notificationDuration)
			setTimeout(() => (AppUtils.handlingUnauthorizedError = false), notificationDuration - 1)
		}

		store.dispatch(authActions.logout())

		NeritFrameworkRoutingHelper.historyPush(PublicRouter.USER_LOGIN)
	}

	/**
	 */
	static onUnauthorizedPatientPortalRequestResponse(authData: IAuthState | null): void {
		if (this.handlingUnauthorizedError) return

		if (!!authData?.patientPortal) {
			this.handlingUnauthorizedError = true
			const notificationDuration = 6
			NotificationHelper.info('Sessão Expirada!', 'Sua sessão expirou. Insira seus dados novamente para prosseguir', notificationDuration)
			setTimeout(() => (AppUtils.handlingUnauthorizedError = false), notificationDuration - 1)
		}

		store.dispatch(authActions.logout())

		NeritFrameworkRoutingHelper.historyPush(PublicRouter.PATIENT_PORTAL_LOGIN)
	}

	/**
	 * Atualiza propriedades estaticas que guardam o token de autenticacao do usuario atual sincronizando-as com o conteudo mantido no estado global da aplicacao (redux).
	 */
	static refreshToken(authToken: string | null): void {
		if (!authToken) {
			return
		}

		RequestHelper.addDefaultHeaderConfig(AuthUtils.getBearerAuthHeaderConfig(authToken))
		RequestHelper.addDefaultHeaderConfig(AuthUtils.getOnBehalfHeaderConfig(AppUtils.getDomain() ?? ProjectWhiteLabelsEnum.BEERADS))
		RequestHelper.addDefaultHeaderConfig(AuthUtils.getTypeBehalfTo())

		setAuthHeader(authToken)
		setOnBehalfOfHeader()
	}
}
