import { InputCP } from '@/components/ui/InputCP'
import { IconCP } from 'app/components/icon/Icon'
import { OPEN_DIGITAL_CERTIFICATE_AUTH_PARAM } from 'app/components/menu-item-profile/MenuItemProfileCP'
import { useAppDispatch } from 'app/redux/hook'
import { authActions } from 'app/redux/slices/auth/AuthSlice'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import md5 from 'md5'
import { useEffect, useState } from 'react'
import { AuthRequests } from 'submodules/beerads-sdk/services/auth/AuthRequests'
import { UserLoginRequestDTO } from 'submodules/beerads-sdk/services/auth/dtos/requests/UserLoginRequestDTO'
import { AuthResponseDTO } from 'submodules/beerads-sdk/services/auth/dtos/responses/AuthResponseDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { InputControlledCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputControlledCP'
import { SmartFormCP } from 'submodules/nerit-framework-ui/common/components/smart-form/SmartFormCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

/**
 */
export function UserLoginFormCP(): JSX.Element {
	const dispatch = useAppDispatch()
	const [isPasswordVisible, setIsPasswordVisible] = useState(false)

	const loginRequest = useRequest<AuthResponseDTO>()
	useEffect(onLoginRequestChange, [loginRequest.isAwaiting])

	function onTogglePasswordVisibility(): void {
		setIsPasswordVisible((oldValue) => !oldValue)
	}

	async function onFormSubmit(formValues: UserLoginRequestDTO): Promise<void> {
		const loginDto: UserLoginRequestDTO = {
			email: formValues.email,
			password: md5(formValues.password),
		}

		loginRequest.runRequest(AuthRequests.login(loginDto))
	}

	/**
	 * Retorno da requisao de login
	 */
	function onLoginRequestChange(): void {
		const isValidRequest = RequestUtils.isValidRequestReturn(
			{
				request: loginRequest,
			},
			loginRequest.error?.data?.message,
		)

		if (!isValidRequest) {
			return
		}

		const result = loginRequest.responseData!

		dispatch(authActions.setLoggedUser(result))

		NeritFrameworkRoutingHelper.historyPush(
			`${PrivateUserRouter.HOME}?${OPEN_DIGITAL_CERTIFICATE_AUTH_PARAM}=${result.user.hasDigitalCertificate ?? false}`,
		)
	}

	return (
		<SmartFormCP
			className="flex w-full max-w-[360px] flex-col gap-2"
			formModel={UserLoginRequestDTO}
			onSubmit={onFormSubmit}
			defaultValues={{
				email: '',
				password: '',
			}}
		>
			<InputControlledCP name="email">
				<InputCP
					required
					id="email"
					type="text"
					label="E-mail / Login"
					placeholder="E-mail / Login"
					suffixIcon={<IconCP iconName="mail" className="bg-background text-foreground" />}
				/>
			</InputControlledCP>

			<InputControlledCP name="password">
				<InputCP
					required
					id="password"
					type={isPasswordVisible ? 'text' : 'password'}
					label="Senha"
					placeholder="Senha"
					suffixIcon={
						<button
							aria-label="Visualizar senha"
							className="border-none bg-background p-0 text-foreground"
							type="button"
							onClick={onTogglePasswordVisibility}
						>
							{!isPasswordVisible ? <IconCP iconName="visibilityOff" /> : <IconCP iconName="visibility" />}
						</button>
					}
				/>
			</InputControlledCP>

			<div className="mt-5 flex w-full justify-between py-3">
				<ButtonCP onClick={() => NeritFrameworkRoutingHelper.historyPush(PublicRouter.FORGOT_PASSWORD)}>Esqueci minha senha</ButtonCP>

				<ButtonCP isSubmit type={'primary'} loading={loginRequest.isAwaiting}>
					Entrar
				</ButtonCP>
			</div>
		</SmartFormCP>
	)
}
