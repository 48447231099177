/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import { useValidationCodeInput } from 'submodules/nerit-framework-ui/common/components/validation-code-input/useValidationCodeInput'
import { THEME_LOCAL_STORAGE_KEY, ThemeTP } from 'submodules/nerit-framework-ui/features/common/components/button-switch-theme/inner/ThemeTP'
import * as S from './ValidationCodeInputStyles' // We know that this import are from styled components because of the S. syntax

export interface IValidationCodeInputCPProps {
	value?: string
	length?: number
	validChars?: string
	placeholder?: string
	autoFocus?: boolean
	passwordMode?: boolean
	inputProps?: React.ComponentPropsWithRef<'input'>
	containerProps?: React.ComponentPropsWithRef<'div'>
	onChange?: (value: string) => void
	onFocus?: () => void
	onBlur?: () => void
	onComplete?: (value: string) => void
}

export const ValidationCodeInputCP = forwardRef<HTMLInputElement | null, IValidationCodeInputCPProps>((props, ref) => {
	const length = props.length ?? 6
	const validationCodeInput = useValidationCodeInput({ ...props, length })
	const currentTheme = (localStorage.getItem(THEME_LOCAL_STORAGE_KEY) as ThemeTP) ?? 'light'

	const { type: inputType, ...restInputProps } = props.inputProps ?? {}

	return (
		<S.ValidationCodeContainer
			data-testid="container"
			onClick={() => (validationCodeInput.inputRef.current?.focus ? validationCodeInput.inputRef.current.focus() : undefined)}
			{...props.containerProps}
		>
			<S.ValidationCodeHiddenInput
				aria-label="verification input"
				spellCheck={false}
				value={validationCodeInput.getValue()}
				onChange={validationCodeInput.handleInputChange}
				ref={(node) => {
					validationCodeInput.inputRef.current = node

					if (typeof ref === 'function') {
						ref(node)
						return
					}

					if (ref) {
						ref.current = node
					}
				}}
				onKeyDown={validationCodeInput.handleKeyDown}
				onFocus={() => {
					validationCodeInput.setActive(true)
					if (props.onFocus) props.onFocus()
				}}
				onBlur={() => {
					validationCodeInput.setActive(false)
					if (props.onBlur) props.onBlur()
				}}
				onSelect={(event) => {
					const target = event.target as HTMLInputElement

					target.setSelectionRange(target.value.length, target.value.length)
				}}
				type={props.passwordMode ? 'password' : inputType}
				{...restInputProps}
			/>
			{Array.from({ length }, (_, index) => (
				<S.ValidationCodeCharacter
					appTheme={currentTheme}
					key={index}
					onClick={validationCodeInput.handleClick}
					inactive={validationCodeInput.isCharacterInactive(index)}
					selected={validationCodeInput.isCharacterSelected(index)}
					id={`field-${index}`}
					data-testid={`character-${index}`}
				>
					{props.passwordMode && validationCodeInput.getValue()[index]
						? '*'
						: validationCodeInput.getValue()[index] || (props.placeholder ?? '·')}
				</S.ValidationCodeCharacter>
			))}
		</S.ValidationCodeContainer>
	)
})
