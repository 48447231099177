import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface IDrawerFooterICPProps {
	footerSpaced?: boolean
	children: JSX.Element | JSX.Element[]
}

/**
 * COMPONENTE para rodape de drawer
 */
export function DrawerFooterICP(props: IDrawerFooterICPProps): JSX.Element {
	return <FooterSCP footerSpaced={props.footerSpaced}>{props.children}</FooterSCP>
}

const FooterSCP = styled.div<{ footerSpaced?: boolean }>`
	display: flex;
	background: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
	padding: 15px;
	position: sticky;
	right: 0;
	bottom: -24px;
	width: 100%;
	justify-content: ${(props) => (props.footerSpaced ? 'space-between' : 'flex-end')};
	align-items: center;
`
