import { IsOptional } from 'class-validator'
import type { MedicalTimetableAssignType } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/ModalMedicalTimetableAssignCP'
import { MedicalTimetableReasonAbsenceEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableReasonAbsenceEnum'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { DateRangeSearchRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/DateRangeSearchRequestDTO'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'


export class MedicalTimetableAbsenceFormModel extends FormModel {

    @IsRequired()
    doctorCode: number

    @IsRequired()
    type: MedicalTimetableAssignType

    @IsRequired()
    dateRange: DateRangeSearchRequestDTO

    @IsOptional()
    description?: string

    @IsRequired()
    reasonAbsence: MedicalTimetableReasonAbsenceEnum

    constructor(initialData?: ObjectPropsTP<MedicalTimetableAbsenceFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
