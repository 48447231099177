import { useAppSelector } from 'app/redux/hook'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { useEffect } from 'react'
import { ExamsRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import { AssignExamsRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/AssignExamsRequestDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'
import { ButtonTypeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonTypeTP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps {
	selectedExamCodes: number[]
	onSave: () => void
	appearance?: {
		size?: ButtonSizeTP
		onlyIcon?: boolean
		buttonType?: ButtonTypeTP
		danger?: boolean
	}
}

/**
 */
export function ButtonUnAssignExamCP(props: ICPProps): JSX.Element {
	const assignRequest = useRequest<void>('none')
	const doctorGroupPermissions = useAppSelector((state) => state.auth.doctorGroupPermissions)

	useEffect(onAssingRequestChange, [assignRequest.isAwaiting])

	/**
	 */
	function assignExams(): void {
		const dto: AssignExamsRequestDTO = {
			examCodes: props.selectedExamCodes,
		}
		assignRequest.runRequest(ExamsRequests.assign(dto))
	}

	/**
	 */
	function onAssingRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				assignRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSave()
	}

	// Tem que estar atrelado em algum grupo para exibir esse botao
	if (!DoctorGroupPermissionUtils.hasAnyPermissionsInAnyDoctorGroup(['isDoctorGroupAdmin', 'isDoctorGroupUser'], doctorGroupPermissions)) {
		return <></>
	}

	return (
		<ButtonCP
			icon={ThemeProjectCommon.icons.unAssignExam}
			onClick={assignExams}
			marginRight={5}
			type={props.appearance?.buttonType}
			danger={props.appearance?.danger}
			size={props.appearance?.size}
			loading={assignRequest.isAwaiting}
		>
			{props.appearance?.onlyIcon ? undefined : 'Remover Atribuição'}
		</ButtonCP>
	)
}
