import { Alert } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import { AlertCPTypeTP } from 'submodules/nerit-framework-ui/common/components/alert/inner/AlertCPTypeTP'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ISCPProps {
	borderType?: 'rounded' | 'square'
	onClick?: () => void
}

interface IAlertCPProps extends BasicStyleWrapperCPProps, ISCPProps {
	message: string | React.ReactNode
	description?: string | JSX.Element
	type: AlertCPTypeTP
	showIcon?: boolean
	show?: boolean
	closable?: boolean
}

/**
 * Exibe mensagem estatica para notificacao de forma destacada.
 */
export function AlertCP(props: IAlertCPProps): JSX.Element {
	if (props.show === false) return <></>

	return (
		<BasicStyleWrapperCP margin={props.margin}>
			<WrapperSCP onClick={props.onClick} borderType={props.borderType}>
				<Alert message={props.message} showIcon={props.showIcon} description={props.description} type={props.type} closable={props.closable} />
			</WrapperSCP>
		</BasicStyleWrapperCP>
	)
}

const WrapperSCP = styled.div<ISCPProps>`
	cursor: ${(props) => (!!props.onClick ? 'pointer' : undefined)};

	.ant-alert {
		border-radius: ${(props) => (props.borderType === 'square' ? '0px' : undefined)};
	}
	.ant-typography {
		color: ${ThemeFrameworkCommon.black};
	}
`
