import { useAppSelector } from 'app/redux/hook'
import { DoctorPermissionUtils } from 'modules/auth/permissions/DoctorPermissionUtils'
import { ModalDiscardMedicalReportCP } from 'modules/exams/components/medical-report/modal-discard-medical-report/ModalDiscardMedicalReportCP'
import { useState } from 'react'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'

interface ICPProps extends BasicStyleWrapperCPProps {
	exam: ExamResponseDTO
	onSave: () => void
	size?: ButtonSizeTP
	onClick?: () => void
}

/**
 */
export function ButtonDiscardMedicalReportCP(props: ICPProps): JSX.Element {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
	const loggedUser = useAppSelector((state) => state.auth.user)

	if (!DoctorPermissionUtils.canDiscarMedicalReport(props.exam, loggedUser)) return <></>

	return (
		<>
			<ButtonCP
				size={props.size ?? 'small'}
				onClick={() => {
					setIsModalVisible(true)
					if (!!props.onClick) props.onClick()
				}}
				icon={'rollback'}
				marginRight={props.margin?.right}
				marginLeft={props.margin?.left}
			>
				Desistir do Laudo
			</ButtonCP>

			<ModalDiscardMedicalReportCP
				examCode={props.exam.code}
				onCancel={() => setIsModalVisible(false)}
				onSave={() => {
					setIsModalVisible(false)
					props.onSave()
				}}
				visible={isModalVisible}
			/>
		</>
	)
}
