import { AppUtils } from 'app/utils/AppUtils'
import {
	ADMIN_BEERADS_SUB_MENU_CLINICS_KEY,
	ADMIN_BEERADS_SUB_MENU_DOCTORS_KEY,
	AdminBeeRadsScreenUtils,
	AdminBeeRadsViewTP,
} from 'modules/admin-beerads/screens/screen-admin-beerads/inner/AdminBeeRadsScreenUtils'
import { LayoutSubmenuCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/layout/LayoutSubmenuCP'

/**
 * Tela de listagem de medicos.
 */
export function ScreenAdminBeeRads(): JSX.Element {
	return (
		<LayoutSubmenuCP<AdminBeeRadsViewTP>
			loggedDomain={AppUtils.getDomain()}
			menuTitle={'Admin BeeRads'}
			defaultSubmenu={'clinicsView'}
			defaultOpenKeys={[ADMIN_BEERADS_SUB_MENU_CLINICS_KEY, ADMIN_BEERADS_SUB_MENU_DOCTORS_KEY]}
			submenuConfigs={AdminBeeRadsScreenUtils.getSubMenuConfigs()}
		/>
	)
}
