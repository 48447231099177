import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { FontAwsomeIconCP } from 'submodules/nerit-framework-ui/common/components/icon/FontAwsomeIconCP'
import { MenuItemCP } from 'submodules/nerit-framework-ui/common/components/menu/menu-item/MenuItemCP'
import { MenuCP } from 'submodules/nerit-framework-ui/common/components/menu/menu/MenuCP'
import { MenuItemTP } from 'submodules/nerit-framework-ui/common/components/menu/menu/inner/MenuItemTP'
import { SubMenuCP } from 'submodules/nerit-framework-ui/common/components/menu/sub-menu/SubMenuCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

interface ICPProps {
	menuTop: {
		topContent: JSX.Element
		options: MenuItemTP[]
	}
	menuBottom: {
		bottomContent: JSX.Element
		options: MenuItemTP[]
	}
	onSelectMenuItem?: () => void
}

/**
 * Sider do sistema para usuários da clínica
 */
export function MenuUserMainCP(props: ICPProps): JSX.Element {
	const routeLocation = useLocation()

	/**
	 * Renderiza os itens do menu.
	 */
	function renderMenuItems(options: MenuItemTP[]): JSX.Element[] {
		return options
			.filter((option) => option.enabled === undefined || option.enabled)
			.map((option) => {
				if (!option.menuItemsChildren) {
					return (
						<MenuItemCP
							key={`menu-opts-route${StringUtils.getSlugStyleString(option.title)}`}
							tooltipTitle={option.renderType === 'text' ? undefined : option.title}
							isActive={routeLocation.pathname.startsWith(NeritFrameworkRoutingHelper.mountPathWithCustomerDomain(option.route))}
							onClick={() => {
								NeritFrameworkRoutingHelper.historyPush(option.route)
								if (!!props.onSelectMenuItem) props.onSelectMenuItem()
							}}
							marginVertical={option.renderType === 'text' ? 0 : 15}
							align={option.renderType === 'text' ? 'left' : 'center'}
							disabled={option.disabled}
						>
							{option.renderType === 'text' ? (
								<>
									{!!option.icon ? (
										<FlexCP justify={'flex-start'}>
											<>{option.icon}</>
											<TextCP text={option.title} color={ThemeFrameworkCommon.black} marginLeft={10} />
										</FlexCP>
									) : (
										<TextCP text={option.title} color={ThemeFrameworkCommon.black} />
									)}
								</>
							) : (
								option.icon
							)}
						</MenuItemCP>
					)
				}

				return (
					<SubMenuCP
						key={`menu-opts-route${StringUtils.getSlugStyleString(option.title)}`}
						title={option.icon}
						onTitleClick={() => {
							NeritFrameworkRoutingHelper.historyPush(option.route)
							if (!!props.onSelectMenuItem) props.onSelectMenuItem()
						}}
						hideArrow={true}
						padding={'0 20px'}
						isActive={routeLocation.pathname.startsWith(NeritFrameworkRoutingHelper.mountPathWithCustomerDomain(option.route))}
					>
						<SubmenuTitleSCP>{option.title}</SubmenuTitleSCP>
						<ContentSubmenuSCP>{renderMenuItems(option.menuItemsChildren)}</ContentSubmenuSCP>
					</SubMenuCP>
				)
			})
	}

	return (
		<MenuContainerSCP>
			<MenuCP mode={'vertical'} selectable={false} inlineCollapsed={false}>
				{props.menuTop.topContent}
			</MenuCP>

			<MainMenuSCP>
				<MenuCP mode={'vertical'} selectable={false} inlineCollapsed={false}>
					{renderMenuItems(props.menuTop.options)}
				</MenuCP>

				<MenuCP mode={'vertical'} selectable={false} inlineCollapsed={false}>
					<MenuItemCP tooltipTitle="Ajuda" marginVertical={15} align="center">
						<FlexCP justify={'space-between'}>
							<a href="https://ajuda.vx.med.br/pt-BR" target="_blank" rel="noopener noreferrer">
								<FontAwsomeIconCP type={faQuestionCircle} color={ThemeProject.menuItemColor} style={{ fontSize: '20px' }} />
							</a>
						</FlexCP>
					</MenuItemCP>
					{renderMenuItems(props.menuBottom.options)}
				</MenuCP>
			</MainMenuSCP>

			<MenuCP mode={'vertical'} selectable={false} inlineCollapsed={false}>
				{props.menuBottom.bottomContent}
			</MenuCP>
		</MenuContainerSCP>
	)
}

const MenuContainerSCP = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	flex-direction: column;
	height: 100%;

	li.ant-menu-item {
		padding: 0 !important;
	}

	.ant-menu-submenu-title {
		display: flex;
		justify-content: center;
		align-items: center;

		:hover {
			opacity: 0.5;
		}
	}

	.anticon {
		margin: 0 !important;
	}
`

const SubmenuTitleSCP = styled.div`
	background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
	color: ${ThemeFrameworkCommon.browserDefaultColorDark};
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	border-bottom: solid 1px ${ThemeProject.secondary};
`

const ContentSubmenuSCP = styled.div`
	padding: 1px;

	.ant-menu-light .ant-menu-item {
		display: flex;
		color: #fff;
	}

	.ant-typography {
		color: ${ThemeFrameworkCommon.browserDefaultColorDark};
	}
`

const MainMenuSCP = styled.div`
	overflow-y: scroll;
	scrollbar-width: none;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
`
