import { CreateClinicReportConfigStepICP } from 'modules/clinic/components/wizard-create-clinic/steps/CreateClinicReportConfigStepICP'
import { CreateClinicStepICP } from 'modules/clinic/components/wizard-create-clinic/steps/CreateClinicStepICP'
import { CreateClinicUsersStepICP } from 'modules/clinic/components/wizard-create-clinic/steps/CreateClinicUsersStepICP'
import { FormClinicDoctorGroupCP } from 'modules/doctor-groups/components/doctor-groups/form-clinic-doctor-group/FormClinicDoctorGroupCP'
import { useState } from 'react'
import { ClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicResponseDTO'
import { WizardCP } from 'submodules/nerit-framework-ui/common/components/wizard/WizardCP'
// import { CreateClinicPriceStepICP } from 'modules/clinic/components/wizard-create-clinic/steps/CreateClinicPriceStepICP'

interface ICPProps {
	doctorGroupCode?: number
	onFinish: () => void
	initialData?: {
		cnpj?: string
		clinic?: ClinicResponseDTO
	}
}

/**
 * Processo de criar uma nova clinica
 */
export function WizardCreateClinicCP(props: ICPProps): JSX.Element {
	const [currentStep, setCurrentStep] = useState<number>()
	const [createdClinicCode, setCreatedClinicCode] = useState<number>()

	return (
		<WizardCP
			currentStep={currentStep}
			hideButtons={true}
			steps={[
				{
					key: 'clinic',
					title: 'Unidade Hosp.',
					content: (
						<CreateClinicStepICP
							onSave={(code) => {
								setCreatedClinicCode(code)
								setCurrentStep(!!props.doctorGroupCode ? 1 : 2)
							}}
							initialData={props.initialData}
						/>
					),
				},
				{
					key: 'plan',
					title: 'Exames',
					hide: !props.doctorGroupCode,
					content: (
						<>
							{createdClinicCode && !!props.doctorGroupCode ? (
								<FormClinicDoctorGroupCP
									doctorGroupCode={props.doctorGroupCode}
									clinicCode={createdClinicCode}
									onSave={() => setCurrentStep(2)}
								/>
							) : (
								<></>
							)}
						</>
					),
				},
				// {
				// 	key: 'price',
				// 	title: 'Preço',
				// 	content: (
				// 		<>
				// 			{
				// 				(createdClinicCode && !!props.doctorGroupCode)
				// 					?
				// 					<CreateClinicPriceStepICP
				// 						clinicCode={createdClinicCode}
				// 						doctorGroupCode={props.doctorGroupCode}
				// 						onSave={() => setCurrentStep(3)}
				// 					/>
				// 					:
				// 					<></>
				// 			}
				// 		</>
				// 	),
				// },
				{
					key: 'users',
					title: 'Usuários',
					content: (
						<>
							{createdClinicCode ? (
								<CreateClinicUsersStepICP
									clinicCode={createdClinicCode}
									doctorGroupCode={props.doctorGroupCode}
									onSave={() => setCurrentStep(3)} //Mudar para 4 quando descomentar o código acima
								/>
							) : (
								<></>
							)}
						</>
					),
				},
				{
					key: 'reportConfig',
					title: 'Configurar Laudo',
					content: <>{createdClinicCode ? <CreateClinicReportConfigStepICP clinicCode={createdClinicCode} onSave={props.onFinish} /> : <></>}</>,
				},
			]}
		/>
	)
}
