import { ModalTemplateCP } from 'modules/template/components/modal-template/ModalTemplateCP'
import { useState } from 'react'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'

interface ICPProps {
	doctorGroupCode?: number
	clinicCode?: number
	onSave: () => void
}

export function ButtonCreateMedicalReportTemplateCP(props: ICPProps): JSX.Element {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

	function onSave(): void {
		setIsModalVisible(false)
		props.onSave()
	}

	return (
		<>
			<ButtonCP
				onClick={() => {
					setIsModalVisible(true)
				}}
			>
				Novo template de laudo
			</ButtonCP>

			<ModalTemplateCP
				type={TemplateTypeEnum.TEMPLATE}
				onCancel={() => setIsModalVisible(false)}
				onSave={onSave}
				visible={isModalVisible}
				data={{
					initialValues: {
						owner: {
							doctorGroupCode: props.doctorGroupCode,
							clinicCode: props.clinicCode,
						},
					},
				}}
			/>
		</>
	)
}
