import { VxWindModalAuthDigitalCertificateCP } from 'app/components/vx-wind/vx-wind-modal-auth-digital-certificate/VxWindModalAuthDigitalCertificateCP'
import { useAppSelector } from 'app/redux/hook'
import { ClinicPermissionUtils } from 'modules/auth/permissions/ClinicPermissionUtils'
import { CommonPermissionUtils } from 'modules/auth/permissions/CommonPermissionUtils'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { SelectClinicFromLoggedUserCP } from 'modules/clinic/components/select-clinic/SelectClinicFromLoggedUserCP'
import { ScreenContentHomeClinicUser } from 'modules/reports/screens/screen-home/contents/ScreenContentHomeClinicUser'
import { ScreenContentHomeDoctor } from 'modules/reports/screens/screen-home/contents/ScreenContentHomeDoctor'
import { ScreenContentHomeDoctorGroupAdmin } from 'modules/reports/screens/screen-home/contents/ScreenContentHomeDoctorGroupAdmin'
import { ScreenContentHomeDoctorGroupAssignAnalysis } from 'modules/reports/screens/screen-home/contents/ScreenContentHomeDoctorGroupAssignAnalysis'
import { useState } from 'react'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { TabsCP } from 'submodules/nerit-framework-ui/common/components/tabs/TabsCP'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

/**
 * Mostra a Home especifica para cara tipo de usuario.
 */
export function ScreenHome(): JSX.Element {
	const authData = useAppSelector((state) => state.auth)
	const screenSize = useScreenSize()
	const [clinicCode, setClinicCode] = useState<number>()
	const [reload, setReload] = useState<number>()
	const [isModalVisible, setIsModalVisible] = useState<boolean>(true)

	return (
		<>
			<LayoutCP
				titleBrowser={'Página Inicial'}
				header={
					<HeaderCP title={'Página Inicial'}>
						{!screenSize.xs && (
							<SelectClinicFromLoggedUserCP
								value={{
									byOnChange: {
										value: clinicCode,
										onChange: setClinicCode,
									},
								}}
								width={300}
								allowClear={true}
							/>
						)}
					</HeaderCP>
				}
				content={
					<ContentCP overflowVertical={true}>
						<TabsCP
							margin={{ top: 20, bottom: 20, left: 20, right: 20 }}
							padding={{ bottom: screenSize.xs ? 100 : undefined }}
							extraContent={
								<ButtonCP color={ThemeFrameworkCommon.black} icon={'reload'} onClick={() => setReload(TableUtils.getReloadNumber())} />
							}
							tabs={[
								{
									key: 'doctor',
									title: 'Médico',
									hide: !!clinicCode
										? !ClinicPermissionUtils.hasAnyPermissionsInClinic(clinicCode, ['isDoctor'], authData.user)
										: !CommonPermissionUtils.canAccessByType(UserTypeEnum.DOCTOR, authData.user),
									content: <ScreenContentHomeDoctor clinicCode={clinicCode} />,
								},
								{
									key: 'clinicUser',
									hide: !!clinicCode
										? !ClinicPermissionUtils.hasAnyPermissionsInClinic(clinicCode, ['isUser', 'isAdmin'], authData.user)
										: !ClinicPermissionUtils.hasAnyPermissionsInAnyClinic(['isUser', 'isAdmin'], authData.clinicPermissions),
									title: 'Unidade Hospitalar',
									content: <ScreenContentHomeClinicUser clinicCode={clinicCode} />,
								},
								{
									key: 'doctorGroupAdmin',
									title: 'Indicadores do Grupo de Médicos',
									hide: !!clinicCode
										? !ClinicPermissionUtils.hasAnyPermissionsInClinic(
												clinicCode,
												['isDoctorGroupAdmin', 'isDoctorGroupUser'],
												authData.user,
											)
										: !DoctorGroupPermissionUtils.hasAnyPermissionsInAnyDoctorGroup(
												['isDoctorGroupAdmin', 'isDoctorGroupUser'],
												authData.doctorGroupPermissions,
											),
									content: <ScreenContentHomeDoctorGroupAdmin clinicCode={clinicCode} />,
								},
								{
									key: 'doctorGroupAssignAnalysis',
									title: 'Análise de Atribuições',
									hide: !!clinicCode
										? !ClinicPermissionUtils.hasAnyPermissionsInClinic(
												clinicCode,
												['isDoctorGroupAdmin', 'isDoctorGroupUser'],
												authData.user,
											)
										: !DoctorGroupPermissionUtils.hasAnyPermissionsInAnyDoctorGroup(
												['isDoctorGroupAdmin', 'isDoctorGroupUser'],
												authData.doctorGroupPermissions,
											),
									content: <ScreenContentHomeDoctorGroupAssignAnalysis clinicCode={clinicCode} reload={reload} />,
								},
							]}
						/>

						{isModalVisible && (
							<VxWindModalAuthDigitalCertificateCP
								onCancel={() => setIsModalVisible(false)}
								onSuccess={() => setIsModalVisible(false)}
								onClose={() => setIsModalVisible(false)}
							/>
						)}
					</ContentCP>
				}
			/>
		</>
	)
}
