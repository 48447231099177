import { ButtonAddDoctorGroupCP } from 'modules/doctor-groups/components/doctor-groups/button-add-doctor-group/ButtonAddDoctorGroupCP'
import { DoctorGroupFormModel } from 'modules/doctor-groups/components/doctor-groups/drawer-doctor-group-data/inner/DoctorGroupFormModel'
import { DoctorGroupDigitalCertificateDataFormModel } from 'modules/doctor-groups/components/doctor-groups/form-doctor-group-digital-certificate-data/inner/DoctorGrouṕDigitalCertificateDataFormModel'
import { TabsDoctorGroupDataCP } from 'modules/doctor-groups/components/doctor-groups/tabs-doctor-group-data/TabsDoctorGroupDataCP'
import { useEffect, useState } from 'react'
import { DoctorGroupsRequests } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/DoctorGroupsRequests'
import { DoctorGroupSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/requests/DoctorGroupSaveRequestDTO'
import { DoctorGroupResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/responses/DoctorGroupResponseDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { LayoutSubmenuContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/content/LayoutSubmenuContentCP'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { AddressFormModel } from 'submodules/nerit-framework-ui/features/address/components/form-address/inner/AddressFormModel'
import { AddressFormUtils } from 'submodules/nerit-framework-ui/features/address/components/form-address/inner/AddressFormUtils'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps {
	doctorGroupCode?: number
}

/**
 */
export function ScreenContentDoctorGroupDataCP(props: ICPProps): JSX.Element {
	const [doctorGroupFormModel, setDoctorGroupFormModel] = useState<DoctorGroupFormModel>()
	const doctorGroupFormStateManager = useFormStateManager<DoctorGroupFormModel>(doctorGroupFormModel)

	const [addressFormValidator, setAddressFormValidator] = useState<AddressFormModel>()
	const addressFormStateManager = useFormStateManager<AddressFormModel>(addressFormValidator)

	const [doctorGroupDigitalCertificateModel, setDoctorGroupDigitalCertificateModel] = useState<DoctorGroupDigitalCertificateDataFormModel>()
	const doctorGroupDigitalCertificateFormStateManager =
		useFormStateManager<DoctorGroupDigitalCertificateDataFormModel>(doctorGroupDigitalCertificateModel)

	const getDoctorGroupRequest = useRequest<DoctorGroupResponseDTO>()
	useEffect(onGetDoctorGroupRequestChange, [getDoctorGroupRequest.isAwaiting])

	const saveGroupRequest = useRequest<void>('none')
	useEffect(onSaveGroupRequestChange, [saveGroupRequest.isAwaiting])

	useEffect(init, [])

	/**
	 * Inicializa
	 */
	function init(): void {
		setDoctorGroupFormModel(new DoctorGroupFormModel())
		setAddressFormValidator(new AddressFormModel())

		if (!!props.doctorGroupCode) getDoctorGroupRequest.runRequest(DoctorGroupsRequests.getOne(props.doctorGroupCode))
	}

	/**
	 */
	function onGetDoctorGroupRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getDoctorGroupRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = getDoctorGroupRequest.responseData!
		setDoctorGroupFormModel(
			new DoctorGroupFormModel({
				cnpj: result.cnpj,
				crm: result.crm,
				name: result.name,
				fantasyName: result.fantasyName,
				maxExamsPerDoctor: result.config?.maxExamsPerDoctor,
				hasAdministrativeAnalysis: result.config?.hasAdministrativeAnalysis,
				isAwaitingFirstStatus: result.config?.isAwaitingFirstStatus,
				shouldValidateExamDescription: result.config?.shouldValidateExamDescription,
				blockMultipleMedicalReportsOnExam: result.config?.blockMultipleMedicalReportsOnExam,
				canSeeExamOutsideMedicalReport: result.config?.canSeeExamOutsideMedicalReport,
				hasDigitalCertificateData: !!result.config?.digitalCertificateData ? true : false,
			}),
		)
		setDoctorGroupDigitalCertificateModel(
			new DoctorGroupDigitalCertificateDataFormModel({
				clientId: result.config?.digitalCertificateData?.clientId,
				clientSecret: result.config?.digitalCertificateData?.clientSecret,
				endpoint: result.config?.digitalCertificateData?.endpoint,
				partner: result.config?.digitalCertificateData?.partner,
			}),
		)
		setAddressFormValidator(AddressFormUtils.formatFormModel(result.address))
	}

	/**
	 */
	async function save(): Promise<void> {
		if (
			!(await FormStateManagerUtils.validateRequiredFields(doctorGroupFormStateManager)) ||
			!(await FormStateManagerUtils.validateRequiredFields(addressFormStateManager))
		)
			return

		const formValues = doctorGroupFormStateManager.getFormValues()!

		if (formValues.hasDigitalCertificateData && !(await FormStateManagerUtils.validateRequiredFields(doctorGroupDigitalCertificateFormStateManager)))
			return

		const digitalCertificateValues = doctorGroupDigitalCertificateFormStateManager.getFormValues()
		const dto: DoctorGroupSaveRequestDTO = {
			cnpj: formValues.cnpj,
			crm: formValues.crm,
			name: formValues.name,
			fantasyName: formValues.fantasyName,
			config: {
				hasAdministrativeAnalysis: formValues.hasAdministrativeAnalysis ?? false,
				maxExamsPerDoctor: formValues.maxExamsPerDoctor,
				isAwaitingFirstStatus: formValues.isAwaitingFirstStatus,
				shouldValidateExamDescription: formValues.shouldValidateExamDescription,
				blockMultipleMedicalReportsOnExam: formValues.blockMultipleMedicalReportsOnExam,
				canSeeExamOutsideMedicalReport: formValues.canSeeExamOutsideMedicalReport,
				digitalCertificateData: formValues.hasDigitalCertificateData
					? {
							clientId: digitalCertificateValues?.clientId,
							clientSecret: digitalCertificateValues?.clientSecret,
							endpoint: digitalCertificateValues?.endpoint,
							partner: digitalCertificateValues?.partner,
					  }
					: undefined,
			},
			address: AddressFormUtils.formatDto(addressFormStateManager.getFormValues()),
		}

		if (!!props.doctorGroupCode) saveGroupRequest.runRequest(DoctorGroupsRequests.update(props.doctorGroupCode, dto))
		else saveGroupRequest.runRequest(DoctorGroupsRequests.create(dto))
	}

	/**
	 */
	function onSaveGroupRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				saveGroupRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return
	}

	return (
		<>
			<LayoutSubmenuContentCP>
				<FlexCP justify="flex-end">
					<ButtonAddDoctorGroupCP />
				</FlexCP>
				<CardCP margin="20px 0px">
					<TabsDoctorGroupDataCP
						formStateManager={doctorGroupFormStateManager}
						addressFormStateManager={addressFormStateManager}
						doctorGroupCode={props.doctorGroupCode}
						digitalCertificateStateManager={doctorGroupDigitalCertificateFormStateManager}
					/>

					<FlexCP margin={{ top: 30 }} justify={'flex-end'}>
						<ButtonCP onClick={save} type={'primary'} loading={saveGroupRequest.isAwaiting}>
							Salvar
						</ButtonCP>
					</FlexCP>
				</CardCP>
			</LayoutSubmenuContentCP>
		</>
	)
}
