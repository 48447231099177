import ApexCharts, { ApexOptions } from 'apexcharts'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'

interface ICPProps {
	options: ApexOptions
}

/**
 */
export function ChartApexCP(props: ICPProps): JSX.Element {
	const chartRef = useRef<any>(null)
	const chartElement = useRef<any>(null)

	useEffect(onMount, [])
	useEffect(updateChartData, [props.options])

	function onMount(): void {
		chartElement.current = new ApexCharts(chartRef.current, props.options)

		// chartElement.current.updateSeries(props.options.series, true)
		// chartElement.current.updateOptions({
		//     xaxis: { categories: props.options.labels }
		// }, undefined, true, true)

		chartElement.current.render()
	}

	function updateChartData(): void {
		if (!props.options || !chartElement.current) return

		chartElement.current.updateOptions(props.options)
	}

	return (
		<ChartWrapperSCP>
			<div ref={chartRef} />
		</ChartWrapperSCP>
	)
}

const ChartWrapperSCP = styled.div`
	height: 100%;
	width: 100%;
`
