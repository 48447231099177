import styled, { css, keyframes } from 'styled-components'

interface ICPProps {
	isCapturing?: boolean
}

export function VxWindMicIconCP({ isCapturing }: ICPProps): JSX.Element {
	return (
		<WrapperIcon isCapturing={!!isCapturing}>
			<svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="currentColor">
				<path
					d="M6.49987 3C8.22575 3 9.6249 4.39911 9.6249 6.125V8.625C9.6249 10.3509 8.22575 11.75 6.49987 11.75C4.77395 11.75 3.37485 10.3509 3.37485 8.625V6.125C3.37485 4.39911 4.77395 3 6.49987 3ZM0.90918 9.25H2.16915C2.47242 11.3702 4.2958 13 6.49987 13C8.70387 13 10.5272 11.3702 10.8306 9.25H12.0905C11.8023 11.8573 9.7321 13.9274 7.12487 14.2157V16.75H5.87487V14.2157C3.26759 13.9274 1.1974 11.8573 0.90918 9.25Z"
					fill="url(#paint0_linear_205_2657)"
				/>
				<path
					d="M18 4.00783C15.8543 4.13723 14.1372 5.85433 14.0078 8H13.9921C13.8627 5.85433 12.1457 4.13723 10 4.00783V3.99217C12.1457 3.86277 13.8627 2.14573 13.9921 0H14.0078C14.1372 2.14573 15.8543 3.86277 18 3.99217V4.00783Z"
					fill="url(#paint1_linear_205_2657)"
				/>
				<defs>
					<linearGradient id="paint0_linear_205_2657" x1="6.49984" y1="3" x2="6.49984" y2="16.75" gradientUnits="userSpaceOnUse">
						<stop stop-color="currentColor" />
						<stop offset="0.175" stop-color="currentColor" />
						<stop offset="1" stop-color="currentColor" />
					</linearGradient>
					<linearGradient id="paint1_linear_205_2657" x1="14" y1="0" x2="14" y2="8" gradientUnits="userSpaceOnUse">
						<stop stop-color="currentColor" />
						<stop offset="0.175" stop-color="currentColor" />
						<stop offset="1" stop-color="currentColor" />
					</linearGradient>
				</defs>
			</svg>
		</WrapperIcon>
	)
}

const flickering = keyframes`
	0% {
		opacity: 0.2;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.2;
	}
`

const WrapperIcon = styled.div<{ isCapturing: boolean }>`
	${({ isCapturing }) =>
		isCapturing &&
		css`
			animation: ${flickering} 0.7s infinite;
		`}
	color: #067B8E;
	fill: #067b8e;
`
