import { MEDICAL_REPORT_COUNTER_PARAM } from 'app/components/report-counter-indicator/MedicalReportCounterCP'
import { useAppSelector } from 'app/redux/hook'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import { AppUtils } from 'app/utils/AppUtils'
import { AppConfig } from 'config/AppConfig'
import {
	PARAM_SCREEN_UPLOAD_FILES_AUTHENTICATION,
	PARAM_SCREEN_UPLOAD_FILES_EXAM_ID,
	PARAM_SCREEN_UPLOAD_FILES_TYPE,
} from 'modules/exams/screens/screen-exam-upload-files/ScreenExamUploadFiles'
import { ExamUploadFileTypeEnum } from 'modules/exams/screens/screen-exam-upload-files/inner/ExamUploadFileTypeEnum'
import styled from 'styled-components'
import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { PatientPortalAccessUtils } from 'submodules/beerads-sdk/services/patient-portal/utils/PatientPortalAccessUtils'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { DescriptionCP } from 'submodules/nerit-framework-ui/common/components/description/DescriptionCP'
import { LinkHtmlCP } from 'submodules/nerit-framework-ui/common/components/link-html/LinkHtmlCP'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'

/**
 * Relacao de Links publicos
 *
 * Acessar o dev-test passando ?publicLinks = true
 */
export function ScreenContentDevTestPublicLinks(): JSX.Element {
	const devTestRequest = useRequest<void>('none')
	const authData = useAppSelector((selector) => selector.auth)

	/**
	 */
	function mountUrlUpload(type: ExamUploadFileTypeEnum): string {
		let url = `${AppConfig.getInstance().uiBaseUrl}/${AppUtils.getDomain()!}/${PublicRouter.UPLOAD_EXAM_FILES}`

		url = `${url}?${PARAM_SCREEN_UPLOAD_FILES_EXAM_ID}=71bfd04e-31c8-4f3a-a44b-6491b41a4ad3`
		url = `${url}&${PARAM_SCREEN_UPLOAD_FILES_TYPE}=${type}`
		url = `${url}&${PARAM_SCREEN_UPLOAD_FILES_AUTHENTICATION}=${authData.token}`

		return url
	}

	return (
		<ContentCP overflowVertical={true}>
			<WrapperSCP>
				<div>
					<ButtonCP
						onClick={() =>
							NeritFrameworkRoutingHelper.historyPush(
								`${window.location.pathname}?publicLinks=true&${MEDICAL_REPORT_COUNTER_PARAM}=${TableUtils.getReloadNumber()}`,
							)
						}
					>
						Recarregar Laudos do Dia
					</ButtonCP>

					<ButtonCP
						loading={devTestRequest.isAwaiting}
						onClick={() =>
							devTestRequest.runRequest({
								baseUrlType: BeeRadsApiEnum.BEERADS_API,
								url: 'dev-test',
								method: HttpMethodEnum.GET,
							})
						}
					>
						Chamar API teste
					</ButtonCP>

					<ButtonCP onClick={() => NeritFrameworkRoutingHelper.openInNewTab(mountUrlUpload(ExamUploadFileTypeEnum.ATTACHMENT))}>
						Upload de Anexos de Exame via QR CODE
					</ButtonCP>

					<ButtonCP onClick={() => NeritFrameworkRoutingHelper.openInNewTab(mountUrlUpload(ExamUploadFileTypeEnum.EXAM_IMAGE))}>
						Upload de Imagens Exames via QR CODE
					</ButtonCP>

					<ButtonCP
						onClick={() =>
							NeritFrameworkRoutingHelper.openInNewTab(
								NeritFrameworkRoutingHelper.mountPathWithCustomerDomain(PublicRouter.PATIENT_PORTAL_LOGIN),
							)
						}
					>
						Laudos Online
					</ButtonCP>

					<ButtonCP
						onClick={() =>
							NeritFrameworkRoutingHelper.openInNewTab(
								NeritFrameworkRoutingHelper.mountPathWithCustomerDomain(PublicRouter.MEDICAL_REPORT_VIDEO),
							)
						}
					>
						Video Laudo
					</ButtonCP>

					<ButtonCP
						onClick={() =>
							NeritFrameworkRoutingHelper.openInNewTab(
								NeritFrameworkRoutingHelper.mountPathWithCustomerDomain(`${PublicRouter.USER_LOGIN}?forceLogin=true`),
							)
						}
					>
						Forçar Login
					</ButtonCP>
				</div>

				<div>
					<DescriptionCP
						title={'URLs do Laudo ONLINE'}
						numberOfColumns={1}
						items={[
							{
								label: 'Full URL',
								text: (
									<LinkHtmlCP href={PatientPortalAccessUtils.getBeeRadsLaudoUrl(true, AppUtils.getDomain())} type={'link'}>
										{PatientPortalAccessUtils.getBeeRadsLaudoUrl(true, AppUtils.getDomain())}
									</LinkHtmlCP>
								),
							},
							{
								label: 'SHORT URL',
								text: PatientPortalAccessUtils.getBeeRadsLaudoUrl(false, AppUtils.getDomain()),
							},
							{
								label: 'Full URL PARAM',
								text: PatientPortalAccessUtils.getBeeRadsLaudoUrl(true, AppUtils.getDomain()),
							},
							{
								label: 'SHORT URL PARAM',
								text: PatientPortalAccessUtils.getBeeRadsLaudoUrl(false, AppUtils.getDomain()),
							},
						]}
					/>
				</div>
			</WrapperSCP>
		</ContentCP>
	)
}

const WrapperSCP = styled.div`
	padding: 20px;
	display: flex;
	justify-content: space-between;

	button {
		width: 400px;
		margin-top: 20px;

		span {
			width: 100%;
		}
	}
`
