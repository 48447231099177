import { IsEnum, IsNumber, IsOptional, IsString } from 'class-validator'
import { FeedbackEventEnum } from 'submodules/beerads-sdk/services/feedbacks/enum/FeedbackEventEnum'
import type { FeedbackMetadataTP } from 'submodules/beerads-sdk/services/feedbacks/types/MetadataTP'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'

export class FeedbackFormModel<T> extends FormModel {
	@IsNumber()
	score: number

	@IsEnum(FeedbackEventEnum)
	event: FeedbackEventEnum

	@IsString()
	@IsOptional()
	comments?: string

	@IsRequired()
	metadata: FeedbackMetadataTP<T>

	constructor(initialData?: ObjectPropsTP<FeedbackFormModel<T>>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
