import { FiltersExamsInnerICP } from 'modules/exams/components/exams/filters-exams/inner/FiltersExamsInnerICP'
import { ExamFilterFormModel } from 'modules/exams/components/exams/table-exams/filters/ExamFilterFormModel'
import { useState } from 'react'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { DrawerCP } from 'submodules/nerit-framework-ui/common/components/drawer/DrawerCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'

interface ICPProps {
	examStatusGroup?: ExamStatusGroupEnum
	isLoading: boolean
	onFilter: () => void
	isScreenInitialized: boolean
	filterFormStateManager: IFormStateManager<ExamFilterFormModel>
	fieldsConfig?: {
		disableStatus?: boolean
		disableDoctorGroup?: boolean
	}
	originalExamFilters?: ExamSearchRequestDTO
}

/**
 * Componente de filtros da tela de exames.
 */
export function FiltersExamsCP(props: ICPProps): JSX.Element {
	const screenSize = useScreenSize()
	const [isFilterDrawerVisible, setIsFilterDrawerVisible] = useState<boolean>(false)

	return (
		<>
			{screenSize.smd ? (
				<FlexCP justify={'center'} margin={{ top: 10 }}>
					<ButtonCP icon={'filter'} type={'primary'} onClick={() => setIsFilterDrawerVisible(true)} loading={props.isLoading}>
						Filtros
					</ButtonCP>
				</FlexCP>
			) : (
				<FiltersExamsInnerICP
					isLoading={props.isLoading}
					onFilter={props.onFilter}
					isScreenInitialized={props.isScreenInitialized}
					filterFormStateManager={props.filterFormStateManager}
					examStatusGroup={props.examStatusGroup}
					fieldsConfig={props.fieldsConfig}
					originalExamFilters={props.originalExamFilters}
				/>
			)}

			<DrawerCP placement={'bottom'} title={'Filtros'} visible={isFilterDrawerVisible} onClose={() => setIsFilterDrawerVisible(false)} height={450}>
				<FiltersExamsInnerICP
					isLoading={props.isLoading}
					onFilter={() => {
						setIsFilterDrawerVisible(false)
						props.onFilter()
					}}
					isScreenInitialized={props.isScreenInitialized}
					filterFormStateManager={props.filterFormStateManager}
					examStatusGroup={props.examStatusGroup}
					fieldsConfig={props.fieldsConfig}
					originalExamFilters={props.originalExamFilters}
				/>
			</DrawerCP>
		</>
	)
}
