import { IAuthState } from 'app/redux/slices/auth/AuthSlice'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { AuditResponseDTO } from 'submodules/beerads-sdk/services/exams/audits/dtos/response/AuditResponseDTO'
import { AuditStatusEnum } from 'submodules/beerads-sdk/services/exams/audits/enums/AuditStatusEnum'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'

/**
 */
export class DoctorPermissionUtils {
	/**
	 */
	static canDiscarMedicalReport(exam: ExamResponseDTO, loggedUser: IAuthState['user']): boolean {
		// So pode exames em progresso
		if (![ExamStatusEnum.IN_PROGRESS, ExamStatusEnum.RECTIFICATION].includes(exam.status)) return false

		// Se for o medico, mas tiver analise administrativa, nao pode usar esse botao
		if (exam.doctor?.code === loggedUser?.code) return !exam.doctorGroup?.config?.hasAdministrativeAnalysis

		// Se nao for o medico, o usuario deve ter permissao de usuario do grupo
		if (exam.doctor?.code !== loggedUser?.code)
			return (
				!!exam.doctorGroup &&
				DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(loggedUser, exam.doctorGroup.code, ['isDoctorGroupUser', 'isDoctorGroupAdmin'])
			)

		return false
	}

	/**
	 */
	static canDoMedicalReport(
		loggedUser: IAuthState['user'],
		examStatus: ExamStatusEnum,
		doctorGroupCode?: number,
		examDoctorCode?: number,
		examRevisionDoctorCode?: number,
		examDoctorsAssignedCodes?: number[],
	): boolean {
		if (!loggedUser?.isDoctor) return false

		const loggedUserCode = loggedUser.code

		if (examStatus === ExamStatusEnum.AWAITING) {
			if (!!doctorGroupCode && DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(loggedUser, doctorGroupCode, ['isDoctorAdmin'])) return true

			if (ArrayUtils.isEmpty(examDoctorsAssignedCodes)) return true

			if (examDoctorsAssignedCodes?.includes(loggedUserCode)) return true

			return false
		}

		if ([ExamStatusEnum.RECTIFICATION, ExamStatusEnum.SIGNED, ExamStatusEnum.SIGNED_RECTIFICATION].includes(examStatus)) {
			if (!!doctorGroupCode && DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(loggedUser, doctorGroupCode, ['isDoctorAdmin'])) return true

			if (examDoctorsAssignedCodes?.includes(loggedUserCode)) return true

			return examDoctorCode === loggedUserCode || examRevisionDoctorCode === loggedUserCode
		}

		if ([ExamStatusEnum.IN_PROGRESS].includes(examStatus)) return examDoctorCode === loggedUserCode

		if (examStatus === ExamStatusEnum.DONE) return examDoctorCode === loggedUserCode || examRevisionDoctorCode === loggedUserCode

		return false
	}

	/**
	 */
	static canAudit(audit: AuditResponseDTO, doctorGroupCode: number, loggedUser: IAuthState['user']): boolean {
		if (!loggedUser?.isDoctor) return false

		const loggedUserCode = loggedUser.code

		// O proprio medico e esta em progresso
		if (audit.status === AuditStatusEnum.IN_PROGRESS && audit.doctor.code === loggedUserCode) return true

		if (audit.status === AuditStatusEnum.AWAITING) {
			if (audit.doctor.code === loggedUserCode) return true

			if (DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(loggedUser, doctorGroupCode, ['isDoctorAdmin'])) return true
		}

		return false
	}
}
