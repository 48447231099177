import { IsEnum, IsOptional } from 'class-validator'
import { DoctorGroupDoctorDutyProgramEnum } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/enums/DoctorGroupDoctorDutyProgramEnum'
import { DoctorLevelEnum } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/enums/DoctorLevelEnum'
import { DoctorRoleInDoctorGroupEnum } from 'submodules/beerads-sdk/services/doctor-groups/doctor-role-in-doctor-group/enum/DoctorRoleInDoctorGroupEnum'
import { ExamSpecialtyEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamSpecialtyEnum'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { IsArray } from 'submodules/nerit-framework-utils/validators/decorators/IsArray'

export class DoctorGroupDoctorFormModel extends FormModel {
	@IsOptional()
	specialty?: ExamSpecialtyEnum

	@IsOptional()
	doctorLevel?: DoctorLevelEnum

	@IsArray()
	@IsOptional()
	teamCodes?: number[]

	@IsOptional()
	isAuditor?: boolean

	@IsEnum(DoctorRoleInDoctorGroupEnum, { each: true })
	@IsOptional()
	doctorRole?: DoctorRoleInDoctorGroupEnum
	//TODO: no momento não é necessário
	// @IsOptional()
	// managerCode?: number

	@IsEnum(DoctorGroupDoctorDutyProgramEnum, { each: true })
	@IsOptional()
	doctorDutyProgram?: DoctorGroupDoctorDutyProgramEnum

	constructor(initialData?: ObjectPropsTP<DoctorGroupDoctorFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
