import { PayloadAction } from '@reduxjs/toolkit'
import { IAuthState } from 'app/redux/slices/auth/AuthSlice'
import { AppUtils } from 'app/utils/AppUtils'
import { PatientPortalLoginResponseDTO } from 'submodules/beerads-sdk/services/patient-portal/dtos/response/PatientPortalLoginResponseDTO'

export function setLoggedPatientReducer(state: IAuthState, action: PayloadAction<PatientPortalLoginResponseDTO>): IAuthState {
	AppUtils.refreshToken(action.payload.token)

	return {
		...state,
		patientPortal: action.payload,
	}
}
