import { ThemeProject } from 'config/theme/project/ThemeProject'
import { MedicalTimetableAbsenceFormModel } from 'modules/medical-timetable/components/modals/modal-medical-timetable-absence/inner/MedicalTimetableAbsenceFormModel'
import { MedicalTimetableReasonAbsenceEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableReasonAbsenceEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'

interface ICPProps {
	formStateManager: IFormStateManager<MedicalTimetableAbsenceFormModel>
	onLoading?: boolean
	onSave: (shouldDeleteFollowingDays?: boolean) => void
	onCreateAbsence: () => void
	date: Date
}

export function ButtonMedicalTimetableUnassignRecurrencyCP(props: ICPProps): JSX.Element {
	const formatedDate = DateUtils.formatDate(props.date, DateFormatEnum.BR_DAY_MONTH)

	return (
		<>
			<TextCP
				text={`Você deseja adicionar ausência deste médico neste dia específico ${formatedDate} OU deseja apagar a recorrência deste dia em diante?`}
			/>
			<TextCP
				text={'Apagar a recorrência não gera registro no sistema e não será possível gerar dados futuramente. Ele será removido da visualização.'}
				size="small"
				color={ThemeProject.gray700}
				fontStyle="italic"
			/>
			<TextAreaCP
				placeholder={'Escreva os detalhes do motivo da ausência (opcional)'}
				formStateManager={props.formStateManager}
				fieldName={'description'}
				minRows={4}
			/>
			<FlexCP margin={{ top: 35 }} flexDirection="column">
				<FlexCP justify={'space-between'} margin={{ top: 30 }}>
					<ButtonCP
						size={'small'}
						onClick={() => {
							props.formStateManager.changeFieldValue('reasonAbsence', MedicalTimetableReasonAbsenceEnum.MISTAKE)
							props.onSave(true)
						}}
						loading={props.onLoading}
						danger
						confirmMsg={'Você tem certeza que deseja apagar a recorrência, essa ação não pode ser desfeita!'}
					>
						Remover daqui em diante
					</ButtonCP>

					<ButtonCP
						size={'small'}
						onClick={() => {
							props.formStateManager.changeFieldValue('reasonAbsence', MedicalTimetableReasonAbsenceEnum.NO_SHOW)
							props.onCreateAbsence()
						}}
						loading={props.onLoading}
					>
						Adicionar ausência neste dia
					</ButtonCP>
				</FlexCP>
			</FlexCP>
		</>
	)
}
