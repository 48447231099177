import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'

export type RouteConfigTP = {
	component: React.ComponentType<RouteComponentProps<any>>
	path?: string
	exact?: boolean
}

interface ICPProps {
	routes: RouteConfigTP[]
}

/**
 */
export function RouterSwitchCP(props: ICPProps): JSX.Element {
	return (
		<Switch>
			{props.routes.map((routeConfig, index) => (
				<Route
					key={index}
					path={!!routeConfig.path ? NeritFrameworkRoutingHelper.mountPathWithCustomerDomain(routeConfig.path) : undefined}
					exact={routeConfig.exact}
					component={routeConfig.component}
				/>
			))}
		</Switch>
	)
}
