import { ContentCP } from '_old/main/common/components/screen-layout/content/ContentCP'
import { useAppSelector } from 'app/redux/hook'
import { ScreenContentProfileDoctor } from 'modules/person/screens/screen-profile/content/ScreenContentProfileDoctor'
import { ScreenContentProfileUser } from 'modules/person/screens/screen-profile/content/ScreenContentProfileUser'
import { useEffect, useState } from 'react'
import { AuthRequests } from 'submodules/beerads-sdk/services/auth/AuthRequests'
import { PersonResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PersonResponseDTO'
import { PeopleRequests } from 'submodules/beerads-sdk/services/people/people/PeopleRequests'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ModalChangePasswordCP } from 'submodules/nerit-framework-ui/features/user/components/change-password-modal/ModalChangePasswordCP'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

/**
 * Tela de perfil para os diferentes tipos de usuário.
 */
export function ScreenProfile(): JSX.Element {
	const [isModalChangePasswordVisible, setIsModalChangePasswordVisible] = useState<boolean>(false)

	const [person, setPerson] = useState<PersonResponseDTO>()
	const getPersonRequest = useRequest<PersonResponseDTO>()
	const loggedUser = useAppSelector((state) => state.auth.user)

	useEffect(onGetPersonRequestChange, [getPersonRequest.isAwaiting])

	useEffect(init, [])

	/**
	 * Inicializa a tela
	 */
	function init(): void {
		if (!loggedUser?.code) return

		getPersonRequest.runRequest(PeopleRequests.getOne(loggedUser?.code))
	}

	/**
	 * Retorno da requisicao para pegar agenda passada como parametro
	 */
	function onGetPersonRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getPersonRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		setPerson(getPersonRequest.responseData)
	}

	if (!person || getPersonRequest.isAwaiting) {
		return <LoadingOverlayCP show={true} />
	}

	return (
		<LayoutCP
			titleBrowser={`Perfil - ${StringUtils.getFirstName(person.name)}`}
			content={
				<ContentCP style={{ flexShrink: 0 }}>
					{loggedUser?.isDoctor ? (
						<ScreenContentProfileDoctor
							person={person}
							onClickChangePassword={() => setIsModalChangePasswordVisible(true)}
							onChangeData={init}
						/>
					) : (
						<ScreenContentProfileUser
							person={person}
							onClickChangePassword={() => setIsModalChangePasswordVisible(true)}
							onChangeData={init}
						/>
					)}

					{loggedUser?.code && (
						<ModalChangePasswordCP
							visible={isModalChangePasswordVisible}
							onClose={() => setIsModalChangePasswordVisible(false)}
							userCode={loggedUser.code}
							requestConfig={AuthRequests.changePassword}
						/>
					)}
				</ContentCP>
			}
		/>
	)
}
