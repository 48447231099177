import { useEffect } from 'react'
import { TemplatesHistoryRequests } from 'submodules/beerads-sdk/services/templates/templates/TemplatesHistoryRequests'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps {
	templateId: string
	size?: ButtonSizeTP
	onSave: () => void
	setIsLoading?: (isLoading: boolean) => void
}

export function ButtonApproveMedicalReportTemplate(props: ICPProps): JSX.Element {
	const approveMedicalReportTemplateRequest = useRequest<void>('none')
	useEffect(onApproveRequestChange, [approveMedicalReportTemplateRequest.isAwaiting])

	function approveRequest(): void {
		approveMedicalReportTemplateRequest.runRequest(TemplatesHistoryRequests.approveTemplateHistory(props.templateId))
		props.setIsLoading?.(true)
	}

	function onApproveRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(approveMedicalReportTemplateRequest, 'Erro ao salvar!', 'Salvo com sucesso', true)) {
			props.setIsLoading?.(false)

			return
		}
		props.setIsLoading?.(false)
		props.onSave()
	}

	return (
		<ButtonCP size={props.size} type="primary" onClick={approveRequest}>
			Aprovar
		</ButtonCP>
	)
}
