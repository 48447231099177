import { useAppSelector } from 'app/redux/hook'
import { ModalMedicalTimetableAbsenceCP } from 'modules/medical-timetable/components/modals/modal-medical-timetable-absence/ModalMedicalTimetableAbsenceCP'
import { IMedicalTimetableRecord } from 'modules/medical-timetable/components/tables-medical-timetable/types/IMedicalTimetableRecord'
import { useState } from 'react'
import { DoctorOnMedicalTimetableResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/DoctorOnMedicalTimetableResponseDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'

interface ICPProps {
	dayOfWeek: WeekDaysEnum
	date: Date
	record: IMedicalTimetableRecord
	doctorOnMedicalTimetable: DoctorOnMedicalTimetableResponseDTO
	onSave: () => void
}

export function ButtonMedicalTimetableEditDoctorAbsenceCP(props: ICPProps): JSX.Element {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
	const doctorGroupCode = useAppSelector((state) => state.doctorGroup.code)

	return (
		<>
			<ButtonCP icon={'close'} size={'small'} onClick={() => setIsModalVisible(true)} />
			{isModalVisible && doctorGroupCode && (
				<ModalMedicalTimetableAbsenceCP
					isUserAddingAbsenceOnMedicalTimetable={{
						date: props.date,
						dayOfWeek: props.dayOfWeek,
						record: props.record,
						doctorOnMedicalTimetable: props.doctorOnMedicalTimetable,
					}}
					doctorGroupCode={doctorGroupCode}
					isModalVisible={isModalVisible}
					onClose={() => setIsModalVisible(false)}
					onSave={() => {
						setIsModalVisible(false)
						props.onSave()
					}}
				/>
			)}
		</>
	)
}
