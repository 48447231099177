import { ContentCP } from '_old/main/common/components/screen-layout/content/ContentCP'
import { CertificatesFooterCP } from 'modules/auth/components/certificates-footer/CertificatesFooterCP'
import { UserLoginFormCP } from 'modules/auth/components/user-login-form/UserLoginFormCP'
import styled from 'styled-components'

/**
 * Conteudo principal da tela de login de usuario do sistema (medico / funcionario de clinica).
 */
export function ScreenContentUserLoginCP(): JSX.Element {
	return (
		<ContentCP>
			<FormWrapperSCP>
				<UserLoginFormCP />
			</FormWrapperSCP>

			{/*<AppStoreButtonsCP*/}
			{/*    androidAppUrl={'https://play.google.com/store/apps/details?id=br.com.beerads.nerit_beerads_app'}*/}
			{/*    appleAppUrl={'https://apps.apple.com/br/app/beerads/id1495819909'}*/}
			{/*/>*/}

			<CertificatesFooterCP />
		</ContentCP>
	)
}

const FormWrapperSCP = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`
