import { IFormItemCommonProps } from '_old/main/common/components/form-fields/inner/IFormItemCommonProps'
import { RadioGroupCP } from '_old/main/common/components/radio-group/RadioGroupCP'
import { SwitchCP } from '_old/main/common/components/switch/SwitchCP'
import { MaskUtils } from '_old/main/common/utils/MaskUtils'
import { Checkbox, Form } from 'antd'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import * as _ from 'lodash-es'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

const CLASS_RISE_LABEL = 'rise-label'
const CLASS_ERROR = 'has-error'
const CLASS_ANIMATE = 'animate-label'

interface IFormItemCPProps extends IFormItemCommonProps {
	children: JSX.Element
}

/**
 * COMPONENTE
 * Wrapper generico a ser utilizado por inputs de formulario.
 *
 * @todo: Melhorar tratamento da mascara (considerar mascara que envolva caracteres nao numericos)
 * @todo: Melhorar logica de exibicao das msgs de falha
 * @todo: Encontrar forma de melhorar tipagem
 * @todo: expor os dados do value para melhor depuração
 *
 * @author hjcostabr
 * @author Stella
 * @author Lucas Rosa
 */
export function FormItemICP(props: IFormItemCPProps): JSX.Element | null {
	const validationsCount = _.get(props, 'formStateManager.validationsCount', 0)

	const [value, setValue] = useState<string | number | boolean>(props.value)
	const [riseLabel, setRiseLabel] = useState<boolean>(false)
	const [validationErr, setValidationErr] = useState<string>()
	const [hasFocus, setHasFocus] = useState<boolean>(false)

	useEffect(setValueByProps, [props.value])
	useEffect(parseLabelRising, [value, hasFocus])
	useEffect(parseValidation, [validationsCount])
	useEffect(onFormStateManagerChange, [props.formStateManager])

	function setValueByProps(): void {
		if (props.value) setValue(props.value)
	}

	function parseLabelRising(): void {
		// se filho for Switch ou RadioGroup, ja fica alto por padrao
		const shouldBeRised =
			props.children.type.name === RadioGroupCP.name ||
			props.children.type.name === SwitchCP.name ||
			props.children.type.name === Checkbox.Group.name
		setRiseLabel(shouldBeRised || hasFocus || (value !== null && value !== undefined))
	}

	function parseValidation(): void {
		if (!hasStateManager() || props.formStateManager?.isValid) return setValidationErr(undefined)

		// @todo: Encontrar forma de tipar isso corretamente
		const fieldErrors = props.formStateManager?.getFieldError(props.fieldName as any)
		const constraints = fieldErrors?.constraints

		if (!!constraints) {
			const errMessages = Object.values(constraints)
			if (!!errMessages.length) return setValidationErr(errMessages[0])
		}

		setValidationErr(undefined)
	}

	function onFormStateManagerChange(): void {
		if (hasStateManager()) {
			const stateValue = props.formStateManager!.getFieldValue(props.fieldName!)
			if (typeof stateValue !== 'object' || stateValue instanceof Array) handleChange(stateValue)
		}
	}

	function hasStateManager(): boolean {
		return !!props.fieldName && !!props.formStateManager
	}

	function handleChange(eventOrValue: SyntheticEvent | string): void {
		let newValue = _.get(eventOrValue, 'target.value', eventOrValue)
		if (newValue === value) return
		if (!!props.mask) newValue = MaskUtils.applyMask(newValue, props.mask)
		setValue(newValue)
		if (!!props.onChange) props.onChange(newValue)

		if (hasStateManager()) props.formStateManager!.changeFieldValue(props.fieldName!, newValue)
	}

	function handleBlur(): void {
		setHasFocus(false)
		if (hasStateManager()) props.formStateManager!.validate()
	}

	function handleFocus(): void {
		setHasFocus(true)
		if (hasStateManager()) props.formStateManager!.setFieldDirty(props.fieldName!)
	}

	function getCssClasses(): string {
		let cssClasses = CLASS_ANIMATE

		if (riseLabel) cssClasses += ` ${CLASS_RISE_LABEL}`

		if (!!validationErr) cssClasses += ` ${CLASS_ERROR}`

		return cssClasses
	}

	function handleKeyPress(event): void {
		if (event.key === 'Enter' && !!props.onFormSubmit) props.onFormSubmit()
	}

	let fieldValue = value
	if (!!props.mask && typeof value !== 'boolean') fieldValue = MaskUtils.applyMask(value, props.mask)

	return (
		<FormItemSCP label={props.label} colon={false} className={getCssClasses()} required={props.required} style={{ width: props.width }}>
			{React.cloneElement(props.children, {
				onFocus: handleFocus,
				onBlur: handleBlur,
				onChange: handleChange,
				onKeyPress: handleKeyPress,
				value: fieldValue,
			})}

			{!!validationErr && <div className={'ant-form-explain'}>{validationErr}</div>}
		</FormItemSCP>
	)
}

const FormItemSCP = styled(Form.Item)`
	width: 100%;
	&.ant-row {
		margin-bottom: 5px;
	}

	input {
		background-color: transparent;
	}

	.ant-select-selection {
		background-color: transparent;
	}

	.ant-form-item-label {
		label {
			&.ant-form-item-required {
				&:before {
					content: '';
				}
				&:after {
					display: inline-block;
					margin-right: 4px;
					font-size: 10px;
					font-family: SimSun, sans-serif;
					line-height: 1;
					content: '*';
				}
			}
		}
	}

	&.${CLASS_ANIMATE} {
		position: relative;

		.ant-col {
			position: initial;
		}

		&.${CLASS_RISE_LABEL} {
			.ant-form-item-label > label {
				transform: translate(-4px, -16px) scale(1);
				-webkit-transition: transform 1s ease, color 1s ease;
				transition: transform 0.5s ease, color 1s ease;
				color: ${ThemeProject.primary};
			}
			.ant-input-suffix {
				color: ${ThemeProject.primary};
			}
		}

		input {
			outline: 0;
		}

		.ant-form-item-label > label {
			position: absolute;
			font-size: 14px;
			transform: translate(0, 10px) scale(1);
			-webkit-transition: transform 1s ease, color 1s ease;
			transition: transform 0.5s ease, color 1s ease;
		}
	}

	&.${CLASS_ERROR} {
		.ant-input-affix-wrapper {
			.ant-input:focus {
				-webkit-box-shadow: none;
				box-shadow: none;
			}
		}

		&:not(.${CLASS_RISE_LABEL}) {
			.ant-select-arrow {
				color: transparent;
			}
		}
	}

	.ant-select {
		width: 100%;
	}

	.ant-checkbox-wrapper {
		color: ${ThemeFrameworkCommon.browserDefaultColorDark}a6;
	}
`
