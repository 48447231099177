import { useCurrentEditor } from '@tiptap/react'
import { IntercomEventEnum } from 'app/components/intercom/inner/IntercomEventEnum'
import { useAppSelector } from 'app/redux/hook'
import { AppConfig } from 'config/AppConfig'
import { LeoRadUtils } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/buttons/leoRad/inner/LeoRadUtils'
import { useEffect, useState } from 'react'
import { AiOutlineBuild } from 'react-icons/ai'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { IntercomUtils } from 'submodules/nerit-framework-ui/common/components/intercom/inner/IntercomUtils'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ToolbarTipTapButtonICP } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/toolbar-tip-tap/toolbar-tip-tap-button/ToolbarTipTapButtonICP'

interface ICPProps {
	examModality?: ExamModalityEnum
}

/**
 */
export function LEORadButtonICP(props: ICPProps): JSX.Element {
	const { editor } = useCurrentEditor()
	const loggedUser = useAppSelector((state) => state.auth.user)
	const [isModalVisible, setIsModalVisible] = useState(false)

	function updateContentFromLeoRAD(event: MessageEvent): void {
		if (event.origin !== AppConfig.getInstance().leoRadUrl) {
			return
		}

		const contentFromLeoRAD = event.data

		if (typeof contentFromLeoRAD === 'string' && !!contentFromLeoRAD) {
			editor?.commands.setContent(contentFromLeoRAD, true)
			setIsModalVisible(false)
		}

		if (typeof contentFromLeoRAD === 'string' && !contentFromLeoRAD) {
			NotificationHelper.warning('Ops', 'Conteúdo selecionado está vazio, nada foi inserido no laudo.')
			return
		}

		IntercomUtils.trackEvent(IntercomEventEnum.LEORAD_EXPORT_MEDICAL_REPORT)
	}

	useEffect(
		/**
		 * Escuta mensagens enviadas pelo iframe
		 */
		function captureEvent() {
			if (!editor) {
				return
			}

			window.addEventListener('message', updateContentFromLeoRAD, false)

			return () => {
				window.removeEventListener('message', updateContentFromLeoRAD)
			}
		},
		[editor],
	)

	const leoRadToken = loggedUser?.preferences?.leoRadToken

	let leoExamModality
	if (!!props.examModality) leoExamModality = LeoRadUtils.modalityVxToLeoRad.get(props.examModality)

	if (!leoRadToken) return <></>

	return (
		<>
			<ToolbarTipTapButtonICP
				Icon={AiOutlineBuild}
				onClick={() => {
					setIsModalVisible(!isModalVisible)
					IntercomUtils.trackEvent(IntercomEventEnum.LEORAD_OPEN)
				}}
			/>
			<ModalCP
				visible={isModalVisible}
				onCancel={() => setIsModalVisible(false)}
				width={'95vw'}
				height={700}
				top={20}
				noFooter={true}
				title={'Laudo estruturado / Reconhecimento de voz'}
			>
				<iframe
					src={`${AppConfig.getInstance().leoRadUrl}/${leoRadToken}/?modalities=${leoExamModality}`}
					width={'100%'}
					height={'650'}
					frameBorder={'0'}
					allow={'clipboard-write; microphone; fullscreen; encrypted-media'}
					allowFullScreen={true}
				/>
			</ModalCP>
		</>
	)
}
