import { AvatarExamModalityCP } from 'modules/exams/components/exams/avatar-exam-modality/AvatarExamModalityCP'
import { IconSlaCP } from 'modules/exams/components/exams/icons/IconSlaCP'
import styled from 'styled-components'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ExamExtraDataTP } from 'submodules/beerads-sdk/services/exams/exams/types/ExamExtraDataTP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'

interface IExamTitleCPProps {
	modality: ExamModalityEnum
	patientName: string
	examTitle: string
	examStatus: ExamStatusEnum
	slaFromNowInMinutes?: number
	extraData?: ExamExtraDataTP
}

/**
 * Título do exame mostrando nome do paciente, modalidade e nome do exame
 */
export function TitleExamCP(props: IExamTitleCPProps): JSX.Element {
	return (
		<FlexCP justify={'space-between'} alignItems={'center'} margin={{ right: 20 }}>
			<TitleSCP>
				<AvatarExamModalityCP
					modality={props.modality}
					patientName={props.patientName}
					description={props.examTitle}
					extraData={props.extraData}
				/>
			</TitleSCP>
			{!!props.slaFromNowInMinutes &&
				[ExamStatusEnum.AWAITING, ExamStatusEnum.PENDING_ADMINISTRATIVE_ANALYSIS, ExamStatusEnum.IN_PROGRESS].includes(props.examStatus) && (
					<IconSlaCP slaNowInMinutes={props.slaFromNowInMinutes} />
				)}
		</FlexCP>
	)
}

const TitleSCP = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
`
