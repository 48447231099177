import { DoctorGroupFormModel } from 'modules/doctor-groups/components/doctor-groups/drawer-doctor-group-data/inner/DoctorGroupFormModel'
import { DoctorGroupDigitalCertificateDataFormModel } from 'modules/doctor-groups/components/doctor-groups/form-doctor-group-digital-certificate-data/inner/DoctorGrouṕDigitalCertificateDataFormModel'
import { DoctorGroupDataConfigTabICP } from 'modules/doctor-groups/components/doctor-groups/tabs-doctor-group-data/inner/DoctorGroupDataConfigTabICP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { TabsCP } from 'submodules/nerit-framework-ui/common/components/tabs/TabsCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FormAddressCP } from 'submodules/nerit-framework-ui/features/address/components/form-address/FormAddressCP'
import { AddressFormModel } from 'submodules/nerit-framework-ui/features/address/components/form-address/inner/AddressFormModel'

interface ICPProps {
	formStateManager: IFormStateManager<DoctorGroupFormModel>
	addressFormStateManager: IFormStateManager<AddressFormModel>
	digitalCertificateStateManager?: IFormStateManager<DoctorGroupDigitalCertificateDataFormModel>
	doctorGroupCode?: number
}

export function TabsDoctorGroupDataCP(props: ICPProps): JSX.Element {
	return (
		<TabsCP
			tabs={[
				{
					key: 'data',
					title: 'Dados do Grupo',
					content: (
						<FlexCP gap={7} flexDirection="column">
							<RowCP>
								<ColumnCP size={12}>
									<InputCP
										type={'text'}
										label={'CNPJ'}
										fieldName={'cnpj'}
										disabled={!!props.doctorGroupCode}
										mask={InputMaskTypeEnum.CNPJ}
										formStateManager={props.formStateManager}
										required={true}
									/>
								</ColumnCP>
								<ColumnCP size={12}>
									<InputCP label={'CRM'} fieldName={'crm'} required={true} formStateManager={props.formStateManager} />
								</ColumnCP>
							</RowCP>
							<InputCP type={'text'} label={'Razão Social'} required={true} fieldName={'name'} formStateManager={props.formStateManager} />
							<InputCP
								type={'text'}
								label={'Nome Fantasia'}
								required={true}
								fieldName={'fantasyName'}
								formStateManager={props.formStateManager}
							/>

							<FormAddressCP formStateManager={props.addressFormStateManager} />
						</FlexCP>
					),
				},
				{
					key: 'config',
					title: 'Configurações',
					content: (
						<DoctorGroupDataConfigTabICP
							formStateManager={props.formStateManager}
							digitalCertificateStateManager={props.digitalCertificateStateManager!}
						/>
					),
				},
			]}
		/>
	)
}
