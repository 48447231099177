import { useAppSelector } from 'app/redux/hook'
import { ModalTemplateCP } from 'modules/template/components/modal-template/ModalTemplateCP'
import { ReactElement, useEffect, useState } from 'react'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import { useTipTapContext } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/context/useTipTapContext'

export function ToolbarTipTapSavePhrase(): ReactElement {
	const { auth: loggedUser } = useAppSelector((state) => state)
	const [isCreatePhraseModalVisible, setIsCreatePhraseModalVisible] = useState(false)
	const { examModality, savedPhrase, onSavePhrase } = useTipTapContext()

	useEffect(shouldModalBeVisible, [savedPhrase])
	function shouldModalBeVisible(): void {
		if (!!savedPhrase) {
			setIsCreatePhraseModalVisible(true)
		}
	}

	function onCancelOrSave(): void {
		setIsCreatePhraseModalVisible(false)
		onSavePhrase('')
	}

	return (
		<ModalTemplateCP
			type={TemplateTypeEnum.PHRASE}
			visible={isCreatePhraseModalVisible}
			onCancel={onCancelOrSave}
			onSave={onCancelOrSave}
			data={{
				initialValues: {
					modalities: !!examModality ? [examModality] : undefined,
					content: savedPhrase,
					owner: { doctorCode: loggedUser.user?.code },
				},
			}}
		/>
	)
}
