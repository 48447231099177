import React from 'react'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'

interface ICPProps {
	text: string
}
export function WarningCP(props: ICPProps): JSX.Element {
	return (
		<TooltipCP showSpan={true} text={props.text}>
			<IconICP iconName="warning" size={11} />
		</TooltipCP>
	)
}
