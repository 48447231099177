import { IDoctorGroupState } from 'app/redux/slices/doctor-group/DoctorGroupSlice'

export function logoutDoctorGroupReducer(): IDoctorGroupState {
	return {
		code: null,
		config: null,
		loggedUserAccess: null,
		name: null,
	}
}
