export enum WeekDaysEnum {
	SUNDAY,
	MONDAY,
	TUESDAY,
	WEDNESDAY,
	THURSDAY,
	FRIDAY,
	SATURDAY,
}

export enum WeekDaysLabelEnum {
	SUNDAY = 'Domingo',
	MONDAY = 'Segunda-feira',
	TUESDAY = 'Terça-feira',
	WEDNESDAY = 'Quarta-feira',
	THURSDAY = 'Quinta-feira',
	FRIDAY = 'Sexta-feira',
	SATURDAY = 'Sábado',
}
