import styled from 'styled-components'
import { PopOverWrapperICP } from 'submodules/nerit-framework-ui/common/components/pop-over/inner/PopOverWrapperICP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

export const CustomPopOver = styled(PopOverWrapperICP)`
	.ant-popover-title {
		border-bottom: 1px solid #e8e8e8;
		padding: 0px 0 0 16px;
		margin: -8px -12px 0 -12px;
	}

	.ant-popover-inner-content {
		color: ${ThemeFrameworkCommon.browserDefaultBackgroundDark}a6;
	}
`

export const PopOverTitle = styled.div`
	font-weight: 700;
	padding: 0.6rem 0.6rem 0.6rem 0;
	color: ${ThemeFrameworkCommon.browserDefaultBackgroundDark};
`
