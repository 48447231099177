import { combineSlices } from '@reduxjs/toolkit'
import { authSlice } from 'app/redux/slices/auth/AuthSlice'
import { doctorGroupSlice } from 'app/redux/slices/doctor-group/DoctorGroupSlice'
import { AppConfig } from 'config/AppConfig'
import { PersistConfig, persistReducer } from 'redux-persist'
import createEncryptor from 'redux-persist-transform-encrypt'
import storage from 'redux-persist/lib/storage'

export const rootReducer = combineSlices(authSlice, doctorGroupSlice)

export type RootStateTP = ReturnType<typeof rootReducer>

const encryptor = createEncryptor({
	secretKey: AppConfig.getInstance().reduxEncryptKey,
	onError: (error) => {
		console.error("Erro ao criar o 'encryptor' para persistência: ", error)
	},
})

const persistConfig: PersistConfig = {
	key: 'root',
	storage,
	transforms: import.meta.env.DEV ? [] : [encryptor],
}

export const persistedReducer = persistReducer(persistConfig, rootReducer)
