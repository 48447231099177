import { useAppSelector } from 'app/redux/hook'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { DoctorPermissionUtils } from 'modules/auth/permissions/DoctorPermissionUtils'
import { ButtonCreateAuditsCP } from 'modules/quality-assurance/components/button-create-audits/ButtonCreateAuditsCP'
import { ButtonDeleteAuditsCP } from 'modules/quality-assurance/components/buttons/button-delete-audits/ButtonDeleteAuditsCP'
import { AuditListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/audits/dtos/response/AuditListItemResponseDTO'
import { AuditStatusEnum } from 'submodules/beerads-sdk/services/exams/audits/enums/AuditStatusEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'

interface ICPProps {
	record: AuditListItemResponseDTO
	onChangeAnyData: () => void
}

/**
 */
export function ColumnActionsTableAuditsCP(props: ICPProps): JSX.Element {
	const currentDoctorGroup = useAppSelector((state) => state.doctorGroup)
	const loggedUser = useAppSelector((state) => state.auth.user)

	return (
		<ButtonGroupCP mode={'separeted'}>
			{DoctorPermissionUtils.canAudit(props.record.audit, props.record.doctorGroupCode, loggedUser) && (
				<ButtonCP
					type={'primary'}
					icon={'edit'}
					onClick={() =>
						NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.AUDIT.replace(':auditCode', props.record.audit.code.toString()))
					}
					size={'small'}
				>
					Auditar
				</ButtonCP>
			)}
			{!!currentDoctorGroup.code && props.record.audit.status === AuditStatusEnum.AWAITING && (
				<>
					<ButtonCreateAuditsCP
						doctorGroupCode={currentDoctorGroup.code}
						selectedExamCodes={[props.record.audit.examCode]}
						audit={{
							codes: [props.record.audit.code],
							doctorCode: props.record.audit.doctor.code,
						}}
						onSave={props.onChangeAnyData}
						appearance={{
							size: 'small',
							type: 'default',
						}}
					/>

					<ButtonDeleteAuditsCP onDelete={props.onChangeAnyData} auditCodes={[props.record.code]} />
				</>
			)}
		</ButtonGroupCP>
	)
}
