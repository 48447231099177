import { UploadMedicalReportVideoResponseDTO } from 'submodules/beerads-sdk/services/exams/attachments/dtos/response/UploadMedicalReportVideoResponseDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'

interface ICPProps {
	medicalReportVideo: UploadMedicalReportVideoResponseDTO
}

export function ButtonCopyClipboardCP(props: ICPProps): JSX.Element {
	return (
		<ButtonCP
			icon={'share-alt'}
			onClick={() => {
				navigator.clipboard.writeText(props.medicalReportVideo.shortenedUrl)
				NotificationHelper.success('Link copiado')
			}}
		>
			Copiar Link
		</ButtonCP>
	)
}
