import { useEffect } from 'react'
import { MedicalReportRequests } from 'submodules/beerads-sdk/services/exams/medical-reports/MedicalReportRequests'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { IBasicModalCPProps, ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps extends IBasicModalCPProps {
	examCode: number
}

/**
 */
export function ModalDiscardMedicalReportCP(props: ICPProps): JSX.Element {
	const discardMedicalReportRequest = useRequest<void>('none')
	useEffect(onDiscardMedicalReportRequestChange, [discardMedicalReportRequest.isAwaiting])

	/**
	 * Ao remover um laudo
	 */
	function onDiscardMedicalReportRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				discardMedicalReportRequest,
				'Ocorreu algum erro ao voltar exame para lista de aguardando',
				'Laudo descartado com sucesso',
				true,
			)
		)
			return

		props.onSave()
	}

	return (
		<ModalCP
			title={'Descartar o Laudo'}
			visible={props.visible}
			actionLoading={discardMedicalReportRequest.isAwaiting}
			okText={'Descartar'}
			onOk={() => discardMedicalReportRequest.runRequest(MedicalReportRequests.discard(props.examCode))}
			onCancel={props.onCancel}
		>
			<AlertCP message={'O laudo deste exame será descartado e o exame voltará para o status aguardando'} type={'error'} margin={{ bottom: 20 }} />
			Você tem certeza que deseja descartar o laudo e retornar o exame para a fila de exames aguardando? Essa ação não pode ser desfeita!
		</ModalCP>
	)
}
