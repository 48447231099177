import { useCurrentEditor } from '@tiptap/react'
import { VxWindMicIconCP } from 'app/components/vx-wind/vx-wind-icon/VxWindMicIconCP'
import { useSpeechToText } from 'modules/exams/components/medical-report/editor-medical-report/hooks/useSpeechToText'
import { ReactElement, useEffect, useState } from 'react'
import { useTipTapContext } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/context/useTipTapContext'
import { ToolbarTipTapButtonICP } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/toolbar-tip-tap/toolbar-tip-tap-button/ToolbarTipTapButtonICP'
import { MathUtils } from 'submodules/nerit-framework-utils/utils/MathUtils'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

export function VoiceRecorderTipTapButtonICP(): ReactElement {
	const { editor } = useCurrentEditor()
	const [textData, setTextData] = useState('')
	const [previousText, setPreviousText] = useState('')
	const { stream, websocket, isCapturing, onChangeCapturing } = useTipTapContext()

	function onChangeTextData(value: string): void {
		setTextData(value)
	}

	function resolveConflictText(currentText: string, previousText: string): string {
		const parsedCurrentText = currentText.split(' ')
		const parsedPreviousText = previousText.split(' ')

		const totalLength = parsedPreviousText.length - 1
		let lastIndexPrevious = totalLength
		let conflictResolved = false

		while (!conflictResolved && lastIndexPrevious >= 0) {
			if (parsedCurrentText[lastIndexPrevious] === parsedPreviousText[lastIndexPrevious]) {
				conflictResolved = true
			} else {
				lastIndexPrevious--
			}
		}

		return parsedCurrentText.slice(lastIndexPrevious).join(' ')
	}

	function handleSpeechToText(newText: string): void {
		if (!editor) return
		const _currentText = newText.replaceAll(/\s/gm, '')
		const _previousText = previousText.replaceAll(/\s/gm, '')
		const parsedCurrentText = StringUtils.parseEditorText(newText)
		const parsedPreviousText = StringUtils.parseEditorText(previousText)
		const selection = editor.state.selection
		const maxLength = editor.$doc.size - 1

		if (_currentText.startsWith(_previousText)) {
			const newPart = newText.slice(previousText.length)
			const parsedText = StringUtils.parseEditorText(newPart)

			if (parsedText.trim().length > 0) {
				const range = {
					from: MathUtils.clampValue(selection.from, 0, maxLength),
					to: MathUtils.clampValue(selection.to, 0, maxLength),
				}

				editor.commands.insertContentAt(range, parsedText)
				editor.commands.focus()
			}
		} else if (!_previousText.startsWith(_currentText)) {
			const newPart = resolveConflictText(parsedCurrentText, parsedPreviousText)
			const parsedText = StringUtils.parseEditorText(newPart)

			if (parsedText.trim().length > 0) {
				const range = {
					from: MathUtils.clampValue(selection.from - parsedText.length, 0, maxLength),
					to: MathUtils.clampValue(selection.to, 0, maxLength),
				}

				editor.commands.insertContentAt(range, parsedText)
				editor.commands.focus()
			}
		}

		setPreviousText(newText)
	}

	useEffect(() => {
		if (!editor) return

		handleSpeechToText(textData)
	}, [textData])

	useEffect(() => {
		if (!isCapturing && textData) {
			setPreviousText('')
			setTextData('')
		}
	}, [isCapturing])

	const { onTrigger } = useSpeechToText({
		stream,
		websocket,
		isCapturing,
		onChangeCapturing,
		onChangeTextData,
	})

	return <ToolbarTipTapButtonICP Icon={VxWindMicIconCP} onClick={onTrigger} className={isCapturing ? 'is-recording' : ''} />
}
