import { useAppSelector } from 'app/redux/hook'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import { AppUtils } from 'app/utils/AppUtils'
import { AppConfig } from 'config/AppConfig'
import { UploadUrlTP } from 'modules/exams/components/exams/drawer-exam-pending/inner/FormExamPendingAnamnesisDataCP'
import { ExamUploadFileTypeEnum } from 'modules/exams/screens/screen-exam-upload-files/inner/ExamUploadFileTypeEnum'
import {
	PARAM_SCREEN_UPLOAD_FILES_AUTHENTICATION,
	PARAM_SCREEN_UPLOAD_FILES_EXAM_ID,
	PARAM_SCREEN_UPLOAD_FILES_EXPIRATION_DATE,
	PARAM_SCREEN_UPLOAD_FILES_EXPIRATION_HOUR,
	PARAM_SCREEN_UPLOAD_FILES_TYPE,
} from 'modules/exams/screens/screen-exam-upload-files/ScreenExamUploadFiles'
import { ExamAttachmentsRequests } from 'submodules/beerads-sdk/services/exams/attachments/ExamAttachmentsRequests'
import { ListFilesFromApiCP } from 'submodules/nerit-framework-ui/common/components/file/list-files-from-api/ListFilesFromApiCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'

interface ICPProps {
	examId: string
	type: ExamUploadFileTypeEnum
	showQrCode?: boolean
	maxMbSize?: number
	permissions?: {
		canUpload: boolean
		canRemove: boolean
		canShowFileList: boolean
	}
	temporaryToken?: UploadUrlTP
}

/**
 */
export function UploadExamAttachmentsCP(props: ICPProps): JSX.Element {
	const authData = useAppSelector((selector) => selector.auth)

	const formattedExpirationDateTime =
		props.temporaryToken?.expirationDate !== 'undefined'
			? DateUtils.formatDate(props.temporaryToken?.expirationDate!, DateFormatEnum.BR_WITH_TIME_H_M).split(' ')
			: []

	/**
	 * Monta a url de upload quando leitura do qrcode
	 */
	function getUploadByQrUrl(): string {
		let url = `${AppConfig.getInstance().uiBaseUrl}/${AppUtils.getDomain()!}/${PublicRouter.UPLOAD_EXAM_FILES}`
		url = `${url}?${PARAM_SCREEN_UPLOAD_FILES_TYPE}=${props.type}`
		url = `${url}&${PARAM_SCREEN_UPLOAD_FILES_AUTHENTICATION}=${props.temporaryToken?.token ?? authData.token}`
		url = `${url}&${PARAM_SCREEN_UPLOAD_FILES_EXAM_ID}=${props.examId}`
		url = `${url}&${PARAM_SCREEN_UPLOAD_FILES_EXPIRATION_DATE}=${formattedExpirationDateTime[0]}`
		url = `${url}&${PARAM_SCREEN_UPLOAD_FILES_EXPIRATION_HOUR}=${formattedExpirationDateTime[1]}`

		return url
	}

	return (
		<ListFilesFromApiCP
			entityCode={props.examId}
			showReloadButton={true}
			requestsConfig={{
				uploadRequestConfigTP: ExamAttachmentsRequests.uploadAttachment,
				searchRequestConfigTP: () =>
					ExamAttachmentsRequests.searchAttachments(props.examId, { isExam: props.type === ExamUploadFileTypeEnum.EXAM_IMAGE }),
				deleteRequestConfigTP: ExamAttachmentsRequests.deleteAttachment,
				customUploadParamenter: {
					parameterName: 'isExam',
					parameterValue: props.type === ExamUploadFileTypeEnum.EXAM_IMAGE,
				},
			}}
			maxMbSize={props.maxMbSize}
			qrCode={{
				visible: props.showQrCode ?? false,
				url: getUploadByQrUrl(),
			}}
			showCaptureFromClipboard={true}
			permissions={props.permissions}
		/>
	)
}
