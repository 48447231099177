import { Button } from 'antd'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import styled, { css } from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

export type WrapperScpPropsTP = {
	marginRight?: number
	marginLeft?: number
	marginBottom?: number
	marginTop?: number
}

export const ButtonWrapperSCP = styled.div<WrapperScpPropsTP>`
	margin-left: ${(props) => props.marginLeft ?? 0}px;
	margin-right: ${(props) => props.marginRight ?? 0}px;
	margin-bottom: ${(props) => props.marginBottom ?? 0}px;
	margin-top: ${(props) => props.marginTop ?? 0}px;
`

export type CustomButtonTP = {
	bgColor?: string
	borderColor?: string
	color?: string
	horizontalPadding?: number
	onlyIcon?: boolean
}

export const CustomButton = styled(Button)<CustomButtonTP>`
	${(props) => css`
		&.ant-btn-sm {
			.anticon {
				margin-right: ${!props.onlyIcon ? '-2px !important' : undefined};
			}

			span {
				font-size: 0.7rem;
			}
		}

		&.ant-btn {
			display: flex;
			flex-direction: row;
			align-items: center;
			transition: background-color 0.2s ease-in;
			padding-block: 0;
			color: ${props.color ?? ThemeProject.colorText};

			${props.horizontalPadding && `padding-inline: ${props.horizontalPadding}px;`}

			&.ant-btn[disabled] {
				opacity: 0.7;
			}

			&.ant-btn-primary {
				background-color: ${ThemeProject.primary};
				border-color: ${ThemeProject.primary};
				color: ${props.color ?? ThemeFrameworkCommon.white}

				.anticon {
					color: ${ThemeFrameworkCommon.white};

				}

				:disabled {
					border: none;
					background-color: ${ThemeProject.primary}70;
				}

				&:hover:not([disabled]) {
					background-color: ${ThemeProject.primary}95;
				}

				&.ant-btn-background-ghost > i {
					color: ${ThemeProject.primary};
				}
				&.ant-btn-dangerous{
					background-color: #ff4d4f;
					border-color: #ff4d4f;

					:hover {
						background-color: #ff7875;
						border-color: #ff7875;
					}
				}
			}

			&.ant-btn-default {
				color: ${ThemeFrameworkCommon.browserDefaultBackgroundDark}

				color: ${props.color ?? ThemeFrameworkCommon.black};

				:disabled {
					background-color: ${ThemeFrameworkCommon.white};
				}
			}

			&.ant-btn-ghost {
				&:not(:disabled) {
					background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight}40;
				}
			}

			&.ant-btn-icon-only {
				display: flex;
				justify-content: center;
				align-items: center;
				transition: all 0.3s ease;

				:hover {
					:not(.ant-btn-default.ant-btn-dangerous) {
						color: ${props.color ?? ThemeProject.colorText};
					}
					opacity: 0.7
				}
			}

			&.ant-btn-link {
				height: unset;
				width: unset;
			}

			&.ant-btn-loading {
				display: flex;
				align-items: center;

				.anticon-loading {
					margin-right: 7px;
				}
			}

			&.anticon {
				display: flex;
				align-items: center;
			}

			&.ant-typography {
				margin-left: 4px;
			}

			&.ant-btn-dangerous {
				background-color: ${ThemeProject.error};
				border-color: ${ThemeProject.error};
			
				.anticon {
					color: ${ThemeFrameworkCommon.white};
				}
			}
		}
	`}
`
