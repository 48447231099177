import { DoctorGroupDoctorFormModel } from 'modules/doctor-groups/components/people/form-doctor-group-doctor/inner/DoctorGroupDoctorFormModel'
import {
	DoctorRoleInDoctorGroupEnum,
	DoctorRoleInDoctorGroupLabelEnum,
} from 'submodules/beerads-sdk/services/doctor-groups/doctor-role-in-doctor-group/enum/DoctorRoleInDoctorGroupEnum'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps<FModelTP extends FormModel = DoctorGroupDoctorFormModel> {
	label: string
	fieldName: keyof FModelTP
	formStateManager: IFormStateManager<DoctorGroupDoctorFormModel>
	required?: boolean
}

export function SelectDoctorRoleCP(props: ICPProps): JSX.Element {
	return (
		<SelectCP
			label={props.label}
			fieldName={props.fieldName}
			formStateManager={props.formStateManager}
			required={props.required}
			options={Object.values(DoctorRoleInDoctorGroupEnum).map((item) => ({ label: DoctorRoleInDoctorGroupLabelEnum[item], value: item }))}
			allowClear={true}
		/>
	)
}
