import { CALLED_VX_WIND_SERVICE_LOCAL_STORAGE_ITEM } from 'app/components/vx-wind/vx-wind-report-assistant/VxWindReportAssistantCP'
import { ModalExamsSuggestionsCP } from 'modules/exams/components/exams/modal-exams-suggestions/ModalExamsSuggestionsCP'
import { ButtonsFinishMedicalReportCP } from 'modules/exams/components/medical-report/buttons-finish-medical-report/ButtonsFinishMedicalReportCP'
import { ListMedicalReportCompliance } from 'modules/exams/components/medical-report/list-medical-report-compliance/ListMedicalReportCompliance'
import { FinalizeMedicalReportFormFieldsICP } from 'modules/exams/components/medical-report/modal-finalize-medical-report/inner/FinalizeMedicalReportFormFieldsICP'
import { TabsPreviewMedicalReportsCP } from 'modules/exams/components/medical-report/tabs-preview-medical-reports/TabsPreviewMedicalReportsCP'
import { MedicalReportFormModel } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModel'
import { useEffect, useState } from 'react'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { FeedbackEventEnum } from 'submodules/beerads-sdk/services/feedbacks/enum/FeedbackEventEnum'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { FeedBackCP } from 'submodules/nerit-framework-ui/common/components/feedback/FeedbackCP'
import { VxWindFeedbackMetadataTP } from 'submodules/nerit-framework-ui/common/components/feedback/inner/feedback-metadata/VxWindFeedbackMetadataTP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import * as S from './style'

interface ICPProps {
	exam: ExamResponseDTO
	formStateManager: IFormStateManager<MedicalReportFormModel>
	visible: boolean
	onCancel: () => void
	searchExamDto?: ExamSearchRequestDTO
}

/**
 */
export function ModalFinalizeMedicalReportCP(props: ICPProps): JSX.Element {
	const [visible, setVisible] = useState<boolean>(props.visible)
	useEffect(() => setVisible(props.visible), [props.visible])
	const showWindFeedbackCard = localStorage.getItem(CALLED_VX_WIND_SERVICE_LOCAL_STORAGE_ITEM)

	const [finalizedMedicalReportModalVisible, setFinalizedMedicalReportModalVisible] = useState<boolean>(false)

	return (
		<>
			<ModalCP title={'Finalizar Laudo'} visible={visible} onCancel={props.onCancel} noFooter={true} width={1100} top={10}>
				<S.WrapperSCP>
					<RowCP>
						<ColumnCP size={14}>
							<TabsPreviewMedicalReportsCP
								examCode={props.exam.code}
								medicalReportCotents={props.formStateManager.getFieldValue('medicalReports')}
							/>
						</ColumnCP>

						<ColumnCP size={10}>
							<HelpCP text={'Confirme todas as informações antes de finalizar o laudo'} type={'text'} marginBottom={10} />
							<S.ListMedicalReportComplianceWrapperSCP>
								<ListMedicalReportCompliance exam={props.exam} formStateManager={props.formStateManager} />
							</S.ListMedicalReportComplianceWrapperSCP>

							<FinalizeMedicalReportFormFieldsICP exam={props.exam} formStateManager={props.formStateManager} />

							<FlexCP margin={{ top: 40 }} justify={'center'}>
								<ButtonsFinishMedicalReportCP
									exam={props.exam}
									formStateManager={props.formStateManager}
									onSave={() => {
										setVisible(false)
										setFinalizedMedicalReportModalVisible(true)
										localStorage.removeItem(CALLED_VX_WIND_SERVICE_LOCAL_STORAGE_ITEM)
									}}
								/>
							</FlexCP>
							<HelpCP
								text={'Preview! Verifique se o resultado do laudo ao lado está com uma visualização agradável'}
								type={'text'}
								marginTop={10}
							/>

							{showWindFeedbackCard && (
								<CardCP cardMargin="0">
									<FeedBackCP<VxWindFeedbackMetadataTP>
										title="Dê seu feedback"
										subTitle="Nos diga o que você achou da VX Wind"
										feedbackData={{ examCode: props.exam.code }}
										event={FeedbackEventEnum.WIND}
									/>
								</CardCP>
							)}
						</ColumnCP>
					</RowCP>
				</S.WrapperSCP>
			</ModalCP>

			<ModalExamsSuggestionsCP visible={finalizedMedicalReportModalVisible} exam={props.exam} searchExamDto={props.searchExamDto} />
		</>
	)
}
