import { MedicalReportVarsEnum } from 'submodules/beerads-sdk/services/exams/medical-reports/types/MedicalReportVarsEnum'

export const REQUIRED_PADI_VALUES: readonly MedicalReportVarsEnum[] = [
	MedicalReportVarsEnum.PATIENT_NAME,
	MedicalReportVarsEnum.PATIENT_BIRTHDAY,
	MedicalReportVarsEnum.PATIENT_ID,
	MedicalReportVarsEnum.CLINIC_NAME,
	MedicalReportVarsEnum.CLINIC_ADDRESS,
	MedicalReportVarsEnum.EXAM_COMPANY_REQUESTER,
	MedicalReportVarsEnum.EXAM_COMPANY_REQUESTER_CRM,
	MedicalReportVarsEnum.EXAM_DATE,
	MedicalReportVarsEnum.DOCTOR_GROUP_NAME,
	MedicalReportVarsEnum.DOCTOR_GROUP_ID,
	MedicalReportVarsEnum.HTML_BOX_DOCTOR_SIGNATURE_1,
]