import styled from 'styled-components'

export const WrapperSCP = styled.div`
	max-height: 300px;
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 12px;
`

export const Wrapper = styled.div`
	.ant-drawer {
		z-index: 1000;
	}
`
