import React from 'react'
import * as S from './CertificatesFooterStyles'

export function CertificatesFooterCP(): React.ReactElement {
	return (
		<S.CertificatesFooter>
			<S.Image src={`${import.meta.env.VITE_UI_BASE_URL}/images/saasDefenceN1.png`} />
			<S.Image src={`${import.meta.env.VITE_UI_BASE_URL}/images/icpBrasil.png`} />
		</S.CertificatesFooter>
	)
}
