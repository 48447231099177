import { MedicalTimetableAssignFormModel } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/inner/MedicalTimetableAssignFormModel'
import { MedicalTimetableWeekDayRecurrencyTP } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/inner/MedicalTimetableWeekDayRecurrencyTP'
import { TimeRangeMedicalTimetableAssignCP } from 'modules/medical-timetable/components/time-range-medical-timetable-assign/TimeRangeMedicalTimetableAssignCP'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
	MedicalTimetablePeriodEnum,
	MedicalTimetablePeriodLabelEnum,
} from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetablePeriodEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { IconCP } from 'submodules/nerit-framework-ui/common/components/icon/IconCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { momentPtBrLocale } from 'submodules/nerit-framework-utils/utils/date/moment-pt-br-locale'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'
interface ICPProps {
	formStateManager: IFormStateManager<MedicalTimetableAssignFormModel>
	weekDay: WeekDaysEnum
	period: MedicalTimetablePeriodEnum
}

/**
 */
export function BoxMedicalTimetableDoctorWeekSpotConfigItemICP(props: ICPProps): JSX.Element {
	const [start, end] = MedicalTimetablePeriodLabelEnum[props.period].split('~')

	// Caso não haja registro, seta o período como horário default
	const [beginHour, setBeginHour] = useState<string>(props.formStateManager.getFieldValue('spot')?.get(props.weekDay)?.beginHour ?? start)
	const [endHour, setEndHour] = useState<string>(props.formStateManager.getFieldValue('spot')?.get(props.weekDay)?.endHour ?? end)

	const [onEdit, setOnEdit] = useState<boolean>(false)

	useEffect(init, [
		props.formStateManager.getFieldValue('spot')?.get(props.weekDay)?.beginHour,
		props.formStateManager.getFieldValue('spot')?.get(props.weekDay)?.endHour,
	])

	function init(): void {
		setBeginHour(props.formStateManager.getFieldValue('spot')?.get(props.weekDay)?.beginHour ?? start)
		setEndHour(props.formStateManager.getFieldValue('spot')?.get(props.weekDay)?.endHour ?? end)
	}

	//se nao vier nenhum valor do response, cria um map já setando o horário do período
	useEffect(createSpotMap, [!!props.formStateManager.getFieldValue('spot')])

	function createSpotMap(): void {
		let map: Map<WeekDaysEnum, MedicalTimetableWeekDayRecurrencyTP> = props.formStateManager.getFieldValue('spot')

		if (!map) map = new Map<WeekDaysEnum, MedicalTimetableWeekDayRecurrencyTP>()

		map.set(props.weekDay, {
			selected: true,
			beginHour: beginHour,
			endHour: endHour,
		})
		props.formStateManager.changeFieldValue('spot', map)
	}

	return (
		<WrapperSCP>
			<RowCP align={'middle'}>
				<ColumnCP size={11}>
					<SwitchWrapperSCP>
						<SwitchCP label={momentPtBrLocale.weekdaysFull[props.weekDay]} value={true} disabled={true} />
					</SwitchWrapperSCP>
				</ColumnCP>

				<ColumnCP size={11}>
					<TextCP text={`${beginHour}~${endHour}`} fontStyle="italic" size="small" />
				</ColumnCP>

				<ColumnCP size={2}>
					<ButtonCP
						size="small"
						type={'default'}
						onClick={() => setOnEdit(!onEdit)}
						disabled={!props.formStateManager.getFieldValue('spot')?.get(props.weekDay)?.selected}
					>
						<IconCP antIcon="edit" />
					</ButtonCP>
				</ColumnCP>
			</RowCP>

			{props.formStateManager.getFieldValue('spot')?.get(props.weekDay)?.selected && onEdit && (
				<RowCP>
					<ColumnCP size={9}>
						<TimeRangeMedicalTimetableAssignCP
							formStateManager={props.formStateManager}
							weekDay={props.weekDay}
							assignType={'spot'}
							beginHour={beginHour}
							endHour={endHour}
							setBeginHour={setBeginHour}
							setEndHour={setEndHour}
						/>
					</ColumnCP>
				</RowCP>
			)}
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	display: flex;
	gap: 5px;
	margin-bottom: 10px;
	flex-direction: column;
	padding: 5px;
	border-radius: 5px;
	justify-content: center;
`
const SwitchWrapperSCP = styled.div`
	.ant-typography {
		width: 100px;
	}

	span {
		line-height: normal;
	}
`
