import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { FormClinicReportTemplateConfigCP } from 'modules/clinic/components/form-clinic-report-config-template/FormClinicReportTemplateConfigCP'
import { UploadClinicLogoCP } from 'modules/clinic/components/upload-clinic-logo/UploadClinicLogoCP'
import { UploadClinicSystemLogoCP } from 'modules/clinic/components/upload-clinic-system-logo/UploadClinicSystemLogoCP'
import { useEffect, useState } from 'react'
import { ClinicsRequests } from 'submodules/beerads-sdk/services/clinics/clinics/ClinicsRequests'
import { ClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicResponseDTO'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps {
	clinicCode: number
}

/**
 * Componente para personalização das informações do footer do laudo.
 */
export function ScreenContentClinicsAdminReportConfig(props: ICPProps): JSX.Element {
	const [loadedClinic, setLoadedClinic] = useState<ClinicResponseDTO>()
	const getClinicRequest = useRequest<ClinicResponseDTO>()
	useEffect(onGetClinicRequestChange, [getClinicRequest.isAwaiting])

	useEffect(init, [props.clinicCode])

	/**
	 * Inicializa a tela.
	 */
	function init(): void {
		getClinicRequest.runRequest(ClinicsRequests.get(props.clinicCode))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetClinicRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getClinicRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		setLoadedClinic(getClinicRequest.responseData)
	}

	return (
		<>
			<CardCP title={'Logo do Cabeçalho'} isLoading={getClinicRequest.isAwaiting}>
				{!!loadedClinic && <UploadClinicLogoCP clinicCode={props.clinicCode} clinicLogoUrl={loadedClinic?.imageUrl} />}
			</CardCP>

			<AccessControlCP
				permission={{
					byHasAnyPermissionInClinic: { clinicCode: props.clinicCode, permissions: ['isDoctorGroupAdmin'] },
				}}
			>
				<CardCP title={'Logo do Rodapé'} isLoading={getClinicRequest.isAwaiting}>
					{!!loadedClinic && <UploadClinicSystemLogoCP clinicCode={props.clinicCode} clinicLogoUrl={loadedClinic?.systemLogoUrl} />}
				</CardCP>
			</AccessControlCP>

			<AccessControlCP
				permission={{
					byHasAnyPermissionInClinic: {
						clinicCode: props.clinicCode,
						permissions: ['isDoctorGroupUser', 'isDoctorGroupAdmin'],
					},
				}}
			>
				<CardCP>
					<FormClinicReportTemplateConfigCP clinicCode={props.clinicCode} />
				</CardCP>
			</AccessControlCP>
		</>
	)
}
