import { CardProfileUserDataCP } from 'modules/person/components/user/card-profile-user-data/CardProfileUserDataCP'
import { PersonResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PersonResponseDTO'

interface ICPProps {
	person: PersonResponseDTO
	onClickChangePassword: () => void
	onChangeData: () => void
}

/**
 * Perfil do Usuário.
 */
export function ScreenContentProfileUser(props: ICPProps): JSX.Element {
	return <CardProfileUserDataCP person={props.person} onClickChangePassword={props.onClickChangePassword} onChangeData={props.onChangeData} />
}
