import { NeritFrameworkProjectConfig } from 'config/NeritFrameworkProjectConfig'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	positionFixed?: boolean
}

/**
 */
export function TopBarHomEnvironmentCP(props: ICPProps): JSX.Element {
	if (!NeritFrameworkProjectConfig.isHom()) return <></>

	return (
		<WrapperSCP positionFixed={props.positionFixed}>
			Você está acessando o ambiente de HOMOLOGAÇÃO. As informações inseridas aqui não afetarão o ambiente de produção.
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div<{ positionFixed?: boolean }>`
	background: ${ThemeProject.warning};
	color: ${ThemeFrameworkCommon.black};
	display: flex;
	justify-content: center;
	padding: 10px;
	position: ${(props) => (props.positionFixed ? 'fixed' : undefined)};
	width: 100%;
	z-index: 999;
`
