import { IOitSectionProps, OitSectionContentICP } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/OitSectionContentICP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'

/**
 */
export function OitSection1ICP(props: IOitSectionProps): JSX.Element {
	return (
		<FlexCP>
			<OitSectionContentICP>
				<FlexCP gap={40}>
					<RadioGroupCP
						type={'button'}
						options={[
							{ content: '1', value: '1' },
							{ content: '2', value: '2' },
							{ content: '3', value: '3' },
							{ content: '4', value: '4' },
						]}
						formStateManager={props.formStateManager}
						fieldName={'answer1A'}
						label={'1A - Qualidade Técnica'}
					/>
					<RadioGroupCP
						type={'button'}
						options={[
							{ content: 'Sim', value: true },
							{ content: 'Não', value: false },
						]}
						formStateManager={props.formStateManager}
						fieldName={'answer1B'}
						label={'1B - Radiografia Normal'}
					/>
					{!!props.formStateManager.getFieldValue('answer1A') && props.formStateManager.getFieldValue('answer1A') !== '1' && (
						<TextAreaCP label={'1C - Motivo da qualidade técnica'} formStateManager={props.formStateManager} fieldName={'answer1C'} />
					)}
				</FlexCP>
			</OitSectionContentICP>
		</FlexCP>
	)
}
