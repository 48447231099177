import { useAppSelector } from 'app/redux/hook'
import { AlertExtraDoctorOnMedicalTimetableCP } from 'modules/medical-timetable/components/alert-extra-doctor-on-medical-timetable/AlertExtraDoctorOnMedicalTimetableCP'
import { ButtonMedicalTimetableEditDoctorAbsenceCP } from 'modules/medical-timetable/components/buttons/button-medical-timetable-edit-doctor-absence/ButtonMedicalTimetableEditDoctorAbsenceCP'
import { ButtonMedicalTimetableEditRecurrencyCP } from 'modules/medical-timetable/components/buttons/button-medical-timetable-edit-recurrency/ButtonMedicalTimetableEditRecurrencyCP'
import { ButtonMedicalTimetableEditSpotCP } from 'modules/medical-timetable/components/buttons/button-medical-timetable-edit-spot/ButtonMedicalTimetableEditSpotCP'
import { IMedicalTimetableRecord } from 'modules/medical-timetable/components/tables-medical-timetable/types/IMedicalTimetableRecord'
import { DoctorOnMedicalTimetableResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/DoctorOnMedicalTimetableResponseDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'
import * as S from './DoctorOnMedicalTimetableStyles'

interface ICPProps {
	record: IMedicalTimetableRecord
	dayOfWeek: WeekDaysEnum
	beginWeekDate: Date
	recordIndex: number
	doctors: DoctorOnMedicalTimetableResponseDTO[]
	numberOfRows: number
	onAssign: () => void
	isDoctorAbsence?: boolean
}

/**
 */
export function DoctorOnMedicalTimetableCP(props: ICPProps): JSX.Element {
	const doctorOnMedicalTimetable = props.doctors[props.recordIndex]
	const doctorGroupCode = useAppSelector((state) => state.doctorGroup.code)
	const date = DateUtils.add(props.beginWeekDate, props.dayOfWeek, TimeBaseEnum.DAY)

	return (
		<S.Wrapper>
			<FlexCP alignItems={'center'} justify={'space-between'}>
				<S.ContentWrapper>
					{doctorOnMedicalTimetable.doctor.name}
					{!!doctorOnMedicalTimetable.hourRange && (
						<S.HoursWrapper>
							<i>{`${doctorOnMedicalTimetable.hourRange.beginHour} às ${doctorOnMedicalTimetable.hourRange.endHour}`}</i>
						</S.HoursWrapper>
					)}
					{!!doctorOnMedicalTimetable.extraValue && (
						<S.ValueWapper>
							{MaskUtils.applyMoneyMask(doctorOnMedicalTimetable.extraValue)}
							{!!doctorOnMedicalTimetable.shouldKeepDynamicValue && ' + variável'}
						</S.ValueWapper>
					)}
				</S.ContentWrapper>

				<S.ButtonsWrapper>
					{!!doctorOnMedicalTimetable.description && (
						<S.CommentButtonWrapper>
							<ButtonCP icon={'message'} tooltip={doctorOnMedicalTimetable.description} size={'small'} marginBottom={5} />
						</S.CommentButtonWrapper>
					)}
					{props.isDoctorAbsence ? (
						<S.AbsenceButtonWrapper>
							<ButtonCP type="link" icon={'info-circle'} size={'large'} tooltip="Médico ausente" marginBottom={25} />
						</S.AbsenceButtonWrapper>
					) : (
						<>
							{doctorGroupCode && (
								<ButtonMedicalTimetableEditSpotCP
									dayOfWeek={props.dayOfWeek}
									doctorCode={doctorOnMedicalTimetable.doctor.code}
									doctorGroupCode={doctorGroupCode}
									groupBy={props.record.groupBy}
									id={props.record.id}
									modality={props.record.modality}
									period={props.record.period}
									onSave={props.onAssign}
									date={date}
									isRecurrent={props.doctors[props.recordIndex]?.isRecurrent}
								/>
							)}

							{doctorGroupCode && (
								<ButtonMedicalTimetableEditRecurrencyCP
									dayOfWeek={props.dayOfWeek}
									doctorCode={doctorOnMedicalTimetable.doctor.code}
									doctorGroupCode={doctorGroupCode}
									groupBy={props.record.groupBy}
									id={props.record.id}
									modality={props.record.modality}
									period={props.record.period}
									onSave={props.onAssign}
									date={date}
									isRecurrent={props.doctors[props.recordIndex]?.isRecurrent}
								/>
							)}

							<ButtonMedicalTimetableEditDoctorAbsenceCP
								record={props.record}
								dayOfWeek={props.dayOfWeek}
								date={date}
								doctorOnMedicalTimetable={doctorOnMedicalTimetable}
								onSave={props.onAssign}
							/>
						</>
					)}
				</S.ButtonsWrapper>
			</FlexCP>
			{
				// Caso seja a ultima linha e possua mais medicos do que ja exibido, mostra o alerta
				props.recordIndex + 1 === props.numberOfRows && props.doctors.length > props.numberOfRows && (
					<AlertExtraDoctorOnMedicalTimetableCP doctors={props.doctors} numberOfRows={props.numberOfRows} />
				)
			}
		</S.Wrapper>
	)
}
