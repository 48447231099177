import { ExamDateRangeFilterEnum } from '@/submodules/beerads-sdk/services/exams/exams/enums/ExamDateRangeFilterEnum'
import { useAppSelector } from 'app/redux/hook'
import { FiltersExamsCP } from 'modules/exams/components/exams/filters-exams/FiltersExamsCP'
import { ExamFilterFormModel } from 'modules/exams/components/exams/table-exams/filters/ExamFilterFormModel'
import { ExamFiltersFormModelConverter } from 'modules/exams/components/exams/table-exams/filters/ExamFiltersFormModelConverter'
import { ScreenWrapperQualityAssurance } from 'modules/quality-assurance/components/screen-wrapper-quality-assurance/ScreenWrapperQualityAssurance'
import { TableExamsToAuditCP } from 'modules/quality-assurance/components/table-exams-to-audit/TableExamsToAuditCP'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { ExamStatusUtils } from 'submodules/beerads-sdk/services/exams/exams/utils/ExamStatusUtils'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'

/**
 */
export function ScreenQualityAssuranceExams(): JSX.Element {
	const [formValidator, setFormValidator] = useState<ExamFilterFormModel>()
	const formStateManager = useFormStateManager<ExamFilterFormModel>(formValidator)

	const currentDoctorGroup = useAppSelector((state) => state.doctorGroup)
	const loggedUser = useAppSelector((state) => state.auth.user)

	const [loadList, setLoadList] = useState<number>(1)
	const [isLoadingList, setIsLoadingList] = useState<boolean>(false)

	useEffect(init, [])

	/**
	 * Inicializa.
	 */
	function init(): void {
		// Monta os filtros
		const examFilterFormModel = ExamFiltersFormModelConverter.formatFormModel(loggedUser, currentDoctorGroup, {
			viewAsDoctor: false,
			statuses: ExamStatusUtils.examStatusOfExamStatusGroupMap.get(ExamStatusGroupEnum.DONE),
			dateRangeFilter: ExamDateRangeFilterEnum.MEDICAL_REPORT_CREATED_AT,
			dateRange: { beginDate: moment().subtract(1, 'day').toDate(), endDate: moment().add(1, 'day').toDate() },
		})
		setFormValidator(examFilterFormModel)
	}

	return (
		<ScreenWrapperQualityAssurance headerTitle={'Central de Qualidade - Exames'} appearance={{ hideMargin: true }}>
			{!!formStateManager.getFormValues() && !!currentDoctorGroup.code && (
				<>
					<FiltersExamsCP
						filterFormStateManager={formStateManager}
						isLoading={isLoadingList}
						onFilter={() => {
							setLoadList(TableUtils.getReloadNumber())
							setIsLoadingList(true)
						}}
						isScreenInitialized={true}
						fieldsConfig={{
							disableStatus: true,
							disableDoctorGroup: true,
						}}
					/>

					<TableExamsToAuditCP reloadTable={loadList} onLoading={setIsLoadingList} filterStateManager={formStateManager} />
				</>
			)}
		</ScreenWrapperQualityAssurance>
	)
}
