import { IOitSectionProps, OitSectionContentICP } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/OitSectionContentICP'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'

export function OitSection3AICP(props: IOitSectionProps): JSX.Element {
	return (
		<RowCP>
			<OitSectionContentICP title={'3A - Alguma anormalidade pleural consistente com pneumoconiose?'}>
				<ColumnCP size={24}>
					<RadioGroupCP
						type={'button'}
						options={[
							{ content: 'Sim', value: true },
							{ content: 'Não', value: false },
						]}
						formStateManager={props.formStateManager}
						fieldName={'answer3A'}
					/>
				</ColumnCP>
			</OitSectionContentICP>
		</RowCP>
	)
}
