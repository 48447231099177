import { setAuthHeader } from '@/services/http/http'
import { AuthUtils } from '@/submodules/nerit-framework-utils/sdk-utils/request-manager/utils/AuthUtils'
import { IAuthState } from 'app/redux/slices/auth/AuthSlice'

import { RequestHelper } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'

export function logoutReducer(): IAuthState {
	RequestHelper.removeHeader('Authorization')
	RequestHelper.removeHeader(AuthUtils.getTypeBehalfTo().headerName)
	RequestHelper.removeHeader(AuthUtils.getCodeBehalfTo(0).headerName)
	RequestHelper.removeHeader(AuthUtils.getViewAsDoctor().headerName)

	setAuthHeader()

	return {
		clinicPermissions: null,
		doctorGroupPermissions: null,
		user: null,
		isLoggedWithDigitalCertificate: false,
		token: '',
		version: '',
		patientPortal: null,
	}
}
