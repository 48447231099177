import { TitleExamCP } from 'modules/exams/components/exams/title-exam/TitleExamCP'
import { useEffect, useState } from 'react'
import { SendExamToPendingAnalysisRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/SendExamToPendingAnalysisRequestDTO'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { ExamsAnalysisRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsAnalysisRequests'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { IBasicModalCPProps, ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

interface ICPProps extends IBasicModalCPProps {
	exam: ExamResponseDTO
}

/**
 */
export function ModalSendExamToPendingAnalysisCP(props: ICPProps): JSX.Element {
	const [reason, setReason] = useState<string>()

	const sendExamToPendingAnalysisRequest = useRequest<void>('none')
	useEffect(onSendExamToPendingAnalysisRequestChange, [sendExamToPendingAnalysisRequest.isAwaiting])

	/**
	 * Salva o exame.
	 */
	async function sendExamToPendingAnalysis(): Promise<void> {
		if (StringUtils.isEmpty(reason)) return NotificationHelper.error('Ops', 'Preencha o motivo para prosseguir')

		const dto: SendExamToPendingAnalysisRequestDTO = {
			examCode: props.exam.code,
			reason: reason!,
		}
		sendExamToPendingAnalysisRequest.runRequest(ExamsAnalysisRequests.sendExamToPendingAnalysis(dto))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onSendExamToPendingAnalysisRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				sendExamToPendingAnalysisRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSave()
	}

	return (
		<ModalCP
			title={'Devolver Exame para Análise'}
			visible={props.visible}
			onOk={sendExamToPendingAnalysis}
			actionLoading={sendExamToPendingAnalysisRequest.isAwaiting}
			onCancel={props.onCancel}
		>
			<AlertCP
				message={'O laudo deste exame será descartado e o exame será enviado para análise do Grupo/Unidade Hospitalar'}
				type={'error'}
				margin={{ bottom: 20 }}
			/>

			<FlexCP justify={'center'}>
				<TitleExamCP
					modality={props.exam.modality}
					patientName={props.exam.patient.name}
					examTitle={props.exam.description}
					examStatus={props.exam.status}
				/>
			</FlexCP>

			<TitleCP underLine={true} textSize={'normal'} marginTop={30}>
				Descreva o motivo da devolução
			</TitleCP>
			<TextAreaCP
				placeholder={'Escreva os detalhes do motivo da devolução do exame'}
				value={reason}
				onChange={setReason}
				minRows={4}
				required={true}
			/>
		</ModalCP>
	)
}
