import { DoctorProfileFormModel } from 'modules/person/components/doctor/modal-doctor-data/inner/DoctorProfileFormModel'
import { ModalDoctorIdCP } from 'modules/person/components/doctor/modal-doctor-id/ModalDoctorIdCP'
import { useState } from 'react'
import { DoctorIdTP } from 'submodules/beerads-sdk/services/people/people/types/DoctorIdTP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TableCP } from 'submodules/nerit-framework-ui/common/components/table/TableCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
	formStateManager: IFormStateManager<DoctorProfileFormModel>
	doctorCode: number
}

/**
 */
export function TableDoctorIdsCP(props: ICPProps): JSX.Element {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
	const [selectedDoctorId, setSelectedDoctorId] = useState<DoctorIdTP>()
	const [selectedIndex, setSelectedIndex] = useState<number>()

	/**
	 */
	function onSave(idData: DoctorIdTP): void {
		const ids = props.formStateManager.getFieldValue('ids') ?? []
		if (selectedIndex === undefined) ids.push(idData)
		else ids[selectedIndex] = idData

		setIsModalVisible(false)
		setSelectedIndex(undefined)
		setSelectedDoctorId(undefined)
	}

	return (
		<>
			<FlexCP alignItems={'center'}>
				<TitleCP marginTop={30} marginBottom={10} textSize={'normal'} underLine={true}>
					Registros de Conselho
				</TitleCP>
				<ButtonCP
					size={'small'}
					icon={'plus'}
					marginLeft={10}
					marginTop={20}
					onClick={() => {
						setSelectedDoctorId(undefined)
						setSelectedIndex(undefined)
						setIsModalVisible(true)
					}}
				/>
			</FlexCP>

			<TableCP<DoctorIdTP>
				emptyText={'Nenhum número de registro de conselho cadastrado'}
				data={props.formStateManager.getFieldValue('ids')}
				columns={[
					{
						title: 'Tipo',
						render: (text, record) => record.type,
					},
					{
						title: 'UF',
						render: (text, record) => record.uf ?? '-',
					},
					{
						title: 'Número',
						render: (text, record) => record.id,
					},
					{
						title: 'Especialização',
						render: (text, record) => record.specialtyTitle ?? '-',
					},
					{
						render: (text, record, index) => (
							<ButtonGroupCP mode={'separeted'}>
								<ButtonCP
									icon={'edit'}
									size={'small'}
									onClick={() => {
										setSelectedIndex(index)
										setSelectedDoctorId(record)
										setIsModalVisible(true)
									}}
								/>
								<ButtonCP
									icon={'delete'}
									size={'small'}
									confirmMsg={'Tem certeza que deseja remover Registro'}
									type={'primary'}
									danger
									onClick={() => {
										const ids = props.formStateManager.getFieldValue('ids')
										ids.splice(index, 1)
										props.formStateManager.changeFieldValue('ids', ids)
									}}
								/>
							</ButtonGroupCP>
						),
					},
				]}
			/>

			<ModalDoctorIdCP
				visible={isModalVisible}
				onCancel={() => setIsModalVisible(false)}
				onSave={onSave}
				userCode={props.doctorCode}
				doctorId={selectedDoctorId}
			/>
		</>
	)
}
