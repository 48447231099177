import { useAppSelector } from 'app/redux/hook'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { AvatarExamModalityCP } from 'modules/exams/components/exams/avatar-exam-modality/AvatarExamModalityCP'
import { ButtonExamDetailsCP } from 'modules/exams/components/exams/button-exam-details/ButtonExamDetailsCP'
import { TagExamStatusCP } from 'modules/exams/components/exams/tag-exam-status/TagExamStatusCP'
import { ButtonDoMedicalReportCP } from 'modules/exams/components/medical-report/button-do-medical-report/ButtonDoMedicalReportCP'
import { ExamListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import * as S from './VxWindListExamsStyles'

interface ICPProps {
	exams: ExamListItemResponseDTO[]
	clinicCode?: number
	onReportClick: () => void
}

export function VxWindListExamsCP(props: ICPProps): JSX.Element {
	const { auth } = useAppSelector((state) => state)

	return (
		<S.WrapperSCP>
			{props.exams.map((exam) => (
				<FlexCP key={exam.id} alignItems="center" gap={2} justify="space-between">
					<FlexCP>
						<AvatarExamModalityCP size={64} modality={exam.modality} fontSize={'normal'} />

						<FlexCP flexDirection="column">
							<TextCP text={StringUtils.getFirstAndLastName(exam.patient.name)} size="normal" />
							<TextCP text={exam.description} size={'extraSmall'} />
							<TagExamStatusCP examStatus={exam.status} />
						</FlexCP>
					</FlexCP>
					<FlexCP gap={4}>
						<ButtonDoMedicalReportCP exam={exam} onClick={() => props.onReportClick} />
						{DoctorGroupPermissionUtils.canSeeExamData(auth.user, true, exam.clinic.code, exam.doctorGroupCode) && (
							<S.Wrapper>
								<ButtonExamDetailsCP examId={exam.id} zIndexDrawer={1000} />
							</S.Wrapper>
						)}
					</FlexCP>
				</FlexCP>
			))}
		</S.WrapperSCP>
	)
}
