import { Modal } from 'antd'
import { DoctorProductivityChartCP } from 'app/components/vx-wind/vx-wind-portal/inner/doctor-productivity/DoctorProductivityChartCP'
import { DoctorProductivityTimeTableCP } from 'app/components/vx-wind/vx-wind-portal/inner/doctor-productivity/DoctorProductivityTimeTableCP'
import { useAppSelector } from 'app/redux/hook'
import { CommonPermissionUtils } from 'modules/auth/permissions/CommonPermissionUtils'
import { useEffect, useState } from 'react'
import { AuthUserResponseDTO } from 'submodules/beerads-sdk/services/auth/dtos/responses/inner/AuthUserResponseDTO'
import { DashboardRequests } from 'submodules/beerads-sdk/services/dashboard/dashboard/DashboardRequests'
import { CountMedicalReportsDoneByClinicRequestDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/request/CountMedicalReportsDoneByClinicRequestDTO'
import { CountMedicalReportsDoneByClinicResponseDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/response/CountMedicalReportsDoneByClinicResponseDTO'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

export interface IDoctorProductivityCPProps {
	onClose: () => void
}

export function DoctorProductivityCP({ onClose }: IDoctorProductivityCPProps): JSX.Element {
	const loggedUser = useAppSelector((store) => store.auth.user) as AuthUserResponseDTO
	const currentDoctorGroup = useAppSelector((selector) => selector.doctorGroup)

	const firstName = loggedUser.name.split(' ')[0]

	const [totalMedicalReportsByClinic, setTotalMedicalReportsByClinic] = useState<number>(0)

	const countMedicalReportsDoneRequest = useRequest<ListResponseDTO<CountMedicalReportsDoneByClinicResponseDTO>>()

	useEffect(init, [])

	useEffect(onCountMedicalReportsDoneRequestChange, [countMedicalReportsDoneRequest.isAwaiting])

	function init(): void {
		if (!CommonPermissionUtils.canAccessByType(UserTypeEnum.DOCTOR, loggedUser)) return

		const dto: CountMedicalReportsDoneByClinicRequestDTO = {
			dateRange: { beginDate: new Date(), endDate: new Date() },
			doctorCode: loggedUser.code,
			doctorGroupCode: currentDoctorGroup.code ?? undefined,
		}

		countMedicalReportsDoneRequest.runRequest(DashboardRequests.countMedicalReportsDoneByClinic(dto))
	}

	function onCountMedicalReportsDoneRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(countMedicalReportsDoneRequest)) {
			return
		}

		const total = countMedicalReportsDoneRequest.responseData?.list.reduce((acc, item) => acc + item.totalMedicalReportDone, 0) ?? 0

		setTotalMedicalReportsByClinic(total)
	}

	return (
		<Modal
			open
			centered
			title="Resumo de produtividade"
			footer={null}
			width={1000}
			onCancel={onClose}
			onClose={onClose}
			styles={{
				header: {
					paddingInline: '10px',
				},
				content: {
					paddingInline: '10px',
				},
				body: {
					maxHeight: '70vh',
					overflowY: 'scroll',
					paddingInline: '20px',
				},
			}}
		>
			<FlexCP gap={20} flexDirection="column">
				<TextCP text={`Olá doutor ${firstName}, aqui está o resumo de sua produtividade:`} />
				<TextCP text={`Você impactou um total de ${totalMedicalReportsByClinic} vida(s)`} strong center size="extraLarge" />
			</FlexCP>

			<DoctorProductivityChartCP />
			<DoctorProductivityTimeTableCP />
		</Modal>
	)
}
