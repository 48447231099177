import { useAppSelector } from 'app/redux/hook'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import { ScreenPrivatePatientPortal } from 'app/screens/screen-private-patient-portal/ScreenPrivatePatientPortal'
import { AppUtils } from 'app/utils/AppUtils'
import { useEffect } from 'react'
import { RedirectCP } from 'submodules/nerit-framework-ui/common/router/components/redirect/RedirectCP'

/**
 */
export function PrivatePatientPortalAccessVerifierCP(): JSX.Element {
	const loggedUserData = useAppSelector((state) => state.auth)

	useEffect(updateLoggedUserData, [])

	function updateLoggedUserData(): void {
		if (!loggedUserData) return

		AppUtils.refreshToken(loggedUserData?.token)
	}

	if (!loggedUserData) return <RedirectCP path={PublicRouter.PATIENT_PORTAL_LOGIN} />

	return <ScreenPrivatePatientPortal />
}
