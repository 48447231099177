import { useEffect } from 'react'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface IUseFilterOnEnterProps {
	stateManager: IFormStateManager<any>
	onFilter: VoidFunction
}

export function useFilterOnEnter({ stateManager, onFilter }: IUseFilterOnEnterProps): void {
	function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement> | KeyboardEvent): void {
		if (event.key === 'Enter') {
			onFilter()
		}
	}

	function filterOnEnter(): VoidFunction {
		document.addEventListener('keydown', handleKeyPress)
		return () => {
			document.removeEventListener('keydown', handleKeyPress)
		}
	}

	useEffect(filterOnEnter, [stateManager])
}
