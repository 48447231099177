export enum MedicalReportVarsEnum {
	EXAM_COMPANY_REQUESTER = 'exame_solicitante',
	EXAM_COMPANY_REQUESTER_CRM = 'exame_solicitante_crm',
	EXAM_REASON = 'exame_motivo',
	EXAM_DATE = 'exame_data',
	EXAM_NUMBER = 'exame_numero_pedido',
	EXAM_CODE = 'exame_codigo',
	EXAM_EXTRA_INFO = 'exame_extra_info',

	MEDICAL_REPORT_DATE_HOUR = 'laudo_data_hora',

	PATIENT_NAME = 'paciente_nome',
	PATIENT_AGE = 'paciente_idade',
	PATIENT_BIRTHDAY = 'paciente_nascimento',
	PATIENT_ID = 'paciente_id',
	PATIENT_SEX = 'paciente_sexo',

	DOCTOR_NAME = 'medico_nome',
	DOCTOR_ID_TYPE = 'medico_tipo_registro',
	DOCTOR_ID = 'medico_registro',
	DOCTOR_SPECIALTY = 'medico_especialidade',
	DOCTOR_SIGNATURE = 'medico_assinatura',

	DOCTOR_GROUP_NAME = 'grupo_medico_nome',
	DOCTOR_GROUP_ID_TYPE = 'grupo_medico_tipo_registro',
	DOCTOR_GROUP_ID = 'grupo_medico_registro',

	CLINIC_LOGO = 'clinica_logo',
	CLINIC_NAME = 'clinica_nome',
	CLINIC_ADDRESS = 'clinica_endereco',
	CLINIC_TECHNICAL_RESPONSIBLE_NAME = 'clinica_rt_nome',
	CLINIC_TECHNICAL_RESPONSIBLE_ID = 'clinica_rt_id',

	SYSTEM_LOGO = 'sistema_logo',

	PATIENT_PORTAL_QR_CODE = 'portal_paciente_qrcode',
	PATIENT_PORTAL_URL = 'beerads_laudo_url',
	PATIENT_PORTAL_FILLED_URL = 'beerads_laudo_filled_url',

	WATERMARK_TEXT = 'texto_marca_dagua',

	HTML_BOX_MEDICAL_REPORT_CONTENT = 'conteudo_laudo',
	HTML_BOX_DOCTOR_SIGNATURE_1 = 'box_assinatura_medico_1',
	HTML_BOX_DOCTOR_SIGNATURE_2 = 'box_assinatura_medico_2',
}
