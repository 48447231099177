import { useAppSelector } from 'app/redux/hook'
import { ClinicPermissionUtils } from 'modules/auth/permissions/ClinicPermissionUtils'
import { useEffect, useState } from 'react'
import { ExamStatusLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ExamStatusGroupEnum, ExamStatusGroupLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { ExamStatusUtils } from 'submodules/beerads-sdk/services/exams/exams/utils/ExamStatusUtils'
import { SelectFullGroupedOptionTP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
	formStateManager: IFormStateManager<any>
	fieldName: string
	viewAsDoctor?: boolean
	disabled?: boolean
}

/**
 * Drawer para triar um exam.
 */
export function SelectExamStatusCP(props: ICPProps): JSX.Element {
	const [options, setOptions] = useState<SelectFullGroupedOptionTP[]>([])
	useEffect(init, [props.viewAsDoctor])
	const clinicPermissions = useAppSelector((state) => state.auth.clinicPermissions)

	/**
	 */
	function init(): void {
		const examStatusOfExamStatusGroupMap = new Map(ExamStatusUtils.examStatusOfExamStatusGroupMap)

		// Se o modo for medico e se ele não for admin, filtra apenas os status que um medico pode ver
		if (props.viewAsDoctor && !ClinicPermissionUtils.hasAnyPermissionsInAnyClinic(['isDoctorGroupUser', 'isDoctorGroupAdmin'], clinicPermissions)) {
			examStatusOfExamStatusGroupMap.delete(ExamStatusGroupEnum.PENDING)
			examStatusOfExamStatusGroupMap.delete(ExamStatusGroupEnum.PENDING_ANALYSIS)
			examStatusOfExamStatusGroupMap.delete(ExamStatusGroupEnum.ARCHIVED)
		}

		const statusOptions: SelectFullGroupedOptionTP[] = []
		examStatusOfExamStatusGroupMap.forEach((value, key) => {
			statusOptions.push({
				groupName: ExamStatusGroupLabelEnum[key],
				options: value.map((status) => ({
					label: ExamStatusLabelEnum[status],
					value: status,
				})),
			})
		})
		setOptions(statusOptions)
	}

	return (
		<SelectCP
			grouped
			allowClear
			formStateManager={props.formStateManager}
			fieldName={props.fieldName}
			label={'Status do Exame'}
			isMultiple={true}
			options={options}
			disabled={props.disabled}
			maxTagCount={3}
		/>
	)
}
