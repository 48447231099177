import { AppUtils } from 'app/utils/AppUtils'
import { TitleBrowserCP } from 'submodules/nerit-framework-ui/common/components/title-browser/TitleBrowserCP'

interface ICPProps {
	pageName?: string
}

/**
 * Title da página no Browser
 */
export function BeeRadsTitleBrowserCP(props: ICPProps): JSX.Element {
	let pageName
	if (!!props.pageName) pageName = `${props.pageName} | ${AppUtils.getDomainName()}`
	else pageName = AppUtils.getDomainName(true)

	return <TitleBrowserCP pageName={pageName} />
}
