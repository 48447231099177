import { TableTemplatesMedicalReportHistoryUtils } from 'modules/template/components/table-templates-medical-report-history/inner/TableTemplatesMedicalReportHistoryUtils'
import { useEffect, useState } from 'react'
import { TemplateHistoryResponseDTO } from 'submodules/beerads-sdk/services/templates/history-templates/dtos/response/TemplateHistoryResponseDTO'
import { TemplatesHistoryRequests } from 'submodules/beerads-sdk/services/templates/templates/TemplatesHistoryRequests'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { TableCP } from 'submodules/nerit-framework-ui/common/components/table/TableCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps {
	templateHistories?: TemplateResponseDTO
	currentTemplateList?: TemplateResponseDTO[]
	onChangeData: () => void
}

export function TableTemplateMedicalReportHistoryCP(props: ICPProps): JSX.Element {
	const request = useRequest<ListResponseDTO<TemplateHistoryResponseDTO>>('api-return')
	const [data, setData] = useState<ListResponseDTO<TemplateHistoryResponseDTO>>()
	const [isLoading, setIsLoading] = useState(false)

	const [loadList, setLoadList] = useState<number>(0)

	useEffect(init, [loadList])

	useEffect(onChange, [request.isAwaiting])

	function init(): void {
		request.runRequest(
			TemplatesHistoryRequests.searchTemplateHistory({
				templateCode: props.templateHistories?.code,
			}),
		)
	}

	function onChange(): void {
		if (!RequestUtils.isValidRequestReturn(request, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		if (request.responseData) setData(request.responseData)
	}

	function onChangeDataOnTable(): void {
		setLoadList(TableUtils.getReloadNumber())
		props.onChangeData()
	}

	return (
		<TableCP<TemplateHistoryResponseDTO>
			data={data?.list}
			loading={isLoading || request.isAwaiting}
			columns={TableTemplatesMedicalReportHistoryUtils.getColumns(onChangeDataOnTable, setIsLoading, props.currentTemplateList)}
		/>
	)
}
