import { Table } from 'antd'
import { ColumnProps, TableProps } from 'antd/es/table'
import { TableRowSelection } from 'antd/es/table/interface'
import { PaginationConfig } from 'antd/lib/pagination'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import React from 'react'
import styled from 'styled-components'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { EmptyCP } from 'submodules/nerit-framework-ui/common/components/empty/EmptyCP'
import { TablePaginationTP } from 'submodules/nerit-framework-ui/common/components/table/types/TablePaginationTP'
import { TableSortAndPageTP } from 'submodules/nerit-framework-ui/common/components/table/types/TableSortAndPageTP'
import { TableSortTP } from 'submodules/nerit-framework-ui/common/components/table/types/TableSortTP'
import { ColorUtils } from 'submodules/nerit-framework-ui/common/utils/ColorUtils'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { OrderingEnum } from 'submodules/nerit-framework-utils/utils/enums/OrderingEnum'
import { OrUndefinedTP } from 'submodules/nerit-framework-utils/utils/types/OrUndefinedTP'

interface ICPProps<RowTP> {
	data?: RowTP[]
	columns?: Array<ColumnProps<RowTP>>
	sortAndPage?: TableSortAndPageTP
	onSortOrChangePagination?: (sortAndPage?: TableSortAndPageTP) => void

	rowKey?: (row: RowTP, index: number) => string
	bordered?: boolean
	rowSelection?: TableRowSelection<RowTP>
	loading?: boolean
	children?: React.ReactNode
	rowClassName?: (record: RowTP, index: number) => string
	showHeader?: boolean
	onRow?: TableProps<RowTP>['onRow']
	emptyText?: string

	expandedRowRender?: (record: RowTP, index: number, indent: number, expanded: boolean) => React.ReactNode
	onExpand?: (expanded: boolean, record: RowTP) => void
	rowKeyGetter?: (row: RowTP, index: number) => string

	wrappedOnCard?: boolean
}

/**
 * Tabela.
 */
export function TableCP<RowTP = any>(props: ICPProps<RowTP>): JSX.Element {
	/**
	 * Monta a Key de cada linha.
	 */
	function rowKeyGetter(row: RowTP, index: number): string {
		if (!!props.rowKeyGetter) return props.rowKeyGetter(row, index)

		const _row = row as any

		const defaultRowKey: number | string =
			((_row.code ?? _row.key ?? _row.id) as OrUndefinedTP<number | string>) ??
			`table_${DateUtils.getFormatted(new Date(), DateFormatEnum.US_WITHOUT_TIME)}_${index}`

		return defaultRowKey.toString()
	}

	/**
	 * Ao mudar paginacao ou ordenacao.
	 */
	function onChange(paginationConfig?: PaginationConfig, sortConfig?: SorterResult<RowTP>): void {
		if (!props.onSortOrChangePagination) return

		let sorter: TableSortTP | undefined
		if (!!sortConfig?.columnKey) {
			sorter = {
				column: sortConfig?.columnKey,
				order: sortConfig?.order === 'descend' ? OrderingEnum.DESC : OrderingEnum.ASC,
			}
		}

		let pagination: TablePaginationTP | undefined
		if (!!paginationConfig) {
			pagination = {
				total: paginationConfig.total!,
				current: paginationConfig.current!,
				pageSize: paginationConfig.pageSize!,
				showTotal: paginationConfig.showTotal,
			}
		}

		props.onSortOrChangePagination({
			sort: sorter ?? props.sortAndPage?.sort,
			pagination: pagination ?? props.sortAndPage?.pagination,
		})
	}

	function renderTable(): JSX.Element {
		return (
			<Table<RowTP>
				rowSelection={props.rowSelection}
				rowKey={rowKeyGetter}
				dataSource={props.data}
				columns={props.columns}
				pagination={props.sortAndPage?.pagination ? { position: 'bottom', size: 'small', ...props.sortAndPage.pagination } : false}
				onChange={(pagination, filters, sorter) => onChange(pagination, sorter)}
				rowClassName={props.rowClassName}
				size={'small'}
				locale={{ emptyText: <EmptyCP description={props.emptyText ?? 'Nenhum dado encontrado para os filtros selecionados'} /> }}
				loading={props.loading}
				showHeader={props.showHeader !== false}
				onRow={props.onRow}
				expandedRowRender={props.expandedRowRender}
				onExpand={props.onExpand}
				bordered={props.bordered}
			>
				{props.children}
			</Table>
		)
	}

	return (
		<TableSCP isClickable={false}>
			{props.wrappedOnCard ? (
				<CardCP innerSpacing={'none'} bordered={false}>
					{renderTable()}
				</CardCP>
			) : (
				renderTable()
			)}
		</TableSCP>
	)
}

const TableSCP = styled.div<{ isClickable: boolean }>`
	width: 100%;

	.row-nerit-error {
		background: ${ColorUtils.lightenColor(ThemeProject.error, 210)};

		:hover {
			background: ${ColorUtils.lightenColor(ThemeProject.error, 200)};
		}

		td {
			color: ${ThemeFrameworkCommon.black} !important;
		}
	}

	.ant-table-small {
		border: none !important;
	}

	.ant-table-body {
		color: ${ThemeProject.colorText};
		margin: 0 !important;
	}

	.ant-table-wrapper thead {
		tr th {
			color: ${ThemeProject.colorText};
			font-weight: bold;
			font-size: 0.8rem;
			padding: 12px 8px !important;
		}
	}

	.ant-table-wrapper tbody {
		tr:hover td {
			background: rgba(218, 225, 239, 0.3) !important; // Hex: #DAE1EFF4
			cursor: ${(props) => (props.isClickable ? 'pointer' : undefined)};
		}

		tr td {
			font-size: 11px;
		}
	}

	.ant-table-wrapper .ant-pagination {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.ant-pagination-total-text {
		color: ${ThemeFrameworkCommon.browserDefaultColorDark};
	}

	.ant-table-wrapper .ant-table-column-sorter-down.active {
		color: red !important;
	}

	.n-selected-row {
		background-color: ${ThemeProject.backgroundMain};
	}

	.ant-table-content {
		border: none !important;
	}
`
