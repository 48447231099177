import { IntercomCP } from 'app/components/intercom/IntercomCP'
import { OPEN_DIGITAL_CERTIFICATE_AUTH_PARAM } from 'app/components/menu-item-profile/MenuItemProfileCP'
import { useAppSelector } from 'app/redux/hook'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { ScreenContentUserLoginCP } from 'modules/auth/screens/screen-user-login/content/ScreenContentUserLoginCP'
import { useLocation } from 'react-router-dom'
import { LayoutImageSiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-image-sider/LayoutImageSiderCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'

/**
 * Login de usuario do sistema (medico / funcionario de clinica).
 */
export function ScreenUserLogin(): JSX.Element {
	const routeLocation = useLocation()
	const params = new URLSearchParams(routeLocation.search)
	const forceLoginParam = Boolean(params.get('forceLogin'))
	const loggedUser = useAppSelector((selector) => selector.auth.user)

	if (!!loggedUser && !forceLoginParam) {
		NeritFrameworkRoutingHelper.historyPush(
			`${PrivateUserRouter.HOME}?${OPEN_DIGITAL_CERTIFICATE_AUTH_PARAM}=${loggedUser.hasDigitalCertificate ?? false}`,
		)
	}

	return (
		<LayoutImageSiderCP
			sider={{
				width: '60%',
				imageUrl: '/images/brCover4.png',
			}}
			content={{
				logoUrl: ThemeProject.logoLoginUrl,
				logoWidth: 300,
			}}
		>
			<ScreenContentUserLoginCP />

			<IntercomCP />
		</LayoutImageSiderCP>
	)
}
