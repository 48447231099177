import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { ScreenContentDoctorGroupClinics } from 'modules/doctor-groups/screens/screen-doctor-group/content/ScreenContentDoctorGroupClinics'
import { ScreenContentDoctorGroupDataCP } from 'modules/doctor-groups/screens/screen-doctor-group/content/ScreenContentDoctorGroupData'
import { ScreenContentDoctorGroupPeopleDoctors } from 'modules/doctor-groups/screens/screen-doctor-group/content/ScreenContentDoctorGroupPeopleDoctors'
import { ScreenContentDoctorGroupPeopleUsers } from 'modules/doctor-groups/screens/screen-doctor-group/content/ScreenContentDoctorGroupPeopleUsers'
import { ScreenContentDoctorGroupTeams } from 'modules/doctor-groups/screens/screen-doctor-group/content/ScreenContentDoctorGroupTeams'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { ScreenSubMenuTP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/submenu/inner/ScreenSubMenuItemTP'
// import { ScreenContentDoctorGroupExams } from 'modules/doctor-groups/screens/screen-doctor-group/content/ScreenContentDoctorGroupExams'
import { IAuthState } from 'app/redux/slices/auth/AuthSlice'
import { ScreenContentDoctorGroupTemplates } from 'modules/doctor-groups/screens/screen-doctor-group/content/ScreenContentDoctorGroupTemplates'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
// import { ScreenContentDoctorGroupExamGroup } from 'modules/doctor-groups/screens/screen-doctor-group/content/ScreenContentDoctorGroupExamGroup'
// import { ExamGroupTypeEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamTypeEnum'
// import { ScreenContentExamGroupPricingClinic } from 'modules/doctor-groups/screens/screen-doctor-group/content/ScreenContentExamGroupPricingClinic'
// import { ScreenContentExamGroupDoctorRevenue } from 'modules/doctor-groups/screens/screen-doctor-group/content/ScreenContentExamGroupDoctorRevenue'

export const DOCTOR_GROUP_SUBMENU_GENERAL_KEY = 'general'
export const DOCTOR_GROUP_SUBMENU_TEAMS_KEY = 'teams'
export const DOCTOR_GROUP_SUBMENU_EXAMS_KEY = 'exams'
export const DOCTOR_GROUP_SUBMENU_FINANCIAL_KEY = 'financial'
export type DoctorGroupViewTP =
	| 'doctorGroupData'
	| 'doctorGroupClinics'
	| 'doctorGroupPeopleUsers'
	| 'doctorGroupTeams'
	| 'doctorGroupPeopleDoctors'
	| 'doctorGroupExams'
	| 'doctorGroupTemplates'
	| 'doctorGroupPricingClinic'
	| 'doctorGroupDoctorRevenue'
	| 'doctorGroupClinicBilling'
	| 'doctorGroupDoctorRevenue'
	| 'DoctorGroupExamGroup'

export const DoctorGroupUtils = {
	getSubMenuConfigs(auth: IAuthState, doctorGroupCode: number): Array<ScreenSubMenuTP<DoctorGroupViewTP>> {
		return [
			{
				key: DOCTOR_GROUP_SUBMENU_GENERAL_KEY,
				title: 'Geral',
				items: [
					{
						name: 'Dados do Grupo',
						view: 'doctorGroupData',
						route: PrivateUserRouter.DOCTOR_GROUP_DATA,
						content: <ScreenContentDoctorGroupDataCP doctorGroupCode={doctorGroupCode} />,
						contentTitle: 'Dados do Grupo',
					},
					{
						name: 'Unidades Hospitalares',
						view: 'doctorGroupClinics',
						route: PrivateUserRouter.DOCTOR_GROUP_CLINICS,
						content: <ScreenContentDoctorGroupClinics doctorGroupCode={doctorGroupCode} />,
						contentTitle: 'Unidades Hospitalares',
					},
					{
						name: 'Usuários',
						view: 'doctorGroupPeopleUsers',
						route: PrivateUserRouter.DOCTOR_GROUP_PEOPLE_USERS,
						content: <ScreenContentDoctorGroupPeopleUsers doctorGroupCode={doctorGroupCode} role={UserTypeEnum.USER} />,
						contentTitle: 'Usuários',
					},
				],
				enabled: DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(auth.user, doctorGroupCode, [
					'isDoctorGroupAdmin',
					'isDoctorGroupUser',
				]),
			},
			{
				key: DOCTOR_GROUP_SUBMENU_TEAMS_KEY,
				title: 'Corpo Clínico',
				items: [
					{
						name: 'Times de Médicos',
						view: 'doctorGroupTeams',
						route: PrivateUserRouter.DOCTOR_GROUP_TEAMS,
						content: <ScreenContentDoctorGroupTeams doctorGroupCode={doctorGroupCode} />,
						contentTitle: 'Times de Médicos',
					},
					{
						name: 'Médicos',
						view: 'doctorGroupPeopleDoctors',
						route: PrivateUserRouter.DOCTOR_GROUP_PEOPLE_DOCTORS,
						content: <ScreenContentDoctorGroupPeopleDoctors doctorGroupCode={doctorGroupCode} role={UserTypeEnum.DOCTOR} />,
						contentTitle: 'Médicos',
					},
				],
				enabled: DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(auth.user, doctorGroupCode, [
					'isDoctorGroupAdmin',
					'isDoctorGroupUser',
				]),
			},
			{
				key: DOCTOR_GROUP_SUBMENU_EXAMS_KEY,
				title: 'Exames',
				items: [
					// {
					// 	name: 'Lista de Exames',
					// 	view: 'doctorGroupExams',
					// 	route: PrivateUserRouter.DOCTOR_GROUP_EXAMS,
					// 	content: <ScreenContentDoctorGroupExams doctorGroupCode={doctorGroupCode} />,
					// 	contentTitle: 'Lista de Exames',
					// },
					{
						name: 'Templates de Laudo',
						view: 'doctorGroupTemplates',
						route: PrivateUserRouter.DOCTOR_GROUP_TEMPLATES,
						content: <ScreenContentDoctorGroupTemplates type={TemplateTypeEnum.TEMPLATE} filters={{ doctorGroupCode: doctorGroupCode }} />,
						contentTitle: 'Templates de Laudo',
					},
				],
				enabled: DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(auth.user, doctorGroupCode, [
					'isDoctorGroupAdmin',
					'isDoctorGroupUser',
				]),
			},
			// {
			// 	key: DOCTOR_GROUP_SUBMENU_FINANCIAL_KEY,
			// 	title: 'Financeiro',
			// 	items: [
			// 		{
			// 			name: 'Preços de Exames',
			// 			view: 'doctorGroupPricingClinic',
			// 			route: PrivateUserRouter.DOCTOR_GROUP_EXAM_PRICE,
			// 			content: <ScreenContentExamGroupPricingClinic doctorGroupCode={doctorGroupCode} />,
			// 			contentTitle: 'Preços de Exames',
			// 		},
			// 		{
			// 			name: 'Valores de Repasse Médico',
			// 			view: 'doctorGroupDoctorRevenue',
			// 			route: PrivateUserRouter.DOCTOR_GROUP_REVENUE_PRICE,
			// 			content: <ScreenContentExamGroupDoctorRevenue doctorGroupCode={doctorGroupCode} />,
			// 			contentTitle: 'Valores de Repasse Médico',
			// 		},
			// 		{
			// 			name: 'Grupos de Faturamento',
			// 			view: 'doctorGroupClinicBilling',
			// 			route: PrivateUserRouter.DOCTOR_GROUP_CLINIC_BILLING,
			// 			content: <ScreenContentDoctorGroupExamGroup doctorGroupCode={doctorGroupCode} type={ExamGroupTypeEnum.CLINIC_BILLING} />,
			// 			contentTitle: 'Grupos de Faturamento',
			// 		},
			// 		{
			// 			name: 'Grupos de Repasse',
			// 			view: 'DoctorGroupExamGroup',
			// 			route: PrivateUserRouter.DOCTOR_GROUP_DOCTOR_REVENUE,
			// 			content: <ScreenContentDoctorGroupExamGroup doctorGroupCode={doctorGroupCode} type={ExamGroupTypeEnum.DOCTOR_REVENUE} />,
			// 			contentTitle: 'Grupos de Repasse',
			// 		},
			// 	],
			// 	enabled: DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(doctorGroupCode, ['isDoctorGroupAdmin', 'isDoctorGroupUser'])
			// },
		]
	},

	slugMask(str: string): string {
		// Remover acentos
		if (str) {
			str = str.toLowerCase()

			const accentReplaceRegex = {
				áàâã: 'a',
				éèê: 'e',
				íìî: 'i',
				óòôõ: 'o',
				úùû: 'u',
				ç: 'c',
			}

			Object.keys(accentReplaceRegex).forEach((regexGroup) => {
				const regex = new RegExp(`[${regexGroup}]`, 'g')
				str = str.replace(regex, accentReplaceRegex[regexGroup])
			})

			// Remover espacos & caracteres especiais
			str = str.replace(/[ ]/g, '-')
			return str.replace(/[^\d\w-_ ]/g, '')
		}

		return ''
	},
}
