import { PersonFormModel } from 'modules/person/components/user/form-user/inner/PersonFormModel'
import { useEffect } from 'react'
import { PersonByCpfResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PersonByCpfResponseDTO'
import { PeopleRequests } from 'submodules/beerads-sdk/services/people/people/PeopleRequests'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { HttpStatusEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpStatusEnum'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

interface IAddUserModalCPProps {
	formStateManager: IFormStateManager<PersonFormModel>
	onResult: (person?: PersonByCpfResponseDTO) => void
}

/**
 * Input para buscar uma pessoa pelo CPF.
 */
export function InputSearchPersonByCpfCP(props: IAddUserModalCPProps): JSX.Element {
	const searchUserRequest = useRequest<PersonByCpfResponseDTO>()
	useEffect(OnSearchRequestChange, [searchUserRequest.isAwaiting])

	/**
	 * Buscar pessoa pelo CPF.
	 */
	function search(): void {
		const cpf = props.formStateManager.getFieldValue('cpf')
		if (!cpf) {
			NotificationHelper.error('Ops', 'Preencha o CPF')
			return
		}
		searchUserRequest.runRequest(PeopleRequests.findByCpf(StringUtils.stripNonNumericChars(cpf)))
	}

	/**
	 * Retorno da busca
	 */
	function OnSearchRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(searchUserRequest, 'Erro ao buscar pessoa') &&
			searchUserRequest.responseStatus !== HttpStatusEnum.NOT_FOUND
		)
			return

		props.onResult(searchUserRequest.responseData)
	}

	return (
		<FlexCP alignItems={'center'}>
			<InputCP label={'CPF'} mask={InputMaskTypeEnum.CPF} formStateManager={props.formStateManager} fieldName={'cpf'} />
			<ButtonCP onClick={search} icon={'search'} ghost loading={searchUserRequest.isAwaiting} marginLeft={20} />
		</FlexCP>
	)
}
