import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/AvatarCP'
import * as React from 'react'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import { UpdateAppVersionCP } from 'submodules/nerit-framework-ui/common/components/system/update-app-version/UpdateAppVersionCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

interface IProfileMenuItemICPProps {
	profileImage?: string
	profileImageIcon?: React.ReactNode
	profileOptions: Array<{ text: string; action: () => void; hidden?: boolean }>
	optionTitle: string
	isTopMenu?: boolean
}

/**
 * Foto e menu de profile no final do menu.
 */
export function ProfileMenuItemCP(props: IProfileMenuItemICPProps): JSX.Element {
	const screenSize = useScreenSize()

	return (
		<FlexCP>
			{props.isTopMenu && !screenSize.smd && (
				<TextCP
					text={`Bem-vindo(a), ${StringUtils.getFirstName(props.optionTitle)}`}
					wrap={false}
					marginRight={10}
					color={ThemeFrameworkCommon.browserDefaultColorDark}
				/>
			)}
			<PopOverCP
				trigger={'click'}
				placement={'rightTop'}
				title={props.optionTitle}
				content={
					<div className="box-border">
						{props.profileOptions
							.filter((opt) => !opt.hidden)
							.map((opt) => (
								<div onClick={opt.action} role="button" key={opt.text} className="cursor-pointer py-2 font-bold">
									{opt.text}
								</div>
							))}

						<UpdateAppVersionCP />
					</div>
				}
			>
				<div className={`relative flex w-full items-center justify-center ${props.isTopMenu ?? 'mb-5'}`}>
					<Avatar className="cursor-pointer">
						<AvatarImage src={props.profileImage} />
						<AvatarFallback className="text-md font-medium text-slate-950 dark:bg-slate-600">
							{StringUtils.getFirstAndLastInitials(props.optionTitle)}
						</AvatarFallback>
					</Avatar>
					{props.profileImageIcon}
				</div>
			</PopOverCP>
		</FlexCP>
	)
}
