import { Result } from 'antd'

interface ICPProps {
	status?: 'success' | 'error' | 'info' | 'warning'
	title?: string
	subTitle?: string
	extra?: JSX.Element[]
}

export function ResultCP(props: ICPProps): JSX.Element {
	return <Result status={props.status} title={props.title} subTitle={props.subTitle} extra={props.extra} />
}
