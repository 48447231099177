import { registerDecorator, ValidationArguments, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'
import * as CPFValidator from '@fnando/cpf'
import { ValidationTypes } from '../ValidationTypes'
import * as _ from 'lodash-es'

/**
 * VALIDATOR
 * Valida Cpf
 *
 * @author guilherme.diniz
 */
@ValidatorConstraint({ name: ValidationTypes.IS_VALID_CPF })
class IsValidCpfConstraint implements ValidatorConstraintInterface {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	validate(value: string, args: ValidationArguments): boolean {
		return !_.isEmpty(value) && CPFValidator.isValid(value)
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	defaultMessage(args: ValidationArguments): string {
		return 'Cpf Inválido!'
	}
}

/**
 * DECORATOR
 * @param {ValidationOptions} validationOptions
 * @return {(object: Object, propertyName: string) => void}
 * @constructor
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function IsValidCpf(validationOptions?: ValidationOptions) {
	return (object: {}, propertyName: string) => {
		registerDecorator({
			target: object.constructor,
			propertyName,
			options: validationOptions,
			constraints: [],
			validator: IsValidCpfConstraint,
		})
	}
}
