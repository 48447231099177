import { useAppDispatch } from 'app/redux/hook'
import { authActions } from 'app/redux/slices/auth/AuthSlice'
import { PrivatePatientPortalRouter } from 'app/routers/private/PrivatePatientPortalRouter'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { FormPatientLoginCP } from 'modules/patient-portal/components/form-patient-login/FormPatientLoginCP'
import { PatientLoginFormModel } from 'modules/patient-portal/components/form-patient-login/inner/PatientLoginFormModel'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PatientPortalLoginRequestDTO } from 'submodules/beerads-sdk/services/patient-portal/dtos/request/PatientPortalLoginRequestDTO'
import { PatientPortalLoginResponseDTO } from 'submodules/beerads-sdk/services/patient-portal/dtos/response/PatientPortalLoginResponseDTO'
import { PatientPortalRequests } from 'submodules/beerads-sdk/services/patient-portal/PatientPortalRequests'
import { LayoutImageSiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-image-sider/LayoutImageSiderCP'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'

/**
 */
export function ScreenPatientPortalLogin(): JSX.Element {
	const [formModel] = useState<PatientLoginFormModel>(new PatientLoginFormModel())
	const formStateManager = useFormStateManager(formModel)
	const dispatch = useAppDispatch()

	const patientPortalLoginRequest = useRequest<PatientPortalLoginResponseDTO>()
	useEffect(onPatientPortalLoginRequestChange, [patientPortalLoginRequest.isAwaiting])

	/**
	 */
	async function doLogin(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) return

		const formValues = formStateManager.getFormValues()!
		const dto: PatientPortalLoginRequestDTO = {
			patientBirthday: DateUtils.toDate(formValues.patientBirthday, DateFormatEnum.BR_WITHOUT_TIME),
			examCode: formValues.examCode,
			patientName: formValues.patientName,
		}
		patientPortalLoginRequest.runRequest(PatientPortalRequests.login(dto))
	}

	/**
	 * Retorno Requisicao
	 */
	function onPatientPortalLoginRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(patientPortalLoginRequest, 'Dados inválidos')) return

		const result = patientPortalLoginRequest.responseData!

		dispatch(authActions.setLoggedPatient(result))

		NeritFrameworkRoutingHelper.historyPush(PrivatePatientPortalRouter.HOME)
	}

	return (
		<LayoutImageSiderCP
			sider={{
				width: '60%',
				imageUrl: '/images/brCover3.jpg',
			}}
			content={{
				logoUrl: ThemeProject.logoLoginUrl,
				logoWidth: 300,
			}}
		>
			<ContentCP>
				<FormWrapperSCP>
					<FormPatientLoginCP formStateManager={formStateManager} onDoLogin={doLogin} loading={patientPortalLoginRequest.isAwaiting} />
				</FormWrapperSCP>
			</ContentCP>
		</LayoutImageSiderCP>
	)
}

const FormWrapperSCP = styled.div`
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
`
