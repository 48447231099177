import React from 'react'
import { TooltipCP, TooltipPlacementTP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import { PopConfirmCP } from 'submodules/nerit-framework-ui/common/components/pop-confirm/PopConfirmCP'

interface IButtonContentICPProps {
	children: JSX.Element
	onClick?: () => void
	tooltip?: string
	tooltipPlacement?: TooltipPlacementTP
	confirmMsg?: string
}

/**
 * Monta conteudo interno de 01 botao generico.
 */
export function ButtonContentICP(props: IButtonContentICPProps): JSX.Element {
	if (!!props.tooltip && !!props.confirmMsg) {
		// Ambos
		return (
			<TooltipCP text={props.tooltip} placement="top" showSpan>
				<PopConfirmCP title={props.confirmMsg} onConfirm={props.onClick} placement="bottom">
					{props.children}
				</PopConfirmCP>
			</TooltipCP>
		)
	}

	if (!!props.tooltip) {
		// Inclui tooltip
		return (
			<TooltipCP text={props.tooltip} placement={props.tooltipPlacement} showSpan>
				{props.children}
			</TooltipCP>
		)
	}

	if (!!props.confirmMsg) {
		// Inclui popup de confirmacao
		return (
			<PopConfirmCP title={props.confirmMsg} onConfirm={props.onClick}>
				{props.children}
			</PopConfirmCP>
		)
	}

	return props.children
}
