import { useAppSelector } from 'app/redux/hook'
import { ReturnedExamFormModel } from 'modules/exams/components/exam-analysis/drawer-exam-returned/inner/ReturnedExamFormModel'
import { ExamHistoryComments } from 'modules/exams/components/exam-history/exam-history-comments/ExamHistoryComments'
import { AlertExamCountImagesCP } from 'modules/exams/components/exams/alert-exam-count-images/AlertExamCountImagesCP'
import { ButtonArchiveExamCP } from 'modules/exams/components/exams/button-archive-exam/ButtonArchiveExamCP'
import { FormExamPendingAnamnesisDataCP } from 'modules/exams/components/exams/drawer-exam-pending/inner/FormExamPendingAnamnesisDataCP'
import { FormExamPendingDoctorGroupDataCP } from 'modules/exams/components/exams/drawer-exam-pending/inner/FormExamPendingDoctorGroupDataCP'
import { FormExamPendingExamDataCP } from 'modules/exams/components/exams/drawer-exam-pending/inner/FormExamPendingExamDataCP'
import { FormExamPendingPatientDataCP } from 'modules/exams/components/exams/drawer-exam-pending/inner/FormExamPendingPatientDataCP'
import { PendingExamFormModel } from 'modules/exams/components/exams/drawer-exam-pending/inner/form-models/PendingExamFormModel'
import { PendingExamFormModelConverter } from 'modules/exams/components/exams/drawer-exam-pending/inner/form-models/PendingExamFormModelConverter'
import { useEffect, useState } from 'react'
import { ExamsAnalysisRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsAnalysisRequests'
import { ExamReturnedResolveRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamReturnedResolveRequestDTO'
import { ExamReturnedResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamReturnedResponseDTO'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { DrawerCP } from 'submodules/nerit-framework-ui/common/components/drawer/DrawerCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { IBasicModalCPProps } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

interface ICPProps extends IBasicModalCPProps {
	examCode: number
	examId: string
}

/**
 */
export function DrawerExamReturnedCP(props: ICPProps): JSX.Element {
	const [returnedFormModel, setReturnedFormModel] = useState<ReturnedExamFormModel>(new ReturnedExamFormModel())
	const returnedFormStateManager = useFormStateManager<ReturnedExamFormModel>(returnedFormModel)
	const loggedUser = useAppSelector((state) => state.auth.user)

	const [formValidator, setFormValidator] = useState<PendingExamFormModel>(new PendingExamFormModel())
	const formStateManager = useFormStateManager<PendingExamFormModel>(formValidator)

	const [loadedExam, setLoadedExam] = useState<ExamReturnedResponseDTO>()
	const getExamRequest = useRequest<ExamReturnedResponseDTO>()
	useEffect(onGetExamRequestChange, [getExamRequest.isAwaiting])

	const resolveExamRequest = useRequest<void>('none')
	useEffect(onResolveExamRequestChange, [resolveExamRequest.isAwaiting])

	useEffect(init, [props.visible])

	/**
	 * Inicializa a tela
	 */
	function init(): void {
		setReturnedFormModel(new ReturnedExamFormModel({}))
		setLoadedExam(undefined)
		if (!props.visible) return

		getExamRequest.runRequest(ExamsAnalysisRequests.getExamReturned(props.examCode))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetExamRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getExamRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = getExamRequest.responseData!
		setLoadedExam(result)
		setFormValidator(PendingExamFormModelConverter.getFormModel(result.exam))
	}

	/**
	 * Salva o exame.
	 */
	async function save(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(returnedFormStateManager))) return

		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) return

		const returnedFormValues = returnedFormStateManager.getFormValues()!
		const formValues = formStateManager.getFormValues()!

		const dto: ExamReturnedResolveRequestDTO = {
			comments: returnedFormValues.comments,
			pendingData: PendingExamFormModelConverter.getDto(formValues),
		}
		resolveExamRequest.runRequest(ExamsAnalysisRequests.resolveReturnedExam(props.examCode, dto))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onResolveExamRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				resolveExamRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSave()
	}

	if (!props.visible) return <></>

	if (!loadedExam) return <LoadingOverlayCP show={true} />

	return (
		<DrawerCP
			visible={props.visible}
			title={'Editar exame devolvido'}
			onClose={props.onCancel}
			width={900}
			loading={getExamRequest.isAwaiting}
			footerSpaced={true}
			footer={
				<>
					<ButtonArchiveExamCP examCode={props.examCode} onArchive={props.onSave} />
					<ButtonCP type={'primary'} onClick={save} loading={resolveExamRequest.isAwaiting}>
						Enviar para fila de laudos
					</ButtonCP>
				</>
			}
		>
			{!!loadedExam && (
				<>
					<AlertExamCountImagesCP
						examCode={props.examCode}
						examDescription={loadedExam.exam.description}
						patientName={loadedExam.exam.patientName}
						imageData={loadedExam.exam.imageData}
						margin={{ bottom: 10 }}
						examId={props.examId}
					/>
					<AlertCP
						message={'Este exame foi devolvido. Preencha a resposta da solicitação e altere os dados desejados do exame.'}
						type={'warning'}
						margin={{ bottom: 20 }}
					/>
					<BasicStyleWrapperCP margin={{ bottom: 30 }}>
						<TitleCP underLine={true} textSize={'normal'}>
							Detalhes da Devolução do Exame
						</TitleCP>
						<ExamHistoryComments histories={loadedExam.returnedHistories} />
						<HelpCP text={`${StringUtils.getFirstName(loggedUser?.name ?? '')}, escreva uma resposta para a solicitação`} type={'text'} />
						<TextAreaCP
							placeholder={'Escreva os detalhes da resolução do pedido de devolução'}
							minRows={2}
							formStateManager={returnedFormStateManager}
							fieldName={'comments'}
							required={true}
						/>
					</BasicStyleWrapperCP>

					<FormExamPendingDoctorGroupDataCP formStateManager={formStateManager} clinicCode={loadedExam.exam.clinicCode} />
					{!!formStateManager.getFieldValue('doctorGroup') && (
						<>
							<FormExamPendingPatientDataCP formStateManager={formStateManager} />
							<FormExamPendingExamDataCP formStateManager={formStateManager} loadedExam={loadedExam.exam} />
							<FormExamPendingAnamnesisDataCP
								temporaryToken={loadedExam.exam.temporaryToken}
								formStateManager={formStateManager}
								examCode={props.examCode}
								examId={props.examId}
								clinicCode={loadedExam.exam.clinicCode}
							/>
						</>
					)}
				</>
			)}
		</DrawerCP>
	)
}
