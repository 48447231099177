import { createContext, MutableRefObject } from 'react'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'

export interface ITipTapContext {
	examModality?: ExamModalityEnum
	canSeeWind: boolean
	stream: MutableRefObject<MediaStream | null | undefined>
	websocket: MutableRefObject<WebSocket | null | undefined>
	isCapturing: boolean
	onChangeCapturing: (value: boolean) => void
	savedPhrase: string
	onSavePhrase: (value: string) => void
}

export const TipTapContext = createContext<ITipTapContext | null>(null)
