import { useAppSelector } from 'app/redux/hook'
import { DoctorGroupFormModel } from 'modules/doctor-groups/components/doctor-groups/drawer-doctor-group-data/inner/DoctorGroupFormModel'
import { TabsDoctorGroupDataCP } from 'modules/doctor-groups/components/doctor-groups/tabs-doctor-group-data/TabsDoctorGroupDataCP'
import { useEffect, useState } from 'react'
import { DoctorGroupsRequests } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/DoctorGroupsRequests'
import { DoctorGroupSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/requests/DoctorGroupSaveRequestDTO'
import { DoctorGroupResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/responses/DoctorGroupResponseDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { DrawerCP } from 'submodules/nerit-framework-ui/common/components/drawer/DrawerCP'
import { IBasicModalCPProps } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { AddressFormModel } from 'submodules/nerit-framework-ui/features/address/components/form-address/inner/AddressFormModel'
import { AddressFormUtils } from 'submodules/nerit-framework-ui/features/address/components/form-address/inner/AddressFormUtils'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps extends IBasicModalCPProps {
	doctorGroupCode?: number
}

/**
 */
export function DrawerDoctorGroupDataCP(props: ICPProps): JSX.Element {
	const currentDoctorGroup = useAppSelector((state) => state.doctorGroup)

	const [doctorGroupFormModel, setDoctorGroupFormModel] = useState<DoctorGroupFormModel>()
	const doctorGroupFormStateManager = useFormStateManager<DoctorGroupFormModel>(doctorGroupFormModel)

	const [addressFormValidator, setAddressFormValidator] = useState<AddressFormModel>()
	const addressFormStateManager = useFormStateManager<AddressFormModel>(addressFormValidator)

	const getDoctorGroupRequest = useRequest<DoctorGroupResponseDTO>()
	useEffect(onGetDoctorGroupRequestChange, [getDoctorGroupRequest.isAwaiting])

	const saveGroupRequest = useRequest<void>('none')
	useEffect(onSaveGroupRequestChange, [saveGroupRequest.isAwaiting])

	useEffect(init, [props.visible])

	/**
	 * Inicializa
	 */
	function init(): void {
		setDoctorGroupFormModel(new DoctorGroupFormModel())
		setAddressFormValidator(new AddressFormModel())

		if (!props.visible || !currentDoctorGroup.code) return

		if (!!props.doctorGroupCode) getDoctorGroupRequest.runRequest(DoctorGroupsRequests.getOne(currentDoctorGroup.code))
	}

	/**
	 */
	function onGetDoctorGroupRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getDoctorGroupRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = getDoctorGroupRequest.responseData!

		setDoctorGroupFormModel(
			new DoctorGroupFormModel({
				cnpj: result.cnpj,
				crm: result.crm,
				name: result.name,
				fantasyName: result.fantasyName,
				maxExamsPerDoctor: result.config?.maxExamsPerDoctor,
				hasAdministrativeAnalysis: result.config?.hasAdministrativeAnalysis,
				isAwaitingFirstStatus: result.config?.isAwaitingFirstStatus,
				shouldValidateExamDescription: result.config?.shouldValidateExamDescription,
				blockMultipleMedicalReportsOnExam: result.config?.blockMultipleMedicalReportsOnExam,
				canSeeExamOutsideMedicalReport: result.config?.canSeeExamOutsideMedicalReport,
			}),
		)
		setAddressFormValidator(AddressFormUtils.formatFormModel(result.address))
	}

	/**
	 */
	async function save(): Promise<void> {
		if (
			!(await FormStateManagerUtils.validateRequiredFields(doctorGroupFormStateManager)) ||
			!(await FormStateManagerUtils.validateRequiredFields(addressFormStateManager))
		)
			return

		const formValues = doctorGroupFormStateManager.getFormValues()!
		const dto: DoctorGroupSaveRequestDTO = {
			cnpj: formValues.cnpj,
			crm: formValues.crm,
			name: formValues.name,
			fantasyName: formValues.fantasyName,
			config: {
				hasAdministrativeAnalysis: formValues.hasAdministrativeAnalysis ?? false,
				maxExamsPerDoctor: formValues.maxExamsPerDoctor,
				isAwaitingFirstStatus: formValues.isAwaitingFirstStatus,
				shouldValidateExamDescription: formValues.shouldValidateExamDescription,
				blockMultipleMedicalReportsOnExam: formValues.blockMultipleMedicalReportsOnExam,
				canSeeExamOutsideMedicalReport: formValues.canSeeExamOutsideMedicalReport,
			},
			address: AddressFormUtils.formatDto(addressFormStateManager.getFormValues()),
		}

		if (!!props.doctorGroupCode) saveGroupRequest.runRequest(DoctorGroupsRequests.update(props.doctorGroupCode, dto))
		else saveGroupRequest.runRequest(DoctorGroupsRequests.create(dto))
	}

	/**
	 */
	function onSaveGroupRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				saveGroupRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSave()
	}

	return (
		<DrawerCP
			visible={props.visible}
			loading={saveGroupRequest.isAwaiting}
			onClose={props.onCancel}
			footer={
				<>
					<ButtonCP onClick={save} type={'primary'} loading={saveGroupRequest.isAwaiting}>
						Salvar
					</ButtonCP>
				</>
			}
			title={'Novo Grupo de Médicos'}
			width={600}
		>
			<TabsDoctorGroupDataCP
				formStateManager={doctorGroupFormStateManager}
				addressFormStateManager={addressFormStateManager}
				doctorGroupCode={props.doctorGroupCode}
			/>
		</DrawerCP>
	)
}
