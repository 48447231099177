import styled from 'styled-components'

const theme = localStorage.getItem('theme')
const isDarkMode = theme === 'dark'

export const TipTapToolbar = styled.div`
	background: ${isDarkMode ? '#1E2122' : '#fff'};
	margin-bottom: 10px;
	border-bottom: 1px solid ${isDarkMode ? '#1E2122' : '#fff'};
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	position: sticky;
	top: 0;
	z-index: 1;
	border-radius: 5px 5px 0 0;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

	.sticky {
		border-top: 2px solid transparent;
		box-shadow: 0px 3px 5px -3px rgba(51, 51, 51, 0.5);
	}

	.Toolbar {
		display: flex;
		gap: 2px;
	}

	.Toolbar .icon {
		border-radius: 5px;
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: ${isDarkMode ? '#fff' : '#333333'};
	}

	.Toolbar .icon .disabled {
		color: rgba(51, 51, 51, 0.5);
		pointer-events: none;
	}

	.Toolbar .icon:hover {
		background: #333333;
		color: #ffffff;
		cursor: pointer;
	}

	.Toolbar .icon.is-active {
		background: #333333;
		color: #ffffff;
		cursor: pointer;
	}

	.Toolbar .icon.is-recording {
		background: #333333;
		color: red;
		cursor: pointer;
		animation: pulse 1.5s infinite; /* Definindo a animação de pulsação */
	}

	.Toolbar .divider {
		border: none;
		border-left: 2px solid ${isDarkMode ? 'rgba(127, 123, 123, 0.2)' : 'rgba(51, 51, 51, 0.2)'};
		margin: 2px 10px;
	}

	.Toolbar .icon + .icon {
		margin-left: 4px;
	}
`
