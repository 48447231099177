import { LogoLoginCP } from 'app/components/logo-login/LogoLoginCP'
import { AppUtils } from 'app/utils/AppUtils'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { UploadExamAttachmentsCP } from 'modules/exams/components/exams/upload-exam-attachments/UploadExamAttachmentsCP'
import { ExamUploadFileTypeEnum, ExamUploadFileTypeLabelEnum } from 'modules/exams/screens/screen-exam-upload-files/inner/ExamUploadFileTypeEnum'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { UpdateAppVersionCP } from 'submodules/nerit-framework-ui/common/components/system/update-app-version/UpdateAppVersionCP'
import { ButtonCloseWindow } from 'submodules/nerit-framework-ui/features/common/components/button-close-window/ButtonCloseWindow'
import { ScreenResultWrongParametersCP } from 'submodules/nerit-framework-ui/features/common/components/screen-results/screen-result-wrong-parameters/ScreenResultWrongParametersCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

export const PARAM_SCREEN_UPLOAD_FILES_TYPE = 't'
export const PARAM_SCREEN_UPLOAD_FILES_EXAM_ID = 'i'
export const PARAM_SCREEN_UPLOAD_FILES_AUTHENTICATION = 'a'
export const PARAM_SCREEN_UPLOAD_FILES_EXPIRATION_DATE = 'd'
export const PARAM_SCREEN_UPLOAD_FILES_EXPIRATION_HOUR = 'h'

/**
 * Tela para anexo de documentos em um exame.
 */
export function ScreenExamUploadFiles(): JSX.Element {
	const routeLocation = useLocation()
	const params = new URLSearchParams(routeLocation.search)
	const fileType = params.get(PARAM_SCREEN_UPLOAD_FILES_TYPE)
	const examIdParam = params.get(PARAM_SCREEN_UPLOAD_FILES_EXAM_ID)
	const tokenParam = params.get(PARAM_SCREEN_UPLOAD_FILES_AUTHENTICATION)
	const expirationDate = params.get(PARAM_SCREEN_UPLOAD_FILES_EXPIRATION_DATE)
	const expirationHour = params.get(PARAM_SCREEN_UPLOAD_FILES_EXPIRATION_HOUR)

	const [isValid, setIsValid] = useState<boolean>()

	useEffect(init, [])

	/**
	 */
	function init(): void {
		if (!tokenParam || !fileType || !examIdParam || !expirationDate) {
			setIsValid(false)
			return
		}

		AppUtils.refreshToken(tokenParam)
		AppUtils.refreshLoggedUserData()
			.then(() => {
				setIsValid(true)
			})
			.catch(() => {
				NotificationHelper.error('Ops', 'Erro ao obter o usuário logado')
				setIsValid(false)
			})
	}

	if (isValid === undefined) return <LoadingOverlayCP show={true} />

	if (!isValid) return <ScreenResultWrongParametersCP />

	return (
		<BasicStyleWrapperCP height="100vh">
			<LayoutCP
				header={<HeaderCP title={ExamUploadFileTypeLabelEnum[fileType!]} bgColor={ThemeProject.primary} color={ThemeProject.menuItemColor} />}
				content={
					<FlexCP justify="center">
						<WrapperSCP>
							<ContentCP style={{ padding: 20 }}>
								{expirationDate && expirationHour && (
									<FlexCP justify="center" alignItems="center">
										<AlertCP
											message={`Por segurança essa tela irá expirar no dia ${expirationDate} às ${expirationHour}`}
											type="warning"
										/>
									</FlexCP>
								)}
								<UploadExamAttachmentsCP
									temporaryToken={{ expirationDate: expirationDate!, token: tokenParam! }}
									examId={examIdParam!}
									showQrCode={true}
									type={fileType as ExamUploadFileTypeEnum}
									permissions={{
										canUpload: true,
										canShowFileList: false,
										canRemove: false,
									}}
								/>

								<FlexCP margin={{ top: 50 }} justify="center">
									<ButtonCloseWindow label={'Encerrar Upload'} />
								</FlexCP>
							</ContentCP>
						</WrapperSCP>
					</FlexCP>
				}
				footer={
					<FlexCP justify={'center'} margin={{ top: 20 }}>
						<div style={{ textAlign: 'center' }}>
							<LogoLoginCP height={40} />
							<UpdateAppVersionCP size={'extraSmall'} />
						</div>
					</FlexCP>
				}
				backgroundColor={`${ThemeFrameworkCommon.browserDefaultBackgroundLight}`}
			/>
		</BasicStyleWrapperCP>
	)
}

const WrapperSCP = styled.div`
	width: 50%;
`
