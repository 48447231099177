import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'
import { isValid as isValidCnpj } from '@fnando/cnpj'
import { ValidationTypes } from '_old/main/common/validation/ValidationTypes'

/**
 * VALIDATOR
 * Valida Cpf
 *
 * @author guilherme.diniz
 * @author Lucas Rosa
 */
@ValidatorConstraint({ name: ValidationTypes.IS_VALID_CNPJ })
class IsValidCnpjConstraint implements ValidatorConstraintInterface {
	validate(value: string): boolean {
		return isValidCnpj(value)
	}

	defaultMessage(): string {
		return 'Cnpj Inválido!'
	}
}

/**
 * DECORATOR
 * @param {ValidationOptions} validationOptions
 * @return {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function IsValidCnpj(validationOptions?: ValidationOptions) {
	return (object: {}, propertyName: string) => {
		registerDecorator({
			target: object.constructor,
			propertyName,
			options: validationOptions,
			constraints: [],
			validator: IsValidCnpjConstraint,
		})
	}
}
