import { useAppSelector } from 'app/redux/hook'
import { RadioGroupMedicalTimetableConfigTP } from 'modules/medical-timetable/components/radio-group-medical-timetable-config-type/RadioGroupMedicalTimetableConfigTypeCP'
import { useEffect } from 'react'
import { MedicalTimetableStructureSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/MedicalTimetableStructureSaveRequestDTO'
import { IMedicalTimeTableStructure } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/interfaces/IMedicalTimeTableStructure'
import { MedicalTimetableRequests } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/MedicalTimetableRequests'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps {
	selectedConfigType: RadioGroupMedicalTimetableConfigTP
	isEditing: boolean
	date?: Date
	medicalTimetableStructureEdit: IMedicalTimeTableStructure[]
	onSaved: () => void
}

/**
 */
export function ButtonSaveMedicalTimetableStructure(props: ICPProps): JSX.Element {
	const saveMedicalTimetableStructureRequest = useRequest<void>('none')
	const currentDoctorGroup = useAppSelector((state) => state.doctorGroup)

	useEffect(onSaveMedicalTimetableStructureRequestChange, [saveMedicalTimetableStructureRequest.isAwaiting])

	/**
	 */
	function save(): void {
		if (!currentDoctorGroup.code) {
			return
		}

		if (props.selectedConfigType === 'week' && !props.date) {
			NotificationHelper.error('Ops', 'Para salvar uma semana em específico passe uma data')
			return
		}

		const dto: MedicalTimetableStructureSaveRequestDTO = {
			doctorGroupCode: currentDoctorGroup.code,
			date: props.selectedConfigType === 'week' ? props.date : undefined,
			structures: props.medicalTimetableStructureEdit,
		}
		saveMedicalTimetableStructureRequest.runRequest(MedicalTimetableRequests.saveStructure(dto))
	}

	/**
	 */
	function onSaveMedicalTimetableStructureRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				saveMedicalTimetableStructureRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSaved()
	}

	return (
		<ButtonCP
			icon={'check'}
			type={'primary'}
			onClick={save}
			disabled={!props.isEditing}
			tooltip={!props.isEditing ? 'Nenhuma alteração feita ainda' : undefined}
			loading={saveMedicalTimetableStructureRequest.isAwaiting}
		>
			Salvar
		</ButtonCP>
	)
}
