import { MedicalReportLocalStorageUtil } from 'modules/exams/components/medical-report/status-save-report-local-storage/inner/MedicalReportLocalStorageUtil'
import * as S from 'modules/exams/components/medical-report/status-save-report-local-storage/style'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'

interface ICPProps {
	examCode: number
	onClickToRestore: () => void
}

/**
 */
export function StatusSaveReportLocalStorageCP(props: ICPProps): JSX.Element {
	// Verifica se tem laudo no localStorage
	const medicalReportOnLocalStorage = MedicalReportLocalStorageUtil.getMedicalReportLocalStorage(props.examCode)
	if (!medicalReportOnLocalStorage || !medicalReportOnLocalStorage.medicalReportContentsHistory) return <></>

	return (
		<S.AlertWrapperSCP>
			<AlertCP
				message={
					<FlexCP justify={'space-between'} alignItems={'center'}>
						<TextCP
							icon={'warning-circle'}
							text={
								'Você saiu e entrou novamente neste laudo sem salvar o laudo, deixamos guardado para não perder seu trabalho. Caso deseje recuperar o conteúdo clique em "Recuperar"'
							}
							fontStyle={'italic'}
						/>
						<ButtonCP onClick={props.onClickToRestore} size={'small'} type={'primary'} marginLeft={10}>
							Recuperar
						</ButtonCP>
					</FlexCP>
				}
				type={'warning'}
			/>
		</S.AlertWrapperSCP>
	)
}
