import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
`

export const Info = styled.div`
	line-height: 30px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`
export const ImgWrapper = styled.div`
	margin: 0px 30px 30px 0px;
`
