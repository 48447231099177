import { LoadingVxCP } from 'app/components/loading-vx/LoadingVxCP'
import { useAppSelector } from 'app/redux/hook'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import { ScreenPrivateUser } from 'app/screens/screen-private-user/ScreenPrivateUser'
import { AppUtils } from 'app/utils/AppUtils'
import { NeritFrameworkProjectConfig } from 'config/NeritFrameworkProjectConfig'
import { useEffect, useState } from 'react'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { RedirectCP } from 'submodules/nerit-framework-ui/common/router/components/redirect/RedirectCP'

/**
 * Componente verificador se esta logado, pois se caiu aqui esta chamando rota privada.
 */
export function PrivateAccessVerifierCP(): JSX.Element {
	const loggedUserData = useAppSelector((state) => state.auth)

	const [isLoading, setIsLoading] = useState<boolean>(true)

	useEffect(updateLoggedUserData, [])

	/**
	 */
	function updateLoggedUserData(): void {
		if (!loggedUserData?.user) {
			return
		}

		setIsLoading(true)
		AppUtils.refreshToken(loggedUserData.token)

		AppUtils.refreshLoggedUserData()
			.catch(() => NeritFrameworkProjectConfig.onUnauthorized(loggedUserData))
			.finally(() => setIsLoading(false))
	}

	if (!loggedUserData?.token) {
		return <RedirectCP path={PublicRouter.USER_LOGIN} />
	}

	if (isLoading) {
		return <LoadingOverlayCP show={true} customLogo={<LoadingVxCP />} />
	}

	// Se estiver logado, inclui a tela do sistema e nela tera o roteador das rotas
	return <ScreenPrivateUser />
}
