import { PayloadAction } from '@reduxjs/toolkit'
import { IDoctorGroupState } from 'app/redux/slices/doctor-group/DoctorGroupSlice'
import { DoctorGroupNamesResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/responses/DoctorGroupNamesResponseDTO'

export function setCurrentDoctorGroupReducer(state: IDoctorGroupState, action: PayloadAction<DoctorGroupNamesResponseDTO | null>): IDoctorGroupState {
	if (!action.payload) {
		return {
			code: null,
			name: null,
			loggedUserAccess: null,
			config: null,
		}
	}

	return {
		...state,
		...action.payload,
	}
}
