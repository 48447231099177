import * as _ from 'lodash-es'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { LayoutSubmenuContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/content/LayoutSubmenuContentCP'
import {
	ScreenSubMenuItemTP,
	ScreenSubMenuTP,
} from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/submenu/inner/ScreenSubMenuItemTP'
import { ScreenSubmenuMobileICP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/submenu/inner/ScreenSubmenuMobileICP'
import { ScreenSubmenuCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/submenu/ScreenSubmenuCP'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import { OrUndefinedTP } from 'submodules/nerit-framework-utils/utils/types/OrUndefinedTP'

interface IScreenContentSubmenuCPProps<ViewTP> {
	defaultSubmenu: ViewTP
	menuTitle: string
	menuSubTitle?: string
	submenuConfigs: Array<ScreenSubMenuTP<ViewTP>>
	defaultOpenKeys?: string[]
	marginVertical?: number
	loading?: boolean
	loggedDomain?: string
	titleBrowser?: string
}

/**
 * Componente LayoutCP para telas que possuem submenu.
 */
export function LayoutSubmenuCP<ViewTP>(props: IScreenContentSubmenuCPProps<ViewTP>): JSX.Element {
	const [isPathResolved, setIsPathResolved] = useState<boolean>(false)
	const [currentMenuOption, setCurrentMenuOption] = useState<ViewTP>(props.defaultSubmenu)

	const screenSize = useScreenSize()
	const routeLocation = useLocation()

	useEffect(onPathChange, [routeLocation.pathname])

	/**
	 * Quando muda o path
	 */
	function onPathChange(): void {
		setIsPathResolved(false)

		let pathWithoutDomain = routeLocation.pathname

		if (!!props.loggedDomain) {
			pathWithoutDomain = StringUtils.stripInitialChars(routeLocation.pathname, `/${props.loggedDomain}`)
			pathWithoutDomain = pathWithoutDomain.replace('/', '')
		}

		const viewForRoute = getSubMenuItem(pathWithoutDomain, 'route')?.view
		if (!viewForRoute) return onSelectionChange(props.defaultSubmenu)

		setCurrentMenuOption(viewForRoute)
		setIsPathResolved(true)
	}

	/**
	 * Quando selecionado um item do submenu.
	 */
	function onSelectionChange(view: ViewTP): void {
		NeritFrameworkRoutingHelper.historyPush(getSubMenuItem(view, 'viewTp')!.route)
	}

	/**
	 * Busca o item de acordo com o viewTP ou a rota.
	 */
	function getSubMenuItem(lookup: string | ViewTP, lookType: 'route' | 'viewTp'): OrUndefinedTP<ScreenSubMenuItemTP<ViewTP>> {
		for (const sub of props.submenuConfigs) {
			// Procura pela rota ou viewTP infomado
			// Se for rota, devemos desconsiderar o queryParams, por isso fazemos split e pegamos o que esta ntes
			let foundItem
			if (lookType === 'route') foundItem = sub.items.find((item) => item.route.split('?')[0] === lookup)
			else foundItem = _.find(sub.items, { view: lookup }) as ScreenSubMenuItemTP<ViewTP>

			if (!!foundItem) return foundItem
		}

		return undefined
	}

	if (!isPathResolved) return <LoadingOverlayCP show={true} />

	const _selectedMenuItem = getSubMenuItem(currentMenuOption, 'viewTp')
	if (!_selectedMenuItem) return <ContentCP>Conteúdo não encontrado</ContentCP>

	return (
		<LayoutCP
			titleBrowser={props.titleBrowser}
			sider={
				<>
					{!screenSize.smd && (
						<ScreenSubmenuCP
							onSelectMenuOption={onSelectionChange}
							currentMenuOption={currentMenuOption}
							title={props.menuTitle}
							subTitle={props.menuSubTitle}
							submenuConfigs={props.submenuConfigs}
							defaultOpenKeys={props.defaultOpenKeys}
							marginVertical={props.marginVertical}
							loading={props.loading}
						/>
					)}
				</>
			}
			content={
				<ContentCP>
					{_selectedMenuItem.contentTitle ? (
						<>
							<HeaderCP title={_selectedMenuItem.contentTitle} bgColor={ThemeFrameworkCommon.browserDefaultBackgroundLight}>
								{_selectedMenuItem.contentTitleChildren}
							</HeaderCP>

							{screenSize.smd && (
								<ScreenSubmenuMobileICP
									onSelectMenuOption={onSelectionChange}
									currentMenuOption={currentMenuOption}
									submenuConfigs={props.submenuConfigs}
								/>
							)}

							<LayoutSubmenuContentCP>{_selectedMenuItem.content}</LayoutSubmenuContentCP>
						</>
					) : (
						_selectedMenuItem.content
					)}
				</ContentCP>
			}
		/>
	)
}
