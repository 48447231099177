export enum KeyMaps {
	CMD_OR_CTRL = 'CMD_OR_CTRL',
	ALT_OR_OPTIONS = 'ALT_OR_OPTIONS',
	CTRL_KEY = 'CTRL_KEY',
}

export class EditorUiUtils {
	private static commonKeyMaps = { [KeyMaps.CTRL_KEY]: 'Ctrl' }

	private static keyMaps = {
		mac: {
			...EditorUiUtils.commonKeyMaps,
			[KeyMaps.CMD_OR_CTRL]: 'Cmd',
			[KeyMaps.ALT_OR_OPTIONS]: 'Option',
		},
		others: {
			...EditorUiUtils.commonKeyMaps,
			[KeyMaps.CMD_OR_CTRL]: 'Ctrl',
			[KeyMaps.ALT_OR_OPTIONS]: 'Alt',
		},
	}

	/** Retorna as keymaps do editor de acordo com o SO do usuário */
	public static matchKeyMapToOS(str: string): string {
		const os = navigator.userAgent.includes('Mac') ? 'mac' : 'others'
		let strFormatted = str

		Object.entries(EditorUiUtils.keyMaps[os]).forEach(([key, value]) => {
			strFormatted = strFormatted.replace(key, value)
		})

		return strFormatted
	}
}
