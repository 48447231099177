import * as AvatarPrimitive from '@radix-ui/react-avatar'
import * as React from 'react'
import { tv, VariantProps } from 'tailwind-variants'

const avatarVariants = tv({
	base: 'relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full',
})

const Avatar = React.forwardRef<
	React.ElementRef<typeof AvatarPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root> & VariantProps<typeof avatarVariants>
>(({ className, ...props }, ref) => <AvatarPrimitive.Root ref={ref} className={avatarVariants({ className })} {...props} />)
Avatar.displayName = AvatarPrimitive.Root.displayName

const avatarImageVariants = tv({
	base: 'aspect-square h-full w-full avatar object-cover',
})

const AvatarImage = React.forwardRef<
	React.ElementRef<typeof AvatarPrimitive.Image>,
	React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image> & VariantProps<typeof avatarVariants>
>(({ className, ...props }, ref) => <AvatarPrimitive.Image ref={ref} className={avatarImageVariants({ className })} {...props} />)
AvatarImage.displayName = AvatarPrimitive.Image.displayName

const avatatFallbackVariants = tv({
	base: 'flex h-full w-full items-center justify-center rounded-full bg-muted font-medium text-xs',
})

const AvatarFallback = React.forwardRef<
	React.ElementRef<typeof AvatarPrimitive.Fallback>,
	React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback> & VariantProps<typeof avatatFallbackVariants>
>(({ className, ...props }, ref) => <AvatarPrimitive.Fallback ref={ref} className={avatatFallbackVariants({ className })} {...props} />)
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName

export { Avatar, AvatarFallback, AvatarImage }
