import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { WelcomeContentSelectDoctorGroupCP } from 'app/components/welcome-content-select-doctor-group/WelcomeContentSelectDoctorGroupCP'
import { useAppSelector } from 'app/redux/hook'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import React from 'react'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'

interface ICPProps {
	children: React.ReactNode
	headerTitle: string
	appearance?: {
		hideMargin?: boolean
	}
}

/**
 */
export function ScreenWrapperQualityAssurance(props: ICPProps): JSX.Element {
	const currentDoctorGroup = useAppSelector((state) => state.doctorGroup)

	if (!currentDoctorGroup.code) return <WelcomeContentSelectDoctorGroupCP />

	return (
		<AccessControlCP
			permission={{ byHasAnyDoctorGroupRelationPermission: ['isDoctorGroupAdmin', 'isAuditor'] }}
			redirect={{ shouldRedirect: true, redirectTo: PrivateUserRouter.HOME }}
		>
			<LayoutCP
				titleBrowser={'Auditorias'}
				header={<HeaderCP title={props.headerTitle} />}
				content={
					<ContentCP overflowVertical={true}>
						<BasicStyleWrapperCP margin={props.appearance?.hideMargin ? undefined : { top: 30, left: 10, right: 10, bottom: 10 }}>
							{props.children}
						</BasicStyleWrapperCP>
					</ContentCP>
				}
			/>
		</AccessControlCP>
	)
}
