import { Menu } from 'antd'
import { BarActiveMenuItemCP } from 'submodules/nerit-framework-ui/common/components/menu/bar-active-menu-item/BarActiveMenuItemCP'
import { ObjectUtils } from 'submodules/nerit-framework-utils/utils/ObjectUtils'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import * as S from './SubMenuStyles'

export interface ITitleEventEntity {
	key: string
	domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
}

interface ICPProps extends S.IScpProps {
	children: React.ReactNode
	title?: string | JSX.Element
	onTitleClick?: (e: ITitleEventEntity) => void
	openKeys?: string[]
	isActive?: boolean
}

/**
 * COMPONENTE: Sub Menu.
 */
export function SubMenuCP(props: ICPProps): JSX.Element {
	function onTitleClick(e: ITitleEventEntity): void {
		if (!!props.onTitleClick) props.onTitleClick(e)
	}

	function getAntProps(): ObjectPropsTP<ICPProps> {
		const customProps: Array<keyof ICPProps> = ['children']
		return ObjectUtils.getObjectsWithoutSomeProps(props, customProps)
	}

	return (
		<S.WrapperSCP hideArrow={props.hideArrow} padding={props.padding}>
			<Menu.SubMenu {...getAntProps()} onTitleClick={onTitleClick} title={props.title}>
				{props.children}
			</Menu.SubMenu>

			<BarActiveMenuItemCP isActive={props.isActive} isSecondaryMenu={false} />
		</S.WrapperSCP>
	)
}
