import { Row, RowProps } from 'antd'

interface IRowCPProps {
	children: React.ReactNode
	justify?: RowProps['justify']
	align?: RowProps['align']
	spaceBetweenCols?: RowProps['gutter']
	wrap?: RowProps['wrap']
}

/**
 * Componente de LINHA para alinhamento de GRID
 */
export const RowCP = (props: IRowCPProps): JSX.Element => {
	return (
		<Row gutter={props.spaceBetweenCols ?? 16} justify={props.justify} align={props.align} wrap={props.wrap}>
			{props.children}
		</Row>
	)
}
