import styled from 'styled-components'

export const WrapperTimelineSCP = styled.div`
	padding: 24px;
	overflow: auto;
	max-height: 400px;
`

export const TextHelperWrapper = styled.p`
	font-weight: 500;
	font-size: 12px;
	margin-bottom: 0;
`
