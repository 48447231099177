import { DoctorProductivityCP, IDoctorProductivityCPProps } from 'app/components/vx-wind/vx-wind-portal/inner/doctor-productivity/DoctorProductivityCP'
import { ISpeakWithWindCPProps, SpeakWithWindCP } from 'app/components/vx-wind/vx-wind-portal/inner/speak-with-wind/SpeakWithWindCP'
import { VxWindModalContentKeysTP } from 'app/components/vx-wind/vx-wind-system-assistant/constants'

export interface IVxWindPortalCPProps {
	type: VxWindModalContentKeysTP
	props: IDoctorProductivityCPProps | ISpeakWithWindCPProps
}

export function VxWindPortalCP({ type, props }: IVxWindPortalCPProps): JSX.Element {
	if (type === 'doctor-productivity') {
		return <DoctorProductivityCP {...(props as IDoctorProductivityCPProps)} />
	}

	if (type === 'speak-with-wind') {
		return <SpeakWithWindCP {...(props as ISpeakWithWindCPProps)} />
	}

	return <></>
}
