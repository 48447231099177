import styled, { css, keyframes } from 'styled-components'

export const TextWrapperSCP = styled.div`
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	text-align: center;
`

export const fadeInAnimation = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`

export const fadeOutAnimation = keyframes`
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
`

export const WrapperSuccessRating = styled.div<{ fadeIn?: boolean }>`
	${({ fadeIn }) =>
		fadeIn
			? css`
					animation: ${fadeInAnimation} 0.5s forwards;
			  `
			: ''}
`

export const WrapperRating = styled.div<{ fadeOut?: boolean }>`
	${({ fadeOut }) =>
		fadeOut
			? css`
					animation: ${fadeOutAnimation} 0.5s forwards;
			  `
			: ''}
`
