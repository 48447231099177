import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

export const WrapperSCP = styled.div`
	display: none;

	@media print {
		display: block !important;
	}

	padding: 40px;

	.ant-typography {
		color: ${ThemeFrameworkCommon.black};
		margin: 0;
	}
`

export const BorderWrapperSCP = styled.div`
	border: dashed #dedede 1px;
	border-radius: 5px;
	padding: 10px;
`
