import { useAppSelector } from 'app/redux/hook'
import { AppUtils } from 'app/utils/AppUtils'
import { AppConfig } from 'config/AppConfig'
import Intercom from 'react-intercom'
import { ProjectWhiteLabelsEnum } from 'submodules/beerads-sdk/common/ProjectWhiteLabelsEnum'
import { FrontEnvironmentEnum } from 'submodules/nerit-framework-utils/utils/enums/EnvironmentEnum'

interface ICPProps {
	forceShow?: boolean
}

/**
 */
export function IntercomCP(props: ICPProps): JSX.Element {
	const loggedUser = useAppSelector((state) => state.auth.user)
	const sistema = AppUtils.getDomain() === ProjectWhiteLabelsEnum.VX ? 'VX PACS' : 'VX PACS - BeeRads'

	if (AppConfig.getInstance().environment === FrontEnvironmentEnum.DEVELOPMENT && !props.forceShow) return <></>

	return (
		<Intercom
			appID={'qxkjlonm'}
			user_id={loggedUser?.code}
			name={loggedUser?.name}
			email={loggedUser?.email}
			phone={`+55${loggedUser?.phone}`}
			job_title={loggedUser?.isDoctor ? 'Médico' : 'Usuário'}
			produto_vx={sistema}
			company={
				!!loggedUser
					? {
							id: loggedUser.isDoctor ? loggedUser.doctorGroups?.[0]?.code : loggedUser.clinics?.[0]?.code,
							name: loggedUser.isDoctor ? loggedUser.doctorGroups?.[0]?.name : loggedUser.clinics?.[0]?.name,
					  }
					: undefined
			}
		/>
	)
}
