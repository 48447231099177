import { ThemeProject } from 'config/theme/project/ThemeProject'
import { ButtonCopyClipboardCP } from 'modules/quality-assurance/components/button-copy-clipboard/ButtonCopyClipboardCP'
import { ButtonShareMedicalReportVideoWhatsappCP } from 'modules/quality-assurance/components/button-share-medical-report-video-whatsapp/ButtonShareMedicalReportVideoWhatsappCP'
import { useEffect, useState } from 'react'
import { MedicalReportVideoFileResponseDTO } from 'submodules/beerads-sdk/services/exams/attachments/dtos/response/MedicalReportVideoFileResponseDTO'
import { UploadMedicalReportVideoResponseDTO } from 'submodules/beerads-sdk/services/exams/attachments/dtos/response/UploadMedicalReportVideoResponseDTO'
import { ExamAttachmentsRequests } from 'submodules/beerads-sdk/services/exams/attachments/ExamAttachmentsRequests'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ButtonDeleteRecordCP } from 'submodules/nerit-framework-ui/common/components/table/columns/ButtonDeleteRecordCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { VideoCP } from 'submodules/nerit-framework-ui/common/components/video/VideoCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'

interface ICPProps {
	medicalReportVideo?: MedicalReportVideoFileResponseDTO
	mediaBlobUrl?: string
	visible: boolean
	onClose: () => void
	examId: string
}

export function ModalRecordedVideoCP(props: ICPProps): JSX.Element {
	const [uploadedVideoData, setUploadedVideoData] = useState<UploadMedicalReportVideoResponseDTO>()
	const uploadRequest = useRequest<UploadMedicalReportVideoResponseDTO>()

	const formattedCreationDate = uploadedVideoData?.expirationDate
		? DateUtils.formatDate(uploadedVideoData?.expirationDate, DateFormatEnum.BR_WITH_TIME_H_M)
		: undefined

	useEffect(onUploadRequestChange, [uploadRequest.isAwaiting])

	useEffect(init, [props.visible, props.mediaBlobUrl, props.medicalReportVideo])

	function init(): void {
		if (!props.visible) return
		//Apenas quando o modal for aberto através da listagem de exames, entrará neste if
		if (!!props.medicalReportVideo) {
			setUploadedVideoData({
				attachmentCode: props.medicalReportVideo.fileCode,
				expirationDate: props.medicalReportVideo.expirationDate,
				shortenedUrl: props.medicalReportVideo.fileShortenedUrl,
				url: props.medicalReportVideo.fileUrl,
			})
			return
		}

		//Apenas quando o modal for aberto através da tela de laudo executará o código subsequente
		try {
			if (!!props.mediaBlobUrl) {
				fetch(props.mediaBlobUrl).then(async (response) => {
					const blobData = await response.blob()
					const blobWithMimetype = new Blob([blobData])
					const formData = new FormData()
					formData.append('file', blobWithMimetype)

					const uploadPromise = ExamAttachmentsRequests.uploadMedicalReportVideo(props.examId, formData)
					uploadRequest.runRequest(uploadPromise)
				})
			}
		} catch (error) {
			return NotificationHelper.error('Ops', 'Erro ao enviar o arquivo')
		}
	}

	function onUploadRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(uploadRequest, 'Erro ao fazer upload', 'Upload realizado com sucesso')) return

		setUploadedVideoData(uploadRequest.responseData)
	}

	return (
		<ModalCP
			visible={props.visible}
			onClose={props.onClose}
			title={'Vídeo Laudo'}
			width={600}
			loading={uploadRequest.isAwaiting}
			footer={
				uploadedVideoData && (
					<FlexCP justify={'space-between'}>
						<ButtonDeleteRecordCP
							onDelete={props.onClose}
							requestConfigTP={() => ExamAttachmentsRequests.deleteMedicalReportVideo(uploadedVideoData.attachmentCode)}
						/>
						<ButtonCP onClick={props.onClose}>Fechar</ButtonCP>
					</FlexCP>
				)
			}
		>
			{!!uploadedVideoData && (
				<>
					<VideoCP url={uploadedVideoData.url} />

					<FlexCP justify={'flex-start'} gap={'8px'}>
						<ButtonCopyClipboardCP medicalReportVideo={uploadedVideoData} />
						<ButtonShareMedicalReportVideoWhatsappCP medicalReportVideo={uploadedVideoData} />
					</FlexCP>

					<TextCP
						text={`Este vídeo expirará em: ${formattedCreationDate}`}
						fontStyle={'italic'}
						color={ThemeProject.gray300}
						style={{ marginTop: 5 }}
					/>
				</>
			)}
		</ModalCP>
	)
}
