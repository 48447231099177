import { IAuthState } from 'app/redux/slices/auth/AuthSlice'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'

/**
 */
export class CommonPermissionUtils {
	/**
	 * Se usuario logado tem perfil de BeeRads Admin.
	 */
	static isBeeRadsAdmin(loggedUser: IAuthState['user']): boolean {
		return !!loggedUser?.isBeeradsAdmin
	}

	/**
	 * Retorna se usuario eh do tipo desejado
	 */
	static canAccessByType(type: UserTypeEnum, loggedUser: IAuthState['user']): boolean {
		const isDoctor = !!loggedUser?.isDoctor

		return type === UserTypeEnum.USER ? !isDoctor : isDoctor
	}
}
