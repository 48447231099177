import React from 'react'
import { Controller, FieldPath, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form'

interface ICPProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> {
	children: React.ReactElement
	name: TName
	options?: RegisterOptions<TFieldValues, TName>
	onBlur?: (e: React.FocusEvent) => void
	onChange?: (e: React.ChangeEvent) => void
}
/**
 * Input Wrapper para input controlado com o React Hook Form
 * @param param0
 * @returns
 */
export function InputControlledCP<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
	children,
	name,
	onBlur,
	onChange,
	...controllerProps
}: ICPProps<TFieldValues, TName>): JSX.Element {
	const {
		formState: { errors },
	} = useFormContext<TFieldValues>()

	return (
		<>
			<Controller
				name={name}
				{...controllerProps}
				render={({ field }) =>
					React.cloneElement(children, {
						...field,
						onBlur: (e: React.FocusEvent) => {
							field.onBlur()
							onBlur?.(e)
						},
						onChange: (e: React.ChangeEvent) => {
							field.onChange(e)
							onChange?.(e)
						},
					})
				}
			/>
			{errors[name] && <span className="text-rose-500	font-medium text-xs">{String(errors[name]?.message)}</span>}
		</>
	)
}
