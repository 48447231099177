import { ThemeProject } from 'config/theme/project/ThemeProject'
import styled, { css } from 'styled-components'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

export const CLASS_RISE_LABEL = 'rise-label'
export const CLASS_ERROR = 'has-error'
export const CLASS_ANIMATE = 'animate-label'

type ScpPropsTP = {
	fontSize: FontSizeTP
	marginRight?: number
	marginLeft?: number
	marginTop?: number
	marginBottom?: number
}

const FormElementsCSS = css<ScpPropsTP>`
	border-radius: 0 !important;
	border: 0 none !important;
	border-bottom: 1px solid ${ThemeProject.gray300} !important;
`

export const WrapperSCP = styled.div<ScpPropsTP & { hideBorder?: boolean }>`
	.ant-input-suffix {
		color: ${ThemeFrameworkCommon.browserDefaultColorDark}55 !important;
	}

	/*ESTILOS GERAIS*/
	width: 100%;

	.ant-col:focus {
		outline: 0;
	}

	.ant-row,
	.${CLASS_ANIMATE}, .${CLASS_ERROR} {
		margin: ${(props) => `${props.marginTop ?? 0}px ${props.marginRight ?? 0}px ${props.marginBottom ?? 0}px ${props.marginLeft ?? 0}px`};
		line-height: 0;

		&:focus,
		.ant-form-item-control:focus,
		.ant-form-item-children:focus {
			outline: 0;
		}

		.ant-form-item-label {
			margin-bottom: 5px;
			line-height: 35px;

			label {
				color: ${ThemeProject.gray300};

				&.ant-form-item-required {
					&:before {
						content: '';
					}

					&:after {
						display: inline-block;
						margin-right: 4px;
						font-size: 10px;
						font-family: SimSun, sans-serif;
						line-height: 1;
						content: '*';
					}
				}
			}
		}

		.ant-form-explain {
			color: ${ThemeProject.gray300};
			transition: opacity 0.3s;
			font-size: 12px;
			font-style: italic;
			margin: 8px 0;

			.input-hint {
				line-height: 1;
			}

			i {
				margin-left: 5px;
			}

			a {
				text-decoration: underline;
			}
		}
	}

	.${CLASS_ANIMATE} {
		position: relative;

		.ant-col {
			position: initial;
		}

		.ant-input-affix-wrapper {
			outline: 0;
			background-color: transparent;
			border: 0 none;

			&:focus,
			&:hover {
				border-color: transparent;
			}
		}

		label {
			position: absolute;
			transition: transform 0.5s ease, color 1s ease, font-size 1s ease;
		}

		&.${CLASS_RISE_LABEL} {
			label {
				transform: translate(2px, -24px) scale(1);
			}

			.ant-input-affix-wrapper {
				color: ${ThemeFrameworkCommon.browserDefaultColorDark}65;
			}
		}

		&:not(.${CLASS_RISE_LABEL}) {
			label {
				font-size: ${(props) => ThemeFrameworkCommon.fontSizes[props.fontSize || 'small']};
				transform: translate(0, 0) scale(1);
			}
		}
	}

	.${CLASS_ERROR} {
		.ant-input-affix-wrapper {
			.ant-input:focus {
				box-shadow: none;
			}
		}

		&:not(.${CLASS_RISE_LABEL}) {
			.ant-select-arrow {
				color: transparent;
			}
		}
	}

	/*SELECT*/

	.ant-select {
		width: 100%;

		.ant-select-selector {
			${(props) => (props.hideBorder ? undefined : FormElementsCSS)}
			background-color: transparent !important;
			border: none;
			border-radius: 0;
			border-bottom: 1px solid ${ThemeProject.gray300} !important;

			.ant-select-enable {
				height: 0;
			}

			&.ant-select-selector--multiple {
				padding-bottom: 0px !important;

				.ant-select-selector__rendered {
					margin: 0;
				}
			}

			.ant-select-selector__rendered:focus,
			&:focus,
			&:active {
				box-shadow: none;
				outline: 0;
			}

			.ant-select-selector__rendered {
				ul {
					&:focus {
						outline: 0;
					}

					li:not(.ant-select-search) {
						height: 20px;
						margin: 2px;
						padding: 0 18px 0 6px;
						font-size: 12px;
						display: flex;
						line-height: 18px;
					}
				}
			}

			.ant-select-selection-item {
				height: fit-content;
			}
		}
		svg {
			color: ${ThemeFrameworkCommon.browserDefaultBackgroundDark}90;
		}
	}

	/*INPUT*/
	.ant-input {
		background-color: transparent !important;
		border: none;
		border-bottom: 1px solid ${ThemeProject.gray300};
		border-radius: 0;
	}

	.ant-input-affix-wrapper {
		${(props) => !props.hideBorder && FormElementsCSS}

		.ant-input {
			cursor: text !important;
			color: ${ThemeProject.colorText} !important;
			font-size: ${(props) => ThemeFrameworkCommon.fontSizes[props.fontSize]} !important;
		}
	}

	.has-error {
		background-color: transparent !important;
	}

	.ant-input:focus,
	.has-error .ant-input:focus {
		box-shadow: none;
	}

	/*ANT PICKER*/

	.ant-picker {
		background-color: transparent !important;
		border: none;
		border-bottom: 1px solid ${ThemeProject.gray300};
		border-radius: 0;

		.ant-picker-input > input {
			font-size: none;
		}
	}
`
