import { TableTemplateMedicalReportHistoryCP } from 'modules/template/components/table-templates-medical-report-history/TableTemplateMedicalReportHistoryCP'
import {
	TemplateHistoryStatusEnum,
	TemplateHistoryStatusLabelEnum,
} from 'submodules/beerads-sdk/services/templates/history-templates/enums/TemplateHistoryStatusEnum'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { CollapseCP } from 'submodules/nerit-framework-ui/common/components/collapse/CollapseCP'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'

interface ICPProps {
	templateHistories?: TemplateResponseDTO[]
	currentTemplateList?: TemplateResponseDTO[]
	onChangeData: () => void
}

export function CollapseTemplateMedicalReportHistoryCP(props: ICPProps): JSX.Element {
	return (
		<CardCP>
			<CollapseCP
				panels={props.templateHistories?.map((templateHistory) => ({
					key: templateHistory.code.toString(),
					title: templateHistory.name,
					extra: <TagCP content={TemplateHistoryStatusLabelEnum[TemplateHistoryStatusEnum.AWAITING_APPROVAL]} />,
					content: <TableTemplateMedicalReportHistoryCP templateHistories={templateHistory} currentTemplateList={props.currentTemplateList} onChangeData={props.onChangeData} />,
				}))}
			/>
		</CardCP>
	)
}
