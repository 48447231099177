import { JSONContent } from '@tiptap/react'
import { EditorMedicalReportCP } from 'modules/exams/components/medical-report/editor-medical-report/EditorMedicalReportCP'
import { ModalRemoveMedicalReportCP } from 'modules/exams/components/medical-report/modal-remove-medical-report/ModalRemoveMedicalReportCP'
import { StatusSaveReportLocalStorageCP } from 'modules/exams/components/medical-report/status-save-report-local-storage/StatusSaveReportLocalStorageCP'
import { MedicalReportLocalStorageUtil } from 'modules/exams/components/medical-report/status-save-report-local-storage/inner/MedicalReportLocalStorageUtil'
import { MedicalReportFormModel } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModel'
import { useEffect, useState } from 'react'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { MedicalReportRequests } from 'submodules/beerads-sdk/services/exams/medical-reports/MedicalReportRequests'
import { MedicalReportContentTP } from 'submodules/beerads-sdk/services/exams/medical-reports/types/MedicalReportContentTP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { TabsCP } from 'submodules/nerit-framework-ui/common/components/tabs/TabsCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import * as S from './style'

interface ICPProps {
	exam: ExamResponseDTO
	formStateManager: IFormStateManager<MedicalReportFormModel>
}

/**
 */
export function TabsMedicalReportEditorCP(props: ICPProps): JSX.Element {
	const [reloadEditor, setReloadEditor] = useState<number>()
	const [currentTab, setCurrentTab] = useState<string>('0')

	const addMedicalReportRequest = useRequest<number>('api-return')
	useEffect(onAddMedicalReportRequestChange, [addMedicalReportRequest.isAwaiting])

	const [isRemoveMedicalReportModalVisible, setIsRemoveMedicalReportModalVisible] = useState<boolean>(false)
	const [medicalReportTabKeyCodeToRemove, setMedicalReportTabKeyCodeToRemove] = useState<number>()
	const [medicalReportCodeToRemove, setMedicalReportCodeToRemove] = useState<number>()

	useEffect(() => onForceContent('template'), [props.formStateManager.getFieldValue('selectedHtmlContentToReplace')])

	/**
	 * Ao selecionar um conteudo do template ou resgatando da sessao.
	 */
	function onForceContent(mode: 'template' | 'localStorage'): void {
		let contentHtml
		if (mode === 'template') {
			contentHtml = props.formStateManager.getFieldValue('selectedHtmlContentToReplace')
		} else if (mode === 'localStorage') {
			const medicalReportFromLocalStorage = MedicalReportLocalStorageUtil.getMedicalReportLocalStorage(props.exam.code)
			contentHtml = medicalReportFromLocalStorage?.medicalReportContentsHistory?.[Number(currentTab)].html
		}

		if (!contentHtml) return

		const medicalReports: MedicalReportContentTP[] = props.formStateManager.getFieldValue('medicalReports')

		// Altera o conteudo do laudo atual
		medicalReports[Number(currentTab)].html = contentHtml
		props.formStateManager.changeFieldValue('medicalReports', [...medicalReports])

		// Forca recarregar o editor
		setReloadEditor(TableUtils.getReloadNumber())

		// Retorna o template para nao selecionado para captar outra selecao. Ou limpa o localstorage
		if (mode === 'template') props.formStateManager.changeFieldValue('selectedHtmlContentToReplace', undefined)
		else if (mode === 'localStorage') MedicalReportLocalStorageUtil.discardMedicalReportLocalStorage(props.exam.code)
	}

	/**
	 * Adiciona um laudo
	 */
	function onAddMedicalReportRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(addMedicalReportRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = addMedicalReportRequest.responseData!
		const medicalReports = props.formStateManager.getFieldValue('medicalReports')
		props.formStateManager.changeFieldValue('medicalReports', [...medicalReports, { reportCode: result }])

		setCurrentTab(`${medicalReports.length}`)
	}

	/**
	 */
	function removeMedicalReport(tabKey: string): void {
		const medicalReports: MedicalReportContentTP[] = props.formStateManager.getFieldValue('medicalReports')
		if (medicalReports.length === 1) {
			NotificationHelper.error('Ops', 'Não é possível remover o único laudo existente')
			return
		}

		const medicalReportTP = medicalReports[Number(tabKey)]
		if (!medicalReportTP) {
			NotificationHelper.error('Ops!', 'Laudo não encontrado')
			return
		}

		setMedicalReportTabKeyCodeToRemove(Number(tabKey))
		setMedicalReportCodeToRemove(medicalReportTP.reportCode)
		setIsRemoveMedicalReportModalVisible(true)
	}

	/**
	 * Ao remover um laudo
	 */
	function onRemoveMedicalReport(): void {
		const medicalReports: MedicalReportContentTP[] = props.formStateManager.getFieldValue('medicalReports')

		medicalReports.splice(medicalReportTabKeyCodeToRemove!, 1)
		props.formStateManager.changeFieldValue('medicalReports', [...medicalReports])

		setCurrentTab('0')
		setIsRemoveMedicalReportModalVisible(false)
		setMedicalReportCodeToRemove(undefined)
		setMedicalReportTabKeyCodeToRemove(undefined)
	}

	/**
	 */
	function onContentChange(json: JSONContent, html: string, index: number): void {
		const medicalReports = props.formStateManager.getFieldValue('medicalReports')
		medicalReports[index].json = json
		medicalReports[index].html = html

		MedicalReportLocalStorageUtil.saveMedicalReportOnTyping(props.exam.code, medicalReports)
	}

	return (
		<S.Wrapper>
			<LoadingOverlayCP show={addMedicalReportRequest.isAwaiting} />

			<StatusSaveReportLocalStorageCP examCode={props.exam.code} onClickToRestore={() => onForceContent('localStorage')} />

			{props.exam.doctorGroup?.config?.blockMultipleMedicalReportsOnExam ? (
				<EditorMedicalReportCP
					exam={props.exam}
					appearance={{
						noBorder: true,
					}}
					forceReloadContent={reloadEditor}
					initialContent={props.formStateManager.getFieldValue('medicalReports')[0].html as string}
					onContentChange={(json, html) => onContentChange(json, html, 0)}
				/>
			) : (
				<S.TabsWrapper>
					<TabsCP
						defaultActiveKey={currentTab}
						type={props.exam.doctorGroup?.config?.blockMultipleMedicalReportsOnExam ? 'card' : 'editable-card'}
						editableConfig={{
							onAddTab: () => addMedicalReportRequest.runRequest(MedicalReportRequests.addMedicalReportToExam(props.exam.code)),
							onRemoveTab: removeMedicalReport,
						}}
						onChangeTab={setCurrentTab}
						tabs={props.formStateManager.getFieldValue('medicalReports').map((medicalReport, index) => ({
							key: index.toString(),
							title: <S.TabsTitle> {`Laudo ${+index + 1}`} </S.TabsTitle>,
							content: (
								<EditorMedicalReportCP
									exam={props.exam}
									appearance={{ noBorder: true }}
									forceReloadContent={reloadEditor}
									initialContent={props.formStateManager.getFieldValue('medicalReports')[index]?.html as string}
									onContentChange={(json, html) => onContentChange(json, html, index)}
								/>
							),
						}))}
					/>
				</S.TabsWrapper>
			)}

			{medicalReportTabKeyCodeToRemove !== undefined && !!medicalReportCodeToRemove && (
				<ModalRemoveMedicalReportCP
					visible={isRemoveMedicalReportModalVisible}
					medicalReportTabKeyCode={medicalReportTabKeyCodeToRemove}
					medicalReportCode={medicalReportCodeToRemove}
					onCancel={() => setIsRemoveMedicalReportModalVisible(false)}
					onDelete={onRemoveMedicalReport}
				/>
			)}
		</S.Wrapper>
	)
}
