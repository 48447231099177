import { Layout } from 'antd'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import React from 'react'
import styled from 'styled-components'
import { TitleBrowserCP } from 'submodules/nerit-framework-ui/common/components/title-browser/TitleBrowserCP'

const CLASS_MAIN_LAYOUT = 'layout_main'

interface ScpProps {
	backgroundColor?: string
	overflowVertical?: boolean
}

interface ICPProps extends ScpProps {
	mainLayout?: boolean
	header?: JSX.Element
	content?: JSX.Element
	sider?: React.ReactNode
	rightSider?: React.ReactNode
	footer?: JSX.Element
	titleBrowser?: string
}

/**
 * Componente que renderiza o layout padrao do sistema.
 */
export function LayoutCP(props: ICPProps): JSX.Element {
	return (
		<WrapperSCP
			backgroundColor={props.backgroundColor}
			overflowVertical={props.overflowVertical}
			className={!!props.mainLayout ? CLASS_MAIN_LAYOUT : ''}
		>
			{!!props.titleBrowser && <TitleBrowserCP pageName={props.titleBrowser} />}
			<Layout>
				{props.header}

				<Layout>
					{props.sider}
					{props.content}
					{props.rightSider}
				</Layout>

				{props.footer}
			</Layout>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div<ScpProps>`
	.ant-layout > .ant-layout {
		overflow-y: ${(props) => (props.overflowVertical ? 'auto' : undefined)};
	}

	&,
	.ant-layout {
		overflow: hidden;
		height: 100%;
		width: 100%;
		background-color: ${ThemeProject.backgroundMain} !important;
		color: ${ThemeProject.colorText};
	}

	&.${CLASS_MAIN_LAYOUT} {
		> .ant-layout {
			height: 100vh;
			width: 100vw;
		}
	}
`
