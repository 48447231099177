import axios, { AxiosRequestConfig, AxiosResponse, CancelToken, CancelTokenSource } from 'axios'
import { NeritFrameworkProjectConfig } from 'config/NeritFrameworkProjectConfig'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { MimeTypeEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/MimeTypeEnum'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { RequestHeaderTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestHeaderTP'
import { RequestResponseTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestResponseTP'
import { ResponseErrorCustomActionTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/ResponseErrorCustomActionTP'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'

/**
 * Encapsula metodos para gestao de requisicoes http.
 */
export class RequestHelper {
	static readonly CANCELLED_RESPONSE = '[cancelled-request]'

	private static readonly _DEFAULT_RESPONSE: RequestResponseTP = 'json'

	private static readonly _customErrorActions: ResponseErrorCustomActionTP[] = []
	private static _cancellationTokenMap: Map<string, CancelTokenSource> = new Map() // [requestID] -> [token de cancelamento]

	static readonly _defaultHeaders: RequestHeaderTP[] = [
		{
			headerName: 'Content-Type',
			headerValue: MimeTypeEnum.JSON,
		},
		{
			headerName: 'Accept',
			headerValue: MimeTypeEnum.JSON,
		},
	]

	private constructor() {}

	/**
	 * Adiciona 01 action generica customizada para tratar erros de 01
	 * determinado tipo (status http) em requisicoes.
	 *
	 * Sera executada toda vez que ocorrer 01 erro do tipo especificado
	 * em alguma requisicao a menos que a requisicao sobrescreva essa acao
	 * individualmente.
	 */
	static addErrorCustomAction(action: ResponseErrorCustomActionTP): void {
		this._customErrorActions.push(action)
	}

	/** Adiciona 01 header customizado a ser incluido por padrao em toda requisicao. */
	static addDefaultHeader(headerName: string, headerValue: string): void {
		this._defaultHeaders.push({ headerName, headerValue })
	}

	static addDefaultHeaderConfig(headerConfig: RequestHeaderTP): void {
		//Verifica se já possui, se sim, apenas atualiza o valor
		if (this._defaultHeaders.find((header) => header.headerName === headerConfig.headerName)) {
			this._defaultHeaders[headerConfig.headerName] = headerConfig.headerValue
			return
		}
		this._defaultHeaders.push(headerConfig)
	}

	static removeHeader(headerName: string): void {
		this._defaultHeaders.splice(
			this._defaultHeaders.findIndex((header) => header.headerName === headerName),
			1,
		)
	}

	static async runRequest<ResponseTP>(config: RequestConfigTP, requestId?: string): Promise<AxiosResponse<ResponseTP>> // Foramato de chamada 01

	/** Executa 01 requisicao http generica parametrizada. */
	static async runRequest<ResponseTP = any>(
		config: RequestConfigTP,
		param2?: string | (() => void) | boolean,
		requestId?: string,
	): Promise<AxiosResponse<ResponseTP>> {
		requestId = requestId ?? SystemUtils.nvl(typeof param2 === 'string', param2 as string)
		const onCancel = typeof param2 === 'function' ? param2 : undefined
		const enableCancellation = param2 === true || !!onCancel || !!requestId

		const _params = config.avoidParamTransformer ? config.params : RequestUtils.paramTransformer(config.params)
		let requestParams: AxiosRequestConfig | undefined

		try {
			requestParams = {
				url: NeritFrameworkProjectConfig.formatApiBaseUrl(config),
				method: config.method,
				headers: this._getRequestHeaders(config),
				params: config.method === HttpMethodEnum.GET ? _params : undefined,
				data: config.method !== HttpMethodEnum.GET ? _params : undefined,
				responseType: config.responseType ?? this._DEFAULT_RESPONSE,
				maxContentLength: Infinity,
			}

			if (enableCancellation) {
				requestId = requestId ?? RequestUtils.getNewRequestId()
				requestParams.cancelToken = this._getCancelToken(requestId)
			}

			return await axios.request<ResponseTP>(requestParams)
		} catch (error) {
			if (!axios.isCancel(error)) {
				if (axios.isAxiosError(error)) {
					const { errors, message } = RequestUtils.getErrorMessage(error)

					NeritFrameworkProjectConfig.notifyApi('error', `${message}`, `${errors}`)
				}

				throw this._getErrorToThrow(error, config)
			}

			if (!!onCancel) onCancel()

			throw this.CANCELLED_RESPONSE
		} finally {
			if (!!requestId) this._cancellationTokenMap.delete(requestId)
		}
	}

	/** Cancela 01 requisicao, identificada por seu ID, caso esteja em andamento. */
	static cancelRequest(requestId: string, logMessage?: string): void {
		if (!!this._cancellationTokenMap.get(requestId))
			this._cancellationTokenMap.get(requestId)!.cancel(logMessage ?? `Requisicao "${requestId}" cancelada...`)
	}

	/** Cancela todas as requisicoes que estiverem em andamento. */
	static cancelAllRequests(): void {
		this._cancellationTokenMap.forEach((cancelTokenSource, requestId) => this.cancelRequest(requestId))
	}

	/**
	 * Cancela todas as requisicoes que estiverem em andamento & reinicializa estado de
	 * controle de andamento de requisicoes, na classe.
	 */
	static async reset(): Promise<void> {
		this.cancelAllRequests()
		await SystemUtils.sleep(this._cancellationTokenMap.size * 25)
		this._cancellationTokenMap = new Map()
	}

	/** Gera & retorna headers para envio de 01 requisicao. */
	static _getRequestHeaders(config?: RequestConfigTP): {} {
		const headerLists = [this._defaultHeaders, config?.headers ?? []]

		const hasNoAuth = config?.noAuth ?? false
		const headers: any = {}

		for (const headersList of headerLists) {
			headersList.forEach((header) => {
				if (!hasNoAuth || header.headerName !== 'Authorization') {
					headers[header.headerName] = header.headerValue
				}
			})
		}

		return headers
	}

	/** Trata & retorna token para cancelamento de 01 requisicao. */
	private static _getCancelToken(requestId: string): CancelToken {
		let cancelToken = this._cancellationTokenMap.get(requestId)

		if (!cancelToken) {
			cancelToken = axios.CancelToken.source()
			this._cancellationTokenMap.set(requestId, cancelToken)
		}

		return cancelToken.token
	}

	/** Avalia & trata 01 erro ocorrido durante 01 requisicao. Retorna falha a ser lancada ao fim da execucao. */
	private static _getErrorToThrow(error: any, requestConfig: RequestConfigTP): any {
		// Trata falha desconhecida
		const responseStatus = error.response?.status
		if (!responseStatus) return error

		// Trata falha identificada (com handler individual)
		const reqCustomAction = requestConfig?.httpStatusCustomAction
		if (!!reqCustomAction && reqCustomAction?.httpStatus === responseStatus) {
			reqCustomAction.action(error)
			return error.response
		}

		// Trata falha identificada (com handler generico)
		for (const genericCustomAction of this._customErrorActions) {
			if (genericCustomAction.httpStatus === responseStatus) {
				genericCustomAction.action(error)
				break
			}
		}

		return error.response
	}
}
