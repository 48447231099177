import { Flex } from 'antd'
import styled, { css } from 'styled-components'

export interface IFlexSCPProps {
	margin?: {
		top?: number
		right?: number
		bottom?: number
		left?: number
	}
	wrap?: boolean
}

export const CustomFlex = styled(Flex)<IFlexSCPProps>`
	${(props) => css`
		margin-top: ${props.margin?.top ?? 0}px;
		margin-right: ${props.margin?.right ?? 0}px;
		margin-bottom: ${props.margin?.bottom ?? 0}px;
		margin-left: ${props.margin?.left ?? 0}px;

		${props.wrap && 'flex-wrap: wrap;'}
	`}
`
