import { VxWindIconCP } from 'app/components/vx-wind/vx-wind-icon/VxWindIconCP'
import { memo } from 'react'
import * as S from './VxWindTalksStyles'

interface ICPProps {
	phrase: string
}
const SPACE_CHARACTER_UNICODE = '\u00A0'

function VxWindTalksComponent({ phrase }: ICPProps): JSX.Element {
	return (
		<S.Wrapper>
			<VxWindIconCP />
			<S.AnimatedTextWrapper>
				{phrase.split(/(?!$)/).map((char, index) => (
					//A Key precisa ser diferente para que o componente seja renderizado novamente toda vez que receberuma frase nova
					<S.AnimatedLetter key={`${char}-${index}-${Date.now()}`} delay={index * 0.05}>
						{char === ' ' ? SPACE_CHARACTER_UNICODE : char}
					</S.AnimatedLetter>
				))}
			</S.AnimatedTextWrapper>
		</S.Wrapper>
	)
}

export const VxWindTalksCP = memo(VxWindTalksComponent)
