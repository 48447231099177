import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { EditorMedicalReportCP } from 'modules/exams/components/medical-report/editor-medical-report/EditorMedicalReportCP'
import { FormTemplateBasicDataICP } from 'modules/template/components/form-template/inner/FormTemplateBasicDataICP'
import { TemplateFormModel } from 'modules/template/components/form-template/inner/TemplateFormModel'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { MedicalReportContentTP } from 'submodules/beerads-sdk/services/exams/medical-reports/types/MedicalReportContentTP'
import { TemplatesRequests } from 'submodules/beerads-sdk/services/templates/templates/TemplatesRequests'
import { TemplateSaveRequestDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/request/TemplateSaveRequestDTO'
import { TemplateOwnerRequestDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/request/inner/TemplateOwnerRequestDTO'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import * as S from './FormTemplateStyles'

interface ICPProps {
	type: TemplateTypeEnum
	data?: {
		initialValues?: {
			content?: string
			contentHtml?: string
			modalities?: ExamModalityEnum[]
			owner: {
				// Um dos 3 deve ser preenchido
				clinicCode?: number
				doctorGroupCode?: number
				doctorCode?: number
			}
		}
		template?: TemplateResponseDTO
	}
	onSave: () => void
	appearance?: {
		wrapBasicDataOnCard: boolean
	}
}

export function FormTemplateCP(props: ICPProps): JSX.Element {
	const [formValidator, setFormValidator] = useState<TemplateFormModel>(new TemplateFormModel())
	const formStateManager = useFormStateManager(formValidator)
	const { pathname } = useLocation()

	const isTemplateScreen = pathname.includes(PrivateUserRouter.TEMPLATES)

	const saveTemplateRequest = useRequest<void>('none')
	useEffect(onSaveTemplateRequestChange, [saveTemplateRequest.isAwaiting])

	useEffect(init, [props.data])

	function init(): void {
		if (!!props.data?.template) {
			setFormValidator(
				new TemplateFormModel({
					modalities: props.data.template.modalities[0],
					name: props.data.template.name,
					examDescription: props.data.template.examDescription,
					content: props.type === TemplateTypeEnum.TEMPLATE ? ({ html: props.data.template.contentHtml } as any) : props.data.template.content,
					contentHtml: props.data.template.contentHtml,
				}),
			)
			return
		}

		setFormValidator(
			new TemplateFormModel({
				modalities: props.data?.initialValues?.modalities,
				contentHtml: props.data?.initialValues?.contentHtml,
				content:
					props.type === TemplateTypeEnum.TEMPLATE
						? ({ html: props.data?.initialValues?.content ?? '' } as any)
						: props.data?.initialValues?.content,
			}),
		)
	}

	async function save(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) return

		const owner = props.data?.template?.owner ?? props.data?.initialValues?.owner
		if (!owner) {
			return NotificationHelper.success('Ops!', 'Dono do template não foi definido')
		}

		const formValues = formStateManager.getFormValues()

		if (!formValues) {
			return NotificationHelper.error('Erro', 'Erro ao obter valores do formulário.')
		}

		const doctorGroupCode = props.data?.initialValues?.owner.doctorGroupCode

		const templateOwner: TemplateOwnerRequestDTO = {
			...owner,
			...(!isTemplateScreen && !doctorGroupCode && { doctorGroupCode: formValues.doctorGroupCode }),
		}

		const dto: TemplateSaveRequestDTO = {
			type: props.type,
			name: formValues.name,
			content: props.type === TemplateTypeEnum.PHRASE ? formValues.content : undefined,
			contentHtml: (formValues.content as MedicalReportContentTP).html ?? `<p>${formValues.content}</p>`,
			modalities: Array.isArray(formValues.modalities) ? formValues.modalities : [formValues.modalities],
			examDescription: formValues.examDescription,
			owner: templateOwner,
		}

		if (props.data?.template?.code) {
			saveTemplateRequest.runRequest(TemplatesRequests.update(props.data.template.code, dto))
		} else {
			saveTemplateRequest.runRequest(TemplatesRequests.create(dto))
		}
	}

	function onSaveTemplateRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				saveTemplateRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSave()
	}

	return (
		<>
			<CardCP
				margin={!props.appearance?.wrapBasicDataOnCard ? '0px 20px 20px 20px' : undefined}
				bordered={!props.appearance?.wrapBasicDataOnCard ? false : true}
				innerSpacing={!props.appearance?.wrapBasicDataOnCard ? 'none' : undefined}
				overflow={'hidden'}
			>
				<FormTemplateBasicDataICP
					type={props.type}
					formStateManager={formStateManager}
					doctorGroupCode={props.data?.initialValues?.owner.doctorGroupCode}
					isTemplateScreen={isTemplateScreen}
				/>
			</CardCP>

			{props.type === TemplateTypeEnum.PHRASE && (
				<CardCP
					bordered={!props.appearance?.wrapBasicDataOnCard ? false : true}
					innerSpacing={!props.appearance?.wrapBasicDataOnCard ? 'none' : undefined}
				>
					<S.TextAareaWrapperSCP>
						<TextAreaCP
							label={'Conteúdo'}
							fieldName={'content'}
							formStateManager={formStateManager}
							required={true}
							minRows={10}
							maxRows={15}
						/>
					</S.TextAareaWrapperSCP>
				</CardCP>
			)}

			{props.type === TemplateTypeEnum.TEMPLATE && (
				<S.Editor>
					<EditorMedicalReportCP
						initialContent={formStateManager.getFieldValue('content')?.html}
						onContentChange={(json, html) => {
							const content: MedicalReportContentTP = formStateManager.getFieldValue('content')
							content.json = json
							content.html = html
						}}
					/>
				</S.Editor>
			)}

			<FlexCP justify={'flex-end'} margin={{ top: 20, right: 20 }}>
				<ButtonCP type={'primary'} loading={saveTemplateRequest.isAwaiting} onClick={save}>
					Salvar
				</ButtonCP>
			</FlexCP>
		</>
	)
}
