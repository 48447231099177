import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { WidgetAuditPercentageResponseDTO } from 'submodules/beerads-sdk/services/dashboard/widgets/dtos/responses/WidgetAuditPercentageResponseDTO'
import { ExamModalityLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ChartApexCP } from 'submodules/nerit-framework-ui/common/components/chart/ChartApexCP'
import { CardWidgetWrapperCP } from 'submodules/nerit-framework-ui/common/components/dashboard/card-widget-wrapper/CardWidgetWrapperCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { WidgetOnlyValuesResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/features/dashboard/dtos/response/WidgetOnlyValuesResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'

interface ICPProps extends BasicStyleWrapperCPProps {
	initialValue?: WidgetOnlyValuesResponseDTO
	requestConfigGetter?: RequestConfigTP
	loading?: boolean
	shouldLoad?: number
	onClick?: () => void
	style?: {
		maxWidth?: string
	}
	main: {
		title: string
		icon?: JSX.Element
		help?: string
		isZeroTarget?: boolean
		blinkOnZeroTarget?: boolean
	}
}

export function CardWidgetAuditPercentage(props: ICPProps): JSX.Element {
	const [labels, setLabels] = useState<string[]>([])
	const [series, setSeries] = useState<number[]>([])
	const [totalAuditPercentage, setTotalAuditPercentage] = useState<string>()

	const lastUpdated = `A última atualização dos dados foi em ${DateUtils.formatDate(
		new Date(),
		DateFormatEnum.BR_WITHOUT_TIME,
	)} às ${DateUtils.formatDate(new Date(), DateFormatEnum.TIME_H_M)}`

	const getAuditPercentageRequest = useRequest<WidgetAuditPercentageResponseDTO>('api-return')

	const formattedLabel = labels.map((item, index) => {
		return `${item} - ${series[index].toFixed(1)}%`
	})

	useEffect(init, [props.shouldLoad])

	useEffect(onGetAuditPercentageRequestChange, [getAuditPercentageRequest.isAwaiting])

	function init() {
		getAuditPercentageRequest.runRequest(props.requestConfigGetter)
	}

	function onGetAuditPercentageRequestChange() {
		if (!RequestUtils.isValidRequestReturn(getAuditPercentageRequest)) return

		if (getAuditPercentageRequest.responseData) {
			const labelsArray: string[] = []
			const seriesArray: number[] = []
			//Se veio dado, seta utilizando 1 casa decimal
			if (getAuditPercentageRequest.responseData.totalPercentage) {
				setTotalAuditPercentage(getAuditPercentageRequest.responseData.totalPercentage.toFixed(1))
			} else {
				//Se não utiliza 0
				setTotalAuditPercentage('0')
			}

			getAuditPercentageRequest.responseData.auditPercetageData.forEach((item) => {
				labelsArray.push(ExamModalityLabelEnum[item.modality])
				seriesArray.push(+item.percentage.toFixed(3))
			})

			setLabels(labelsArray)
			setSeries(seriesArray)
		}
	}

	return (
		<CardWidgetWrapperCP title={props.main.title} help={props.main.help} loading={getAuditPercentageRequest.isAwaiting} info={lastUpdated}>
			{getAuditPercentageRequest.isSuccess && series.length > 0 ? (
				<FlexCP alignItems="flex-start" flexDirection="column" gap={8}>
					<ChartWrapper>
						<ChartApexCP
							options={{
								series: series,
								labels: formattedLabel,
								chart: {
									width: 350,
									type: 'pie',
								},
								dataLabels: {
									enabled: true,
									formatter: (_, opts) => {
										return opts.w.globals.labels[opts.seriesIndex]
									},
								},
								tooltip: {
									y: {
										formatter: (seriesLabel) => `${seriesLabel}%`,
									},
								},
							}}
						/>
					</ChartWrapper>
					<CardWidgetWrapperCP
						title={'Porcentual total de auditorias'}
						help={'Percentual de auditorias realizadas em relação ao total de exames'}
						info={lastUpdated}
					>
						<ValueSCP>{totalAuditPercentage}%</ValueSCP>
					</CardWidgetWrapperCP>
				</FlexCP>
			) : (
				<ValueSCP>0%</ValueSCP>
			)}
		</CardWidgetWrapperCP>
	)
}

const ValueSCP = styled.div<{ color?: string }>`
	font-size: 30px;
	color: ${(props) => props.color};
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const ChartWrapper = styled.div`
	height: 200px;
`
