import { RolesPermissionsTP } from 'submodules/beerads-sdk/common/roles-permissions/enums'

export type UserHasPermissionsOptionsTP = {
	/**
	 * If true, the function will check if the user has all the required permissions.
	 */
	validateAllRoles?: boolean
}

type UserHasPermissionsPropsTP = {
	userPermissions: RolesPermissionsTP[]
	requiredPermissions: RolesPermissionsTP[]
	options?: UserHasPermissionsOptionsTP
}

/**
 * Checks if the user has all or some of the required permissions.
 *
 * @param userPermissions - Permissions the user has.
 * @param requiredPermissions - Permissions that are required to pass the check.
 * @param options - Options to customize the check.
 *
 * @returns true if the user has all or some of the required permissions, false otherwise.
 */
export function userHasPermissions({ userPermissions, requiredPermissions, options = {} }: UserHasPermissionsPropsTP): boolean {
	const permissions = new Set<RolesPermissionsTP>(userPermissions)

	return options.validateAllRoles
		? requiredPermissions.every((requiredRole) => permissions.has(requiredRole))
		: requiredPermissions.some((requiredRole) => permissions.has(requiredRole))
}
