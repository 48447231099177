import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

export const WrapperSCP = styled.div`
	color: ${ThemeFrameworkCommon.browserDefaultColorDark};
	margin-top: -10px;
	height: auto;
`

export const ListMedicalReportComplianceWrapperSCP = styled.div`
	height: fit-content;
`
