import { setAuthHeader } from '@/services/http/http'
import { HeaderVxCP } from 'app/components/header-vx/HeaderVxCP'
import { useAppDispatch, useAppSelector } from 'app/redux/hook'
import { authActions } from 'app/redux/slices/auth/AuthSlice'
import { PrivatePatientPortalRouter } from 'app/routers/private/PrivatePatientPortalRouter'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ProfileMenuItemCP } from 'submodules/nerit-framework-ui/common/components/menu/menu-item-profile/ProfileMenuItemCP'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'

/**
 */
export function ScreenPrivatePatientPortal(): JSX.Element {
	const dispatch = useAppDispatch()
	const loggedPatientUserData = useAppSelector((state) => state.auth.patientPortal)

	return (
		<LayoutCP
			mainLayout={true}
			header={
				<HeaderVxCP title={'Laudo Online'}>
					<FlexCP justify={'flex-end'} alignItems={'center'}>
						<ProfileMenuItemCP
							isTopMenu={true}
							optionTitle={loggedPatientUserData?.patientName ?? ''}
							profileOptions={[
								{
									text: 'Sair',
									action: () => {
										dispatch(authActions.logout())
										setAuthHeader()
										NeritFrameworkRoutingHelper.historyPush(PublicRouter.PATIENT_PORTAL_LOGIN)
									},
								},
							]}
						/>
					</FlexCP>
				</HeaderVxCP>
			}
			content={
				<ContentCP overflowVertical={true}>
					<PrivatePatientPortalRouter />
				</ContentCP>
			}
		/>
	)
}
