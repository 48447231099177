import { useAppSelector } from 'app/redux/hook'
import { PendingRectificationAnalysisExamFormModel } from 'modules/exams/components/exam-analysis/drawer-exam-pending-rectification-analysis/inner/PendingRectificationAnalysisExamFormModel'
import { ExamStatusLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

interface ICPProps {
	isVisible: boolean
	formStateManager: IFormStateManager<PendingRectificationAnalysisExamFormModel>
	onSave: () => void
	onCancel: () => void
}
export default function ModalRefuseRectificationCP(props: ICPProps): JSX.Element {
	const loggedUserName = useAppSelector((state) => state.auth.user?.name)

	return (
		<ModalCP visible={props.isVisible} title={'Negar pedido de retificação'} onOk={props.onSave} onCancel={props.onCancel}>
			<>
				<TitleCP underLine={true} textSize={'normal'}>
					{`Resolução do evento ${ExamStatusLabelEnum.RECTIFICATION}`}
				</TitleCP>
				<HelpCP
					text={`${StringUtils.getFirstName(loggedUserName!)}, escreva uma resposta para a solicitação e devolva para a unidade hospitalar`}
					type={'text'}
				/>
				<TextAreaCP
					placeholder={'Escreva os detalhes da resolução do pedido de retificação'}
					minRows={4}
					required={true}
					formStateManager={props.formStateManager}
					fieldName={'comments'}
				/>
			</>
		</ModalCP>
	)
}
