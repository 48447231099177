import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

const theme = localStorage.getItem('theme')
const isDarkMode = theme === 'dark'

export const WrapperSCP = styled.div`
	/* Basic editor styles */
	.tiptap > * + * {
		margin-top: 0.75em;
	}

	.tiptap ul,
	.tiptap ol {
		padding: 0 1rem;
	}

	.tiptap h1,
	.tiptap h2,
	.tiptap h3,
	.tiptap h4,
	.tiptap h5,
	.tiptap h6 {
		line-height: 1.7;
	}

	.tiptap code {
		background-color: rgba(97, 97, 97, 0.1);
		color: #616161;
	}

	.tiptap pre {
		background: #0d0d0d;
		color: #fff;
		font-family: 'JetBrainsMono', monospace;
		padding: 0.75rem 1rem;
		border-radius: 0.5rem;
	}

	.tiptap pre code {
		color: inherit;
		padding: 0;
		background: none;
		font-size: 0.8rem;
	}

	.tiptap img {
		max-width: 100%;
		height: auto;
	}

	.tiptap blockquote {
		padding-left: 1rem;
		border-left: 2px solid ${isDarkMode ? 'rgba(199, 196, 194, 0.12)' : 'rgba(13, 13, 13, 0.1)'};
	}

	.tiptap hr {
		border: none;
		border-top: 2px solid ${isDarkMode ? 'rgba(199, 196, 194, 0.12)' : 'rgba(13, 13, 13, 0.1)'};
		margin: 2rem 0;
	}

	.tiptap {
		background-color: ${isDarkMode ? '#1E2122' : '#fff'};
		color: ${ThemeFrameworkCommon.browserDefaultBackgroundDark};
		padding: 16px;
		height: calc(100vh - 310px);
		overflow-y: auto;
	}

	.tiptap.ProseMirror-focused {
		outline: none;
	}

	/* table style */
	.tiptap table {
		border-collapse: collapse;
		margin: 0 auto;
		overflow: hidden;
		table-layout: fixed;

		td,
		th {
			border: 1px solid ${isDarkMode ? 'rgba(199, 196, 194, 0.12)' : 'rgba(61, 37, 20, 0.12)'};
			box-sizing: border-box;
			min-width: 1em;
			padding: 6px 8px;
			position: relative;
			vertical-align: top;

			> * {
				margin-bottom: 0;
			}
		}

		th {
			background-color: ${isDarkMode ? 'rgba(183, 183, 183, 0.05)' : 'rgba(61, 37, 20, 0.05)'};
			font-weight: bold;
			text-align: left;
		}

		.selectedCell:after {
			background: ${isDarkMode ? 'rgba(183, 183, 183, 0.05)' : 'rgba(61, 37, 20, 0.05)'};
			content: '';
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			pointer-events: none;
			position: absolute;
			z-index: 2;
		}

		.column-resize-handle {
			background-color: #d4d0d0;
			bottom: -2px;
			pointer-events: none;
			position: absolute;
			right: -2px;
			top: 0;
			width: 4px;
		}
	}

	.tableWrapper {
		margin: 1.5rem 0;
		overflow-x: auto;
	}

	.resize-cursor {
		cursor: ew-resize;
		cursor: col-resize;
	}
`
