import React, { useEffect, useState } from 'react'
import { SelectDoctorGroupPeopleCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-people/SelectDoctorGroupPeopleCP'
import { MedicalTimetableAbsenceFormModel } from 'modules/medical-timetable/components/modals/modal-medical-timetable-absence/inner/MedicalTimetableAbsenceFormModel'
import { DoctorsMedicalTimetableEventsSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/DoctorsMedicalTimetableEventsSaveRequestDTO'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { IMedicalTimetableRecord } from 'modules/medical-timetable/components/tables-medical-timetable/types/IMedicalTimetableRecord'
import { MedicalTimetablePeriodLabelEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetablePeriodEnum'
import { DoctorOnMedicalTimetableResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/DoctorOnMedicalTimetableResponseDTO'
import { DoctorsMedicalTimetableEventsRequests } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/DoctorsMedicalTimetableEventsRequests'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { TabsMedicalTimetableAbsenceCP } from 'modules/medical-timetable/components/tabs-medical-timetable-absence/TabsMedicalTimetabelAbsenceCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'
import { IHourRange } from 'submodules/nerit-framework-utils/sdk-utils/dtos/interfaces/IHourRange'

export type isUserAddingAbsenceOnMedicalTimetableTP = {
	record: IMedicalTimetableRecord
	dayOfWeek: WeekDaysEnum
	date: Date
	doctorOnMedicalTimetable: DoctorOnMedicalTimetableResponseDTO
}
interface ICPProps {
	isModalVisible: boolean
	doctorGroupCode: number
	isUserAddingAbsenceOnMedicalTimetable?: isUserAddingAbsenceOnMedicalTimetableTP
	onClose: () => void
	onSave: () => void
}

export function ModalMedicalTimetableAbsenceCP(props: ICPProps): JSX.Element {
	const [formValidator, setFormValidator] = useState<MedicalTimetableAbsenceFormModel>(new MedicalTimetableAbsenceFormModel())
	const formStateManager = useFormStateManager<MedicalTimetableAbsenceFormModel>(formValidator)

	const createAbsenceRequest = useRequest<void>('none')
	useEffect(onCreateAbsenceRequestChange, [createAbsenceRequest.isAwaiting])

	useEffect(init, [props.isModalVisible, formStateManager.getFieldValue('doctorCode')])

	function init(): void {
		if (!props.isModalVisible) return

		if (props.isUserAddingAbsenceOnMedicalTimetable && Object.keys(props.isUserAddingAbsenceOnMedicalTimetable).length > 0)
			setFormValidator(
				new MedicalTimetableAbsenceFormModel({
					type: props.isUserAddingAbsenceOnMedicalTimetable.doctorOnMedicalTimetable?.isRecurrent ? 'recurrent' : 'spot',
					doctorCode: props.isUserAddingAbsenceOnMedicalTimetable.doctorOnMedicalTimetable?.doctor.code,
					dateRange: {
						beginDate: props.isUserAddingAbsenceOnMedicalTimetable.doctorOnMedicalTimetable?.date,
						endDate: props.isUserAddingAbsenceOnMedicalTimetable.doctorOnMedicalTimetable?.date,
					},
				}),
			)
	}

	async function createAbsence(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) return

		const formValues = formStateManager.getFormValues()

		// Quando é spot seta a mesma data como inicial e final e converte do formato moment , se for edição não é nessário pois já setta no INIT
		if (formValues?.type === 'spot' && !props.isUserAddingAbsenceOnMedicalTimetable) {
			formValues.dateRange = {
				beginDate: DateUtils.toDate(formValues?.dateRange, DateFormatEnum.US_WITHOUT_TIME),
				endDate: DateUtils.toDate(formValues?.dateRange, DateFormatEnum.US_WITHOUT_TIME),
			}
		}

		// HourRange pega o horário da escala, se não vier horário passa o dia inteiro
		const hourRange: IHourRange = {
			beginHour: props.isUserAddingAbsenceOnMedicalTimetable?.record.period
				? MedicalTimetablePeriodLabelEnum[props.isUserAddingAbsenceOnMedicalTimetable.record.period].split('~')[0].trim()
				: '00:00',
			endHour: props.isUserAddingAbsenceOnMedicalTimetable?.record.period
				? MedicalTimetablePeriodLabelEnum[props.isUserAddingAbsenceOnMedicalTimetable.record.period].split('~')[1].trim()
				: '23:59',
		}

		const dto: DoctorsMedicalTimetableEventsSaveRequestDTO = {
			doctorGroupCode: props.doctorGroupCode,
			userDoctorCode: formValues?.doctorCode,
			dateRange: formValues?.dateRange,
			description: formValues?.description,
			reasonAbsence: formValues?.reasonAbsence,
			hourRange: hourRange,
			period: props.isUserAddingAbsenceOnMedicalTimetable?.record.period,
			id: props.isUserAddingAbsenceOnMedicalTimetable?.record.id,
		}
		createAbsenceRequest.runRequest(DoctorsMedicalTimetableEventsRequests.create(dto))
	}

	function onCreateAbsenceRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				createAbsenceRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onClose()
		props.onSave()
	}

	return (
		<ModalCP padding={20} title={'Ausência na escala'} visible={props.isModalVisible} onClose={props.onClose} onOk={createAbsence} noFooter={true}>
			<SelectDoctorGroupPeopleCP
				role={UserTypeEnum.DOCTOR}
				namesConfig={{
					showSpecialty: true,
					showTeam: true,
				}}
				formStateManager={formStateManager}
				formField={'doctorCode'}
				doctorGroupCode={props.doctorGroupCode}
				disabled={props.isUserAddingAbsenceOnMedicalTimetable && Object.keys(props.isUserAddingAbsenceOnMedicalTimetable).length > 0}
			/>

			{!!formStateManager.getFieldValue('doctorCode') && (
				<BasicStyleWrapperCP margin={{ top: 10 }}>
					<TabsMedicalTimetableAbsenceCP
						doctorGroupCode={props.doctorGroupCode}
						formStateManager={formStateManager}
						onCreateAbsence={createAbsence}
						onSave={props.onSave}
						onLoading={createAbsenceRequest.isAwaiting}
						isUserAddingAbsenceOnMedicalTimetable={props.isUserAddingAbsenceOnMedicalTimetable}
						onClose={props.onClose}
					/>
				</BasicStyleWrapperCP>
			)}
		</ModalCP>
	)
}
