import { useAppSelector } from 'app/redux/hook'
import { AppUtils } from 'app/utils/AppUtils'
import { RoutingHelper } from 'config/RoutingHelper'
import { NonDicomViewerICP } from 'modules/exams/components/exams/dicom-viewer-embedded/inner/NonDicomViewerICP'
import styled from 'styled-components'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { VxViewerParamTP } from 'submodules/beerads-sdk/services/exams/exams/types/VxViewerParamTP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface IEmbedViewerCPProps {
	exam: ExamResponseDTO
	onUnEmbedViewer: () => void
}

/**
 */
export function DicomViewerEmbeddedCP(props: IEmbedViewerCPProps): JSX.Element {
	const authData = useAppSelector((selector) => selector.auth)

	const viewerParams: VxViewerParamTP = {
		token: authData.token,
		examId: props.exam.id,
		examDescription: props.exam.description,
		patientName: props.exam.patient.name,
		viewBy: 'user',
	}

	if (!!props.exam.imageData.metadata) {
		return (
			<DicomViewerWrapperSCP>
				<FlexCP className={'header'} justify={'space-between'} alignItems={'center'}>
					BeeRads Viewer
					<ButtonCP icon={'close'} size={'small'} onClick={props.onUnEmbedViewer} />
				</FlexCP>

				<iframe
					src={RoutingHelper.getBeeRadsViewerUrl(viewerParams, props.exam.imageData.metadata)}
					title={`${AppUtils.getDomainName()} Viewer`}
				/>
			</DicomViewerWrapperSCP>
		)
	}

	return <NonDicomViewerICP examId={props.exam.id} />
}

const DicomViewerWrapperSCP = styled.div`
	width: 100%;
	height: 100%;
	margin: 20px 0;

	.header {
		height: 42px;
		background: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
		padding: 8px;
		border: solid 1px #e8e8e8;
	}

	iframe {
		height: 100%;
		width: 100%;
		border: none;
	}
`
