import { SelectDoctorRoleCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-role/SelectDoctorRoleCP'
import { SelectDutyProgramCP } from 'modules/doctor-groups/components/doctor-groups/select-duty-program/SelectDutyProgramCP'
import { DoctorGroupDoctorFormModel } from 'modules/doctor-groups/components/people/form-doctor-group-doctor/inner/DoctorGroupDoctorFormModel'
import { SelectTeamCP } from 'modules/doctor-groups/components/teams/select-team/SelectTeamCP'
import { SelectDoctorLevelCP } from 'modules/exams/components/exams/select-doctor-level/SelectDoctorLevelCP'
import { SelectExamSpecialtyCP } from 'modules/exams/components/exams/select-exam-specialty/SelectExamSpecialtyCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface IUserSelectedInfoICPProps {
	doctorGroupCode: number
	formStateManager: IFormStateManager<DoctorGroupDoctorFormModel>
}

/**
 * Formulario com dados do medico no grupo de medicos.
 */
export function FormDoctorGroupDoctorCP(props: IUserSelectedInfoICPProps): JSX.Element {
	const isSpecialtyFilled = !!props.formStateManager.getFieldValue('specialty')
	// const isDoctorManager = props.formStateManager.getFieldValue('doctorRole') === DoctorRoleInDoctorGroupEnum.MANAGER

	return (
		<FlexCP gap={'16px'} flexDirection="column">
			<RowCP>
				<ColumnCP size={isSpecialtyFilled ? 12 : 24}>
					<SelectExamSpecialtyCP label={'Especialidade'} isMultiple={false} formStateManager={props.formStateManager} fieldName={'specialty'} />
				</ColumnCP>

				{isSpecialtyFilled && (
					<ColumnCP size={12}>
						<SelectDoctorLevelCP
							label="Nível"
							isMultiple={false}
							formStateManager={props.formStateManager}
							fieldName={'doctorLevel'}
							required={true}
						/>
					</ColumnCP>
				)}
			</RowCP>

			<SelectTeamCP doctorGroupCode={props.doctorGroupCode} isMultiple={true} formStateManager={props.formStateManager} fieldName={'teamCodes'} />

			<RowCP>
				<ColumnCP size={24}>
					<SelectDoctorRoleCP label="Função" fieldName={'doctorRole'} formStateManager={props.formStateManager} />
				</ColumnCP>

				{/* //TODO: no momento não é necessário
				
					{!isDoctorManager && !!props.formStateManager.getFieldValue('doctorRole') && (
					<ColumnCP size={12}>
						<SelectDoctorGroupPeopleCP
							role={UserTypeEnum.DOCTOR}
							namesConfig={{
								showSpecialty: true,
								showTeam: true,
							}}
							label="Gerente"
							formStateManager={props.formStateManager}
							formField={'managerCode'}
							doctorGroupCode={props.doctorGroupCode}
							isMultiple={false}
						/>
					</ColumnCP>
				)} */}
			</RowCP>

			<SelectDutyProgramCP fieldName="doctorDutyProgram" formStateManager={props.formStateManager} label="Programa plantonista" />

			<SwitchCP formStateManager={props.formStateManager} fieldName={'isAuditor'} label={'É auditor?'} />
		</FlexCP>
	)
}
