import { ButtonApproveMedicalReportTemplate } from 'modules/template/components/button-approve-medical-report-template/ButtonApproveMedicalReportTemplate'
import { ButtonReproveMedicalReportTemplate } from 'modules/template/components/button-reprove-medical-report-template.tsx/ButtonReproveMedicalReportTemplateCP'
import { TemplateContentToCompareCP } from 'modules/template/components/modal-compare-template/inner/TemplateContentToCompareCP'
import { TemplateHistoryResponseDTO } from 'submodules/beerads-sdk/services/templates/history-templates/dtos/response/TemplateHistoryResponseDTO'
import { TemplateHistoryStatusEnum } from 'submodules/beerads-sdk/services/templates/history-templates/enums/TemplateHistoryStatusEnum'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { SplitterCP } from 'submodules/nerit-framework-ui/common/components/splitter/SplitterCP'

interface ICPProps {
	onClose: () => void
	templateHistory: TemplateHistoryResponseDTO
	currentTemplate: TemplateResponseDTO

	onSave: () => void
}

export function ModalCompareTemplateCP(props: ICPProps): JSX.Element {
	const previousContent = props.currentTemplate?.contentHtml ?? ''

	return (
		<ModalCP
			visible={true}
			onClose={props.onClose}
			width={props.currentTemplate?.contentHtml ? 1200 : 600}
			footer={
				props.templateHistory.status === TemplateHistoryStatusEnum.AWAITING_APPROVAL ? (
					<FlexCP gap={4} justify="flex-end">
						<ButtonReproveMedicalReportTemplate templateId={props.templateHistory.id} onSave={props.onSave} />
						<ButtonApproveMedicalReportTemplate templateId={props.templateHistory.id} onSave={props.onSave} />
					</FlexCP>
				) : (
					<></>
				)
			}
		>
			<SplitterCP>
				{previousContent && (
					<SplitterCP.Panel defaultSize={'50%'}>
						<TemplateContentToCompareCP
							content={previousContent}
							templateExamDescription={props.currentTemplate?.examDescription}
							templateExamModalities={props.currentTemplate.modalities}
							templateExamName={props.currentTemplate.name}
							templateVersion={'Versão atual'}
						/>
					</SplitterCP.Panel>
				)}
				<SplitterCP.Panel defaultSize={'50%'}>
					<TemplateContentToCompareCP
						content={props.templateHistory.contentHtmlNew}
						templateExamDescription={props.templateHistory.examDescriptionNew}
						templateExamModalities={props.templateHistory.examModalitiesNew}
						templateExamName={props.templateHistory.examNameNew}
						templateVersion={props.templateHistory.contentHtmlPrevious ? 'Nova Versão' : 'Novo'}
					/>
				</SplitterCP.Panel>
			</SplitterCP>
		</ModalCP>
	)
}
