import { IAuthState } from 'app/redux/slices/auth/AuthSlice'
import { IDoctorGroupState } from 'app/redux/slices/doctor-group/DoctorGroupSlice'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { ClinicPermissionUtils } from 'modules/auth/permissions/ClinicPermissionUtils'
import { CommonPermissionUtils } from 'modules/auth/permissions/CommonPermissionUtils'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { ScreenContentExamsDoneCP } from 'modules/reports/screens/screen-reports/contents/screen-content-exams-done/ScreenContentExamsDoneCP'
import { ScreenContentGrowthAnalysisReportCP } from 'modules/reports/screens/screen-reports/contents/screen-content-growth-analysis-report/ScreenContentGrowthAnalysisReportCP'
import { ScreenContentMainReportCP } from 'modules/reports/screens/screen-reports/contents/screen-content-main-report/ScreenContentMainReportCP'
import { ScreenContentNpsReportCP } from 'modules/reports/screens/screen-reports/contents/screen-content-nps-report/ScreenContentNpsReportCP'
import { ScreenContentRectificationReportCP } from 'modules/reports/screens/screen-reports/contents/screen-content-rectification-report/ScreenContentRectificationReportCP'
import { ScreenContentSlaReportCP } from 'modules/reports/screens/screen-reports/contents/screen-content-sla-report/ScreenContentSlaReportCP'
import { ReportTypeEnum } from 'submodules/beerads-sdk/services/dashboard/dashboard/types/ReportTypeEnum'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { ScreenSubMenuTP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/submenu/inner/ScreenSubMenuItemTP'

export const REPORTS_SUB_MENU_CLINIC_KEY = 'clinic'
export const REPORTS_SUB_MENU_DOCTOR_GROUP_KEY = 'doctorGroup'
export const REPORTS_SUB_MENU_DOCTOR_KEY = 'doctor'
export type ReportsViewTP =
	| 'clinicMainView'
	| 'clinicExamsDone'
	| 'clinicGrowthAnalysis'
	| 'clinicSla'
	| 'doctorGroupMainView'
	| 'doctorGroupExamsDone'
	| 'doctorGroupGrowthAnalysis'
	| 'doctorGroupSla'
	| 'doctorGroupRectification'
	| 'doctorGroupNps'
	| 'doctorMainView'
	| 'doctorExamsDone'

const VX_DOCTOR_GROUP_CODE = 142

/**
 * Reune metodos auxiliares para gestao da tela CONFIGURACOES.
 */
export const ReportsScreenUtils = {
	getSubMenuConfigs(authData: IAuthState, doctorGroup: IDoctorGroupState): Array<ScreenSubMenuTP<ReportsViewTP>> {
		return [
			{
				key: REPORTS_SUB_MENU_CLINIC_KEY,
				title: 'Clínica',
				items: [
					{
						name: 'Visão Geral',
						view: 'clinicMainView',
						route: PrivateUserRouter.REPORT_CLINIC_MAIN,
						content: <ScreenContentMainReportCP reportType={ReportTypeEnum.CLINIC_REPORT} />,
					},
					{
						name: 'Exames Laudados',
						view: 'clinicExamsDone',
						route: PrivateUserRouter.REPORT_CLINIC_EXAMS_DONE,
						content: <ScreenContentExamsDoneCP reportType={ReportTypeEnum.CLINIC_REPORT} />,
					},
					{
						name: 'Análise de Crescimento',
						view: 'clinicGrowthAnalysis',
						route: PrivateUserRouter.REPORT_CLINIC_GROWTH_ANALYSIS,
						content: <ScreenContentGrowthAnalysisReportCP reportType={ReportTypeEnum.CLINIC_REPORT} />,
						enabled: false,
					},
					{
						name: 'Análise de SLA',
						view: 'clinicSla',
						route: PrivateUserRouter.REPORT_CLINIC_SLA,
						content: <ScreenContentSlaReportCP reportType={ReportTypeEnum.DOCTOR_GROUP_REPORT} />,
						enabled: false,
					},
				],
				enabled: ClinicPermissionUtils.hasAnyPermissionsInAnyClinic(['isAdmin', 'isUser'], authData.clinicPermissions),
			},
			{
				key: REPORTS_SUB_MENU_DOCTOR_GROUP_KEY,
				title: 'Grupo de Médicos',
				items: [
					{
						name: 'Visão Geral',
						view: 'doctorGroupMainView',
						route: PrivateUserRouter.REPORT_DOCTOR_GROUP_MAIN,
						content: <ScreenContentMainReportCP reportType={ReportTypeEnum.DOCTOR_GROUP_REPORT} />,
					},
					{
						name: 'Exames Laudados',
						view: 'doctorGroupExamsDone',
						route: PrivateUserRouter.REPORT_DOCTOR_GROUP_EXAMS_DONE,
						content: <ScreenContentExamsDoneCP reportType={ReportTypeEnum.DOCTOR_GROUP_REPORT} />,
					},
					{
						name: 'Análise de Crescimento',
						view: 'doctorGroupGrowthAnalysis',
						route: PrivateUserRouter.REPORT_DOCTOR_GROUP_GROWTH_ANALYSIS,
						content: <ScreenContentGrowthAnalysisReportCP reportType={ReportTypeEnum.DOCTOR_GROUP_REPORT} />,
						enabled: false,
					},
					{
						name: 'Análise de SLA',
						view: 'doctorGroupSla',
						route: PrivateUserRouter.REPORT_DOCTOR_GROUP_SLA,
						content: <ScreenContentSlaReportCP reportType={ReportTypeEnum.DOCTOR_GROUP_REPORT} />,
					},
					{
						name: 'Laudos Conformes',
						view: 'doctorGroupRectification',
						route: PrivateUserRouter.REPORT_DOCTOR_GROUP_RECTIFICATION,
						content: <ScreenContentRectificationReportCP reportType={ReportTypeEnum.DOCTOR_GROUP_REPORT} />,
					},
					{
						name: 'NPS',
						view: 'doctorGroupNps',
						route: PrivateUserRouter.REPORT_DOCTOR_GROUP_NPS,
						content: <ScreenContentNpsReportCP />,
						enabled:
							doctorGroup.code === VX_DOCTOR_GROUP_CODE &&
							DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(authData.user, VX_DOCTOR_GROUP_CODE, [
								'isDoctorGroupAdmin',
								'isDoctorGroupUser',
							]),
					},
				],
				enabled: DoctorGroupPermissionUtils.hasAnyPermissionsInAnyDoctorGroup(
					['isDoctorGroupAdmin', 'isDoctorGroupUser'],
					authData.doctorGroupPermissions,
				),
			},
			{
				key: REPORTS_SUB_MENU_DOCTOR_KEY,
				title: 'Meus Laudos (médico)',
				items: [
					{
						name: 'Visão Geral',
						view: 'doctorMainView',
						route: PrivateUserRouter.REPORT_DOCTOR_MAIN,
						content: <ScreenContentMainReportCP reportType={ReportTypeEnum.DOCTOR_REPORT} />,
					},
					{
						name: 'Meus Laudos',
						view: 'doctorExamsDone',
						route: PrivateUserRouter.REPORT_DOCTOR_EXAMS_DONE,
						content: <ScreenContentExamsDoneCP reportType={ReportTypeEnum.DOCTOR_REPORT} />,
					},
				],
				enabled: CommonPermissionUtils.canAccessByType(UserTypeEnum.DOCTOR, authData.user),
			},
		]
	},
}
