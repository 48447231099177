import { PopConfirmCP } from '@/submodules/nerit-framework-ui/common/components/pop-confirm/PopConfirmCP'
import { useEffect } from 'react'
import { TemplatesHistoryRequests } from 'submodules/beerads-sdk/services/templates/templates/TemplatesHistoryRequests'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps {
	templateId: string
	index: number
	size?: ButtonSizeTP
	onSave: () => void
	setIsLoading?: (isLoading: boolean) => void
}

export function ButtonReproveMedicalReportTemplate(props: ICPProps): JSX.Element {
	const reproveMedicalReportTemplateRequest = useRequest<void>('none')
	useEffect(onReproveRequestChange, [reproveMedicalReportTemplateRequest.isAwaiting])

	function reproveRequest(): void {
		reproveMedicalReportTemplateRequest.runRequest(TemplatesHistoryRequests.reproveTemplateHistory(props.templateId))
		props.setIsLoading?.(true)
	}

	function onReproveRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(reproveMedicalReportTemplateRequest, 'Erro ao salvar!', 'Salvo com sucesso', true)) {
			return
		}
		props.setIsLoading?.(false)
		props.onSave()
	}

	return props.index === 0 ? (
		<PopConfirmCP title={'Este template não possui histórico, logo será deletado. Deseja prosseguir?'} onConfirm={reproveRequest} placement="top">
			<ButtonCP size={props.size} type="primary" danger>
				Reprovar
			</ButtonCP>
		</PopConfirmCP>
	) : (
		<ButtonCP size={props.size} type="primary" danger onClick={reproveRequest}>
			Reprovar
		</ButtonCP>
	)
}
