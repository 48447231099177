import { Col, ColProps } from 'antd'
import React from 'react'
type ColumnSizeTP = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24

interface IColumnCPProps {
	children?: React.ReactNode
	size?: ColProps['span'] | ColumnSizeTP
	xs?: ColProps['xs']
	sm?: ColProps['sm']
	md?: ColProps['md']
	lg?: ColProps['lg']
	xl?: ColProps['xl']
	xxl?: ColProps['xxl']
	style?: ColProps['style']
}

/**
 * Componente de COLUNA para alinhamento de GRID
 */
export const ColumnCP = (props: IColumnCPProps): JSX.Element => {
	return (
		<Col span={props.size} xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl} xxl={props.xxl} style={props.style}>
			{props.children}
		</Col>
	)
}
