import { OitFormModel } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/form-model/OitFormModel'
import { OitMedicalReportFormModelConverter } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/form-model/OitMedicalReportFormModelConverter'
import { OitSection1ICP } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/sections/OitSection1ICP'
import { OitSection2AICP } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/sections/OitSection2AICP'
import { OitSection2BICP } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/sections/OitSection2BICP'
import { OitSection3AICP } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/sections/OitSection3AICP'
import { OitSection3BICP } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/sections/OitSection3BICP'
import { OitSection3CICP } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/sections/OitSection3CICP'
import { OitSection3DICP } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/sections/OitSection3DICP'
import { OitSection4AICP } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/sections/OitSection4AICP'
import { OitSection4BICP } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/sections/OitSection4BICP'
import { OitSection4CICP } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/sections/OitSection4CICP'
import { MedicalReportFormModel } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModel'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MedicalReportContentTP } from 'submodules/beerads-sdk/services/exams/medical-reports/types/MedicalReportContentTP'
import { OitContentReportTP } from 'submodules/beerads-sdk/services/exams/medical-reports/types/OitContentReportTP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'

interface ICPProps {
	formStateManager: IFormStateManager<MedicalReportFormModel>
}

/**
 */
export function EditorOitMedicalReportCP(props: ICPProps): JSX.Element {
	const [formValidator, setFormValidator] = useState<OitFormModel>()
	const formStateManager = useFormStateManager(formValidator)

	useEffect(init, [props.formStateManager.getFieldValue('medicalReports')])

	useEffect(onChange, [formStateManager.getFormValues()])

	/**
	 * Inicializa.
	 */
	function init(): void {
		const medicalReports = props.formStateManager.getFieldValue('medicalReports')
		if (ArrayUtils.isEmpty(medicalReports)) {
			return
		}

		setFormValidator(new OitFormModel(medicalReports[0].json as OitContentReportTP))
	}

	/**
	 * Ao mudar alguma valor
	 */
	function onChange(): void {
		const oitMedicalReport = OitMedicalReportFormModelConverter.getOitContent(formStateManager)
		if (!oitMedicalReport) return

		const medicalReports: MedicalReportContentTP[] = props.formStateManager.getFieldValue('medicalReports')
		if (ArrayUtils.isEmpty(medicalReports)) return NotificationHelper.error('Ops!', 'Laudo não inicializado')

		medicalReports[0].json = oitMedicalReport
	}

	return (
		<ReportWrapperSCP>
			<OitSection1ICP formStateManager={formStateManager} />
			<OitSection2AICP formStateManager={formStateManager} />
			<OitSection2BICP formStateManager={formStateManager} />
			<OitSection3AICP formStateManager={formStateManager} />
			<OitSection3BICP formStateManager={formStateManager} />
			<OitSection3CICP formStateManager={formStateManager} />
			<OitSection3DICP formStateManager={formStateManager} />
			<OitSection4AICP formStateManager={formStateManager} />
			<OitSection4BICP formStateManager={formStateManager} />
			<OitSection4CICP formStateManager={formStateManager} />
		</ReportWrapperSCP>
	)
}

const ReportWrapperSCP = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	background: ${ThemeFrameworkCommon.browserDefaultBackgroundDark};
`
