import { AvatarExamModalityCP } from 'modules/exams/components/exams/avatar-exam-modality/AvatarExamModalityCP'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'

interface ICPProps {
	modalities: ExamModalityEnum[]
	id?: string
}

/**
 */
export function AvatarMultipleExamModalityCP(props: ICPProps): JSX.Element {
	if (props.modalities.length === 1) return <AvatarExamModalityCP modality={props.modalities[0]} />

	return (
		<FlexCP alignItems={'center'}>
			{props.modalities.map((modality) => (
				<BasicStyleWrapperCP margin={{ right: 5 }} key={props.id + modality}>
					<AvatarExamModalityCP modality={modality} />
				</BasicStyleWrapperCP>
			))}
		</FlexCP>
	)
}
