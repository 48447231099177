import { ThemeProjectVX } from 'config/theme/project/white-labels/ThemeProjectVX'
import React, { useEffect, useState } from 'react'
import { DoctorsMedicalTimetableEventsRequests } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/DoctorsMedicalTimetableEventsRequests'
import { DoctorsMedicalTimetableEventsSearchRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/DoctorsMedicalTimetableEventsSearchRequestDTO'
import { DoctorsMedicalTimetableEventsResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/DoctorsMedicalTimetableEventsResponseDTO'
import { MedicalTimetableIdEnum, MedicalTimetableIdLabelEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableIdEnum'
import {
	MedicalTimetableReasonAbsenceEnum,
	MedicalTimetableReasonAbsenceLabelEnum,
} from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableReasonAbsenceEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { IconCP } from 'submodules/nerit-framework-ui/common/components/icon/IconCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { ITimelineItem, TimelineCP } from 'submodules/nerit-framework-ui/common/components/timeline/TimelineCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { HourRangeRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/HourRangeRequestDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'
import * as S from'./TimelineDoctorMedicalStyles'

interface ICPProps {
	doctorCode: number
	doctorGroupCode: number
	onSave: () => void
}

export function TimelineDoctorMedicalTimetableAbsenceCP(props: ICPProps): JSX.Element {
	const [historyItems, setHistoryItems] = useState<ITimelineItem[]>([])

	const deleteAbsenceRequest = useRequest<void>('none')
	useEffect(onDeleteAbsenceRequestChange, [deleteAbsenceRequest.isAwaiting])

	const getDoctorMedicalTimetableAbsenceRequest = useRequest<DoctorsMedicalTimetableEventsResponseDTO>()
	useEffect(onGetDoctorMedicalTimetableAbsenceRequest, [getDoctorMedicalTimetableAbsenceRequest.isAwaiting])

	useEffect(() => onSelectDoctor(props.doctorCode), [props.doctorCode])

	function onSelectDoctor(doctorCode: number): void {
		if (!doctorCode) return

		const dto: DoctorsMedicalTimetableEventsSearchRequestDTO = {
			doctorGroupCode: props.doctorGroupCode,
			userDoctorCode: doctorCode,
		}

		getDoctorMedicalTimetableAbsenceRequest.runRequest(DoctorsMedicalTimetableEventsRequests.search(dto))
	}

	function onGetDoctorMedicalTimetableAbsenceRequest(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				{ request: getDoctorMedicalTimetableAbsenceRequest, mustReport404: false },
				NotificationHelper.DEFAULT_ERROR_GET_MESSAGE,
			)
		)
			return

		const result = getDoctorMedicalTimetableAbsenceRequest.responseData!

		const data: ITimelineItem[] = []

		Object.values(result)[1].forEach((item: DoctorsMedicalTimetableEventsResponseDTO) => {
			data.push({
				title: formatAbsenceTitle(item.beginDate, item.endDate, item.reasonAbsence),
				content: (
					<>
						<S.TextHelperWrapper>{formatAbsenceSubtitle(item.reasonAbsence, item.beginHour, item.endHour, item.id)}</S.TextHelperWrapper>
						<ButtonCP size={'small'} type={'link'} onClick={() => removeAbsence(item.code)}>
							<TextCP
								color={ThemeProjectVX.error}
								icon={<IconCP antIcon={'delete'} color={ThemeProjectVX.error} marginRight={5} />}
								text={'Excluir registro'}
							/>
						</ButtonCP>
					</>
				),
			})
		})

		setHistoryItems(data)
	}

	function formatAbsenceTitle(beginDate: string, endDate: string, reasonAbsence: MedicalTimetableReasonAbsenceEnum): string {
		if (DateUtils.getDiff(TimeBaseEnum.DAY, beginDate, endDate) > 0) {
			return `${DateUtils.formatDate(beginDate, DateFormatEnum.BR_WITHOUT_TIME)}-${DateUtils.formatDate(endDate, DateFormatEnum.BR_WITHOUT_TIME)}`
		}
		return `${DateUtils.formatDate(beginDate, DateFormatEnum.BR_WITHOUT_TIME)} - ${MedicalTimetableReasonAbsenceLabelEnum[reasonAbsence]}`
	}

	function formatAbsenceSubtitle(reasonAbsence: MedicalTimetableReasonAbsenceEnum, beginHour: string, endHour: string, urgent?: MedicalTimetableIdEnum): string {
		if (reasonAbsence === MedicalTimetableReasonAbsenceEnum.VACATION) {
			return `${MedicalTimetableReasonAbsenceLabelEnum[reasonAbsence]}`
		}

		const dateRange: HourRangeRequestDTO = {
			beginHour: DateUtils.formatTime(beginHour, DateFormatEnum.TIME_H_M),
			endHour: DateUtils.formatTime(endHour, DateFormatEnum.TIME_H_M),
		}

		if (urgent) {
			return `${MedicalTimetableIdLabelEnum[urgent]} - ${dateRange.beginHour} às ${dateRange.endHour}`
		}

		return `${dateRange.beginHour} às ${dateRange.endHour}`
	}

	function removeAbsence(code: number): void {
		deleteAbsenceRequest.runRequest(DoctorsMedicalTimetableEventsRequests.delete(code))
	}

	function onDeleteAbsenceRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				deleteAbsenceRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSave()
	}

	return (
		<S.WrapperTimelineSCP>
			<TimelineCP
				loading={getDoctorMedicalTimetableAbsenceRequest.isAwaiting || deleteAbsenceRequest.isAwaiting}
				mode={'alternate'}
				items={historyItems}
			/>
		</S.WrapperTimelineSCP>
	)
}

