import React from 'react'
import { ButtonTypeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonTypeTP'

type LinkSizeTP = 'large' | 'default' | 'small'

interface ILinkHtmlCPProps extends Omit<React.HTMLProps<HTMLAnchorElement>, 'size'> {
	type?: ButtonTypeTP
	href: string
	children: JSX.Element | string | JSX.Element[]
	size?: LinkSizeTP
}

const linkSize: Record<LinkSizeTP, string> = {
	large: 'lg',
	default: '',
	small: 'sm',
}

export function LinkHtmlCP({ size = 'default', type, href, children, className, ...props }: ILinkHtmlCPProps): JSX.Element {
	return (
		<a
			className={`ant-btn ant-btn-${type} ant-btn-${linkSize[size]} ${className}`}
			href={href}
			target={'_blank'}
			rel={'noopener noreferrer'}
			{...props}
		>
			{children}
		</a>
	)
}
