import { useAppSelector } from 'app/redux/hook'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import styled from 'styled-components'
import { DoctorGroupPeopleNamesResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/responses/DoctorGroupPeopleNamesResponseDTO'
import { ExamSpecialtyLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamSpecialtyEnum'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'

interface ICPProps {
	doctorGroupCode: number
	user: DoctorGroupPeopleNamesResponseDTO
	namesConfig?: {
		showSpecialty?: boolean
		showTeam?: boolean
	}
}

/**
 */
export function SelectLabelDoctorGroupPeopleICP(props: ICPProps): JSX.Element {
	const loggedUser = useAppSelector((state) => state.auth.user)

	const shouldShowTeam =
		(DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(loggedUser, props.doctorGroupCode, ['isDoctorGroupAdmin', 'isDoctorGroupUser']) &&
			props.namesConfig?.showTeam &&
			!ArrayUtils.isEmpty(props.user.teamNames)) ??
		false

	return (
		<OptionsWrapperSCP hasMultipleLine={shouldShowTeam}>
			<span>
				{props.user.name}
				{props.namesConfig?.showSpecialty && !!props.user.specialty ? ` (${ExamSpecialtyLabelEnum[props.user.specialty]})` : ''}
			</span>
			{shouldShowTeam && <OptionTeamSCP>{props.user.teamNames?.join(', ')}</OptionTeamSCP>}
		</OptionsWrapperSCP>
	)
}

const OptionsWrapperSCP = styled.div<{ hasMultipleLine: boolean }>`
	display: flex;
	flex-direction: column;
	line-height: ${(props) => (props.hasMultipleLine ? 15 : 22)}px;
`

const OptionTeamSCP = styled.div`
	font-style: italic;
	font-size: 9px;
`
