import styled from 'styled-components'
import { LoadingCP } from 'submodules/nerit-framework-ui/common/components/loading/basic/LoadingCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	message: string
	icon: JSX.Element
	extra?: JSX.Element
	isLoading?: boolean
}

/**
 * Exibe mensagem de 'boas vindas': Para quando NAO ha nenhum formluario a ser exibido na area de conteudo principal da tela de TEMPLATES.
 */
export function WelcomeContentCP(props: ICPProps): JSX.Element {
	return (
			<div className='flex flex-col items-center justify-center flex-auto bg-[#f1f1f1] dark:bg-[#252525]'>
				{props.isLoading ? (
					<LoadingCP />
				) : (
					<>
						<IconSCP>{props.icon}</IconSCP>
						<h2 className='text-2xl dark:text-white text-default-inherit' >{props.message}</h2>
						<div className='mt-8'>{props.extra}</div>
					</>
				)}
			</div >
	)
}

const IconSCP = styled.div`
	margin: 0 0 30px 0;
	fill: ${ThemeFrameworkCommon.browserDefaultColorDark};
`
