import { SizeType } from 'antd/es/config-provider/SizeContext'
import { ButtonProps } from 'antd/lib'
import React from 'react'
import { ButtonContentICP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonContentICP'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'
import { ButtonTypeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonTypeTP'
import { IconCP } from 'submodules/nerit-framework-ui/common/components/icon/IconCP'
import { TooltipPlacementTP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import * as S from './ButtonStyles'

type CustomPropsTP = {
	onClick?: () => void
	type?: ButtonTypeTP
	shape?: ButtonProps['shape']
	size?: ButtonSizeTP
	ghost?: boolean
	danger?: boolean
	tooltip?: string
	tooltipPlacement?: TooltipPlacementTP
	confirmMsg?: string
	loading?: boolean
	disabled?: boolean
	isSubmit?: boolean
	value?: string | number
	icon?: string
	style?: React.CSSProperties
	form?: string
} & S.WrapperScpPropsTP &
	S.CustomButtonTP

export interface IButtonCPProps extends React.PropsWithChildren<CustomPropsTP> {}

/**
 * COMPONENTE
 * Botao generico customizavel.
 */
export function ButtonCP(props: IButtonCPProps): JSX.Element {
	const buttonSizes: Record<ButtonSizeTP, SizeType> = {
		default: 'middle',
		large: 'large',
		small: 'small',
	}

	return (
		<S.ButtonWrapperSCP marginLeft={props.marginLeft} marginRight={props.marginRight} marginBottom={props.marginBottom} marginTop={props.marginTop}>
			<ButtonContentICP
				onClick={props.onClick}
				tooltip={props.tooltip}
				tooltipPlacement={props.tooltipPlacement}
				confirmMsg={props.disabled ? undefined : props.confirmMsg}
			>
				<S.CustomButton
					onlyIcon={!!props.icon && !props.children}
					htmlType={props.isSubmit ? 'submit' : 'button'}
					onClick={!props.confirmMsg ? props.onClick : undefined}
					disabled={props.disabled}
					type={props.type ?? 'default'}
					size={props.size ? buttonSizes[props.size] : 'middle'}
					ghost={props.ghost}
					shape={props.shape}
					icon={props.icon && <IconCP antIcon={props.icon} />}
					loading={props.loading}
					style={props.style}
					value={props.value}
					danger={props.danger}
					color={props.color}
					bgColor={props.bgColor}
					borderColor={props.borderColor}
					form={props.form}
				>
					{props.children}
				</S.CustomButton>
			</ButtonContentICP>
		</S.ButtonWrapperSCP>
	)
}
