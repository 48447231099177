import { ButtonMedicalTimetableUnassignCP } from 'modules/medical-timetable/components/buttons/button-medical-timetable-unassign/ButtonMedicalTimetableUnassignCP'
import { isUserAddingAbsenceOnMedicalTimetableTP } from 'modules/medical-timetable/components/modals/modal-medical-timetable-absence/ModalMedicalTimetableAbsenceCP'
import { MedicalTimetableAbsenceFormModel } from 'modules/medical-timetable/components/modals/modal-medical-timetable-absence/inner/MedicalTimetableAbsenceFormModel'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
	isUserAddingAbsenceOnMedicalTimetable: isUserAddingAbsenceOnMedicalTimetableTP
	doctorGroupCode: number
	formStateManager: IFormStateManager<MedicalTimetableAbsenceFormModel>
	onClose: () => void
	onSave: () => void
	onCreateAbsence: () => void
}

export function TabContentRemoveScheduleICP(props: ICPProps): JSX.Element {
	if (!props.isUserAddingAbsenceOnMedicalTimetable && Object.keys(props.isUserAddingAbsenceOnMedicalTimetable).length > 0) return <></>

	return (
		<ButtonMedicalTimetableUnassignCP
			doctorGroupCode={props.doctorGroupCode}
			isUserAddingAbsenceOnMedicalTimetable={props.isUserAddingAbsenceOnMedicalTimetable}
			isRecurrent={props.isUserAddingAbsenceOnMedicalTimetable.doctorOnMedicalTimetable.isRecurrent}
			formStateManager={props.formStateManager}
			onClose={props.onClose}
			onSave={props.onSave}
			onCreateAbsence={props.onCreateAbsence}
		/>
	)
}
