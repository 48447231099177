import { Layout } from "antd";
import styled from "styled-components";

export const Content = styled(Layout.Content)`
	position: relative;

	display: flex;
	height: 100%;
	max-height: 100vh;
	overflow-y: scroll;
	flex-direction: column;
	overflow: hidden;
`

export const LayoutWrapper = styled.div`
    display: grid;
    grid-template-columns: 67px 1fr;
`