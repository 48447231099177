import { IAutocompleteOption } from '@/modules/exams/components/medical-report/editor-medical-report/EditorMedicalReportCP'
import { EditorState } from '@tiptap/pm/state'
import { useCurrentEditor } from '@tiptap/react'
import { useEffect, useMemo, useState } from 'react'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import * as S from './TipTapAutoCompleteExtensionStyles'

interface ICPProps {
	autoComplete: IAutocompleteOption[]
}

export function TipTapAutoCompleteExtensionCP(props: ICPProps): JSX.Element {
	const { editor } = useCurrentEditor()
	const [query, setQuery] = useState('')

	const filteredOptions = useMemo(() => {
		return props.autoComplete.filter((item) => StringUtils.normalizeString(item.name).includes(StringUtils.normalizeString(query)))
	}, [props.autoComplete, query])

	useEffect(() => {
		if (editor) {
			editor.on('update', handleTextChange)

			return () => {
				editor.off('update', handleTextChange)
			}
		}

		return () => undefined
	}, [editor])

	useEffect(() => {
		document.addEventListener('keydown', handleTabPress)
		return () => {
			document.removeEventListener('keydown', handleTabPress)
		}
	}, [editor, query])

	function handleTextChange(): void {
		if (!editor) {
			return
		}

		const { from } = editor.state.selection
		const textAfterAt =
			editor.state.doc
				.textBetween(from - 20, from, undefined, ' ')
				.split('@')
				.pop() ?? ''

		setQuery(textAfterAt)
	}

	function handleTabPress(event: KeyboardEvent): void {
		if (!editor) {
			return
		}

		if (event.key === 'Tab') {
			event.preventDefault()
			if (filteredOptions.length > 0) {
				const firstOption = filteredOptions[0]
				const { from } = editor.state.selection

				editor.commands.deleteRange({ from: from - query.length - 1, to: from })
				editor.commands.insertContent(`${firstOption.content} `)
				editor.commands.focus()
				setQuery('')
			}
		}
	}

	function shouldShowFloatingMenu(state: EditorState): boolean {
		if (!editor) {
			return false
		}

		const { from } = state.selection
		const lengthOfTextToCheck = 5 // Quantidade de caracteres após o @ que irá validar

		// Captura o texto antes do cursor
		const textBeforeCursor = state.doc.textBetween(Math.max(0, from - lengthOfTextToCheck), from, undefined, ' ')

		return /^.*@$/gm.test(textBeforeCursor) || textBeforeCursor.includes('@')
	}

	function onClickInFloatingMenuOptions(item: IAutocompleteOption): void {
		if (!editor) {
			return
		}

		const { from } = editor.state.selection

		editor.commands.deleteRange({ from: from - query.length - 1, to: from })
		editor.commands.insertContent(item.content)
		editor.commands.focus()
		setQuery('')
	}

	return (
		<S.TipTapFloatingMenu
			className="shadow-md"
			editor={editor}
			tippyOptions={{ duration: 100 }}
			shouldShow={({ state }) => shouldShowFloatingMenu(state)}
		>
			{[...new Set(filteredOptions)].map((item, index) => (
				<S.TipTapFloatingMenuButton key={`${index}_${item.name}`} onClick={() => onClickInFloatingMenuOptions(item)}>
					{item.name}
				</S.TipTapFloatingMenuButton>
			))}
		</S.TipTapFloatingMenu>
	)
}
