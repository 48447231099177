import { ThemeProjectVX } from 'config/theme/project/white-labels/ThemeProjectVX'
import { FormMedicalTimetableAbsenceCP } from 'modules/medical-timetable/components/form-medical-timetable-absence/FormMedicalTimetableAbsenceCP'
import { MedicalTimetableAbsenceFormModel } from 'modules/medical-timetable/components/modals/modal-medical-timetable-absence/inner/MedicalTimetableAbsenceFormModel'
import React from 'react'
import styled from 'styled-components'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
	isUserAddingAbsenceOnMedicalTimetable?: boolean
	formStateManager: IFormStateManager<MedicalTimetableAbsenceFormModel>
	onLoading?: boolean
	onClose: () => void
	onCreateAbsence: () => void
}

export function TabContentAddAbsenceICP(props: ICPProps): JSX.Element {
	return (
		<>
			<TextCP
				text={
					'Use a ausência para registrar alguma ocorrência em relação ao médico. Este registro ficará salvo no sistema para consultas posteriores.'
				}
				size={'small'}
				fontStyle={'italic'}
				color={ThemeProjectVX.gray700}
			/>
			<FlexCP flexDirection="column" alignItems="center" margin={{ bottom: 20 }}>
				<FormMedicalTimetableAbsenceCP
					isUserAddingAbsenceOnMedicalTimetable={props.isUserAddingAbsenceOnMedicalTimetable}
					formStateManager={props.formStateManager}
				/>

				<ButtonWrapperSCP>
					<ButtonCP onClick={props.onClose}>Cancelar</ButtonCP>
					<ButtonCP
						type={'primary'}
						loading={props.onLoading}
						onClick={() => {
							props.formStateManager.changeFieldValue('type', 'recurrent')
							props.onCreateAbsence()
						}}
					>
						Salvar
					</ButtonCP>
				</ButtonWrapperSCP>
			</FlexCP>
		</>
	)
}

const ButtonWrapperSCP = styled.div`
	margin-top: 35px;
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 15px;
`
