import { useAppSelector } from 'app/redux/hook'
import { AppUtils } from 'app/utils/AppUtils'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AuthRequests } from 'submodules/beerads-sdk/services/auth/AuthRequests'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ValidationCodeInputCP } from 'submodules/nerit-framework-ui/common/components/validation-code-input/ValidationCodeInputCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps {
	onSuccess: () => void
	onCancel: () => void
	visible: boolean
}

export function ModalAuthDigitalCertificateCP(props: ICPProps): JSX.Element {
	const [otp, setOtp] = useState<string>('')
	const [loadingRefreshData, setLoadingRefreshData] = useState<boolean>(true)
	const { isLoggedWithDigitalCertificate, user } = useAppSelector((state) => state.auth)

	const digitalCertificateAuthRequest = useRequest<boolean>()

	useEffect(onDigitalCertificateAuthRequestChange, [digitalCertificateAuthRequest.isAwaiting])

	useEffect(() => {
		if (otp?.length === 6 && !digitalCertificateAuthRequest.isAwaiting) {
			authenticate()
		}
	}, [otp])

	/**
	 */
	async function authenticate(): Promise<void> {
		if (!otp || otp?.length < 6) {
			NotificationHelper.error('Ops', 'Preencha o código de 6 dígitos gerado no BirdID')
			return
		}
		setLoadingRefreshData(true)
		digitalCertificateAuthRequest.runRequest(AuthRequests.createDigitalCertificateToken({ otp }))
	}

	/**
	 */
	function onDigitalCertificateAuthRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(digitalCertificateAuthRequest, 'Erro ao autenticar', 'Autenticado com sucesso')) {
			setLoadingRefreshData(false)
			return
		}

		const success = digitalCertificateAuthRequest.responseData
		if (success) {
			AppUtils.refreshLoggedUserData().then(() => {
				props.onSuccess()
				setLoadingRefreshData(false)
			})
		}
	}

	if (!user?.isDoctor) return <></>

	return (
		<ModalCP
			title={'Autenticação do Certificado Digital'}
			visible={props.visible}
			onCancel={props.onCancel}
			onOk={authenticate}
			width={450}
			okText={'Autenticar'}
			actionLoading={digitalCertificateAuthRequest.isAwaiting || loadingRefreshData}
			disableOutsideClickClosing={digitalCertificateAuthRequest.isAwaiting || loadingRefreshData}
			closable={!digitalCertificateAuthRequest.isAwaiting || loadingRefreshData}
		>
			<AlertCP
				message={'Você já está autenticado e com uma sessão ativa no BirdID. Só informe novamente o código caso deseje atualizar a sessão'}
				type={'warning'}
				show={isLoggedWithDigitalCertificate}
				margin={{ bottom: 10 }}
			/>

			<HelpCP
				text={
					digitalCertificateAuthRequest.isAwaiting || loadingRefreshData
						? 'Conectando com a certificadora, aguarde alguns segundos'
						: 'Acesse o aplicativo do BirdID e informe o código de autenticação'
				}
				type={'text'}
			/>

			<FlexCP justify={'center'}>
				<WrapperSCP>
					<ValidationCodeInputCP onChange={setOtp} />
				</WrapperSCP>
			</FlexCP>
		</ModalCP>
	)
}

const WrapperSCP = styled.div`
	padding: 20px 0 20px 0;
`
