import { Form } from 'antd'
import * as _ from 'lodash-es'
import moment from 'moment'
import React, { KeyboardEvent, SyntheticEvent, useEffect, useState } from 'react'
import { ConditionalRenderCP } from 'submodules/nerit-framework-ui/common/components/conditional-render/ConditionalRenderCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { IFormItemCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { HelpIconCP } from 'submodules/nerit-framework-ui/common/components/icon/icons/HelpIconCP'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { OrArrayTP } from 'submodules/nerit-framework-utils/utils/types/OrArrayTP'
import * as S from './FormItemStyles'
import { IFormItemInputCommonProps } from './interfaces/IFormItemInputCommonProps'

type _SimpleSingleValueTP = number | string
type _ValueTP = OrArrayTP<_SimpleSingleValueTP> | moment.Moment

/**
 * OBS: Neste caso, excepcionalmente, eh necessario definir as props como 'type' ao invez de
 * 'interface' para possbilitar a tipagem correta.
 */
type _FormItemICPPropsTP<FModelTP extends FormModel, PropsTP extends IFormItemCommonProps<FModelTP>> = PropsTP & {
	children: JSX.Element
	hideBorder?: boolean
}

/**
 * COMPONENTE
 * Wrapper generico a ser utilizado por inputs de formulario.
 *
 * NOTE: Caso este componente seja controlado via 'form state manager' seu valor nao eh determinado diretamente pela prop 'value'.
 * @see FormStateManager
 */
export function FormItemICP<FModelTP extends FormModel, PropsTP extends IFormItemCommonProps<FModelTP>>(
	props: _FormItemICPPropsTP<FModelTP, PropsTP>,
): JSX.Element | null {
	const validationsCount = props.formStateManager?.validationsCount ?? 0

	const [value, setValue] = useState<_ValueTP>(props.value)
	const [mustRiseLabel, setMustRiseLabel] = useState<boolean>(false)
	const [validationErrMsg, setValidationErrMsg] = useState<string>()
	const [errorMessage, setErrorMessage] = useState<string>()
	const [hasFocus, setHasFocus] = useState<boolean>(false)

	useEffect(setValueByProps, [props.value])
	useEffect(parseValidation, [validationsCount])
	useEffect(onFormStateManagerChange, [props.formStateManager, props.reloadField])
	useEffect(handleErrMsgUpdate, [props.errorMessage, validationErrMsg])
	useEffect(updateLabelRisingState, [value, hasFocus])

	function updateLabelRisingState(): void {
		setMustRiseLabel(hasFocus || (typeof value !== 'object' && value !== undefined) || (typeof value === 'object' && !_.isEmpty(value)))
	}

	function setValueByProps(): void {
		if (props.allowFalsyValue) {
			setValue(props.value)
			return
		}
		if (!!props.value) setValue(props.value)
	}

	function parseValidation(): void {
		if (!!props.noValidation) return

		if (!hasStateManager() || props.formStateManager!.isValid) return setValidationErrMsg(undefined)

		const fieldErrors = props.formStateManager!.getFieldError(props.fieldName as keyof FModelTP)
		const constraints = _.get(fieldErrors, 'constraints')

		if (!!constraints) {
			const errMessages: any = Object.values(constraints) || []
			if (!!errMessages.length) return setValidationErrMsg(errMessages[0])
		}

		setValidationErrMsg(undefined)
	}

	function onFormStateManagerChange(): void {
		if (!hasStateManager()) return

		const stateValue = props.formStateManager!.getFieldValue(props.fieldName!)
		const isValidValue = ['number', 'string'].includes(typeof stateValue) || stateValue instanceof Array || stateValue instanceof Date

		if (isValidValue) handleChange(stateValue instanceof Date ? moment(stateValue) : stateValue)
	}

	function hasStateManager(): boolean {
		return !!props.fieldName && !!props.formStateManager
	}

	function hasMask(): boolean {
		return !!(props as IFormItemInputCommonProps).mask
	}

	function handleChange(eventOrValue: SyntheticEvent | string): void {
		// Analisa valor interno
		const receivedValue = ((eventOrValue as SyntheticEvent)?.target as any)?.value ?? eventOrValue
		const mustApplyMask = hasMask() && !!receivedValue && !Array.isArray(receivedValue) && !(receivedValue instanceof Date)

		const exhibitionValue = mustApplyMask ? MaskUtils.applyMask(receivedValue, (props as IFormItemInputCommonProps).mask! as any) : receivedValue
		if (exhibitionValue === value) return

		setValue(exhibitionValue)

		const formItemProps = props as IFormItemInputCommonProps
		// Expele valor de saida
		const shouldClearMask =
			mustApplyMask && typeof receivedValue === 'string' && !formItemProps.keepMask && formItemProps.mask !== InputMaskTypeEnum.MONEY
		const outputValue = shouldClearMask ? receivedValue.replace(/\D/g, '') : receivedValue

		if (!!props.onChange) props.onChange(outputValue)

		if (hasStateManager()) props.formStateManager!.changeFieldValue(props.fieldName!, outputValue)
	}

	function handleErrMsgUpdate(): void {
		if (!props.noValidation) setErrorMessage(props.errorMessage ?? validationErrMsg)
	}

	function handleBlur(eventOrValue: SyntheticEvent | string): void {
		setHasFocus(false)

		if (hasStateManager() && !props.noValidation) props.formStateManager!.validate()

		if (!!props.onBlur) {
			const newValue = _.get(eventOrValue, 'target.value', eventOrValue)
			props.onBlur(newValue)
		}
	}

	function handleFocus(): void {
		setHasFocus(true)
		if (hasStateManager()) props.formStateManager!.setFieldDirty(props.fieldName!)
	}

	function getCssClasses(): string {
		let cssClasses = S.CLASS_ANIMATE

		if (mustRiseLabel) cssClasses += ` ${S.CLASS_RISE_LABEL}`

		if (!!errorMessage) cssClasses += ` ${S.CLASS_ERROR}`

		return cssClasses
	}

	function handleKeyPress(event: KeyboardEvent): void {
		if (event.key === 'Enter' && !!props.onFormSubmit) props.onFormSubmit()
	}

	function getValueForChildren(): _ValueTP {
		const mustApplyMask = hasMask() && !!value && !(value instanceof Array)
		return mustApplyMask ? MaskUtils.applyMask(value as _SimpleSingleValueTP, (props as IFormItemInputCommonProps).mask! as any) : value
	}

	return (
		<S.WrapperSCP
			id="form-item-wrapper"
			fontSize={props.fontSize ?? 'normal'}
			marginTop={(props.marginTop ?? !!props.label) ? 15 : 5}
			marginRight={props.marginRight ?? 0}
			marginBottom={props.marginBottom ?? 5}
			marginLeft={props.marginLeft ?? 0}
			hideBorder={props.hideBorder}
		>
			<Form.Item label={props.label} colon={false} className={getCssClasses()} required={props.required} style={{ width: props.width }}>
				{React.cloneElement(props.children, {
					onFocus: handleFocus,
					onBlur: handleBlur,
					onChange: handleChange,
					onKeyPress: handleKeyPress,
					value: getValueForChildren(),
				})}

				<ConditionalRenderCP shouldRender={!!errorMessage}>
					<div className={'ant-form-explain'}>
						<span className="input-hint">{errorMessage}</span>
					</div>
				</ConditionalRenderCP>

				<div className={'ant-form-explain'}>
					{props.hint?.type === 'tooltip' ? (
						<FlexCP alignItems={'center'} justify={'flex-end'}>
							<TooltipCP showSpan={true} text={props.hint.text}>
								<FlexCP alignItems={'center'}>
									<>Entenda</>
									<HelpIconCP size={11} />
								</FlexCP>
							</TooltipCP>
						</FlexCP>
					) : (
						<span className="input-hint">{props.hint?.text}</span>
					)}
				</div>
			</Form.Item>
		</S.WrapperSCP>
	)
}
