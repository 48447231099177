import { RequestHelper } from '@/submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'
import { AuthUtils } from '@/submodules/nerit-framework-utils/sdk-utils/request-manager/utils/AuthUtils'
import { PayloadAction } from '@reduxjs/toolkit'
import { IAuthState } from 'app/redux/slices/auth/AuthSlice'
import { AppUtils } from 'app/utils/AppUtils'
import { AuthResponseDTO } from 'submodules/beerads-sdk/services/auth/dtos/responses/AuthResponseDTO'
import { ClinicNamesResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicNamesResponseDTO'
import { PersonClinicAccessTP } from 'submodules/beerads-sdk/services/clinics/person-clinic/types/PersonClinicAccessTP'
import { DoctorGroupNamesResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/responses/DoctorGroupNamesResponseDTO'
import { UserDoctorGroupAccessTP } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/types/UserDoctorGroupAccessTP'

function makeClinicPermissions(clinics: ClinicNamesResponseDTO[]): PersonClinicAccessTP {
	const clinicPermissions: PersonClinicAccessTP = {
		isAdmin: false,
		isUser: false,
		isDoctorGroupAdmin: false,
		isDoctorGroupUser: false,
		isDoctor: false,
	}

	if (!clinics.length) {
		return clinicPermissions
	}

	for (const clinic of clinics) {
		if (clinic.loggedUserAccess.isUser) {
			clinicPermissions.isUser = clinic.loggedUserAccess.isUser
		}

		if (clinic.loggedUserAccess.isAdmin) {
			clinicPermissions.isAdmin = clinic.loggedUserAccess.isAdmin
		}

		if (clinic.loggedUserAccess.isDoctorGroupAdmin) {
			clinicPermissions.isDoctorGroupAdmin = clinic.loggedUserAccess.isDoctorGroupAdmin
		}

		if (clinic.loggedUserAccess.isDoctorGroupUser) {
			clinicPermissions.isDoctorGroupUser = clinic.loggedUserAccess.isDoctorGroupUser
		}

		if (clinic.loggedUserAccess.isDoctor) {
			clinicPermissions.isDoctor = clinic.loggedUserAccess.isDoctor
		}
	}

	return clinicPermissions
}
function makeDoctorGroupPermissions(doctorGroups: DoctorGroupNamesResponseDTO[]): UserDoctorGroupAccessTP {
	const doctorGroupPermissions: UserDoctorGroupAccessTP = {
		isDoctorGroupAdmin: false,
		isDoctorGroupUser: false,
		isAuditor: false,
		isDoctorAdmin: false,
	}

	if (!doctorGroups.length) {
		return doctorGroupPermissions
	}

	for (const doctorGroup of doctorGroups) {
		if (doctorGroup.loggedUserAccess.isDoctorGroupAdmin) {
			doctorGroupPermissions.isDoctorGroupAdmin = doctorGroup.loggedUserAccess.isDoctorGroupAdmin
		}

		if (doctorGroup.loggedUserAccess.isAuditor) {
			doctorGroupPermissions.isAuditor = doctorGroup.loggedUserAccess.isAuditor
		}

		if (doctorGroup.loggedUserAccess.isDoctorGroupUser) {
			doctorGroupPermissions.isDoctorGroupUser = doctorGroup.loggedUserAccess.isDoctorGroupUser
		}

		if (doctorGroup.loggedUserAccess.isDoctorAdmin) {
			doctorGroupPermissions.isDoctorAdmin = doctorGroup.loggedUserAccess.isDoctorAdmin
		}
	}

	return doctorGroupPermissions
}

export function setLoggedUserReducer(state: IAuthState, action: PayloadAction<AuthResponseDTO>): IAuthState {
	const clinicPermissions = makeClinicPermissions(action.payload.user.clinics ?? [])
	const doctorGroupPermissions = makeDoctorGroupPermissions(action.payload.user.doctorGroups ?? [])

	AppUtils.refreshToken(action.payload.token)
	RequestHelper.addDefaultHeaderConfig(AuthUtils.getViewAsDoctor(action.payload.user.isDoctor))

	return {
		...state,
		...action.payload,
		clinicPermissions: clinicPermissions,
		doctorGroupPermissions: doctorGroupPermissions,
	}
}
