import { useAppSelector } from 'app/redux/hook'
import { ThemeProjectVX } from 'config/theme/project/white-labels/ThemeProjectVX'
import { FiltersReportCommonCP } from 'modules/reports/components/filters-report-common/FiltersReportCommonCP'
import { CommonReportsFilterFormModel } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModel'
import { CommonReportsFilterFormModelConverter } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModelConverter'
import { TableExamSlaAnalysisCP } from 'modules/reports/components/table-exams-sla-analysis/TableExamSlaAnalysisCP'
import { WidgetMedicalReportSlaByUrgencyCP } from 'modules/reports/components/widgets/widget-medical-report-sla-by-urgency/WidgetMedicalReportSlaByUrgencyCP'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import { ReportTypeEnum } from 'submodules/beerads-sdk/services/dashboard/dashboard/types/ReportTypeEnum'
import { WidgetsRequests } from 'submodules/beerads-sdk/services/dashboard/widgets/WidgetsRequests'
import { CardWidgetOnlyValuesShouldLoadCP } from 'submodules/nerit-framework-ui/common/components/dashboard/card-widget-only-values-should-load/CardWidgetOnlyValuesShouldLoadCP'
import { WidgetsRowCP } from 'submodules/nerit-framework-ui/common/components/dashboard/widgets-row/WidgetsRowCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { LayoutSubmenuContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/content/LayoutSubmenuContentCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'

interface ICPProps {
	reportType: ReportTypeEnum
}

/**
 */
export function ScreenContentSlaReportCP(props: ICPProps): JSX.Element {
	const [formValidator, setFormValidator] = useState<CommonReportsFilterFormModel>()
	const formStateManager = useFormStateManager<CommonReportsFilterFormModel>(formValidator)

	const currentDoctorGroup = useAppSelector((state) => state.doctorGroup)
	const loggedUser = useAppSelector((state) => state.auth.user)

	const [shouldLoad, setShouldLoad] = useState<number>(TableUtils.getReloadNumber())

	useEffect(init, [props.reportType])

	/**
	 */
	function init(): void {
		setFormValidator(
			new CommonReportsFilterFormModel({
				dateRange: {
					beginDate: moment().startOf('month').toDate(),
					endDate: new Date(),
				},
				doctorGroupCode: currentDoctorGroup.code ?? undefined,
			}),
		)
	}

	if (!formStateManager.getFormValues()) return <></>

	return (
		<>
			<HeaderCP title={'Análise de SLA'} />
			<FiltersReportCommonCP
				reportType={props.reportType}
				filterFormStateManager={formStateManager}
				onFilter={() => setShouldLoad(TableUtils.getReloadNumber())}
				fieldsConfig={{
					hideDoctorFilter: props.reportType !== ReportTypeEnum.DOCTOR_GROUP_REPORT,
				}}
			/>

			<LayoutSubmenuContentCP>
				<WidgetsRowCP>
					<CardWidgetOnlyValuesShouldLoadCP
						margin={{ top: 20 }}
						main={{
							title: 'Laudos entregues no Prazo',
							valueMask: '%',
							secondaryValueMask: '%',
							icon: <IconICP iconName={'check-circle'} theme={'twoTone'} color={ThemeProjectVX.success} size={30} />,
							help: 'Percentual de laudos entregues no prazo acordado',
						}}
						requestConfigGetter={WidgetsRequests.medicalReportSlaSummary(
							CommonReportsFilterFormModelConverter.formatDto(
								loggedUser,
								currentDoctorGroup,
								props.reportType,
								formStateManager.getFormValues(),
							),
						)}
						shouldLoad={shouldLoad}
						style={{ maxWidth: '100%' }}
					/>

					<WidgetMedicalReportSlaByUrgencyCP reportType={props.reportType} formStateManager={formStateManager} shouldLoad={shouldLoad} />
				</WidgetsRowCP>

				<TableExamSlaAnalysisCP reportType={props.reportType} reloadTable={shouldLoad} filterStateManager={formStateManager} />
			</LayoutSubmenuContentCP>
		</>
	)
}
