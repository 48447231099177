import { useCurrentEditor } from '@tiptap/react'
import { useAppSelector } from 'app/redux/hook'
import { TipTapButtonUtils } from 'submodules/nerit-framework-ui/common/components/wysiwyg/tip-tap/toolbar-tip-tap/toolbar-tip-tap-button/ToolbarTipTapButtonUtils'
import * as S from './ToolbarTipTapStyles'

export function ToolbarTipTapICP(): JSX.Element {
	const loggedUser = useAppSelector((state) => state.auth.user)
	const { editor } = useCurrentEditor()

	if (!editor || !loggedUser) {
		return <></>
	}

	return (
		<S.TipTapToolbar className="ToolbarContainer">
			<div className="Toolbar">
				{TipTapButtonUtils.getTipTapButtons({
					editor,
					loggedUser,
				}).map((button, index) => {
					return button.divider ? <div key={index} className="divider"></div> : button.button?.[0]()
				})}
			</div>
		</S.TipTapToolbar>
	)
}
