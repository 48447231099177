import styled from 'styled-components'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	hideIcon?: boolean
	size?: number
}

/**
 * Icone de achado critico no exame.
 */
export function IconCriticalExamCP(props: ICPProps): JSX.Element {
	if (props.hideIcon) return <></>

	return (
		<TooltipCP text={'Achado crítico!'} showSpan={true}>
			<WrapperSCP>
				<IconICP size={props.size ?? 16} iconName={'warning'} />
			</WrapperSCP>
		</TooltipCP>
	)
}

const WrapperSCP = styled.div`
	color: ${ThemeFrameworkCommon.browserDefaultBackgroundDark};
`
