import { PdfViewerContentICP } from 'submodules/nerit-framework-ui/common/components/file-preview/pdf-viewer/inner/PdfViewerContentICP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'

interface IPdfViewerCPProps {
	file: any
	paginationType: 'scroll' | 'paginator'
	viewType: 'inline' | 'modal'
	visible?: boolean
	onSetVisibleFalse?: () => void
	onLoadDocument?: () => void
	width?: string
}

/**
 * COMPONENTE: Visualizador de PDF
 */
export function PdfViewerCP(props: IPdfViewerCPProps): JSX.Element | null {
	return (
		<>
			{props.viewType === 'inline' && (
				<PdfViewerContentICP width={props.width} file={props.file} paginationType={props.paginationType} onLoadDocument={props.onLoadDocument} />
			)}

			{props.viewType === 'modal' && (
				<ModalCP visible={props.visible} onClose={props.onSetVisibleFalse} noFooter={true} top={5} width={800}>
					<PdfViewerContentICP file={props.file} paginationType={props.paginationType} onLoadDocument={props.onLoadDocument} />
				</ModalCP>
			)}
		</>
	)
}
