import { RoundedWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/RoundedWrapperCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'

interface ICPProps {
	color: string
	total: number
}

/**
 * Mostra a Home especifica para cara tipo de usuario.
 */
export function ColumnCountSlaICP(props: ICPProps): JSX.Element {
	return (
		<FlexCP justify={'center'}>
			<RoundedWrapperCP text={MaskUtils.applyNumberMask(props.total, 0)} color={props.color} size={30} />
		</FlexCP>
	)
}
