import { Icon as LegacyIcon } from '@ant-design/compatible'
import { IIconProps } from 'submodules/nerit-framework-ui/common/components/icon/inner/IIconProps'

export const MailIconCP = (props: IIconProps): JSX.Element => {
	return (
		<LegacyIcon
			type={'mail'}
			style={{
				fill: props.color ?? 'inherit',
				color: props.color ?? 'inherit',
				fontSize: props.size ?? '20px',
				...props.style,
			}}
		/>
	)
}
