import { notification } from 'antd'
import { NotificationPlacement } from 'antd/es/notification/interface'
import { ArgsProps } from 'antd/lib/notification'

/**
 * Gerencia exibicao de notificacoes, no sistema.
 */
export class NotificationHelper {
	private static readonly _DEFAULT_DURATION = 4.5
	private static readonly _DEFAULT_PLACEMENT = 'topRight' as NotificationPlacement

	static DEFAULT_SUCCESS_SAVE_MESSAGE = 'Salvo com sucesso'
	static DEFAULT_ERROR_SAVE_MESSAGE = 'Ocorreu algum erro ao salvar'
	static DEFAULT_ERROR_GET_MESSAGE = 'Ocorreu algum erro ao buscar informações'
	static DEFAULT_SUCCESS_DELETE_MESSAGE = 'Removido com sucesso'
	static DEFAULT_ERROR_DELETE_MESSAGE = 'Ocorreu algum erro ao remover'
	static DEFAULT_CONFIRM_DELETE_MESSAGE = 'Você tem certeza que deseja remover?'

	private constructor() {}

	/** Gera notificacao de sucesso. */
	static success(title: string, message?: string, duration?: number, placement?: NotificationPlacement): void {
		this.showNotification(notification.success, title, message, duration, placement)
	}

	/** Gera notificacao de info. */
	static info(title: string, message?: string, duration?: number, placement?: NotificationPlacement): void {
		this.showNotification(notification.info, title, message, duration, placement)
	}

	/** Gera notificacao de alerta. */
	static warning(title: string, message?: string, duration?: number, placement?: NotificationPlacement): void {
		this.showNotification(notification.warning, title, message, duration, placement)
	}

	/** Gera notificacao de falha. */
	static error(title: string, message?: string, duration?: number, placement?: NotificationPlacement): void {
		this.showNotification(notification.error, title, message, duration, placement)
	}

	/**
	 * Gera 01 notificacao generica.
	 */
	private static showNotification(
		method: (args: ArgsProps) => void,
		title: string,
		message?: string,
		duration?: number,
		placement?: NotificationPlacement,
	): void {
		if (!!message && typeof message !== 'string') {
			console.warn('_showNotification invocado sem msg string', message)
			return
		}

		method({
			message: title,
			description: message,
			duration: duration ?? this._DEFAULT_DURATION,
			placement: placement ?? this._DEFAULT_PLACEMENT,
			style: {
				fontFamily: 'Montserrat, sans-serif',
			},
		})
	}
}
