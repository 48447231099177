import { useAppSelector } from 'app/redux/hook'
import { FiltersReportCommonCP } from 'modules/reports/components/filters-report-common/FiltersReportCommonCP'
import { CommonReportsFilterFormModel } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModel'
import { TableExamsDoneCP } from 'modules/reports/components/table-exams-done/TableExamsDoneCP'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { ReportTypeEnum } from 'submodules/beerads-sdk/services/dashboard/dashboard/types/ReportTypeEnum'
import { LayoutSubmenuContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/content/LayoutSubmenuContentCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'

interface ICPProps {
	reportType: ReportTypeEnum
}

/**
 */
export function ScreenContentExamsDoneCP(props: ICPProps): JSX.Element {
	const currentDoctorGroup = useAppSelector((state) => state.doctorGroup)

	const [formValidator] = useState<CommonReportsFilterFormModel>(
		new CommonReportsFilterFormModel({
			dateRange: { beginDate: moment().startOf('M').toDate(), endDate: new Date() },
			doctorGroupCode: currentDoctorGroup.code ?? undefined,
			shouldConsiderMedicalReportDate: true,
		}),
	)
	const formStateManager = useFormStateManager<CommonReportsFilterFormModel>(formValidator)

	const [loadList, setLoadList] = useState<number>(0)
	const [isLoadingList, setIsLoadingList] = useState<boolean>(false)

	useEffect(init, [props.reportType])

	/**
	 * Inicializa.
	 */
	function init(): void {
		setLoadList(TableUtils.getReloadNumber())
	}

	return (
		<>
			<HeaderCP title={'Exames Laudados'} />
			<FiltersReportCommonCP
				reportType={props.reportType}
				filterFormStateManager={formStateManager}
				isLoading={isLoadingList}
				onFilter={init}
				fieldsConfig={{
					hideDoctorFilter: props.reportType !== ReportTypeEnum.DOCTOR_GROUP_REPORT,
				}}
			/>

			<LayoutSubmenuContentCP>
				<TableExamsDoneCP
					reportType={props.reportType}
					filterStateManager={formStateManager}
					reloadTable={loadList}
					onLoading={setIsLoadingList}
				/>
			</LayoutSubmenuContentCP>
		</>
	)
}
