import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { WelcomeContentSelectDoctorGroupCP } from 'app/components/welcome-content-select-doctor-group/WelcomeContentSelectDoctorGroupCP'
import { useAppSelector } from 'app/redux/hook'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { TableAuditsCP } from 'modules/quality-assurance/components/table-audits/TableAuditsCP'
import { HeaderScreenDoctorAuditsCP } from 'modules/quality-assurance/screens/screen-doctor-audits/header/HeaderScreenDoctorAuditsCP'
import { useEffect, useState } from 'react'
import { AuditStatusEnum } from 'submodules/beerads-sdk/services/exams/audits/enums/AuditStatusEnum'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'

/**
 */
export function ScreenDoctorAudits(): JSX.Element {
	const [status, setStatus] = useState<AuditStatusEnum>(AuditStatusEnum.AWAITING)
	const [reload, setReload] = useState<number>(0)

	const loggedUser = useAppSelector((state) => state.auth.user)
	const currentDoctorGroup = useAppSelector((state) => state.doctorGroup)

	useEffect(() => setReload(TableUtils.getReloadNumber), [status])

	if (!currentDoctorGroup.code) return <WelcomeContentSelectDoctorGroupCP />

	return (
		<AccessControlCP
			permission={{ byHasAnyDoctorGroupRelationPermission: ['isAuditor'] }}
			redirect={{ shouldRedirect: true, redirectTo: PrivateUserRouter.HOME }}
		>
			<LayoutCP
				titleBrowser={'Minhas Auditorias'}
				header={<HeaderScreenDoctorAuditsCP status={status} onChangeAuditStatus={setStatus} />}
				content={
					<ContentCP overflowVertical={true}>
						<TableAuditsCP
							reloadTable={reload}
							filters={{
								doctorAuditorCode: loggedUser?.code,
								statuses: [status],
							}}
						/>
					</ContentCP>
				}
			/>
		</AccessControlCP>
	)
}
