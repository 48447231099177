import { ThemeProjectVX } from 'config/theme/project/white-labels/ThemeProjectVX'
import {
	TemplateHistoryStatusEnum,
	TemplateHistoryStatusLabelEnum,
} from 'submodules/beerads-sdk/services/templates/history-templates/enums/TemplateHistoryStatusEnum'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'

interface ICPProps {
	status: TemplateHistoryStatusEnum
}

export function TagsTemplateStatusCP(props: ICPProps): JSX.Element {
	function getTagColor(status: TemplateHistoryStatusEnum): string {
		switch (status) {
			case TemplateHistoryStatusEnum.ACTIVE:
				return ThemeProjectVX.success
			case TemplateHistoryStatusEnum.AWAITING_APPROVAL:
				return ThemeProjectVX.warning

			case TemplateHistoryStatusEnum.REPROVED:
				return ThemeProjectVX.error
		}
	}

	return <TagCP color={getTagColor(props.status)} content={TemplateHistoryStatusLabelEnum[props.status]} />
}
