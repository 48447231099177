import { useAppSelector } from 'app/redux/hook'
import { FiltersReportCommonCP } from 'modules/reports/components/filters-report-common/FiltersReportCommonCP'
import { CommonReportsFilterFormModel } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModel'
import { TableExamRectificationAnalysisCP } from 'modules/reports/components/table-exams-rectification-analysis/TableExamRectificationAnalysisCP'
import { WidgetRectificationAnalysisCP } from 'modules/reports/components/widgets/widget-rectification-analysis/WidgetRectificationAnalysisCP'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import { ReportTypeEnum } from 'submodules/beerads-sdk/services/dashboard/dashboard/types/ReportTypeEnum'
import { LayoutSubmenuContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/content/LayoutSubmenuContentCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'

interface ICPProps {
	reportType: ReportTypeEnum
}

/**
 */
export function ScreenContentRectificationReportCP(props: ICPProps): JSX.Element {
	const [formValidator, setFormValidator] = useState<CommonReportsFilterFormModel>()
	const formStateManager = useFormStateManager<CommonReportsFilterFormModel>(formValidator)

	const currentDoctorGroup = useAppSelector((state) => state.doctorGroup)

	const [shouldLoad, setShouldLoad] = useState<number>(TableUtils.getReloadNumber())

	useEffect(init, [props.reportType])

	/**
	 */
	function init(): void {
		setFormValidator(
			new CommonReportsFilterFormModel({
				dateRange: {
					beginDate: moment().startOf('month').toDate(),
					endDate: new Date(),
				},
				doctorGroupCode: currentDoctorGroup.code ?? undefined,
			}),
		)
	}

	if (!formStateManager.getFormValues()) return <></>

	return (
		<>
			<HeaderCP title={'Laudos Conformes - Classificação de Retificações'} />
			<FiltersReportCommonCP
				reportType={props.reportType}
				filterFormStateManager={formStateManager}
				onFilter={() => setShouldLoad(TableUtils.getReloadNumber())}
				fieldsConfig={{
					hideDoctorFilter: props.reportType !== ReportTypeEnum.DOCTOR_GROUP_REPORT,
					hideDateSwitch: true,
					showReasonFielter: true,
				}}
			/>

			<LayoutSubmenuContentCP>
				<WidgetRectificationAnalysisCP reportType={props.reportType} shouldLoad={shouldLoad} formStateManager={formStateManager} />

				<TableExamRectificationAnalysisCP reportType={props.reportType} reloadTable={shouldLoad} filterStateManager={formStateManager} />
			</LayoutSubmenuContentCP>
		</>
	)
}
