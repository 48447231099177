import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { SelectDoctorGroupPeopleCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-people/SelectDoctorGroupPeopleCP'
import { PendingAnalysisExamFormModel } from 'modules/exams/components/exam-analysis/drawer-exam-pending-analysis/model/PendingAnalysisExamFormModel'
import { useState } from 'react'
import styled from 'styled-components'
import { UserDoctorGroupStatusEnum } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/enums/UserDoctorGroupStatusEnum'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
	examCode: number
	doctorGroupCode: number
	formStateManager: IFormStateManager<PendingAnalysisExamFormModel>
	onSave: (sendTo: ExamStatusEnum.AWAITING | ExamStatusEnum.RETURNED | ExamStatusEnum.IN_PROGRESS) => void
}

/**
 * Drawer para fazer analise administrativa de um exame.
 */
export function ExamPendingAnalysisFooterICP(props: ICPProps): JSX.Element {
	const [isDoctorPopoverVisible, setIsDoctorPopoverVisible] = useState<boolean>(false)

	return (
		<>
			<ButtonCP size={'small'} icon={'arrow-left'} onClick={() => props.onSave(ExamStatusEnum.RETURNED)}>
				Devolver para Unidade Hospitalar
			</ButtonCP>

			<FlexCP>
				<PopOverCP
					visible={isDoctorPopoverVisible}
					onClose={() => setIsDoctorPopoverVisible(false)}
					trigger={'click'}
					title={'Atribuição para médico'}
					content={
						<PopoverContentSCP>
							<SelectDoctorGroupPeopleCP
								doctorGroupCode={props.doctorGroupCode}
								formStateManager={props.formStateManager}
								formField={'doctorCode'}
								role={UserTypeEnum.DOCTOR}
								filters={{
									status: [UserDoctorGroupStatusEnum.ACTIVE],
								}}
							/>
							<HelpCP text={'Selecione um médico para atribuir ou deixe em branco para ir para fila'} type={'text'} />

							<FlexCP justify={'space-between'} margin={{ top: 20 }}>
								<ButtonCP onClick={() => setIsDoctorPopoverVisible(false)}>Cancelar</ButtonCP>
								<ButtonCP
									type={'primary'}
									onClick={() => {
										setIsDoctorPopoverVisible(false)
										props.onSave(ExamStatusEnum.AWAITING)
									}}
								>
									Salvar
								</ButtonCP>
							</FlexCP>
						</PopoverContentSCP>
					}
				>
					<ButtonCP size={'small'} icon={'arrow-right'} onClick={() => setIsDoctorPopoverVisible(true)}>
						Devolver para Médico
					</ButtonCP>
				</PopOverCP>

				<AccessControlCP permission={{ byUserType: UserTypeEnum.DOCTOR }}>
					<ButtonCP type={'primary'} size={'small'} onClick={() => props.onSave(ExamStatusEnum.IN_PROGRESS)} icon={'edit'} marginLeft={5}>
						Laudar
					</ButtonCP>
				</AccessControlCP>
			</FlexCP>
		</>
	)
}

const PopoverContentSCP = styled.div`
	width: 300px;
`
