import { ThemeProject } from 'config/theme/project/ThemeProject'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

export interface IScpProps {
	hideArrow?: boolean
	padding?: string
}

export const WrapperSCP = styled.div<IScpProps>`
	position: relative;

	.ant-menu-submenu > .ant-menu {
		background: ${ThemeProject.primary200} !important;
	}

	.ant-menu-submenu-title {
		font-weight: bold !important;
		color: ${ThemeFrameworkCommon.browserDefaultColorDark};
		padding: ${(props) => props.padding ?? undefined} !important;
	}

	.ant-menu-submenu-arrow {
		display: ${(props) => (!!props.hideArrow ? 'none' : undefined)};
	}
`
