import { DrawerExamPendingCP } from 'modules/exams/components/exams/drawer-exam-pending/DrawerExamPendingCP'
import { useEffect, useState } from 'react'
import { ExamsRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps extends BasicStyleWrapperCPProps {
	examCode: number
	examId: string
	clinicCode: number
	size?: ButtonSizeTP
	showIcon?: boolean
	hideLabel?: boolean
	tooltip?: string
	onUnarchive: () => void
}

/**
 */
export function ButtonUnarchiveExamCP(props: ICPProps): JSX.Element {
	const [isPendingExamDrawerVisible, setIsPendingExamDrawerVisible] = useState<boolean>(false)

	const archiveExamRequest = useRequest<void>('none')
	useEffect(onArchiveExamRequestChange, [archiveExamRequest.isAwaiting])

	/**
	 * Retorno da requisicao.
	 */
	function onArchiveExamRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				archiveExamRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		setIsPendingExamDrawerVisible(true)
	}

	return (
		<>
			<ButtonCP
				onClick={() => archiveExamRequest.runRequest(ExamsRequests.unarchiveExam(props.examCode))}
				loading={archiveExamRequest.isAwaiting}
				type={'primary'}
				danger
				size={props.size}
				icon={props.showIcon ? 'import' : undefined}
				marginTop={props.margin?.top}
				marginBottom={props.margin?.bottom}
				marginLeft={props.margin?.left}
				marginRight={props.margin?.right}
				confirmMsg={'Você tem certeza que deseja tirar do arquivo esse exame e voltar para pendência?'}
				tooltip={props.tooltip}
			>
				{props.hideLabel ? undefined : 'Desarquivar'}
			</ButtonCP>

			<DrawerExamPendingCP
				examCode={props.examCode}
				examId={props.examId}
				clinicCode={props.clinicCode}
				visible={isPendingExamDrawerVisible}
				onCancel={() => setIsPendingExamDrawerVisible(false)}
				onSave={props.onUnarchive}
			/>
		</>
	)
}
