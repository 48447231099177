export enum NotificationTypeEnum {
	CRITICAL_EXAM = 'CRITICAL_EXAM',
	INTEGRATION_FAILURE = 'INTEGRATION_FAILURE',
	QUEUE_INTEGRATION_ERROR = 'QUEUE_INTEGRATION_ERROR',
}

export enum NotificationTypeLabelEnum {
	CRITICAL_EXAM = 'Notificação de Achado Crítico',
	INTEGRATION_FAILURE = 'Notificação de Falha na Integração do Laudo',
	QUEUE_INTEGRATION_ERROR = 'Notificação de falha ao analisar as pendências do exame. Clique no botão abaixo para verificar na plataforma VxPacs',
}
