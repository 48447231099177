import { faBookMedical, faDownload, faHistory, faPlayCircle, faRecordVinyl } from '@fortawesome/free-solid-svg-icons'
import { AttachmentIconCP } from '_old/main/common/components/icons/AttachmentIconCP'
import { ExamIconCP } from '_old/main/common/components/icons/ExamIconCP'
import { NotesIconCP } from '_old/main/common/components/icons/NotesIconCP'
import { ReportMaskIconCP } from '_old/main/common/components/icons/ReportMaskIconCP'
import { useAppSelector } from 'app/redux/hook'
import { AppUtils } from 'app/utils/AppUtils'
import { AppConfig } from 'config/AppConfig'
import { RoutingHelper } from 'config/RoutingHelper'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { ClinicPermissionUtils } from 'modules/auth/permissions/ClinicPermissionUtils'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { AttachmentViewerCP } from 'modules/exams/components/exams/attachment-viewer/AttachmentViewerCP'
import { ExamNotesCP } from 'modules/exams/components/exams/exam-notes/ExamNotesCP'
import { OsirixIconCP } from 'modules/exams/components/exams/icons/IconOsirixCP'
import { IconWeasisCP } from 'modules/exams/components/exams/icons/IconWeasisCP'
import { TabContentExamMainDataICP } from 'modules/exams/components/exams/tabs-exam-details/tabs/TabContentExamMainDataICP'
import { UploadExamAttachmentsCP } from 'modules/exams/components/exams/upload-exam-attachments/UploadExamAttachmentsCP'
import { ButtonFloatingMedicalReportICP } from 'modules/exams/components/medical-report/buttons-floating-medical-report/inner/ButtonFloatingMedicalReportICP'
import { TableMedicalReportHistoryCP } from 'modules/exams/components/medical-report/table-medical-report-history/TableMedicalReportHistoryCP'
import { ExamUploadFileTypeEnum } from 'modules/exams/screens/screen-exam-upload-files/inner/ExamUploadFileTypeEnum'
import { MedicalReportFormModel } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModel'
import { TablePatientExamsCP } from 'modules/person/components/patient/table-patient-exams/TablePatientExamsCP'
import { ModalRecordedVideoCP } from 'modules/quality-assurance/components/modal-recorded-video/ModalRecordedVideoCP'
import { TableTemplatesForMedicalReportCP } from 'modules/template/components/table-templates-for-medical-report/TableTemplatesForMedicalReportCP'
import { useEffect, useState } from 'react'
import { isSafari } from 'react-device-detect'
import { useReactMediaRecorder } from 'react-media-recorder'
import styled from 'styled-components'
import { ExamAttachmentsRequests } from 'submodules/beerads-sdk/services/exams/attachments/ExamAttachmentsRequests'
import { ExamsDownloadsRequests } from 'submodules/beerads-sdk/services/exams/exams-downloads/ExamsDownloadsRequests'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ExamImageMetadataVersionEnum } from 'submodules/beerads-sdk/services/exams/exams/types/ExamImageMetadataVersionEnum'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { DrawerCP } from 'submodules/nerit-framework-ui/common/components/drawer/DrawerCP'
import { FontAwsomeIconCP } from 'submodules/nerit-framework-ui/common/components/icon/FontAwsomeIconCP'
import { LoadingIconCP } from 'submodules/nerit-framework-ui/common/components/icon/icons/LoadingIconCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { LogoCP } from 'submodules/nerit-framework-ui/common/components/logo/LogoCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { UiUtils } from 'submodules/nerit-framework-ui/common/utils/UiUtils'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { FileDataTP } from 'submodules/nerit-framework-utils/utils/types/FileDataTP'

interface ICPProps {
	exam: ExamResponseDTO
	onEmbedViewer?: () => void
	formStateManager?: IFormStateManager<MedicalReportFormModel>
	buttonsConfig?: {
		hideTemplates?: boolean
	}
	searchExamDto?: ExamSearchRequestDTO
}

/**
 * Componente para botões de ação complementar na tela de laudos.
 */
export function ButtonsFloatingMedicalReportCP(props: ICPProps): JSX.Element {
	const authData = useAppSelector((selector) => selector.auth)

	// Se tiver analise administravia, somente usuario da clinica
	let canUserUploadAndRemove = true

	if (props.exam.doctorGroup?.config?.hasAdministrativeAnalysis)
		canUserUploadAndRemove = ClinicPermissionUtils.hasAnyPermissionsInClinic(props.exam.clinic.code, ['isAdmin', 'isUser'], authData.user)

	const [showDrawer, setShowDrawer] = useState(false)
	const [drawerContent, setDrawerContent] = useState<JSX.Element>()
	const [drawerTitle, setDrawerTitle] = useState<JSX.Element | string>('')

	const [shouldShowNonDicomView, setShouldShowNonDicomViewer] = useState<boolean>(false)
	const [loadedNonDicomExamImages, setLoadedNonDicomExamImages] = useState<FileDataTP[]>()

	const getNonDicomExamImagesRequest = useRequest<ListResponseDTO<FileDataTP>>()
	useEffect(onGetNonDicomExamImagesRequestChange, [getNonDicomExamImagesRequest.isAwaiting])

	const getDicomExamImageLinkRequest = useRequest<string>()
	useEffect(onGetDicomExamImageLinkRequestRequestChange, [getDicomExamImageLinkRequest.isAwaiting])

	/**
	 */
	function onGetNonDicomExamImagesRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getNonDicomExamImagesRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = getNonDicomExamImagesRequest.responseData?.list
		if (ArrayUtils.isEmpty(result)) {
			NotificationHelper.warning('Ops', 'Nenhum arquivo encontrado')
			return
		}

		setShouldShowNonDicomViewer(true)
		setLoadedNonDicomExamImages(result)
	}

	/**
	 */
	function onGetDicomExamImageLinkRequestRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getDicomExamImageLinkRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = getDicomExamImageLinkRequest.responseData!
		NeritFrameworkRoutingHelper.downloadFile(result)
	}

	/**
	 * Ao clicar em um botato que tem conteudo de drawer
	 */
	function onDrawerContentClick(title: string, content: JSX.Element): void {
		setDrawerTitle(title)
		setDrawerContent(content)
		setShowDrawer(true)
	}

	/**
	 * Gravação de tela
	 */
	const { status, startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder({ screen: true })
	const [showRecordedVideoModal, setShowRecordedVideoModal] = useState(false)

	useEffect(() => {
		if (status === 'stopped' && mediaBlobUrl) {
			setShowRecordedVideoModal(true)
		}
	}, [status, mediaBlobUrl])

	const toggleRecording = (): void => {
		if (status === 'recording') {
			stopRecording()
		} else {
			startRecording()
		}
	}

	const handleCloseModal = (): void => {
		setShowRecordedVideoModal(false)
	}

	return (
		<>
			{mediaBlobUrl && (
				<ModalRecordedVideoCP visible={showRecordedVideoModal} onClose={handleCloseModal} examId={props.exam.id} mediaBlobUrl={mediaBlobUrl} />
			)}

			<ContainerSCP>
				<ButtonFloatingMedicalReportICP
					itens={[
						{
							icon: (
								<>
									<LogoCP imgUrl={ThemeProject.logoMenuUrl} marginTop={0} marginBottom={0} height={22} />
									<ViwerBtnSCP>
										<IconICP iconName={'select'} size={10} />
									</ViwerBtnSCP>
								</>
							),
							onClick: () =>
								window.open(
									RoutingHelper.getBeeRadsViewerUrl(
										{
											token: authData.token,
											examId: props.exam.id,
											examDescription: props.exam.description,
											patientName: props.exam.patient.name,
											viewBy: 'user',
										},
										props.exam.imageData.metadata,
									),
								),
							text: `${AppUtils.getDomainName()} Viewer (nova aba)`,
							hide: !!props.exam.imageData.metadata?.version && props.exam.imageData.metadata?.version === ExamImageMetadataVersionEnum.V1,
						},
						{
							icon: (
								<>
									<LogoCP imgUrl={ThemeProject.logoMenuUrl} marginTop={0} marginBottom={0} height={22} />
									<ViwerBtnSCP>
										<IconICP iconName={'layout'} size={10} />
									</ViwerBtnSCP>
								</>
							),
							onClick: props.onEmbedViewer,
							text: `${AppUtils.getDomainName()}  Viewer (mesma tela)`,
							hide:
								(!!props.exam.imageData.metadata?.version && props.exam.imageData.metadata?.version === ExamImageMetadataVersionEnum.V1) ||
								!props.onEmbedViewer,
						},
						{
							icon: <OsirixIconCP size={28} />,
							onClick: async () => window.open(RoutingHelper.getOsirixViewerUrl([props.exam.id], authData), '_self'),
							text: `Ver no Osirix ${isSafari ? '' : '(acesse pelo safari)'}`,
							disabled: !isSafari,
							hide: !props.exam.imageData.isDicom || !UiUtils.testOS('mac'),
						},
						{
							icon: <IconWeasisCP size={28} />,
							onClick: async () => window.open(RoutingHelper.getWeasisViewerUrl(props.exam.id, authData), '_self'),
							text: 'Ver no Weasis',
						},
						{
							icon: getDicomExamImageLinkRequest.isAwaiting ? <LoadingIconCP /> : <FontAwsomeIconCP type={faDownload} size={'lg'} />,
							onClick: () =>
								getDicomExamImageLinkRequest.runRequest(
									ExamsDownloadsRequests.getExamImagesLink({ examId: props.exam.id }, AppConfig.getInstance().api2BaseUrl),
								),
							text: 'Download do Exame',
							disabled: getDicomExamImageLinkRequest.isAwaiting,
							hide: !props.exam.imageData.isDicom,
							lastFromGroup: true,
						},
						{
							icon: getNonDicomExamImagesRequest.isAwaiting ? <LoadingIconCP /> : <IconICP iconName={'file-image'} size={22} />,
							onClick: () =>
								getNonDicomExamImagesRequest.runRequest(ExamAttachmentsRequests.searchAttachments(props.exam.id, { isExam: true })),
							text: 'Ver Imagens do Exame',
							hide: props.exam.imageData.isDicom,
							lastFromGroup: true,
						},
						{
							icon: <ReportMaskIconCP size={22} />,
							onClick: () =>
								onDrawerContentClick(
									'Templates',
									<TableTemplatesForMedicalReportCP
										examCode={props.exam.code}
										onRowClick={(template) => {
											if (!!props.formStateManager)
												props.formStateManager.changeFieldValue('selectedHtmlContentToReplace', template.contentHtml)
											setShowDrawer(false)
										}}
									/>,
								),
							text: 'Templates',
							hide: props.exam.modality === ExamModalityEnum.CR_OIT || props.buttonsConfig?.hideTemplates,
						},
						{
							icon: <FontAwsomeIconCP type={faBookMedical} size={'lg'} />,
							onClick: () =>
								onDrawerContentClick(
									'Histórico do Paciente',
									<TablePatientExamsCP
										exam={props.exam}
										onCopyMedicalReport={(jsonContent) => {
											if (!!props.formStateManager)
												props.formStateManager.changeFieldValue('selectedHtmlContentToReplace', jsonContent)
											setShowDrawer(false)
										}}
										searchExamDto={props.searchExamDto}
									/>,
								),
							text: 'Histórico do Paciente',
						},
						{
							icon: <FontAwsomeIconCP type={faHistory} size={'lg'} />,
							onClick: () =>
								onDrawerContentClick(
									'Histórico do Laudo',
									<TableMedicalReportHistoryCP
										examCode={props.exam.code}
										examModality={props.exam.modality}
										onCopy={(jsonContent) => {
											if (!!props.formStateManager)
												props.formStateManager.changeFieldValue('selectedHtmlContentToReplace', jsonContent)
											setShowDrawer(false)
										}}
									/>,
								),
							text: 'Histórico do Laudo',
							lastFromGroup: true,
						},
						{
							icon: <ExamIconCP />,
							onClick: () => onDrawerContentClick('Dados do Exame', <TabContentExamMainDataICP exam={props.exam} />),
							text: 'Dados do Exame',
						},
						{
							icon: <AttachmentIconCP />,
							onClick: () =>
								onDrawerContentClick(
									'Anexos',
									<UploadExamAttachmentsCP
										examId={props.exam.id}
										showQrCode={true}
										type={ExamUploadFileTypeEnum.ATTACHMENT}
										permissions={{ canUpload: canUserUploadAndRemove, canRemove: canUserUploadAndRemove, canShowFileList: true }}
									/>,
								),
							text: 'Anexos',
						},
						{
							icon: <NotesIconCP />,
							onClick: () =>
								onDrawerContentClick('Notas', <ExamNotesCP examCode={props.exam.code} permissions={{ canAdd: canUserUploadAndRemove }} />),
							text: 'Notas',
						},
						{
							icon: (
								<>
									<RecordingSCP className={status === 'recording' ? 'blinking' : ''}>
										<FontAwsomeIconCP
											color={status === 'recording' ? 'red' : 'inherit'}
											type={status === 'recording' ? faRecordVinyl : faPlayCircle}
											size={'lg'}
										/>
									</RecordingSCP>
								</>
							),
							onClick: toggleRecording,
							text: status === 'recording' ? 'Parar Gravação' : 'Iniciar Gravação',
							hide: !DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(authData.user, 142, ['isAuditor']),
						},
					]}
				/>
			</ContainerSCP>

			<DrawerCP title={drawerTitle} onClose={() => setShowDrawer(false)} visible={showDrawer} width={600}>
				<BasicStyleWrapperCP margin={{ right: 30 }}>{drawerContent}</BasicStyleWrapperCP>
			</DrawerCP>

			{shouldShowNonDicomView && !!loadedNonDicomExamImages && (
				<AttachmentViewerCP attachments={loadedNonDicomExamImages} onClose={() => setShouldShowNonDicomViewer(false)} />
			)}
		</>
	)
}

const ContainerSCP = styled.div`
	height: 60%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	position: fixed;
	top: 20%;
	right: 0;
	z-index: 99999;
`

const ViwerBtnSCP = styled.div`
	position: absolute;
	top: 5px;
	right: 5px;
`

const RecordingSCP = styled.div`
	@keyframes blink {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}

	&.blinking {
		animation: blink 1s infinite alternate;
	}
`
