import { Color } from '@tiptap/extension-color'
import { HardBreak } from '@tiptap/extension-hard-break'
import { ListItem } from '@tiptap/extension-list-item'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import { TextAlign } from '@tiptap/extension-text-align'
import { TextStyle } from '@tiptap/extension-text-style'
import { Underline } from '@tiptap/extension-underline'
import { type AnyExtension } from '@tiptap/react'
import { StarterKit } from '@tiptap/starter-kit'
import { useMemo } from 'react'

export function useEditorDefaultExtensions(): AnyExtension[] {
	return useMemo(() => {
		return [
			Color.configure({ types: [TextStyle.name, ListItem.name] }),
			StarterKit.configure({
				bulletList: {
					keepMarks: true,
					keepAttributes: false,
				},
				orderedList: {
					keepMarks: true,
					keepAttributes: false,
				},
			}),
			TextAlign.configure({
				types: ['heading', 'paragraph'],
			}),
			Table.configure({
				resizable: true,
				HTMLAttributes: {
					style: 'margin: 0 auto; border-collapse: collapse; min-width: 600px; max-width: 1000px;',
				},
			}),
			TableRow,
			TableHeader.configure({
				HTMLAttributes: {
					style: 'border: 1px solid #000; font-size: 0.9rem; padding: 0.25rem;',
				},
			}),
			TableCell.configure({
				HTMLAttributes: {
					style: 'border: 1px solid #000; font-size: 0.9rem; padding: 0.25rem;',
				},
			}),
			Underline,
			HardBreak,
		]
	}, [])
}
