import { TableUtils } from '@/submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { useAppSelector } from 'app/redux/hook'
import { MedicalTimetableFilterFormModel } from 'modules/medical-timetable/components/sider-medical-timetable-filters/inner/MedicalTimetableFilterFormModel'
import { TableMedicalTimetableDoctorUtils } from 'modules/medical-timetable/components/tables-medical-timetable/table-medical-timetable-doctor/inner/TableMedicalTimetableDoctorUtils'
import { IMedicalTimetableRecord } from 'modules/medical-timetable/components/tables-medical-timetable/types/IMedicalTimetableRecord'
import { IMedicalTimetableStatistics } from 'modules/medical-timetable/components/tables-medical-timetable/types/IMedicalTimetableStatistics'
import { useEffect, useState } from 'react'
import { MedicalTimetableRequests } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/MedicalTimetableRequests'
import { MedicalTimetableStructureSearchRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/MedicalTimetableStructureSearchRequestDTO'
import { DoctorOnMedicalTimetableResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/DoctorOnMedicalTimetableResponseDTO'
import { MedicalTimetableStructureResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/MedicalTimetableStructureResponseDTO'
import { MedicalTimetableIdEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableIdEnum'
import { MedicalTimetablePeriodEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetablePeriodEnum'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { TableCP } from 'submodules/nerit-framework-ui/common/components/table/TableCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'
import * as S from './TableMedicalTimetableDoctorStyles'

interface ICPProps {
	filterFormStateManager: IFormStateManager<MedicalTimetableFilterFormModel>
	reload?: number
}

/**
 */
export function TableMedicalTimetableDoctorCP(props: ICPProps): JSX.Element {
	const dataStatistics: IMedicalTimetableStatistics[] = []
	const currentDoctorGroup = useAppSelector((state) => state.doctorGroup)

	const [filteredStructure, setFilteredStructure] = useState<IMedicalTimetableRecord[]>([])
	const [isMounting, setIsMounting] = useState<boolean>(false)
	const [isDoctorsOnMedicalTimetableFilled, setIsDoctorsOnMedicalTimetableFilled] = useState<boolean>(false)

	const [medicalTimetableStructure, setMedicalTimetableStructure] = useState<MedicalTimetableStructureResponseDTO[]>([])
	const getMedicalTimetableStructureRequest = useRequest<ListResponseDTO<MedicalTimetableStructureResponseDTO>>()
	useEffect(onGetMedicalTimetableStructureRequestChange, [getMedicalTimetableStructureRequest.isAwaiting])

	const [doctorsOnMedicalTimetable, setDoctorsOnMedicalTimetable] = useState<DoctorOnMedicalTimetableResponseDTO[]>([])
	const getDoctorsOnMedicalTimetableRequest = useRequest<ListResponseDTO<DoctorOnMedicalTimetableResponseDTO>>()
	useEffect(onGetDoctorsOnMedicalTimetableRequest, [getDoctorsOnMedicalTimetableRequest.isAwaiting])

	useEffect(mountTableRecordsFromStructure, [
		props.filterFormStateManager.getFieldValue('id'),
		props.filterFormStateManager.getFieldValue('period'),
		props.filterFormStateManager.getFieldValue('modality'),
		props.filterFormStateManager.getFieldValue('groupBy'),
	])
	useEffect(init, [props.reload])

	/**
	 */
	function init(): void {
		if (!currentDoctorGroup.code) {
			NotificationHelper.error('Ops', 'Selecione um grupo de médicos primeiro')
			return
		}

		const filters: MedicalTimetableStructureSearchRequestDTO = {
			doctorGroupCode: currentDoctorGroup.code,
			date: props.filterFormStateManager.getFieldValue('beginDate'),
		}
		getMedicalTimetableStructureRequest.runRequest(MedicalTimetableRequests.getStructure(filters))
		getDoctorsOnMedicalTimetableRequest.runRequest(MedicalTimetableRequests.searchDoctorsOnMedicalTimetable(filters))
	}

	/**
	 */
	function onGetMedicalTimetableStructureRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getMedicalTimetableStructureRequest, 'Erro ao buscar estrutura da escala')) return

		const result = getMedicalTimetableStructureRequest.responseData!.list
		setMedicalTimetableStructure(result)
		mountTableRecordsFromStructure(result)
	}

	/**
	 */
	function onGetDoctorsOnMedicalTimetableRequest(): void {
		if (!RequestUtils.isValidRequestReturn(getDoctorsOnMedicalTimetableRequest, 'Erro ao buscar médicos da escala')) return

		setDoctorsOnMedicalTimetable(getDoctorsOnMedicalTimetableRequest.responseData!.list)
		setIsDoctorsOnMedicalTimetableFilled(true)
	}

	/**
	 */
	function mountTableRecordsFromStructure(structureData?: MedicalTimetableStructureResponseDTO[]): void {
		setIsMounting(true)

		const filteredIds: MedicalTimetableIdEnum[] = props.filterFormStateManager.getFieldValue('id') ?? []
		const filteredPeriods: MedicalTimetablePeriodEnum[] = props.filterFormStateManager.getFieldValue('period') ?? []
		const filteredModalities = props.filterFormStateManager.getFieldValue('modality') ?? []
		const filteredGroupBy = props.filterFormStateManager.getFieldValue('groupBy') ?? []

		// Se não tiver enviado do parametro utiliza a do escopo, ja carregada anteriormente
		if (!structureData) structureData = medicalTimetableStructure

		const generatedTableRecords: IMedicalTimetableRecord[] = []
		structureData.forEach((structure) => {
			// Skipa em casos de filtros
			if (
				(!ArrayUtils.isEmpty(filteredIds) && !filteredIds.includes(structure.id)) ||
				(!ArrayUtils.isEmpty(filteredPeriods) && !filteredPeriods.includes(structure.period)) ||
				(!ArrayUtils.isEmpty(filteredModalities) && !filteredModalities.includes(structure.modality)) ||
				(!ArrayUtils.isEmpty(filteredGroupBy) && !filteredGroupBy.includes(structure.groupBy))
			)
				return

			let maxNumberOfRows = 0
			structure.schedules.forEach((dayStructure) => {
				if (dayStructure.numberOfRows > maxNumberOfRows) maxNumberOfRows = dayStructure.numberOfRows
			})

			for (let i = 0; i < maxNumberOfRows; i++) {
				generatedTableRecords.push({
					code: structure.code,
					id: structure.id,
					period: structure.period,
					modality: structure.modality,
					groupBy: structure.groupBy,
				})
			}
		})

		// Precisa esperar para recarregar a tabela
		SystemUtils.sleep(10).then(() => {
			setIsMounting(false)
		})
		setFilteredStructure(generatedTableRecords)
	}

	// Aguarda montar a estrutura e carregar a requisição
	if (isMounting || !isDoctorsOnMedicalTimetableFilled) return <LoadingOverlayCP show={true} />

	return (
		<S.TableWrapper>
			<TableCP<IMedicalTimetableRecord>
				key={TableUtils.getReloadNumber()}
				data={filteredStructure}
				wrappedOnCard={true}
				bordered={true}
				loading={getMedicalTimetableStructureRequest.isAwaiting || getDoctorsOnMedicalTimetableRequest.isAwaiting || isMounting}
				columns={TableMedicalTimetableDoctorUtils.getColumns(
					filteredStructure,
					dataStatistics,
					medicalTimetableStructure,
					doctorsOnMedicalTimetable,
					props.filterFormStateManager.getFieldValue('beginDate'),
					init,
				)}
				rowKeyGetter={(record) => String(record.code)}
			/>
		</S.TableWrapper>
	)
}
