import { Collapse } from 'antd'
import { CollapseProps } from 'antd/lib'
import styled from 'styled-components'
import { CollapseCPPanelTP } from 'submodules/nerit-framework-ui/common/components/collapse/inner/CollapseCPPanelTP'
import { EmptyCP } from 'submodules/nerit-framework-ui/common/components/empty/EmptyCP'
import { LoadingCP } from 'submodules/nerit-framework-ui/common/components/loading/basic/LoadingCP'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'

type _ScpPropsTP = {
	overflow?: string
}

interface ICollapseCPProps extends _ScpPropsTP {
	panels?: CollapseCPPanelTP[]
	defaultPanelKeys?: string[]
	bordered?: boolean
	bgColor?: string
	loading?: boolean
	lockPanels?: boolean
	collapsible?: CollapseProps['collapsible']
	onSelectPanel?: (key: string | string[]) => void
}

/**
 * COMPONENTE
 * Painel com blocos de conteudo colapsavel.
 */
export function CollapseCP(props: ICollapseCPProps): JSX.Element {
	if (props.loading) return <LoadingCP show={props.loading} />

	return (
		<WrapperSCP overflow={props.overflow}>
			{!!props.panels && !ArrayUtils.isEmpty(props.panels) ? (
				<Collapse
					bordered={props.bordered ?? false}
					defaultActiveKey={props.defaultPanelKeys ?? []}
					style={{ backgroundColor: props.bgColor ?? 'transparent' }}
					onChange={props.onSelectPanel}
				>
					{props.panels.map((panel, i) => (
						<Collapse.Panel
							key={panel.key ?? `panel=${i}`}
							header={panel.title}
							extra={panel.extra}
							collapsible={props.collapsible}
							showArrow={!props.lockPanels}
							className={`nrt-panel-${i}`}
						>
							{panel.content}
						</Collapse.Panel>
					))}
				</Collapse>
			) : (
				<EmptyCP description={'Nenhum dado encontrado'} />
			)}
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div<_ScpPropsTP>`
	.ant-collapse {
		border-color: #e0e0e0 !important;
	}

	.ant-collapse-content {
		border-color: #e0e0e0 !important;
		overflow: ${(props) => props.overflow};
	}

	.ant-collapse-item {
		margin: 0;
		border-color: #e0e0e0 !important;

		.ant-collapse-header {
			display: flex;
			justify-content: space-between;
		}
	}

	.ant-collapse-item-disabled {
		.ant-collapse-header {
			cursor: auto !important;
		}
	}
`
