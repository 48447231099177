import { useAppSelector } from 'app/redux/hook'
import { FiltersAuditsCP } from 'modules/quality-assurance/components/filters-audits/FiltersAuditsCP'
import { AuditFilterFormModel } from 'modules/quality-assurance/components/filters-audits/form-model/AuditFilterFormModel'
import { AuditFilterFormModelConverter } from 'modules/quality-assurance/components/filters-audits/form-model/AuditFilterFormModelConverter'
import { ScreenWrapperQualityAssurance } from 'modules/quality-assurance/components/screen-wrapper-quality-assurance/ScreenWrapperQualityAssurance'
import { TableAuditsCP } from 'modules/quality-assurance/components/table-audits/TableAuditsCP'
import { WidgetCountAuditsOpenCP } from 'modules/reports/components/widgets/widget-count-audits-open/WidgetCountAuditsOpenCP'
import { useState } from 'react'
import { WidgetsRequests } from 'submodules/beerads-sdk/services/dashboard/widgets/WidgetsRequests'
import { AuditStatusEnum } from 'submodules/beerads-sdk/services/exams/audits/enums/AuditStatusEnum'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { CardWidgetAuditPercentage } from 'submodules/nerit-framework-ui/common/components/dashboard/card-widget-audit-percentage/CardWidgetAuditPercentage'
import { CardWidgetOnlyValuesShouldLoadCP } from 'submodules/nerit-framework-ui/common/components/dashboard/card-widget-only-values-should-load/CardWidgetOnlyValuesShouldLoadCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'

/**
 */
export function ScreenQualityAssuranceAudits(): JSX.Element {
	const [formValidator] = useState<AuditFilterFormModel>(new AuditFilterFormModel())
	const formStateManager = useFormStateManager<AuditFilterFormModel>(formValidator)

	const currentDoctorGroup = useAppSelector((state) => state.doctorGroup)

	const [loadList, setLoadList] = useState<number>(1)
	const [isLoadingList, setIsLoadingList] = useState<boolean>(false)

	return (
		<ScreenWrapperQualityAssurance headerTitle={'Central de Qualidade - Auditorias'} appearance={{ hideMargin: true }}>
			{!!currentDoctorGroup.code && (
				<>
					<FiltersAuditsCP
						doctorGroupCode={currentDoctorGroup.code}
						filterFormStateManager={formStateManager}
						isLoading={isLoadingList}
						onFilter={() => {
							setLoadList(TableUtils.getReloadNumber())
							setIsLoadingList(true)
						}}
					/>
					<FlexCP justify="center">
						<CardCP width="800px">
							<FlexCP justify="space-between">
								<FlexCP flexDirection="column">
									<CardWidgetOnlyValuesShouldLoadCP
										main={{
											title: 'Auditorias Realizadas',
											help: 'Número total de auditorias realizadas',
										}}
										shouldLoad={loadList}
										requestConfigGetter={WidgetsRequests.countAudits({
											...AuditFilterFormModelConverter.convertToDto(formStateManager, currentDoctorGroup.code),
											statuses: [AuditStatusEnum.DONE],
										})}
									/>
									<WidgetCountAuditsOpenCP
										doctorGroupCode={currentDoctorGroup.code}
										shouldLoad={loadList}
										filters={AuditFilterFormModelConverter.convertToDto(formStateManager, currentDoctorGroup.code)}
									/>
									<CardWidgetOnlyValuesShouldLoadCP
										main={{
											title: 'Score Médio',
											help: 'Média das notas das auditorias',
										}}
										shouldLoad={loadList}
										requestConfigGetter={WidgetsRequests.averageAuditsScore({
											...AuditFilterFormModelConverter.convertToDto(formStateManager, currentDoctorGroup.code),
											statuses: [AuditStatusEnum.DONE],
										})}
									/>
								</FlexCP>
								<CardWidgetAuditPercentage
									main={{
										title: 'Percentual de Auditorias',
										help: 'Percentual de auditorias realizadas por modalidade de exames',
									}}
									shouldLoad={loadList}
									requestConfigGetter={WidgetsRequests.auditPercentage({
										...AuditFilterFormModelConverter.convertToDto(formStateManager, currentDoctorGroup.code),
										statuses: [AuditStatusEnum.DONE],
									})}
								/>
							</FlexCP>
						</CardCP>
					</FlexCP>

					<TableAuditsCP
						reloadTable={loadList}
						onLoading={setIsLoadingList}
						filters={AuditFilterFormModelConverter.convertToDto(formStateManager, currentDoctorGroup.code)}
					/>
				</>
			)}
		</ScreenWrapperQualityAssurance>
	)
}
