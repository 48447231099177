import { SelectExamSpecialtyCP } from 'modules/exams/components/exams/select-exam-specialty/SelectExamSpecialtyCP'
import { MedicalTimetableFilterFormModel } from 'modules/medical-timetable/components/sider-medical-timetable-filters/inner/MedicalTimetableFilterFormModel'
import styled from 'styled-components'
import {
	MedicalTimetableIdEnum,
	MedicalTimetableIdLabelEnum,
} from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableIdEnum'
import {
	MedicalTimetablePeriodEnum,
	MedicalTimetablePeriodLabelEnum,
} from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetablePeriodEnum'
import { ExamModalityEnum, ExamModalityLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ExamSpecialtyEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamSpecialtyEnum'
import { ExamUrgencyTypeEnum, ExamUrgencyTypeLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { CalendarCP } from 'submodules/nerit-framework-ui/common/components/calendar/CalendarCP'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { SiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/sider/SiderCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'

interface ICPProps {
	filterFormStateManager: IFormStateManager<MedicalTimetableFilterFormModel>
	onChangeFilters: () => void
	appearance?: {
		hideCalendar?: boolean
	}
}

/**
 */
export function SiderMedicalTimetableFiltersCP(props: ICPProps): JSX.Element {
	/**
	 */
	function onSelectDate(date: Date) {
		const weekRange = DateUtils.getFirstAndLastDayOfWeek(date)
		props.filterFormStateManager.changeFieldValue('beginDate', weekRange.beginDate)
		props.filterFormStateManager.changeFieldValue('endDate', weekRange.endDate)

		props.onChangeFilters()
	}

	return (
		<SiderCP width={275} bgColor={`${ThemeFrameworkCommon.browserDefaultBackgroundLight}`}>
			<div>
				{!props.appearance?.hideCalendar && (
					<CalendarCP
						currentDate={{
							beginDate: props.filterFormStateManager.getFieldValue('beginDate'),
							endDate: props.filterFormStateManager.getFieldValue('endDate'),
						}}
						onChangeSelectedDate={onSelectDate}
					/>
				)}

				<ContentSCP>
					<SelectCP
						label={'Escala'}
						formStateManager={props.filterFormStateManager}
						fieldName={'id'}
						options={Object.values(MedicalTimetableIdEnum).map((id) => ({
							value: id,
							label: MedicalTimetableIdLabelEnum[id],
						}))}
						isMultiple={true}
					/>

					<SelectCP
						label={'Período'}
						formStateManager={props.filterFormStateManager}
						fieldName={'period'}
						options={Object.values(MedicalTimetablePeriodEnum).map((period) => ({
							value: period,
							label: MedicalTimetablePeriodLabelEnum[period],
						}))}
						isMultiple={true}
					/>

					<SelectCP
						label={'Modalidade'}
						formStateManager={props.filterFormStateManager}
						fieldName={'modality'}
						options={[
							{ value: ExamModalityEnum.CT, label: ExamModalityLabelEnum[ExamModalityEnum.CT] },
							{ value: ExamModalityEnum.MR, label: ExamModalityLabelEnum[ExamModalityEnum.MR] },
							{ value: ExamModalityEnum.CR, label: ExamModalityLabelEnum[ExamModalityEnum.CR] },
						]}
						isMultiple={true}
					/>

					{(ArrayUtils.isEmpty(props.filterFormStateManager.getFieldValue('groupBy')) ||
						!!ExamUrgencyTypeEnum[props.filterFormStateManager.getFieldValue('groupBy')[0]]) && (
						<SelectCP
							label={'Prioridade'}
							formStateManager={props.filterFormStateManager}
							fieldName={'groupBy'}
							options={[
								{ value: ExamUrgencyTypeEnum.URGENT, label: ExamUrgencyTypeLabelEnum.URGENT },
								{ value: ExamUrgencyTypeEnum.REGULAR, label: ExamUrgencyTypeLabelEnum.REGULAR },
							]}
							isMultiple={true}
						/>
					)}

					{(ArrayUtils.isEmpty(props.filterFormStateManager.getFieldValue('groupBy')) ||
						!!ExamSpecialtyEnum[props.filterFormStateManager.getFieldValue('groupBy')[0]]) && (
						<SelectExamSpecialtyCP formStateManager={props.filterFormStateManager} fieldName={'groupBy'} isMultiple={true} />
					)}
				</ContentSCP>
			</div>
		</SiderCP>
	)
}

const ContentSCP = styled.div`
	padding: 0 10px;
	display: flex;
	flex-direction: column;
	gap: 5px;
`
