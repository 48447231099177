import { IAuthState } from 'app/redux/slices/auth/AuthSlice'
import { AppUtils } from 'app/utils/AppUtils'
import { AppConfig } from 'config/AppConfig'
import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { FrontEnvironmentEnum } from 'submodules/nerit-framework-utils/utils/enums/EnvironmentEnum'
import { Space4LeadsSystemApiEnum } from 'submodules/space4leads-sdk/common/Space4LeadsSystemApiEnum'

/**
 * Class especifica do projeto com informacoes necessarios para o projeto.
 */
export class NeritFrameworkProjectConfig {
	static APP_VERSION = import.meta.env.VITE_APP_VERSION
	static PROJECT_NAME = 'VX Medical Innovation'

	/**
	 */
	static isDev(): boolean {
		return AppConfig.getInstance().environment === FrontEnvironmentEnum.DEVELOPMENT
	}

	/**
	 */
	static isHom(): boolean {
		return AppConfig.getInstance().environment === FrontEnvironmentEnum.HOMOLOGATION
	}

	/**
	 */
	static formatApiBaseUrl(config: RequestConfigTP, forceApi2?: boolean): string {
		let baseurl = config.url
		if (config.baseUrlType === BeeRadsApiEnum.BEERADS_API) {
			if (!!config.baseUrl) {
				baseurl = `${config.baseUrl}/${config.url}`
			} else {
				baseurl = `${forceApi2 ? AppConfig.getInstance().api2BaseUrl : AppConfig.getInstance().apiBaseUrl}/${config.url}`
			}
		} else if (config.baseUrlType === Space4LeadsSystemApiEnum.SPACE4LEADS_API) {
			baseurl = `${AppConfig.getInstance().space4LeadsApiBaseUrl}/${config.url}`

			if (!config.headers) {
				config.headers = [{ headerName: 'on-behalf-of', headerValue: '100' }]
			}

			// if (store.getState().doctorGroup?.code === 142)
			// 	config.headers.push({
			// 		headerName: 'Authorization',
			// 		headerValue:
			// 			'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1c2VyQ29kZSI6NjQ0OTksInR5cGUiOiJBUEkiLCJpYXQiOjE2OTI3MTczNzMsImF1ZCI6InVybjpuZXJpdGNsaW4ifQ.lFoevM-h2J-XcWigSO-eMEsl2YpGdtp4FF1BAjnL4IHjIvptikhhHKJEwDqTldlrXeqnpAmCPzgnP-Lz8ucP-w',
			// 	})
		}

		return baseurl
	}

	/**
	 */
	static notifyApi(type: 'success' | 'error', title: string, msg: any): void {
		if (typeof msg !== 'string') {
			console.warn('NotifyApi invokado sem msg string', msg)
			return
		}

		if (type === 'error') NotificationHelper.error(title, msg)
		else NotificationHelper.success(title, msg)
	}

	/**
	 */
	static onUnauthorized(authData: IAuthState | null): void {
		AppUtils.onUnauthorizedRequestResponse(authData)
	}
}
