import React from 'react'
import { SiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/sider/SiderCP'
import * as S from './LayoutUserMainStyles'

interface ICPProps {
	menu: React.ReactNode
	refreshMenuCollapsed?: number
	privateRouter: React.ReactNode
	extraContent?: React.ReactNode
}

/**
 */
export function LayoutUserMainCP(props: ICPProps): JSX.Element {
	return (
		<S.LayoutWrapper>
			<SiderCP isMainSider={true} collapsedWidth={0} breakpoint={'sm'} id={'main-menu-id'}>
				{props.menu}
			</SiderCP>
			<S.Content>
				{props.privateRouter}

				{props.extraContent}
			</S.Content>
		</S.LayoutWrapper>
	)
}
