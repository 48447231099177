import { IAuthState } from 'app/redux/slices/auth/AuthSlice'
import { ClinicPermissionUtils } from 'modules/auth/permissions/ClinicPermissionUtils'
import { UserDoctorGroupAccessTP } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/types/UserDoctorGroupAccessTP'

export type DoctorGroupRelationPermissionTP = 'isDoctorGroupAdmin' | 'isAuditor' | 'isDoctorGroupUser' | 'isDoctorAdmin'

/**
 */
export class DoctorGroupPermissionUtils {
	/**
	 */
	static canSeeBulkExamData(loggedUser: IAuthState['user']): boolean {
		// Tem algum grupo nao permitindo ver
		let hasAnyDoctorGroupNotAllowing = false
		// Tem permissao de admin em todos os grupos
		let isNotAdminInDoctorGroup = false

		// Se nao tiver vinculado a nenhum grupo, permite
		if (!loggedUser?.doctorGroups) return true

		loggedUser.doctorGroups.forEach((doctorGroup) => {
			// Verifica se tem ao menos uma configuracao que ele nao pode ver, ai nao mostra
			if (doctorGroup.config?.canSeeExamOutsideMedicalReport === false) hasAnyDoctorGroupNotAllowing = true

			// Verifica se o medico tem permissao de ADMIN em todos
			if (!doctorGroup.loggedUserAccess.isDoctorAdmin) isNotAdminInDoctorGroup = true
		})

		let canSeeExamData = false

		// Se for admin em todos os grupos, ja pode ver
		// Senao, deixa ver caso todos os grupos deixem
		if (!isNotAdminInDoctorGroup) canSeeExamData = true
		else if (!hasAnyDoctorGroupNotAllowing) canSeeExamData = true

		return canSeeExamData
	}

	/**
	 */
	static canSeeExamData(loggedUser: IAuthState['user'], viewAsDoctor: boolean, clinicCode: number, doctorGroupCode?: number): boolean {
		// Se nao estiver visualizando como medico, pode sempre ver. Se nao tiver grupo de medico vinculado, sempre pode tambem (nesse caso o exame nao tem grupo)
		if (!viewAsDoctor || !doctorGroupCode) return true

		// Obtem o grupo
		const doctorGroup = loggedUser?.doctorGroups?.find((doctorGroup) => doctorGroup.code === doctorGroupCode)

		// Se for usuario sempre pode ver
		if (ClinicPermissionUtils.hasAnyPermissionsInClinic(clinicCode, ['isUser', 'isAdmin'], loggedUser)) return true

		// Se for usuario no grupo sempre pode ver tb
		if (DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(loggedUser, doctorGroupCode, ['isDoctorGroupAdmin', 'isDoctorGroupUser']))
			return true

		// Se for medico admin, sempre pode ver
		if (doctorGroup?.loggedUserAccess.isDoctorAdmin) return true

		// Verifica a configuracao do grupo. Se nao tiver configurado ou se esta permitido
		if (doctorGroup?.config?.canSeeExamOutsideMedicalReport === undefined || doctorGroup?.config?.canSeeExamOutsideMedicalReport === true) return true

		return false
	}

	/**
	 */
	static hasAnyPermissionsInDoctorGroup(
		loggedUser: IAuthState['user'],
		doctorGroupCode: number,
		permissions: DoctorGroupRelationPermissionTP[],
	): boolean {
		const foundDoctorGroup = loggedUser?.doctorGroups?.find((doctorGroup) => doctorGroup.code === doctorGroupCode)

		if (!foundDoctorGroup) return false

		// Valida se possui alguma permissao na clinica especifica
		return DoctorGroupPermissionUtils.checkAnyPermission(permissions, foundDoctorGroup.loggedUserAccess)
	}

	/**
	 */
	static hasAnyPermissionsInAnyDoctorGroup(
		permissions: DoctorGroupRelationPermissionTP[],
		doctorGroupPermissions: IAuthState['doctorGroupPermissions'],
	): boolean {
		// Valida se possui alguma permissao em alguma clinica
		return doctorGroupPermissions ? DoctorGroupPermissionUtils.checkAnyPermission(permissions, doctorGroupPermissions) : false
	}

	/**
	 * Checa se possui pelo menos uma das permissoes desejadas
	 */
	private static checkAnyPermission(permissions: DoctorGroupRelationPermissionTP[], doctorGroupPermission: UserDoctorGroupAccessTP): boolean {
		if (!doctorGroupPermission) return false

		// A logida sera de OU em todas permissoes, ou seja, tendo ao menos uma ja da acesso
		// Buscaremos pela primeira ocorrecia verdadeira, ou seja, caso a permissao seja para acesso de usuario e seja usuario, ja eh retornado

		if (permissions.includes('isDoctorGroupAdmin') && doctorGroupPermission.isDoctorGroupAdmin) return true

		if (permissions.includes('isAuditor') && doctorGroupPermission.isAuditor) return true

		if (permissions.includes('isDoctorAdmin') && doctorGroupPermission.isDoctorAdmin) return true

		if (permissions.includes('isDoctorGroupUser') && doctorGroupPermission.isDoctorGroupUser) return true

		return false
	}
}
