import React from 'react'
import { DoctorGroupPersonResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/responses/DoctorGroupPersonResponseDTO'
import { ButtonToggleDoctorGroupPersonAdminCP } from 'modules/doctor-groups/components/doctor-groups/button-toggle-doctor-group-person-admin/ButtonToggleDoctorGroupPersonAdminCP'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { ITableColumn } from 'submodules/nerit-framework-ui/common/components/table/types/ITableColumn'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { ExamSpecialtyLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamSpecialtyEnum'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { ColumnTableDoctorGroupActionsCP } from 'modules/doctor-groups/components/doctor-groups/table-doctor-group-people/columns/ColumnTableDoctorGroupActionsCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'

export const TableDoctorGroupPeopleUtils = {
	getColumns(
		role: UserTypeEnum,
		doctorGroupCode: number,
		onEdit: (doctorGroupPerson: DoctorGroupPersonResponseDTO) => void,
		onChangeData: () => void,
	): Array<ITableColumn<DoctorGroupPersonResponseDTO>> {
		return [
			{
				render: (text, record) => (
					<ButtonToggleDoctorGroupPersonAdminCP
						personCode={record.person.code}
						isAdmin={record.isAdmin}
						doctorGroupCode={doctorGroupCode}
						role={role}
					/>
				),
			},
			{
				title: 'Código',
				render: (text, record) => <TextCP size="small" text={record.person.code} />,
			},
			{
				title: 'Nome',
				render: (text, record) => (
					<>
						<span>{record.person.name}</span>
						<TextCP
							size={'small'}
							color={ThemeProject.gray300}
							text={!!record.extraDoctorData?.specialty ? ExamSpecialtyLabelEnum[record.extraDoctorData?.specialty] : undefined}
						/>
					</>
				),
			},
			{
				title: 'CPF',
				render: (text, record) => MaskUtils.applyMask2(InputMaskTypeEnum.CPF, record.person.cpf),
			},
			{
				title: 'E-mail',
				render: (text, record) => record.person.email,
			},
			{
				render: (text, record) => (
					<ColumnTableDoctorGroupActionsCP record={record} onEdit={() => onEdit(record)} onChangeData={onChangeData} role={role} />
				),
			},
		]
	},
}
