import { RadioGroupCP } from '_old/main/common/components/radio-group/RadioGroupCP'
import { ITotalByModality } from 'modules/reports/_old/interfaces/ITotalByModality'
import { ExamModalitySelectorCP } from 'modules/reports/components/exam-modality-selector/ExamModalitySelectorCP'
import styled from 'styled-components'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'

const RADIO_BTN_WIDTH = 65

interface IGraphSideOptionsICPProps {
	currentTimeBase: TimeBaseEnum
	currentModality?: ExamModalityEnum
	onTimeBaseChange: (timeBase: TimeBaseEnum) => void
	byModalityLists: ITotalByModality[]
	onModalityChange: (modality?: ExamModalityEnum) => void
}

/**
 * Barra lateral do componente de grafico do dashboard: Contem opcoes de filtragem dos dados exibidos (base de tempo, modalidades, etc).
 */
export function GraphSideOptionsICP(props: IGraphSideOptionsICPProps): JSX.Element {
	return (
		<WrapperSCP>
			<RadioGroupCP<TimeBaseEnum>
				value={props.currentTimeBase}
				onChange={props.onTimeBaseChange}
				buttonWidth={RADIO_BTN_WIDTH}
				options={[
					{
						value: TimeBaseEnum.DAY,
						content: 'Dia',
					},
					{
						value: TimeBaseEnum.MONTH,
						content: 'Mês',
					},
				]}
			/>

			<ExamModalitySelectorCP byModalityLists={props.byModalityLists} currentModality={props.currentModality} onChange={props.onModalityChange} />
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	display: flex;
	flex-direction: column;
`
